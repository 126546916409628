var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "password-text" }, [
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isHidden,
            expression: "isHidden",
          },
        ],
        staticClass: "password-text__hidden",
      },
      [_vm._v(" •••••••••••• ")]
    ),
    _c(
      "span",
      {
        staticClass: "password-text__password",
        class: { "password-text__password--hidden": _vm.isHidden },
      },
      [_vm._v(" " + _vm._s(_vm.password) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }