// import and export all files from this folder here
import Activity from "./Activity";
import CloakCreate from "./CloakCreate";
import CloakedDaily from "./CloakedDaily";
import CloakDetails from "./CloakDetails";
import Settings from "./Settings";
import ReferralDenied from "./ReferralDenied";
import ReferralCode from "./ReferralCode";
import Referral from "./Referral";
import SettingsAccount from "./Settings/SettingsAccount";
import SettingsEmailGeneration from "./Settings/EmailGeneration";
import SettingsForwarding from "./Settings/Forwarding";
import SettingsPassphrase from "./Settings/Passphrase";
import SettingsPersonalInformation from "./Settings/PersonalInformation";
import SettingsPrivacy from "./Settings/SettingsPrivacy";
import SettingsSubscription from "./Settings/SettingsSubscription";
import SettingsCloakedCards from "./Settings/CloakedCards";
import Shared from "./Shared";
import Trash from "./Trash";
import Cards from "./CloakedCards/Cards";
import Transactions from "./CloakedCards/Transactions";
import AutoChange from "./auto-change/AutoChange";
import WalletPage from "./WalletPage";

export {
  Trash,
  CloakCreate,
  CloakedDaily,
  CloakDetails,
  Activity,
  ReferralCode,
  Settings,
  Referral,
  ReferralDenied,
  SettingsAccount,
  SettingsEmailGeneration,
  SettingsPassphrase,
  SettingsForwarding,
  SettingsPersonalInformation,
  SettingsPrivacy,
  SettingsCloakedCards,
  SettingsSubscription,
  Shared,
  Cards,
  Transactions,
  AutoChange,
  WalletPage,
};
