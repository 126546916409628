var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "activity-list-wrapper",
      class: {
        "overflow-hidden": _vm.searchLoading || !_vm.loaded,
        "shorter-list": _vm.areRequestsVisible,
      },
    },
    [
      _c(
        "button",
        {
          staticClass: "refresh",
          class: { visible: _vm.showRefresh },
          on: { click: _vm.toggleRefresh },
        },
        [_vm._v(" New Messages Available ")]
      ),
      _c("ActivityActionBar", {
        attrs: {
          multiSelectEnabled: _vm.multiSelectEnabled,
          multiSelected: _vm.multiSelected,
          selectTooltipText: _vm.selectTooltipText,
          numSelected: _vm.numSelected,
          searchEnabled: _vm.searchEnabled,
          searchText: _vm.searchText,
          allSelectedAreRead: _vm.allSelectedAreRead,
          singleSelected: _vm.singleSelected,
        },
        on: {
          setSearchEnabled: _vm.setSearchEnabled,
          setSearchText: _vm.setSearchText,
          searchThreads: _vm.searchThreads,
          setMultiSelect: (enabled) => _vm.$emit("setMultiSelect", enabled),
          toggleSelectAll: _vm.toggleSelectAll,
          showDeleteModal: _vm.showDeleteModal,
          markAsRead: _vm.markAsRead,
        },
      }),
      _vm.groups.length && !_vm.searchLoading
        ? _c(
            "div",
            {
              staticClass: "activity-group-wrapper",
              attrs: { "aria-id": "ActivityLoadedState" },
            },
            [
              _vm._l(_vm.groups, function (group) {
                return _c(
                  "div",
                  {
                    key: `thread-groups-${group.name}`,
                    staticClass: "activity-group",
                    class: {
                      fullHeight:
                        _vm.searchedThreads !== null &&
                        _vm.searchedThreads.length === 0,
                    },
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c("h2", { staticClass: "activity-list-day" }, [
                      _vm._v(_vm._s(group.name)),
                    ]),
                    _vm.searchedThreads !== null &&
                    _vm.searchedThreads.length === 0 &&
                    !_vm.searchLoading
                      ? _c("ActivityEmptySearch", {
                          attrs: { searchText: _vm.searchText },
                        })
                      : _vm._e(),
                    _vm._l(group.items, function (thread, idx) {
                      return _c("ActivityPreviewItem", {
                        key: `activity-preview-today-${idx}`,
                        attrs: {
                          thread: thread,
                          threadType: thread.threadType,
                          selected: _vm.isThreadSelected(thread),
                          multiSelectEnabled: _vm.multiSelectEnabled,
                          isCloakSpecificView: !!_vm.cloakId,
                          searchText: _vm.searchEnabled ? _vm.searchText : null,
                          allSelectedAreRead: _vm.allSelectedAreRead,
                        },
                        on: {
                          select: function ($event) {
                            return _vm.$emit("select", thread)
                          },
                          showDeleteModal: _vm.showDeleteModal,
                          markAsRead: _vm.markAsRead,
                          setMultiSelect: (enabled) =>
                            _vm.$emit("setMultiSelect", enabled),
                          toggleSelectAll: _vm.selectAll,
                        },
                      })
                    }),
                  ],
                  2
                )
              }),
              _c("InfiniteTrigger", {
                key: "activity",
                ref: "infiniteLoader",
                staticStyle: { "align-self": "center" },
                on: { infinite: _vm.getMoreData },
              }),
            ],
            2
          )
        : _vm.loaded && !_vm.error && !_vm.searchLoading
        ? _c(
            "div",
            {
              staticClass: "preview-empty-state",
              style: {
                height: `calc(100vh - 65px - 70px - ${
                  _vm.hasVisibleBanner ? "40px" : "0"
                })`,
              },
              attrs: { "aria-id": "ActivityLoadingEmptyState" },
            },
            [
              !_vm.isRequests
                ? _c(
                    "div",
                    [
                      _c("InboxIcon"),
                      _c("h1", [_vm._v("There’s nothing here yet.")]),
                      _c("p", [
                        _vm._v(
                          " Calls, texts, and emails associated with this identity will appear here. "
                        ),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "request-empty" },
                    [
                      _c("RequestIcon", {
                        attrs: { width: "35", height: "37" },
                      }),
                      _c("h1", [_vm._v("No requests")]),
                      _c("p", [
                        _vm._v(
                          " Calls and texts will appear here that been marked as needing approval. "
                        ),
                      ]),
                    ],
                    1
                  ),
            ]
          )
        : _vm.error && _vm.loaded && !_vm.searchLoading
        ? _c(
            "div",
            {
              staticClass: "preview-empty-state",
              style: {
                height: `calc(100% - 65px - 70px - ${
                  _vm.hasVisibleBanner ? "40px" : "0"
                })`,
              },
              attrs: { "aria-id": "ActivityFailedEmptyState" },
            },
            [
              _c("ErrorTriangleIcon"),
              _c("h1", [_vm._v("Failed to load inbox list.")]),
              _c("p", [
                _vm._v(
                  " Sorry, we couldn’t load this inbox list right now. Try refreshing your browser. "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.searchLoading || !_vm.loaded ? _c("SkeletonLoader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }