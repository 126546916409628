var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.CustomFieldFormWrapper,
    _vm._g(
      _vm._b(
        { staticClass: "custom-field-form-text", attrs: { value: _vm.value } },
        "CustomFieldFormWrapper",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(_setup.BorderInputText, {
        ref: "label",
        attrs: {
          label: "Label",
          value: _vm.value.label,
          placeholder: _setup.labelPlaceholder,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", { ..._vm.value, label: $event })
          },
        },
      }),
      _vm.fieldType === "url"
        ? _c(_setup.BorderInputMultiline, {
            attrs: {
              label: "Content",
              value: _vm.value.value,
              errors: _vm.errors?.value,
              placeholder: _setup.valuePlaceholder,
            },
            on: {
              input: _setup.onValueInput,
              change: function ($event) {
                return _vm.$emit("validate", { value: $event, field: "value" })
              },
            },
          })
        : _c(_setup.BorderInputText, {
            attrs: {
              label: "Content",
              value: _vm.value.value,
              errors: _vm.errors?.value,
              placeholder: _setup.valuePlaceholder,
            },
            on: {
              input: _setup.onValueInput,
              change: function ($event) {
                return _vm.$emit("validate", { value: $event, field: "value" })
              },
            },
          }),
      _c(_setup.CustomFieldFormInputIsSensitive, {
        attrs: { value: _vm.value.isSecret },
        on: {
          input: function ($event) {
            return _vm.$emit("input", { ..._vm.value, isSecret: $event })
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }