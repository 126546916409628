<template>
  <section class="activity-action-bar-wrapper">
    <div class="activity-action-bar-top flex-row-center">
      <div v-if="searchEnabled" class="activity-search-bar">
        <input
          aria-id="ActivityInputSearchBar"
          ref="searchInput"
          :value="searchText"
          @input="(e) => $emit('setSearchText', e.target.value)"
        />
        <SearchIcon class="activity-action-input-search" />
        <CloseXIcon class="activity-action-input-x" @click="closeSearch" />
      </div>
      <div v-if="!searchEnabled" class="flex-row-center activity-title">
        <h1>Inbox</h1>
        <div aria-id="ActivitySearchButton" class="activity-action-icons">
          <UiTooltip title="Search" align-x="center"
            ><span @click="showSearch"><SearchIcon /></span
          ></UiTooltip>

          <UiTooltip v-if="false" title="Filters" align-x="center"
            ><span> <FilterIcon /></span
          ></UiTooltip>
        </div>
      </div>

      <UiMenu
        width="188px"
        placement="bottom-end"
        @click="shouldShowTooltipOnHover = !shouldShowTooltipOnHover"
      >
        <UiTooltip
          aria-id="ActivitySearchKabob"
          :title="shouldShowTooltipOnHover ? 'More' : ''"
          align-x="center"
          class="activity-action-kabob-icon"
          ><span v-click-outside="toggleShouldShowTooltip"> <KabobIcon /></span
        ></UiTooltip>

        <template #content>
          <UiMenuButton
            aria-id="ActivitySelect"
            :title="`Select messages`"
            @click="$emit('setMultiSelect', true)"
          >
            <template v-slot:icon>
              <SelectMessagesIcon />
            </template>
          </UiMenuButton>
          <UiMenuButton
            aria-id="ActivitySelectAll"
            :title="`Select all`"
            @click="$emit('toggleSelectAll')"
          >
            <template v-slot:icon>
              <SelectAllIcon />
            </template>
          </UiMenuButton>
          <UiMenuButton
            aria-id="ActivityMarkRead"
            :title="`Mark as ${readOrUnread}`"
            @click="$emit('markAsRead', readOrUnread)"
          >
            <template v-slot:icon>
              <ActivityMarkAsReadIcon />
            </template>
          </UiMenuButton>
          <!-- <UiMenuButton
            :title="`Reply`"
            @click="reply"
          >
            <template v-slot:icon>
              <ActivityReplyIcon />
            </template>
          </UiMenuButton> -->

          <UiMenuSeparator />

          <div class="alert-text">
            <UiMenuButton
              aria-id="ActivityDelete"
              title="Delete"
              @click="$emit('showDeleteModal')"
              danger
            >
              <template v-slot:icon>
                <DeleteTrashIcon />
              </template>
            </UiMenuButton>
          </div>
        </template>
      </UiMenu>
    </div>

    <div
      v-if="multiSelectEnabled"
      class="activity-action-icons activity-action-multiselect flex-row-center"
    >
      <div class="flex-row-center">
        <UiTooltip
          aria-id="ActivitySelectAllIcon"
          :title="selectTooltipText"
          align-x="center"
          ><span @click="$emit('toggleSelectAll')">
            <MinusRound
              v-if="selectTooltipText.toLowerCase().includes('deselect')"
            />
            <EmptyRound v-else /> </span
        ></UiTooltip>

        <UiTooltip
          aria-id="ActivityMarkReadIcon"
          :title="`Mark as ${readOrUnread}`"
          align-x="center"
          ><span @click="$emit('markAsRead', readOrUnread)"
            ><MarkAsReadIcon /></span
        ></UiTooltip>

        <div class="border"></div>

        <UiTooltip aria-id="ActivityDeleteIcon" title="Delete" align-x="center"
          ><span @click="$emit('showDeleteModal')"><DeleteIcon /></span
        ></UiTooltip>
      </div>
    </div>
  </section>
</template>
<script>
import FilterIcon from "@/assets/icons/filter-actionbar.svg";
import KabobIcon from "@/assets/icons/kabob-actionbar.svg";
import {
  ActivityMarkAsReadIcon,
  DeleteTrashIcon,
  SelectAllIcon,
  SelectMessagesIcon,
  MinusRound,
  EmptyRound,
} from "@/assets/icons";
import SearchIcon from "@/assets/icons/search-actionbar.svg";
import MarkAsReadIcon from "@/assets/icons/markasread-actionbar.svg";
import DeleteIcon from "@/assets/icons/delete-actionbar.svg";
import CloseXIcon from "@/assets/icons/close-x-actionbar.svg";
import UiTooltip from "@/components/ui/ui-tooltip";
import { mapActions } from "vuex";
import ClickOutside from "v-click-outside";
import { UiMenu, UiMenuButton, UiMenuSeparator } from "@/components";

let searchTimeout;

export default {
  name: "ActivityActionBar",
  components: {
    FilterIcon,
    KabobIcon,
    SearchIcon,
    MarkAsReadIcon,
    DeleteIcon,
    UiTooltip,
    CloseXIcon,
    UiMenu,
    UiMenuButton,
    UiMenuSeparator,
    ActivityMarkAsReadIcon,
    DeleteTrashIcon,
    SelectAllIcon,
    SelectMessagesIcon,
    MinusRound,
    EmptyRound,
  },
  props: [
    "multiSelectEnabled",
    "multiSelected",
    "selectTooltipText",
    "numSelected",
    "searchEnabled",
    "searchText",
    "allSelectedAreRead",
    "singleSelected",
  ],
  data() {
    return {
      shouldShowTooltipOnHover: true,
    };
  },
  computed: {
    readOrUnread() {
      if (this.multiSelectEnabled) {
        return this.allSelectedAreRead ? "unread" : "read";
      }
      return this.singleSelected && this.singleSelected.read
        ? "unread"
        : "read";
    },
  },
  methods: {
    ...mapActions(["openModal"]),
    showSearch() {
      this.$emit("setSearchEnabled", true);
      this.$nextTick(() => this.$refs.searchInput.focus());
    },
    closeSearch() {
      this.$emit("setSearchEnabled", false);
    },
    toggleShouldShowTooltip() {
      this.shouldShowTooltipOnHover = true;
    },
  },
  watch: {
    searchText(value) {
      clearTimeout(searchTimeout);
      if (value) {
        searchTimeout = setTimeout(() => this.$emit("searchThreads"), 300);
      }
    },
  },
  directives: {
    ClickOutside: ClickOutside.directive,
  },
};
</script>
<style lang="scss" scoped>
.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.activity-action-bar-wrapper {
  position: sticky;
  top: 0;
  padding: 14px 4px 16px 16px;
  background: $color-surface;
  z-index: 10;

  h1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    color: $color-primary-100;
  }

  .activity-action-icons {
    gap: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 32px;
    width: 32px;
    div > span {
      height: 32px;
      width: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      z-index: 1;

      &:hover {
        cursor: pointer;
      }

      svg {
        color: $color-primary-100;
      }
    }
    .border {
      width: 1px;
      height: 30px;
      background-color: $color-primary-100-10;
      margin: 0 4px;
    }
  }
  .activity-action-bar-top {
    height: 40px;

    .activity-title {
      // padding: 21.5px 16px 18.5px;
    }
    .activity-search-bar {
      // position: absolute;
      width: calc(100% - 16px);
      color: $color-primary-100;
      // margin-left: -8px;
      top: 15px;
      > input {
        position: relative;
        width: 345px;
        padding: 11.5px 35px;
        background: $color-primary-10;
        border-radius: 8px;
        border: none;
        color: $color-primary-100;
        outline: none;
        &::placeholder {
          color: $color-primary-50;
        }
      }
      .activity-action-input-search {
        position: absolute;
        top: 35px;
        left: 34px;
        @include transform(translate(-50%, -50%));
        cursor: pointer;
      }
      .activity-action-input-x {
        position: absolute;
        top: 35px;
        left: 342px;
        @include transform(translate(-50%, -50%));
        cursor: pointer;
      }
    }

    .activity-action-kabob-icon {
      height: 32px;
      width: 32px;
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      > span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        height: 32px;
        width: 32px;

        svg {
          color: $color-primary-100;
        }
      }
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        background-color: $color-primary-100-10;
        width: 0px;
        height: 0px;
        border-radius: 50%;
        @include transition(all 0.45s cubic-bezier(0.68, -0.55, 0.27, 1.55));
        z-index: -1;
      }

      &:hover {
        cursor: pointer;

        &:after {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  .activity-action-multiselect {
    margin: 14.5px 0 5.5px;
    > p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $color-primary-100;
      text-transform: capitalize;
      padding: 4px 6px;
      cursor: pointer;
    }
  }
}

.slide-in-enter-active {
  animation: slide-in 0.5s;
}
.slide-in-leave-active {
  animation: slide-in 0.5s reverse;
}

@keyframes slide-in {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.fade-out-enter-active {
  animation: fade-out 1s reverse;
}
.fade-out-leave-active {
  animation: fade-out 0s;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.alert-text {
  color: $color-alert;
}
</style>
