<template>
  <div class="forwarding-email">
    <Email v-if="step < 2" />
    <div v-if="step === -1">
      <AppModalTitle v-if="step === -1" class="forwarding-phone__choice-title">
        Where should emails sent to your Cloaked email addresses go?
      </AppModalTitle>
      <fieldset class="forwarding-phone__forwarding-choice">
        <OnboardingInputRichRadio
          @change="$emit('input-email-forwarding', $event.target.value)"
          :checked="emailForwarding === 'cloaked'"
          pre-title="RECOMMENDED"
          title="Keep emails in Cloaked"
          text="Emails only go to your Cloaked inbox"
          id="cloaked"
          ref="radioOne"
          v-on="$listeners"
          @keydown.down.native="$emit('input-email-forwarding', 'email')"
        >
          <template #image>
            <EmailForwardingCloaked />
          </template>
        </OnboardingInputRichRadio>
        <OnboardingInputRichRadio
          @change="$emit('input-email-forwarding', $event.target.value)"
          title="Forward email to your personal email address"
          text="Emails go to your Cloaked inbox and are also forwarded to your personal
          email inbox"
          id="email"
          :checked="emailForwarding === 'email'"
          v-on="$listeners"
          @keydown.up.native="$emit('input-email-forwarding', 'cloaked')"
        >
          <template #image>
            <EmailForwardingPersonal />
          </template>
        </OnboardingInputRichRadio>
      </fieldset>
    </div>
    <EmailDone v-else-if="step === 2" class="forwarding-email__done" />
    <AppModalTitle v-if="step === 0"> Add an email address </AppModalTitle>
    <AppModalTitle v-else-if="step === 1">
      Verify your email address
    </AppModalTitle>
    <AppModalTitle v-else-if="step === 2" class="forwarding-email__verified">
      Your email address has been verified
    </AppModalTitle>
    <AppModalParagraph v-if="step === 0">
      Emails sent to your Cloaked email addresses will be forwarded to this
      email address
    </AppModalParagraph>
    <AppModalParagraph v-else-if="step === 1">
      Enter the 6-digit code sent to <strong>{{ email }}</strong
      ><br />
      <button class="forwarding-email__resend" @click="$emit('resend')">
        Resend code
      </button>
    </AppModalParagraph>
    <AppModalParagraph
      v-else-if="step === 2"
      class="forwarding-email__verified-subtitle"
    >
      Emails sent to your Cloaked email addresses will be forwarded to
      <strong>{{ email }}</strong
      >. You can change this setting for individual Cloaked identities.
    </AppModalParagraph>
    <OnboardingInputEmail
      :value="email"
      v-if="step === 0"
      @input="$emit('input-email', $event.target.value)"
      v-on="$listeners"
      placeholder="Email"
    />
    <OnboardingInputCode
      :value="emailCode"
      v-else-if="step === 1"
      @input="$emit('input-email-code', $event)"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import AppModalTitle from "@/components/ui/AppModalTitle";
import AppModalParagraph from "@/components/ui/AppModalParagraph";
import Email from "@/assets/icons/onboarding/email.svg";
import EmailDone from "@/assets/icons/onboarding/email-done.svg";
import OnboardingInputEmail from "@/components/feature/onboarding/OnboardingInputEmail";
import OnboardingInputCode from "@/components/feature/onboarding/OnboardingInputCode";
import OnboardingInputRichRadio from "@/components/feature/onboarding/OnboardingInputRichRadio";
import EmailForwardingCloaked from "@/assets/icons/onboarding/email-forwarding-cloaked.svg";
import EmailForwardingPersonal from "@/assets/icons/onboarding/email-forwarding-personal.svg";
export default {
  components: {
    OnboardingInputRichRadio,
    EmailForwardingCloaked,
    EmailForwardingPersonal,
    OnboardingInputCode,
    OnboardingInputEmail,
    AppModalParagraph,
    AppModalTitle,
    Email,
    EmailDone,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    emailCode: {
      type: String,
      required: true,
    },
    emailForwarding: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.forwarding-email {
  &__done {
    animation: scale-in 0.35s 0.1s cubic-bezier(0.3, 1.6, 0.9, 0.95) forwards;
    opacity: 0;

    @at-root .forwarding-email--verified & {
      animation: none;
      opacity: 1;
    }
  }

  &__verified {
    opacity: 0;
    animation: appear-bottom-5 0.4s 0.4s ease-out forwards;

    @at-root .forwarding-email--verified & {
      animation: none;
      opacity: 1;
    }

    &-subtitle {
      opacity: 0;
      animation: appear-bottom-5 0.4s 0.5s ease-out forwards;

      @at-root .forwarding-email--verified & {
        animation: none;
        opacity: 1;
      }
    }
  }

  &__resend {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;

    &:hover {
      opacity: 0.9;
    }
  }

  strong {
    font-weight: 500;
  }
}
</style>
