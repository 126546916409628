var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { class: { hidden: _setup.route.fullPath.includes("settings") } },
    [
      _c(
        "div",
        { staticClass: "logo" },
        [
          _c(
            "router-link",
            { attrs: { to: "/" } },
            [_c(_setup.CloakedLogo)],
            1
          ),
        ],
        1
      ),
      _c(
        "nav",
        [
          _c(_setup.Identities),
          _c(_setup.Wallet),
          _c(_setup.Inbox),
          _c(_setup.Categories, { on: { addCategory: _setup.openCategory } }),
          _c(_setup.Trash),
        ],
        1
      ),
      _setup.subscriptionState !== "CANCELLED"
        ? _c("div", { staticClass: "buttons" }, [
            _c(
              "button",
              {
                staticClass: "primary",
                on: {
                  click: function ($event) {
                    return _setup.newIdentity()
                  },
                },
              },
              [_c(_setup.PlusIcon), _c("span", [_vm._v("New identity")])],
              1
            ),
            _c(
              "button",
              { on: { click: _setup.openCompose } },
              [_c(_setup.Compose), _c("span", [_vm._v("Compose message")])],
              1
            ),
          ])
        : _vm._e(),
      _c(_setup.AddEditNewCategory, {
        attrs: { isModalVisible: _setup.isCatActionsVisible },
        on: { closeModal: _setup.closeEditCategoryModal },
      }),
      _setup.new_message
        ? _c(_setup.ComposePanel, { attrs: { onSend: _setup.send } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }