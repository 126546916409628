var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.tag,
    {
      tag: "component",
      staticClass: "cloak-info-row-button",
      class: {
        "cloak-info-row-button--icon": _setup.props.icon,
        "cloak-info-row-button--100": _setup.props.colorOverride,
        "cloak-info-row-button--primary": _setup.props.colorOverride,
        "cloak-info-row-button--danger": _setup.props.danger,
        "cloak-info-row-button--active": _setup.props.active,
        "cloak-info-row-button--disabled": _setup.props.disabled,
      },
      attrs: {
        target: _setup.target,
        href: _setup.props.href,
        disabled: _setup.props.disabled,
      },
      on: { click: ($event) => _vm.$emit("click", $event) },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }