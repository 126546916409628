<template>
  <div
    class="ui-input"
    :class="{
      'ui-input--clearable': clearable,
      'ui-input--focused': focused,
    }"
  >
    <div class="ui-input__wrapper">
      <slot>
        <input
          class="ui-input__field"
          :value="value"
          :type="type"
          :placeholder="placeholder"
          data-lpignore="true"
          data-form-type="other"
          autocomplete="off"
          :autofocus="autofocus"
          @focus="focused = true"
          @blur="focused = false"
          @input="handleInputUpdate"
        />
      </slot>
    </div>

    <div v-if="hasActions" class="ui-input__actions">
      <button v-if="clearable" @click="handleClearInput">
        <InputClear />
      </button>
    </div>
  </div>
</template>

<script>
import { InputClear } from "@/assets/icons";

export default {
  name: "UiInput",

  props: {
    value: { type: [Number, String], default: "" },
    type: { type: String, default: "text" },
    placeholder: { type: String, default: "" },
    clearable: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
  },

  components: {
    InputClear,
  },

  data() {
    return {
      focused: false,
    };
  },

  computed: {
    hasActions() {
      return [this.clearable].some(Boolean);
    },
  },

  methods: {
    handleClearInput() {
      this.$emit("input", "");
    },

    handleInputUpdate(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style lang="scss">
.ui-input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: $color-background;
  border-radius: 10px;
  border: 2px solid $color-background;

  &__wrapper {
    flex: 1 1 0;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }

  &__field {
    width: 100%;
    border: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.1px;
    color: $color-primary-100;
    background: none;

    &:focus {
      outline: none;
    }
  }

  &__actions {
    display: flex;
    gap: 4px;
    align-items: center;
    flex-shrink: 0;
    padding: 0 10px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: none;
      width: 24px;
      height: 24px;
      color: $color-primary-100;
      padding: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &--focused {
    box-shadow: inset 0px 0px 0px 1px $color-primary-100;
  }
}
</style>
