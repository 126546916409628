var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "credit-card-display",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("CardLogo", { attrs: { cardType: _vm.card.card_type } }),
      _c("div", { staticClass: "card-id" }, [
        _c("div", { staticClass: "card-info" }, [
          _c("div", { staticClass: "card-name" }, [
            _vm._v(" " + _vm._s(_vm.cardTypeFormatted) + " "),
          ]),
        ]),
        _c("div", { staticClass: "card-address" }, [
          _vm._v(
            " •••• " +
              _vm._s(_vm.card.last_4_digits) +
              ", Exp " +
              _vm._s(_vm.card.expiry_date) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "card-actions" }, [_c("ChevronRight")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }