<script setup>
import CustomFieldFormWrapper from "@/components/cloakDetails/CustomFields/CustomFieldForm/CustomFieldFormWrapper.vue";
import BorderInputText from "@/components/BorderInputText.vue";
import BorderInputSelect from "@/components/BorderInputSelect.vue";
import CustomFieldFormInputIsSensitive from "@/components/cloakDetails/CustomFields/CustomFieldFormInputIsSensitive.vue";
import CustomFieldAddressIcon from "@/components/cloakDetails/CustomFields/CustomFieldAddressIcon.vue";
import { addressTypes } from "@/components/cloakDetails/CustomFields/CustomFieldForm/utils";
import { StateList } from "@/scripts/states";
import { countries } from "@/scripts/countries";
</script>

<template>
  <CustomFieldFormWrapper
    class="custom-field-form-address"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="custom-field-form-address__row">
      <BorderInputSelect
        label="Address type"
        :options="addressTypes"
        :value="$attrs.value.value.type"
        @input="
          $emit('input', {
            ...$attrs.value,
            value: { ...$attrs.value.value, type: $event.value },
          })
        "
      >
        <template #selected-value="{ value, options }">
          {{ options.find((option) => option.value === value).label }}
        </template>
        <template #option="{ option }">
          <span class="custom-field-form-address__type-icon">
            <CustomFieldAddressIcon :type="option.value" />
          </span>
          {{ option.label }}
        </template>
      </BorderInputSelect>
      <BorderInputText
        label="Address label"
        :value="$attrs.value.label"
        @input="$emit('input', { ...$attrs.value, label: $event })"
      />
    </div>
    <BorderInputText
      label="Street"
      :value="$attrs.value.value.street_address"
      @input="
        $emit('input', {
          ...$attrs.value,
          value: { ...$attrs.value.value, street_address: $event },
        })
      "
    />
    <div class="custom-field-form-address__row">
      <BorderInputText
        label="Apartment, suite"
        :value="$attrs.value.value.unit"
        @input="
          $emit('input', {
            ...$attrs.value,
            value: { ...$attrs.value.value, unit: $event },
          })
        "
      />
      <BorderInputText
        label="City"
        :value="$attrs.value.value.city"
        @input="
          $emit('input', {
            ...$attrs.value,
            value: { ...$attrs.value.value, city: $event },
          })
        "
      />
    </div>
    <div class="custom-field-form-address__row">
      <BorderInputSelect
        label="State"
        placeholder="Select"
        :options="StateList"
        :value="$attrs.value.value.state"
        @input="
          $emit('input', {
            ...$attrs.value,
            value: { ...$attrs.value.value, state: $event.value },
          })
        "
      >
        <template #selected-value="{ value, options }">
          {{ options.find((option) => option.value === value).label }}
        </template>
        <template #option="{ option }">
          {{ option.label }}
        </template>
      </BorderInputSelect>
      <BorderInputText
        label="Zip code"
        :value="$attrs.value.value.postal_code"
        @input="
          $emit('input', {
            ...$attrs.value,
            value: { ...$attrs.value.value, postal_code: $event },
          })
        "
      />
    </div>
    <BorderInputSelect
      label="Country"
      placeholder="Select"
      :options="countries()"
      :value="$attrs.value.value.country"
      @input="
        $emit('input', {
          ...$attrs.value,
          value: { ...$attrs.value.value, country: $event.name },
        })
      "
    >
      <template #selected-value="{ value, options }">
        {{ options.find((option) => option.name === value).name }}
      </template>
      <template #option="{ option }">
        {{ option.name }}
      </template>
    </BorderInputSelect>
    <CustomFieldFormInputIsSensitive
      :value="$attrs.value.isSecret"
      @input="$emit('input', { ...$attrs.value, isSecret: $event })"
    />
  </CustomFieldFormWrapper>
</template>

<style lang="scss">
.custom-field-form-address {
  &__row {
    display: flex;
    gap: 12px;

    & > * {
      flex: 1;
    }
  }

  &__type-icon {
    flex-shrink: 0;
    margin-right: 8px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .custom-field-address-icon {
      // fix color override
      color: $color-primary-50;
    }
  }
}
</style>
