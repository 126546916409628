<script setup>
import CloaksList from "@/routes/your-cloaks/CloaksList";
import { reactive, computed } from "vue";
import store from "@/store";
import NoIdentities from "@/routes/your-cloaks/NoIdentities.vue";

const PAGE_SIZE = 30;

const state = reactive({
  page: 0,
});

const cloaks = computed(() => {
  return store.state.localdb.db_cloaks;
});

function loadNextPage($state) {
  const newPage = state.page + 1;
  state.page = newPage;

  if ($state) {
    if (newPage * PAGE_SIZE >= allMutedCloaks.value.length) {
      $state.complete();
    } else {
      $state.loaded();
    }
  }
}

const allMutedCloaks = computed(() => {
  if (cloaks.value) {
    return cloaks.value.filter((cloak) => cloak.muted);
  }
  return [];
});

const identityList = computed(() => {
  const numToShow = state.page * PAGE_SIZE;
  return allMutedCloaks.value.slice(0, numToShow);
});
</script>

<template>
  <div class="container">
    <CloaksList
      ref="cloaklist"
      title="Ignored"
      :identityList="identityList"
      @loadNextPage="loadNextPage"
    >
      <template #no-results>
        <NoIdentities identity-type="ignored" />
      </template>
    </CloaksList>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin-top: 40px;
}
.empty-state {
  width: 100%;
  padding: 100px 20px 120px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.full {
    height: 100vh;
    padding: 0;
    position: absolute;
    top: 0;
    left: 240px;
    width: calc(100% - 240px);
  }

  .content {
    max-width: 634px;
    text-align: center;
    display: inline-block;

    h1 {
      font-weight: 300;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      color: $color-primary-100-60;
      margin-top: 20px;
    }
  }
}

.title {
  padding: 0 36px;
  margin-bottom: 7px;
  margin-top: 24px;

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: $color-primary-100;
  }
}
</style>
