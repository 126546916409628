var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "label",
    {
      staticClass: "border-input-text",
      class: { "border-input-text--error": _vm.errors.length },
    },
    [
      _vm.label
        ? _c("span", { staticClass: "border-input-text__label" }, [
            _vm._v(" " + _vm._s(_vm.label) + " "),
          ])
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "border-input-text__input-wrapper",
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
          },
        },
        [
          _c(
            "input",
            _vm._b(
              {
                ref: "input",
                staticClass: "border-input-text__input",
                attrs: { placeholder: _vm.placeholder },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input", $event.target.value)
                  },
                  change: function ($event) {
                    return _vm.$emit("change", $event.target.value)
                  },
                },
              },
              "input",
              _vm.$attrs,
              false
            )
          ),
          _c(
            "span",
            { staticClass: "border-input-text__input-after" },
            [_vm._t("after")],
            2
          ),
        ]
      ),
      _vm._l(_vm.errors, function (error) {
        return _vm._t(
          "error",
          function () {
            return [
              _c(_setup.InputValidationError, { key: error }, [
                _vm._v(" " + _vm._s(error) + " "),
              ]),
            ]
          },
          { error: error }
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }