var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesPanel",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("PreferencesHeader", { on: { "go-back": _vm.toggle_back } }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("PreferencesInput", {
        attrs: {
          label: "First name",
          placeholder: "Enter Your First Name",
          tabindex: "1",
        },
        on: { save: _vm.handleSave },
        model: {
          value: _vm.autofill_first_name,
          callback: function ($$v) {
            _vm.autofill_first_name = $$v
          },
          expression: "autofill_first_name",
        },
      }),
      _c("PreferencesInput", {
        attrs: {
          label: "Last name",
          placeholder: "Enter Your Last Name",
          tabindex: "2",
        },
        on: { save: _vm.handleSave },
        model: {
          value: _vm.autofill_last_name,
          callback: function ($$v) {
            _vm.autofill_last_name = $$v
          },
          expression: "autofill_last_name",
        },
      }),
      _c(
        "PreferencesFooter",
        [
          _c(
            "Button",
            {
              attrs: { disabled: _vm.loading, loading: _vm.loading },
              on: { click: _vm.handleSave },
            },
            [_vm._v("Save changes")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }