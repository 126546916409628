var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "message-header",
      on: {
        click: function ($event) {
          _vm.open = _vm.index
        },
      },
    },
    [
      _vm.message.email.inbound
        ? _c("div", { staticClass: "message-contact" }, [
            _c(
              "div",
              {
                staticClass: "message-icon click-pointer",
                on: {
                  click: function ($event) {
                    return _vm.goToCloak(_vm.thread.identity)
                  },
                },
              },
              [
                _c("ContactIcon", {
                  attrs: {
                    initials: _vm.getExternalContactInitialsDisplay(
                      _vm.message.email
                    ),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "message-info" },
              [
                _c("div", { staticClass: "message-from" }, [
                  _vm.message.email.sender_name
                    ? _c("div", [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.message.email.sender_name)),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.message.email.sender_email)),
                        ]),
                      ])
                    : _c("div", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.getAliasFromEmail(
                                _vm.message.email.sender_email
                              )
                            )
                          ),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.message.email.sender_email)),
                        ]),
                      ]),
                ]),
                _c("div", { staticClass: "message-to" }, [
                  _c("div", [
                    _c("p", [
                      _vm._v(" to "),
                      _c(
                        "strong",
                        {
                          staticClass: "click-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.goToCloak(_vm.thread.identity)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.recipients(_vm.message)))]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _vm.multiRecipientEnabled
                        ? _c("ArrowButton", {
                            attrs: {
                              onClick: _vm.toggleRecipientDetailsModal,
                              isOpen: _vm.isVisible,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.isVisible
                  ? _c("ActivityRecipientDetails", {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.hide,
                          expression: "hide",
                        },
                      ],
                      attrs: {
                        message: _vm.message,
                        nickname: _vm.thread.identity.nickname,
                      },
                      on: { close: _vm.hide },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _c("div", { staticClass: "message-contact" }, [
            _c("div", { staticClass: "message-icon" }, [
              _c(
                "div",
                {
                  staticClass: "user-icon click-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.goToCloak(_vm.thread.identity)
                    },
                  },
                },
                [_c("UserIcon")],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "message-info" },
              [
                _c("div", { staticClass: "message-from" }, [
                  _c(
                    "strong",
                    {
                      staticClass: "click-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.goToCloak(_vm.thread.identity)
                        },
                      },
                    },
                    [_vm._v("me")]
                  ),
                  _vm._v("  "),
                  _c(
                    "span",
                    {
                      staticClass: "click-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.goToCloak(_vm.thread.identity)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.message.email.sender_email))]
                  ),
                ]),
                _c("div", { staticClass: "message-to" }, [
                  _c("div", [
                    _c("p", [
                      _vm._v(" to "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.recipients(_vm.message))),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _vm.multiRecipientEnabled
                        ? _c("ArrowButton", {
                            attrs: {
                              onClick: _vm.toggleRecipientDetailsModal,
                              isOpen: _vm.isVisible,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.isVisible
                  ? _c("ActivityRecipientDetails", {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.hide,
                          expression: "hide",
                        },
                      ],
                      attrs: {
                        message: _vm.message,
                        nickname: _vm.thread.identity.nickname,
                      },
                      on: { close: _vm.hide },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
      _c(
        "div",
        { staticClass: "message-actions" },
        [
          _c(
            "UiMenu",
            {
              attrs: {
                width: "247px",
                "max-height": "300px",
                placement: "bottom-end",
                "z-index": 9,
              },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("UiMenuButton", {
                        attrs: {
                          title: _vm.message.email.read
                            ? "Mark as unread"
                            : "Mark as read",
                        },
                        on: {
                          click: function ($event) {
                            _vm.mark_read(_vm.message)
                            _vm.toggleShowForwardingEmail(false)
                          },
                          mouseover: function ($event) {
                            return _vm.toggleShowForwardingEmail(false)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [_c("Read")]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("UiMenuSeparator"),
                      _c("UiMenuButton", {
                        attrs: { title: "Reply" },
                        on: {
                          click: function ($event) {
                            _vm.replyTo(_vm.message, _vm.index)
                            _vm.toggleShowForwardingEmail(false)
                          },
                          mouseover: function ($event) {
                            return _vm.toggleShowForwardingEmail(false)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [_c("Reply")]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("UiMenuSeparator"),
                      _c("UiMenuButton", {
                        attrs: { title: _vm.forwardingOptionTitle },
                        on: {
                          mouseover: function ($event) {
                            _vm.verifiedEmails.length &&
                              _vm.toggleShowForwardingEmail(true)
                          },
                          mouseout: function ($event) {
                            _vm.verifiedEmails.length &&
                              _vm.toggleShowForwardingEmail(false)
                          },
                          click: function ($event) {
                            !_vm.verifiedEmails.length &&
                              _vm.handleAddNewEmail(_vm.message)
                            _vm.toggleShowForwardingEmail(false)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [_c("Forward")]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("UiMenuSeparator"),
                      _c(
                        "div",
                        { staticClass: "danger" },
                        [
                          _c("UiMenuButton", {
                            attrs: { title: "Delete", danger: "" },
                            on: {
                              click: function ($event) {
                                _vm.trip_delete(_vm.message)
                                _vm.toggleShowForwardingEmail(false)
                              },
                              mouseover: function ($event) {
                                return _vm.toggleShowForwardingEmail(false)
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "icon",
                                fn: function () {
                                  return [_c("Delete")]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "UiTooltip",
                { attrs: { title: "Options", "align-x": "center" } },
                [_c("span", { staticClass: "kabab" }, [_c("Kabob")], 1)]
              ),
            ],
            1
          ),
          _vm.showForwardingEmails
            ? _c(
                "div",
                {
                  staticClass: "fwd",
                  on: {
                    mouseover: function ($event) {
                      _vm.verifiedEmails.length &&
                        _vm.toggleShowForwardingEmail(true)
                    },
                    mouseleave: function ($event) {
                      _vm.verifiedEmails.length &&
                        _vm.toggleShowForwardingEmail(false)
                    },
                  },
                },
                [
                  _c(
                    "ul",
                    [
                      _vm._l(_vm.verifiedEmails, function (email) {
                        return _c("li", { key: email.email }, [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.forward_email(_vm.message, email)
                                  _vm.toggleShowForwardingEmail(false)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(email.email) + " ")]
                          ),
                        ])
                      }),
                      _c("UiMenuSeparator"),
                      _c("li", [
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.handleAddNewEmail(_vm.message)
                                _vm.toggleShowForwardingEmail(false)
                              },
                            },
                          },
                          [
                            _c("PlusIcon"),
                            _c("span", [_vm._v("Add a new email address")]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "time" }, [
            _vm._v(_vm._s(_vm.getTime(_vm.message.created_at))),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }