import {
  AutoChangeStatus,
  AutoChangeUpdateStatus,
  AutoChangeVerificationStatus,
  SupportedAutoChangeFieldTypes,
} from "@/components/auto-change/utils";

export const getFieldStatus = ({ change, verification, update } = {}) => {
  if (!change?.status) {
    return null;
  }

  const getChangeStatus = () => change?.status;
  const getUpdateStatus = () => update?.status;

  const changeStatus = getChangeStatus();
  const updateStatus = getUpdateStatus();

  const getVerificationStatus = () => {
    const newValueVerification = verification?.find(
      (item) => item.value === change?.newValue
    );

    const oldValueVerification = verification?.find(
      (item) => item.value === change?.oldValue
    );

    for (const status of [
      AutoChangeStatus.NEEDS_REVIEW,
      AutoChangeStatus.IN_PROGRESS,
      AutoChangeStatus.SUCCESS,
      AutoChangeStatus.SKIPPED,
      AutoChangeStatus.WAITING,
      AutoChangeStatus.ERROR,
    ]) {
      if (status === AutoChangeUpdateStatus.SUCCESS) {
        if (newValueVerification?.status === status) {
          return status;
        }
      } else {
        if (
          [newValueVerification?.status, oldValueVerification?.status].includes(
            status
          )
        ) {
          return status;
        }
      }
    }

    return null;
  };

  if (
    ![
      AutoChangeUpdateStatus.WAITING,
      AutoChangeUpdateStatus.NOT_REQUIRED,
    ].includes(updateStatus) &&
    updateStatus
  ) {
    return updateStatus;
  }

  const verificationStatus = getVerificationStatus();

  if (
    ![
      AutoChangeVerificationStatus.WAITING,
      AutoChangeVerificationStatus.NOT_REQUIRED,
    ].includes(verificationStatus) &&
    verificationStatus
  ) {
    return verificationStatus;
  }

  return changeStatus;
};

export const getIdentityStatus = ({ change, verification, update }) => {
  const fieldStatuses = SupportedAutoChangeFieldTypes.map((fieldType) =>
    getFieldStatus({
      change: change?.fields.find((field) => field.fieldType === fieldType),
      verification: verification?.fields.filter(
        (field) => field.fieldType === fieldType
      ),
      update: update?.fields.find((field) => field.fieldType === fieldType),
    })
  ).filter((status) => !!status);

  if (
    fieldStatuses.length &&
    fieldStatuses.every((status) => [AutoChangeStatus.WAITING].includes(status))
  ) {
    return AutoChangeStatus.WAITING;
  }

  for (const status of [
    AutoChangeStatus.NEEDS_REVIEW,
    AutoChangeStatus.IN_PROGRESS,
    AutoChangeStatus.WAITING,
    AutoChangeStatus.ERROR,
    AutoChangeStatus.SKIPPED,
    AutoChangeStatus.SUCCESS,
  ]) {
    if (fieldStatuses.some((fieldStatus) => fieldStatus === status)) {
      return status === AutoChangeStatus.WAITING
        ? AutoChangeStatus.IN_PROGRESS
        : status;
    }
  }

  return null;
};

export const getProcessStatus = ({ change, verification, update }) => {
  const allStatuses = [
    ...change.reduce((result, item) => [...result, ...item.fields], []),
    ...verification.reduce((result, item) => [...result, ...item.fields], []),
    ...update.reduce((result, item) => [...result, ...item.fields], []),
  ].map((item) => item.status);

  if (allStatuses.every((status) => status !== AutoChangeStatus.NEEDS_REVIEW)) {
    return AutoChangeStatus.IN_PROGRESS;
  }

  if (allStatuses.some((status) => status === AutoChangeStatus.IN_PROGRESS)) {
    return AutoChangeStatus.NEEDS_REVIEW;
  }

  return "blockedByReview";
};
