import api from "@/api/api";
import store from "@/store";

export default class ExtensionService {
  static async getChromeExtension(pluginId) {
    return api()
      .get(`chrome-extension://${pluginId}/static/css/browser-menu.css`)
      .then(() => {
        store.dispatch("browser/savePluginDetected");
      });
  }
}
