var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ValueDisplay", {
    staticClass: "preferences-radio",
    attrs: {
      label: _vm.label,
      value: _vm.subLabel,
      disabled: _vm.disabled,
      "dark-label": "",
      "light-value": "",
    },
    on: { click: _vm.handleClick },
    scopedSlots: _vm._u(
      [
        {
          key: "pre-field",
          fn: function () {
            return [_vm._t("pre-field")]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _vm.deletable
                ? _c(
                    "button",
                    {
                      staticClass:
                        "value-display__action-button value-display__action-button--delete",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleDelete.apply(null, arguments)
                        },
                      },
                    },
                    [_c("TrashOutline")],
                    1
                  )
                : _vm._e(),
              _c("RadioButton", {
                attrs: {
                  name: _vm.groupName,
                  checked: _vm.value === _vm.inputValue,
                  value: _vm.inputValue,
                  disabled: _vm.disabled,
                },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }