<script setup>
import _ from "lodash";
import { isEqual } from "lodash-es";

import {
  SectionList,
  SidebarCloseButton,
  SidebarHeader,
  InputSpinner,
} from "@/components";
import { url } from "@/scripts/validation";
import { standardizeUrl } from "@/scripts/format";
import WebService from "@/api/actions/web-service";
import IdentityService from "@/api/actions/identity-service";
import store from "@/store";

import {
  reactive,
  onMounted,
  nextTick,
  computed,
  watch,
  ref,
  defineEmits,
} from "vue";

let fetchWebSearchTimeout;

const staticSuggestionList = [
  "google.com",
  "amazon.com",
  "linkedin.com",
  "twitter.com",
  "facebook.com",
  "apple.com",
  "instagram.com",
  "jetblue.com",
  "bankofamerica.com",
  "calendly.com",
  "cvs.com",
  "meetup.com",
  "coinbase.com",
  "dropbox.com",
  "united.com",
  "zillow.com",
  "americanexpress.com",
  "hulu.com",
  "eventbrite.com",
  "ebay.com",
  "walgreens.com",
  "airbnb.com",
  "netflix.com",
  "adobe.com",
  "etsy.com",
  "tiktok.com",
  "aa.com",
  "epicgames.com",
  "venmo.com",
  "bestbuy.com",
];

const cloakNicknameRef = ref(null);

const emit = defineEmits(["done"]);

onMounted(() => {
  setTimeout(() => cloakNicknameRef.value.focus(), 300);
  if (!suggestions.value.length) {
    fetchBrandDataForStaticList();
  }
});

const state = reactive({
  nickname: "",
  results: [],
  searching: false,
  active: null,
});

const suggestions = computed(() => {
  return store.state.localdb.suggestions;
});

const listItems = computed(() => {
  let items = [];
  if (!state.nickname.length) {
    items = suggestions.value;
  } else if (state.results.length) {
    items = state.results;
  }

  if (state.nickname.length) {
    const customCloak = {
      title: `Create "${state.nickname}"`,
      nickname: state.nickname,
    };
    if (items.length && items[0].title && items[0].title.startsWith("Create")) {
      items[0] = customCloak;
    } else {
      items.unshift(customCloak);
    }
  }

  return items;
});

const sectionTitle = computed(() => {
  if (isEqual(listItems.value, suggestions.value)) {
    return "Suggested websites";
  }

  return "";
});

watch(
  () => state.nickname,
  (name) => {
    store.dispatch("updateTempCloak", { nickname: name });
    if (!name.length) {
      state.active = null;
    }
    searchWithTimeout(name);
  }
);

watch(
  () => state.active,
  (idx) => {
    // NOTE: delete this function if the "hover over to update temp cloak" is not wanted
    if (idx !== null) {
      const activeItem = { ...listItems.value[idx] };
      store.dispatch("updateTempCloak", activeItem);
    }
  }
);

function handleClosePanel() {
  store.dispatch("closeRightPanel");
}

function formatSuggestedCloak(brandData) {
  return {
    website_url: brandData.base_domain,
    logo_url: brandData.icon_image_url,
    nickname: brandData.name,
    color: brandData.primary_color,
    secondary_color: brandData.secondary_color,
    cloak_brand_color: brandData.cloak_brand_color,
  };
}

function loadIdentities(identities) {
  const suggestedWebsites = [];
  return new Promise((resolve) => {
    Promise.all(
      identities.map((baseDomain) => {
        return WebService.getWebsite(baseDomain).then(({ data }) => {
          suggestedWebsites.push(formatSuggestedCloak(data));
        });
      })
    ).then(() => {
      store.dispatch("updateSuggestions", suggestedWebsites);
      resolve();
    });
  });
}

async function loadChunk(chunks, index) {
  if (chunks[index]) {
    await loadIdentities(chunks[index]);
    loadChunk(chunks, index + 1);
  }
}

async function fetchBrandDataForStaticList() {
  const chunks = _.chunk(staticSuggestionList.sort(), 5);
  state.searching = true;
  await loadChunk(chunks, 0);
  state.searching = false;
}

function handleEnter() {
  if (state.active === null) {
    return searchWithTimeout(state.nickname);
  } else {
    return selectWebsite(listItems.value[state.active]);
  }
}

async function searchWebsites(query) {
  if (state.searching || !query) {
    return;
  }

  state.searching = true;
  state.active = 0;

  let strippedQuery;

  try {
    strippedQuery = new URL(query).host;
    strippedQuery = strippedQuery.replace("www.", "");
  } catch {
    strippedQuery = query.toLowerCase();
  }

  return WebService.searchWebsites(strippedQuery)
    .then(({ data }) => {
      if (data && data.results) {
        state.results = data.results.map((website) => ({
          ...website,
          logo_url: getImageFor(website),
          nickname: website.name,
          website_url: website.base_domain,
        }));
      } else {
        state.results = [];
      }

      if (!state.results.length) {
        return;
      }

      const exactMatch = state.results.findIndex(
        (item) => item.base_domain === strippedQuery
      );

      if (exactMatch >= 0) {
        const items = [...state.results];
        const item = items[exactMatch];

        items.splice(exactMatch, 1);
        state.results = [item, ...items];
        state.active = 1;
      }
    })
    .catch(() => {
      state.results = [];
    })
    .finally(() => {
      state.searching = false;
    });
}

function getImageFor(website) {
  return (
    website.logo_url ||
    website.icon_image_url ||
    website.logo_image_url ||
    website.logo_svg_url ||
    website.icon_svg_url
  );
}

function selectName(listItem) {
  let payload = {};
  if (listItem.nickname) {
    payload.nickname = listItem.nickname;
  }
  payload.website_url = "cloaked.app";
  createCloak(payload);
}

function selectWebsite(listItem) {
  let payload = {};
  if (listItem.website_url) {
    payload.app_ref = listItem.nickname;
    payload.nickname = listItem.nickname;
    payload.website_url = listItem.website_url;
    payload.category = "website";
  } else {
    return selectName(listItem);
  }
  createCloak(payload);
}

function createCloak(payload) {
  if (url(payload.nickname)) {
    const url = new URL(standardizeUrl(payload.nickname));
    payload.website_url = payload.nickname;
    payload.nickname = url.hostname;
  }

  window.Appcues?.track("Created new identity");
  window.Appcues?.page();

  IdentityService.createIdentity(payload).then(({ data }) => {
    emit("done", { ...data, replaceTempCloak: true });
    nextTick(reset);
  });
}

function reset() {
  state.nickname = "";
  state.results = [];
  state.searching = false;
  state.active = null;
}

function searchWithTimeout(query) {
  if (fetchWebSearchTimeout) {
    clearTimeout(fetchWebSearchTimeout);
  }
  fetchWebSearchTimeout = setTimeout(() => searchWebsites(query), 300);
}

function nav(direction, override) {
  if (!listItems.value.length) {
    state.active = null;
    return;
  }

  if (override) {
    state.active = direction;
    return;
  }

  const max = listItems.value.length;
  let active = state.active || 0;

  active += direction;

  if (active === -1) {
    active = max - 1;
  }

  state.active = active % max;
}
</script>

<template>
  <section class="cloak-detail-wrapper">
    <SidebarHeader>
      <SidebarCloseButton @click.native="handleClosePanel" />
    </SidebarHeader>

    <div class="nickname-row">
      <input
        aria-id="SearchOrAddInput"
        class="nickname-row__input"
        ref="cloakNicknameRef"
        type="text"
        placeholder="Add name or URL"
        v-model="state.nickname"
        @keydown.enter="handleEnter"
        autocomplete="off"
        maxlength="50"
        data-lpignore="true"
        :autofocus="true"
        @keyup.up="nav(-1)"
        @keyup.down="nav(1)"
      />
    </div>

    <div v-if="state.searching" class="cloak-detail-wrapper__loader">
      <InputSpinner />
    </div>

    <SectionList
      v-else
      :title="sectionTitle"
      :items="listItems"
      :active="state.active"
      @select="selectWebsite"
      @setActive="(idx) => nav(idx, true)"
    />
  </section>
</template>

<style lang="scss" scoped>
.cloak-detail-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__loader {
    width: 100%;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }
}

.nickname-row {
  padding: 18px 16px;

  &__input {
    width: 100%;
    height: 48px;
    border: none;
    font-weight: 300;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: $color-primary-100;
    background: $color-surface;
    caret-color: #007aff;

    &:active,
    &:focus-visible {
      outline: none;
    }
  }
}

.app--visible-banner {
  .cloak-detail-wrapper {
    height: calc(100vh - 40px);
  }
}
</style>
