<template>
  <img :src="attachment.url" />
</template>

<script>
export default {
  name: "image-attachment",
  props: ["attachment"],
};
</script>
<style lang="scss">
img {
  display: block;
  max-width: 140px;
  border-radius: 12px;
  background-color: $color-primary-0;
  padding: 10px;
  max-height: 110px;
}
</style>
