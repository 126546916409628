var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.cloak
    ? _c(
        "section",
        {
          key: `cloak-details-${_setup.cloak.id}`,
          staticClass: "cloak-details-wrapper",
        },
        [
          _c(
            _setup.SidebarHeader,
            [
              _c(
                _setup.SidebarHeaderButton,
                {
                  attrs: { noBackground: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _setup.handleClosePanel.apply(null, arguments)
                    },
                  },
                },
                [_c(_setup.DoubleChevronRight)],
                1
              ),
              _c(_setup.CloakActions, {
                attrs: { cloak: _setup.cloak },
                on: { refresh: _setup.refreshCloak },
              }),
            ],
            1
          ),
          _c(_setup.CloakNicknameSection, {
            attrs: { readOnly: _setup.isCancelled, cloak: _setup.cloak },
            on: { refresh: _setup.refreshCloak },
          }),
          _setup.isCancelled
            ? _c(
                "button",
                {
                  staticClass: "read-only",
                  on: { click: _setup.openSubscribeModal },
                },
                [_vm._v(" Your identities are read-only • Upgrade now ")]
              )
            : _vm._e(),
          _c(_setup.CloakCategorySection, {
            attrs: {
              readOnly: _setup.isCancelled,
              refreshing: _setup.state.loading,
              cloak: _setup.cloak,
            },
            on: { refresh: _setup.refreshCloak },
          }),
          _c(_setup.CloakWebSection, {
            key: `web-${_setup.cloak.id}`,
            attrs: {
              readOnly: _setup.isCancelled,
              refreshing: _setup.state.loading,
              cloak: _setup.cloak,
            },
            on: { refresh: _setup.refreshCloak },
          }),
          _c(_setup.SidebarSeparator),
          _c(_setup.CloakIdentifierSection, {
            attrs: {
              refreshing: _setup.state.loading,
              cloak: _setup.cloak,
              readOnly: _setup.isCancelled,
              showLimits: _setup.isTrial,
            },
            on: { refresh: _setup.refreshCloak },
          }),
          _c(_setup.SidebarSeparator),
          _c(_setup.CustomFieldsSection, {
            attrs: {
              readOnly: _setup.isCancelled,
              identity: _setup.cloak,
              items: _setup.customFields,
              "existing-addresses": _setup.existingAddresses,
            },
            on: { refresh: _setup.refreshCloak },
          }),
          _vm.$store.getters.isV2User ? _c(_setup.SidebarSeparator) : _vm._e(),
          _c(_setup.CloakContacts, {
            attrs: {
              identityId: _setup.cloak.id,
              readOnly: _setup.isCancelled,
            },
          }),
          _c(_setup.SidebarSeparator),
          _setup.cardsEnabled && _setup.kycValidated
            ? _c(_setup.CloakVirtualCards, {
                class: { disable: _setup.isCancelled },
              })
            : _vm._e(),
          _setup.cardsEnabled && _setup.kycValidated
            ? _c(_setup.SidebarSeparator)
            : _vm._e(),
          _c(_setup.CloakCommunicationSection, {
            attrs: {
              refreshing: _setup.state.loading,
              cloak: _setup.cloak,
              readOnly: _setup.isCancelled,
            },
            on: { refresh: _setup.refreshCloak },
          }),
          _c(_setup.SidebarSeparator),
          _c(
            _setup.CloakNotesSection,
            {
              attrs: { cloak: _setup.cloak, readOnly: _setup.isCancelled },
              on: { refresh: _setup.refreshCloak },
            },
            [
              _c("portal-target", {
                attrs: { name: "identity-detail", multiple: "" },
              }),
            ],
            1
          ),
          _c(_setup.SidebarSeparator),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }