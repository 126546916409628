var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { key: _vm.cloak.id, staticClass: "cloak-actions header-actions" },
    [
      _c(
        _setup.SidebarHeaderButton,
        {
          attrs: { "aria-": "", id: "GetActivityButton", wide: "" },
          nativeOn: {
            click: function ($event) {
              return _setup.handleActivityClick.apply(null, arguments)
            },
          },
        },
        [_c(_setup.ShelfDownArrow), _vm._v(" Inbox ")],
        1
      ),
      _c(
        _setup.UiTooltip,
        {
          attrs: {
            "aria-id": "MuteButtonWrapper",
            "align-x": "right",
            "max-width": "210",
            "can-show": _setup.cloakedPhone || _setup.cloakedEmail,
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _vm.cloak && _vm.cloak.muted
                    ? _c("div", [
                        _vm._v(" This cloak is "),
                        _c("strong", [_vm._v("Ignored")]),
                        _vm._v(
                          ", which means you’ll never receive notifications or forwarded activity from it "
                        ),
                      ])
                    : _c("div", [
                        _c("strong", [_vm._v("Ignore")]),
                        _vm._v(
                          " if you never want to receive notifications or forwarded email or phone activity "
                        ),
                      ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            _setup.SidebarHeaderButton,
            {
              attrs: {
                "aria-id": "MuteButton",
                active: _vm.cloak && _vm.cloak.muted,
                disabled: _vm.cloak && _vm.cloak.muted == null,
              },
              nativeOn: {
                click: function ($event) {
                  ;[_setup.toggleMute()]
                },
              },
            },
            [
              _vm.cloak && _vm.cloak.muted
                ? _c(_setup.MutedFilledIcon)
                : _c(_setup.Bell),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        _setup.UiTooltip,
        {
          attrs: {
            "aria-id": "ComposeButtonWrapper",
            "align-x": "right",
            "max-width": "210",
            "can-show": _setup.cloakedEmail,
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", [
                    _c("strong", [_vm._v("Compose")]),
                    _vm._v(" a new message with your Cloaked email address "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            _setup.SidebarHeaderButton,
            {
              attrs: {
                "aria-id": "ComposeButton",
                disabled: !_setup.cloakedEmail && !_setup.cloakedPhone,
              },
              nativeOn: {
                click: function ($event) {
                  return _setup.toggleCompose()
                },
              },
            },
            [_c(_setup.Compose)],
            1
          ),
        ],
        1
      ),
      _c(
        _setup.UiTooltip,
        {
          attrs: {
            "align-x": "right",
            "max-width": "210",
            "aria-id": "FavoriteButtonWrapper",
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", [
                    _c("strong", [_vm._v("Favorite")]),
                    _vm._v(
                      " the cloaks that you use most often in order to find them faster "
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            _setup.SidebarHeaderButton,
            {
              attrs: {
                "aria-id": "FavoriteButton",
                active: _vm.cloak.favorited,
              },
              nativeOn: {
                click: function ($event) {
                  return _setup.toggleFavorite.apply(null, arguments)
                },
              },
            },
            [_c(_setup.FavoriteIcon)],
            1
          ),
        ],
        1
      ),
      _vm.cloak && !_vm.cloak.protected
        ? _c(
            _setup.UiTooltip,
            {
              attrs: { "aria-id": "DeleteButtonWrapper" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c("div", [
                          _vm._v(" Delete "),
                          _vm.cloak.nickname
                            ? _c("span", [_vm._v(_vm._s(_vm.cloak.nickname))])
                            : _vm._e(),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                509628748
              ),
            },
            [
              _c(
                _setup.SidebarHeaderButton,
                {
                  attrs: { "aria-id": "DeleteButton" },
                  nativeOn: {
                    click: function ($event) {
                      return _setup.showDeleteModal()
                    },
                  },
                },
                [_c(_setup.TrashOutline)],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }