var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "cloak-nickname-section" },
    [
      _c(_setup.IdentityIcon, {
        attrs: {
          identity: _setup.props.cloak,
          override: { height: "40px", width: "40px" },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _setup.state.nickname,
            expression: "state.nickname",
          },
        ],
        ref: "cloakNickname",
        staticClass: "input-nickname",
        attrs: {
          "aria-id": "CloakNicknameInput",
          disabled: _setup.props.readOnly,
          type: "text",
          placeholder: "Add name or URL",
          title: _setup.state.nickname,
          autocomplete: "off",
          maxlength: "50",
          "data-lpignore": "true",
        },
        domProps: { value: _setup.state.nickname },
        on: {
          blur: _setup.save,
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _setup.save.apply(null, arguments)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_setup.state, "nickname", $event.target.value)
          },
        },
      }),
      _setup.hasIdentitySharingV1
        ? _c(
            _setup.IdentitySharingContainer,
            _vm._g(
              {
                staticClass: "cloak-nickname-section__sharing",
                attrs: { identity: _setup.props.cloak },
              },
              _vm.$listeners
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }