import Vue from "vue";

let modalId = 1;

export default {
  state: {
    visibleModals: {},
    modals: [],
  },

  mutations: {
    addModal(state, modal) {
      state.modals = [...state.modals, modal];
    },
    removeModal(state, { id }) {
      state.modals = state.modals.filter((modal) => modal.id !== id);
    },
    showModal(state, { id }) {
      Vue.set(state.visibleModals, id, true);
    },
    hideModal(state, { id }) {
      Vue.delete(state.visibleModals, id);
    },
    hideAllModals(state) {
      state.visibleModals = {};
    },
    removeAllModals(state) {
      state.modals = {};
    },
  },

  getters: {
    getModals: (store) => store.modals,
  },

  actions: {
    openGlobalDeleteModal(
      { dispatch },
      { header, type, paragraphs = [], onClick = () => {}, disabled = false }
    ) {
      let headerTitle = header || "Are you sure you want to delete this?";

      if (type && !header) {
        headerTitle = headerTitle.replace("?", ` ${type}?`);
      }

      dispatch("openModal", {
        header: headerTitle,
        paragraphs,
        showCancel: true,
        showCloseInHeader: false,
        button: {
          text: "Yes, delete",
          danger: true,
          onClick,
          disabled,
        },
      });
    },

    openModal({ commit, state }, modalInfo) {
      const { customTemplate } = modalInfo;

      if (customTemplate && customTemplate.template) {
        const found = state.modals.find((modal) => {
          if (!modal.customTemplate || !modal.customTemplate.template) {
            return false;
          }

          if (modal.customTemplate.template === customTemplate.template) {
            return true;
          }

          return false;
        });

        if (found) {
          return;
        }
      }

      const modal = {
        id: modalInfo.id || modalId++,
        visible: true,
        customTemplate,
        header: modalInfo.header,
        subheader: modalInfo.subheader,
        paragraphs: modalInfo.paragraphs,
        cancelText: modalInfo.cancelText,
        showCancel:
          modalInfo.showCancel === undefined ? true : modalInfo.showCancel,
        cancelAction: modalInfo.cancelAction,
        showCloseInHeader: !!modalInfo.showCloseInHeader,
        hideFooter: !!modalInfo.hideFooter,
        closeAfterOnClick: !!modalInfo.closeAfterOnClick,
        width: modalInfo.width,
        input: modalInfo.input,
        ...modalInfo,
      };

      if (modalInfo.button) {
        modal.button = {
          text: modalInfo.button.text,
          danger: !!modalInfo.button.danger,
          onClick: modalInfo.button.onClick,
          disabled: modalInfo.button.disabled,
        };
      }

      commit("addModal", modal);
      commit("showModal", modal);
    },

    closeAllModals({ commit }) {
      commit("hideAllModals");
      commit("removeAllModals");
    },

    closeModal({ commit, state }, { id } = {}) {
      let modalId = id;

      if (!modalId) {
        const lastModal = state.modals[state.modals.length - 1];

        if (lastModal) {
          modalId = lastModal.id;
        } else {
          return;
        }
      }

      commit("hideModal", { id: modalId });

      setTimeout(() => {
        commit("removeModal", { id: modalId });
      }, 500);
    },
  },
};
