import { autofill_decrypter } from "@/scripts/actions/encryption/intercepts/autofill";

export const transform = async (results, decrypt) => {
  const promises = results.map(async (row) => {
    if (row.stored_autofill) {
      row.stored_autofill = await autofill_decrypter(
        row.stored_autofill,
        decrypt
      );
    }
    return row;
  });
  return await Promise.all(promises);
};

export const read_identities = async (response, decrypt) => {
  if (response.data) {
    if (response.data.results) {
      response.data.results = await transform(response.data.results, decrypt);
    } else {
      const row = await transform([response.data], decrypt);
      response.data = row[0];
    }
    return response;
  } else {
    return response;
  }
};

export const read_create_identity = async (response, decrypt) => {
  if (response.data.stored_password) {
    const updated = await transform([response.data], decrypt);
    response.data = updated[0];
    return response;
  } else {
    return response;
  }
};
