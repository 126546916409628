<script setup>
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
</script>

<template>
  <AppModalParagraph class="identity-sharing-terms">
    Read full sharing
    <a
      href="https://www.cloaked.app/terms-of-service"
      target="_blank"
      class="identity-sharing-terms__link"
      >Terms and Conditions</a
    >.
  </AppModalParagraph>
</template>

<style lang="scss">
.identity-sharing-terms {
  &__link {
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
