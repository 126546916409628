var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ui-menu-input",
      class: {
        "ui-menu-input--focused": _vm.isFocused,
      },
    },
    [
      _c("input", {
        attrs: {
          type: "text",
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
        },
        domProps: { value: _vm.value },
        on: {
          input: _vm.handleInput,
          focus: _vm.handleFocus,
          blur: _vm.handleBlur,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }