var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        _setup.CustomFields,
        _vm._b(
          {
            attrs: {
              readOnly: _vm.readOnly,
              "is-loading": _setup.isLoading,
              "is-confirm-delete-modal-shown": _setup.isConfirmDeleteModalShown,
              "is-unsaved-changes-modal-shown":
                _setup.isUnsavedChangesModalShown,
            },
            on: {
              add: _setup.onAdd,
              edit: _setup.onEdit,
              delete: _setup.onDelete,
              "set-show-unsaved-changes-modal": function ($event) {
                _setup.isUnsavedChangesModalShown = $event
              },
              "set-show-confirm-delete-modal": function ($event) {
                _setup.isConfirmDeleteModalShown = $event
              },
            },
            model: {
              value: _setup.editedField,
              callback: function ($$v) {
                _setup.editedField = $$v
              },
              expression: "editedField",
            },
          },
          "CustomFields",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }