<script setup>
import SettingsChildPage from "./SettingsChildPage.vue";
import { SettingsTitle, SettingsParagraph } from "@/components";
import Privacy from "@/routes/modals/preferences/Privacy.vue";
</script>
<template>
  <SettingsChildPage class="privacy">
    <template v-slot:aside>
      <SettingsTitle>Privacy</SettingsTitle>
      <SettingsParagraph
        >Help Cloaked improve its products and services by allowing anonymized
        analytics about usage of the product.</SettingsParagraph
      >
    </template>

    <Privacy />
  </SettingsChildPage>
</template>
