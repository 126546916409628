var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      staticClass: "secret-toggle",
      on: {
        click: function ($event) {
          return _vm.$emit("input", !_vm.value)
        },
      },
    },
    [
      _vm.value
        ? _c(_setup.Eye, { staticClass: "secret-toggle__icon" })
        : _c(_setup.EyeSlash, { staticClass: "secret-toggle__icon" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }