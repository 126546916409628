var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    _vm._g(
      {
        on: {
          close: function ($event) {
            return _vm.$store.dispatch("onboarding/close")
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "AppModalContent",
        { staticClass: "onboarding-demo" },
        [
          _c("AppModalVideo", {
            attrs: {
              src: _vm.stepsConfiguration[_vm.stepIndex].videoSrc,
              autoplay: "",
              loop: "",
            },
          }),
          _c("AppModalTitle", [
            _vm.notInstalled
              ? _c(
                  "div",
                  {
                    staticClass: "install-extension-link",
                    on: { click: _vm.linkToDownload },
                  },
                  [_vm._v(" Install Extension ")]
                )
              : _vm._e(),
            _vm._v(
              " " + _vm._s(_vm.stepsConfiguration[_vm.stepIndex].title) + " "
            ),
          ]),
          _c("AppModalParagraph", [
            _vm._v(
              " " + _vm._s(_vm.stepsConfiguration[_vm.stepIndex].text) + " "
            ),
          ]),
          _c(
            "AppModalFooter",
            [
              _c(
                "div",
                { staticClass: "onboarding-demo__step" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.stepsConfiguration[_vm.stepIndex].step) +
                      " "
                  ),
                  _c("LineProgress", {
                    attrs: { progress: (_vm.stepIndex + 1) / 3 },
                  }),
                ],
                1
              ),
              _c("Button", { on: { click: _vm.onNextClicked } }, [
                _vm._v(" " + _vm._s(_vm.nextButtonLabel)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }