var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.AppTable, {
    ref: "table",
    staticClass: "auto-change-table",
    attrs: {
      columns: ["Identity", "Email address", "Password", "Username"],
      rows: _vm.items,
    },
    scopedSlots: _vm._u([
      {
        key: "head-row",
        fn: function ({ row, cellClass }) {
          return _vm._l(row, function (cell, index) {
            return _c(
              "label",
              {
                key: index,
                staticClass: "auto-change-table__head-cell",
                class: {
                  [cellClass]: true,
                  "auto-change-table__head-cell--clickable":
                    cell !== "Identity",
                  "auto-change-table__head-cell--disabled":
                    _setup.isAutoChangeInProgress ||
                    !_vm.items.filter((item) =>
                      item.fields.some(
                        (field) =>
                          field.fieldType ===
                            _setup.columnToFieldTypeMap[cell] &&
                          !!field.oldValue
                      )
                    ).length,
                },
              },
              [
                cell !== "Identity"
                  ? _c(_setup.InputToggleAll, {
                      attrs: {
                        disabled:
                          _setup.isAutoChangeInProgress ||
                          !_vm.items.filter((item) =>
                            item.fields.some(
                              (field) =>
                                field.fieldType ===
                                  _setup.columnToFieldTypeMap[cell] &&
                                !!field.oldValue
                            )
                          ).length,
                        values: _vm.items
                          .filter((item) =>
                            item.fields.some(
                              (field) =>
                                field.fieldType ===
                                  _setup.columnToFieldTypeMap[cell] &&
                                !!field.oldValue
                            )
                          )
                          .map((item) => item.identityId),
                        value: _vm.value
                          .filter((item) =>
                            item.fields.some(
                              (field) =>
                                field.fieldType ===
                                  _setup.columnToFieldTypeMap[cell] &&
                                !!field.oldValue
                            )
                          )
                          .map((item) => item.identityId),
                      },
                      on: {
                        input: function ($event) {
                          return _setup.onToggleFieldType(
                            _setup.columnToFieldTypeMap[cell],
                            $event
                          )
                        },
                      },
                    })
                  : _vm._e(),
                _c(
                  "span",
                  { staticClass: "auto-change-table__head-cell-text" },
                  [_vm._v(" " + _vm._s(cell) + " ")]
                ),
                cell === "Password"
                  ? _c(_setup.SecretToggle, {
                      staticClass: "auto-change-table__password-toggle",
                      model: {
                        value: _setup.hasHiddenPasswords,
                        callback: function ($$v) {
                          _setup.hasHiddenPasswords = $$v
                        },
                        expression: "hasHiddenPasswords",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          })
        },
      },
      {
        key: "body-row",
        fn: function ({
          row: {
            identityId,
            identityWebsite,
            identityNickname,
            identityLogo,
            identityColor,
            fields,
          },
          cellClass,
        }) {
          return [
            _c(_setup.AutoChangePageIdentity, {
              staticClass: "auto-change-table__identity",
              class: {
                [cellClass]: true,
                "auto-change-table__identity--in-progress":
                  _setup.isAutoChangeInProgress,
                "auto-change-table__identity--selected": _vm.value.find(
                  (item) => item.identityId === identityId
                )?.fields.length,
              },
              attrs: {
                website: identityWebsite,
                nickname: identityNickname,
                logo: identityLogo,
                color: identityColor,
              },
              on: {
                click: function ($event) {
                  _setup.isAutoChangeInProgress ||
                    _setup.onToggleIdentity(identityId)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "left",
                    fn: function () {
                      return [
                        _c(_setup.InputToggleAll, {
                          staticClass: "auto-change-table__identity-checkbox",
                          attrs: {
                            id: identityId,
                            disabled: _setup.isAutoChangeInProgress,
                            values:
                              _vm.items
                                .find((item) => item.identityId === identityId)
                                ?.fields.map((field) => field.fieldType) ?? [],
                            value:
                              _vm.value
                                .find((item) => item.identityId === identityId)
                                ?.fields.map((field) => field.fieldType) ?? [],
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
            _vm._l(_setup.SupportedAutoChangeFieldTypes, function (fieldType) {
              return _c(_setup.AutoChangePageTableCell, {
                key: fieldType,
                class: cellClass,
                attrs: {
                  id: fieldType,
                  isAutoChangeInProgress: _setup.isAutoChangeInProgress,
                  field: fields.find((field) => field.fieldType === fieldType),
                  verification: _vm.verification
                    .find((item) => item.identityId === identityId)
                    ?.fields.filter((field) => field.fieldType === fieldType),
                  update: _vm.updates
                    .find((item) => item.identityId === identityId)
                    ?.fields.find((field) => field.fieldType === fieldType),
                  "is-supported": !!_vm.support
                    .find((item) => identityWebsite?.endsWith(item.websiteUrl))
                    ?.fieldTypes?.includes(fieldType),
                  hidesSecretValues: _setup.hasHiddenPasswords,
                  disabled:
                    _setup.isAutoChangeInProgress ||
                    !fields.find((field) => field.fieldType === fieldType)
                      ?.oldValue,
                  value:
                    _vm.value
                      ?.find((item) => item.identityId === identityId)
                      ?.fields.map((field) => field.fieldType) ?? [],
                },
                on: {
                  input: (updatedFieldTypes) =>
                    _setup.onUpdateIdentity(identityId, updatedFieldTypes),
                  click: function ($event) {
                    return _vm.$emit("cell-click", {
                      identityId,
                      fieldType,
                    })
                  },
                },
              })
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }