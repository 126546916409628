<script setup>
import { computed } from "vue";
import Button from "./WalletSettingsButton";
import store from "@/store";
import router from "@/routes/router";

const primaryCard = computed(() => {
  return store.state.cards.primaryFundingSource;
});

const subtext = computed(() => {
  const pan = "**** " + primaryCard.value?.pan_last_four;
  const note = primaryCard.value?.note;

  if (pan && note) {
    return `${pan} • ${note}`;
  } else if (pan) {
    return pan;
  } else {
    return "";
  }
});

const loading = computed(() => {
  if (!primaryCard.value) {
    return true;
  } else {
    return false;
  }
});
</script>

<template>
  <Button
    :loading="loading"
    icon="bank"
    text="Default funding source"
    :title="primaryCard.card_brand"
    :subtext="subtext"
    clickable
    @click.native="router.push('/settings/cloaked-cards')"
  />
</template>
