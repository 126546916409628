var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.contactIsApproved
      ? _c("section", { staticClass: "activity-convo-input" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.message,
                expression: "message",
              },
            ],
            style: { height: _vm.height },
            attrs: { placeholder: "Text message" },
            domProps: { value: _vm.message },
            on: {
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  )
                    return null
                  $event.preventDefault()
                  return _vm.send.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  if (!$event.shiftKey) return null
                  if ($event.ctrlKey || $event.altKey || $event.metaKey)
                    return null
                  $event.preventDefault()
                  return _vm.addNewLine.apply(null, arguments)
                },
              ],
              input: function ($event) {
                if ($event.target.composing) return
                _vm.message = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "message_footer" }, [
            _vm.attachmentEnabled
              ? _c(
                  "div",
                  { staticClass: "file-section" },
                  [
                    _c("FileAttachments", {
                      ref: "fileAttachment",
                      attrs: {
                        endpoint: "/api/v1/cloaked/activity/upload_media_urls/",
                        filter: _vm.attachFilter,
                        disabled: _vm.files.length > 4,
                        error: _vm.errors.includes("files"),
                      },
                      on: { change: _vm.handleFileChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function () {
                              return [_c("ImageGallery")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1764144908
                      ),
                    }),
                    _c("FileList", {
                      attrs: {
                        files: _vm.files,
                        errors:
                          "Files must be a jpeg, png, pdf and must total less than 3.5 mb",
                      },
                      on: { remove: _vm.removeFile },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "button",
              {
                class: {
                  active:
                    (_vm.message.length > 0 || _vm.files.length > 0) &&
                    !_vm.sending,
                  disabled: _vm.sending,
                },
                on: { click: _vm.send },
              },
              [_c("SendIcon")],
              1
            ),
          ]),
        ])
      : _vm._e(),
    !_vm.contactIsApproved
      ? _c("div", { staticClass: "relative" }, [
          _vm.contactIsUnknown
            ? _c("section", { staticClass: "activity-approve-contact" }, [
                _c("div", [
                  _c("div", [
                    _c("h1", [
                      _vm._v(
                        _vm._s(_vm.contactPhone) + " is trying to contact you"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " Approve this contact if you want to reply to this message and "
                      ),
                      _c("br"),
                      _vm._v(
                        "allow " +
                          _vm._s(_vm.contactPhone) +
                          " to be able to message you from now on. "
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c(
                      "button",
                      {
                        class: { disabled: _vm.sending },
                        on: {
                          click: function ($event) {
                            return _vm.setStatus("block")
                          },
                        },
                      },
                      [_vm._v(" Block "), _c("Block")],
                      1
                    ),
                    _c(
                      "button",
                      {
                        class: { disabled: _vm.sending },
                        on: {
                          click: function ($event) {
                            return _vm.setStatus("approve")
                          },
                        },
                      },
                      [_vm._v(" Approve "), _c("Approve")],
                      1
                    ),
                  ]),
                ]),
              ])
            : !_vm.contactIsApproved
            ? _c("section", { staticClass: "activity-approve-contact" }, [
                _c("div", [
                  _c("div", [
                    _c("h1", [
                      _vm._v(_vm._s(_vm.contactPhone) + " is blocked"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Approve " +
                          _vm._s(_vm.contactPhone) +
                          " in order to reply"
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c(
                      "button",
                      {
                        class: { disabled: _vm.sending },
                        on: {
                          click: function ($event) {
                            return _vm.setStatus("approve")
                          },
                        },
                      },
                      [_vm._v(" Approve "), _c("Approve")],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }