<script setup>
import AppSurveyTypeform from "@/components/surveys/AppSurveyTypeform.vue";
</script>

<template>
  <AppSurveyTypeform
    form-id="sAPY1Gm7"
    type="light"
    :user-id="$store.state.authentication.user.id"
    :height="450"
    @close="$emit('close')"
    :value="true"
  />
</template>
