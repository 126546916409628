<template>
  <h3
    :class="[
      'preferences-title',
      {
        'preferences-title--big': big,
      },
    ]"
  >
    <slot />
  </h3>
</template>

<script>
export default {
  name: "PreferencesTitle",
  props: {
    big: Boolean,
  },
};
</script>

<style lang="scss">
.preferences-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: $color-primary-100;
  margin-bottom: 16px;

  &--big {
  }
}
</style>
