var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.MountEvent, { on: { mounted: _setup.onMount } }, [
    _setup.challenge
      ? _c("iframe", {
          key: _setup.path,
          ref: "keywindow",
          attrs: {
            id: _setup.props.id || _setup.path,
            src: _setup.src,
            allow: "clipboard-read; clipboard-write",
            frameborder: "0",
          },
        })
      : _vm._e(),
    !_setup.store.state.support
      ? _c(
          "button",
          { staticClass: "zendesk-button", on: { click: _setup.openZendek } },
          [
            !_setup.zendeskLoading
              ? _c("span", [
                  _vm._v(" Need Help "),
                  _c("span", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v("?"),
                  ]),
                  _vm._v(" "),
                ])
              : _c("span", [_vm._v("Loading")]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }