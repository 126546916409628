var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.UiTooltip,
    {
      attrs: {
        title: _setup.tooltipText,
        width: "192",
        "align-x": "center",
        position: "top",
        isOnSharedPage: _vm.isOnSharedPage,
      },
    },
    [
      _c("div", { staticClass: "timer" }, [
        _c(
          "span",
          {
            staticClass: "timer__label",
            class: {
              "timer__label--default": _vm.isOnSharedPage,
            },
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isOneTimeView
                    ? "This link can be accessed"
                    : "This link can be accessed for"
                ) +
                " "
            ),
          ]
        ),
        _c("div", { staticClass: "timer__timer" }, [
          _c("svg", { attrs: { viewBox: "0 0 40 40" } }, [
            _c(
              "g",
              {
                attrs: {
                  transform: "translate(20 20) rotate(-90)",
                  "stroke-width": "2",
                  fill: "none",
                },
              },
              [
                _c("circle", {
                  staticClass: "timer__circle-container",
                  class: {
                    "timer__circle-container--default": _vm.isOnSharedPage,
                  },
                  attrs: { r: "18" },
                }),
                _c("circle", {
                  staticClass: "timer__circle-fill",
                  class: {
                    "timer__circle-fill--default": _vm.isOnSharedPage,
                    "timer__circle-fill--yellow":
                      _setup.isCloseToExpiring && !_vm.isOneTimeView,
                    "timer__circle-fill--red":
                      _setup.isVeryCloseToExpiring && !_vm.isOneTimeView,
                  },
                  style: {
                    strokeDashoffset: _vm.isOneTimeView
                      ? 0
                      : 113 -
                        (113 * _setup.percentageOfTimePassed) / 100 +
                        "px",
                  },
                  attrs: { r: "18" },
                }),
              ]
            ),
            _c(
              "text",
              {
                staticClass: "timer__text",
                class: {
                  "timer__text--default": _vm.isOnSharedPage,
                  "timer__text--yellow":
                    _setup.isCloseToExpiring && !_vm.isOneTimeView,
                  "timer__text--red":
                    _setup.isVeryCloseToExpiring && !_vm.isOneTimeView,
                },
                attrs: { x: "20", y: "15" },
              },
              [_vm._v(" " + _vm._s(_setup.timerNumber) + " ")]
            ),
            _c(
              "text",
              {
                staticClass: "timer__text timer__unit",
                class: {
                  "timer__text--default": _vm.isOnSharedPage,
                  "timer__text--yellow":
                    _setup.isCloseToExpiring && !_vm.isOneTimeView,
                  "timer__text--red":
                    _setup.isVeryCloseToExpiring && !_vm.isOneTimeView,
                },
                attrs: { x: "20", y: "23" },
              },
              [_vm._v(" " + _vm._s(_setup.timerUnit) + " ")]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }