var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.AppTooltipIntro,
    {
      staticClass: "totp-identifier",
      attrs: {
        value: _vm.$attrs.isTotpIntroDisplayed,
        "has-click-open": false,
        "has-click-close": false,
        "has-outside-click-close": true,
      },
      on: {
        input: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.$emit("set-is-totp-intro-displayed", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "content",
          fn: function () {
            return [
              _vm._v(
                " Type or paste in your one-time passcode secret key or otpauth link."
              ),
              _c("br"),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("set-is-totp-intro-displayed", false)
                    },
                  },
                },
                [_vm._v(" Got it ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        _setup.CloakInfoRow,
        _vm._g(
          {
            attrs: {
              field: "one-time passcode",
              placeholder: "Add one-time passcode",
              fieldType: "totp",
              initialValue: _vm.$attrs.totp?.value,
              "copy-value": _vm.$attrs.totpToken,
              loading: _vm.$attrs.loading,
              error: !!_vm.$attrs.errors,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "input",
                  fn: function ({ isEditable }) {
                    return [
                      _vm.$attrs.totp?.value && !isEditable
                        ? _c(_setup.TOTPToken, {
                            attrs: {
                              url: _vm.$attrs.totp?.value.startsWith(
                                "otpauth://"
                              )
                                ? _vm.$attrs.totp?.value
                                : undefined,
                              secret: _vm.$attrs.totp?.value.startsWith(
                                "otpauth://"
                              )
                                ? undefined
                                : _vm.$attrs.totp?.value,
                            },
                            on: {
                              "new-token": function ($event) {
                                return _vm.$emit("set-totp-token", $event)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
                !_vm.$attrs.totp?.value
                  ? {
                      key: "tooltip",
                      fn: function () {
                        return [_vm._v(" Type a secret key or otpauth link ")]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }