var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.vcoConfig,
          expression: "vcoConfig",
        },
      ],
      ref: "menu",
      staticClass: "context-menu",
      class: { flipped: _vm.flipped },
      style: _vm.location,
      attrs: { "aria-id": "ContextMenu" },
      on: { click: (event) => event.stopPropagation() },
    },
    [
      _vm.count < 2
        ? _c("ul", [
            !_vm.selected
              ? _c("li", [
                  _c(
                    "button",
                    {
                      attrs: { "aria-id": "SelectContextMenu" },
                      on: {
                        click: function ($event) {
                          return _vm.emit("select")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "context-menu__icon" },
                          [_c("SelectIcon")],
                          1
                        ),
                        _c("span", [_vm._v("Select")]),
                      ]),
                      _c(
                        "span",
                        [_c("span", [_vm._v("hold")]), _c("CommandKeyIcon")],
                        1
                      ),
                    ]
                  ),
                ])
              : _c("li", [
                  _c(
                    "button",
                    {
                      attrs: { "aria-id": "UnselectContextMenu" },
                      on: {
                        click: function ($event) {
                          return _vm.emit("unselect")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "context-menu__icon" },
                          [_c("SelectIcon")],
                          1
                        ),
                        _c("span", [_vm._v("Unselect")]),
                      ]),
                      _c(
                        "span",
                        [_c("span", [_vm._v("hold")]), _c("CommandKeyIcon")],
                        1
                      ),
                    ]
                  ),
                ]),
            _c("li", [
              _c(
                "button",
                {
                  attrs: { "aria-id": "SelectAllContextMenu" },
                  on: {
                    click: function ($event) {
                      return _vm.emit("selectAll")
                    },
                  },
                },
                [
                  _c("span", [
                    _c(
                      "span",
                      { staticClass: "context-menu__icon" },
                      [_c("CheckListIcon")],
                      1
                    ),
                    _c("span", [_vm._v("Select All")]),
                  ]),
                  _c(
                    "span",
                    [
                      _c("CommandKeyIcon"),
                      _c("UpKeyIcon"),
                      _c("span", [_vm._v("A")]),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm.categories.length > 0
              ? _c(
                  "li",
                  {
                    on: {
                      mouseover: function ($event) {
                        return _vm.toggleCats(true)
                      },
                      mouseout: function ($event) {
                        return _vm.toggleCats(false)
                      },
                    },
                  },
                  [
                    _c(
                      "button",
                      { attrs: { "aria-id": "MoveToCategoryContextMenu" } },
                      [
                        _c("span", [
                          _c(
                            "span",
                            { staticClass: "context-menu__icon" },
                            [_c("MoveIcon")],
                            1
                          ),
                          _c("span", [_vm._v(" Move To")]),
                        ]),
                        _c(
                          "span",
                          [_c("span", [_vm._v("M")]), _c("ArrowKeyRightIcon")],
                          1
                        ),
                      ]
                    ),
                    _vm.showCats
                      ? _c(
                          "div",
                          {
                            staticClass: "cats",
                            class: { flipped: _vm.flipped_cats },
                            on: {
                              mouseover: function ($event) {
                                return _vm.toggleCats(true)
                              },
                            },
                          },
                          [
                            _c(
                              "ul",
                              _vm._l(_vm.categories, function (cat, index) {
                                return _c("li", { key: index }, [
                                  _c(
                                    "button",
                                    {
                                      attrs: {
                                        "aria-id": `Category.${cat.name}ContextMenu`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.move(cat)
                                        },
                                      },
                                    },
                                    [
                                      _vm.isInCategory(cat)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "context-menu__icon",
                                            },
                                            [_c("CheckIcon")],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(cat.name) + " "),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.can_mute && !_vm.is_muted
              ? _c("li", [
                  _c(
                    "button",
                    {
                      attrs: { "aria-id": "MuteContextMenu" },
                      on: {
                        click: function ($event) {
                          return _vm.emit("ignore")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "context-menu__icon" },
                          [_c("MutedContextIcon")],
                          1
                        ),
                        _c("span", [_vm._v("Ignore")]),
                      ]),
                      _c(
                        "span",
                        [_c("CommandKeyIcon"), _c("span", [_vm._v("M")])],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.can_mute && _vm.is_muted
              ? _c("li", [
                  _c(
                    "button",
                    {
                      attrs: { "aria-id": "MuteContextMenu" },
                      on: {
                        click: function ($event) {
                          return _vm.emit("unignore")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "context-menu__icon" },
                          [_c("UnmuteIcon")],
                          1
                        ),
                        _c("span", [_vm._v("Unignore")]),
                      ]),
                      _c(
                        "span",
                        [_c("CommandKeyIcon"), _c("span", [_vm._v("M")])],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._m(0),
            _vm.email
              ? _c("li", [
                  _c(
                    "button",
                    {
                      attrs: { "aria-id": "CopyEmailContextMenu" },
                      on: { click: (e) => _vm.copy("email", _vm.email, e) },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "context-menu__icon" },
                          [_c("CopyIcon")],
                          1
                        ),
                        _c("span", [_vm._v("Copy Email Address")]),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.phone
              ? _c("li", [
                  _c(
                    "button",
                    {
                      attrs: { "aria-id": "CopyPhoneContextMenu" },
                      on: { click: (e) => _vm.copy("phone", _vm.phone, e) },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "context-menu__icon" },
                          [_c("CopyIcon")],
                          1
                        ),
                        _c("span", [_vm._v("Copy Phone Number")]),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.password
              ? _c("li", [
                  _c(
                    "button",
                    {
                      on: {
                        click: (e) => _vm.copy("password", _vm.password, e),
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "context-menu__icon" },
                          [_c("CopyIcon")],
                          1
                        ),
                        _c("span", [_vm._v("Copy Password")]),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.username
              ? _c("li", [
                  _c(
                    "button",
                    {
                      attrs: { "aria-id": "CopyUsernameContextMenu" },
                      on: {
                        click: (e) => _vm.copy("username", _vm.username, e),
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "context-menu__icon" },
                          [_c("CopyIcon")],
                          1
                        ),
                        _c("span", [_vm._v("Copy Username")]),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._m(1),
            _vm.cloaked_email
              ? _c("li", [
                  _c(
                    "button",
                    {
                      attrs: { "aria-id": "EmailContactContextMenu" },
                      on: {
                        click: function ($event) {
                          return _vm.compose("email")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "context-menu__icon" },
                          [_c("EmailOutlineIcon")],
                          1
                        ),
                        _c("span", [_vm._v("Email Contact")]),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._m(2),
            _c("li", [
              _c(
                "button",
                {
                  attrs: { "aria-id": "DeleteContextMenu" },
                  on: {
                    click: function ($event) {
                      return _vm.emit("delete")
                    },
                  },
                },
                [
                  _c("span", [
                    _c(
                      "span",
                      { staticClass: "context-menu__icon" },
                      [
                        _c("DeleteActionBarIcon", {
                          staticClass: "delete-icon",
                        }),
                      ],
                      1
                    ),
                    _c("span", [_vm._v("Delete")]),
                  ]),
                ]
              ),
            ]),
          ])
        : _c("ul", [
            _vm.selected
              ? _c("li", [
                  _c(
                    "button",
                    {
                      attrs: { "aria-id": "UnselectContextMenu" },
                      on: {
                        click: function ($event) {
                          return _vm.emit("unselect")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "context-menu__icon" },
                          [_c("SelectIcon")],
                          1
                        ),
                        _c("span", [_vm._v("Unselect")]),
                      ]),
                      _c(
                        "span",
                        [_c("span", [_vm._v("hold")]), _c("CommandKeyIcon")],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("li", [
              _c(
                "button",
                {
                  attrs: { "aria-id": "SelectAllContextMenu" },
                  on: {
                    click: function ($event) {
                      return _vm.emit("selectAll")
                    },
                  },
                },
                [
                  _c("span", [
                    _c(
                      "span",
                      { staticClass: "context-menu__icon" },
                      [_c("CheckListIcon")],
                      1
                    ),
                    _c("span", [_vm._v("Select All")]),
                  ]),
                  _c(
                    "span",
                    [_c("span", [_vm._v("hold")]), _c("CommandKeyIcon")],
                    1
                  ),
                ]
              ),
            ]),
            _vm.count > 1
              ? _c("li", [
                  _c(
                    "button",
                    {
                      attrs: { "aria-id": "UnselectAllContextMenu" },
                      on: {
                        click: function ($event) {
                          return _vm.emit("unselectAll")
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c(
                          "span",
                          { staticClass: "context-menu__icon" },
                          [_c("CheckListIcon")],
                          1
                        ),
                        _c("span", [_vm._v("Unselect All")]),
                      ]),
                      _c(
                        "span",
                        [
                          _c("CommandKeyIcon"),
                          _c("UpKeyIcon"),
                          _c("span", [_vm._v("A")]),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.categories.length > 0
              ? _c(
                  "li",
                  {
                    on: {
                      mouseover: function ($event) {
                        return _vm.toggleCats(true)
                      },
                      mouseout: function ($event) {
                        return _vm.toggleCats(false)
                      },
                    },
                  },
                  [
                    _c(
                      "button",
                      { attrs: { "aria-id": "MoveToCategoryContextMenu" } },
                      [
                        _c("span", [
                          _c(
                            "span",
                            { staticClass: "context-menu__icon" },
                            [_c("MoveIcon")],
                            1
                          ),
                          _c("span", [_vm._v(" Move To")]),
                        ]),
                        _c(
                          "span",
                          [_c("span", [_vm._v("M")]), _c("ArrowKeyRightIcon")],
                          1
                        ),
                      ]
                    ),
                    _vm.showCats
                      ? _c(
                          "div",
                          {
                            staticClass: "cats",
                            class: { flipped: _vm.flipped_cats },
                            on: {
                              mouseover: function ($event) {
                                return _vm.toggleCats(true)
                              },
                            },
                          },
                          [
                            _c(
                              "ul",
                              _vm._l(_vm.categories, function (cat, index) {
                                return _c("li", { key: index }, [
                                  _c(
                                    "button",
                                    {
                                      attrs: {
                                        "aria-id": `Category.${cat.name}ContextMenu`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.move(cat)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(cat.name) + " ")]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _c("li", [
              _c(
                "button",
                {
                  attrs: { "aria-id": "MuteContextMenu" },
                  on: {
                    click: function ($event) {
                      _vm.$emit(`${_vm.ignoreDisplay.toLowerCase()}`)
                    },
                  },
                },
                [
                  _c("span", [
                    _c(
                      "span",
                      { staticClass: "context-menu__icon" },
                      [
                        _vm.ignoreDisplay.toLowerCase() === "ignore"
                          ? _c("MutedContextIcon")
                          : _c("UnmuteIcon"),
                      ],
                      1
                    ),
                    _c("span", [_vm._v(_vm._s(_vm.ignoreDisplay))]),
                  ]),
                  _c(
                    "span",
                    [_c("CommandKeyIcon"), _c("span", [_vm._v("M")])],
                    1
                  ),
                ]
              ),
            ]),
            _vm._m(3),
            _c("li", [
              _c(
                "button",
                {
                  attrs: { "aria-id": "DeleteConextMenu" },
                  on: {
                    click: function ($event) {
                      return _vm.emit("delete")
                    },
                  },
                },
                [
                  _c("span", [
                    _c(
                      "span",
                      { staticClass: "context-menu__icon" },
                      [_c("DeleteActionBarIcon")],
                      1
                    ),
                    _c("span", [_vm._v("Delete")]),
                  ]),
                ]
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "hr" }, [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "hr" }, [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "hr" }, [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "hr" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }