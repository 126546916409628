<template>
  <div class="activity-call-display-voicemail">
    <div class="vm-play">
      <button
        v-if="playing && playing.id === recording.id"
        class="playing"
        @click="pauseVoicemail(recording)"
      >
        <ActivityPauseIcon />
      </button>
      <button v-else @click="playVoicemail(recording)">
        <PlayVoicemailIconDark />
      </button>
      <span>Play Voicemail</span>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import { ActivityPauseIcon, PlayVoicemailIconDark } from "@/assets/icons";
export default {
  name: "VoicePlayIcon",
  props: {
    recording: Object,
    idx: Number,
  },
  components: {
    ActivityPauseIcon,
    PlayVoicemailIconDark,
  },
  computed: {
    playing() {
      return this.$store.state.media.playing;
    },
  },
  beforeMount() {
    this.$store.dispatch("media/register", {
      recording: this.recording,
      id: `${this.idx}-pi`,
    });
  },
  beforeDestroy() {
    this.$store.dispatch("media/unregister", {
      recording: this.recording,
      id: `${this.idx}-pi`,
    });
  },
  methods: {
    durationDisplay(voicemail) {
      const duration = moment.duration(voicemail.duration);
      if (this.playing && this.playing.id === voicemail.id) {
        const played = this.$store.state.media.progress;
        duration.subtract(played, "milliseconds");
      }
      const min = duration.minutes().toString().padStart(2, "0");
      const sec = duration.seconds().toString().padStart(2, "0");
      return `${min}:${sec}`;
    },
    pauseVoicemail(recording) {
      this.$store.dispatch("media/playAudio", recording);
    },
    playVoicemail(recording) {
      this.$store.dispatch("media/playAudio", recording);
    },
    resetVoicemailData() {
      this.audioState = null;
      this.activeRecordingId = null;
      this.activeVoicemail = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-call-display-voicemail {
  .vm-play {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    font-size: 11px;
    button {
      padding: 5px 0 0 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
      &.playing {
        svg {
          rect {
            filter: invert(100%);
          }
        }
      }
    }
  }
}
</style>
