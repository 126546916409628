var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._g(
      {
        staticClass: "totp-token",
        class: { "totp-token--expiring": _setup.isExpiring },
        on: {
          mouseenter: function ($event) {
            _setup.isHovered = true
          },
          mouseleave: function ($event) {
            _setup.isHovered = false
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "totp-token__token" },
        [
          _c(
            _setup.SecretValue,
            {
              attrs: {
                "is-hidden": _vm.isHidden ? !_setup.isHovered : false,
                "obfuscated-value": "••••••",
              },
            },
            [_vm._v(" " + _vm._s(_vm.token) + " ")]
          ),
        ],
        1
      ),
      _c(_setup.TOTPTokenProgress, { attrs: { progress: _vm.progress } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }