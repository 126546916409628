var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.Button,
    {
      attrs: {
        loading: _setup.loading,
        icon: "person-circle",
        text: "Available spending limit",
        title: _setup.title,
      },
    },
    [
      _c("div", { staticClass: "progress-bar" }, [
        _c("div", {
          key: _setup.currentCard.id,
          staticClass: "progress",
          style: { width: _setup.remainingPercent + "%" },
          attrs: { "data-percent": "remainingPercent" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }