<template>
  <PreferencesPanel>
    <template v-slot:header>
      <PreferencesHeader @go-back="$emit('toggleBack', $event)" />
    </template>

    <PreferencesTitle>Birthday</PreferencesTitle>
    <PreferencesInput
      label="Birthday (YYYY-MM-DD)"
      type="date"
      v-model="value"
      :error="error"
      :error-message="errorMessage"
      @save="handleSave"
    />

    <PreferencesFooter>
      <Button :disabled="loading" :loading="loading" @click="handleSave"
        >Save changes</Button
      >
    </PreferencesFooter>
  </PreferencesPanel>
</template>

<script>
import {
  PreferencesHeader,
  PreferencesPanel,
  PreferencesFooter,
  PreferencesTitle,
  PreferencesInput,
} from "@/routes/modals/preferences";

import PersonalInfoService from "@/api/settings/personal-services";
import { Button } from "@/components";
import moment from "moment";
import { date } from "@/components/cloakDetails/CustomFields/CustomFieldForm/validations";

export default {
  components: {
    PreferencesHeader,
    PreferencesPanel,
    PreferencesFooter,
    PreferencesTitle,
    PreferencesInput,
    Button,
  },
  props: ["id", "birthday"],
  mounted() {
    window.document.title = "Birthday Preferences • Cloaked";
    this.value = this.birthday
      ? moment(this.birthday).format("YYYY-MM-DD")
      : "";
  },
  data() {
    return {
      value: this.birthday,
      error: false,
      errorMessage: "",
      loading: false,
    };
  },
  methods: {
    async handleSave() {
      this.errorMessage = date(this.value)?.[0] ?? null;
      this.error = !!this.errorMessage;

      if (this.error) {
        return;
      }

      try {
        this.loading = true;

        let payload = {
          autofill_dob: moment(this.value).utc().format(),
        };

        if (this.id) {
          await PersonalInfoService.updateInfo(this.id, payload);
        } else {
          await PersonalInfoService.createInfo(payload);
        }

        this.$toast.success("Birthday saved.");
        this.$emit("toggleBack", {
          info_birthday: this.value,
        });
      } catch {
        this.$toast.error("Error saving your birthday.");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
