<template>
  <div class="details-modal">
    <div class="modal-header">
      <h3>Message details</h3>
    </div>

    <div class="modal-content">
      <VerticalTable :tableContent="tableDisplayInfo" />
    </div>

    <div class="modal-message-wrapper">
      <div class="modal-message">
        <div class="modal-img-wrap">
          <CloakedColorLogo />
        </div>
        <div>This message is secured by Cloaked two-way routing.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { CloakedColorLogo } from "@/assets/icons";
import { formatFullDayDateTime } from "@/scripts/timestamp_format";
import { VerticalTable } from "@/components";

export default {
  name: "ActivityRecipientDetails",
  props: ["message"],
  mixins: [],
  components: {
    VerticalTable,
    CloakedColorLogo,
  },
  computed: {
    tableDisplayInfo() {
      const senderName = !this.message.email.inbound
        ? "me"
        : this.message.email.sender_name;
      const fromContent = senderName
        ? {
            title: senderName,
            subtitle: this.message.email.sender_email,
          }
        : { title: this.message.email.sender_email };

      const toContent = this.message.email.to_recipients?.map((recipient) =>
        recipient.name
          ? {
              title: recipient.name,
              subtitle: recipient.email,
            }
          : { title: recipient.email }
      );

      const ccContent = this.message.email.cc_recipients?.map((recipient) =>
        recipient.name
          ? {
              title: recipient.name,
              subtitle: recipient.email,
            }
          : { title: recipient.email }
      );

      const fromRow = {
        header: "From",
        content: [fromContent],
      };
      const toRow = {
        header: "To",
        content: [...toContent],
      };
      const ccRow = {
        header: "CC",
        content: [...ccContent],
      };
      const dateRow = {
        header: "Date",
        content: [{ title: formatFullDayDateTime(this.message.created_at) }],
      };
      const tableData = [fromRow, toRow, dateRow];
      if (this.message.email.cc_recipients?.length > 0) {
        tableData.splice(2, 0, ccRow);
      }
      return tableData;
    },
  },
};
</script>

<style scoped lang="scss">
.details-modal {
  position: absolute;
  width: 450px;
  max-height: calc(100vh - 300px);
  z-index: 1;
  background: $color-background;
  box-shadow: -3.63315px -3.63315px 10.8995px rgba(0, 0, 0, 0.04),
    5.44973px 5.44973px 20.9px rgba(1, 2, 24, 0.12);
  border-radius: 28px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  .modal-header {
    border-bottom: 0.5px solid $color-primary-10;
    padding: 0px 0px 16px;

    h3 {
      font-weight: 500;
      font-size: 15px;
      color: $color-primary-100;
    }
  }
  .modal-content {
    max-height: calc(100% - 300px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-track {
      background: $color-primary-5;
    }
  }
  .modal-message-wrapper {
    padding-top: 24px;
    text-align: center;
    margin: 0 auto;
    display: flex;
  }
  .modal-message {
    display: flex;
    padding: 8px;
    border: 1px solid $color-primary-10;
    border-radius: 16px;
    justify-content: space-between;
    font-weight: 500;
    font-size: 10px;
    color: $color-primary-100;
  }
  .modal-img-wrap {
    padding-right: 8px;
  }
}
</style>
