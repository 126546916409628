<script setup>
import SettingsChildPage from "./SettingsChildPage.vue";
import { SettingsTitle, SettingsParagraph } from "@/components";
import { Subscription } from "@/routes/modals/preferences";
</script>
<template>
  <SettingsChildPage class="settings-account-page">
    <template v-slot:aside>
      <SettingsTitle>Subscription</SettingsTitle>
      <SettingsParagraph>Manage your subscription settings.</SettingsParagraph>
    </template>

    <Subscription />
  </SettingsChildPage>
</template>
