<script setup>
import UiSwitchInput from "@/components/UiSwitchInput.vue";
import { defineProps } from "vue";
import InputValidationError from "@/components/InputValidationError.vue";

defineProps({
  title: {
    type: String,
    default: "",
  },
  text: {
    type: String,
    default: "",
  },
  errors: {
    type: Array,
    default: () => [],
  },
});
</script>

<template>
  <div
    class="border-input-switch"
    :class="{ 'border-input-switch--error': errors.length }"
  >
    <label
      class="border-input-switch__label"
      tabindex="0"
      @keydown.enter="$emit('input', !$attrs.value)"
    >
      <span>
        <span class="border-input-switch__title" v-if="title">{{ title }}</span>
        <span class="border-input-switch__subtitle" v-if="text">
          {{ text }}
        </span>
      </span>
      <UiSwitchInput
        class="border-input-switch__input"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </label>
    <slot name="error" v-for="error in errors" :error="error">
      <InputValidationError :key="error">
        {{ error }}
      </InputValidationError>
    </slot>
  </div>
</template>

<style lang="scss">
.border-input-switch {
  &__label {
    display: flex;
    padding: 20px;
    border: 1px solid $color-primary-10;
    border-radius: 15px;
    cursor: pointer;

    &:focus {
      // reconsider
      outline: 1px solid $color-primary-100;
    }

    &:active {
      outline: none;
    }

    @at-root .border-input-switch--error & {
      border: 1px solid $color-alert;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: $color-primary-100;
    display: block;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $color-primary-70;
    margin-top: 8px;
    display: block;
  }

  &__input {
    margin-left: auto;
    flex-shrink: 0;
  }
}
</style>
