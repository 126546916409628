var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "auto-change-terms-of-use",
      attrs: {
        href: "https://www.cloaked.app/terms-of-service",
        target: "_blank",
      },
    },
    [_vm._v(" AutoCloak Terms of Use ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }