var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showNewMenu
    ? _c(
        "PreferencesPanel",
        [
          !_vm.primaryEmail
            ? _c("ValueDisplay", {
                attrs: { label: "Add a recovery email" },
                on: { add: _vm.handleAddEmail, click: _vm.handleAddEmail },
              })
            : _c("ValueDisplay", {
                attrs: {
                  label: "Recovery email",
                  value: _vm.primaryEmail.email,
                },
                on: {
                  edit: _vm.handleAddEmail,
                  delete: function ($event) {
                    return _vm.handleDelete(_vm.primaryEmail.id)
                  },
                },
              }),
        ],
        1
      )
    : _c(
        "PreferencesPanel",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("PreferencesHeader", {
                    on: { "go-back": _vm.toggle_back },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("PreferencesTitle", [_vm._v("Recovery email address")]),
          !_vm.primaryEmail
            ? _c("PreferencesParagraph", [
                _vm._v(
                  " Add a recovery email address to ensure you can access your Cloaked account. "
                ),
              ])
            : _vm.primaryEmail.email
            ? _c("ValueDisplay", {
                attrs: {
                  label: "Recovery Email",
                  value: _vm.primaryEmail.email,
                },
                on: {
                  delete: function ($event) {
                    return _vm.handleDelete(_vm.primaryEmail.id)
                  },
                },
              })
            : _vm._e(),
          _c("Button", { on: { click: _vm.handleAddEmail } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.primaryEmail
                    ? "Use a different email address"
                    : "Add an email address"
                ) +
                " "
            ),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }