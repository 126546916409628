var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.props.value
    ? _c(
        "portal",
        { attrs: { to: "modals" } },
        [
          _c(
            "transition",
            {
              attrs: {
                name: "app-modal",
                appear: "",
                duration: { enter: 650, leave: 450 },
              },
            },
            [
              _c("div", { staticClass: "app-modal" }, [
                _c("div", {
                  staticClass: "app-modal__background",
                  class: {
                    "app-modal__background--without-overlay":
                      _setup.props.withoutOverlay,
                  },
                  on: { click: _setup.onClose },
                }),
                _c(
                  "div",
                  { staticClass: "app-modal__content" },
                  [_vm._t("default")],
                  2
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }