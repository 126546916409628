var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "page" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "list" },
      _vm._l(_setup.cardList, function (card) {
        return _c("div", { key: card.id, staticClass: "item" }, [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c("div", { staticClass: "available" }, [
                _c("svg", { attrs: { viewBox: "0 0 36 36" } }, [
                  _c("path", {
                    attrs: {
                      d: "M18 2.0845\n                a 15.9155 15.9155 0 0 1 0 31.831\n                a 15.9155 15.9155 0 0 1 0 -31.831",
                      fill: "none",
                      stroke: "currentColor",
                      "stroke-width": "3",
                      "stroke-dasharray": `${
                        (card.transaction_period_limit / card.spent_period) *
                        100
                      }, 100`,
                    },
                  }),
                ]),
                _c("div", { staticClass: "text" }, [
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _setup.convert_dollars(
                            card.transaction_period_limit - card.spent_period
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "title" }, [_vm._v("available")]),
                ]),
              ]),
              _c("div", { staticClass: "price" }, [
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _setup.convert_dollars(card.transaction_period_limit)
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(card.type)),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "view",
                  on: {
                    click: function ($event) {
                      return _setup.openCard(card)
                    },
                  },
                },
                [_c("span", [_vm._v("View card info")])]
              ),
              _c(_setup.MastercardIcon, { staticClass: "mastercard" }),
            ],
            1
          ),
          _c("div", { staticClass: "identity" }, [
            _setup.identity(card.identity_id)
              ? _c("div", {
                  staticClass: "icon",
                  style: {
                    backgroundImage: `url(${
                      _setup.identity(card.identity_id).logo_url
                    })`,
                  },
                })
              : _c("div", { staticClass: "icon" }),
            _c("div", { staticClass: "text" }, [
              _c("div", { staticClass: "name" }, [
                _setup.identity(card.identity_id)
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_setup.identity(card.identity_id).nickname)
                      ),
                    ])
                  : _c("span", [_vm._v("Deleted identity")]),
              ]),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("h1", [_vm._v("All Cloaked cards")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }