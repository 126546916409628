var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "cards", class: { loaded: !_setup.loading } },
    [
      _vm._m(0),
      _c("div", { staticClass: "list-details" }, [
        !_setup.loading
          ? _c(
              "div",
              { staticClass: "list" },
              _vm._l(_setup.cards.results, function (card) {
                return _c(_setup.CardSingle, {
                  key: card.id,
                  attrs: { card: card },
                })
              }),
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "loader" },
          [_c(_setup.InlineSvg, { attrs: { name: "loading-small" } })],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("h1", [_vm._v("Cloaked cards")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }