var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.state.isVisible
    ? _c(
        _setup.UiBanner,
        { staticClass: "confirm-banner", attrs: { danger: "" } },
        [
          _setup.state.email
            ? _c(
                "div",
                [
                  _c("div", [
                    _vm._v(
                      "Please verify your recovery email " +
                        _vm._s(_setup.state.email.email)
                    ),
                  ]),
                  _c(
                    _setup.Button,
                    { attrs: { type: "danger" }, on: { click: _setup.open } },
                    [_vm._v("Verify Email")]
                  ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("div", [
                    _vm._v("Add recovery email to keep your account safe"),
                  ]),
                  _c(
                    _setup.Button,
                    {
                      attrs: { type: "danger" },
                      on: { click: _setup.addVerify },
                    },
                    [_vm._v("Add Email")]
                  ),
                ],
                1
              ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }