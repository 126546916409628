<script setup>
import { computed } from "vue";
import Button from "./WalletSettingsButton";
import { useRoute } from "vue-router/composables";
import store from "@/store";
import moment from "moment";

const route = useRoute();

const currentCard = computed(() => {
  if (route.params.id && store.state.cards.cards.results) {
    return store.state.cards.cards.results.find(
      (card) => card.id === route.params.id
    );
  } else {
    return "";
  }
});

const loading = computed(() => {
  if (!currentCard.value) {
    return true;
  } else {
    return false;
  }
});

function yearsMonthsLeft() {
  const today = new Date();
  const renewalDate = new Date(currentCard.value.expires_at);
  const diffTime = Math.abs(renewalDate - today);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const years = Math.floor(diffDays / 365);
  const months = Math.floor((diffDays - years * 365) / 30);
  const days = diffDays - years * 365 - months * 30;

  if (currentCard.value.expires_at === currentCard.value.network_expires_at) {
    return "When card expires";
  } else if (years > 0) {
    if (years === 1) {
      return `${years} year`;
    } else {
      return `${years} years`;
    }
  } else if (months > 0) {
    return `${months} months`;
  } else {
    return `${days} days`;
  }
}

const date = computed(() => {
  return moment(currentCard.value.expires_at).format("MMMM DD, YYYY");
});
</script>

<template>
  <Button
    :loading="loading"
    icon="fire"
    text="Self-destruct"
    :title="yearsMonthsLeft()"
    :subtext="date"
  />
</template>
