var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.CircularProgress, {
    staticClass: "totp-progress",
    class: { "totp-progress--expiring": _setup.isExpiring },
    attrs: { progress: _vm.progress, "anti-clock-wise": "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }