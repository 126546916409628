var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "icon",
      class: { blocked: _vm.showBlockIcon },
      style: _vm.getBkgdWrapperStyle(),
      on: {
        click: _vm.handleClick,
        mouseenter: function ($event) {
          return _vm.$emit("mouseenter")
        },
        mouseleave: function ($event) {
          return _vm.$emit("mouseleave")
        },
      },
    },
    [
      _vm.showBlockIcon
        ? _c("BlockContact")
        : _vm.initials
        ? _c("span", { style: _vm.getInitialsFontSize() }, [
            _vm._v(_vm._s(_vm.initials.toUpperCase())),
          ])
        : _c(
            "div",
            {
              staticClass: "default-icon-wrapper",
              style: _vm.getDefaultIconStyle(),
            },
            [_c("UserIcon", { staticClass: "default-icon" })],
            1
          ),
      _vm.associatedIdentityId && _vm.associatedIdentity
        ? _c(
            "div",
            { staticClass: "mini-icon", style: _vm.getMiniIconBorderStyle() },
            [
              _c("IdentityIcon", {
                attrs: {
                  identity: _vm.associatedIdentity,
                  override: _vm.getMiniIconStyle(),
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }