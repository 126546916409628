var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: _vm.changed,
          expression: "changed",
        },
      ],
      ref: "handler",
      staticClass: "infinite-loader",
    },
    [
      _vm.loading && !_vm.completed
        ? _c("div", { staticClass: "loading" }, [_c("span")])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }