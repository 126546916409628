<template>
  <div v-observe-visibility="changed" ref="handler" class="infinite-loader">
    <div v-if="loading && !completed" class="loading">
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfiniteTrigger",
  data() {
    return {
      loading: false,
      completed: false,
      status: false,
    };
  },
  methods: {
    loaded() {
      if (this.amIVisible()) {
        this.$emit("infinite", this);
      } else {
        this.completed = false;
        this.loading = false;
      }
    },
    changed(status) {
      this.status = status;
      if (status) {
        if (!this.completed) {
          this.loading = true;
          setTimeout(() => {
            this.$emit("infinite", this);
          }, 250);
        }
      }
    },
    uncomplete() {
      this.completed = false;
      this.loading = true;
      this.$nextTick(() => {
        if (this.amIVisible()) {
          setTimeout(() => {
            this.$emit("infinite", this);
          }, 250);
        }
      });
    },
    complete() {
      this.completed = true;
      this.loading = false;
    },
    amIVisible() {
      return this.status;
    },
  },
  watch: {
    immediate: true,
    loading: {
      handler(newValue) {
        this.$emit("loading", newValue);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.infinite-loader {
  min-height: 10px;
}
.loading {
  // matches dimensions of cloak card
  width: 190px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 15px;
    height: 15px;
    margin: 10px;
    display: inline-block;
    border: 3px solid #aaa;
    border-top-color: transparent;
    border-radius: 50%;
    @include animation(spin linear 0.7s infinite);
    @include transition(all 0.3s ease);

    .loaded & {
      opacity: 0;
      visibility: hidden;
      @include transition-delay(0.2s);
    }

    @keyframes spin {
      from {
        @include transform(rotate(0));
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
