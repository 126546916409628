var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.UiMenu,
    {
      attrs: { width: "220px", placement: "bottom-start" },
      scopedSlots: _vm._u([
        {
          key: "content",
          fn: function () {
            return _vm._l(_setup.options, function (option, index) {
              return _c(
                _setup.UiMenuButton,
                {
                  key: index,
                  staticClass: "custom-field-add__option",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("add", option.type)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "custom-field-add__icon" },
                    [
                      _c(_setup.CustomFieldIcon, {
                        attrs: { type: option.type },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" " + _vm._s(option.label) + " "),
                ]
              )
            })
          },
          proxy: true,
        },
      ]),
    },
    [_c(_setup.ButtonAdd, { attrs: { label: "Add new information" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }