var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ModalTemplate, {
    attrs: { show: _setup.props.isModalVisible },
    on: {
      close: function ($event) {
        return _setup.emit("closeModal")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("h1", [
              _vm._v(
                _vm._s(_setup.props.category ? "Edit" : "Add") +
                  " category name"
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _setup.state.categoryName,
                  expression: "state.categoryName",
                },
              ],
              ref: "newCategory",
              attrs: {
                "aria-id": `NewCategory.${_setup.state.categoryName || ""}`,
                id: `newCategory-${_vm.id}`,
                type: "text",
                placeholder: "New category",
                autocomplete: "off",
                maxlength: "50",
              },
              domProps: { value: _setup.state.categoryName },
              on: {
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _setup.handleSave.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_setup.state, "categoryName", $event.target.value)
                },
              },
            }),
            _c(
              "div",
              {
                staticClass: "error-message",
                class: { visible: _setup.nameIsTaken },
              },
              [_vm._v(" Please choose a unique name for this category ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              _setup.Button,
              {
                attrs: {
                  "aria-id": "SaveChangesCategoryButton",
                  disabled: _setup.isButtonDisabled,
                  id: `save-${_vm.id}`,
                },
                on: { click: _setup.handleSave },
              },
              [_vm._v(" Save Changes ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }