var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.Popper,
    _vm._g(
      _vm._b(
        {
          attrs: {
            width: "250px",
            placement: "left",
            "offset-along": 12,
            "offset-away": 16,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "app-tooltip-intro__content" },
                      [
                        _vm._t("content"),
                        _c(_setup.ChevronTooltip, {
                          staticClass: "app-tooltip-intro__chevron",
                        }),
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "Popper",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }