var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ModalTemplate, {
    staticClass: "recovery-modal",
    attrs: { show: true, width: 580 },
    on: { close: _setup.close },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("span", { staticClass: "clap" }, [_c(_setup.Warning)], 1),
            _c("h1", { staticClass: "title" }, [
              _vm._v("Are you sure you have your recovery key?"),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("p", { staticClass: "subscribe-thanks" }, [
              _vm._v(
                " Before continuing, be sure you’ve saved your recovery key in a secure location. "
              ),
            ]),
            _c("p", { staticClass: "subscribe-cta" }, [
              _vm._v(
                " Cloaked has no record or way to recover your key if you lose it. We will be unable to help you regain access to your account if you lose your password and don’t have this key. "
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("div", { staticClass: "actions" }, [
              _c(
                "button",
                {
                  staticClass: "subscribe-invite",
                  on: { click: _setup.navigate },
                },
                [_vm._v(" Download recovery key ")]
              ),
              _c(
                "button",
                { staticClass: "subscribe-close", on: { click: _setup.close } },
                [_vm._v(" Yes, I have my key ")]
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }