<template>
  <div
    class="icon"
    :class="{ blocked: showBlockIcon }"
    :style="getBkgdWrapperStyle()"
    @click="handleClick"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <BlockContact v-if="showBlockIcon" />
    <span v-else-if="initials" :style="getInitialsFontSize()">{{
      initials.toUpperCase()
    }}</span>
    <div v-else :style="getDefaultIconStyle()" class="default-icon-wrapper">
      <UserIcon class="default-icon" />
    </div>
    <div
      v-if="associatedIdentityId && associatedIdentity"
      class="mini-icon"
      :style="getMiniIconBorderStyle()"
    >
      <IdentityIcon
        :identity="associatedIdentity"
        :override="getMiniIconStyle()"
      />
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import { UserIcon } from "@/assets/icons";
import IdentityIcon from "@/components/ui/IdentityIcon.vue";
import { BlockContact } from "@/assets/icons";

export default {
  name: "ContactIcon",
  props: {
    initials: String,
    override: {
      height: Number,
      width: Number,
    },
    onClick: Function,
    associatedIdentityId: Number,
    showBlockIcon: Boolean,
  },
  components: {
    BlockContact,
    UserIcon,
    IdentityIcon,
  },
  data() {
    return {
      associatedIdentity: null,
    };
  },
  methods: {
    handleClick(e) {
      if (this.onClick) {
        return this.onClick(e);
      }
    },
    getBkgdWrapperStyle() {
      return {
        ...this.override,
        height: this.height,
        width: this.width,
      };
    },
    getDefaultIconStyle() {
      return {
        height: `${0.3889 * parseFloat(this.height.replace("px", ""))}px`,
        width: `${0.3889 * parseFloat(this.width.replace("px", ""))}px`,
      };
    },
    getInitialsFontSize() {
      return {
        "font-size": `${
          this.override
            ? 0.444 * parseFloat(this.height.replace("px", ""))
            : "18"
        }px`,
      };
    },
    getMiniIconBorderStyle() {
      return {
        "outline-width": `${
          0.083 * 0.3333 * parseFloat(this.width.replace("px", ""))
        }px`,
      };
    },
    getMiniIconStyle() {
      return {
        height: `${0.3333 * parseFloat(this.height.replace("px", ""))}px`,
        width: `${0.3333 * parseFloat(this.width.replace("px", ""))}px`,
      };
    },
    async getAssociatedIdentity() {
      return api()
        .get(`/api/v2/cloaked/identity/${this.associatedIdentityId}/`)
        .then(({ data }) => {
          this.associatedIdentity = data;
        });
    },
  },
  computed: {
    height() {
      return (this.override && this.override.height) || "40px";
    },
    width() {
      return (this.override && this.override.width) || "40px";
    },
  },
  watch: {
    associatedIdentityId() {
      this.getAssociatedIdentity();
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  background-color: $color-primary-100;
  color: $color-primary-0;
  font-family: $poppins;
  font-weight: 600;
  font-size: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;

  .default-icon-wrapper {
    display: flex;
    .default-icon {
      height: 100%;
      width: 100%;
      align-self: center;
    }
  }

  .mini-icon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: $color-primary-20;
    outline: 2px solid $color-primary-5;
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -2%;
    bottom: -4%;
  }
  &.blocked {
    background-color: $color-primary-10-light;
  }
}
</style>
