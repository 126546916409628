var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    _vm._g(_vm._b({}, "AppModal", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        "AppModalContent",
        [
          _c("AppModalTitle", [_vm._v(" Dismiss checklist? ")]),
          _c("AppModalParagraph", [
            _vm._v(
              " If you dismiss this checklist without completing it, you won’t be able to get your free month of Cloaked Premium. "
            ),
          ]),
          _c(
            "AppModalFooter",
            [
              _c(
                "Button",
                {
                  attrs: { type: "secondary" },
                  on: { click: _vm.dismissOnboarding },
                },
                [_vm._v(" Dismiss ")]
              ),
              _c(
                "Button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("input", false)
                    },
                  },
                },
                [_vm._v(" Don't dismiss ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }