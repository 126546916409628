<template>
  <UiMenu
    :value="visible"
    width="250px"
    @close="$emit('close')"
    placement="left-start"
    class="cloak-generate-email-flyout"
  >
    <template #content>
      <UiMenuButton
        title="Professional"
        subtitle="(ie. yourusername@cloak.id)"
        :rtl="false"
        :active="email_type === 'professional'"
        @click="onChangeInput('professional')"
      >
        <template v-slot:icon>
          <RadioButton
            name="email_type"
            value="professional"
            :checked="email_type === 'professional'"
            @update="onChangeInput"
          />
        </template>
      </UiMenuButton>

      <UiMenuButton
        title="Random"
        subtitle="(ie. five.score.extra@cloaked.id)"
        :rtl="false"
        :active="email_type === 'random'"
        @click="onChangeInput('random')"
      >
        <template v-slot:icon>
          <RadioButton
            name="email_type"
            value="random"
            :checked="email_type === 'random'"
            @update="onChangeInput"
          />
        </template>
      </UiMenuButton>

      <UiMenuSeparator />

      <UiMenuButton
        title="Generate New Email Address"
        active
        @click="$emit('generate', email_type)"
      >
        <template v-slot:icon>
          <GenerateIcon />
        </template>
      </UiMenuButton>
    </template>
  </UiMenu>
</template>

<script>
import { RadioButton } from "@/components";
import { GenerateIcon } from "@/assets/icons";
import { UiMenu, UiMenuButton, UiMenuSeparator } from "@/components";

export default {
  name: "CloakGenerateEmailFlyout",
  props: {
    visible: { type: Boolean, default: false },
  },
  components: {
    GenerateIcon,
    RadioButton,
    UiMenu,
    UiMenuButton,
    UiMenuSeparator,
  },

  data() {
    return {
      email_type: this.$store.state.profile.email_type,
    };
  },

  methods: {
    onChangeInput(selected) {
      this.email_type = selected;
    },
  },
};
</script>

<style lang="scss">
.cloak-generate-email-flyout {
  position: absolute;
  inset: 0;

  .popper__activator {
    position: absolute;
    inset: 0;
    cursor: pointer;
  }
}
</style>
