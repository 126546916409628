<template>
  <AppModal v-on="$listeners" @close="$store.dispatch('onboarding/close')">
    <AppModalContent class="onboarding-forwarding">
      <div class="onboarding-forwarding__controls">
        <AppModalBack @click="onBack" v-show="isBackVisible" />
        <AppModalClose
          @click="$store.dispatch('onboarding/close')"
          class="onboarding-forwarding__controls-next"
        />
      </div>
      <OnboardingForwardingEmail
        :step="step"
        v-if="step < 3"
        :email="email"
        :email-code="emailCode"
        :emailForwarding="emailForwarding"
        @input-email="setEmail"
        @input-email-code="emailCode = $event"
        @input-email-forwarding="emailForwarding = $event"
        @input-email-forwarding-consent="emailForwardingConsent = $event"
        @resend="resend('email', emailData.id)"
        :class="{ 'forwarding-email--verified': isEmailVerified }"
        @keydown.enter="onContinue"
      />
      <OnboardingForwardingPhone
        :step="step"
        v-else
        :phone="phone"
        :phone-code="phoneCode"
        :phone-forwarding="phoneForwarding"
        :phone-forwarding-consent="phoneForwardingConsent"
        @input-phone="setPhone"
        @input-phone-code="phoneCode = $event"
        @input-phone-forwarding="phoneForwarding = $event"
        @input-phone-forwarding-consent="phoneForwardingConsent = $event"
        @resend="resend('phone', phoneData.id)"
        :class="{ 'forwarding-phone--verified': isPhoneVerified }"
        @keydown.enter="onContinue"
      />
      <div class="input_error" v-if="error">{{ error }}</div>
      <AppModalFooter with-border>
        <Stepper
          :value="step < 3 ? 0 : 1"
          :steps="['Emails', 'Calls & texts']"
          class="onboarding-forwarding__stepper"
        />
        <Button
          @click="onContinue"
          :disabled="isNextDisabled"
          ref="next"
          @keydown.native.enter="onContinue"
        >
          {{ continueText }}
        </Button>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>

<script>
import api from "@/api/api";
import { phone_package } from "@/scripts/format";
import { email, phone } from "@/scripts/validation";
import Stepper from "@/components/ui/stepper";
import AppModal from "@/components/ui/AppModal";
import Button from "@/components/Button.vue";
import AppModalBack from "@/components/ui/AppModalBack";
import AppModalClose from "@/components/ui/AppModalClose";
import { STEP_FORWARDING } from "@/store/modules/onboarding";
import AppModalFooter from "@/components/ui/AppModalFooter";
import AppModalContent from "@/components/ui/AppModalContent";
import OnboardingForwardingEmail from "@/components/feature/onboarding/OnboardingForwardingEmail";
import OnboardingForwardingPhone from "@/components/feature/onboarding/OnboardingForwardingPhone";
import ForwardingService from "@/api/actions/forwarding-service";

export default {
  components: {
    OnboardingForwardingPhone,
    OnboardingForwardingEmail,
    AppModalBack,
    AppModalFooter,
    AppModalContent,
    AppModalClose,
    AppModal,
    Button,
    Stepper,
  },
  data() {
    return {
      step: -1,
      error: null,
      email: "",
      emailData: null,
      emailCode: "",
      phoneCountry: "us",
      phone: "",
      phoneCode: "",
      phoneData: null,
      token: null,
      emailForwarding: "cloaked",
      emailForwardingConsent: false,
      phoneForwarding: "cloaked",
      phoneForwardingConsent: false,
      isEmailVerified: false,
      isPhoneVerified: false,
    };
  },
  methods: {
    setPhone({ value, country }) {
      if (phone(value)) {
        this.phone = value;
        this.phoneCountry = country;
      } else {
        this.phone = "";
        this.phoneCountry = "us";
      }
    },
    setEmail(data) {
      if (email(data)) {
        this.email = data;
      } else {
        this.email = "";
      }
    },
    addEmail() {
      const payload = {
        email: this.email.toLowerCase(),
        primary: false,
        collection_name: "email",
        collection: this.$store.getters["authentication/collection"]("email"),
        user: this.$store.state.authentication.user.url,
      };
      return api()
        .post(`/api/v1/email/`, payload)
        .then(({ data }) => {
          return data;
        })
        .catch((e) => {
          const error = e.response.data;
          throw error && error[0];
        });
    },
    addPhone() {
      const phone_payload = phone_package(this.phone, this.phoneCountry);
      const payload = {
        ...phone_payload,
        primary: false,
        collection_name: "phone",
        collection: this.$store.getters["authentication/collection"]("phone"),
        user: this.$store.state.authentication.user.url,
      };
      return api()
        .post(`/api/v1/phone/`, payload)
        .then(({ data }) => {
          return data;
        })
        .catch((e) => {
          const error = e.response.data;
          throw error && error[0];
        });
    },
    resend(type, id) {
      this.emailCode = "";
      this.phoneCode = "";
      return api()
        .get(`api/v1/${type}/${id}/verify/`)
        .then((verify_response) => {
          this.$toast.success("Verification code sent.");
          if (!verify_response.data.verified) {
            this.token = verify_response.data.session_token;
          }
        })
        .catch(() => {
          this.$toast.error("Error resending verification code.");
        });
    },
    verify(type, object, key, code) {
      const typeFinal = type === "phone" ? "phone_number" : "email";
      return api()
        .patch(`api/v1/${type}/${object.id}/verify/`, {
          security_code: code,
          [typeFinal]: object[key],
          session_token: this.token,
        })
        .then(() => {
          this.$toast.success("Verification successful.");
        })
        .catch((err) => {
          this.$toast.error("Error with verification.");
          throw err;
        });
    },
    async onContinue() {
      this.error = null;
      if (this.isNextDisabled) {
        return;
      }
      switch (this.step) {
        case -1:
          if (this.emailForwarding === "cloaked") {
            ForwardingService.disableEmailForwarding()
              .then(() => {
                this.step = 3;
              })
              .catch(() => {
                this.error = "Something went wrong, please try again";
              });
          } else {
            this.step = 0;
          }
          break;
        case 0:
          this.addEmail()
            .then((email) => {
              if (email.verified) {
                return ForwardingService.enableEmailForwarding(email.id)
                  .then(() => {
                    this.step = 2;
                  })
                  .catch(() => {
                    this.error =
                      "Could not turn email forwarding on, please try again";
                  });
              } else {
                this.emailData = email;
                this.resend("email", email.id)
                  .then(() => {
                    this.step = 1;
                  })
                  .catch(() => {
                    this.error = "Could not verify email, please try again";
                  });
              }
            })
            .catch((message) => {
              this.error = message;
            });
          break;
        case 1:
          this.verify("email", this.emailData, "email", this.emailCode)
            .then(() => {
              return ForwardingService.enableEmailForwarding(this.emailData.id)
                .then(() => {
                  this.step = 2;
                })
                .catch(() => {
                  this.error =
                    "Could not turn email forwarding on, please try again";
                });
            })
            .catch(() => {
              this.error = "Verification code is incorrect";
            });
          break;
        case 2:
          this.isEmailVerified = true;
          this.step = 3;
          break;
        case 3:
          if (this.phoneForwarding === "cloaked") {
            ForwardingService.disablePhoneForwarding()
              .then(() => {
                this.$store.dispatch(
                  "onboarding/completeStep",
                  STEP_FORWARDING
                );
                this.$router.push({ name: "Onboarding" });
              })
              .catch(() => {
                this.error = "Something went wrong, please try again";
              });
          } else {
            this.step = 4;
          }
          break;
        case 4:
          this.addPhone()
            .then((phone) => {
              if (phone.verified) {
                return ForwardingService.enablePhoneForwarding(phone.id)
                  .then(() => {
                    this.step = 6;
                  })
                  .catch(() => {
                    this.error =
                      "Could not turn phone forwarding on, please try again";
                  });
              } else {
                this.phoneData = phone;
                this.resend("phone", phone.id)
                  .then(() => {
                    this.step = 5;
                  })
                  .catch(() => {
                    this.error =
                      "Could not verify phone number, please try again";
                  });
              }
            })
            .catch((message) => {
              this.error = message;
            });
          break;
        case 5:
          this.verify("phone", this.phoneData, "phone_number", this.phoneCode)
            .then(() => {
              return ForwardingService.enablePhoneForwarding(this.phoneData.id)
                .then(() => {
                  this.step = 6;
                })
                .catch(() => {
                  this.error =
                    "Could not turn phone forwarding on, please try again";
                });
            })
            .catch(() => {
              this.error = "Verification code is incorrect";
            });
          break;
        case 6:
          this.isPhoneVerified = true;
          this.$router.push({ name: "Onboarding" });
          this.$store.dispatch("onboarding/completeStep", STEP_FORWARDING);
          break;
      }
    },
    onBack() {
      if (this.step === 2) {
        this.isEmailVerified = true;
      }

      if (this.step === 4) {
        this.phoneData = null;
        this.phone = "";
      }
      if (this.step === 5) {
        this.phoneCode = "";
      }

      if (this.step === 6) {
        this.isPhoneVerified = true;
      }

      if (this.step >= 0) {
        this.step--;
      } else {
        this.$router.push({ name: "Onboarding" });
      }
    },
  },
  computed: {
    continueText() {
      if (this.step === 6) {
        return "Done";
      }

      if ([1, 5].includes(this.step)) {
        return "Submit";
      }

      return "Continue";
    },
    isNextDisabled() {
      if (this.step === 0 && !this.email) {
        return true;
      }

      if (this.step === 1 && this.emailCode.length !== 6) {
        return true;
      }

      if (this.step === 5 && this.phoneCode.length !== 6) {
        return true;
      }

      return this.step === 4 && (!this.phone || !this.phoneForwardingConsent);
    },
    isBackVisible() {
      return ![-1, 2, 3, 6].includes(this.step);
    },
  },
  watch: {
    step(newValue) {
      if ([2, 6].includes(newValue)) {
        this.$refs.next.$el.focus();
      }
    },
  },
};
</script>

<style lang="scss">
.onboarding-forwarding {
  text-align: center;

  .input_error {
    font-size: 12px;
    color: $color-alert;
    border: 1px solid $color-alert;
    border-radius: 10px;
    padding: 10px;
    margin: 20px 30px 5px 30px;
    background-color: #f82f2810;
    width: calc(100% - 126px);
  }

  &__controls {
    display: flex;
    align-items: center;
    width: 100%;

    &-next {
      margin-left: auto;
    }
  }

  &__stepper {
    margin-right: auto;

    .stepper__item {
      margin-left: 70px;

      &:first-child {
        margin-left: 16px;
      }

      &:after {
        width: 38px;
      }

      &--done {
        &:before {
          background: $color-primary-100;
        }
      }
    }
  }

  .app-modal-title {
    margin-top: 16px;
    padding: 0 64px;
  }

  .app-modal-paragraph {
    padding: 0 64px;
  }
}
</style>
