<script setup>
import InlineSvg from "@/components/InlineSvg";
import { useRoute } from "vue-router/composables";
import { computed } from "vue";
import store from "@/store";
import { IdentityIcon } from "@/components";
import CreateCard from "./WalletSettings/CreateCardButton";
import DefaultFundingSource from "./WalletSettings/DefaultFundingSourceButton.vue";
import SpendingLimit from "./WalletSettings/SpendingLimitButton.vue";
import AvailableSpendindButton from "./WalletSettings/AvailableSpendindButton.vue";
import SelfDestruct from "./WalletSettings/SelfDestructButton.vue";

const route = useRoute();

function identity(id) {
  const identity = store.state.localdb.db_cloaks.find((item) => item.id === id);
  return identity;
}

const card = computed(() => {
  if (route.params.id && store.state.cards.cards.results) {
    return store.state.cards.cards.results.find(
      (card) => card.id === route.params.id
    );
  }
  return "";
});

function openIdentity(identity) {
  if (
    !store.state.rightPanel.cloak ||
    store.state.rightPanel.cloak.id !== identity.id
  ) {
    store.dispatch("fetchPopulatedData", identity).then((data) => {
      store.commit("compose", null);
      store.dispatch("openCloakDetails", {
        cloak: data,
        skipNav: true,
      });
    });
  }
}
</script>

<template>
  <div class="settings">
    <header>
      <div class="title">
        <router-link to="/wallet" class="back-button" v-if="route.params.id">
          <InlineSvg name="chevron-left" />
        </router-link>
        <h1 v-else>Wallet settings</h1>

        <div class="icons">
          <div
            class="icon"
            v-if="route.params.id && card"
            @click="openIdentity(identity(card.identity_id))"
          >
            <InlineSvg name="users-circle-icon" />
          </div>

          <!-- <div class="icon" v-if="route.params.id && card">
            <InlineSvg name="ellipses-circle-icon" />
          </div> -->
        </div>
      </div>

      <div
        class="identity"
        v-if="route.params.id && card"
        @click="openIdentity(identity(card.identity_id))"
      >
        <IdentityIcon
          :identity="identity(card.identity_id)"
          :override="{ height: '32px', width: '32px' }"
        />
        <div class="info">
          <h1>{{ identity(card.identity_id)?.nickname || "New identity" }}</h1>
        </div>
      </div>
    </header>

    <div class="buttons">
      <CreateCard v-if="!route.params.id" />
      <AvailableSpendindButton v-if="route.params.id" />
      <SpendingLimit v-if="route.params.id" />
      <DefaultFundingSource v-if="!route.params.id" />
      <SelfDestruct v-if="route.params.id" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.settings {
  width: 100%;
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    padding: 0 16px;
    position: relative;
    height: 32px;

    .title {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .icons {
        display: flex;
        .icon {
          color: $color-primary-100;
          margin-left: 10px;

          &:first-child {
            margin-left: 0;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .back-button {
        width: 23px;
        height: 23px;
        border-radius: 50%;
        position: relative;

        svg {
          width: 12px;
          height: 12px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: $color-primary-100;
        }

        &:hover {
          background-color: $color-primary-10;
          cursor: pointer;
        }
      }

      h1 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.4px;
        color: $color-primary-100;
      }
    }

    .identity {
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      .info {
        margin-left: 8px;

        h1 {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.4px;
          color: $color-primary-100;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-top: 16px;
    width: 100%;
  }
}
</style>
