<script setup>
import { defineEmits, reactive, onBeforeMount, watch } from "vue";
import { useToast } from "@/hooks";
import store from "@/store";
import AuthService from "@/api/actions/auth-service";
import MfaService from "@/api/actions/mfa-service";
import { password_confirm } from "@/scripts/actions/encryption";
import {
  PreferencesInput,
  PreferencesParagraph,
  PreferencesTitle,
  PreferencesPanel,
  PreferencesHeader,
} from "@/routes/modals/preferences";
import OnboardingInputCode from "@/components/feature/onboarding/OnboardingInputCode";
import ValueDisplay from "@/components/ui/value-display";
import { Button } from "@/components";
import {
  EmailOutlineIcon,
  PhoneOutlineIcon,
  TextOutlineIcon,
} from "@/assets/icons";
import { phone_format } from "@/scripts/format";

const ENTER_PASSWORD = "password";
const CHOOSE_METHOD = "chooseMethod";
const SELECT_METHOD_DEVICE = "selectMethodDevice";
const ADD_NEW_METHOD = "addNewMethod";
const VERIFY_NEW_METHOD = "verifyNewMethod";
const AVAILABLE_STEPS = [
  ENTER_PASSWORD,
  CHOOSE_METHOD,
  SELECT_METHOD_DEVICE,
  ADD_NEW_METHOD,
  VERIFY_NEW_METHOD,
];

const toast = useToast();

const emit = defineEmits([
  "toggleBack",
  "refresh",
  "email-verified",
  "phone-verified",
  "mfa-devices-updated",
]);

const state = reactive({
  fetching: false,
  currentPassword: "",
  newPersonalPhone: "",
  newPersonalEmail: "",
  currentInputPhoneEmail: { display: "", value: "" },
  currentMethodId: null,
  totpToken: "",
  sessionToken: "",
  error: false,
  errorMessage: "",
  currentStep: ENTER_PASSWORD,
  currentMethodDisplayText: "",
  availableMethods: [],
  selectedMethod: "",
  verifiedPhoneNumbers: [],
  verifiedEmails: [],
  availableSteps: AVAILABLE_STEPS,
});

onBeforeMount(() => {
  getAvailableMfaMethods();
  getUserVerifiedPhoneNumbers();
  getUserVerifiedEmails();
});

const setCurrentStep = (step) => {
  state.error = false;
  state.errorMessage = "";
  if (step) {
    state.currentStep = step;
  } else {
    emit("toggleBack", {});
  }
};
const toggleBack = () => {
  if (state.currentStep === CHOOSE_METHOD) {
    emit("toggleBack", {});
    return;
  }
  const currentStepIndex = state.availableSteps.indexOf(state.currentStep);
  setCurrentStep(state.availableSteps[currentStepIndex - 1]);
};
const setSelectedMethod = (method) => {
  state.selectedMethod = method;
  state.currentMethodDisplayText = getMethodDisplayText(method);
  if (
    method === "call" ||
    (method === "sms" && state.verifiedPhoneNumbers.length > 0)
  ) {
    state.availableSteps = [
      ENTER_PASSWORD,
      CHOOSE_METHOD,
      SELECT_METHOD_DEVICE,
      ADD_NEW_METHOD,
      VERIFY_NEW_METHOD,
    ];
    setCurrentStep(SELECT_METHOD_DEVICE);
    return;
  } else if (method === "email" && state.verifiedEmails.length > 0) {
    state.availableSteps = [
      ENTER_PASSWORD,
      CHOOSE_METHOD,
      SELECT_METHOD_DEVICE,
      ADD_NEW_METHOD,
      VERIFY_NEW_METHOD,
    ];
    setCurrentStep(SELECT_METHOD_DEVICE);
    return;
  }
  state.availableSteps = [
    ENTER_PASSWORD,
    CHOOSE_METHOD,
    ADD_NEW_METHOD,
    VERIFY_NEW_METHOD,
  ];
  setCurrentStep(ADD_NEW_METHOD);
};
const getMethodDisplayText = (method) => {
  let methodText = "";
  switch (method) {
    case "call":
      methodText = "phone call";
      break;
    case "sms":
      methodText = "SMS";
      break;
    case "email":
      methodText = "email";
      break;

    default:
      break;
  }
  return `Verify via ${methodText}`;
};
const resetData = () => {
  setCurrentStep(ENTER_PASSWORD);
  /* go back to main page */
  emit("mfa-devices-updated");
  emit("toggleBack", {});
  state.fetching = false;
  state.currentPassword = "";
  state.newPersonalPhone = "";
  state.newPersonalEmail = "";
  state.currentInputPhoneEmail = { display: "", value: "" };
  state.currentMethodId = null;
  state.totpToken = "";
  state.sessionToken = "";
  state.error = false;
  state.errorMessage = "";
  state.currentMethodDisplayText = "";
  state.availableMethods = [];
  state.selectedMethod = "";
  state.verifiedPhoneNumbers = [];
  state.verifiedEmails = [];
  refresh();
};
const getUserVerifiedPhoneNumbers = async () => {
  return MfaService.getVerifiedPhoneNumbers()
    .then((res) => {
      state.verifiedPhoneNumbers = res.data.results.map((phone) => {
        return {
          ...phone,
          formattedPhoneNumber: phone_format(phone.phone_number),
        };
      });
    })
    .catch(() => {
      toast.error("Unable to find verified phone numbers.");
    });
};
const getUserVerifiedEmails = async () => {
  return MfaService.getVerifiedEmails()
    .then((res) => {
      state.verifiedEmails = res.data.results;
    })
    .catch(() => {
      toast.error("Unable to find verified emails.");
    });
};

const addNewMfaDeviceForVerifiedMethod = async (
  methodId,
  isVerified,
  methodType
) => {
  try {
    state.error = false;
    state.errorMessage = "";
    state.fetching = true;
    await MfaService.addMfaDevice({
      deviceId: navigator.userAgent,
      method: state.selectedMethod,
      methodId,
    });
    if (isVerified) {
      toast.success("Two-factor authentication enabled.");
      resetData();
      return;
    }
    /* if the phone number is verified we don't need extra api calls
      ie in case they happen to type in a number that is already verified
      */
    switch (methodType) {
      case "phone": {
        const phoneEndpointRes = await MfaService.requestPhoneVerificationCode({
          id: methodId,
        });
        if (phoneEndpointRes.data.verified) {
          toast.success("Two-factor authentication enabled.");
          resetData();
          return;
        }
        break;
      }
      case "email": {
        const emailEndpointRes = await MfaService.requestEmailVerificationCode({
          id: methodId,
        });
        if (emailEndpointRes.data.verified) {
          toast.success("Two-factor authentication enabled.");
          resetData();
          return;
        }
        break;
      }

      default:
        break;
    }
  } catch (e) {
    state.fetching = false;
    toast.error("Unable to add 2FA method. Please try again later.");
  }
};
const addNewPersonalPhone = async (phoneNumber) => {
  try {
    state.error = false;
    state.errorMessage = "";
    state.fetching = true;
    const phoneRes = await MfaService.addPersonalPhone({ phoneNumber });
    /* ie if that number is already saved to their account */
    if (phoneRes.data?.verified) {
      await MfaService.addMfaDevice({
        deviceId: navigator.userAgent,
        method: state.selectedMethod,
        methodId: phoneRes.data.id,
      });
      toast.success(
        "Verification successful. Two-factor authentication enabled."
      );
      /* reset data + go back to settings */
      resetData();
      return;
    }
    // otherwise we need to verify the phone number
    const verifyRes = await MfaService.requestPhoneVerificationCode({
      id: phoneRes.data.id,
    });
    state.currentMethodId = phoneRes.data.id;
    state.currentInputPhoneEmail = {
      value: phoneRes.data.phone_number,
      display: phone_format(phoneRes.data.phone_number),
    };
    state.sessionToken = verifyRes.data.session_token;
    state.fetching = false;
    setCurrentStep(VERIFY_NEW_METHOD);
  } catch (e) {
    state.fetching = false;
    const errors = Object.values(e.response?.data) ||
      e.response?.data || ["Unable to add new phone number."];
    state.errorMessage = errors.join(", ");
    state.error = true;
  }
};
const addNewPersonalEmail = async (email) => {
  try {
    state.error = false;
    state.errorMessage = "";
    state.fetching = true;
    const emailRes = await MfaService.addPersonalEmail({ email });
    /* if that email is already saved to their account */
    if (emailRes.data?.verified) {
      await MfaService.addMfaDevice({
        deviceId: navigator.userAgent,
        method: state.selectedMethod,
        methodId: emailRes.data.id,
      });
      toast.success(
        "Verification successful. Two-factor authentication enabled."
      );
      resetData();
      return;
    }
    /* otherwise we need to verify the email at email endpoint */
    const verifyRes = await MfaService.requestEmailVerificationCode({
      id: emailRes.data.id,
    });
    state.currentMethodId = emailRes.data.id;
    state.currentInputPhoneEmail = {
      value: emailRes.data.email,
      display: emailRes.data.email,
    };
    state.sessionToken = verifyRes.data.session_token;
    state.fetching = false;
    setCurrentStep(VERIFY_NEW_METHOD);
  } catch (e) {
    state.fetching = false;
    const errors = e.response?.data?.errors
      ? Object.values(e.response?.data?.errors)
      : e.response?.data
      ? e.response?.data
      : ["Unable to add new email."];
    state.errorMessage = errors.join(", ");
    state.error = true;
  }
};
const resendEmailCode = async () => {
  try {
    state.error = false;
    state.errorMessage = "";
    state.fetching = true;
    const verifyRes = await MfaService.requestEmailVerificationCode({
      id: state.currentMethodId,
    });
    state.sessionToken = verifyRes.data.session_token;
    state.fetching = false;
  } catch (e) {
    state.errorMessage =
      e.response?.data?.join(", ") ||
      "Something went wrong, please try again later.";
    state.error = true;
    state.fetching = false;
  }
};
const resendPhoneCode = async () => {
  try {
    state.error = false;
    state.errorMessage = "";
    state.fetching = true;
    const verifyRes = await MfaService.requestPhoneVerificationCode({
      id: state.currentMethodId,
    });
    state.sessionToken = verifyRes.data.session_token;
    state.fetching = false;
  } catch (e) {
    state.errorMessage = e.response?.data?.join(", ");
    state.error = true;
    state.fetching = false;
  }
};
const verifyPhoneTotpCode = async () => {
  try {
    state.error = false;
    state.errorMessage = "";
    state.fetching = true;
    await MfaService.validatePhoneVerificationCode({
      id: state.currentMethodId,
      phoneNumber: state.currentInputPhoneEmail.value,
      totpToken: state.totpToken,
      sessionToken: state.sessionToken,
    });
    addNewMfaDeviceForVerifiedMethod(state.currentMethodId, true, "phone");
    state.fetching = false;
  } catch (e) {
    state.fetching = false;
    state.errorMessage = "Verification code is incorrect.";
    state.error = true;
  }
};
const verifyEmailTotpCode = async () => {
  try {
    state.error = false;
    state.errorMessage = "";
    state.fetching = true;
    await MfaService.validateEmailVerificationCode({
      id: state.currentMethodId,
      email: state.currentInputPhoneEmail.value,
      totpToken: state.totpToken,
      sessionToken: state.sessionToken,
    });
    addNewMfaDeviceForVerifiedMethod(state.currentMethodId, true, "email");
    state.fetching = false;
  } catch (e) {
    state.fetching = false;
    state.errorMessage = "Verification code is incorrect.";
    state.error = true;
  }
};
const verifyUserPassword = () => {
  state.error = false;
  state.errorMessage = "";
  state.fetching = true;
  setTimeout(() => {
    verifyPassword();
  }, 100);
};
const verifyPassword = async () => {
  try {
    state.error = false;
    state.errorMessage = "";
    const hash = await password_confirm(state.currentPassword);
    const user = store.getters["authentication/user"];
    const userId = user.id;
    const passwordIsCorrect = await AuthService.confirmPassword(userId, hash);
    state.fetching = false;

    if (passwordIsCorrect) {
      setCurrentStep(CHOOSE_METHOD);
      state.error = false;
      state.errorMessage = "";
    }
  } catch (e) {
    state.fetching = false;
    state.errorMessage =
      e.response?.data?.password?.join(", ") ||
      "Invalid password, please try again.";
    state.error = true;
  }
};
const getAvailableMfaMethods = async () => {
  state.availableMethods = ["sms", "email"];
};
const refresh = () => {
  emit("refresh");
  emit("email-verified", {
    verified: true,
  });
  emit("phone-verified", {
    verified: true,
  });
  window.dispatchEvent(new CustomEvent("cloak:refresh-emails"));
};

watch(
  () => state.currentPassword,
  (newVal, oldValue) => {
    if (oldValue !== newVal) {
      state.error = false;
      state.errorMessage = "";
    }
  }
);

watch(
  () => state.newPersonalPhone,
  (newVal, oldValue) => {
    if (oldValue !== newVal) {
      state.error = false;
      state.errorMessage = "";
    }
  }
);

watch(
  () => state.newPersonalEmail,
  (newVal, oldValue) => {
    if (oldValue !== newVal) {
      state.error = false;
      state.errorMessage = "";
    }
  }
);

watch(
  () => state.currentStep,
  (newVal, oldValue) => {
    if (oldValue !== newVal) {
      state.totpToken = "";
    }
  }
);
</script>
<template>
  <PreferencesPanel class="panel-offset">
    <template v-slot:header>
      <PreferencesHeader @go-back="toggleBack" />
    </template>

    <div v-if="state.currentStep === ENTER_PASSWORD">
      <div class="mfa-header">
        <PreferencesTitle>Enter your password</PreferencesTitle>
        <PreferencesParagraph
          >For security purposes, please re-enter your
          password.</PreferencesParagraph
        >
      </div>

      <PreferencesInput
        v-model="state.currentPassword"
        label="Password"
        type="password"
        placeholder=""
        :error="!!state.error"
        @save="verifyUserPassword"
      />

      <div class="message" v-if="state.error">
        <p>{{ state.errorMessage }}</p>
      </div>
      <div class="mfa-button">
        <Button
          :loading="state.fetching"
          :disabled="state.fetching || !state.currentPassword"
          @click="verifyUserPassword"
          >Continue</Button
        >
      </div>
    </div>

    <div v-if="state.currentStep === CHOOSE_METHOD">
      <div class="mfa-header">
        <PreferencesTitle>Set up two-factor authentication</PreferencesTitle>
        <PreferencesParagraph
          >Choose a method for two-factor authentication.</PreferencesParagraph
        >
      </div>

      <ValueDisplay
        v-for="availableMethod in state.availableMethods"
        :key="availableMethod"
        :label="`${getMethodDisplayText(availableMethod)}`"
        @click="setSelectedMethod(availableMethod)"
      >
        <template v-slot:pre-field>
          <EmailOutlineIcon v-if="availableMethod === 'email'" />
          <PhoneOutlineIcon v-else-if="availableMethod === 'call'" />
          <TextOutlineIcon v-else-if="availableMethod === 'sms'" />
        </template>
      </ValueDisplay>
    </div>

    <div
      v-if="state.selectedMethod === 'call' || state.selectedMethod === 'sms'"
    >
      <div v-if="state.currentStep === SELECT_METHOD_DEVICE">
        <div class="mfa-header">
          <PreferencesTitle>Choose a phone number</PreferencesTitle>
        </div>
        <ValueDisplay
          v-for="verifiedPhoneNumber in state.verifiedPhoneNumbers"
          :key="verifiedPhoneNumber.id"
          label="Verified phone number"
          :value="verifiedPhoneNumber?.formattedPhoneNumber"
          @click="
            addNewMfaDeviceForVerifiedMethod(
              verifiedPhoneNumber.id,
              verifiedPhoneNumber.verified,
              state.selectedMethod === 'call' || state.selectedMethod === 'sms'
                ? 'phone'
                : 'email'
            )
          "
        />
        <ValueDisplay
          :label="
            state.verifiedPhoneNumbers?.length > 0
              ? 'Use a different phone number'
              : 'Add a phone number'
          "
          @click="setCurrentStep(ADD_NEW_METHOD)"
        />
      </div>

      <div v-if="state.currentStep === ADD_NEW_METHOD">
        <div class="mfa-header">
          <PreferencesTitle>{{
            state.currentMethodDisplayText
          }}</PreferencesTitle>
          <PreferencesParagraph
            >Add a phone number to use for two-factor
            authentication.</PreferencesParagraph
          >
        </div>
        <PreferencesInput
          v-model="state.newPersonalPhone"
          label="Phone number"
          type="tel"
          placeholder=""
          @save="() => addNewPersonalPhone(state.newPersonalPhone)"
          :error="state.error"
        />
        <div class="message" v-if="state.error">
          <p>{{ state.errorMessage }}</p>
        </div>
        <div class="mfa-button">
          <Button
            :loading="state.fetching"
            :disabled="state.fetching || !state.newPersonalPhone"
            @click="() => addNewPersonalPhone(state.newPersonalPhone)"
            >Continue</Button
          >
        </div>
      </div>

      <div v-if="state.currentStep === VERIFY_NEW_METHOD">
        <div class="mfa-header">
          <PreferencesTitle>Enter verification code</PreferencesTitle>
          <PreferencesParagraph
            >Please enter the code we sent to
            {{ state.currentInputPhoneEmail.display }}</PreferencesParagraph
          >
        </div>
        <div class="resend-btn-wrapper">
          <a class="resend" @click="resendPhoneCode">Resend code</a>
        </div>
        <OnboardingInputCode
          :value="state.totpToken"
          @input="state.totpToken = $event"
          v-on="$listeners"
          :largeFont="true"
        />
        <div class="message" v-if="state.error">
          <p>{{ state.errorMessage }}</p>
        </div>
        <div class="mfa-button">
          <Button
            :loading="state.fetching"
            :disabled="state.fetching || !state.totpToken"
            @click="() => verifyPhoneTotpCode()"
            >Verify code</Button
          >
        </div>
      </div>
    </div>

    <div v-if="state.selectedMethod === 'email'">
      <div v-if="state.currentStep === SELECT_METHOD_DEVICE">
        <div class="mfa-header">
          <PreferencesTitle>Choose an email address</PreferencesTitle>
          <PreferencesParagraph
            >Add an email address to use with two-factor
            authentication.</PreferencesParagraph
          >
        </div>
        <ValueDisplay
          v-for="verifiedEmail in state.verifiedEmails"
          :key="verifiedEmail.id"
          label="Verified email address"
          :value="verifiedEmail?.email"
          @click="
            addNewMfaDeviceForVerifiedMethod(
              verifiedEmail.id,
              verifiedEmail.verified,
              state.selectedMethod
            )
          "
        />
        <ValueDisplay
          :label="
            state.verifiedEmails?.length > 0
              ? 'Use a different email address'
              : 'Add an email address'
          "
          @click="setCurrentStep(ADD_NEW_METHOD)"
        />
      </div>

      <div v-if="state.currentStep === ADD_NEW_METHOD">
        <div class="mfa-header">
          <PreferencesTitle>{{
            state.currentMethodDisplayText
          }}</PreferencesTitle>
          <PreferencesParagraph
            >Add an email address to use with two-factor
            authentication.</PreferencesParagraph
          >
        </div>
        <PreferencesInput
          v-model="state.newPersonalEmail"
          label="Email address"
          type="text"
          placeholder=""
          @save="() => addNewPersonalEmail(state.newPersonalEmail)"
          :error="state.error"
        />
        <div class="message" v-if="state.error">
          <p>{{ state.errorMessage }}</p>
        </div>
        <div class="mfa-button">
          <Button
            :loading="state.fetching"
            :disabled="state.fetching || !state.newPersonalEmail"
            @click="() => addNewPersonalEmail(state.newPersonalEmail)"
            >Continue</Button
          >
        </div>
      </div>

      <div v-if="state.currentStep === VERIFY_NEW_METHOD">
        <div class="mfa-header">
          <PreferencesTitle>Enter verification code</PreferencesTitle>
          <PreferencesParagraph
            >Please enter the code we sent to
            {{ state.currentInputPhoneEmail.display }}</PreferencesParagraph
          >
        </div>
        <div class="resend-btn-wrapper">
          <a class="resend" @click="resendEmailCode">Resend code</a>
        </div>
        <OnboardingInputCode
          :value="state.totpToken"
          @input="state.totpToken = $event"
          v-on="$listeners"
          :largeFont="true"
        />
        <div class="message" v-if="state.error">
          <p>{{ state.errorMessage }}</p>
        </div>
        <div class="mfa-button">
          <Button
            :loading="state.fetching"
            :disabled="state.fetching || !state.totpToken"
            @click="() => verifyEmailTotpCode()"
            >Verify code</Button
          >
        </div>
      </div>
    </div>
  </PreferencesPanel>
</template>

<style lang="scss" scoped>
.mfa-button {
  padding-top: 32px;
}
.mfa-header {
  padding-bottom: 32px;
  .preferences-title {
    font-size: 24px;
    font-weight: 500;
  }
}
.resend-btn-wrapper {
  .resend {
    color: $color-primary-100;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
  }
}
.message {
  color: $color-alert;
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
}
.onboarding-input-code {
  margin: 32px 0 0 0;
}
</style>
