<script setup>
import store from "@/store";
import { logout } from "@/scripts/actions/auth";
import { DOWNLOADED_KEYS } from "@/scripts/userFlags";
import UserService from "@/api/actions/user-service";
import { check_encryption } from "@/scripts/actions/encryption";
import { downloadRecoveryKeyAndCreatePdf } from "@/scripts/tools.js";

import {
  defineProps,
  onMounted,
  ref,
  reactive,
  defineEmits,
  computed,
} from "vue";

const props = defineProps({
  hasPlugin: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["message"]);

const keywindow = ref(null);

const state = reactive({
  iframeLoaded: false,
  download: false,
  completed: false,
  message: {},
});

onMounted(() => {
  const childWindow = keywindow.contentWindow;
  keywindow.setAttribute(
    "sandbox",
    "allow-forms allow-modals allow-popups allow-scripts allow-same-origin"
  );

  window.addEventListener("message", (message) => {
    if (message.source !== childWindow) {
      return;
    }
    if (message.data.page && message.data.page === "login") {
      if (message.data.loggedin === false) {
        logout();
        return;
      }
      state.iframeLoaded = true;
    }
    if (message.data.success) {
      state.message = message;
      if (hasDownload.value) {
        notNow();
      } else {
        state.download = true;
      }
      check_encryption();
    } else {
      wrongPassword();
    }
  });
});

const hasDownload = computed(() => {
  return store.getters.getFlag(DOWNLOADED_KEYS);
});

const src = computed(() => {
  return `${window.ENV.VUE_APP_API}encryption/v2/restore/?cleanup=true&minimal=true`;
});

const username = computed(() => {
  return (
    store.state.authentication?.username ||
    store.state.authentication?.user?.username
  );
});

const supportEmail = computed(() => {
  const supportCloak = store.state.localdb.db_cloaks.find(
    (c) => c.protected && c.nickname?.match(/team|support/i)
  );
  return supportCloak && supportCloak?.email;
});

function openStore() {
  window.open("https://download.cloaked.app", "_blank");
  emit("message", state.message);
}

function notNow() {
  emit("message", state.message);
}

function complete() {
  if (!props.hasPlugin) {
    state.completed = true;
  } else {
    emit("message", state.message);
  }
}

async function downloadRecoveryPDF() {
  downloadRecoveryKeyAndCreatePdf(supportEmail.value, username.value);
  UserService.setFlags({ [DOWNLOADED_KEYS]: true });
}

function wrongPassword() {
  if (keywindow) {
    keywindow.classList.add("wrong-password");
    setTimeout(() => {
      keywindow.classList.remove("wrong-password");
    }, 1000);
  }
}

function iframeLoader() {
  setTimeout(() => {
    state.iframeLoaded = true;
  }, 500);
}
</script>

<template>
  <div class="iframe_container-wrapper">
    <div class="desktop-view">
      <button @click="logout" class="logout-button">logout</button>
      <div
        class="container-row"
        :class="{ download: state.download, completed: state.completed }"
      >
        <span class="dots">
          <div :class="{ selected: !state.download && !state.completed }"></div>
          <div :class="{ selected: state.download && !state.completed }"></div>
          <div :class="{ selected: state.download && state.completed }"></div>
        </span>
        <div class="content-wrapper-div iframe-window">
          <div>
            <div class="iframe-content">
              <div class="frame" :class="{ loaded: state.iframeLoaded }">
                <div class="loader">
                  <span class="spin-loader"></span>
                </div>
                <iframe
                  @load="iframeLoader"
                  ref="keywindow"
                  :src="src"
                  scrolling="no"
                  frameborder="no"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div class="content-wrapper-div download-screen">
          <div class="wrapper-padding">
            <div class="content">
              <div>
                <h1>
                  Seriously... <br />
                  Don’t lose this code phrase:
                </h1>
                <div class="secret_display">
                  <div>
                    <span v-if="state.download">{{
                      state.message.data.secretKey
                    }}</span>
                  </div>
                  <span>Hover to reveal</span>
                </div>
                <p>
                  <strong> We don’t store your code phrase.</strong>
                  If you lose it, you won’t be able to sign in to your Cloaked
                  account on new devices or restore your account if you forget
                  your password.
                </p>
                <p>
                  <strong> Never disclose your secret code.</strong> Anyone with
                  this information will be able to gain access to your Cloaked
                  account.
                </p>
                <button @click="downloadRecoveryPDF">
                  <svg
                    width="13"
                    height="15"
                    viewBox="0 0 13 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.1957 10.5967C6.35341 10.5967 6.47793 10.5469 6.62734 10.3975L8.76894 8.34717C8.88515 8.23926 8.95156 8.11475 8.95156 7.95703C8.95156 7.6499 8.71914 7.43408 8.40371 7.43408C8.26259 7.43408 8.11318 7.49219 8.00527 7.6167L7.09218 8.60449L6.74355 8.97803L6.79336 7.99023V1.34961C6.79336 1.03418 6.51943 0.768555 6.1957 0.768555C5.88027 0.768555 5.60634 1.03418 5.60634 1.34961V7.99023L5.65615 8.97803L5.30752 8.60449L4.39443 7.6167C4.28652 7.49219 4.1288 7.43408 3.98769 7.43408C3.67226 7.43408 3.44814 7.6499 3.44814 7.95703C3.44814 8.11475 3.51455 8.23926 3.62246 8.34717L5.76406 10.3975C5.92177 10.5469 6.04629 10.5967 6.1957 10.5967ZM2.47695 14.9297H9.92275C11.3422 14.9297 12.0394 14.2324 12.0394 12.8379V6.45459C12.0394 5.06006 11.3422 4.36279 9.92275 4.36279H8.13808V5.56641H9.90615C10.4955 5.56641 10.8441 5.89014 10.8441 6.5127V12.7798C10.8441 13.4106 10.4955 13.7261 9.90615 13.7261H2.49355C1.89589 13.7261 1.55556 13.4106 1.55556 12.7798V6.5127C1.55556 5.89014 1.89589 5.56641 2.49355 5.56641H4.26162V4.36279H2.47695C1.06582 4.36279 0.36025 5.06006 0.36025 6.45459V12.8379C0.36025 14.2324 1.06582 14.9297 2.47695 14.9297Z"
                      fill="#FDFDFD"
                    />
                  </svg>
                  Download code phrase
                </button>
              </div>
            </div>
            <div class="footer confirmed">
              <button @click="complete">Next</button>
            </div>
          </div>
        </div>
        <div class="content-wrapper-div download-screen completed">
          <div class="wrapper-padding">
            <div class="wrapper-padding">
              <div class="content">
                <div
                  class="img"
                  style="background-image: url(/images/signup-step3.png)"
                ></div>
                <h1>Get the most from cloaked</h1>
                <p>
                  Install Cloaked’s browser extension to create and save cloaked
                  emails, phone numbers, and passwords on your favorite sites.
                </p>
                <strong>
                  For the best experience, we highly recommend you download the
                  cloaked browser extension.
                </strong>
              </div>
            </div>
            <div class="footer confirmed">
              <button @click="notNow">Not Now</button>
              <button @click="openStore">
                Add Cloaked to Browser
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="#171717"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.3994 8.96436L11.3901 1.72803C11.3901 1.25488 11.084 0.920898 10.583 0.920898H3.34668C2.88281 0.920898 2.56738 1.28271 2.56738 1.68164C2.56738 2.08057 2.91992 2.42383 3.31885 2.42383H5.46191L9.13574 2.29395L7.73486 3.49072L0.823242 10.4209C0.674805 10.5693 0.591309 10.7642 0.591309 10.9404C0.591309 11.3394 0.953125 11.729 1.37061 11.729C1.56543 11.729 1.75098 11.6548 1.89941 11.5063L8.82031 4.57617L10.0356 3.17529L9.87793 6.82129V9.00146C9.87793 9.39111 10.2305 9.76221 10.6387 9.76221C11.0376 9.76221 11.3994 9.41895 11.3994 8.96436Z"
                    fill="#FDFDFD"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.secret_display {
  text-align: center;
  margin: 2% 0;
  > span {
    font-size: 12px;
    font-weight: bold;
  }
  div {
    display: flex;
    padding: 15px 30px;
    border-radius: 30px;
    border: 1px solid $color-primary-100-light;
    justify-content: center;
    margin-bottom: 5px;
    span {
      filter: blur(7px);
      transition: filter 200ms ease;
    }

    &:hover {
      span {
        filter: none;
        transition: filter 200ms ease;
      }
    }
  }
}

.wrapper-padding {
  padding: 30px !important;
}
.container-row {
  position: relative;
  overflow: hidden;
  width: 50vw;
  min-width: 600px;
  max-width: 750px;
  height: 700px;
  padding: 0;

  @media (max-width: 1024px) {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    padding: 0;
  }
  box-shadow: -5.81305px -5.81305px 17.4391px $color-primary-100-light-5,
    8.71957px 8.71957px 17.4391px rgba(1, 2, 24, 0.2);
  background-color: $white;
  .dots {
    position: absolute;
    display: block;
    right: 0;
    top: 50%;
    margin-top: -25px;
    width: 10px !important;
    height: 50px !important;
    padding-right: 18px;
    z-index: 100;
    > div {
      margin-bottom: 5px;
      background-color: rgba($color-primary-100-light, 0.3);
      width: 6px;
      height: 6px;
      border-radius: 6px;
      &.selected {
        background-color: $color-primary-100-light;
      }
    }
  }
  > div {
    position: relative;
    height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100% !important;
    > div {
      padding: 0;
      display: flex;
      overflow: visible;
      min-height: 100%;
      align-items: flex-end;
      flex-direction: column;
      justify-content: space-between;
      > div {
        width: 100%;
      }
      .footer {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
      }
    }

    @media (max-width: 1024px) {
      width: 100vw;
      max-width: 100vw;
      height: 100vh !important;
      max-height: 100vh !important;
    }
  }
  &.download {
    .iframe-window {
      margin-top: -700px;
      @media (max-width: 1024px) {
        margin-top: -100vh;
      }
      transition: margin-top 600ms ease;
    }

    &.completed {
      .iframe-window {
        margin-top: -1400px;
        transition: margin-top 600ms ease;
        @media (max-width: 1024px) {
          margin-top: -200vh;
        }
      }
      .completed {
        .img {
          background-position: center;
          background-size: contain;
          min-height: 250px;
          background-repeat: no-repeat;
          margin-bottom: 35px;
        }
        .footer {
          display: flex;
          justify-content: space-between;
          button {
            text-transform: uppercase;
          }
          button:first-child {
            background-color: #17171715;
            border: none;
          }
          button:last-child {
            background-color: $color-primary-100-light;
            color: $white;
            display: flex;
            gap: 8px;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
.download-screen {
  height: 100%;
  .content {
    strong {
      font-weight: 600;
      color: #f68c49;
    }
    button {
      border: none;
      background-color: $color-primary-100-light;
      cursor: pointer;
      color: $white;
      border-radius: 5px;
      padding: 15px 30px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      gap: 10px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 300;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: left;
    margin-bottom: 30px;
  }
  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .footer {
    border-top: 1px solid $color-primary-100-light-30;
    margin-top: 25px;
    margin-bottom: 15px;
    padding-top: 20px;
    display: grid;
    grid-template-columns: 10% 55% 25%;
    gap: 15px;
    align-items: center;
    color: red;
    > div {
      &:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }

    &.confirmed {
      color: $color-primary-100-light;
      input {
        border: 2px solid $color-primary-100-light;
      }
      button {
        opacity: 1;
        cursor: pointer;
      }
    }
    input {
      border: 1px solid red;
      height: 20px;
      width: 20px;
      border-radius: 4px;

      &:checked {
        background-color: $color-primary-100-light;
      }
    }
    button {
      opacity: 0.2;
      color: $color-primary-100-light;
      border: 1px solid $color-primary-100-light;
      background-color: transparent;
      border-radius: 10px;
      padding: 10px 20px;
    }
  }
}
.extension-line {
  font-weight: 500;
  font-size: 13px;
  margin-top: 15px;
}

iframe.wrong-password {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 500ms;

  /* When the animation is finished, start again */
  animation-iteration-count: 1;
}

.logout-button {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  z-index: 100;
  color: $color-primary-100-light;
}

@keyframes shake {
  0% {
    transform: translate(0px, 0px);
  }
  10% {
    transform: translate(10px, 0px);
  }
  20% {
    transform: translate(0px, 0px);
  }
  30% {
    transform: translate(-10px, 0px);
  }
  40% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(10px, 0px);
  }
  60% {
    transform: translate(0px, 0px);
  }
  70% {
    transform: translate(-10px, 0px);
  }
  80% {
    transform: translate(0px, 0px);
  }
  90% {
    transform: translate(10px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.iframe_container-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  background-color: $color-primary-100-light-10;
  justify-content: center;
  align-items: center;
}

.content-wrapper-div {
  background-color: $white;
  border-radius: 6px;
  @media (max-width: 1024px) {
    padding: 0;
  }
  display: flex;
  flex-direction: column;
}

.iframe-content {
  width: 100%;
  flex-grow: 1;
  @media (max-width: 1024px) {
    border-radius: 0;
  }
  display: flex;
  @media (max-width: 1024px) {
    display: block;
  }
}
.left {
  @media (max-width: 1024px) {
    display: none;
  }
  border-radius: 20px;
  width: 100%;
  height: 100%;
  max-width: 320px;
  margin-right: 30px;
  padding: 32px;

  h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -1px;
    margin-bottom: 17px;
  }
  span {
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.1px;
  }
}
.loader {
  align-self: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.frame {
  opacity: 0;
  background-color: $white;
  position: relative;
  align-self: center;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;

  @media (max-width: 1024px) {
    width: 100vw !important;
    height: 100vh !important;
    border-radius: 0;
    padding: 0;
  }
  &.loaded {
    opacity: 1;
    transition: opacity 500ms ease;
    .loader {
      display: none;
      opacity: 0;
      visibility: hidden;
      transition: opacity 1s ease;
    }
  }
}
iframe {
  width: 100% !important;
  flex-grow: 1;
  background-color: $white;
}
</style>
