var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "kyc", class: { active: _setup.props.active } },
    [
      _c("div", {
        staticClass: "blur",
        class: {
          orange: _setup.ui.step === 0,
          green: _setup.ui.step === 1,
          red: _setup.ui.step === -1,
        },
      }),
      _c(
        "div",
        {
          staticClass: "back",
          on: {
            click: function ($event) {
              return _setup.emits("close")
            },
          },
        },
        [_c(_setup.ChevronLeft)],
        1
      ),
      _c("div", { staticClass: "content-body" }, [
        _c("h1", [
          _setup.ui.step === 0
            ? _c("span", [_vm._v("Verifying your information...")])
            : _vm._e(),
          _setup.ui.step === 1
            ? _c("span", [_vm._v("Approved for Cloaked Pay!")])
            : _vm._e(),
          _setup.ui.step === 2
            ? _c("span", [_vm._v("Funding source")])
            : _vm._e(),
          _setup.ui.step === -1
            ? _c("span", [_vm._v("Unable to verify your identity...")])
            : _vm._e(),
        ]),
        _setup.ui.step === 0 || _setup.ui.step === 1
          ? _c("div", { staticClass: "cards" }, [
              _c(
                "span",
                [
                  _setup.ui.step === 0
                    ? _c(
                        "div",
                        { staticClass: "spinning" },
                        [_c(_setup.Spinner)],
                        1
                      )
                    : _c(_setup.CloakedIcon),
                  _c("div", { staticClass: "mastercard" }),
                ],
                1
              ),
            ])
          : _setup.ui.step === -1
          ? _c("div", { staticClass: "rejected" }, [
              _c("p", [
                _vm._v(
                  " If you feel this is an error, please feel free to reach out to Cloaked customer support for manual identity verification. "
                ),
              ]),
              _c("div", { staticClass: "actions" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _setup.emits("close")
                      },
                    },
                  },
                  [_vm._v("Back to Dashboard")]
                ),
                _c("a", { attrs: { href: "#" } }, [_vm._v("Email Cloak")]),
              ]),
            ])
          : _c("div", { staticClass: "add-accounts" }, [
              _c("div", { staticClass: "account-actions" }, [
                _setup.hasSources
                  ? _c("div", { staticClass: "card-list" }, [
                      _c("h1", [_vm._v("Connected account")]),
                      _c(
                        "ul",
                        _vm._l(_setup.sources, function (source) {
                          return _c(
                            "li",
                            { key: source.id, staticClass: "card-item" },
                            [
                              _c("div", [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(
                                    _vm._s(_setup.get_name(source.card_brand))
                                  ),
                                ]),
                                source.pan_last_four
                                  ? _c("div", { staticClass: "numbers" }, [
                                      _c("span", [_vm._v("**** **** ****")]),
                                      _vm._v(
                                        " " + _vm._s(source.pan_last_four) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "note" }, [
                                  _vm._v("Auto debit enabled"),
                                ]),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    _setup.Popper,
                                    {
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "content",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "popover-menu",
                                                  },
                                                  [
                                                    _c("ul", [
                                                      _c("li", [
                                                        _c("div", [
                                                          _c("strong", [
                                                            _vm._v(
                                                              "Auto-debit"
                                                            ),
                                                          ]),
                                                          _c("span", [
                                                            _vm._v(
                                                              "Transactions debit as you spend"
                                                            ),
                                                          ]),
                                                        ]),
                                                        _c(
                                                          "button",
                                                          [
                                                            _c(_setup.Toggle, {
                                                              attrs: {
                                                                status: false,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                      _c("li", [
                                                        _c("div", [
                                                          _c("strong", [
                                                            _vm._v(
                                                              "Set as default funding source"
                                                            ),
                                                          ]),
                                                        ]),
                                                        _c(
                                                          "button",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _setup.setSourceAsPrimary(
                                                                  source,
                                                                  !source.primary
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(_setup.Toggle, {
                                                              attrs: {
                                                                status:
                                                                  source.primary,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]),
                                                      _c(
                                                        "li",
                                                        {
                                                          staticClass: "delete",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "Remove account connection"
                                                            ),
                                                          ]),
                                                          _c(
                                                            "button",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _setup.removeFundingSource(
                                                                      source
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _setup.DeleteTrashIcon
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("button", [_c(_setup.Ellipses)], 1)]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "add-card",
                          on: { click: _setup.addCard },
                        },
                        [_vm._v(" Create your first Cloaked card ")]
                      ),
                    ])
                  : _vm._e(),
                _c("h1", [
                  _setup.sources.length === 0
                    ? _c("span", [_vm._v("Connect")])
                    : _c("span", [_vm._v("Connect another")]),
                ]),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _setup.addFundingSource("ach")
                      },
                    },
                  },
                  [
                    _c("div", [
                      _c("span", [_c(_setup.Bank)], 1),
                      _c("span", [_vm._v("Bank account (ACH)")]),
                    ]),
                    _c("div", [_c(_setup.ChevronRight)], 1),
                  ]
                ),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _setup.addFundingSource("debit_card")
                      },
                    },
                  },
                  [
                    _c("div", [
                      _c("span", [_c(_setup.CardOutline)], 1),
                      _c("span", [_vm._v("Debit card")]),
                    ]),
                    _c("div", [_c(_setup.ChevronRight)], 1),
                  ]
                ),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _setup.addFundingSource("credit_card")
                      },
                    },
                  },
                  [
                    _c("div", [
                      _c("span", [_c(_setup.CardOutline)], 1),
                      _c("span", [_vm._v("Credit card")]),
                    ]),
                    _c("div", [_c(_setup.ChevronRight)], 1),
                  ]
                ),
              ]),
              _c("p", [
                _vm._v(
                  " Connecting your accounts does not perform a transaction. Connected accounts can be added or removed at any time. "
                ),
              ]),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }