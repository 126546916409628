<script setup>
import { computed, defineProps } from "vue";
import Spinner from "@/assets/icons/spinner.svg";

const props = defineProps({
  type: {
    type: String,
    default: "primary",
    validator: (value) =>
      [
        "hero",
        "primary",
        "secondary",
        "tertiary",
        "danger",
        "danger-secondary",
        "tag",
        "tag-selected",
      ].includes(value),
  },
  as: {
    type: String,
    default: "button",
    validator: (value) => ["button", "a"].includes(value),
  },
  size: {
    type: [String, null],
    default: null,
    validator: (value) => ["sm", "md", "lg", "xl"].includes(value),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const computedSize = computed(() => {
  const defaultSizeForType = {
    hero: "xl",
    primary: "lg",
    secondary: "lg",
    tertiary: "md",
    danger: "lg",
    "danger-secondary": "lg",
    tag: "sm",
    "tag-selected": "sm",
  };

  return props.size ?? defaultSizeForType[props.type];
});
</script>

<template>
  <Component
    :is="as"
    :class="['button', `button--${type}`, `button--${computedSize}`]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <Spinner v-if="loading" />
    <slot name="icon" v-else />
    <slot />
  </Component>
</template>

<style lang="scss" scoped>
.button {
  font-family: $poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border-radius: 1000px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border: none;

  &:disabled {
    cursor: not-allowed;
  }

  &--hero {
    background-color: $color-brand-1-100-dark;
    color: $color-primary-0;

    &:hover {
      background-color: $color-brand-1-90-dark;
    }

    &:disabled {
      background-color: $color-primary-10;
      color: $color-primary-70;
    }
  }

  &--primary {
    background-color: $color-primary-100;
    color: $color-primary-0;

    &:hover {
      background-color: $color-primary-70;
    }

    &:disabled {
      background-color: $color-primary-10;
      color: $color-primary-70;
    }
  }

  &--secondary {
    background-color: $color-surface;
    color: $color-primary-100;
    border: 1px solid $color-primary-100;

    &:hover {
      background-color: $color-primary-10;
    }

    &:disabled {
      background-color: $color-surface;
      color: $color-primary-50;
      border: 1px solid $color-primary-50;
    }
  }

  &--tertiary {
    background-color: $color-primary-10;
    color: $color-primary-100;

    &:disabled {
      color: $color-primary-50;
    }
  }

  &--danger {
    background-color: $color-alert;
    color: $color-primary-0-light;

    &:disabled {
      opacity: 0.3;
    }
  }

  &--danger-secondary {
    background-color: $color-surface;
    color: $color-alert;
    border: 1px solid $color-alert;

    &:disabled {
      opacity: 0.3;
    }
  }

  &--tag {
    background-color: $color-primary-0;
    color: $color-primary-100;
    border: 1px solid $color-primary-100;

    &:hover {
      background-color: $color-primary-10;
    }

    &:disabled {
      background-color: $color-primary-0;
      color: $color-primary-30;
      border: 1px solid $color-primary-30;
    }
  }

  &--tag-selected {
    background-color: $color-primary-100;
    color: $color-primary-0;
    border: 1px solid $color-primary-100;

    &:hover {
      background-color: $color-primary-10;
      color: $color-primary-100;
    }

    &:disabled {
      background-color: $color-primary-0;
      color: $color-primary-30;
      border: 1px solid $color-primary-30;
    }
  }

  &--sm {
    height: 30px;
    padding: 0 10px;
  }

  &--md {
    height: 36px;
    padding: 0 10px;
  }

  &--lg {
    height: 44px;
    padding: 0 17px;
  }

  &--xl {
    height: 44px;
    padding: 0 22px;
  }
}
</style>
