var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.kycValidated
    ? _c("div", { staticClass: "funding-source" }, [
        _vm._m(0),
        _c("div", { staticClass: "content" }, [
          _setup.cards
            ? _c(
                "div",
                { staticClass: "cards" },
                _vm._l(_setup.cards, function (card) {
                  return _c(
                    "div",
                    {
                      key: card.id,
                      staticClass: "card",
                      class: `${card.card_brand}`,
                    },
                    [
                      _c("div", { staticClass: "icon" }),
                      _c("div", { staticClass: "content" }, [
                        _c("p", [
                          card.card_brand === "mastercard"
                            ? _c("span", [
                                _vm._v(
                                  "Mastercard (" +
                                    _vm._s(
                                      _setup.getProviderType(card.provider)
                                    ) +
                                    ") "
                                ),
                                _setup.defaultFundingSource === card.id
                                  ? _c("i", [_vm._v("Default")])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          card.card_brand === "visa"
                            ? _c("span", [
                                _vm._v(
                                  "Visa (" +
                                    _vm._s(
                                      _setup.getProviderType(card.provider)
                                    ) +
                                    ") "
                                ),
                                _setup.defaultFundingSource === card.id
                                  ? _c("i", [_vm._v("Default")])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          card.card_brand === "unionpay"
                            ? _c("span", [
                                _vm._v(
                                  "UnionPay (" +
                                    _vm._s(
                                      _setup.getProviderType(card.provider)
                                    ) +
                                    ") "
                                ),
                                _setup.defaultFundingSource === card.id
                                  ? _c("i", [_vm._v("Default")])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          card.card_brand === "discover"
                            ? _c("span", [
                                _vm._v(
                                  "Discover (" +
                                    _vm._s(
                                      _setup.getProviderType(card.provider)
                                    ) +
                                    ") "
                                ),
                                _setup.defaultFundingSource === card.id
                                  ? _c("i", [_vm._v("Default")])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]),
                        _c("p", { staticClass: "card-number" }, [
                          _vm._v(_vm._s(_setup.formatCard(card.pan_last_four))),
                        ]),
                        false
                          ? _c("p", { staticClass: "auto-debit" }, [
                              _vm._v("Auto-debit enabled"),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "card-dropdown",
                            class: {
                              active: _setup.activeDropdown === card.id,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-icon",
                                on: {
                                  click: function ($event) {
                                    return _setup.currentDropdown(card.id)
                                  },
                                },
                              },
                              [_c(_setup.KabobIcon)],
                              1
                            ),
                            _c("div", { staticClass: "dropdown" }, [
                              _c("div", { staticClass: "content" }, [
                                false
                                  ? _c(
                                      "div",
                                      { staticClass: "button disabled" },
                                      [
                                        _vm._m(1, true),
                                        _c(_setup.UiSwitchInput, {
                                          attrs: { value: true },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "button" },
                                  [
                                    _vm._m(2, true),
                                    _c(_setup.UiSwitchInput, {
                                      attrs: {
                                        value:
                                          _setup.defaultFundingSource ===
                                          card.id,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _setup.toggleDefaultFundingSource(
                                            card.id
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "button delete",
                                    on: {
                                      click: function ($event) {
                                        return _setup.deleteFundingSource(
                                          card.id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._m(3, true), _c(_setup.DeleteTrashIcon)],
                                  1
                                ),
                              ]),
                              _c("div", {
                                staticClass: "background",
                                on: {
                                  click: function ($event) {
                                    return _setup.currentDropdown(null)
                                  },
                                },
                              }),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _c("div", { staticClass: "buttons" }, [
            _setup.cards ? _c("h1", [_vm._v("Connect another")]) : _vm._e(),
            _setup.AchDebitEnabled
              ? _c(
                  "div",
                  {
                    staticClass: "button bank-account",
                    on: {
                      click: function ($event) {
                        return _setup.addACH()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [_c(_setup.BankIcon)],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "text" },
                      [
                        _c("p", [_vm._v("Bank account")]),
                        _c(_setup.ChevronRight),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _setup.AchDebitEnabled
              ? _c(
                  "div",
                  {
                    staticClass: "button debit-card",
                    on: {
                      click: function ($event) {
                        return _setup.addDebitCard()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [_c(_setup.CardIcon)],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "text" },
                      [
                        _c("p", [_vm._v("Debit card")]),
                        _c(_setup.ChevronRight),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "button credit-card",
                on: {
                  click: function ($event) {
                    return _setup.addCreditCard()
                  },
                },
              },
              [
                _c("div", { staticClass: "icon" }, [_c(_setup.CardIcon)], 1),
                _c(
                  "div",
                  { staticClass: "text" },
                  [_c("p", [_vm._v("Credit card")]), _c(_setup.ChevronRight)],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title", attrs: { id: "funding" } }, [
      _c("h1", [_vm._v("Funding sources")]),
      _c("p", [
        _vm._v(
          " Add a debit card to begin using Cloaked Cards. We use Stripe to connect your debit card to your Cloaked account. This will launch a new window. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text" }, [
      _c("p", [_vm._v("Auto-debit")]),
      _c("p", [_vm._v("Transactions debit as you spend")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text" }, [
      _c("p", [_vm._v("Set as default funding source")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text" }, [
      _c("p", [_vm._v("Remove account connection")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }