<template>
  <PreferencesPanel class="forwarding-pref">
    <PreferencesTitle>Analytics settings</PreferencesTitle>

    <ValueDisplay
      label="Share usage analytics"
      value="Your data is anonymized, and we will never share it with anyone."
      dark-label
      light-value
    >
      <template v-slot:actions>
        <UiSwitchInput :value="toggle" @change="toggleAnalytics(!toggle)" />
      </template>
    </ValueDisplay>
  </PreferencesPanel>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import PrivacyServices from "@/api/settings/privacy-services";
import { analyticsExport } from "@/mixins/Analytics";
import PreferencesTitle from "./PreferencesTitle";
import PreferencesPanel from "./PreferencesPanel";
import ValueDisplay from "@/components/ui/value-display";
import UiSwitchInput from "@/components/UiSwitchInput.vue";

export default {
  name: "ToggleAnalytics",
  components: {
    PreferencesTitle,
    PreferencesPanel,
    ValueDisplay,
    UiSwitchInput,
  },
  computed: {
    ...mapGetters({
      toggle: "getAnalytics",
    }),
  },
  methods: {
    ...mapMutations(["setAnalytics"]),
    async toggleAnalytics(value) {
      if (!value) await this.hitEvent(value);
      let payload = {
        allow_analytics: value,
      };
      PrivacyServices.setAnalytics(payload)
        .then((response) => {
          this.setAnalytics(response.data.allow_analytics);
          this.$toast.success("Analytics settings saved.");
          this.loading = false;
          if (value) this.hitEvent(value);
        })
        .catch(() => {
          this.$toast.error("Error saving your analytics settings.");
        });
    },
    async hitEvent(value) {
      let eventName = "Toggle Sharing Analytics";
      let analyticsData = {
        accepted_values: value,
      };
      await analyticsExport.methods.sendEvent(eventName, analyticsData);
    },
  },
};
</script>

<style lang="scss">
.forwarding-pref {
  .value-display {
    margin-top: 13px;
  }
}
</style>
