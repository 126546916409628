import MutedIcon from "./muted-bell.svg";
import MutedContextIcon from "./muted-context.svg";
import MutedFilledIcon from "./muted-filled.svg";
import FavoriteIcon from "./favorite.svg";
import DefaultIdentityIcon from "./new-cloak-icons/DefaultIdentityIcon";
import FavoriteFilledIcon from "./favorite-filled.svg";
import DeleteIcon from "./delete.svg";
import DeleteMinusIcon from "./delete-minus.svg";
import DeleteTrashIcon from "./delete-trash.svg";
import DeleteActionBarIcon from "./delete-actionbar.svg";
import LinkChainIcon from "./link-chain.svg";
import LinkOutIcon from "./link.svg";
import KabobIcon from "./KabobIcon.vue";
import MoreInfoQIcon from "./more-info-q.svg";
import CopyIcon from "./copy.svg";
import BankIcon from "./bank-icon.svg";
import GenerateIcon from "./generate.svg";
import PlusIcon from "./PlusIcon.vue";
import Plus from "./plus.svg";
import CheckIcon from "./check.svg";
import EditPencilIcon from "./edit-pencil.svg";
import EditPencilLargerIcon from "./edit-pencil-larger.svg";
import EmailOutlineIcon from "./email-outline.svg";
import PhoneOutlineIcon from "./phone-outline.svg";
import TextOutlineIcon from "./chat-outline.svg";
import LockIcon from "./lock.svg";
import PermalockIcon from "./permalock.svg";
import OpenLockIcon from "./open-lock.svg";
import KeyIcon from "./key.svg";
import UserIcon from "./user-icon-filled.svg";
import UserOutlineIcon from "./user-icon.svg";
import LockShieldIcon from "./lock-shield.svg";
import SmallX from "./small-x.svg";
import DoubleChevronRight from "./double-chevron-right.svg";
import Bell from "./bell.svg";
import TrashOutline from "./trash-outline.svg";
import ChevronDown from "./chevron-down.svg";
import ChevronRight from "./chevron-right.svg";
import ChevronLeft from "./chevron-left.svg";
import ChevronUp from "./chevron-up.svg";
import ChevronLeft600 from "./chevron-left-600.svg";
import SmallSpinner from "./small-spinner.png";
import CheckActive from "./check-active.svg";
import CheckInactive from "./check-inactive.svg";
import CheckSquareActive from "./check-square-active.svg";
import CheckSquareInactive from "./check-square-inactive.svg";
import Minus from "./minus.svg";
import Eye from "./eye.svg";
import EyeSlash from "./eye-slash.svg";
import ShelfDownArrow from "./shelf-down-arrow.svg";
import ActivityPhoneIcon from "./activity-phone.svg";
import PlayVoicemailIconDark from "./activity-play-black-circle.svg";
import ActivityMarkAsReadIcon from "./activity-mark-as-read.svg";
import ActivityPauseIcon from "./activity-pause-icon.svg";
import Compose from "./compose.svg";
import RadioActive from "./radio-active.svg";
import RadioInactive from "./radio-inactive.svg";
import EmptyRound from "./plus-round.svg";
import MinusRound from "./minus-round.svg";
import ModalX from "./modal-x.svg";
import ComposeIcon from "./compose.svg";
import HourglassRound from "./hourglass-round.svg";
import DownloadRound from "./download-round.svg";
import SelectAllIcon from "./activity-select-all.svg";
import SelectMessagesIcon from "./activity-select-messages.svg";
import ActivityReplyIcon from "./activity-reply.svg";
import ActivityMarkAsUnReadIcon from "./activity-mark-as-unread.svg";
import WarningIcon from "./icon-warning.svg";
import UnmuteIcon from "./unmuted.svg";
import CommandKeyIcon from "./command-key.svg";
import SelectIcon from "./select-box.svg";
import CheckListIcon from "./check-list.svg";
import UpKeyIcon from "./up-key.svg";
import MoveIcon from "./move.svg";
import ArrowKeyRightIcon from "./arrow-key-right.svg";
import CloakedIcon from "./cloaked-icon.svg";
import InputClear from "./input-clear.svg";
import CloakedColorLogo from "./cloaked-logo-orange.svg";
import ExpansionArrow from "./expansion-arrow.svg";
import BlockContact from "./block-contact.svg";
import CheckCancelled from "./check-cancelled.svg";
import Hourglass from "./hourglass.svg";
import InviteSent from "./invite-sent.svg";
import Checkmark from "./checkmark.svg";
import CheckmarkGray from "./checkmark_gray.svg";
import YellowClock from "./yellow-clock.svg";
import Chevron from "./chevron-left-600.svg";
import Referral30 from "./referral_30day.svg";
import ReferralCard from "./referral_card.svg";
import Bank from "./bank.svg";
import CardOutline from "./card-outline.svg";
import Spinner from "./spinner.svg";
import Ellipses from "./ellipses.svg";
import ReferralCardsGraphic from "./referral_cards_graphic.svg";
import ReferralPrivacy from "./referral_privacy.svg";
import SelectAll from "./select-all.svg";
import RestoreNew from "./restore-new.svg";
import Restore from "./restore.svg";
import MastercardIcon from "./mastercard.svg";
// credit cards
import CardIcon from "./card.svg";
import CardOutlineIcon from "./credit-card-outline.svg";
import Visa from "./creditCards/visa.svg";
import Mastercard from "./creditCards/mastercard.svg";
import Discover from "./creditCards/discover.svg";
import Amex from "./creditCards/amex_circle.svg";
import Hipercard from "./creditCards/hipercard.svg";
import DinersClub from "./creditCards/diners-club.svg";
import PeopleIcon from "./people.svg";

export {
  Ellipses,
  SelectAll,
  Restore,
  RestoreNew,
  Bank,
  Spinner,
  CardOutline,
  ReferralPrivacy,
  Referral30,
  ReferralCard,
  CheckmarkGray,
  ReferralCardsGraphic,
  Hourglass,
  InviteSent,
  Checkmark,
  YellowClock,
  Chevron,
  FavoriteIcon,
  FavoriteFilledIcon,
  DefaultIdentityIcon,
  DeleteIcon,
  MutedIcon,
  LinkChainIcon,
  LinkOutIcon,
  KabobIcon,
  DeleteMinusIcon,
  MoreInfoQIcon,
  CopyIcon,
  GenerateIcon,
  PlusIcon,
  Plus,
  CheckIcon,
  EditPencilIcon,
  EditPencilLargerIcon,
  DeleteTrashIcon,
  EmailOutlineIcon,
  PhoneOutlineIcon,
  TextOutlineIcon,
  LockIcon,
  PermalockIcon,
  OpenLockIcon,
  KeyIcon,
  UserIcon,
  UserOutlineIcon,
  LockShieldIcon,
  SmallX,
  DoubleChevronRight,
  Bell,
  TrashOutline,
  ChevronDown,
  SmallSpinner,
  CheckActive,
  CheckInactive,
  CheckSquareActive,
  CheckSquareInactive,
  Minus,
  Eye,
  EyeSlash,
  ShelfDownArrow,
  ActivityPhoneIcon,
  PlayVoicemailIconDark,
  ActivityMarkAsReadIcon,
  ActivityPauseIcon,
  Compose,
  RadioActive,
  RadioInactive,
  EmptyRound,
  MinusRound,
  ModalX,
  ComposeIcon,
  ChevronRight,
  ChevronLeft,
  ChevronUp,
  ChevronLeft600,
  HourglassRound,
  DownloadRound,
  SelectAllIcon,
  SelectMessagesIcon,
  ActivityReplyIcon,
  ActivityMarkAsUnReadIcon,
  WarningIcon,
  UnmuteIcon,
  CommandKeyIcon,
  SelectIcon,
  CheckListIcon,
  UpKeyIcon,
  MoveIcon,
  MutedContextIcon,
  MutedFilledIcon,
  CloakedIcon,
  ArrowKeyRightIcon,
  DeleteActionBarIcon,
  InputClear,
  CloakedColorLogo,
  ExpansionArrow,
  BlockContact,
  CheckCancelled,
  PeopleIcon,
  MastercardIcon,

  // credit cards
  CardIcon,
  BankIcon,
  CardOutlineIcon,
  Visa,
  Mastercard,
  Discover,
  Amex,
  Hipercard,
  DinersClub,
};
