var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.MenuBlock,
    [
      _c(_setup.MenuItem, {
        attrs: { link: "/trash", name: "Trash", icon: "delete-trash" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }