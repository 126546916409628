var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "cd-wrapper" }, [
      _c("iframe", {
        attrs: {
          src: "https://daily.cloaked.app",
          title: "Cloaked Daily - The latest news and updates from Cloaked",
          allowtransparency: "true",
          allow: "clipboard-read; clipboard-write",
          frameborder: "0",
          height: "100%",
          width: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }