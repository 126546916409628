<script setup>
import UiBanner from "@/components/UiBanner.vue";
</script>

<template>
  <UiBanner class="auto-change-banner">
    Do not close your tabs while we AutoCloak your identities.
    <router-link :to="{ name: 'AutoCloak' }" class="auto-change-banner__link">
      Review AutoCloak status ›
    </router-link>
  </UiBanner>
</template>

<style lang="scss">
.auto-change-banner {
  &__link {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
}
</style>
