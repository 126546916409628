var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: `cat-${_setup.categoryId}`, staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("h1", [_vm._v(_vm._s(_setup.state.categoryName))]),
          _c(
            _setup.UiTooltip,
            { attrs: { title: "Edit name and delete", "align-x": "right" } },
            [
              _c(
                "span",
                {
                  attrs: {
                    "aria-id": "CategoryKabob",
                    id: "category-ellipsis-icon",
                  },
                  on: { click: _setup.showCategoryActionsMenu },
                },
                [_c(_setup.KabobIcon)],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(_setup.CloaksList, {
        ref: "cloaklist",
        attrs: {
          hoverText: "Add cloak to category",
          openAddCloaksModal: _setup.openAddCloaksModal,
          showModalOnHover: true,
          identityList: _setup.identityList,
        },
        on: {
          loadNextPage: _setup.loadNextPage,
          refresh: _setup.refreshCloaks,
        },
      }),
      _c(_setup.AddCloakToCategory, {
        attrs: {
          category: _setup.state.category,
          isModalVisible: _setup.state.showAddCloaksModal,
          identitiesInCategory: _setup.identityList,
        },
        on: {
          closeModal: _setup.closeAddCloaksModal,
          addCloaksToCategory: _setup.addCloaksToCategory,
        },
      }),
      _c(_setup.AddEditNewCategory, {
        attrs: {
          id: "add-edit-category",
          isModalVisible: _setup.state.isEditCategoryModalVisible,
          category: _setup.state.category,
        },
        on: { closeModal: _setup.closeEditCategoryModal },
      }),
      _c(_setup.CategoryActionsMenu, {
        attrs: {
          id: "category-actions-menu-id",
          isVisible: _setup.state.categoryActionsMenuVisible,
          category: _setup.state.category,
          getParentBoundingRect: _setup.getBoundingClientRect,
        },
        on: {
          handleEditCatName: _setup.handleEditCatName,
          handleDeleteCat: _setup.handleDeleteCat,
          close: _setup.closeCategoryActionsMenu,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }