<script setup>
import { defineProps } from "vue";
import InlineSvg from "@/components/InlineSvg.vue";

defineProps({
  identityType: {
    type: String,
    default: "favorite",
    validator: (value) => ["favorite", "ignored"].includes(value),
  },
});
</script>

<template>
  <div class="no-identities">
    <div class="no-identities__content">
      <template v-if="identityType === 'favorite'">
        <InlineSvg name="favorite-outline-thin" class="no-identities__icon" />
        <h2 class="no-identities__title">No favorites</h2>
        <p class="no-identities__text">
          To add an identity to your favorites, tap on an identity in your “All
          identities” view and click the
          <InlineSvg
            name="favorite-outline-thin"
            class="no-identities__text-icon"
          />
          icon on the details sidebar.
        </p>
      </template>
      <template v-else-if="identityType === 'ignored'">
        <InlineSvg name="ignored-outline-thin" class="no-identities__icon" />
        <h2 class="no-identities__title">No ignored identities</h2>
        <p class="no-identities__text">
          You can ignore identities you no longer want to hear from by clicking
          on a Cloaked identity from the “All identities” view and clicking the
          <InlineSvg name="bell" class="no-identities__text-icon" />
          icon in the Cloaked details panel.
        </p>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.no-identities {
  width: 100%;
  height: 100%;
  display: flex;

  &__content {
    margin: auto;
    max-width: 550px;
    padding: 0 24px;
  }

  $animation-enter: appear-bottom-10 0.3s forwards ease-out;

  &__icon {
    margin: auto;
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
    color: $color-primary-100;
    opacity: 0;
    animation: $animation-enter;
  }

  &__title {
    color: $color-primary-100;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
    margin: 24px auto 0;
    opacity: 0;
    animation: $animation-enter;
    animation-delay: 0.05s;
  }

  &__text {
    color: $color-primary-70;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.1px;
    margin: 12px auto 0;
    opacity: 0;
    animation: $animation-enter;
    animation-delay: 0.1s;

    &-icon {
      height: 20px;
      width: 24px;
      vertical-align: middle;
    }
  }
}
</style>
