<script setup>
import { defineProps, defineEmits, onMounted, watch } from "vue";

const props = defineProps({
  category: Object,
  isVisible: Boolean,
  id: String,
  getParentBoundingRect: Function,
});

const emit = defineEmits(["close", "handleEditCatName", "handleDeleteCat"]);

onMounted(() => {
  document.addEventListener("keyup", checkForEscapeKey);
});

function checkForEscapeKey(e) {
  if (e?.key?.toLowerCase() === "escape") {
    emit("close");
  }
}

function handleClick(e) {
  if (e.target.id === props.id) {
    emit("close", true);
  }
}

watch(
  () => props.isVisible,
  (value) => {
    const modal = document.getElementById(props.id);

    if (value) {
      const parentElementDimensions = props.getParentBoundingRect();
      const marginLeft = parentElementDimensions.left - 140;
      const marginTop = parentElementDimensions.top + 30;
      modal.style.marginLeft = `${marginLeft}px`;
      modal.style.marginTop = `${marginTop}px`;

      return modal.showModal();
    }

    return modal.close();
  }
);
</script>

<template>
  <div id="wrapper" @click="handleClick">
    <dialog :id="id" class="actionsMenu">
      <div id="actionsMenuInner">
        <div
          aria-id="KabobEditCategory"
          @click="emit('handleEditCatName', props.category)"
        >
          Edit category name
        </div>
        <div class="divider"></div>
        <div aria-id="KabobDeleteCategory" @click="emit('handleDeleteCat')">
          Delete
        </div>
      </div>
    </dialog>
  </div>
</template>

<style lang="scss" scoped>
// NOTE: if modal is showing on load, make sure you are not setting display: style in dialog class/style
.actionsMenu {
  width: 180px;
  background-color: $color-background;
  border-radius: 12px;
  font-size: 12px;
  box-shadow: -22.9px -8.90123px 26.7037px rgba($black, 0.05),
    13.3518px 12.35px 26.7037px rgba($black, 0.16);
  z-index: 1;
  padding: 2px;
  border: none;

  #actionsMenuInner {
    flex-direction: column;
    display: flex;
    cursor: pointer;
  }
  > div {
    flex-direction: column;
    display: flex;
    > div:first-child {
      color: $color-primary-100;
      &:hover {
        background-color: $color-primary-5;
      }
      &:after {
        height: 1px;
        background-color: $color-primary-5;
      }
      height: 38px;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      padding: 0 12px;
      margin: 4px;
      border-radius: 8px;
    }
    > div:last-child {
      color: $color-alert;
      height: 38px;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      padding: 0 12px;
      margin: 4px;
      border-radius: 8px;
      &:hover {
        background-color: $color-primary-5;
      }
    }
  }
  &::backdrop {
    background: transparent !important;
  }
}

.divider {
  height: 1px;
  background-color: $color-primary-20;
}

.wrapper {
  padding: 0;
}
</style>
