var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "review-toggle-all",
      class: { "review-toggle-all--disabled": _vm.disabled },
      on: { click: _vm.onToggleAll },
    },
    [
      _vm.areAllSelected
        ? _c("CheckboxChecked", { staticClass: "review-toggle-all__icon" })
        : _vm.areSomeSelected
        ? _c("CheckboxSome", { staticClass: "review-toggle-all__icon" })
        : _c("CheckboxUnchecked", { staticClass: "review-toggle-all__icon" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }