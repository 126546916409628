import { analyticsExport } from "@/mixins/Analytics";
import { cloakHelpers } from "@/scripts";
import { ANALYTICS_ENABLED } from "@/scripts/featureFlags";

export default {
  state: {
    // analyticsEnabled: true > TODO: move analytics to here
  },
  actions: {
    sendEvent({ rootState }, { eventName, payload }) {
      if (rootState.analytics && ANALYTICS_ENABLED) {
        return analyticsExport.methods.sendEvent(eventName, payload);
      }
    },
    sendCloakViewEvent({ dispatch }, cloak) {
      let eventName = "View Cloak Details";
      const passwordStatus = cloakHelpers.getLatestDetailType(
        "password",
        cloak
      );
      const emailStatus = cloakHelpers.getLatestDetailType("email", cloak);
      const phoneStatus = cloakHelpers.getLatestDetailType(
        "phone_number",
        cloak
      );

      const nameStatus = cloak.nickname
        ? "exists"
        : cloak.app_ref
        ? "exists"
        : "none";

      const usernameStatus = cloak.username ? "exists" : "none";

      const notesStatus = cloak.notes ? "exists" : "none";

      let payload = {
        cloakName: cloak.nickname ? cloak.nickname : cloak.app_ref,
        cloakTags: cloak.tags,
        cloakUrl: cloak.url,
        cloakId: cloak.id,
        emailStatus,
        phoneStatus,
        nameStatus,
        passwordStatus,
        usernameStatus,
        notesStatus,
      };
      dispatch("sendEvent", { eventName, payload });
    },
    sendEditCategoryEvent({ dispatch }, category) {
      const eventName = "Edit Category Content";
      const payload = {
        category_id: category.id,
      };
      dispatch("sendEvent", { eventName, payload });
    },
    sendDeleteCloakEvent({ dispatch }, cloak) {
      const eventName = "Cancel Delete Cloak";
      const payload = {
        cloak_name: cloak.nickname ? cloak.nickname : cloak.app_ref,
        cloak_tags: cloak.tags,
        cloak_url: cloak.url,
        cloak_id: cloak.id,
      };
      dispatch("sendEvent", { eventName, payload });
    },
    sendCancelDeleteCloakEvent({ dispatch }, cloak) {
      const eventName = "Delete Cloak";
      const payload = {
        cloak_name: cloak.nickname ? cloak.nickname : cloak.app_ref,
        cloak_tags: cloak.tags,
        cloak_url: cloak.url,
        cloak_id: cloak.id,
      };
      dispatch("sendEvent", { eventName, payload });
    },
    sendMuteCloakEvent({ dispatch }, { cloak, muted }) {
      const eventName = "Edit Cloak";
      const payload = {
        cloak_name: cloak.nickname ? cloak.nickname : cloak.app_ref,
        cloak_tags: cloak.tags,
        cloak_url: cloak.url,
        cloak_id: cloak.id,
        muted: muted,
      };
      dispatch("sendEvent", { eventName, payload });
    },
    sendFavoriteCloakEvent({ dispatch }, { cloak, favorited }) {
      const eventName = "Edit Cloak";
      const payload = {
        cloak_name: cloak.nickname ? cloak.nickname : cloak.app_ref,
        cloak_tags: cloak.tags,
        cloak_url: cloak.url,
        cloak_id: cloak.id,
        favorited: favorited,
      };
      dispatch("sendEvent", { eventName, payload });
    },
    sendForwardingCloakEvent(
      { dispatch },
      { cloak, forwarding_field, enabled }
    ) {
      const eventName = "Edit Cloak";
      const payload = {
        cloak_name: cloak.nickname ? cloak.nickname : cloak.app_ref,
        cloak_tags: cloak.tags,
        cloak_url: cloak.url,
        cloak_id: cloak.id,
        forwarding_field: forwarding_field,
        enabled,
      };
      dispatch("sendEvent", { eventName, payload });
    },
    sendStartCloakAnalytics({ dispatch }) {
      let eventName = "Start Cloak Creation";
      let payload = {};

      dispatch("sendEvent", { eventName, payload });
    },
    sendExitCloakAnalytics({ dispatch }) {
      let eventName = "Exit Cloak Creation";
      let payload = {};

      dispatch("sendEvent", { eventName, payload });
    },
    sendCreateCloakAnalytics({ dispatch, rootState }, { cloak }) {
      const email_type = rootState.profile.email_type;
      const eventName = "Create Cloak";
      const email_status = cloakHelpers.getLatestDetailType("email", cloak);
      const phone_status = cloakHelpers.getLatestDetailType(
        "phone_number",
        cloak
      );
      const password_status = cloakHelpers.getLatestDetailType(
        "password",
        cloak
      );
      const user_name_status = cloakHelpers.getLatestDetailType(
        "username",
        cloak
      );
      const name_status = cloak.nickname || cloak.app_ref ? "exists" : "none";
      const notes_status = cloak.notes != "" ? "exists" : "none";

      const payload = {
        cloak_name: cloak.nickname || cloak.app_ref,
        cloak_tags: cloak.tags,
        cloakurl: cloak.url,
        cloak_id: cloak.id,
        email_status,
        phone_status,
        name_status,
        password_status,
        user_name_status,
        notes_status,
        email_type,
      };
      dispatch("sendEvent", { eventName, payload });
    },
    sendOnboardingStepCompleteAnalytics({ dispatch }, { step }) {
      let eventName = "Onboarding Step Completed";
      let payload = {
        step: step.replace("STEP_", ""),
      };

      dispatch("sendEvent", { eventName, payload });
    },
  },
};
