<script setup>
import AutoChangeModalStart from "@/components/auto-change/AutoChangeModalStart.vue";
import AutoChangeModalComplete from "@/components/auto-change/AutoChangeModalComplete.vue";
import AutoChangeSpinner from "@/components/auto-change/AutoChangeSpinner.vue";
import { computed } from "vue";
import store from "@/store";
import {
  getIdentityStatus,
  getProcessStatus,
} from "@/components/auto-change/getStatus";
import { AutoChangeStatus } from "@/components/auto-change/utils";

const isAutoChangeModalStartOpen = computed({
  get() {
    return store.getters["autoChange/isAutoChangeModalStartOpen"];
  },
  set(newValue) {
    store.commit("autoChange/SET_IS_AUTO_CHANGE_MODAL_START_OPEN", newValue);
  },
});

const isAutoChangeModalCompleteOpen = computed({
  get() {
    return store.getters["autoChange/isAutoChangeModalCompleteOpen"];
  },
  set(newValue) {
    store.commit("autoChange/SET_IS_AUTO_CHANGE_MODAL_COMPLETE_OPEN", newValue);
  },
});

const spinnerStatus = computed(() => {
  const change = store.getters["autoChange/getAutoChangeProgress"];
  const verification =
    store.getters["autoChange/getAutoChangeVerificationProgress"];
  const update = store.getters["autoChange/getAutoChangeUpdatesProgress"];

  return getProcessStatus({ change, verification, update });
});

const identityStatuses = computed(() => {
  const change = store.getters["autoChange/getAutoChangeProgress"];
  const verification =
    store.getters["autoChange/getAutoChangeVerificationProgress"];
  const update = store.getters["autoChange/getAutoChangeUpdatesProgress"];

  return change.map((identity) => ({
    identityId: identity.identityId,
    status: getIdentityStatus({
      change: change.find((item) => item.identityId === identity.identityId),
      verification: verification.find(
        (item) => item.identityId === identity.identityId
      ),
      update: update.find((item) => item.identityId === identity.identityId),
    }),
  }));
});
</script>

<template>
  <div class="auto-change-state">
    <AutoChangeModalStart
      :identities="
        $store.getters['autoChange/getAutoChangeRequests']?.length ?? 0
      "
      v-model="isAutoChangeModalStartOpen"
      @auto-change="$store.dispatch('autoChange/startAutoChange')"
    />
    <AutoChangeModalComplete
      :success="
        identityStatuses.filter(
          (item) => item.status === AutoChangeStatus.SUCCESS
        ).length ?? 0
      "
      :error="
        identityStatuses.filter(
          (item) => item.status === AutoChangeStatus.ERROR
        ).length ?? 0
      "
      :skipped="
        identityStatuses.filter(
          (item) => item.status === AutoChangeStatus.SKIPPED
        ).length ?? 0
      "
      v-model="isAutoChangeModalCompleteOpen"
      @review="
        $route.name !== 'AutoCloak' && $router.push({ name: 'AutoCloak' })
      "
    />
    <AutoChangeSpinner
      @click="
        $route.name !== 'AutoCloak' && $router.push({ name: 'AutoCloak' })
      "
      :status="spinnerStatus"
      class="auto-change-state__spinner"
      v-if="$store.getters['autoChange/isInProgress']"
    />
  </div>
</template>

<style lang="scss">
.auto-change-state {
  &__spinner {
    position: fixed;
    z-index: 1000;
    right: 24px;
    bottom: 24px;
  }
}
</style>
