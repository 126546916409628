<template>
  <ModalTemplate :show="true" show-close-in-header @close="close" width="512px">
    <template #header>
      <h1>Verify your {{ displayType }}</h1>
    </template>
    <template #body>
      <p class="verification-cta">
        Enter the 6-digit code sent to <strong>{{ contact }}</strong>
      </p>

      <div>
        <OnboardingInputCode
          ref="code_input"
          :disabled="loading"
          :value="code"
          @input="code = $event"
          v-on="$listeners"
          @keydown.enter="verify"
        />
      </div>
    </template>
    <template #footer>
      <Button
        :loading="loading"
        :disabled="loading"
        type="secondary"
        @click="resend"
      >
        Resend code
      </Button>

      <Button @click="verify" :disabled="code && code.length !== 6">
        Verify
      </Button>
    </template>
  </ModalTemplate>
</template>

<script>
import { ModalTemplate, Button } from "@/components";
import OnboardingInputCode from "@/components/feature/onboarding/OnboardingInputCode";
import api from "@/api/api";
import store from "@/store";
export default {
  props: ["isOpen", "details", "index", "params"],
  components: {
    ModalTemplate,
    Button,
    OnboardingInputCode,
  },
  mounted() {
    this.resend();
  },
  data() {
    return {
      code: "",
      loading: false,
      token: null,
    };
  },
  computed: {
    type() {
      return this.params.type;
    },
    displayType() {
      return this.params.type === "email" ? "email address" : "phone number";
    },
    contact() {
      return this.params.contact;
    },
    id() {
      return this.params.id;
    },
  },
  methods: {
    confirm() {
      this.params.confirm();
    },
    close() {
      store.dispatch("closeModal");
    },
    set_code(value) {
      this.code = value;
    },
    tripToggle() {
      this.$emit("toggle");
    },
    resend() {
      this.loading = true;
      this.code = "";

      api()
        .get(`api/v1/${this.type}/${this.id}/verify/`)
        .then((verify_response) => {
          if (!verify_response.data.verified) {
            this.token = verify_response.data.session_token;
          }
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        })
        .catch(() => {
          this.loading = false;
          this.$toast.error("Something went wrong, please try again");
        });
    },
    verify() {
      const payloadKey = this.type === "email" ? "email" : "phone_number";
      const confirm = api().patch(`api/v1/${this.type}/${this.id}/verify/`, {
        security_code: this.code,
        [payloadKey]: this.contact,
        session_token: this.token,
      });

      confirm
        .then(() => {
          this.params.confirm(this);
          store.dispatch("closeModal");
          window.dispatchEvent(new CustomEvent("cloak:refresh-emails"));
        })
        .catch(() => {
          this.loading = false;
          this.$toast.error("Incorrect code, please try again");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.content .modal-body p.verification-cta {
  font-size: 16px;
}

.error {
  font-size: 12px;
  color: red;
  margin-top: -5px;
}
.error svg {
  position: relative;
  top: 2px;
  margin-right: 6px;
}
</style>
