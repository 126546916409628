var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "app-banners" },
    [
      _setup.isCancelled
        ? _c(_setup.UpgradeAccount)
        : _setup.isTrial
        ? _c(_setup.TrialAccount)
        : _setup.userDeleteIsScheduled
        ? _c(_setup.AccountDeleteBanner)
        : [
            _setup.isAutoChangeInProgress
              ? _c(_setup.AutoChangeBanner)
              : _c(_setup.ConfirmBanner, {
                  on: { visible: _setup.setVisible },
                }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }