var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "details-modal" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "modal-content" },
      [_c("VerticalTable", { attrs: { tableContent: _vm.tableDisplayInfo } })],
      1
    ),
    _c("div", { staticClass: "modal-message-wrapper" }, [
      _c("div", { staticClass: "modal-message" }, [
        _c(
          "div",
          { staticClass: "modal-img-wrap" },
          [_c("CloakedColorLogo")],
          1
        ),
        _c("div", [
          _vm._v("This message is secured by Cloaked two-way routing."),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h3", [_vm._v("Message details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }