var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "menu-item", class: { nested: _vm.nested } },
    [
      _setup.props.link
        ? _c(
            "router-link",
            {
              attrs: {
                to: _setup.props.link,
                "active-class": "active",
                "exact-path": "",
              },
            },
            [
              _c(_setup.InlineSvg, { attrs: { name: _setup.props.icon } }),
              _c("span", [_vm._v(_vm._s(_setup.props.name))]),
              _setup.props.count !== 0
                ? _c("i", { staticClass: "count" }, [
                    _vm._v(_vm._s(_setup.maxCount(_setup.props.count))),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _setup.props.add
        ? _c(
            "a",
            [
              _c(_setup.InlineSvg, { attrs: { name: "plus" } }),
              _c("span", [_vm._v(_vm._s(_setup.props.name))]),
            ],
            1
          )
        : _vm._e(),
      !_setup.props.link && !_setup.props.add ? _vm._t("default") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }