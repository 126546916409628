var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(_setup.CloaksList, {
        ref: "cloaklist",
        attrs: { title: "Ignored", identityList: _setup.identityList },
        on: { loadNextPage: _setup.loadNextPage },
        scopedSlots: _vm._u([
          {
            key: "no-results",
            fn: function () {
              return [
                _c(_setup.NoIdentities, {
                  attrs: { "identity-type": "ignored" },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }