<script setup>
import { computed, onBeforeMount } from "vue";
import store from "@/store";

import CloakedLogoLight from "@/assets/images/cloaked-logo-light.svg";
import CloakedLogoDark from "@/assets/images/cloaked-logo-dark.svg";
import { ChevronLeft600 } from "@/assets/icons";
import { SubscriptionService } from "@/api";
import {
  ACCOUNT_RECOVERY_NEW_MENU,
  RECOVERY_KEY_ENABLE,
} from "@/scripts/featureFlags";

onBeforeMount(() => {
  // NOTE: purposefully querying backend every time in case users subscription changes
  SubscriptionService.getSubscription();
});

const items = computed(() => {
  let items = [
    {
      name: "Account",
      to: { name: "settings.account" },
    },
    {
      name: "Personal information",
      to: { name: "settings.personal-information" },
    },
    {
      name: "Forwarding",
      to: { name: "settings.forwarding" },
    },
    // NOTE: disabling client side analytics
    // {
    //   name: "Privacy",
    //   to: { name: "settings.privacy" },
    // },
  ];

  if (!store.getters.isV2User) {
    items.push({
      name: "Email generation",
      to: { name: "settings.email-generation" },
    });
  }

  if (store.state.settings.subscription !== null) {
    items.push({
      name: "Subscription",
      to: { name: "settings.subscription" },
    });
  }
  if (
    (store.state.authentication.user.encryption_status === 1 ||
      store.getters.isV2User) &&
    RECOVERY_KEY_ENABLE &&
    !ACCOUNT_RECOVERY_NEW_MENU
  ) {
    items.push({
      name: "Recovery passphrase",
      to: { name: "settings.recovery" },
    });
  }
  if (store.state.authentication.user.cloaked_card_kyc_configured === true) {
    items.push({
      name: "Cloaked Pay",
      to: { name: "settings.cloaked-cards" },
    });
  }
  return items;
});
</script>
<template>
  <nav class="settings-navigation">
    <header class="settings-navigation__header">
      <router-link to="/" class="logo">
        <CloakedLogoLight class="hidden-dark" />
        <CloakedLogoDark class="hidden-light" />
      </router-link>
    </header>

    <div class="settings-navigation__back" aria-id="NavigationBack">
      <router-link to="/">
        <span class="settings-navigation__back-icon">
          <ChevronLeft600 />
        </span>
        Settings
      </router-link>
    </div>
    <div class="settings-navigation__items">
      <router-link
        v-for="(item, index) in items"
        :aria-id="`${item?.to?.name || ''}`"
        :key="index"
        :to="item.to"
        class="settings-navigation__item"
      >
        {{ item.name }}
      </router-link>
    </div>
  </nav>
</template>

<style lang="scss">
.settings-navigation {
  background: $color-surface;
  border-right: 1px solid $color-primary-10;
  display: flex;
  flex-direction: column;
  max-width: 240px;
  flex: 1 0 0;

  &__header {
    flex-shrink: 0;
    padding: 18px 14px;
    margin-top: 0;

    a {
      display: block;
      height: 26px;
    }

    svg {
      height: auto;
      width: 129px;
    }
  }

  &__back {
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    gap: 2px;
    padding: 0 10px;
    cursor: pointer;

    a {
      display: inline-flex;
      align-items: center;
    }
  }

  &__back-icon {
    width: 24px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-0.5px);
    color: $color-primary-100;
  }

  &__items {
    flex: 1 1 0;
    padding: 0 24px 0 26px;
    gap: 6px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top: 16px;
  }

  &__item {
    height: 33px;
    min-height: 33px;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    background: $color-surface;
    border-radius: 5px;
    align-items: center;
    padding: 0 10px;

    &:hover,
    &.router-link-exact-active {
      background: $color-primary-10;
    }
  }
}
</style>
