var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "activity-call-display-voicemail" }, [
    _c("div", { staticClass: "vm-play" }, [
      _vm.playing && _vm.playing.id === _vm.recording.id
        ? _c(
            "button",
            {
              staticClass: "playing",
              on: {
                click: function ($event) {
                  return _vm.pauseVoicemail(_vm.recording)
                },
              },
            },
            [_c("ActivityPauseIcon")],
            1
          )
        : _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.playVoicemail(_vm.recording)
                },
              },
            },
            [_c("PlayVoicemailIconDark")],
            1
          ),
      _c("span", [_vm._v("Play Voicemail")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }