<template>
  <div
    :class="{ 'add-padding': !logoShouldFillSpace }"
    class="card-icon-wrapper"
  >
    <Component :is="cardIcon" />
  </div>
</template>

<script>
import {
  Visa,
  Amex,
  Mastercard,
  Discover,
  Hipercard,
  DinersClub,
  CardOutlineIcon,
} from "@/assets/icons";

export default {
  name: "CardLogo",
  components: {
    Visa,
    Amex,
    Mastercard,
    Discover,
    Hipercard,
    DinersClub,
    CardOutlineIcon,
  },
  props: {
    cardType: { type: String, default: "" },
  },
  data() {
    return {};
  },
  computed: {
    logoShouldFillSpace() {
      if (!this.cardType) {
        return true;
      }

      const isSupportedType = this.allSupportedTypes.includes(this.cardType);
      const isAmex = ["american express", "american-express", "amex"].includes(
        this.cardType.toLowerCase()
      );
      return isAmex || !isSupportedType;
    },
    allSupportedTypes() {
      return [
        "american express",
        "american-express",
        "amex",
        "diners club",
        "diners-club",
        "discover",
        "hipercard",
        "mastercard",
        "visa",
      ];
    },
    cardIcon() {
      const cardTypeFormatted = (this.cardType || "").toLowerCase();

      if (
        ["american express", "american-express", "amex"].includes(
          cardTypeFormatted
        )
      ) {
        return Amex;
      } else if (["diners club", "diners-club"].includes(cardTypeFormatted)) {
        return DinersClub;
      } else if (cardTypeFormatted === "discover") {
        return Discover;
      } else if (cardTypeFormatted === "hipercard") {
        return Hipercard;
      } else if (cardTypeFormatted === "mastercard") {
        return Mastercard;
      } else if (cardTypeFormatted === "visa") {
        return Visa;
      } else {
        return CardOutlineIcon;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card-icon-wrapper {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  top: 52px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-primary-5;
  margin-right: 16px;
  &.add-padding {
    svg {
      padding: 3px;
      color: $color-primary-50;
    }
  }

  svg {
    max-width: 32px;
    max-height: 32px;
  }
}
</style>
