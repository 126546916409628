var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.CustomFieldFormWrapper,
    _vm._g(
      _vm._b(
        { staticClass: "custom-field-existing-address" },
        "CustomFieldFormWrapper",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(_setup.BorderInputSelect, {
        attrs: {
          label: "Choose existing address",
          placeholder: "Select",
          options: _vm.$attrs.existingAddresses,
          value: _setup.getAddressStringValue(_vm.$attrs.value.value),
          "dropdown-width": "300",
        },
        on: {
          input: function ($event) {
            _vm.$emit("input", {
              ..._vm.$attrs.value,
              label: _setup.getDefaultLabel("address"),
              value: { ...$event },
            })
          },
        },
        scopedSlots: _vm._u([
          {
            key: "selected-value",
            fn: function ({ value }) {
              return [_vm._v(" " + _vm._s(value) + " ")]
            },
          },
          {
            key: "option",
            fn: function ({ option }) {
              return [
                _c(
                  "span",
                  { staticClass: "custom-field-existing-address__option" },
                  [
                    _vm._v(
                      " " + _vm._s(_setup.getAddressStringValue(option)) + " "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c(_setup.ButtonAdd, {
        attrs: { label: "Or enter new address" },
        on: {
          click: function ($event) {
            return _vm.$emit("add-new")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }