<script setup>
import Button from "@/components/Button.vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import AppModalVideo from "@/components/ui/AppModalVideo.vue";
import AppModal from "@/components/ui/AppModal.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
import { reactive } from "vue";
import UserService from "@/api/actions/user-service";
import { HAS_SEEN_MFA_ANNOUNCEMENT } from "@/scripts/userFlags";
/* Turning off, replacing with Referrals announcement
Was previously displayed in src/routes/User.vue */
const state = reactive({
  visible: true,
});

function handleClose() {
  setTimeout(() => {
    state.visible = false;
    this.$store.dispatch("closeModal");
  }, 50);
  UserService.setFlag({
    name: HAS_SEEN_MFA_ANNOUNCEMENT,
    value: true,
  });
}
</script>

<template>
  <AppModal v-on="$listeners" v-bind="$attrs" v-if="state.visible">
    <AppModalContent>
      <AppModalVideo src="/videos/mfa-announcement.mp4" autoplay loop />
      <AppModalTitle>
        Introducing Cloaked two-factor authentication (2FA)
      </AppModalTitle>
      <AppModalParagraph>
        You can now protect your Cloaked account with 2FA! Head over to Settings
        to set up 2FA. Once you’re set up, you can authenticate Cloaked across
        all of your devices using SMS or email.
      </AppModalParagraph>
      <AppModalFooter>
        <Button type="secondary" @click="handleClose">Maybe later</Button>
        <router-link tag="span" to="/settings/account" exact-path>
          <Button @click="handleClose">Set up 2FA</Button>
        </router-link>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>
