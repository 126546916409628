import api from "@/api/api";
import store from "@/store";

const endpoint = "/api/v2/analytics/";
const context = "dashboard";

import { ANALYTICS_ENABLED } from "@/scripts/featureFlags";

export const analyticsExport = {
  methods: {
    async sendEvent(eventName, payload) {
      let analyticsData = {
        event: eventName,
        context: context,
        payload: payload,
      };
      try {
        if (store.state.analytics && ANALYTICS_ENABLED) {
          await api().post(endpoint, analyticsData);
        }
      } catch {
        // console.log("error: ", error);
      }
    },
  },
};

export const analyticsExportAsync = async (
  eventName,
  payload,
  analyticsEnabled
) => {
  let analyticsData = {
    event: eventName,
    context: context,
    payload: payload,
  };
  try {
    if (analyticsEnabled && ANALYTICS_ENABLED) {
      await api().post(endpoint, analyticsData);
    }
  } catch {
    // console.log("error: ", error);
  }
};
