var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [
        _vm._v(
          " Below is a list of all fees for Cloaked Pay Prepaid Cards. All fees assessed by us are deducted from the available balance in your card account. If your card account does not have sufficient funds available to cover a fee, the amount of such fee will be deducted from any funds subsequently loaded to your card account. The fees that apply to your card account are as follows: "
        ),
      ]),
      _c("p", [
        _vm._v(
          " Cloaked charges a subscription fee, as provided at sign-up, for access to an array of Cloaked products and services. This fee is not specific to your Card use. "
        ),
      ]),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("All Fees")]),
            _c("th", [_vm._v("Amount")]),
            _c("th", [_vm._v("Details")]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", { attrs: { colspan: "3" } }, [_vm._v("Get Started")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Card Purchase")]),
            _c("td", [_vm._v("$0")]),
            _c("td", [
              _vm._v(
                "Fee charged for purchase of the Cloaked Pay Prepaid Card."
              ),
            ]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Secondary Card Purchase Fee")]),
            _c("td", [_vm._v("$0")]),
            _c("td", [
              _vm._v(
                " Fee charged for purchase of secondary Cloaked Pay Prepaid Card. "
              ),
            ]),
          ]),
          _c("tr", [
            _c("td", { attrs: { colspan: "3" } }, [_vm._v("Monthly Usage")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Monthly Fee")]),
            _c("td", [_vm._v("$0")]),
            _c("td", [
              _vm._v(
                " Cloaked Card availability is included in your subscription to Cloaked, which includes all Cloaked core and Cloaked Card products and services. If you are eligible for Cloaked Card, you can gain Cloaked Card access with an active and paid Cloaked subscription. "
              ),
            ]),
          ]),
          _c("tr", [
            _c("td", { attrs: { colspan: "3" } }, [_vm._v("Add Money")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Funding from a Bank Account")]),
            _c("td", [_vm._v("$0")]),
            _c("td"),
          ]),
          _c("tr", [
            _c("td", { attrs: { colspan: "3" } }, [_vm._v("Information")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Automated & Live Agent Phone Calls")]),
            _c("td", [_vm._v("$0")]),
            _c("td", [
              _vm._v(
                "No fee for each customer service contact with a live agent."
              ),
            ]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Email and Text Message Alerts")]),
            _c("td", [_vm._v("$0")]),
            _c("td", [_vm._v("Standard text messaging rates may apply.")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Mobile Application")]),
            _c("td", [_vm._v("$0")]),
            _c("td", [
              _vm._v("Standard data rates and service carrier fees may apply."),
            ]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Mailed Periodic Statement Fee")]),
            _c("td", [_vm._v("$0")]),
            _c("td", [
              _vm._v("One request per month is included at no charge."),
            ]),
          ]),
          _c("tr", [
            _c("td", { attrs: { colspan: "3" } }, [
              _vm._v("Using your card outside the U.S."),
            ]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Foreign Transaction Fee")]),
            _c("td", [_vm._v("$0")]),
            _c("td", [
              _vm._v(
                " You may be charged a fee by any retailers or financial institutions involved in your transaction. "
              ),
            ]),
          ]),
          _c("tr", [_c("td", { attrs: { colspan: "3" } }, [_vm._v("Other")])]),
          _c("tr", [
            _c("td", [_vm._v("Inactivity Fee")]),
            _c("td", [_vm._v("$0")]),
            _c("td"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }