var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { attrs: { "aria-id": "ActivityLoadingState" } }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "items" },
      _vm._l(10, function (index) {
        return _c("div", { key: index, staticClass: "item" }, [
          _vm._m(1, true),
          _vm._m(2, true),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("h1", [_vm._v("Loading...")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "avatar" }, [_c("div")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content" }, [_c("div"), _c("div")])
  },
]
render._withStripped = true

export { render, staticRenderFns }