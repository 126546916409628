import axios from "axios";
import store from "@/store";
import info from "@/scripts/info";
import { refreshToken } from "@/scripts/actions/auth";
import {
  auto_encrypt_receive,
  auto_encrypt_send,
} from "@/scripts/actions/encryption";
let browser;
let os;
const userAgent = window.navigator.userAgent;

const generate = (source, customHeaders) => {
  return axios.create({
    baseURL: global.ENV.VUE_APP_API,
    cancelToken: source && source.token,
    headers: { ...headers(), ...customHeaders },
  });
};

export const headers = () => {
  const matches = window.ENV.VUE_APP_API.match(/http(?:s)?:\/\/([a-zA-Z]+)/);
  const env = matches[1];
  const headers = {
    "content-type": "application/json",
    "Cloaked-App-Version": `dashboard-${info.version}`,
    "Cloaked-Environment": env,
    "Cloaked-Device-Model": get_browser(),
    "Cloaked-Platform": "dashboard",
    "Cloaked-Os-Version": get_os(),
  };
  if (store.getters.auth_token !== "") {
    headers.Authorization = `Bearer ${store.getters.auth_token}`;
  }
  return headers;
};

const get_os = () => {
  if (os) {
    return os;
  }
  if (userAgent.indexOf("Windows NT 11") !== -1) os = "Windows 11";
  if (userAgent.indexOf("Windows NT 10") !== -1) os = "Windows 10";
  if (userAgent.indexOf("Windows NT 6.3") !== -1) os = "Windows 8.1";
  if (userAgent.indexOf("Windows NT 6.2") !== -1) os = "Windows 8";
  if (userAgent.indexOf("Windows NT 6.1") !== -1) os = "Windows 7";
  if (userAgent.indexOf("Windows NT 6.0") !== -1) os = "Windows Vista";
  if (userAgent.indexOf("Windows NT 5.1") !== -1) os = "Windows XP";
  if (userAgent.indexOf("Windows NT 5.0") !== -1) os = "Windows 2000";
  if (userAgent.indexOf("Mac") !== -1) os = "Apple";
  if (userAgent.indexOf("X11") !== -1) os = "UNIX";
  if (userAgent.indexOf("Linux") !== -1) os = "Linux";
  return os;
};

const get_browser = () => {
  if (browser) {
    return browser;
  }
  if (userAgent.match(/chrome|chromium|crios/i)) {
    browser = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browser = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browser = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browser = "opera";
  } else if (userAgent.match(/edg/i)) {
    browser = "edge";
  } else {
    browser = null;
  }
  return browser;
};

export default (anonymous, customHeaders) => {
  if (!store.getters.auth_token && !anonymous) {
    return {
      get() {
        return Promise.reject();
      },
      post() {
        return Promise.reject();
      },
      put() {
        return Promise.reject();
      },
    };
  }
  const api = generate(undefined, customHeaders);
  let stored_config;
  api.interceptors.request.use((config) => {
    stored_config = config;
    return new Promise((resolve) => {
      auto_encrypt_send(config).then((updated) => {
        resolve(updated);
      });
    });
  });
  api.interceptors.response.use(
    (response) => {
      store.commit("netComplete");
      return auto_encrypt_receive(response).then((updated) => {
        return updated;
      });
    },
    (error) => {
      if (error && error.response && error.response.status) {
        const code = error.response.status;
        if (code === 401) {
          if (!error.response.config.url.includes("token")) {
            return refreshToken().then(() => {
              const retry = generate();
              return retry[stored_config.method](
                stored_config.url,
                stored_config.data
              );
            });
          } else {
            return store.dispatch("logout");
          }
        } else if (code === 418 || code === 429) {
          window.$vue.$toast.error(
            "Cannot complete request, please try again later"
          );
          throw error;
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    }
  );
  return api;
};
