<script setup>
import AppModal from "@/components/ui/AppModal.vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
import Button from "@/components/Button.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
</script>

<template>
  <AppModal v-on="$listeners" v-bind="$attrs">
    <AppModalContent>
      <AppModalTitle>
        Are you sure you want to delete this link?
      </AppModalTitle>
      <AppModalParagraph>
        You will need to generate a new link and password if you want to share
        this identity again.
      </AppModalParagraph>
      <AppModalFooter>
        <Button type="secondary" @click="$emit('open-published')"
          >Cancel</Button
        >
        <Button
          type="danger"
          @click="$emit('delete')"
          :loading="$attrs.isLoading"
          >Yes, delete</Button
        >
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>
