var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.MenuBlock,
    { attrs: { title: "Categories", toggleState: true } },
    [
      _vm._l(_setup.categories, function (category) {
        return _c(_setup.MenuItem, {
          key: category.id,
          attrs: {
            link: `/category/${category.id}`,
            name: category.name,
            icon: "stacked-blocks",
          },
        })
      }),
      _setup.categories.length < 32
        ? _c(_setup.MenuItem, {
            attrs: { add: "", name: "Add new..." },
            nativeOn: {
              click: function ($event) {
                return _setup.emit("addCategory")
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }