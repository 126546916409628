import { PDFDocument } from "pdf-lib";
import copylib from "copy-to-clipboard";
import AuthService from "@/api/actions/auth-service";

import { authDecrypt } from "@/scripts/actions/encryption";
export const copy = (text, $event) => {
  copylib(text);
  const div = document.createElement("div");
  div.innerHTML = "Copied";
  div.classList.add("copy-div");
  div.style.left = `${$event.clientX + 5}px`;
  div.style.top = `${
    $event.clientY +
    ($event.currentTarget.scrollTop || document.documentElement.scrollTop) +
    5
  }px`;
  div.addEventListener("mouseout", () => {
    if (div) {
      document.body.removeChild(div);
    }
  });
  document.body.appendChild(div);
  setTimeout(() => {
    if (div) {
      document.body.removeChild(div);
    }
  }, 650);
};

export const copyToClipboard = (text) => {
  copylib(text);
};

export const downloadFile = ({
  fileContents = "",
  fileName = "file.txt",
  mimeType = "text/plain",
  createBlob = true,
}) => {
  let data = fileContents;
  const link = document.createElement("a");
  link.setAttribute("download", fileName);
  link.style.display = "none";
  link.rel = "noopener";

  if (createBlob) {
    data = new Blob([fileContents], {
      type: mimeType,
    });
  }

  const blobURL = window.URL.createObjectURL(data);
  link.href = blobURL;

  if (typeof link.download === "undefined") {
    link.setAttribute("target", "_blank");
  }

  link.click();

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);
};

/* Wait for an element to appear on the DOM, then return it */
export const waitForElmmentOnPage = (selector) => {
  return new Promise((resolve) => {
    if (document.getElementById(selector)) {
      return resolve(document.getElementById(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.getElementById(selector)) {
        resolve(document.getElementById(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const createPDF = async (key, supportEmail, username = null) => {
  const res = await fetch("/templates/recovery.pdf");
  const buffer = await res.arrayBuffer();
  const pdfDoc = await PDFDocument.load(buffer);
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  firstPage.drawText(key, { x: 57, y: 505, size: 15 });
  firstPage.drawText(username, { x: 57, y: 570, size: 15 });
  firstPage.drawText(`support.${supportEmail}`, { x: 57, y: 250, size: 15 });
  const pdfBytes = await pdfDoc.save();
  downloadFile({
    fileContents: pdfBytes,
    fileName: "recovery-key.pdf",
    mimeType: "application/pdf",
  });
};

export const downloadRecoveryKeyAndCreatePdf = (
  supportEmail,
  username = null
) => {
  AuthService.passPhrase()
    .then(({ data }) => {
      const { results } = data;
      if (results && results[0]) {
        authDecrypt(results[0].key).then((key) => {
          createPDF(key, supportEmail, username);
        });
      } else {
        throw "error";
      }
    })
    .catch(() => {
      throw "error";
    });
};

export default {
  copy,
  copyToClipboard,
  downloadFile,
  waitForElmmentOnPage,
  createPDF,
  downloadRecoveryKeyAndCreatePdf,
};
