<script setup>
import { onMounted } from "vue";
import router from "@/routes/router";
import { useRoute } from "vue-router/composables";

const route = useRoute();

onMounted(() => {
  if (!route.path.includes("auth") && !route.path.includes("onboard")) {
    router.push({
      name: "login",
    });
  }
});
</script>

<template>
  <main>
    <router-view ref="router" default="login"></router-view>
  </main>
</template>

<style lang="scss" scoped>
body,
html,
main {
  background-color: $color-surface;
}
</style>
