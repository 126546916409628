var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: {
            handler: (e) => _setup.handleBlur(e),
            events: ["mousedown"],
          },
          expression:
            "{\n    handler: (e) => handleBlur(e),\n    events: ['mousedown'],\n  }",
        },
      ],
      staticClass: "cloak-details-input-row",
      class: {
        "cloak-details-input-row--error": _setup.props.error,
        "cloak-details-input-row--has-value": !!_setup.props.value,
        "cloak-details-input-row--warning": _setup.props.warning,
        "cloak-details-input-row--is-disabled": _setup.props.disabled,
        "cloak-details-input-row--is-focused": _setup.state.isFocused,
        "cloak-details-input-row--is-highlighted": _setup.props.highlighted,
        "cloak-details-input-row--is-textarea": _setup.props.textarea,
        "cloak-details-input-row--menu-open": _setup.props.isMenuOpen,
        "cloak-details-input-row--has-tooltip": _setup.slots.tooltip,
      },
    },
    [
      _c("div", { staticClass: "cloak-details-input-row__label" }, [
        _vm._v(" " + _vm._s(_setup.props.label) + " "),
      ]),
      _c(
        "div",
        {
          staticClass: "cloak-details-input-row__input-wrapper",
          attrs: {
            "aria-id": `CloakedDetail${_setup.props.label || ""}RowWrapper`,
          },
          on: { click: _setup.handleClickInputWrapper },
        },
        [
          _vm._t("input-before"),
          _c(
            "div",
            { staticClass: "cloak-details-input-row__icon" },
            [
              _setup.props.loading
                ? _c(_setup.InputSpinner)
                : _setup.props.warning
                ? _c(
                    _setup.UiTooltip,
                    {
                      attrs: {
                        title: _setup.props.warningTooltipMessage,
                        width: "192",
                        "align-x": "center",
                        position: "top",
                      },
                    },
                    [_c(_setup.WarningIcon)],
                    1
                  )
                : _vm._t("icon"),
            ],
            2
          ),
          _c(
            _setup.UiTooltip,
            {
              staticClass: "cloak-details-input-row__input",
              attrs: {
                title: _setup.props.tooltipMessage,
                "align-x": "center",
                isOnSharedPage: _setup.props.isOnSharedPage,
              },
              scopedSlots: _vm._u(
                [
                  _setup.slots.tooltip
                    ? {
                        key: "content",
                        fn: function () {
                          return [_vm._t("tooltip")]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              _setup.props.loading
                ? _c(
                    "div",
                    { staticClass: "cloak-details-input-row__loading-message" },
                    [_c("span", [_vm._v(_vm._s(_setup.props.loadingMessage))])]
                  )
                : _vm._t(
                    "input",
                    function () {
                      return [
                        _c(_setup.CloakIdentifierIcon, {
                          attrs: { fieldType: "cloaked", locked: true },
                        }),
                        _setup.props.textarea
                          ? _c("textarea", {
                              ref: "inputField",
                              attrs: {
                                autocomplete: "off",
                                maxlength: _setup.props.maxlength,
                                "data-lpignore": "true",
                                rows: _setup.state.textareaRows,
                                placeholder: _setup.props.placeholder,
                                disabled: _setup.props.disabled,
                                "aria-id": `Add${
                                  _setup.props.label || ""
                                }Input`,
                              },
                              domProps: { value: _setup.props.value },
                              on: {
                                input: (e) =>
                                  _setup.emit("input", e.target.value),
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  $event.stopPropagation()
                                },
                                focus: _setup.handleFocus,
                              },
                            })
                          : _c("input", {
                              ref: "inputField",
                              attrs: {
                                autocomplete: "new-password",
                                "data-lpignore": "true",
                                "data-form-type": "other",
                                maxlength: _setup.props.maxlength,
                                type: _setup.props.type,
                                placeholder: _setup.props.placeholder,
                                disabled: _setup.props.disabled,
                                "aria-id": `Add${
                                  _setup.props.label || ""
                                }Input`,
                              },
                              domProps: { value: _setup.props.value },
                              on: {
                                input: (e) =>
                                  _setup.emit("input", e.target.value),
                                focus: _setup.handleFocus,
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.$emit("save")
                                },
                              },
                            }),
                        _setup.props.textarea && !_setup.props.loading
                          ? _c(
                              "div",
                              {
                                ref: "textareaGhost",
                                staticClass:
                                  "cloak-details-input-row__textarea-ghost",
                              },
                              _vm._l(
                                _setup.textAreaGhostLines,
                                function (line, lineIndex) {
                                  return _c("div", {
                                    key: lineIndex,
                                    domProps: { textContent: _vm._s(line) },
                                  })
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                    { isEditable: _setup.props.isEditable }
                  ),
            ],
            2
          ),
          _setup.props.showLockIcon
            ? _c(
                _setup.UiTooltip,
                {
                  attrs: {
                    title: _setup.props.numberLockingToolTipText,
                    "align-x": "center",
                    isOnSharedPage: false,
                    "max-width": "255",
                  },
                },
                [_vm._t("numberLocking")],
                2
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "cloak-details-input-row__actions" },
            [_vm._t("actions")],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }