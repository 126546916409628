var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "main",
    { class: { hidePadding: _setup.hidePadding } },
    [_c("router-view", { ref: "router" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }