/* Constants for email and phone contacts, passed to <ContactsViewEdit /> and used in modal. Do not change unless API endpoint has changes */
export const CONTACT_TYPE = {
  PHONE: "phone",
  EMAIL: "email",
};
export const CONTACT_CARD_MODE = {
  VIEW: "view",
  EDIT: "edit",
  BLOCK: "block",
};
export const CONTACT_STATUS = {
  APPROVED: "approved",
  BLOCKED: "blocked",
  UNKNOWN: "unknown",
};
export const CONTACT_ACTION = {
  APPROVE: "approve",
  BLOCK: "block",
};

export const FILE_PERMISSIONS = {
  TEXT_ALLOWED: ["png", "jpg", "jpeg", "pdf"],
  BANNED: [
    ".ade: application/msaccess",
    ".adp: application/msaccess",
    ".apk: application/vnd.android.package-archive",
    ".appx: application/vnd.ms-appx",
    ".appxbundle: application/vnd.ms-appx-bundle",
    ".bat: application/x-msdownload",
    ".cab: application/vnd.ms-cab-compressed",
    ".chm: application/vnd.ms-htmlhelp",
    ".cmd: text/cmd",
    ".com: application/x-msdownload",
    ".cpl: application/x-cpl",
    ".diagcab: application/vnd.ms-cab-diagnostic",
    ".diagcfg: application/vnd.ms-cab-diagnostic-config",
    ".diagpack: application/vnd.ms-cab-diagnostic-package",
    ".dll: application/x-msdownload",
    ".dmg: application/x-apple-diskimage",
    ".ex: application/x-msdownload",
    ".ex_: application/x-msdownload",
    ".exe: application/x-msdownload",
    ".hta: application/hta",
    ".img: application/octet-stream",
    ".ins: application/x-internet-signup",
    ".iso: application/octet-stream",
    ".isp: application/x-internet-signup",
    ".jar: application/java-archive",
    ".jnlp: application/x-java-jnlp-file",
    ".js: application/javascript",
    ".jse: application/javascript",
    ".lib: application/octet-stream",
    ".lnk: application/x-ms-shortcut",
    ".mde: application/msaccess",
    ".msc: application/octet-stream",
    ".msi: application/x-msdownload",
    ".msix: application/x-msix-package",
    ".msixbundle: application/vnd.ms-appx-msixbundle",
    ".msp: application/mspatch",
    ".mst: application/octet-stream",
    ".nsh: application/octet-stream",
    ".pif: application/x-ms-shortcut",
    ".ps1: application/octet-stream",
    ".scr: application/x-msdownload",
    ".sct: application/x-sct",
    ".shb: application/x-shockwave-flash",
    ".sys: application/octet-stream",
    ".vb: application/octet-stream",
    ".vbe: application/octet-stream",
    ".vbs: text/vbscript",
    ".vhd: application/x-virtualbox-vhd",
    ".vxd: application/vnd.visio",
    ".wsc: text/scriptlet",
    ".wsf: application/x-wsf",
    ".wsh: application/x-wsh",
    ".xll: application/xll.ms-excel",
  ],
};
// Constants for stripe URLs
// TODO: move to env vars
export const STRIPE_MANAGE_URL_OPTIONS = {
  development: "https://billing.stripe.com/p/login/test_28o2bdfPQ5jn5iw6oo",
  staging: "https://billing.stripe.com/p/login/test_28o2bdfPQ5jn5iw6oo",
  main: "https://billing.stripe.com/p/login/aEU9B86ox1gk8ZqbII",
  production: "https://billing.stripe.com/p/login/aEU9B86ox1gk8ZqbII",
};

export const STRIPE_MANAGE_URL =
  STRIPE_MANAGE_URL_OPTIONS[process.env.NODE_ENV];

export const STRIPE_RESTRICTED_PLAN_NAME = "Restricted Tier";
