var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    !!_setup.status ? "span" : _setup.InputCheckbox,
    _vm._g(
      _vm._b(
        {
          tag: "Component",
          staticClass: "auto-change-cell",
          attrs: { id: _vm.field?.fieldType ?? "" },
        },
        "Component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      !_vm.isSupported
        ? _c("span", { staticClass: "auto-change-cell__field-text" }, [
            _vm._v(" Not supported "),
          ])
        : !_vm.field?.oldValue
        ? _c("span", { staticClass: "auto-change-cell__field-text" }, [
            _vm._v(" - "),
          ])
        : [
            _c("transition", { attrs: { name: "fade-100", mode: "out-in" } }, [
              _setup.status === "needsReview"
                ? _c(
                    "div",
                    {
                      key: "needsReview",
                      staticClass: "auto-change-cell__needs-review",
                    },
                    [
                      _c(_setup.IconWarning, {
                        staticClass: "auto-change-cell__needs-review-icon",
                      }),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "auto-change-cell__needs-review-title",
                          },
                          [_vm._v("Needs review")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "auto-change-cell__needs-review-message",
                          },
                          [_vm._v(" Verify information to finish AutoCloak ")]
                        ),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      key: "progress",
                      staticClass: "auto-change-cell__progress",
                    },
                    [
                      _setup.status
                        ? _c(_setup.AutoChangeIcon, {
                            staticClass: "auto-change-cell__status",
                            attrs: { status: _setup.status },
                          })
                        : _vm._e(),
                      _vm.field?.fieldType === "password"
                        ? _c(
                            "div",
                            {
                              staticClass: "auto-change-cell__field",
                              class: {
                                "auto-change-cell__field--done":
                                  _setup.isNewValueShown,
                              },
                            },
                            [
                              _c(_setup.PasswordText, {
                                staticClass:
                                  "auto-change-cell__field-text auto-change-cell__field--new-value",
                                attrs: {
                                  password: _vm.field?.newValue ?? "",
                                  "is-hidden": _vm.hidesSecretValues,
                                },
                              }),
                              _c(_setup.PasswordText, {
                                staticClass:
                                  "auto-change-cell__field-text auto-change-cell__field--old-value",
                                attrs: {
                                  password: _vm.field?.oldValue ?? "",
                                  "is-hidden": _vm.hidesSecretValues,
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "auto-change-cell__field",
                              class: {
                                "auto-change-cell__field--done":
                                  _setup.isNewValueShown,
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "auto-change-cell__field-text auto-change-cell__field--new-value",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.field?.newValue) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "auto-change-cell__field-text auto-change-cell__field--old-value",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.field?.oldValue) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }