var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "custom-field",
      class: { "custom-field--menu-open": _setup.isMenuOpen },
    },
    [
      _c(
        _setup.UiTooltip,
        {
          staticClass: "custom-field__content",
          attrs: { title: _setup.tooltip, "align-x": "center" },
          on: { click: _setup.copy, mouseleave: _setup.resetTooltip },
        },
        [
          _c(
            "div",
            { staticClass: "custom-field__icon" },
            [_c(_setup.CustomFieldIcon, { attrs: { type: _vm.type } })],
            1
          ),
          _c("div", [
            _vm.label
              ? _c("div", { staticClass: "custom-field__label" }, [
                  _vm._v(" " + _vm._s(_vm.label) + " "),
                ])
              : _vm._e(),
            _vm.value
              ? _c(
                  "div",
                  {
                    staticClass: "custom-field__value",
                    style: _setup.valueStyle,
                  },
                  [
                    _vm.isSecret
                      ? _c(
                          _setup.SecretValue,
                          { attrs: { "is-hidden": _setup.isHidden } },
                          [
                            _vm.type === "url"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "custom-field__link",
                                    attrs: {
                                      href: _vm.value,
                                      target: "_blank",
                                      rel: "noreferrer",
                                    },
                                  },
                                  [_vm._v(_vm._s(_setup.stringValue))]
                                )
                              : [_vm._v(_vm._s(_setup.stringValue))],
                          ],
                          2
                        )
                      : _vm.type === "url"
                      ? _c(
                          "a",
                          {
                            staticClass: "custom-field__link",
                            attrs: {
                              href: _vm.value,
                              target: "_blank",
                              rel: "noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_setup.stringValue))]
                        )
                      : [_vm._v(_vm._s(_setup.stringValue))],
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "custom-field__actions" },
        [
          _vm.isSecret
            ? _c(
                _setup.CloakInfoRowButton,
                {
                  attrs: { icon: "" },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _setup.isHidden = !_setup.isHidden
                    },
                  },
                },
                [
                  _c(_setup.SecretToggle, {
                    attrs: { value: _setup.isHidden },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            _setup.UiMenu,
            {
              attrs: { width: "188px", placement: "bottom-end" },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(_setup.UiMenuButton, {
                        attrs: { title: "Edit" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("edit")
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [_c(_setup.EditPencilIcon)]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c(_setup.UiMenuButton, {
                        attrs: { title: "Copy" },
                        on: { click: _setup.copy },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [_c(_setup.CopyIcon)]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c(_setup.UiMenuSeparator),
                      _c(_setup.UiMenuButton, {
                        attrs: { title: "Delete" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("delete")
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c(_setup.DeleteTrashIcon, {
                                  attrs: { height: "15px", width: "15px" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _setup.isMenuOpen,
                callback: function ($$v) {
                  _setup.isMenuOpen = $$v
                },
                expression: "isMenuOpen",
              },
            },
            [
              _c(
                _setup.CloakInfoRowButton,
                {
                  staticClass: "custom-field__menu",
                  attrs: { icon: "", active: _setup.isMenuOpen },
                },
                [_c(_setup.KabobIcon)],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }