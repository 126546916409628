var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    _vm._g({ staticClass: "app-close-button" }, _vm.$listeners),
    [
      _c(_setup.ModalX, {
        staticClass: "app-modal-close",
        class: { "app-modal-close--absolute": _setup.props.isAbsolute },
        style: { color: _setup.props.color },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }