<template>
  <section class="activity-anonymous-convo-header">
    <div>
      <div class="user-icon"><UserIcon /></div>
      <h1>{{ anonymPhone }}</h1>
      <h2>{{ contact?.location || "Unknown Location" }}</h2>
      <button @click="$emit('click')">View "{{ name }}" Identity</button>
    </div>
  </section>
</template>
<script>
import { UserIcon } from "@/assets/icons";
import { anonymPhone } from "@/scripts/format";
export default {
  name: "ActivityAnonymousContactHeader",
  components: {
    UserIcon,
  },
  props: ["contact", "name"],
  computed: {
    anonymPhone() {
      return anonymPhone(this.contact?.original_phone);
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.activity-anonymous-convo-header {
  padding: 32px;
  border-bottom: 1px solid $color-primary-20;
  display: flex;
  flex: 1;
  justify-content: center;
  color: $color-primary-100;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .user-icon {
    background-color: $color-primary-10;
    color: $color-primary-100;
    border-radius: 999px;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 35px;
      width: 33px;
    }
  }
  h1 {
    margin: 8px 0 4px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
    color: $color-accent;
  }
  h2 {
    margin: 0 0 8px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $color-primary-50;
  }
  button {
    padding: 4px 12px;
    background-color: $color-primary-5;
    color: $color-primary-100;
    border-radius: 999px;
    border: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
  }
}
</style>
