var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.SettingsChildPage,
    {
      scopedSlots: _vm._u([
        {
          key: "aside",
          fn: function () {
            return [
              _c(_setup.SettingsTitle, [_vm._v("Cloaked Pay")]),
              _c(_setup.SettingsParagraph, [
                _c("p", [
                  _vm._v(
                    " Keep your personal cards protected with Cloaked Pay - powered by Mastercard. "
                  ),
                ]),
                _c("p", [
                  _vm._v(" For more information, please take a look at the "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.cloaked.app/terms-of-service",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Terms of Service")]
                  ),
                  _vm._v(" and "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.cloaked.app/privacy-policy",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Privacy Policy")]
                  ),
                  _vm._v(". "),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c(_setup.CloakedCards)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }