<template>
  <div class="attachment-container">
    <a :href="attachment.url" class="download-link" target="_new" download>
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 0.262695C4.65576 0.262695 4.40674 0.504395 4.40674 0.848633V8.15088L4.45801 9.37402L2.63428 7.35254L1.58691 6.33447C1.48438 6.23193 1.33057 6.17334 1.17676 6.17334C0.847168 6.17334 0.605469 6.42969 0.605469 6.75195C0.605469 6.90576 0.664062 7.04492 0.788574 7.18408L4.56055 10.9634C4.68506 11.0952 4.83887 11.1685 5 11.1685C5.16113 11.1685 5.31494 11.0952 5.43945 10.9634L9.21143 7.18408C9.33594 7.04492 9.39453 6.90576 9.39453 6.75195C9.39453 6.42969 9.15283 6.17334 8.82324 6.17334C8.66943 6.17334 8.51562 6.23193 8.41309 6.33447L7.36572 7.35254L5.54199 9.37402L5.59326 8.15088V0.848633C5.59326 0.504395 5.34424 0.262695 5 0.262695Z"
          fill="#171717"
        />
      </svg>
    </a>
    <section class="attachment">
      <div>
        <div class="flex-row">
          <MediaImage :attachment="attachment" v-if="is_image" />
          <GenericMedia :attachment="attachment" v-else />
        </div>
      </div>
      <div class="media-name">{{ attachment.name }}</div>
    </section>
  </div>
</template>
<script>
import MediaImage from "./Image";
import GenericMedia from "./GenericMedia";
export default {
  name: "Attachment",
  props: ["attachment"],
  components: {
    MediaImage,
    GenericMedia,
  },
  computed: {
    is_image() {
      if (
        this.attachment.content_type &&
        this.attachment.content_type.includes("image")
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped lang="scss">
.attachment-container {
  position: relative;
  &:hover {
    .attachment {
      background-color: $color-primary-100-5;
    }
    .download-link {
      visibility: visible;
      opacity: 1;
    }
  }
}
.download-link {
  position: absolute;
  width: 28px;
  height: 24px;
  z-index: 100;
  background-color: transparent;
  box-shadow: -3.63315px -3.63315px 10.8995px rgba(0, 0, 0, 0.04),
    5.44973px 5.44973px 20.9px rgba(1, 2, 24, 0.12);
  backdrop-filter: blur(40px);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 5px;
  top: 5px;
  visibility: hidden;
  opacity: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  &[title]:hover::after {
    content: attr(title);
    position: absolute;
    background: $color-primary-100-70;
    box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
      13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
    backdrop-filter: blur(100px);
    border-radius: 4px;
    padding: 10px;
    color: $color-primary-0;
    margin-top: 80px;
  }
}
.attachment {
  flex: 1;
  height: 100%;
  backdrop-filter: blur(100px);
  border-radius: 12px;
  padding: 4px;
  max-width: 140px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -0.1px;
  color: $color-primary-100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div:first-child {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}
.media-name {
  display: block;
  margin-top: 8px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
