import api from "@/api/api";
import store from "@/store";

export default class SubscriptionService {
  static async getSubscription() {
    let endpoint = `/api/v1/subscription/`;

    return await api()
      .get(endpoint)
      .then(({ data }) => {
        if (data.message) {
          store.dispatch("settings/saveSubscription", null);
        } else {
          store.dispatch("settings/saveSubscription", data);
        }
        return data;
      });
  }

  static async checkPromoCode(product_id, promo_code) {
    let endpoint = "/api/v1/subscription/stripe/validate-promo-code/";
    return await api()
      .post(endpoint, { product_id, promo_code })
      .then(({ data }) => {
        return data;
      });
  }

  static async getStripeInfo() {
    const userId = store.state.authentication.user.id;
    const endpoint = `/api/v1/user/${userId}/stripe_email/`;

    return await api()
      .get(endpoint)
      .then(({ data }) => {
        store.dispatch("settings/saveStripe", data);
        return data;
      });
  }

  static async getSubscriptionPlans() {
    let endpoint = "/api/v1/subscription/stripe/products/";
    return await api()
      .get(endpoint)
      .then(({ data }) => {
        return data;
      });
  }

  static async getPlanLimits() {
    let endpoint = "/api/v1/limits/";
    return await api()
      .get(endpoint)
      .then(({ data }) => {
        store.dispatch("settings/saveLimits", data);
        return data;
      });
  }

  static async getPaymentIntent(product_id, promo = null) {
    let endpoint = "/api/v2/subscription/stripe/create-payment-intent/";
    const payload = {
      product_id: product_id,
    };
    if (promo) {
      payload.promo_code = promo;
    }
    return await api()
      .post(endpoint, payload)
      .then(({ data }) => {
        return data;
      });
  }
}
