var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "content-block" }, [
    _setup.loading
      ? _c("div", { staticClass: "text" }, [
          _c("p", [_vm._v("Verifying your information...")]),
        ])
      : _vm._e(),
    !_setup.loading && _setup.success
      ? _c(
          "div",
          { staticClass: "text" },
          [_c(_setup.InlineSvg, { attrs: { name: "approve" } })],
          1
        )
      : _vm._e(),
    !_setup.loading && !_setup.success
      ? _c("div", { staticClass: "text" }, [
          _c("h1", [_vm._v("Unable to verify your identity")]),
          _c("p", [
            _vm._v(
              " If you feel this is an error, please feel free to reach out to Cloaked customer support for manual identity verification. "
            ),
          ]),
          _c("div", { staticClass: "buttons" }, [
            _c(
              "button",
              {
                staticClass: "primary",
                on: {
                  click: function ($event) {
                    return _setup.backToDashboard()
                  },
                },
              },
              [_vm._v(" Back to dashboard ")]
            ),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _setup.toggleSupport()
                  },
                },
              },
              [_vm._v("Contact Cloaked support")]
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", {
      staticClass: "background",
      class: {
        alert: !_setup.loading && !_setup.success,
        success: !_setup.loading && _setup.success,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }