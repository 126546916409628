var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "button",
      class: { clickable: _vm.clickable, loading: _setup.props.loading },
    },
    [
      _c(
        "div",
        { staticClass: "icon" },
        [
          _c(_setup.InlineSvg, {
            key: _setup.props.icon,
            attrs: { name: _setup.props.icon },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text" },
        [
          _c("p", [_vm._v(_vm._s(_setup.props.text))]),
          _c("h1", [_vm._v(_vm._s(_setup.props.title))]),
          _setup.props.subtext
            ? _c("p", [_vm._v(_vm._s(_setup.props.subtext))])
            : _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }