var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "reuse-container" }, [
    _vm._m(0),
    _c("div", [
      !_setup.ui.loading && _setup.hasCloaks
        ? _c("div", { staticClass: "select-menu" }, [
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "checkbox checkbox-all",
                  class: { checked: _setup.selectMode },
                  on: { click: _setup.toggleAll },
                },
                [_setup.selectMode ? _c(_setup.ExpandList) : _vm._e()],
                1
              ),
              !_setup.selectMode
                ? _c("span", [_vm._v("Select all")])
                : _vm._e(),
            ]),
            _c("div", [
              _setup.selectMode
                ? _c(
                    "button",
                    { staticClass: "trash", on: { click: _setup.deleteAll } },
                    [_c(_setup.TrashIcon)],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      !_setup.ui.loading && !_setup.hasCloaks
        ? _c("div", { staticClass: "empty-state" }, [
            _c("div", [_c(_setup.TrashIcon)], 1),
            _vm._m(1),
          ])
        : _c("div", [
            _c(
              "ul",
              { class: { preview: _setup.ui.loading } },
              _vm._l(_setup.cloaks, function (cloak) {
                return _c("li", { key: cloak.id }, [
                  _c("div", [
                    _setup.selectMode
                      ? _c("div", { staticClass: "checkbox-container" }, [
                          _c(
                            "button",
                            {
                              staticClass: "checkbox",
                              class: { checked: _setup.isSelected(cloak) },
                              on: {
                                click: function ($event) {
                                  return _setup.toggleCloak(cloak)
                                },
                              },
                            },
                            [
                              _setup.isSelected(cloak)
                                ? _c(_setup.CheckMark)
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "previewable icon",
                          on: {
                            click: function ($event) {
                              return _setup.open(cloak)
                            },
                          },
                        },
                        [
                          _c(_setup.IdentityIcon, {
                            attrs: {
                              identity: cloak,
                              override: {
                                width: "40px",
                                height: "40px",
                                "align-self": "center",
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "identity-info" }, [
                      _c(
                        "button",
                        {
                          staticClass: "previewable",
                          on: {
                            click: function ($event) {
                              return _setup.open(cloak)
                            },
                          },
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(_setup.getNickname(cloak))),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "previewable phone-display preview-alt",
                        },
                        [_c("span", [_vm._v(_vm._s(_setup.getPhone(cloak)))])]
                      ),
                    ]),
                  ]),
                  _c("div", [
                    _c(
                      "span",
                      { staticClass: "timediff previewable preview-alt" },
                      [
                        _c(_setup.Clock),
                        parseInt(_setup.diffInHours(cloak.expiration), 10) > 24
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_setup.diffInDays(cloak.expiration)) +
                                  " days"
                              ),
                            ])
                          : _c("span", { staticClass: "expiring" }, [
                              _vm._v(_vm._s(_setup.timediff(cloak.expiration))),
                            ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class: { disabled: _setup.selectMode || cloak.sending },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "previewable preview-alt",
                            on: {
                              click: function ($event) {
                                return _setup.keeepNumber(cloak)
                              },
                            },
                          },
                          [
                            cloak.sending
                              ? _c(
                                  "div",
                                  { staticClass: "loading small" },
                                  [_c(_setup.InputSpinner)],
                                  1
                                )
                              : _c("span", [_vm._v("keep")]),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "previewable",
                            on: {
                              click: function ($event) {
                                return _setup.deleteNumber(cloak)
                              },
                            },
                          },
                          [
                            cloak.sending
                              ? _c(
                                  "div",
                                  { staticClass: "loading small" },
                                  [_c(_setup.InputSpinner)],
                                  1
                                )
                              : _c("span", [_vm._v("delete")]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]),
    ]),
    _setup.ui.limit < _setup.total
      ? _c("div", { staticClass: "pagination" }, [
          _c("div", [
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(Math.max((_setup.ui.page - 1) * _setup.ui.limit, 1)) +
                  " - " +
                  _vm._s(
                    Math.min(_setup.ui.page * _setup.ui.limit, _setup.total)
                  ) +
                  " of " +
                  _vm._s(_setup.total)
              ),
            ]),
            _setup.ui.page > 1
              ? _c("div", [
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _setup.setPage(-1)
                        },
                      },
                    },
                    [_c(_setup.LeftArrow)],
                    1
                  ),
                ])
              : _vm._e(),
            _setup.ui.page * _setup.ui.limit < _setup.total
              ? _c("div", [
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _setup.setPage(1)
                        },
                      },
                    },
                    [_c(_setup.RightArrow)],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("h1", [_vm._v("Number clean up")]),
      _c("p", [
        _vm._v(
          " Delete inactive phone numbers to prevent unwanted spam and tracking. This will delete the number’s entire history from its identity. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("strong", [_vm._v(" There’s nothing here yet. ")]),
      _c("br"),
      _c("span", [
        _vm._v(" Inactive numbers will be stored in "),
        _c("br"),
        _vm._v(" the Number clean up for 30 days. "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }