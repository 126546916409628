<template>
  <section><p class="generic-skeleton"></p></section>
</template>

<script>
import { encode } from "@/scripts/hash";
export default {
  name: "ActivityRouter",
  beforeMount() {
    if (this.$route.params.id) {
      const encoded = encode(this.$route.params.id);
      this.$router.push({ path: `/inbox/${encoded}/` });
    } else {
      this.$router.push({ path: `/inbox/` });
    }
  },
};
</script>
