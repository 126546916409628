<template>
  <label
    class="check-button"
    :class="{
      'check-button--checked': !!value,
      'check-button--disabled': disabled,
      'check-button--square': square,
    }"
    @click.stop="() => {}"
  >
    <input
      type="checkbox"
      :checked="!!value"
      :disabled="disabled"
      @change="handleChange"
    />

    <template v-if="!square">
      <CheckActive v-if="!!value" />
      <CheckInactive v-else />
    </template>

    <template v-if="square">
      <CheckSquareActive v-if="!!value" />
      <CheckSquareInactive v-else />
    </template>
  </label>
</template>

<script>
import {
  CheckActive,
  CheckInactive,
  CheckSquareActive,
  CheckSquareInactive,
} from "@/assets/icons";

export default {
  name: "CheckButton",
  props: ["value", "checked", "disabled", "square"],

  components: {
    CheckActive,
    CheckInactive,
    CheckSquareActive,
    CheckSquareInactive,
  },

  methods: {
    handleChange() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style scoped lang="scss">
.check-button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  color: $color-primary-30;
  display: inline-block;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
  }

  input {
    visibility: hidden;

    &:checked {
      + ::before {
        border-color: currentColor;
      }

      + ::after {
      }
    }
  }

  &--checked {
    color: $color-primary-100;
  }

  &--disabled {
    color: $color-primary-90;
  }

  &--square {
    width: 19px;
    height: 19px;
  }

  &:not(&--disabled):hover {
    cursor: pointer;
  }
}
</style>
