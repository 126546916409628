var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.Iframe, {
    attrs: { source: "auth/signup", id: "cloak-iframe" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }