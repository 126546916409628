var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "activity-call-section" },
    [
      !_vm.sameAsPrevSender
        ? _c("ActivityConvoNameTimeDisplay", {
            attrs: {
              senderDisplay: _vm.senderDisplay,
              inbound: _vm.call.inbound,
              createdAt: _vm.call.created_at,
            },
            on: {
              openCloak: function ($event) {
                return _vm.$emit("openCloak")
              },
            },
          })
        : _vm._e(),
      _vm.call.recordings && _vm.call.recordings.length
        ? _c("div", [
            _c(
              "div",
              { staticClass: "voice-message" },
              _vm._l(_vm.call.recordings, function (recording, idx) {
                return _c("VoicemailPlayer", {
                  key: `voicemail-${idx}`,
                  attrs: { recording: recording, idx: idx },
                })
              }),
              1
            ),
          ])
        : _c(
            "div",
            {
              staticClass: "activity-call-display-pill",
              class: { outbound: !_vm.call.inbound },
            },
            [
              _c(
                "div",
                [
                  _c("ActivityPhoneIcon", {
                    class: _vm.callType.toLowerCase().includes("out")
                      ? "lightIcon"
                      : "darkIcon",
                  }),
                  _c("span", { staticClass: "activity-call-type" }, [
                    _vm._v(" " + _vm._s(_vm.callType) + " "),
                  ]),
                ],
                1
              ),
              _c("span", { staticClass: "activity-call-time" }, [
                _vm._v(" " + _vm._s(_vm.timeDisplay) + " "),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }