var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.SettingsChildPage,
    {
      staticClass: "forwarding-page",
      scopedSlots: _vm._u([
        {
          key: "aside",
          fn: function () {
            return [
              _c(_setup.SettingsTitle, [_vm._v("Recovery passphrase")]),
              _c(_setup.SettingsParagraph, [
                _vm._v(" Save your recovery passphrase "),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c(_setup.RecoveryPhrase)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }