var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.formByFieldType[_vm.fieldType],
    _vm._g(
      _vm._b(
        {
          tag: "Component",
          attrs: {
            title: _setup.title,
            value: _vm.value,
            errors: _setup.errors,
            "form-type": _vm.formType,
            "field-type": _vm.fieldType,
            existingAddresses: _vm.existingAddresses,
          },
          on: {
            validate: _setup.validateField,
            "validate-all": _setup.validateAll,
            "reset-validation": _setup.resetValidation,
            "attempt-save": _setup.attemptSave,
          },
        },
        "Component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }