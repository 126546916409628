var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { ref: "scrollRef", staticClass: "email-container" },
    [
      _vm.loaded
        ? _c("div", [
            _c("div", [
              _c("header", [
                _c("div", { staticClass: "header-wrapper" }, [
                  _c("div", { staticClass: "header-title" }, [
                    _c("h1", [_vm._v(_vm._s(_vm.thread.subject))]),
                  ]),
                  _c(
                    "div",
                    { ref: "title_kabab", staticClass: "header-actions" },
                    [
                      _c(
                        "UiMenu",
                        {
                          attrs: {
                            width: "247px",
                            "max-height": "300px",
                            placement: "bottom-end",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _c("UiMenuButton", {
                                      attrs: {
                                        title: _vm.thread.read
                                          ? "Mark as unread"
                                          : "Mark as read",
                                      },
                                      on: { click: _vm.mark_read_thread },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "icon",
                                            fn: function () {
                                              return [_c("Read")]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        4049416113
                                      ),
                                    }),
                                    _c("UiMenuSeparator"),
                                    _c(
                                      "div",
                                      { staticClass: "danger" },
                                      [
                                        _c("UiMenuButton", {
                                          attrs: {
                                            title: "Delete",
                                            danger: "",
                                          },
                                          on: { click: _vm.trip_delete_thread },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "icon",
                                                fn: function () {
                                                  return [_c("Delete")]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            3429380666
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1522396197
                          ),
                        },
                        [
                          _c(
                            "UiTooltip",
                            {
                              attrs: { title: "Options", "align-x": "center" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "kabab" },
                                [_c("Kabob")],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "messages" },
                [
                  _vm._l(_vm.messages, function (message, index) {
                    return _c(
                      "div",
                      {
                        key: message.id,
                        class: {
                          message: true,
                          single: _vm.messages.length === 1,
                          inbound: message.inbound,
                        },
                        attrs: { id: `activity-id-${message.id}` },
                      },
                      [
                        message.email
                          ? _c(
                              "div",
                              { staticClass: "email-message" },
                              [
                                _c("ActivityInfoHeader", {
                                  attrs: {
                                    message: message,
                                    index: index,
                                    thread: _vm.thread,
                                    confirmDelete: _vm.confirm_delete,
                                    replyTo: _vm.reply_to,
                                  },
                                  on: {
                                    removed: () =>
                                      _vm.$emit("removed", message.email),
                                  },
                                }),
                                _vm.current !== index
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "preview",
                                        on: {
                                          click: function ($event) {
                                            _vm.open = index
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.removeHtmlTags(
                                                _vm.safe(
                                                  message.email.body_preview
                                                )
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "email-content" },
                                      [
                                        _c("SafeUGC", {
                                          attrs: {
                                            content: _vm.iframe_source(
                                              message,
                                              index
                                            ),
                                          },
                                        }),
                                        _vm.has_attachments(message)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "attachment-list",
                                              },
                                              [
                                                message.email.attachments
                                                  .length > 1
                                                  ? _c("label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          message.email
                                                            .attachments.length
                                                        ) + " attachments"
                                                      ),
                                                    ])
                                                  : _c("label", [
                                                      _vm._v("1 attachment"),
                                                    ]),
                                                _c(
                                                  "div",
                                                  _vm._l(
                                                    message.email.attachments,
                                                    function (
                                                      attachment,
                                                      index
                                                    ) {
                                                      return _c("Attachment", {
                                                        key: index,
                                                        attrs: {
                                                          attachment:
                                                            attachment,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  _c("InfiniteTrigger", { on: { infinite: _vm.get_messages } }),
                ],
                2
              ),
              !_vm.replyIsOpen
                ? _c(
                    "button",
                    {
                      staticClass: "reply_footer",
                      on: { click: _vm.openReplyToLatest },
                    },
                    [
                      _c("span", [_vm._v("Write a reply")]),
                      _c("span", [_c("SendIcon")], 1),
                    ]
                  )
                : _vm._e(),
            ]),
          ])
        : _c("div", { staticClass: "loader" }, [_vm._m(0)]),
      _c("portal-target", { attrs: { name: "email-display", multiple: "" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("@/assets/icons/small-spinner.png"),
          width: "30",
        },
      }),
      _c("br"),
      _vm._v(" Loading messages.... "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }