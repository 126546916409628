var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("UiMenu", {
    staticClass: "cloak-generate-password-flyout",
    attrs: { value: _vm.visible, placement: "left-start", width: "250px" },
    on: {
      close: function ($event) {
        return _vm.$emit("close")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _vm.showGenerateOptions
              ? [
                  _c("UiMenuButton", {
                    attrs: {
                      "aria-id": "ToggleLettersPassword",
                      title: "Letters (e.g. Aa)",
                      disabled: _vm.disabled.includes("letters"),
                    },
                    on: {
                      click: function ($event) {
                        _vm.letters = !_vm.letters
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("CheckButton", {
                                attrs: {
                                  disabled: _vm.disabled.includes("letters"),
                                },
                                model: {
                                  value: _vm.letters,
                                  callback: function ($$v) {
                                    _vm.letters = $$v
                                  },
                                  expression: "letters",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      645078163
                    ),
                  }),
                  _c("UiMenuButton", {
                    attrs: {
                      "aria-id": "ToggleDigitsPassword",
                      title: "Digits (e.g. 123)",
                      disabled: _vm.disabled.includes("numbers"),
                    },
                    on: {
                      click: function ($event) {
                        _vm.numbers = !_vm.numbers
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("CheckButton", {
                                attrs: {
                                  disabled: _vm.disabled.includes("numbers"),
                                },
                                model: {
                                  value: _vm.numbers,
                                  callback: function ($$v) {
                                    _vm.numbers = $$v
                                  },
                                  expression: "numbers",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2554093715
                    ),
                  }),
                  _c("UiMenuButton", {
                    attrs: {
                      "aria-id": "ToggleSymbolsPassword",
                      title: "Symbols (!*$?#)",
                    },
                    on: {
                      click: function ($event) {
                        _vm.symbols = !_vm.symbols
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("CheckButton", {
                                attrs: {
                                  disabled: _vm.disabled.includes("symbols"),
                                },
                                model: {
                                  value: _vm.symbols,
                                  callback: function ($$v) {
                                    _vm.symbols = $$v
                                  },
                                  expression: "symbols",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2585749395
                    ),
                  }),
                  _c("UiMenuButton", {
                    attrs: {
                      "aria-id": "ToggleEasyToRememberPassword",
                      title: "Easy to remember",
                    },
                    on: {
                      click: function ($event) {
                        _vm.words = !_vm.words
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("CheckButton", {
                                attrs: {
                                  disabled: _vm.disabled.includes("words"),
                                },
                                model: {
                                  value: _vm.words,
                                  callback: function ($$v) {
                                    _vm.words = $$v
                                  },
                                  expression: "words",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3511718547
                    ),
                  }),
                  _c("UiMenuSeparator"),
                  _c("div", { staticClass: "password-length" }, [
                    _c("div", { staticClass: "password-length__label" }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.words ? "Word Count" : "Length") +
                            " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "password-length__controls",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return (() => {}).apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.setLength(-1)
                              },
                            },
                          },
                          [_c("Minus")],
                          1
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.size,
                              expression: "size",
                            },
                          ],
                          attrs: {
                            "aria-id": "PasswordLengthInput",
                            type: "text",
                            min: "8",
                            max: "128",
                            maxlength: "3",
                          },
                          domProps: { value: _vm.size },
                          on: {
                            keydown: _vm.onlyNumbers,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.size = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.setLength(1)
                              },
                            },
                          },
                          [_c("Plus")],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c("UiMenuSeparator"),
                  _c("UiMenuButton", {
                    attrs: {
                      "aria-id": "GenerateNewPasswordButton",
                      title: "Generate New Password",
                      active: "",
                    },
                    on: { click: _vm.handleGenerate },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function () {
                            return [_c("GenerateIcon")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      176569347
                    ),
                  }),
                ]
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }