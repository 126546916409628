var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "trash-container" },
    [
      _setup.ui.type === _setup.TYPE.IDENTITY
        ? _c(_setup.IdentifierTrashList, {
            attrs: { types: _setup.TYPE, type: _setup.ui.type },
            on: { setType: _setup.setType },
          })
        : _setup.ui.type === _setup.TYPE.ACTIVITY
        ? _c(_setup.ActivityTrashList, {
            attrs: { types: _setup.TYPE, type: _setup.ui.type },
            on: { setType: _setup.setType },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }