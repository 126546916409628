import { render, staticRenderFns } from "./AppModalVideo.vue?vue&type=template&id=3cf55884"
import script from "./AppModalVideo.vue?vue&type=script&setup=true&lang=js"
export * from "./AppModalVideo.vue?vue&type=script&setup=true&lang=js"
import style0 from "./AppModalVideo.vue?vue&type=style&index=0&id=3cf55884&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/__w/dashboard/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3cf55884')) {
      api.createRecord('3cf55884', component.options)
    } else {
      api.reload('3cf55884', component.options)
    }
    module.hot.accept("./AppModalVideo.vue?vue&type=template&id=3cf55884", function () {
      api.rerender('3cf55884', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ui/AppModalVideo.vue"
export default component.exports