var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "activity-anonymous-convo-header" }, [
    _c("div", [
      _c("div", { staticClass: "user-icon" }, [_c("UserIcon")], 1),
      _c("h1", [_vm._v(_vm._s(_vm.anonymPhone))]),
      _c("h2", [_vm._v(_vm._s(_vm.contact?.location || "Unknown Location"))]),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
          },
        },
        [_vm._v('View "' + _vm._s(_vm.name) + '" Identity')]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }