var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.AppModalParagraph,
    { staticClass: "identity-sharing-terms" },
    [
      _vm._v(" Read full sharing "),
      _c(
        "a",
        {
          staticClass: "identity-sharing-terms__link",
          attrs: {
            href: "https://www.cloaked.app/terms-of-service",
            target: "_blank",
          },
        },
        [_vm._v("Terms and Conditions")]
      ),
      _vm._v(". "),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }