var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.props.card
    ? _c(
        "router-link",
        {
          staticClass: "card",
          class: { active: _setup.route.params.id === _setup.card.id },
          attrs: { to: "/wallet/card/" + _setup.card.id },
          on: {
            click: function ($event) {
              return _vm.toggleCard()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "title" },
            [
              _c(_setup.IdentityIcon, {
                staticClass: "icon",
                attrs: {
                  identity: _setup.identity(
                    JSON.parse(_setup.card.identity_id)
                  ),
                  override: { height: "60px", width: "60px" },
                },
                nativeOn: {
                  click: function ($event) {
                    _setup.openIdentity(
                      _setup.identity(JSON.parse(_setup.card.identity_id))
                    )
                  },
                },
              }),
              _c("div", { staticClass: "info" }, [
                _setup.card.identity_id
                  ? _c("h1", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _setup.identity(JSON.parse(_setup.card.identity_id))
                              ?.nickname || "New identity"
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _c("p", [
                  _vm._v(
                    _vm._s(_setup.convertToDollar(_setup.available)) +
                      " available"
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "card-details" }, [
            _c("div", { staticClass: "card-number" }, [
              _setup.realCardNumbers.pan &&
              _setup.route.params.id === _setup.card.id
                ? _c(
                    "p",
                    {
                      staticClass: "hover",
                      on: {
                        click: function ($event) {
                          return _setup.copyToClipboard(
                            "Card number",
                            _setup.realCardNumbers.pan
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_setup.splitPan(_setup.realCardNumbers.pan)) +
                          " "
                      ),
                    ]
                  )
                : _c("p", [
                    _vm._v(
                      _vm._s(_setup.splitPan(_setup.hiddenPan(_setup.card.pan)))
                    ),
                  ]),
            ]),
            _c("div", { staticClass: "valid" }, [
              _c("p", [_vm._v("Valid thru")]),
              _setup.realCardNumbers.expMonth &&
              _setup.realCardNumbers.expYear &&
              _setup.route.params.id === _setup.card.id
                ? _c(
                    "p",
                    {
                      staticClass: "hover",
                      on: {
                        click: function ($event) {
                          return _setup.copyToClipboard(
                            "Valid thru date",
                            _setup.realCardNumbers.expMonth +
                              "/" +
                              _setup.realCardNumbers.expYear
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_setup.realCardNumbers.expMonth) +
                          "/" +
                          _vm._s(_setup.realCardNumbers.expYear) +
                          " "
                      ),
                    ]
                  )
                : _c("p", [_vm._v("••/••")]),
            ]),
            _c("div", { staticClass: "cvv" }, [
              _c("p", [_vm._v("CVV")]),
              _setup.realCardNumbers.cvv &&
              _setup.route.params.id === _setup.card.id
                ? _c(
                    "p",
                    {
                      staticClass: "hover",
                      on: {
                        click: function ($event) {
                          return _setup.copyToClipboard(
                            "CVV",
                            _setup.realCardNumbers.cvv
                          )
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_setup.realCardNumbers.cvv) + " ")]
                  )
                : _c("p", [_vm._v("•••")]),
            ]),
          ]),
          _c(_setup.InlineSvg, {
            staticClass: "card-icon",
            attrs: { name: "mastercard" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }