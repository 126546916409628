<template>
  <section :id="`fwd-toggle-${field}`">
    <div class="fwd-toggle-wrapper">
      <UiMenu width="250px" placement="bottom-start">
        <div class="fwd-toggle-wrapper__label">
          <div class="fwd-toggle-wrapper__label-title">
            {{ headerText }}
          </div>

          <div
            class="fwd-toggle-wrapper__label-selection"
            :class="{
              'fwd-toggle-wrapper__label-selection--selected': selected,
            }"
          >
            <span class="selected-ellipsis">{{ selectedText }}</span>

            <ChevronDown />
          </div>
        </div>

        <template #content>
          <UiMenuButton
            v-for="option in options"
            :key="option"
            :title="option"
            :active="option === selected"
            @click="() => handleSelect(option)"
          >
            <template v-slot:icon>
              <CheckIcon v-if="option === selected" />
              <span v-else />
            </template>

            <template v-if="field === 'email'" v-slot:title>
              <UiEmailOptionLabel :email="option" />
            </template>
          </UiMenuButton>

          <UiMenuSeparator />

          <UiMenuButton :title="addButtonText" @click="addNewField">
            <template v-slot:icon>
              <Plus />
            </template>
          </UiMenuButton>
        </template>
      </UiMenu>

      <div @click="toggleForward">
        <UiTooltip
          :aria-id="`ToggleSwitch.${field || ''}`"
          :title="switchTooltipTitle"
          :can-show="switchIsDisabled"
          align-x="right"
        >
          <UiSwitchInput :value="!!selected" :disabled="switchIsDisabled" />
        </UiTooltip>
      </div>
    </div>
  </section>
</template>
<script>
import { Plus, CheckIcon, ChevronDown } from "@/assets/icons";

import {
  UiSwitchInput,
  UiMenu,
  UiMenuButton,
  UiMenuSeparator,
  UiEmailOptionLabel,
} from "@/components";
import UiTooltip from "@/components/ui/ui-tooltip";

export default {
  name: "CloakForwardToggleRow",
  props: [
    "field",
    "selected",
    "options",
    "allowed",
    "defaultValue",
    "unverifiedEntries",
  ],
  components: {
    UiSwitchInput,
    Plus,
    CheckIcon,
    ChevronDown,
    UiMenu,
    UiMenuButton,
    UiMenuSeparator,
    UiEmailOptionLabel,
    UiTooltip,
  },
  methods: {
    handleSelect(selection) {
      this.$emit("select", selection);
    },
    addNewField() {
      this.$emit("sentToSettings");
      this.$router.push({ name: "settings.forwarding" });
    },
    toggleForward($event) {
      $event.preventDefault();
      if (this.switchIsDisabled) {
        return this.addNewField();
      }

      if (this.allowed) {
        if (this.selected) {
          return this.$emit("deselect");
        } else if (this.defaultValue) {
          return this.handleSelect(this.defaultValue);
        }
      }
    },
    handleLabelClick(event, { menuTrigger }) {
      if (this.canShowMenu) {
        menuTrigger(event);
      } else {
        this.addNewField();
      }
    },
  },
  computed: {
    switchTooltipTitle() {
      switch (this.field) {
        case "email": {
          if (this.unverifiedEntries) {
            return "Verify your email address to enable forwarding";
          } else {
            return "Add an email address to enable forwarding";
          }
        }
        case "call": {
          if (this.unverifiedEntries) {
            return "Verify your phone number to enable forwarding";
          } else {
            return "Add a phone number to enable forwarding";
          }
        }

        case "message": {
          if (this.unverifiedEntries) {
            return "Verify your phone number to enable forwarding";
          } else {
            return "Add a phone number to enable forwarding";
          }
        }
        default: {
          return "";
        }
      }
    },
    switchIsDisabled() {
      return !this.selected && !this.defaultValue;
    },
    canShowMenu() {
      return this.allowed && this.options.length;
    },
    headerText() {
      switch (this.field) {
        case "email": {
          return "Forward incoming emails to";
        }
        case "call": {
          return "Forward incoming calls to";
        }
        case "message": {
          return "Forward incoming texts to";
        }

        default: {
          return "";
        }
      }
    },
    selectedText() {
      return this.selected || this.defaultValue || this.addFieldText;
    },
    addFieldText() {
      switch (this.field) {
        case "email": {
          if (this.options.length) {
            return "Select a forwarding email";
          }
          return "Add a forwarding email";
        }
        case "call": {
          if (this.options.length) {
            return "Select a forwarding number";
          }
          return "Add a forwarding number";
        }
        case "message": {
          if (this.options.length) {
            return "Select a forwarding number";
          }
          return "Add a forwarding number";
        }
        default: {
          return "";
        }
      }
    },
    addButtonText() {
      switch (this.field) {
        case "email": {
          return "Add a new forwarding email";
        }
        case "call": {
          return "Add a new forwarding number";
        }
        case "message": {
          return "Add a new forwarding number";
        }
        default: {
          return "";
        }
      }
    },
    position() {
      const el = this.$el;

      if (el) {
        const positions = el.getBoundingClientRect();
        return { x: positions.bottom, y: 0 };
      }

      return {};
    },
  },
};
</script>
<style lang="scss">
.fwd-toggle-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 10px 0;
  gap: 8px;

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    &:hover {
      cursor: pointer;
    }
  }

  &__label-title {
    color: $color-primary-100;
    cursor: default;
  }

  &__label-selection {
    font-weight: 500;
    color: $color-primary-100;
    display: flex;
    align-items: center;
    gap: 2px;

    .selected-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 230px;
      display: inline-block;
    }

    svg {
      flex-shrink: 0;
    }

    &--selected {
      text-decoration-line: underline;
    }

    &--disabled {
      color: $color-primary-50;
      cursor: not-allowed;
    }
  }

  .ui-menu {
    flex: 1 1 0;
  }
}

.flyout-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15.5px 0;
  cursor: pointer;
}

.pre-space {
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: $color-accent;
    width: 20px;
  }
}
</style>
