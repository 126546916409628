<template>
  <section class="activity-message-section">
    <ActivityConvoNameTimeDisplay
      v-if="!sameAsPrevSender"
      :senderDisplay="senderDisplay"
      :inbound="message.inbound"
      :createdAt="message.created_at"
      @openCloak="$emit('openCloak')"
    />
    <div
      v-if="bodyLines.length"
      class="activity-message-body"
      :class="{ inbound: message.inbound }"
    >
      <SafeUGC :color="color" :content="safe_html(message.body)" />
    </div>

    <div v-for="(img, i) in message.media" :key="i">
      <a
        :href="img"
        target="_blank"
        download
        v-if="isImage(img)"
        class="media-link"
        ><img class="activity-message-media" :src="img"
      /></a>
      <div v-else class="activity-message-pdf">
        <embed :src="img" width="200" height="260" type="application/pdf" />
        <a :href="img" target="_blank" download class="media-link"
          ><Download
        /></a>
      </div>
    </div>
  </section>
</template>
<script>
import { get } from "lodash-es";

import { ActivityConvoNameTimeDisplay } from "@/components";
import SafeUGC from "@/components/SafeUGC";
import { safe_html } from "@/scripts/format";
import { TEXT_ATTACHMENTS_ENABLED } from "@/scripts/featureFlags";
import Download from "@/assets/icons/download.svg";
export default {
  name: "ActivityMessageItem",
  props: ["message", "senderDisplay", "sameAsPrevSender", "read"],
  components: {
    ActivityConvoNameTimeDisplay,
    Download,
    SafeUGC,
  },

  computed: {
    color() {
      const light = "#ffffff";
      const dark = "#1111111";
      if (this.$store.getters["colorScheme/isDarkMode"]) {
        if (this.message.inbound) {
          return light;
        }
        return dark;
      }

      if (this.message.inbound) {
        return dark;
      }
      return light;
    },
    bodyLines() {
      const body = get(this.message, "body");

      if (!body) {
        return [];
      }
      return body.split("\n");
    },
    attachmentEnabled() {
      return TEXT_ATTACHMENTS_ENABLED;
    },
  },
  methods: {
    has_attachments(message) {
      if (message && message.attachments) {
        return message.attachments.length > 0;
      }
      return false;
    },
    isImage(url) {
      const extensions = [".jpg", ".gif", ".jpeg", ".png", ".webp", ".heic"];
      let supported = false;
      extensions.map((ext) => {
        if (url.toLowerCase().includes(ext)) {
          supported = true;
        }
      });
      return supported;
    },
    safe_html(content) {
      return safe_html(content);
    },
  },
};
</script>
<style lang="scss" scoped>
.activity-message-section {
  .activity-message-body {
    background-color: $color-primary-70;
    color: $color-primary-0;
    border-radius: 16px;
    line-height: 21px;
    padding: 10px 16px 5px 16px;
    display: inline-flex;
    margin-top: 4px;
    margin-left: 64px;
    width: auto;
    max-width: 366px;
    flex-direction: column;
    p {
      line-height: 21px;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: -0.2px;
    }

    &.inbound {
      background-color: $color-primary-10;
      color: $color-primary-100;
      a {
        color: $color-primary-0 !important;
      }
    }
  }

  .activity-message-media {
    max-width: 366px;
    height: auto;
    border-radius: 16px;
    margin: 4px 66px 0 66px;
  }
  .media-link {
    cursor: pointer;
    display: block;
  }
  .activity-message-pdf {
    max-width: 366px;
    margin: 4px 66px 0 66px;
  }
}
</style>
