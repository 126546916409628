var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.MenuBlock,
    { attrs: { title: "Inbox" } },
    [
      _c(_setup.MenuItem, {
        attrs: {
          link: "/inbox",
          name: "All",
          icon: "inbox-filled",
          count: _setup.allUnread,
        },
      }),
      _c(_setup.MenuItem, {
        attrs: {
          link: "/inbox/emails",
          name: "Emails",
          icon: "mail-outline",
          count: _setup.unreadCount.emails,
          nested: "",
        },
      }),
      _c(_setup.MenuItem, {
        attrs: {
          link: "/inbox/texts",
          name: "Texts",
          icon: "text-outline",
          count: _setup.unreadCount.messages,
          nested: "",
        },
      }),
      _c(_setup.MenuItem, {
        attrs: {
          link: "/inbox/calls",
          name: "Calls",
          icon: "phone-outline",
          count: _setup.unreadCount.calls,
          nested: "",
        },
      }),
      _c(_setup.MenuItem, {
        attrs: {
          link: "/inbox/requests",
          name: "Requests",
          icon: "requests-outline",
          count: _setup.unreadCount.requests,
          nested: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }