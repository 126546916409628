var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "drag-container",
      class: { dragging: _setup.state.dragStart },
      on: {
        mousedown: _setup.startDrag,
        mouseup: _setup.endDrag,
        mouseleave: _setup.endDrag,
        mousemove: _setup.onDrag,
      },
    },
    [
      _setup.state.dragEnd
        ? _c("div", {
            staticClass: "dragArea",
            style: `left: ${_setup.state.dragEnd.left}px; top: ${_setup.state.dragEnd.top}px; width: ${_setup.state.dragEnd.width}px; height: ${_setup.state.dragEnd.height}px; `,
          })
        : _vm._e(),
      _setup.props.identityList.length > 0
        ? _c(_setup.MultiSelectToolbar, {
            attrs: {
              isMultiselect: _setup.isMultiselect,
              identityList: _setup.props.identityList,
              selected: _setup.state.selected,
              ignoreDisplay: _setup.ignoreDisplay,
            },
            on: {
              select: _setup.toggleList,
              ignore: _setup.ignoreList,
              unignore: _setup.unignoreList,
              delete: _setup.deleteList,
              transferList: _setup.transferList,
              "auto-change": function ($event) {
                return _vm.$store.dispatch("autoChange/autoChangeIds", $event)
              },
            },
          })
        : _vm._e(),
      !_setup.isLoading && _vm.identityList.length === 0
        ? _vm._t("no-results")
        : _vm._e(),
      _c(
        "div",
        { staticClass: "items" },
        [
          _vm.showModalOnHover
            ? _c(
                _setup.UiTooltip,
                {
                  attrs: {
                    title: _vm.hoverText,
                    "align-x": "right",
                    "offset-x": "10",
                    "offset-y": "-60",
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "add_new",
                      attrs: {
                        "aria-id": "AddCloaktoCategory",
                        id: "add-new-cloak-button",
                      },
                      on: { click: _setup.openModal },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "plus" },
                        [
                          _c(_setup.PlusIcon, {
                            attrs: { height: "19", width: "19" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._l(_setup.props.identityList, function (identity, id_index) {
            return _c(
              "div",
              {
                key: `${identity.id}-${id_index}`,
                staticClass: "item",
                attrs: { id: identity.id },
                on: { mousedown: _setup.handleClick },
              },
              [
                _c(_setup.CloakCard, {
                  attrs: {
                    "aria-id": `CloakCardIdentity${identity?.id || ""}`,
                    identity: identity,
                    id_index: id_index,
                    noSelect: identity.id === -1,
                    selected: _setup.isSelected(identity) || identity.id === -1,
                    isMultiselect: _setup.isMultiselect,
                    count: _setup.state.selected.length,
                    "with-right-click": true,
                    identifierPriority: _setup.props.identifierPriority,
                    ignoreDisplay: _setup.ignoreDisplay,
                  },
                  on: {
                    refresh: _setup.refresh,
                    ignore: _setup.ignoreList,
                    unignore: _setup.unignoreList,
                    delete: _setup.deleteList,
                    select: function ($event) {
                      return _setup.toggleSelect(identity)
                    },
                    move: _setup.move,
                    unselectAll: _setup.unselectAll,
                    unselect: function ($event) {
                      return _setup.toggleSelect(identity, true)
                    },
                    selectAll: _setup.selectAll,
                    click: function ($event) {
                      return _setup.selectIdentity(identity)
                    },
                  },
                }),
              ],
              1
            )
          }),
          _c(_setup.InfiniteTrigger, {
            ref: "infinite",
            on: {
              infinite: _setup.loadMore,
              loading: function ($event) {
                _setup.isLoading = $event
              },
            },
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }