<template>
  <div>
    <section class="activity-convo-input" v-if="contactIsApproved">
      <textarea
        placeholder="Text message"
        :style="{ height }"
        v-model="message"
        @keydown.enter.exact.prevent="send"
        @keydown.shift.enter.exact.prevent="addNewLine"
      />
      <div class="message_footer">
        <div class="file-section" v-if="attachmentEnabled">
          <FileAttachments
            ref="fileAttachment"
            @change="handleFileChange"
            endpoint="/api/v1/cloaked/activity/upload_media_urls/"
            :filter="attachFilter"
            :disabled="files.length > 4"
            :error="errors.includes('files')"
          >
            <template v-slot:icon>
              <ImageGallery />
            </template>
          </FileAttachments>
          <FileList
            :files="files"
            @remove="removeFile"
            errors="Files must be a jpeg, png, pdf and must total less than 3.5 mb"
          />
        </div>
        <button
          :class="{
            active: (message.length > 0 || files.length > 0) && !sending,
            disabled: sending,
          }"
          @click="send"
        >
          <SendIcon />
        </button>
      </div>
    </section>
    <div class="relative" v-if="!contactIsApproved">
      <section class="activity-approve-contact" v-if="contactIsUnknown">
        <div>
          <div>
            <h1>{{ contactPhone }} is trying to contact you</h1>
            <p>
              Approve this contact if you want to reply to this message and
              <br />allow {{ contactPhone }} to be able to message you from now
              on.
            </p>
          </div>
          <div>
            <button @click="setStatus('block')" :class="{ disabled: sending }">
              Block <Block />
            </button>
            <button
              @click="setStatus('approve')"
              :class="{ disabled: sending }"
            >
              Approve <Approve />
            </button>
          </div>
        </div>
      </section>
      <section class="activity-approve-contact" v-else-if="!contactIsApproved">
        <div>
          <div>
            <h1>{{ contactPhone }} is blocked</h1>
            <p>Approve {{ contactPhone }} in order to reply</p>
          </div>
          <div>
            <button
              @click="setStatus('approve')"
              :class="{ disabled: sending }"
            >
              Approve <Approve />
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { FILE_PERMISSIONS } from "@/scripts/constants";
import Block from "@/assets/icons/block.svg";
import { maxPayload } from "@/scripts/validation";
import SendIcon from "@/assets/icons/compose-send.svg";

import { anonymPhone } from "@/scripts/format";
import Approve from "@/assets/icons/approve.svg";
import ImageGallery from "@/assets/icons/image-gallery.svg";
import { ContactsViewEdit } from "@/components/modals";
import { analyticsExport } from "@/mixins/Analytics";
import { TEXT_ATTACHMENTS_ENABLED } from "@/scripts/featureFlags";
import ActivityService from "@/api/actions/activity-service";

import { FileAttachments, FileList } from "@/components";

export default {
  name: "ActivityConvoReply",
  props: ["thread"],
  data() {
    return {
      message: "",
      sending: false,
      files: [],
      errors: [],
    };
  },
  components: {
    Block,
    Approve,
    FileList,
    SendIcon,
    FileAttachments,
    ImageGallery,
  },
  computed: {
    attachmentEnabled() {
      return TEXT_ATTACHMENTS_ENABLED;
    },
    maxPayload() {
      return maxPayload(this.files);
    },
    height() {
      const matches = this.message.match(/\n/g);
      let count = 1;
      if (matches) {
        count = matches.length + 1;
      }
      return `${70 + 15 * count}px`;
    },
    contactIsApproved() {
      return !this.sender || this.sender.status.includes("approve");
    },
    contactIsUnknown() {
      return this.sender && this.sender.status.includes("unknown");
    },
    sender() {
      return this.thread?.sender_contact;
    },
    contactPhone() {
      if (this.sender) {
        return anonymPhone(this.sender.original_phone);
      }
      return null;
    },
  },
  methods: {
    ...mapActions(["openModal"]),
    attachFilter(file) {
      const ext = (file.name || file.filename)?.split(".").pop().toLowerCase();
      return !FILE_PERMISSIONS.TEXT_ALLOWED.find((type) => type === ext);
    },
    removeFile(index) {
      this.$refs.fileAttachment.removeFile(index);
    },
    handleFileChange(files) {
      this.files = files;
    },
    addNewLine() {
      this.message = `${this.message}
`;
    },
    setStatus(status) {
      if (!this.sending) {
        this.sending = true;
        ActivityService.postContactStatus(this.sender?.id, status)
          .then(() => {
            this.$emit("setContactStatus", status);
            this.sending = false;
            window.dispatchEvent(
              new CustomEvent("contact:status", { thread: this.thread })
            );
            if (status === "unknown") {
              this.$toast.success("Contact preference reverted");
              // this.$toast("Contact preference reverted", {
              //   pauseOnHover: false,
              //   pauseOnFocusLoss: false,
              //   timeout: 2500,
              //   hideProgressBar: true,
              // });
            } else {
              window.addEventListener("contact:undo", this.setStatusToUnknown);
              window.addEventListener("contact:manage", this.openManage);
              // TODO: update new toast setup to work with custom components (https://cloakedinc.atlassian.net/browse/ALL-3997)
              this.$toast.success(
                status === "approve" ? "Request approved" : "Request blocked"
              );
              // this.$toast(
              //   status === "approve" ? ApprovedActions : BlockedActions,
              //   {
              //     onClose: () => {
              //       window.removeEventListener(
              //         "contact:undo",
              //         this.setStatusToUnknown
              //       );
              //       window.removeEventListener(
              //         "contact:manage",
              //         this.openManage
              //       );
              //     },
              //   }
              // );
            }
          })
          .catch(() => {
            this.sending = false;
            this.$toast.error(
              "Could not save contact setting, please try again"
            );
          });
      }
    },
    openManage() {
      this.openModal({
        customTemplate: {
          template: ContactsViewEdit,
          props: {
            setPrimary: true,
            contactId: this.sender?.id,
            contactType: "phone",
          },
        },
      });
    },
    setStatusToUnknown() {
      this.setStatus("unknown");
    },
    send() {
      const errors = [];
      if (this.maxPayload) {
        errors.push("files");
      }

      if (!this.sending && errors.length === 0) {
        const data = {
          subject: this.thread.subject,
          text: this.message,
          original_text: this.thread.text || this.thread.html,
        };
        if (this.files.length > 0) {
          data.media = this.files;
        }
        if (this.message.length > 0 || this.files.length > 0) {
          this.sending = true;
          this.$nextTick(() => {
            ActivityService.postActivityReply(
              this.thread.activity_id,
              data
            ).then(() => {
              this.sending = false;
              this.message = "";
              this.files = [];
              this.$refs.fileAttachment.files = [];
              this.$emit("refresh");
              let eventName = "Send Message";
              let payload = {
                message_type: "sms",
                cloak_name: this.thread.identity.nickname
                  ? this.thread.identity.nickname
                  : this.thread.identity.app_ref,
                cloak_id: this.thread.identity.id,
                thread_id: this.thread.conversation_id,
              };
              analyticsExport.methods.sendEvent(eventName, payload);
            });
          });
        }
      } else {
        this.errors = errors;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.activity-approve-contact {
  position: fixed;
  bottom: 5px;
  width: calc(100vw - 720px);
  margin: 31px 31px 0 31px;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
  background-color: $color-primary-5;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  font-family: $poppins;
  color: $color-primary-60;
  h1 {
    align-self: center;
    margin: 0 auto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    letter-spacing: -0.2px;
    color: $color-primary-100;
  }
  > div {
    padding: 23px;
    p {
      margin: 10px 0;
    }
    div {
      &:last-child {
        gap: 10px;
        display: flex;
        justify-content: center;
        button {
          &.disabled {
            opacity: 0.3 !important;
          }
          color: $color-primary-100;
          gap: 10px;
          padding: 10px 22px;
          flex-direction: row;
          align-items: center;
          display: inline-flex;
          cursor: pointer;
          border-radius: 999px;
          justify-content: center;
          border: 1px solid $color-primary-100;
          background-color: transparent;
          &:last-child {
            color: $color-primary-0;
            background-color: $color-primary-100;
            svg {
              color: $color-primary-0;
            }
          }
        }
      }
    }
  }
}
.relative {
  position: relative;
}
.activity-convo-input {
  bottom: 0;
  padding: 0 10px 10px 0;
  display: flex;
  position: fixed;
  min-height: 114px;
  font-family: $poppins;
  flex-direction: column;
  width: calc(100vw - 650px);
  background-color: $color-surface;
  @media (max-width: 1024px) {
    width: calc(100vw - 400px);
  }
  border-top: 1px solid $color-primary-10;
  &:focus-within {
    outline: 1px solid transparent;
  }
  > textarea {
    width: 100%;
    resize: none;
    border: none;
    &:focus {
      outline: none;
    }
    background-color: $color-surface;
    color: $color-primary-100;
    padding: 17.5px 16px;
    font-family: $poppins;
  }
  .message_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    color: $color-primary-100;
    .file-section {
      display: flex;
      align-items: center;
    }
    button {
      width: 24px;
      height: 24px;
      border: none;
      display: flex;
      align-items: center;
      border-radius: 999px;
      justify-content: center;
      background-color: $color-primary-100;
      color: $color-primary-0;
      &.disabled {
        pointer-events: none;
        opacity: 0.3;
      }
      &.active {
        cursor: pointer;
        background-color: $color-primary-100;
      }
    }
  }
}
</style>
