var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.PreferencesPanel,
    {
      staticClass: "delete-account",
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(_setup.PreferencesHeader, {
                on: { "go-back": _setup.handleGoBack },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _setup.currentStep === "password"
                ? _c(
                    _setup.PreferencesFooter,
                    [
                      _c(
                        _setup.Button,
                        {
                          attrs: {
                            loading: _setup.state.loadingPassword,
                            disabled:
                              _setup.state.loadingPassword ||
                              !_setup.state.password,
                          },
                          on: { click: _setup.validatePassword },
                        },
                        [_vm._v("Continue")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _setup.currentStep === "confirmation"
                ? _c(
                    _setup.PreferencesFooter,
                    [
                      _c(
                        _setup.Button,
                        {
                          attrs: {
                            type: "danger",
                            disabled:
                              !_setup.state.userUnderstands ||
                              _setup.state.loadingPassword,
                          },
                          on: { click: _setup.handleDelete },
                        },
                        [_vm._v(" Delete my account ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _setup.currentStep === "before-export"
                ? _c(
                    _setup.PreferencesFooter,
                    [
                      _c(
                        _setup.Button,
                        {
                          attrs: { type: "secondary" },
                          on: { click: _setup.handleLater },
                        },
                        [_vm._v("I'll do this later")]
                      ),
                      _c(
                        _setup.Button,
                        { on: { click: _setup.handleExportData } },
                        [_vm._v("Export account data")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _setup.currentStep === "password"
        ? [
            _c(_setup.PreferencesTitle, [_vm._v("Delete account")]),
            _c(_setup.PreferencesParagraph, [
              _vm._v(
                " This will permanently delete all data associated with your Cloaked identities including email addresses, phone numbers, passwords, usernames, and notes. "
              ),
            ]),
            _c(_setup.PreferencesParagraph, [
              _vm._v(
                " You will no longer be able to send and receive emails, texts, and calls from any of your Cloaked identities. This may also affect your ability to log in to websites associated with your Cloaked identities. "
              ),
            ]),
            _c(_setup.PreferencesParagraph, [
              _vm._v("To continue, enter your account password."),
            ]),
            _c(_setup.PreferencesInput, {
              attrs: {
                label: "Password",
                type: "password",
                placeholder: "Your Password",
                error: _setup.state.invalidPassword,
                disabled: _setup.state.loadingPassword,
              },
              on: { save: _setup.validatePassword },
              model: {
                value: _setup.state.password,
                callback: function ($$v) {
                  _vm.$set(_setup.state, "password", $$v)
                },
                expression: "state.password",
              },
            }),
          ]
        : _vm._e(),
      _setup.currentStep === "confirmation"
        ? [
            _c(_setup.PreferencesTitle, [_vm._v("Confirm account deletion")]),
            _c(_setup.PreferencesParagraph, [
              _vm._v(
                " You’ll still have access to your Cloaked account and be able to restore your account within the next 30 days. "
              ),
            ]),
            _c(
              _setup.PreferencesCheckParagraph,
              {
                staticClass: "disclaimer-row",
                model: {
                  value: _setup.state.userUnderstands,
                  callback: function ($$v) {
                    _vm.$set(_setup.state, "userUnderstands", $$v)
                  },
                  expression: "state.userUnderstands",
                },
              },
              [
                _vm._v(
                  " I understand that all of my account data will be deleted on "
                ),
                _c("strong", [_vm._v(_vm._s(_setup.deleteDateLabel))]),
                _vm._v(" and cannot be retrieved after that. "),
              ]
            ),
          ]
        : _vm._e(),
      _setup.currentStep === "before-export"
        ? [
            _c(_setup.PreferencesTitle, [
              _vm._v(
                " Your account will be deleted on " +
                  _vm._s(_setup.deleteDateLabel) +
                  " "
              ),
            ]),
            _c(_setup.PreferencesParagraph, [
              _vm._v(
                " You’ll still have access to your Cloaked account and be able to restore your account within the next 30 days. "
              ),
            ]),
            _c(_setup.PreferencesTitle, [
              _vm._v("Your data is important to us"),
            ]),
            _c(_setup.PreferencesParagraph, [
              _vm._v(
                " We value your data and privacy. We strongly encourage you to export your data so that you can retain access to it after your account is deleted on "
              ),
              _c("strong", [_vm._v(_vm._s(_setup.deleteDateLabel))]),
              _vm._v(
                ". After that date, it will be permanently deleted and Cloaked will be unable to retrieve it. "
              ),
            ]),
            _c(_setup.PreferencesParagraph, [
              _vm._v("Click the button below to begin exporting."),
            ]),
          ]
        : _vm._e(),
      _setup.currentStep === "export"
        ? [
            _c(_setup.ExportData, {
              attrs: { "view-step": "export", "nav-disabled": "" },
              on: { toggleBack: _setup.toggleBack },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }