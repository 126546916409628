<template>
  <div class="import-banner" v-if="isVisible">
    <Import class="import-banner__icon" />
    <div class="import-banner__content">
      <h2 class="import-banner__title">
        Import your accounts to secure them with Cloaked
      </h2>
      <p class="import-banner__subtitle">
        Import your accounts from 1Password, LastPass, Chrome, and more
      </p>
    </div>
    <div class="import-banner__controls">
      <router-link :to="{ name: 'Import' }" class="import-banner__start"
        >Get started</router-link
      >
      <button @click="onClose" class="import-banner__close">
        <Close />
      </button>
    </div>
  </div>
</template>

<script>
import Import from "@/assets/icons/import.svg";
import Close from "@/assets/icons/close-modal-full.svg";

export const STORAGE_KEY_IMPORT_USED = "import-used";
const STORAGE_KEY_IMPORT_MODAL_DISMISSED = "import-modal-dismissed";

export default {
  components: {
    Import,
    Close,
  },
  data() {
    return {
      isDismissed: true,
      isDesktop: false,
    };
  },
  computed: {
    isVisible() {
      const allIdentities = this.$store.state.localdb.db_cloaks;

      return (
        this.isDesktop &&
        !this.isDismissed &&
        !this.isImportUsed &&
        allIdentities.length < 5
      );
    },
  },
  mounted() {
    this.isDismissed =
      localStorage.getItem(STORAGE_KEY_IMPORT_MODAL_DISMISSED) === "true";

    this.isImportUsed =
      localStorage.getItem(STORAGE_KEY_IMPORT_USED) === "true";

    const onResize = () => {
      this.isDesktop = window.innerWidth > 1023;
    };

    onResize();
    window.addEventListener("resize", onResize);
    this.$once("hook:destroyed", () => {
      window.removeEventListener("resize", onResize);
    });
  },
  methods: {
    onClose() {
      this.isDismissed = true;
      window.localStorage.setItem(STORAGE_KEY_IMPORT_MODAL_DISMISSED, "true");
    },
  },
};
</script>

<style lang="scss">
.import-banner {
  margin: 24px 24px 14px;
  padding: 24px;
  background-color: $color-primary-5;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media all and (min-width: 800px) {
    flex-direction: row;
  }

  &__icon {
    flex-shrink: 0;
    color: $color-primary-100;
    > path {
      color: $color-primary-0;
    }
  }

  &__content {
    margin: 16px 24px 0;
    text-align: left;

    @media all and (min-width: 800px) {
      margin: 0 24px 0;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    text-align: center;

    @media all and (min-width: 800px) {
      text-align: left;
    }
  }

  &__subtitle {
    margin: 4px 0 24px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: $color-primary-50;

    @media all and (min-width: 800px) {
      margin: 4px 0 0;
      text-align: left;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    @media all and (min-width: 800px) {
      margin-left: auto;
    }
  }

  &__start {
    padding: 11px 16px;
    border-radius: 999px;
    border: 1px solid $color-primary-100;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $color-primary-100;
    cursor: pointer;

    &:hover {
      background-color: $color-primary-10;
    }
  }

  &__close {
    margin-left: 8px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    right: 24px;
    top: 24px;
    color: $color-primary-100;

    &:hover {
      opacity: 0.8;
    }

    @media all and (min-width: 800px) {
      position: static;
    }
  }
}
</style>
