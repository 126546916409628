var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "check-button",
      class: {
        "check-button--checked": !!_vm.value,
        "check-button--disabled": _vm.disabled,
        "check-button--square": _vm.square,
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return (() => {}).apply(null, arguments)
        },
      },
    },
    [
      _c("input", {
        attrs: { type: "checkbox", disabled: _vm.disabled },
        domProps: { checked: !!_vm.value },
        on: { change: _vm.handleChange },
      }),
      !_vm.square
        ? [!!_vm.value ? _c("CheckActive") : _c("CheckInactive")]
        : _vm._e(),
      _vm.square
        ? [!!_vm.value ? _c("CheckSquareActive") : _c("CheckSquareInactive")]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }