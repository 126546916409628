var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.props.identity && _setup.state.logo
    ? _c(
        "div",
        {
          staticClass: "identity-icon",
          style: _setup.getBkgdWrapperStyle(),
          on: { click: _setup.handleClick },
        },
        [_c("div", { staticClass: "logo", style: _setup.getBkgdLogoStyle() })]
      )
    : _setup.newDefaultIconsEnabled
    ? _c(_setup.DefaultIdentityIcon, {
        key: `${_setup.state.colors?.color}${_setup.props.identity?.nickname}`,
        attrs: {
          color: _setup.state.colors?.color,
          secondaryColor: _setup.state.colors?.secondaryColor,
          height: _setup.props.override?.height,
          width: _setup.props.override?.width,
        },
      })
    : _c(
        "div",
        {
          staticClass: "identity-icon",
          style: _setup.getBkgdWrapperStyle(),
          on: { click: _setup.handleClick },
        },
        [
          _c("div", {
            staticClass: "logo",
            style: _setup.getLegacyGradient(_setup.props.identity?.muted),
          }),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }