var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesCheckParagraph",
    {
      staticClass: "marketing-opt-in",
      attrs: { disabled: _vm.optedIn === null || _vm.loading === true },
      model: {
        value: _vm.optedIn,
        callback: function ($$v) {
          _vm.optedIn = $$v
        },
        expression: "optedIn",
      },
    },
    [_vm._v(" Opt in to receive updates and announcements from Cloaked ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }