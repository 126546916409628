var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.state.visible
    ? _c(
        _setup.AppModal,
        _vm._g(_vm._b({}, "AppModal", _vm.$attrs, false), _vm.$listeners),
        [
          _c(
            _setup.AppModalContent,
            [
              _c(_setup.AppModalVideo, {
                attrs: {
                  src: "/videos/mfa-announcement.mp4",
                  autoplay: "",
                  loop: "",
                },
              }),
              _c(_setup.AppModalTitle, [
                _vm._v(" Introducing Cloaked two-factor authentication (2FA) "),
              ]),
              _c(_setup.AppModalParagraph, [
                _vm._v(
                  " You can now protect your Cloaked account with 2FA! Head over to Settings to set up 2FA. Once you’re set up, you can authenticate Cloaked across all of your devices using SMS or email. "
                ),
              ]),
              _c(
                _setup.AppModalFooter,
                [
                  _c(
                    _setup.Button,
                    {
                      attrs: { type: "secondary" },
                      on: { click: _setup.handleClose },
                    },
                    [_vm._v("Maybe later")]
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        tag: "span",
                        to: "/settings/account",
                        "exact-path": "",
                      },
                    },
                    [
                      _c(_setup.Button, { on: { click: _setup.handleClose } }, [
                        _vm._v("Set up 2FA"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }