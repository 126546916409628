<script setup>
import { ref, onMounted, computed, watch } from "vue";
import CardSingle from "./CardsListing/CardSingle";
import CardsServices from "@/api/actions/cards-services";
import InlineSvg from "@/components/InlineSvg.vue";
import store from "@/store";
import router from "@/routes/router";

const cards = computed(() => {
  return store.state.cards.cards;
});

const loading = ref(true);

onMounted(() => {
  if (cards.value.length === 0) {
    CardsServices.getCardList();
  } else {
    setTimeout(() => {
      loading.value = false;
    }, 800);
  }
});

watch(cards, (newValue, oldValue) => {
  loading.value = false;

  if (newValue.count > oldValue.count) {
    router.push("/wallet/card/" + newValue.results[0].id);
  }
});
</script>

<template>
  <div class="cards" :class="{ loaded: !loading }">
    <div class="title">
      <h1>Cloaked cards</h1>
    </div>

    <div class="list-details">
      <div class="list" v-if="!loading">
        <CardSingle :card="card" v-for="card in cards.results" :key="card.id" />
      </div>

      <div class="loader">
        <InlineSvg name="loading-small" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cards {
  width: 400px;
  border-right: 1px solid $color-primary-10;
  height: calc(100vh - 60px);
  overflow: hidden;
  position: fixed;
  @include custom-scroll-bar();
  padding-bottom: 30px;

  &.loaded {
    overflow: auto;
  }

  .title {
    position: sticky;
    top: 0;
    background-color: $color-surface;
    z-index: 3;
    padding: 22px 22px 10px 22px;

    h1 {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.2px;
      color: $color-primary-100;
    }
  }

  .list-details {
    padding: 7px 22px 0 22px;
    position: relative;

    .list {
      .card {
        --distance: calc(222px - 95px);

        opacity: 0;
        animation: fadein 0.3s;
        animation-fill-mode: forwards;

        @keyframes fadein {
          0% {
            opacity: 0;
            top: 80px;
          }
          100% {
            opacity: 1;
            top: 0;
          }
        }

        @for $i from 1 through 20 {
          &:nth-child(#{$i}) {
            animation-delay: #{$i * 0.09}s;
          }
        }
      }
    }

    .loader {
      position: absolute;
      top: 7px;
      left: 22px;
      width: calc(100% - 44px);
      height: 222px;
      border-radius: 26px;
      border: 1.009px solid $color-primary-10;
      background: radial-gradient(
        157.8% 94.4% at 54.96% 48%,
        $color-surface 34.38%,
        $color-primary-20 100%
      );
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: -1;
    }
  }
}
</style>
