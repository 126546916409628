<template>
  <PreferencesPanel class="export-data">
    <template v-slot:header v-if="!navDisabled">
      <PreferencesHeader @go-back="handleGoBack" />
    </template>

    <template v-if="currentStep === 'password'">
      <PreferencesTitle>Export my data</PreferencesTitle>
      <PreferencesParagraph>
        Please enter your password to continue.
      </PreferencesParagraph>

      <PreferencesInput
        v-model="password"
        label="Password"
        type="password"
        placeholder="Your Password"
        :error="invalidPassword"
        :disabled="loadingPassword"
        @save="validatePassword"
      />
    </template>

    <template v-if="currentStep === 'options'">
      <PreferencesTitle>Choose data to export</PreferencesTitle>

      <div class="export-data__options">
        <ValueDisplay
          label="Identity data"
          value="Passwords, email addresses, phone numbers, usernames, notes"
          @click="setOption(options.IDENTITY)"
        />

        <ValueDisplay
          label="Inbox data (large file size)"
          value="Emails, text messages, calls"
          @click="setOption(options.ACTIVITY)"
        />

        <ValueDisplay
          label="All data (large file size)"
          @click="setOption(options.ALL)"
        />
      </div>
    </template>

    <template v-if="currentStep === 'export'">
      <div class="header-icon-row">
        <HourglassRound v-if="!downloadReady" />
        <DownloadRound v-else class="success" />
      </div>

      <template v-if="!downloadReady">
        <PreferencesTitle big>
          Getting your data ready for export
        </PreferencesTitle>

        <PreferencesParagraph>
          While most exports finish in about 5 minutes, this might take a while.
          Feel free to leave this tab open and return later.
        </PreferencesParagraph>
      </template>

      <template v-if="downloadReady">
        <PreferencesTitle big>Export completed</PreferencesTitle>

        <PreferencesParagraph>
          {{ totalIdentities }} Cloaked identities exported successfully.
        </PreferencesParagraph>
      </template>

      <UiProgress
        :label="progress.label"
        :counter="progress.counter"
        :counter-total="progress.counterTotal"
      />
    </template>

    <template v-slot:footer>
      <PreferencesFooter v-if="currentStep === 'password'">
        <Button
          @click="validatePassword"
          :disabled="loadingPassword || !password"
          :loading="loadingPassword"
          >Continue</Button
        >
      </PreferencesFooter>

      <PreferencesFooter v-if="currentStep === 'export'">
        <Button
          type="secondary"
          :disabled="loadingIdentities || !downloadReady"
          :loading="loadingIdentities"
          @click="handleDownloadAgain"
        >
          Download again
        </Button>

        <Button
          :loading="loadingIdentities"
          :disabled="loadingIdentities"
          @click="handleDone"
        >
          Done
        </Button>
      </PreferencesFooter>
    </template>
  </PreferencesPanel>
</template>

<script>
import moment from "moment";
import { get } from "lodash-es";
import { mapState } from "vuex";

import {
  PreferencesHeader,
  PreferencesFooter,
  PreferencesParagraph,
  PreferencesTitle,
  PreferencesInput,
  PreferencesPanel,
} from "@/routes/modals/preferences";

import ValueDisplay from "@/components/ui/value-display";
import { Button, UiProgress } from "@/components/";
import api from "@/api/api";
import { HourglassRound, DownloadRound } from "@/assets/icons";
import { downloadFile } from "@/scripts/tools.js";
import { authDecrypt, password_confirm } from "@/scripts/actions/encryption";
import AuthService from "@/api/actions/auth-service";
export default {
  name: "DeleteAccount",
  components: {
    PreferencesHeader,
    PreferencesFooter,
    PreferencesInput,
    PreferencesParagraph,
    PreferencesTitle,
    PreferencesPanel,
    Button,
    ValueDisplay,
    HourglassRound,
    DownloadRound,
    UiProgress,
  },

  props: {
    viewStep: String,
    navDisabled: Boolean,
  },

  data() {
    return {
      step: 0,
      steps: ["password", /* "options", */ "export"],
      userUnderstands: false,

      password: "",
      invalidPassword: false,
      loadingPassword: false,

      options: {
        IDENTITY: "IDENTITY",
        ACTIVITY: "INBOX",
        ALL: "ALL",
      },
      selectedOption: null,

      downloadReady: false,
      loadingIdentities: false,

      totalIdentities: 0,
      identities: [],
    };
  },

  computed: {
    ...mapState(["user"]),

    currentStep() {
      return this.steps[this.step];
    },

    progress() {
      const progress = {
        label: "Exporting Cloaked identities...",
        counter: this.identities.length,
        counterTotal: this.totalIdentities,
      };

      if (progress.counter && progress.counter === progress.counterTotal) {
        progress.label = "Finished";
      }

      return progress;
    },
  },

  watch: {
    currentStep: {
      immediate: true,
      handler() {
        if (this.currentStep === "export") {
          this.exportIdentities();
        }
      },
    },

    viewStep: {
      immediate: true,
      handler() {
        if (this.viewStep) {
          const found = this.steps.findIndex((item) => item === this.viewStep);

          if (found >= 0) {
            this.step = found;
          }
        }
      },
    },
  },

  methods: {
    toggle_back() {
      this.$emit("toggleBack", {});
    },

    nextStep() {
      if (this.navDisabled) {
        return this.$emit("next-step");
      }

      let next = this.step + 1;
      const maxStep = this.steps.length - 1;

      if (next >= maxStep) {
        next = maxStep;
      }

      this.step = next;
    },

    handleGoBack() {
      if (this.step > 0) {
        this.step = this.step - 1;
      } else {
        this.toggle_back();
      }
    },

    toggleUserUnderstands() {
      this.userUnderstands = !this.userUnderstands;
    },

    async validatePassword() {
      const userId = this.user.id;
      this.loadingPassword = true;
      let password = this.password;
      if (this.$store.state.authentication.user.encryption_status === 3) {
        password = await password_confirm(this.password);
      }
      AuthService.confirmPassword(userId, password)
        .then(() => {
          this.invalidPassword = false;
          this.nextStep();
        })
        .catch(() => {
          this.invalidPassword = true;
          this.$toast.error("Invalid password, please try again");
        })
        .finally(() => {
          this.loadingPassword = false;
        });
    },

    setOption(option) {
      this.selectedOption = option;
      this.nextStep();
    },

    handleDownloadAgain() {
      this.downloadIdentities();
    },

    handleDone() {
      this.toggle_back();
    },

    exportIdentities() {
      const getIdentities = ({ url, setTotal }) => {
        return api()
          .get(url)
          .then(({ data }) => {
            if (setTotal) {
              this.totalIdentities = data.count;
            }

            this.identities = [...this.identities, ...data.results];

            if (data.next) {
              return getIdentities({ url: data.next });
            }
          });
      };

      this.loadingIdentities = true;

      const pageSize = 20;

      const url = `/api/v2/cloaked/identity/?protected=false&page_size=${pageSize}`;

      getIdentities({ url, setTotal: true })
        .then(() => {
          return this.downloadIdentities();
        })
        .catch(() => {
          this.$toast.error("Error exporting");
          this.identities = [];
          this.totalIdentities = 0;
        })
        .finally(() => {
          this.loadingIdentities = false;
        });
    },

    async downloadIdentities() {
      const dateLabel = moment().format("YYYY-MM-DD");

      const columns = [
        {
          title: "Name",
          value: "nickname",
        },
        {
          title: "URL",
          value: "website_url",
        },
        {
          title: "Email",
          value: (identity) => {
            const cloaked = get(identity, "cloaked_email.email");
            const autofill = get(identity, "stored_autofill.email");
            return cloaked || autofill;
          },
        },
        {
          title: "Phone",
          value: (identity) => {
            const cloaked = get(identity, "cloaked_phone.phone_number");
            const autofill = get(identity, "stored_autofill.phone_number");

            return cloaked || autofill;
          },
        },
        {
          title: "Username",
          value: "stored_autofill.username",
        },
        {
          title: "Password",
          value: async (identity) => {
            const cloaked = get(identity, "stored_password.password");
            const autofill = get(identity, "stored_autofill.password");

            const password = cloaked || autofill;

            return await authDecrypt(password);
          },
        },
        {
          title: "Notes",
          value: "stored_autofill.notes",
        },
      ];

      const eol = "\r\n";

      const rows = [columns.map((col) => col.title).join(",")];

      for (const identity of this.identities) {
        const row = [];

        for (const column of columns) {
          let value;

          if (typeof column.value === "function") {
            if (column.value.constructor.name === "AsyncFunction") {
              value = await column.value(identity);
            } else {
              value = column.value(identity);
            }
          } else {
            value = get(identity, column.value, "");
          }

          value = value ?? "";
          // wrap value and escape double quotes
          row.push(`"${value.replaceAll('"', '""')}"`);
        }

        rows.push(row.join(","));
      }

      this.downloadReady = true;

      downloadFile({
        fileContents: rows.join(eol),
        fileName: `export-${dateLabel}.csv`,
      });
    },
  },
};
</script>

<style lang="scss">
.export-data {
  .preferences-input {
    margin-top: 37px;
  }

  .ui-progress {
    margin-top: 36px;
  }

  &__options {
    margin-top: 32px;
  }

  .header-icon-row {
    margin-bottom: 24px;

    svg {
      color: $color-primary-70;

      &.success {
        color: $color-info;
      }
    }
  }
}
</style>
