import { render, staticRenderFns } from "./Upgrade.vue?vue&type=template&id=f66953ce&scoped=true"
import script from "./Upgrade.vue?vue&type=script&setup=true&lang=js"
export * from "./Upgrade.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Upgrade.vue?vue&type=style&index=0&id=f66953ce&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f66953ce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/__w/dashboard/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f66953ce')) {
      api.createRecord('f66953ce', component.options)
    } else {
      api.reload('f66953ce', component.options)
    }
    module.hot.accept("./Upgrade.vue?vue&type=template&id=f66953ce&scoped=true", function () {
      api.rerender('f66953ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/routes/guest/Upgrade.vue"
export default component.exports