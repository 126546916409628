var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ModalTemplate, {
    staticClass: "identity-verification",
    attrs: { show: _setup.props.show, "no-close": "", width: "375px" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "text" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [
                  _c(_setup.InlineSvg, {
                    attrs: { name: "pay/identity-verification" },
                  }),
                ],
                1
              ),
              _c("h1", [_vm._v("Identity verification")]),
              _c("p", [
                _vm._v(
                  " Cloaked will securely verify your legal name, address and other specifics, for Cloaked Pay. "
                ),
              ]),
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _setup.emit("close")
                      },
                    },
                  },
                  [
                    _vm._v(" Start verification "),
                    _c(_setup.InlineSvg, {
                      attrs: { name: "user-verification" },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _setup.emit("goBack")
                      },
                    },
                  },
                  [_vm._v("Go back")]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }