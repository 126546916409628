<script setup>
import AutoChangeIcon from "@/assets/icons/auto-change.svg";
import { AutoChangeStatus } from "@/components/auto-change/utils";
import { defineProps } from "vue";

defineProps({
  status: {
    type: String,
    required: true,
    validator: (value) =>
      [
        "waiting",
        "inProgress",
        "needsReview",
        "success",
        "error",
        "skipped",
      ].includes(value),
  },
});
</script>

<template>
  <span>
    <transition name="fade-100" mode="out-in">
      <span
        :class="`auto-change-icon auto-change-icon--${status}`"
        v-if="status === AutoChangeStatus.WAITING"
        key="waiting"
        v-on="$listeners"
      >
        <AutoChangeIcon class="auto-change-icon__icon" />
      </span>
      <span
        :class="`auto-change-icon auto-change-icon--${status}`"
        v-else-if="status === AutoChangeStatus.IN_PROGRESS"
        key="inProgress"
        v-on="$listeners"
      >
        <AutoChangeIcon class="auto-change-icon__icon" />
      </span>
      <span
        :class="`auto-change-icon auto-change-icon--${status}`"
        v-else-if="status === AutoChangeStatus.SKIPPED"
        key="skipped"
        v-on="$listeners"
      >
        <AutoChangeIcon class="auto-change-icon__icon" />
      </span>
      <span
        :class="`auto-change-icon auto-change-icon--${status}`"
        v-else-if="status === AutoChangeStatus.NEEDS_REVIEW"
        key="needsReview"
        v-on="$listeners"
      >
        <AutoChangeIcon class="auto-change-icon__icon" />
      </span>
      <span
        :class="`auto-change-icon auto-change-icon--${status}`"
        v-else-if="status === AutoChangeStatus.ERROR"
        key="error"
        v-on="$listeners"
      >
        <AutoChangeIcon class="auto-change-icon__icon" />
      </span>
      <span
        :class="`auto-change-icon auto-change-icon--${status}`"
        v-else-if="status === AutoChangeStatus.SUCCESS"
        key="success"
        v-on="$listeners"
      >
        <AutoChangeIcon class="auto-change-icon__icon" />
      </span>
    </transition>
  </span>
</template>

<style lang="scss">
.auto-change-icon {
  &--waiting {
    color: $color-primary-100;
  }

  &--inProgress {
    color: $color-success;
  }

  &--skipped {
    color: $color-primary-30;
  }

  &--needsReview {
    color: $color-warning;
  }

  &--error {
    color: $color-alert;
  }

  &--success {
    color: $color-success;
  }

  &__icon {
    width: 24px;
    height: 24px;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @at-root .auto-change-icon--waiting &,
      .auto-change-icon--inProgress & {
      animation: spin 1s infinite linear;
    }

    @at-root .auto-change-icon--waiting &,
      .auto-change-icon--inProgress &,
      .auto-change-icon--skipped & {
      path {
        fill: none;
      }
    }
  }
}
</style>
