import Subscription from "@/components/modals/Subscription";

export default {
  namespaced: true,
  state: {
    ui: {
      modal: false,
    },
    status: {
      active: false,
    },
    plans: [],
  },
  mutations: {},
  actions: {
    openSubscriptionModal({ dispatch }) {
      dispatch(
        "openModal",
        {
          customTemplate: {
            template: Subscription,
          },
        },
        { root: true }
      );
    },
    savePlans({ state }, plans) {
      state.plans = plans;
    },
  },
  getters: {
    isSubscribed(state) {
      return state.status.active;
    },
  },
};
