// store/modules/colorScheme.js

import { DARK_MODE_ENABLED } from "@/scripts/featureFlags";

const state = {
  envDarkMode: false,
  userDarkMode: null,
};

const mutations = {
  ENV_DARK_MODE(state, value) {
    state.envDarkMode = value;
  },
  USER_DARK_MODE(state, value) {
    state.userDarkMode = value;
  },
};

const actions = {
  detectColorScheme({ commit }) {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );

    commit("ENV_DARK_MODE", darkModeMediaQuery.matches);

    darkModeMediaQuery.addEventListener("change", (event) => {
      commit("ENV_DARK_MODE", event.matches);
    });
  },
  changeColorTheme({ commit, getters }) {
    const newDarkModeSetting = !getters.isDarkMode;
    commit("USER_DARK_MODE", newDarkModeSetting);

    if (newDarkModeSetting) {
      document.body.classList.add("theme-dark");
      document.body.classList.remove("theme-light");
    } else {
      document.body.classList.add("theme-light");
      document.body.classList.remove("theme-dark");
    }
  },
};

const getters = {
  isDarkMode(state) {
    if (DARK_MODE_ENABLED) {
      if (state.userDarkMode !== null) {
        return state.userDarkMode;
      }
      return state.envDarkMode;
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
