import api from "@/api/api";

export default class ActivityService {
  static async postContactStatus(id, status) {
    return await api().post(`/api/v1/contacts/phone/${id}/${status}/`);
  }
  static async postActivityReply(id, body) {
    return await api().post(`api/v2/cloaked/activity/${id}/reply/`, body);
  }
  static async loadActivityId(id) {
    return api().get(`/api/v1/cloaked/activity/${id}/?populated=true`);
  }

  static async getActivities(params) {
    const query = {
      page_size: 20,
      page: 1,
      ...params,
    };

    const queryParams = Object.keys(query).map((key) => {
      return `${key}=${query[key]}`;
    });

    return api().get(`api/v1/cloaked/activity/?${queryParams.join("&")}`);
  }

  static async postActivityEmailForward(id, body) {
    return await api().post(
      `api/v2/cloaked/activity/${id}/email/forward/`,
      body
    );
  }

  static nextActivityUrlWithSearch(search_text) {
    return `api/v1/cloaked/activity/?muted=false&sensitive_search=${search_text}&page_size=20&group_threads=true`;
  }

  static async getUnreadCount() {
    return await api().get(`api/v2/cloaked/activity/unread-count/`);
  }
}
