var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { ref: "sectionList", staticClass: "section-list" }, [
    _vm.title
      ? _c("h4", { staticClass: "section-list__title" }, [
          _vm._v(_vm._s(_setup.props.title)),
        ])
      : _vm._e(),
    _c(
      "ul",
      { ref: "sectionListItems", staticClass: "section-list__items" },
      _vm._l(_setup.props.items, function (item, idx) {
        return _c(
          "li",
          {
            key: `${idx}-${item.title || item.nickname}`,
            staticClass: "section-list__item",
            class: {
              "section-list__item--active": _setup.props.active == idx,
            },
            attrs: { "aria-id": `Add${item?.nickname || ""}` },
            on: {
              mousedown: function ($event) {
                return _setup.emit("select", item)
              },
              mouseenter: function ($event) {
                return _setup.emit("setActive", idx)
              },
            },
          },
          [
            _c("div", { staticClass: "section-list__item-body" }, [
              item.title || item.nickname
                ? _c("h2", { staticClass: "section-list__item-title" }, [
                    _vm._v(" " + _vm._s(item.title || item.nickname) + " "),
                  ])
                : _vm._e(),
              item.website_url
                ? _c("h4", { staticClass: "section-list__item-subtitle" }, [
                    _vm._v(" " + _vm._s(item.website_url) + " "),
                  ])
                : _vm._e(),
            ]),
            _setup.props.active == idx
              ? _c(
                  "div",
                  {
                    staticClass: "section-list__item-actions",
                    attrs: { "aria-id": "WebsiteSelectButton" },
                  },
                  [_c(_setup.EnterIcon)],
                  1
                )
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }