var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesPanel",
    {
      staticClass: "manage-account",
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("PreferencesHeader", { on: { "go-back": _vm.handleGoBack } }),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _vm.currentStep === "restore"
                ? _c(
                    "PreferencesFooter",
                    [
                      _c(
                        "Button",
                        {
                          attrs: {
                            loading: _vm.loadingRestore,
                            disabled: _vm.loadingRestore,
                          },
                          on: { click: _vm.handleRestore },
                        },
                        [_vm._v(" Restore my account ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentStep === "delete-verification"
                ? _c(
                    "PreferencesFooter",
                    [
                      _c(
                        "Button",
                        {
                          attrs: {
                            disabled: !_vm.password || _vm.loadingPassword,
                          },
                          on: { click: _vm.validatePassword },
                        },
                        [_vm._v("Continue")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentStep === "delete-confirmation"
                ? _c(
                    "PreferencesFooter",
                    [
                      _c(
                        "Button",
                        {
                          attrs: {
                            type: "danger",
                            disabled: !_vm.userUnderstands || _vm.loadingDelete,
                          },
                          on: { click: _vm.handleDelete },
                        },
                        [_vm._v(" Delete account now ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.currentStep === "manage"
        ? [
            _c("PreferencesTitle", [_vm._v("Manage account")]),
            _c("PreferencesParagraph", [
              _vm._v(" Your account is scheduled to be deleted on "),
              _c("strong", [_vm._v(_vm._s(_vm.deleteDateLabel))]),
              _vm._v(
                ". You can restore your account at any time before this date to continue using Cloaked. "
              ),
            ]),
            _c("PreferencesParagraph", [
              _vm._v(
                " You can also delete your account now instead of waiting until "
              ),
              _c("strong", [_vm._v(_vm._s(_vm.deleteDateLabel))]),
              _vm._v(". "),
            ]),
            _c(
              "div",
              { staticClass: "manage-account__options" },
              [
                _c("ValueDisplay", {
                  attrs: { label: "Restore my account", "dark-label": "" },
                  on: {
                    click: function ($event) {
                      return _vm.setOption(_vm.options.RESTORE)
                    },
                  },
                }),
                _c("ValueDisplay", {
                  attrs: {
                    label: "Permanently delete my account now",
                    danger: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setOption(_vm.options.DELETE)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.currentStep === "restore"
        ? [
            _c("PreferencesTitle", [_vm._v("Restore account")]),
            _c("PreferencesParagraph", [
              _vm._v(
                " This will cancel the account deletion process and restore your Cloaked account. You won’t lose any of your account data and will be able to continue using Cloaked. "
              ),
            ]),
          ]
        : _vm._e(),
      _vm.currentStep === "delete-verification"
        ? [
            _c("PreferencesTitle", [_vm._v("Delete account now")]),
            _c("PreferencesParagraph", [
              _vm._v(" To continue, enter your account password. "),
            ]),
            _c("PreferencesInput", {
              attrs: {
                label: "Password",
                type: "password",
                placeholder: "Your Password",
                error: _vm.invalidPassword,
                disabled: _vm.loadingPassword,
              },
              on: { save: _vm.validatePassword },
              model: {
                value: _vm.password,
                callback: function ($$v) {
                  _vm.password = $$v
                },
                expression: "password",
              },
            }),
          ]
        : _vm._e(),
      _vm.currentStep === "delete-confirmation"
        ? [
            _c("PreferencesTitle", [_vm._v("Delete account now")]),
            _c("PreferencesParagraph", [
              _vm._v(
                " This will permanently delete all data associated with your Cloaked identities including email addresses, phone numbers, passwords, usernames, and notes. "
              ),
            ]),
            _c("PreferencesParagraph", [
              _vm._v(
                " You will no longer be able to send and receive emails, texts, and calls from any of your Cloaked identities. This may also affect your ability to log in to websites associated with your Cloaked identities. "
              ),
            ]),
            _c("PreferencesParagraph", [
              _c("strong", [_vm._v("This action cannot be undone.")]),
              _vm._v(
                " You will automatically lose access to your Cloaked account. "
              ),
            ]),
            _c(
              "PreferencesCheckParagraph",
              {
                staticClass: "disclaimer-row",
                model: {
                  value: _vm.userUnderstands,
                  callback: function ($$v) {
                    _vm.userUnderstands = $$v
                  },
                  expression: "userUnderstands",
                },
              },
              [
                _vm._v(
                  " I understand my account, my identities, and all of my data will be permanently deleted and cannot be retrieved. "
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }