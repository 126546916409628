<template>
  <PreferencesPanel class="email-gen-container">
    <PreferencesTitle>Email address style</PreferencesTitle>
    <!-- <PreferencesParagraph>
      Choose your default email generation format. You can always override these
      default settings when rerolling a Cloaked email.
    </PreferencesParagraph> -->

    <div class="email-gen-container__radios">
      <PreferencesRadio
        label="Professional (i.e. username22@cloak.id)"
        :value="email_type"
        input-value="professional"
        group-name="email"
        :disabled="loading"
        @update="handleUpdate"
      />

      <PreferencesRadio
        label="Random (i.e. glass.frog.bugs@cloak.id)"
        :value="email_type"
        input-value="random"
        group-name="email"
        :disabled="loading"
        @update="handleUpdate"
      />
    </div>
  </PreferencesPanel>
</template>

<script>
import api from "@/api/api";
import PreferencesTitle from "./PreferencesTitle";
// import PreferencesParagraph from "./PreferencesParagraph";
import PreferencesPanel from "./PreferencesPanel";
import PreferencesRadio from "./PreferencesRadio";

export default {
  name: "EmailGenSettings",
  components: {
    PreferencesPanel,
    PreferencesRadio,
    // PreferencesParagraph,
    PreferencesTitle,
  },

  computed: {
    email_type() {
      return this.$store.state.profile.email_type;
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    toggleEmailGen(value) {
      if (this.loading) {
        return;
      }

      const payload = {
        email_type: value,
      };

      const endpoint = "/api/v1/settings/user_profile/";

      this.loading = true;

      api()
        .post(endpoint, payload)
        .then(({ data }) => {
          this.$toast.success("Email generation type saved.");
          this.$store.commit("setEmailTypeSetting", data.email_type);
          this.loading = false;
        })
        .catch(() => {
          this.$toast.error("Error saving your email generation type.");
          this.loading = false;
        });
    },

    handleUpdate(value) {
      this.toggleEmailGen(value);
    },
  },
};
</script>
<style lang="scss">
.email-gen-container {
  &__radios {
    margin-top: 12px;
  }
}
</style>
