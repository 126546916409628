var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.TOTPInputRow, {
        attrs: {
          totp: _setup.totp,
          isTotpIntroDisplayed: _setup.isTotpIntroDisplayed,
          totpToken: _setup.totpToken,
          loading: _vm.$attrs.loading,
        },
        on: {
          save: _setup.saveTotp,
          delete: _setup.deleteTotp,
          "set-is-totp-intro-displayed": function ($event) {
            _setup.isTotpIntroDisplayed = $event
          },
          "set-totp-token": function ($event) {
            _setup.totpToken = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }