var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { staticClass: "switch" }, [
    _c("input", {
      attrs: {
        type: "checkbox",
        name: "checkbox",
        autocomplete: "off",
        "data-lpignore": "true",
        "data-form-type": "other",
        disabled: _vm.disabled,
      },
      domProps: { checked: _vm.value },
      on: { change: _vm.handleChange },
    }),
    _c("span"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }