var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.PreferencesPanel,
    { staticClass: "forwarding-pref" },
    [
      _c(_setup.PreferencesTitle, [
        _vm._v("Forward incoming emails to your personal inbox? "),
      ]),
      _c(_setup.PreferencesParagraph, [
        _vm._v(
          " This will only affect future emails. Settings for your existing Cloaked identities will be unaffected. "
        ),
      ]),
      _c(_setup.ValueDisplay, {
        attrs: {
          label: _setup.emailForwardingLabel,
          value: _setup.emailForwardingValue,
          "dark-label": "",
          "light-value": "",
          "no-separator": "",
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function () {
              return [
                _c(_setup.UiSwitchInput, {
                  attrs: { value: _setup.state.emailForwardingEnabled },
                  on: { change: _setup.handleEmailForwardingChange },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._l(_setup.verifiedEmails, function (email) {
        return _c(_setup.PreferencesRadio, {
          key: email.id,
          attrs: {
            label: email.email,
            value: _setup.forwardingEmailId,
            "input-value": email.id,
            "group-name": "emails",
            deletable: "",
            disabled: !_setup.state.emailForwardingEnabled,
          },
          on: {
            update: _setup.handleEmailUpdate,
            delete: _setup.handleEmailDelete,
          },
        })
      }),
      _setup.state.emailForwardingEnabled
        ? _c(_setup.Button, { on: { click: _setup.handleAddEmail } }, [
            _vm._v(" Add an email address "),
          ])
        : _vm._e(),
      _c(_setup.PreferencesTitle, [
        _vm._v("Forward incoming calls and texts to your personal phone?"),
      ]),
      _c(_setup.PreferencesParagraph, [
        _vm._v(
          " This will only affect future calls and texts. Settings for your existing Cloaked identities will be unaffected. "
        ),
      ]),
      _c(_setup.ValueDisplay, {
        attrs: {
          label: _setup.phoneForwardingLabel,
          value: _setup.phoneForwardingValue,
          "dark-label": "",
          "light-value": "",
          "no-separator": "",
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function () {
              return [
                _c(_setup.UiSwitchInput, {
                  attrs: { value: _setup.state.phoneForwardingEnabled },
                  on: { change: _setup.handlePhoneForwardingChange },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._l(_setup.verifiedPhones, function (phone) {
        return _c(_setup.PreferencesRadio, {
          key: phone.id,
          attrs: {
            label: phone.formattedPhoneNumber,
            value: _setup.forwardingPhoneId,
            "input-value": phone.id,
            "group-name": "phones",
            deletable: "",
            disabled: !_setup.state.phoneForwardingEnabled,
          },
          on: {
            update: _setup.handlePhoneUpdate,
            delete: _setup.handlePhoneDelete,
          },
        })
      }),
      _setup.state.phoneForwardingEnabled
        ? _c(_setup.Button, { on: { click: _setup.handleAddPhone } }, [
            _vm._v(" Add a phone number "),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }