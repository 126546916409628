import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";
import All from "@/routes/your-cloaks/All.vue";
import Favorites from "@/routes/your-cloaks/Favorites.vue";
import Muted from "@/routes/your-cloaks/Muted.vue";
import NumberReuse from "@/routes/NumberReuse.vue";
import { hash } from "@/scripts";

import {
  Activity,
  Settings,
  CloakedDaily,
  Referral,
  SettingsAccount,
  SettingsEmailGeneration,
  SettingsForwarding,
  SettingsPersonalInformation,
  SettingsPrivacy,
  SettingsSubscription,
  SettingsPassphrase,
  SettingsCloakedCards,
  Cards,
  Transactions,
  ReferralCode,
  ReferralDenied,
  Shared,
  Trash,
  AutoChange,
  WalletPage,
} from "@/routes";

import ActivityRouter from "@/routes/activity/ActivityRouter";
import Category from "@/routes/categories/Category";
// import TryCloaked from "@/routes/tryCloaked";
import Login from "@/routes/guest/Login";
import Forgot from "@/routes/guest/Forgot";
import Register from "@/routes/guest/Register";
import RecentlyImported from "@/routes/RecentlyImported";
import Onboarding from "@/routes/onboarding/Onboarding";
import OnboardingDemo from "@/routes/onboarding/OnboardingDemo";
import OnboardingForwarding from "@/routes/onboarding/OnboardingForwarding";
import OnboardingApp from "@/routes/onboarding/OnboardingApp";
import OnboardingImport from "@/routes/onboarding/OnboardingImport";
import Restore from "@/routes/restore/Restore";
import {
  STEP_APP,
  STEP_DEMO,
  STEP_FORWARDING,
  STEP_IMPORT,
} from "@/store/modules/onboarding";

const Import = () => import("@/routes/import/Import");
const ImportSource = () => import("@/routes/import/ImportSource");
const ImportUpload = () => import("@/routes/import/ImportUpload");
const ImportMapping = () => import("@/routes/import/ImportMapping");
const ImportReview = () => import("@/routes/import/ImportReview");
const ImportReviewEdit = () => import("@/routes/import/ImportReviewEdit");

Vue.use(VueRouter);

const beforeImportStepEnter = (to, from, next) => {
  if (!store.getters["accountsImporter/getFileName"]) {
    next({ name: "ImportSource" });
  }

  next();
};

const beforeEnterSettings = (to, from, next) => {
  store.dispatch("settings/savePrevRouteName", from.name || "All");
  store.dispatch("closeRightPanel");
  next();
};

const beforeEnterActivity = (to, from, next) => {
  store.dispatch("closeRightPanel");
  next();
};

const beforeEnter = (to, from, next) => {
  store.dispatch("closeRightPanel");
  next();
};

const beforeEnterCloakDetail = (to, from, next) => {
  const routeName = to.name || "";
  const currentRoute = routeName.toLowerCase();
  const encodedId = currentRoute === "cloak" ? to.params.id : to.params.cloakId;
  const cloakId = hash.decode(encodedId);

  if (cloakId) {
    // NOTE: if cloak is not in list, what do we do?
    store.dispatch("fetchPopulatedData", { id: cloakId }).then((data) => {
      store.dispatch("openCloakDetails", { cloak: data });
    });
  }
  next();
};

const routes = [
  {
    path: "/cards",
    name: "Cards",
    component: Cards,
    meta: { title: "Cards" },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: WalletPage,
    meta: { title: "Wallet", icon: "credit-card" },
    beforeEnter: beforeEnterActivity,

    children: [
      {
        path: "/wallet/card/:id",
        name: "Wallet",
        component: WalletPage,
        meta: { title: "Wallet", icon: "credit-card" },
        beforeEnter: beforeEnterActivity,
      },
    ],
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
    meta: { title: "Transactions" },
  },
  {
    path: "/support",
    name: "Support",
    component: All,
    meta: { title: "Recent Activity", support: true },
  },
  {
    path: "/onboarding",
    name: "registerOnboard",
    component: Register,
  },
  {
    path: "/auth/referral",
    name: "referralCode",
    component: ReferralCode,
  },
  {
    path: "/auth/referral-denied",
    name: "referralDenied",
    component: ReferralDenied,
  },
  {
    path: "/auth/register",
    name: "register",
    component: Register,
  },
  {
    path: "/auth/signup",
    name: "signup",
    redirect: "/auth/register",
    component: Register,
  },
  {
    path: "/auth/forgot-password",
    name: "forgot",
    component: Forgot,
  },
  {
    path: "/trash",
    name: "Trash",
    component: Trash,
    meta: { title: "Trash", icon: "delete-trash" },
    beforeEnter: beforeEnter,
  },
  {
    path: "/auth/login",
    name: "login",
    component: Login,
  },
  {
    path: "/auth/*",
    name: "auth",
    component: Login,
  },
  // {
  //   path: "/.well-known/assetlinks.json",
  //   name: "assetlinks",
  //   contentType: "application/json",
  //   meta: {
  //     httpEquiv: "Content-Type",
  //     content: "application/json",
  //   },
  // },
  {
    path: "/share/",
    name: "share",
    component: Shared,
    meta: { title: "View your shared identity " },
  },
  // route for fakebook demo
  // {
  //   path: "/trycloaked",
  //   name: "tryCloaked",
  //   component: TryCloaked,
  //   meta: { title: "Try Cloaked" },
  // },
  {
    path: "/favorites",
    name: "Favorites",
    component: Favorites,
    meta: { title: "Favorites", icon: "favorite-filled" },
    beforeEnter,
  },
  {
    path: "/number-reuse",
    name: "NumberReuse",
    component: NumberReuse,
    meta: { title: "Number Reuse" },
  },
  {
    path: "/favorites/cloak/:cloakId",
    name: "FavoritesWithCloak",
    component: Favorites,
    meta: { title: "Favorites" },
    beforeEnter: beforeEnterCloakDetail,
  },

  {
    path: "/ignored",
    name: "Ignored",
    component: Muted,
    meta: { title: "Ignored", icon: "user-block" },
    beforeEnter,
  },

  {
    path: "/ignored/cloak/:cloakId",
    name: "IgnoredWithCloak",
    component: Muted,
    meta: { title: "Ignored" },
    beforeEnter: beforeEnterCloakDetail,
  },

  {
    path: "/start",
    name: "StartCloak",
    props: {
      start: true,
    },
    component: All,
    meta: { title: "Start a Cloak" },
  },
  {
    path: "/cloak/:id",
    name: "Cloak",
    component: All,
    meta: { title: "Cloak Details" },
    beforeEnter: beforeEnterCloakDetail,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: { title: "Settings" },
    redirect: { name: "settings.account" },
    beforeEnter: beforeEnterSettings,
    children: [
      {
        path: "account",
        name: "settings.account",
        meta: { title: "Account" },
        component: SettingsAccount,
      },
      {
        path: "personal-information",
        name: "settings.personal-information",
        meta: { title: "Personal information" },
        component: SettingsPersonalInformation,
      },
      {
        path: "forwarding",
        name: "settings.forwarding",
        meta: { title: "Forwarding" },
        component: SettingsForwarding,
      },
      {
        path: "privacy",
        name: "settings.privacy",
        meta: { title: "Privacy" },
        component: SettingsPrivacy,
      },
      {
        path: "email-generation",
        name: "settings.email-generation",
        meta: { title: "Email generation" },
        component: SettingsEmailGeneration,
      },
      {
        path: "subscription",
        name: "settings.subscription",
        meta: { title: "Subscription" },
        component: SettingsSubscription,
      },
      {
        path: "recovery",
        name: "settings.recovery",
        meta: { title: "Recovery passphrase" },
        component: SettingsPassphrase,
      },
      {
        path: "cloaked-cards",
        name: "settings.cloaked-cards",
        meta: { title: "Cloaked cards" },
        component: SettingsCloakedCards,
      },
    ],
  },
  {
    path: "/activities/id/:id",
    name: "ActivityRouter",
    component: ActivityRouter,
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/activities/emails/:id",
    name: "ActivityEmail",
    component: Activity,
    meta: { title: "Inbox", message: true },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/activities/texts/:id",
    name: "ActivityText",
    component: Activity,
    meta: { title: "Inbox", message: true },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/activities/calls/:id",
    name: "ActivityCall",
    component: Activity,
    meta: { title: "Inbox", message: true },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/activities/emails/",
    name: "ActivityEmails",
    component: Activity,
    meta: { title: "Email inbox", type: "emails", message: true, icon: "mail" },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/activities/requests/",
    name: "ActivityRequests",
    component: Activity,
    meta: {
      title: "Requests",
      type: "requests",
      message: true,
      icon: "requests",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/activities/calls/",
    name: "ActivityCalls",
    component: Activity,
    meta: { title: "Inbox", type: "calls", message: true },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/activities/texts/",
    name: "ActivityTexts",
    component: Activity,
    meta: { title: "Inbox", type: "texts", message: true },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/activities/:activity_id/",
    name: "Activity",
    component: Activity,
    meta: { title: "Inbox", message: true },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/cloak/:id/activities",
    name: "CloakActivity",
    component: Activity,
    meta: { title: "Activities", cloak: true },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/activities",
    name: "Activities",
    component: Activity,
    meta: { title: "Activities", message: false },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/id/:id",
    name: "InboxRouter",
    component: ActivityRouter,
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/emails/:id",
    name: "Email",
    component: Activity,
    meta: { title: "Emails", message: true, icon: "mail-outline" },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/texts/:id",
    name: "Text",
    component: Activity,
    meta: { title: "Inbox", message: true },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/calls/:id",
    name: "Call",
    component: Activity,
    meta: { title: "Inbox", message: true },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/emails/",
    name: "Emails",
    component: Activity,
    meta: {
      title: "Emails",
      type: "emails",
      message: true,
      icon: "mail",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/requests/",
    name: "Requests",
    component: Activity,
    meta: {
      title: "Requests",
      type: "requests",
      message: true,
      icon: "requests",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/calls/",
    name: "Calls",
    component: Activity,
    meta: {
      title: "Calls",
      type: "calls",
      message: true,
      icon: "phone-filled",
    },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/texts/",
    name: "Texts",
    component: Activity,
    meta: { title: "Texts", type: "texts", message: true, icon: "chat" },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox/:activity_id/",
    name: "InboxActivity",
    component: Activity,
    meta: { title: "Inbox", message: true },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/cloak/:id/inbox",
    name: "CloakInbox",
    component: Activity,
    meta: { title: "Inbox", cloak: true },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/inbox",
    name: "Inbox",
    component: Activity,
    meta: { title: "All inboxes", icon: "inbox-filled", message: false },
    beforeEnter: beforeEnterActivity,
  },
  {
    path: "/category/:id/cloak/:cloakId",
    name: "CategoryWithCloak",
    component: Category,
    meta: { title: "Categories", icon: "stacked-blocks" },
    beforeEnter: beforeEnterCloakDetail,
  },
  {
    path: "/category/:id",
    name: "Category",
    component: Category,
    meta: { title: "Categories", icon: "stacked-blocks" },
    beforeEnter,
  },
  {
    path: "/recently-imported",
    name: "RecentlyImported",
    component: RecentlyImported,
    meta: { title: "Recently Imported" },
    beforeEnter,
  },
  {
    path: "/get-started",
    name: "Onboarding",
    component: Onboarding,
    meta: { title: "Get Started" },
    children: [
      {
        path: "demo",
        name: "OnboardingDemo",
        component: OnboardingDemo,
        meta: { title: "Get Started", step: STEP_DEMO },
      },
      {
        path: "forwarding",
        name: "OnboardingForwarding",
        component: OnboardingForwarding,
        meta: { title: "Get Started", step: STEP_FORWARDING },
      },
      {
        path: "app",
        name: "OnboardingApp",
        component: OnboardingApp,
        meta: { title: "Get Started", step: STEP_APP },
      },
      {
        path: "import",
        name: "OnboardingImport",
        component: OnboardingImport,
        meta: { title: "Get Started", step: STEP_IMPORT },
      },
    ],
  },
  {
    path: "/recently-imported/cloak/:id",
    name: "RecentlyImportedCloak",
    component: RecentlyImported,
    meta: { title: "Recently Imported" },
  },
  {
    path: "/import",
    name: "Import",
    component: Import,
    meta: { title: "Import" },
    redirect: "/import/source",
    children: [
      {
        path: "source",
        name: "ImportSource",
        component: ImportSource,
        meta: { step: 0, title: "Import" },
      },
      {
        path: "upload/:source",
        name: "ImportUpload",
        component: ImportUpload,
        meta: { step: 1, title: "Import" },
      },
      {
        path: "mapping",
        name: "ImportMapping",
        component: ImportMapping,
        meta: { step: 2, title: "Import" },
        beforeEnter: beforeImportStepEnter,
      },
      {
        path: "review",
        name: "ImportReview",
        component: ImportReview,
        meta: { step: 3, title: "Import" },
        beforeEnter: beforeImportStepEnter,
        children: [
          {
            path: "edit/:id",
            name: "ImportReviewIdentityEdit",
            component: ImportReviewEdit,
            meta: { step: 3, title: "Import" },
            beforeEnter: beforeImportStepEnter,
          },
        ],
      },
    ],
  },
  {
    path: "/autocloak",
    name: "AutoCloak",
    component: AutoChange,
    meta: { title: "AutoCloak" },
  },
  {
    path: "/restore",
    name: "Restore",
    component: Restore,
    meta: { title: "Restore Identifiers" },
  },
  {
    path: "/referrals",
    name: "Referrals",
    component: Referral,
    meta: { title: "Referrals" },
  },
  {
    path: "/cloaked-daily",
    name: "CloakedDaily",
    component: CloakedDaily,
    meta: { title: "Cloaked Daily" },
  },
  {
    path: "/all",
    name: "All",
    component: All,
    alias: "/*",
    meta: { title: "All identities" },
    beforeEnter: beforeEnterCloakDetail,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});
export const IMPORT_ROUTES = [
  "Import",
  "ImportSource",
  "ImportUpload",
  "ImportMapping",
  "ImportReview",
  "ImportReviewIdentityEdit",
];

export const ONBOARDING_ROUTES = [
  "Onboarding",
  "OnboardingDemo",
  "OnboardingForwarding",
  "OnboardingApp",
  "OnboardingImport",
];

router.beforeEach((to, from, next) => {
  const except = ["Cloak"];
  if (except.indexOf(to.name) === -1 && except.indexOf(from.name) === -1) {
    window.scrollTo(0, 0);
  }
  if (store.state.ui.open_dialog) {
    store.commit("unclipBody");
  }
  store.commit("closeCloak");

  next();
});
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? `${to.meta.title} - Cloaked` : "Cloaked";
  });
});
export default router;
