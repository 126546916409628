var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "progress-line",
      attrs: {
        width: _vm.width,
        height: "4",
        viewBox: `0 0 ${_vm.width} 4`,
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("line", {
        staticClass: "progress-line__background",
        attrs: {
          x1: "2",
          y1: "2",
          x2: _vm.width - 2,
          y2: "2",
          "stroke-width": "4",
          "stroke-linecap": "round",
        },
      }),
      _c("line", {
        staticClass: "progress-line__indicator",
        attrs: {
          x1: "2",
          y1: "2",
          x2: _vm.width - 2,
          y2: "2",
          "stroke-width": "4",
          "stroke-linecap": "round",
          pathLength: "1",
          "stroke-dasharray": _vm.indicatorDasharray,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }