<template>
  <UiMenu
    width="188px"
    :has-event-offset="true"
    :has-click-open="false"
    :has-right-click-open="true"
    :z-index="9"
    @open="$emit('select')"
  >
    <div
      class="preview-item-outer"
      :class="{ multiSelectEnabled, selected }"
      @click="$emit('select')"
      :id="`preview-item-outer-${thread.id}`"
    >
      <div v-if="multiSelectEnabled">
        <CheckDarkCircleIcon v-if="selected" />
        <div v-else class="preview-unselected-circle"></div>
      </div>
      <div
        :aria-id="`ActivityRelatedIdentityNickname.${identity?.nickname || ''}`"
        class="preview-item-wrapper"
        :class="{ unread: !thread.read, selected, multiSelectEnabled }"
      >
        <div
          v-if="isCloakSpecificView"
          class="preview-icon"
          :class="{ unread: !thread.read }"
        >
          <ContactIcon
            v-if="contactIsKnown"
            :initials="getInitials"
            :override="{ height: '40px', width: '40px' }"
            @mouseenter="toggleContactDetails(true)"
            @mouseleave="toggleContactDetails(false)"
          />
          <EmailIcon v-else-if="threadType === 'email'" />
          <TextIcon v-else-if="threadType === 'text'" />
          <CallIcon v-else-if="threadType === 'call'" />
          <div
            v-if="contactIsKnown && showContactDetails"
            class="contact-details"
            @mouseenter="toggleContactDetails(true)"
            @mouseleave="toggleContactDetails(false)"
          >
            <div class="contact-details-container">
              <div class="contact-detail-header">
                <ContactIcon v-if="contactIsKnown" :initials="getInitials" />
                <div>
                  <h1>{{ senderName }}</h1>
                  <h2>{{ senderContact }}</h2>
                </div>
              </div>
              <div class="contact-detail-actions">
                <button @click="openCompose">
                  <TextOutlineIcon /> Send Message
                </button>
                <button @click="openDetails"><EditIcon /> Edit Details</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="preview-icon-identity-wrapper">
          <IdentityIcon
            :identity="identity"
            :override="{ width: '32px', height: '32px' }"
          />
          <div class="preview-icon-mini" :class="{ unread: !thread.read }">
            <EmailIcon v-if="threadType === 'email'" />
            <TextIcon v-else-if="threadType === 'text'" />
            <CallIcon v-else-if="threadType === 'call'" />
          </div>
        </div>
        <div class="preview-thread-row">
          <div
            :aria-id="`Sender.${senderContact || ''}`"
            class="preview-sender-row"
            :class="{ unread: !thread.read }"
          >
            <div>
              <span ref="prevIdentifierEl">
                {{ senderIdentifier }}
              </span>
              <span v-if="senderLocation"> &bull; </span>
              <span v-if="senderLocation" class="location">
                {{ senderLocation }}
              </span>
            </div>
            <div class="time">
              {{ thread.timeDisplay }}
            </div>
          </div>

          <div
            :aria-id="`Subject.${subject || ''}`"
            class="preview-subject"
            :class="{ unread: !thread.read }"
            v-if="subject"
            ref="prevSubjectEl"
          >
            {{ subject }}
          </div>
          <div
            v-if="threadType === 'text' || threadType === 'call'"
            :aria-id="`Body.${body || ''}`"
            class="preview-body"
            ref="prevBodyEl"
          >
            {{ body }}
          </div>
          <div class="preview-missed" v-if="thread.missed">Missed Call</div>
          <div class="preview-pill-row">
            <div
              class="preview-pill"
              :class="{ unread: !thread.read }"
              v-if="unreadCount"
            >
              {{ `+${unreadCount} more` }}
            </div>
            <div
              class="preview-pill"
              :class="{ unread: !thread.read }"
              v-for="attachment in thread.attachments"
              :key="`activity-attachments-${attachment.id}`"
            >
              {{ attachment.file_name }}
            </div>
            <div
              class="preview-pill"
              :class="{ unread: !thread.read }"
              v-for="(recording, idx) in thread.recordings"
              :key="`activity-recordings-${recording.id}`"
              :id="`activity-recordings-${recording.id}`"
            >
              <VoicePlayIcon :recording="recording" :idx="idx" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #content>
      <UiMenuButton
        :title="`Select messages`"
        @click="$emit('setMultiSelect', true)"
      >
        <template v-slot:icon>
          <SelectMessagesIcon />
        </template>
      </UiMenuButton>
      <UiMenuButton :title="`Select all`" @click="$emit('toggleSelectAll')">
        <template v-slot:icon>
          <SelectAllIcon />
        </template>
      </UiMenuButton>
      <UiMenuButton
        :title="`Mark as ${readOrUnread}`"
        @click="$emit('markAsRead', readOrUnread)"
      >
        <template v-slot:icon>
          <ActivityMarkAsReadIcon v-if="readOrUnread === 'read'" />
          <ActivityMarkAsUnReadIcon v-else />
        </template>
      </UiMenuButton>
      <!-- <UiMenuButton
          :title="`Reply`"
          @click="reply"
        >
          <template v-slot:icon>
            <ActivityReplyIcon />
          </template>
        </UiMenuButton> -->

      <UiMenuSeparator />

      <div class="alert-text">
        <UiMenuButton title="Delete" danger @click="$emit('showDeleteModal')">
          <template v-slot:icon>
            <DeleteTrashIcon />
          </template>
        </UiMenuButton>
      </div>
    </template>
  </UiMenu>
</template>
<script>
import {
  getAccountInitials,
  getAliasFromEmail,
  getContactName,
  phone_format,
} from "@/scripts/format";
import { constants } from "@/scripts";
import EmailIcon from "@/assets/icons/email-activity-unread.svg";
import TextIcon from "@/assets/icons/text-activity.svg";
import CallIcon from "@/assets/icons/phone-activity.svg";
import TextOutlineIcon from "@/assets/icons/chat-outline.svg";
import EditIcon from "@/assets/icons/edit-pencil.svg";
import CheckDarkCircleIcon from "@/assets/icons/check-circle-dark.svg";
import {
  UiMenu,
  UiMenuButton,
  UiMenuSeparator,
  IdentityIcon,
} from "@/components";
import {
  ActivityMarkAsReadIcon,
  DeleteTrashIcon,
  SelectAllIcon,
  SelectMessagesIcon,
  ActivityMarkAsUnReadIcon,
  // ActivityReplyIcon
} from "@/assets/icons";
import colors from "@/assets/scss/recursive/_colors.scss";
import ContactIcon from "@/components/ui/ContactIcon";
import api from "@/api/api";
import { ContactsViewEdit } from "@/components/modals";
import { mapActions } from "vuex";
import VoicePlayIcon from "@/components/activity/VoicePlayIcon";
export default {
  name: "ActivityPreviewItem",
  props: [
    "thread",
    "threadType",
    "selected",
    "multiSelectEnabled",
    "isCloakSpecificView",
    "searchText",
    "allSelectedAreRead",
  ],
  created() {
    this.PHONE = constants.CONTACT_TYPE.PHONE;
    this.EMAIL = constants.CONTACT_TYPE.EMAIL;
  },
  data() {
    return {
      // should be part of thread/activity data, pending backend update
      unreadCount: null,
      showContactDetails: false,
      detailTimeout: null,
      activeVoicemail: null,
      white: colors.defaultWhite,
      primaryWhiteLt: colors.primaryWhiteLt,
    };
  },
  components: {
    VoicePlayIcon,
    ContactIcon,
    EmailIcon,
    TextIcon,
    CallIcon,
    TextOutlineIcon,
    EditIcon,
    CheckDarkCircleIcon,
    IdentityIcon,
    UiMenu,
    UiMenuButton,
    UiMenuSeparator,
    ActivityMarkAsReadIcon,
    DeleteTrashIcon,
    SelectAllIcon,
    SelectMessagesIcon,
    ActivityMarkAsUnReadIcon,
    // MinusRound,
    // EmptyRound,
  },
  mounted() {
    if (this.searchText && this.searchText.length) {
      this.$nextTick(() => {
        if (this.$refs.prevBodyEl) {
          this.$refs.prevBodyEl.innerHTML =
            this.$refs.prevBodyEl.innerHTML.replaceAll(
              this.searchText,
              `<span style="background-color: #ffceba; color: $color-primary-100; padding: 2px;">${this.searchText}</span>`
            );
        }
        if (this.$refs.prevSubjectEl) {
          this.$refs.prevSubjectEl.innerHTML =
            this.$refs.prevSubjectEl.innerHTML.replaceAll(
              this.searchText,
              `<span style="background-color: #FF8855">${this.searchText}</span>`
            );
        }
        if (this.$refs.prevIdentifierEl) {
          this.$refs.prevIdentifierEl.innerHTML =
            this.$refs.prevIdentifierEl.innerHTML.replaceAll(
              this.searchText,
              `<span style="background-color: #FF8855">${this.searchText}</span>`
            );
        }
      });
    }
  },
  methods: {
    ...mapActions(["openModal"]),
    openCompose(event) {
      event.stopPropagation();
      if (this.thread.identity) {
        api()
          .get(this.thread.identity)
          .then(({ data }) => {
            this.$store.commit("compose", data);
          });
      }
      this.showContactDetails = false;
    },
    openDetails(event) {
      event.stopPropagation();
      this.openModal({
        customTemplate: {
          template: ContactsViewEdit,
          props: {
            setPrimary: true,
            contactId: this.thread.sender_contact?.id,
            contactType: this.type === this.EMAIL ? this.EMAIL : this.PHONE,
          },
        },
      });
      this.showContactDetails = false;
    },
    toggleContactDetails(value) {
      if (value) {
        if (this.detailTimeout) {
          clearTimeout(this.detailTimeout);
        }
        this.showContactDetails = true;
      } else {
        this.detailTimeout = setTimeout(() => {
          this.showContactDetails = false;
        }, 50);
      }
    },
    playVoicemail(recording) {
      this.$store.dispatch("media/playAudio", recording);
    },
  },
  computed: {
    getInitials() {
      const { firstName, lastName } = getContactName(
        this.thread.sender_contact
      );
      if (firstName || lastName) {
        return getAccountInitials(firstName, lastName);
      } else if (this.thread.sender_name) {
        const nameArray = this.thread.sender_name.split(" ");
        return nameArray[1]
          ? getAccountInitials(nameArray[0], nameArray[1])
          : getAccountInitials(nameArray[0]);
      }
      return "";
    },
    type() {
      if (this.thread.call) {
        return this.PHONE;
      }
      if (this.thread.message) {
        return this.PHONE;
      }
      if (this.thread.email || this.thread?.sender_email) {
        return this.EMAIL;
      }
      return "unknown";
    },
    contactIsKnown() {
      if (this.thread.sender_contact) {
        return this.thread.sender_contact.status.includes("approve");
      }
      return false;
    },
    playing() {
      return this.$store.state.media.playing;
    },
    identity() {
      if (this.thread) {
        if (typeof this.thread.identity === "string") {
          const [, id_st] = this.thread.identity.match(/([0-9]+)\/$/);
          const id = parseInt(id_st, 10);
          const find = this.$store.state.localdb.db_cloaks.find(
            (c) => c.id === id
          );
          return find || {};
        }
        return this.thread.identity;
      }
      return {};
    },
    readOrUnread() {
      if (this.multiSelectEnabled) {
        return this.allSelectedAreRead ? "unread" : "read";
      }
      return this.selected && this.thread.read ? "unread" : "read";
    },
    senderLocation() {
      return (
        this.thread &&
        this.thread.sender_contact &&
        this.thread.sender_contact.location
      );
    },
    senderIdentifier() {
      let identifierText = "";
      if (this.thread?.inbound) {
        const { firstName, lastName } = getContactName(
          this.thread.sender_contact
        );
        identifierText =
          ((firstName?.length || lastName?.length) &&
            `${firstName} ${lastName}`.trim()) ||
          this.thread?.sender_name ||
          phone_format(this.thread?.sender_contact?.cloak_contact_phone) ||
          this.thread?.original_name ||
          getAliasFromEmail(this.thread.sender_email);
      } else {
        // If outbound, via nickname if available
        identifierText = `via ${this.getUnknownCloakName}`;
      }

      return identifierText;
    },
    getUnknownCloakName() {
      if (this.identity && this.identity.nickname) {
        return this.identity.nickname;
      }
      return "unnamed identity";
    },
    subject() {
      let subjectText = (this.thread && this.thread.subject) || "";
      return subjectText;
    },
    body() {
      let bodyText =
        (this.thread && (this.thread.body || this.thread.body_preview)) || "";

      return bodyText
        .split("\n")
        .filter((s) => s)
        .join(" ");
    },
    senderName() {
      let senderName = "";
      if (this.thread && this.thread.sender_contact) {
        if (
          this.thread.sender_contact?.first_name &&
          this.thread.sender_contact?.first_name.length
        ) {
          senderName += this.thread.sender_contact.first_name;
        }
        if (
          this.thread.sender_contact.last_name &&
          this.thread.sender_contact.last_name.length
        ) {
          senderName += ` ${this.thread.sender_contact.last_name}`;
        }
      }
      return senderName.length > 0 ? senderName : "unkonwn contact";
    },
    senderContact() {
      if (this.inbound) {
        if (this.thread?.sender_contact?.cloaked_contact_email) {
          return this.thread.sender_contact.cloaked_contact_email;
        } else if (this.thread?.sender_contact?.cloak_contact_phone) {
          return this.thread.sender_contact.cloak_contact_phone;
        } else {
          return "unknownContact";
        }
      } else {
        if (this.thread?.sender_email) {
          return this.thread.sender_email;
        } else if (this.thread?.sender) {
          return this.thread?.sender;
        } else {
          return "unknownSender";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/recursive/_mixins.scss";

.contact-details {
  position: relative;
  .contact-details-container {
    position: absolute;
    background: $color-primary-0;
    box-shadow: -3.63315px -3.63315px 10.8995px rgba(0, 0, 0, 0.04),
      5.44973px 5.44973px 20.9px rgba(1, 2, 24, 0.12);
    border-radius: 12px;
    padding: 17px 16px;
    gap: 12px;
    width: 296px;

    .contact-detail-header {
      display: flex;
      flex-direction: row;
      gap: 12px;
      padding-bottom: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      h1 {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        color: $color-primary-100;
        margin: 0;
      }
      h2 {
        margin: 0;
        line-height: 18px;
        color: $color-primary-50;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
      }
    }

    .contact-detail-actions {
      display: flex;
      flex-direction: row;
      gap: 12px;
      border-top: 1px solid #8f9eab;
      button {
        display: flex;
        gap: 4px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        width: 100%;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        padding: 17px 0 0 0;
        cursor: pointer;
        color: $color-primary-100;
      }
    }
  }
}

.preview-item-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-top: 1px solid transparent;
  cursor: pointer;
  width: 100%;
  margin: 0;

  .preview-item-wrapper {
    &.multiSelectEnabled {
      margin-left: 8px;
      margin-top: 1px;
    }
  }

  &:not(:first-of-type) {
    border-top: 1px solid $color-primary-10;
  }

  &:hover + .preview-item-outer {
    border-top: 1px solid transparent;
  }
  &:hover {
    border-top: 1px solid transparent;

    .preview-item-wrapper {
      border-radius: 12px;
      animation: fadeBackground 0.5s;
      animation-fill-mode: forwards;
      &.selected {
        animation: none;
      }
      .preview-thread-row .preview-pill-row .preview-pill {
        background-color: $color-primary-100;
        color: $color-primary-50;
        > svg {
          > path {
            fill: $color-primary-50;
          }
        }
        &.unread {
          font-weight: 600;
          color: $color-primary-50;
          > svg {
            > path {
              fill: $color-primary-50;
            }
          }
        }
      }
    }
  }
  &.selected {
    border-top: 1px solid transparent;
    .preview-item-wrapper {
      border-radius: 12px;
      .preview-thread-row .preview-pill-row .preview-pill {
        background-color: $color-primary-0;
        color: $color-primary-50;
        > svg {
          > path {
            fill: $color-primary-50;
          }
        }
        &.unread {
          font-weight: 600;
          color: $color-primary-100;
          > svg {
            > path {
              fill: $color-primary-100;
            }
          }
        }
      }
    }
  }
  &.selected + .preview-item-outer {
    border-top: 1px solid transparent;
  }

  &.multiSelectEnabled {
    margin: 0;

    svg {
      display: inline-block;
      height: auto;
    }
  }
  .preview-unselected-circle {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 2px solid $color-primary-50;
  }
  .preview-item-wrapper {
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;

    &.selected {
      background-color: $color-primary-10;
      .preview-thread-row .preview-pill-row .preview-pill {
        background-color: $color-primary-0;
        color: $color-primary-100;
        > svg {
          > path {
            fill: $color-primary-100;
          }
        }
      }
    }

    .preview-icon {
      min-height: 32px;
      min-width: 32px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      display: flex;
      background-color: $color-primary-100; // default if no secondary color for some reason
      color: $color-primary-0;
      svg {
        width: 16px;
        height: auto;
      }
    }

    .preview-icon-identity-wrapper {
      position: relative;
      .preview-icon-mini {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background-color: $color-primary-20;
        position: absolute;
        z-index: 2;
        color: $color-primary-70;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -5px;
        bottom: -8px;
        svg {
          width: 12px;
          height: auto;
        }
        &.unread {
          color: $color-primary-100;
        }
      }
    }

    .preview-thread-row {
      margin-left: 12px;
      width: calc(100% - 48px);
      .preview-sender-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: $color-primary-70;
        font-weight: 400;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;

        .time {
          font-size: 10px;
          line-height: 18px;
          font-weight: 400;
        }
        &.unread {
          color: $color-primary-100;
          font-weight: 600;
          line-height: 15px;
          .time {
            font-weight: 600;
          }
        }
      }
      .preview-subject {
        color: $color-primary-70;
        font-weight: 400;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        @include line-clamp(1);
        &.unread {
          font-weight: 600;
          color: $color-primary-100;
        }
      }
      .preview-body {
        color: $color-primary-70;
        font-weight: 400;
        font-size: 12px;
        @include line-clamp(1);

        .highlight {
          background-color: #ffff00;
          color: $color-primary-100;
        }
      }

      .preview-missed {
        color: $color-primary-50;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: -0.2px;
      }
      .preview-pill-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .preview-pill {
          color: $color-primary-50;
          font-weight: 500;
          font-size: 12px;
          padding: 4px 6px;
          margin-top: 10px;
          background-color: $color-primary-5;
          border-radius: 999px;
          display: flex;
          align-items: center;
          &:not(:first-of-type) {
            margin-left: 4px;
          }
          > svg {
            &.playing {
              rect {
                fill: $color-primary-0;
              }
            }
            margin-right: 6px;
            > path {
              fill: $color-primary-50;
            }
          }
        }
      }
    }
  }
}

@keyframes fadeBackground {
  from {
    background-color: $color-surface;
  }
  to {
    background-color: $color-primary-5;
  }
}

.alert-text {
  color: $color-alert;
}
</style>
