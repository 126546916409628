import { render, staticRenderFns } from "./VoicePlayIcon.vue?vue&type=template&id=13a7588f&scoped=true"
import script from "./VoicePlayIcon.vue?vue&type=script&lang=js"
export * from "./VoicePlayIcon.vue?vue&type=script&lang=js"
import style0 from "./VoicePlayIcon.vue?vue&type=style&index=0&id=13a7588f&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13a7588f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/__w/dashboard/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13a7588f')) {
      api.createRecord('13a7588f', component.options)
    } else {
      api.reload('13a7588f', component.options)
    }
    module.hot.accept("./VoicePlayIcon.vue?vue&type=template&id=13a7588f&scoped=true", function () {
      api.rerender('13a7588f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/activity/VoicePlayIcon.vue"
export default component.exports