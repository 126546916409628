import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import moment from "moment";
import router from "@/routes/router";

import rightPanel from "./modules/rightPanel.js";
import subscription from "./modules/subscription.js";
import modal from "./modules/modal.js";
import cards from "./modules/cards.js";
import media from "./modules/media.js";
import reuse from "./modules/reuse.js";
import colorScheme from "./modules/colorScheme.js";
import analytics from "./modules/analytics.js";
import accountsImporter from "./modules/accounts-importer/index.js";
import recentlyImported from "./modules/recentlyImported.js";
import authentication from "./modules/authentication.js";
import browser from "./modules/browser.js";
import autoChange from "./modules/autoChange.js";
import onboarding, {
  STEP_APP,
  STEP_DEMO,
  STEP_FORWARDING,
  STEP_IMPORT,
} from "./modules/onboarding.js";
import localdb, { cleanDb } from "./modules/localdb.js";
import settings from "./modules/settings.js";
import {
  STORAGE_KEY_ONBOARDING_DISMISSED,
  STORAGE_KEY_ONBOARDING_COMPLETED,
} from "./modules/onboarding.js";
import UserService from "@/api/actions/user-service";
import { ANALYTICS_ENABLED } from "@/scripts/featureFlags";

Vue.use(Vuex);

const defaultState = () => {
  return {
    verifiedPhones: [],
    identities: [],
    packageVersion: process.env.PACKAGE_VERSION || "0",
    bodyOverflowHidden: false,
    searchToggle: false,
    cloakToggle: false,
    deleteCloakToggle: false,
    identity: null,
    support: false,
    isMultiSelect: false,
    defaultForwardingEmail: null,
    defaultForwardingPhone: null,
    hasVisibleBanner: false,
    locked: false,
    support_cloak: null,
    compose_message: {
      identity: null,
      payload: null,
    },
    encryption: {
      active: false,
      enabled: false,
      feature_flag_exists: false,
      secret_key: "",
    },
    account: {
      access_token: "",
      refresh_token: "",
      scope: "",
      logged_in: "",
      expires_at: "",
    },
    user: {
      created_at: null,
      id: null,
      username: null,
      url: null,
      emoji: null,
    },
    flags: {
      onboarding: null,
    },
    collection: {
      autofill: null,
      email: null,
      phone: null,
      password: null,
    },
    net: {
      latestItem: null,
    },
    autofill: {},
    activity_type: null,
    home: [],
    activities: [],
    activity_summary: {},
    ui: {
      skip_onboarding: false,
      open_dialog: false,
      showRequests: true,
      preference: {
        open: false,
        selected: "account",
        right: null,
        lastRoute: null,
        step: null,
      },
      activities: {
        type: null,
        cloak_id: null,
      },
      user: {
        emoji: null,
      },
      modals: {
        confirm: {
          loading: false,
          opened: false,
          details: null,
          callback: null,
        },
        undo: {
          text: "",
          isVisible: false,
          callback: null,
        },
      },
      dashboard: {
        sort: "-created_at",
        filter: [],
      },
      hint: {
        count: 0,
        registered: [],
        active: 0,
      },
    },
    analytics: ANALYTICS_ENABLED ? true : false,
    profile: {
      email_type: "random",
    },
    categories: {
      permanent: [{ name: "Favorites" }, { name: "Ignored" }],
      custom: [],
    },
  };
};
export default new Vuex.Store({
  state: defaultState(),
  mutations: {
    compose(state, identity) {
      state.compose_message.identity = identity;
      state.compose_message.payload = null;
    },
    setSupport(state, status) {
      state.support = status;
    },
    replyTo(state, { identity, activity_id, payload }) {
      state.compose_message.identity = identity;
      state.compose_message.activity_id = activity_id;
      state.compose_message.payload = payload;
    },
    setIsMultiSelect(state, status) {
      state.isMultiSelect = status;
    },
    registerSupport(state, support) {
      state.support_cloak = support;
    },
    registerHint(state, hint) {
      const count = state.ui.hint.count + 1;
      state.ui.hint.registered = [...state.ui.hint.registered, hint];
      state.ui.hint.active = state.ui.hint.registered.length - 1;
      state.ui.hint.count = count;
      hint.index = count;
      this.commit("updateHint");
    },
    unregisterHint(state, hint) {
      state.ui.hint.registered = [...state.ui.hint.registered].filter(
        (o) => o.index !== hint.index
      );
      state.ui.hint.active = state.ui.hint.active - 1;
      this.commit("updateHint");
    },
    updateHint(state) {
      state.ui.hint.registered
        .filter((handle) => !!handle)
        .map((hint, index) => {
          if (index === state.ui.hint.active) {
            hint.visible = true;
          } else {
            hint.visible = false;
          }
        });
    },
    nextHint(state) {
      let next = state.ui.hint.active + 1;
      if (state.ui.hint.registered.length <= next) {
        next = state.ui.hint.active - 1;
      }
      if (next < 0) {
        next = 0;
      }
      state.ui.hint.active = next;
      this.commit("updateHint");
    },

    addLastRoute(state, path) {
      state.ui.preference.lastRoute = path;
    },

    resetPreferences(state) {
      Vue.set(state.ui.preference, "selected", "account");
      Vue.set(state.ui.preference, "right", null);
      Vue.set(state.ui.preference, "step", null);
    },

    openPreference(state, { selected, right = null, step = null }) {
      Vue.set(state.ui.preference, "selected", selected);
      Vue.set(state.ui.preference, "right", right);
      Vue.set(state.ui.preference, "step", step);
    },

    setPreferenceRightPanel(state, { right = null }) {
      Vue.set(state.ui.preference, "right", right);
    },

    setPreferenceStep(state, { step = null }) {
      Vue.set(state.ui.preference, "step", step);
    },

    closePreference(state) {
      Vue.set(state.ui, "open_dialog", false);
      Vue.set(state.ui.preference, "open", false);
      Vue.set(state.ui.preference, "selected", "account");
      Vue.set(state.ui.preference, "right", null);
      Vue.set(state.ui.preference, "step", null);

      if (state.ui.preference.lastRoute) {
        window.history.pushState({}, null, state.ui.preference.lastRoute);
      } else {
        window.history.pushState({}, null, "/");
      }
      window.document.title = "Cloaked";
      Vue.set(state.ui.preference, "lastRoute", null);
    },
    clipBody(state) {
      state.ui.open_dialog = true;
    },
    unclipBody(state) {
      state.ui.open_dialog = false;
    },
    insertUser(state, user) {
      state.user = user;
    },
    insertAutofill(state, autofill) {
      state.autofill = autofill;
    },
    insertAccessToken(state, access_token) {
      state.account.access_token = access_token;
    },
    insertRefreshToken(state, refresh_token) {
      state.account.refresh_token = refresh_token;
    },
    insertExpiresAt(state, expires_at) {
      state.account.expires_at = moment()
        .add(expires_at, "seconds")
        .toISOString();
    },
    insertScope(state, scope) {
      state.account.scope = scope;
    },
    insertLoggedIn(state, logged_in) {
      state.account.logged_in = logged_in;
    },
    insertUserId(state, id) {
      state.user.id = id;
    },
    insertUserDate(state, date) {
      state.user.created_at = date;
    },
    encryptionStatus(state, status) {
      state.encryption.active = status;
    },
    encryptionFeatureFlag(state, status) {
      state.encryption.feature_flag_exists = status;
    },
    insertUserUrl(state, url) {
      state.user.url = url;
    },
    insertCollectionAutofill(state, autofill_url) {
      state.collection.autofill = autofill_url;
    },
    insertCollectionPhone(state, phone_url) {
      state.collection.phone = phone_url;
    },
    insertCollectionEmail(state, email_url) {
      state.collection.email = email_url;
    },
    insertCollectionPassword(state, password_url) {
      state.collection.password = password_url;
    },
    insertCollectionCard(state, cardCollection) {
      state.collection.card = cardCollection;
    },
    setDashboardSort(state, sort) {
      state.ui.activities.type = null;
      state.ui.dashboard.filter = null;
      state.ui.activities.cloak_id = null;
      state.ui.dashboard.sort = sort;
    },
    setDashboardFilter(state, filter) {
      state.ui.activities.type = null;
      state.ui.dashboard.sort = null;
      state.ui.activities.cloak_id = null;
      state.ui.dashboard.filter = filter;
    },
    setActivity(state, type) {
      state.ui.dashboard.sort = null;
      state.ui.dashboard.filter = null;
      state.ui.activities.cloak_id = null;
      state.ui.activities.type = type;
    },
    closeActivityCloak(state) {
      state.ui.activities.cloak_id = null;
    },
    setActivityCloak(state, id) {
      state.ui.activities.cloak_id = id;
    },
    logout(state) {
      window.localStorage.clear();
      cleanDb();
      Object.assign(state, defaultState());
      window.location.reload();
    },
    setActivitySummary(state, summary) {
      state.activity_summary = summary;
    },
    openConfirm(state) {
      state.ui.modals.confirm.loading = true;
      state.ui.modals.confirm.opened = true;
    },
    toggleConfirm(state, { details, callback }) {
      state.ui.modals.confirm.loading = false;
      state.ui.modals.confirm.opened = true;
      state.ui.modals.confirm.details = details;
      state.ui.modals.confirm.callback = callback;
    },
    closeConfirm(state) {
      state.ui.modals.confirm.opened = false;
      state.ui.modals.confirm.details = null;
      state.ui.modals.confirm.callback = null;
    },
    storeHome(state, home) {
      state.home = home;
    },
    storeActivities(state, { activities, type }) {
      state.activities = activities;
      state.activity_type = type;
    },
    hideRecommend(state) {
      state.ui.hide_recommend = true;
    },
    emoji(state, emoji) {
      state.ui.user.emoji = emoji;
    },
    setOnboard(state, { name, status }) {
      state.onboarding[name] = status;
    },
    onboardFlags(state, { flags }) {
      state.flags.onboarding = flags || {};
    },
    postGetListen(state, handler) {
      state.net.latestItem = handler;
    },
    netComplete(state) {
      if (state.net.latestItem) {
        state.net.latestItem();
      }
      state.net.latestItem = null;
    },
    skipOnboarding(state) {
      state.ui.skip_onboarding = true;
    },
    setRequestStatus(state, { status }) {
      state.ui.showRequests = status;
    },
    toggleCloak: (state) => {
      state.cloakToggle = !state.cloakToggle;
    },
    closeCloak: (state) => {
      state.cloakToggle = false;
      state.identity = [];
      state.bodyOverflowHidden = false;
    },
    closeOverflow: (state) => {
      state.bodyOverflowHidden = false;
    },
    toggleDeleteCloak: (state) => {
      state.deleteCloakToggle = !state.deleteCloakToggle;
    },
    setBodyOverflow: (state, override) => {
      state.bodyOverflowHidden = override;
    },
    setSecretKey(state, secret_key) {
      state.encryption.secret_key = secret_key;
    },
    setEncryptionStatus(state, status) {
      state.encryption.enabled = status;
    },
    setAnalytics(state, value) {
      state.analytics = ANALYTICS_ENABLED ? value : false;
    },
    setEmailTypeSetting(state, value) {
      state.profile.email_type = value;
    },
    setSearch: (state, value) => {
      state.searchToggle = value;
    },
    setCategories: (state, categories) => {
      state.categories.custom = categories;
    },
    deleteCategory: (state, categoryId) => {
      const filteredCategories = state.categories.custom.filter(
        (cat) => cat.id != categoryId
      );
      state.categories.custom = filteredCategories;
    },
    addNewCategory: (state, category) => {
      state.categories.custom = [category, ...state.categories.custom];
    },
    appendCategoryList: (state, categoryList) => {
      state.categories.custom = [...state.categories.custom, ...categoryList];
    },
    updateCategory: (state, category) => {
      const matchingIdx = state.categories.custom.findIndex(
        (cat) => cat.id === category.id
      );
      const stateCategoriesCopy = [...state.categories.custom];
      stateCategoriesCopy.splice(matchingIdx, 1, category);
      state.categories.custom = stateCategoriesCopy;
    },
    showUndoModal: (state, modalData) => {
      state.ui.modals.undo = {
        isVisible: true,
        ...modalData,
      };
    },
    hideUndoModal: (state) => {
      state.ui.modals.undo = {
        text: "",
        isVisible: false,
        callback: null,
        dismiss: null,
      };
    },
    prependIdentities: (state, data) => {
      const { identities } = data;
      state.identities = [...identities, ...state.identities];
    },
    updateIdentities: (state, data) => {
      const { identities, override } = data;
      if (override) {
        state.identities = identities;
      } else {
        const list = [...state.identities].filter(
          (f) => identities.findIndex((a) => a.id === f.id) === -1
        );
        state.identities = [...list, ...identities];
      }
    },
    setVerifiedPhones: (state, phones) => {
      state.verifiedPhones = phones;
    },
    addVerifiedPhone: (state, phone) => {
      state.verifiedPhones = [...state.verifiedPhones, phone];
    },
    setDefaultForwardingPhone: (state, phone) => {
      state.defaultForwardingPhone = phone;
    },
    setDefaultForwardingEmail: (state, email) => {
      state.defaultForwardingEmail = email;
    },
    setVisibleBanner: (state, visible) => {
      state.hasVisibleBanner = visible;
    },
  },
  getters: {
    identities: (state) => {
      return state.identities;
    },
    appVersion: (state) => {
      return state.packageVersion;
    },
    auth_token(state) {
      if (state.authentication.auth && state.authentication.auth.access_token) {
        return state.authentication.auth.access_token;
      }
      return state.account.access_token;
    },
    refresh_token(state) {
      if (
        state.authentication.auth &&
        state.authentication.auth.refresh_token
      ) {
        return state.authentication.auth.refresh_token;
      }
      return state.account.refresh_token;
    },
    secret_key: (state) => {
      return state.encryption.secret_key;
    },
    getFlag: (state) => (name) => {
      return state.flags.onboarding && state.flags.onboarding[name];
    },

    getDefaultForwardingPhone: (state) => {
      return state.defaultForwardingPhone;
    },
    getDefaultForwardingEmail: (state) => {
      return state.defaultForwardingEmail;
    },

    getSearchToggle: (state) => {
      return state.searchToggle;
    },

    getCloakToggle: (state) => {
      return state.cloakToggle;
    },

    getDeleteCloakToggle: (state) => {
      return state.deleteCloakToggle;
    },

    bodyOverflowHidden: (state) => {
      return state.bodyOverflowHidden;
    },

    getIdentity: (state) => {
      return state.identity;
    },
    getAnalytics: (state) => {
      return ANALYTICS_ENABLED ? state.analytics : false;
    },
    getEmailTypeSetting: (state) => {
      return state.profile.email_type;
    },
    getCategories: (state) => {
      const customCategories = state.categories.custom;
      return [...customCategories];
    },
    getCustomCategories: (state) => {
      return state.categories.custom;
    },
    getVerifiedPhones: (state) => {
      return state.verifiedPhones;
    },
    hasVisibleBanner: (state) => {
      return state.hasVisibleBanner;
    },
    getCloak: (state) => {
      if (state.rightPanel.cloak && state.rightPanel.cloak.id) {
        return state?.localdb?.db_cloaks?.find(
          (c) => c.id === state.rightPanel.cloak.id
        );
      }
      return null;
    },
    dbLoaded: (state) => {
      const hasCloaks = state.localdb.cloakCount > 0;
      const someCloaksHaveBeenSaved =
        hasCloaks && state.localdb.db_cloaks.length !== 0;
      return (
        state.localdb.dbLoaded && (hasCloaks ? someCloaksHaveBeenSaved : true)
      );
    },
    isNewUser: (state) => {
      const NEW_USER_THRESHOLD_IN_DAYS = 14;
      const MILLISECONDS_IN_ONE_DAY = 24 * 60 * 60 * 1000;

      return (
        new Date().getTime() -
          new Date(state.authentication?.user?.created_at).getTime() <
        NEW_USER_THRESHOLD_IN_DAYS * MILLISECONDS_IN_ONE_DAY
      );
    },
    isUserMeantToSeeRecoveryPhraseReminder: (state) => {
      const RECOVERY_KEY_REMINDER_THRESHOLD_IN_DAYS = 2;
      const MILLISECONDS_IN_ONE_DAY = 24 * 60 * 60 * 1000;

      return (
        new Date().getTime() -
          new Date(state.authentication?.user?.created_at).getTime() >=
        RECOVERY_KEY_REMINDER_THRESHOLD_IN_DAYS * MILLISECONDS_IN_ONE_DAY
      );
    },
    isV2User: (state) => {
      return state.authentication.user.encryption_status === 3;
    },
  },
  actions: {
    setEmailTypeSetting({ commit }, value) {
      commit("setEmailTypeSetting", value);
    },
    setSupport({ commit }, support) {
      commit("setSupport", support);
    },
    logout({ commit, dispatch }) {
      dispatch("authentication/logout");
      commit("logout");
    },
    init({ dispatch, rootState }) {
      dispatch("accountsImporter/watchImportStatus");
      dispatch("autoChange/init");
      const userCreatedAfterOnboardingReleased = moment("2023-03-14").isBefore(
        rootState.authentication.user.created_at
      );

      if (userCreatedAfterOnboardingReleased) {
        dispatch("onboardingInit");
      }
    },
    async onboardingInit({ dispatch, state }) {
      if (
        !state.flags.onboarding ||
        !Object.keys(state.flags.onboarding).length
      ) {
        return UserService.getFlags().then(() => {
          return dispatch("onboardingInitInner");
        });
      } else {
        return dispatch("onboardingInitInner");
      }
    },
    onboardingInitInner({ dispatch, commit, getters }) {
      const availableSteps = [
        STEP_DEMO,
        STEP_FORWARDING,
        STEP_APP,
        STEP_IMPORT,
      ];
      commit("onboarding/setAvailableSteps", availableSteps);
      const completedSteps = [];
      getters["onboarding/availableSteps"].forEach((step) => {
        getters.getFlag(`${step}_COMPLETED`) && completedSteps.push(step);
      });
      commit("onboarding/setCompletedSteps", completedSteps);

      commit(
        "onboarding/setIsAvailable",
        !getters.getFlag(STORAGE_KEY_ONBOARDING_DISMISSED) &&
          !getters.getFlag(STORAGE_KEY_ONBOARDING_COMPLETED)
      );

      if (
        router.currentRoute.name === "Onboarding" &&
        router.currentRoute.query.completed &&
        getters["onboarding/availableSteps"].includes(
          router.currentRoute.query.completed
        )
      ) {
        dispatch(
          "onboarding/completeStep",
          router.currentRoute.query.completed
        );

        if (
          !router.currentRoute?.path?.includes("get-started") &&
          !router.currentRoute?.path?.includes("auth")
        ) {
          return router.push({ name: "Onboarding" });
        }
      }
    },
    setSearch({ commit }, showSearch) {
      commit("setSearch", showSearch);
      commit("setBodyOverflow", showSearch);
    },
    deleteCloakFromLocalDB({ commit, dispatch }, idsForDelete) {
      let cloakIds = idsForDelete;
      if (typeof cloakId === "string" || typeof cloakId === "number") {
        cloakIds = [idsForDelete];
      }
      dispatch("removeCloaks", cloakIds);
      commit("setCloseRightPanel");
    },
    setVerifiedPhones({ commit }, phones) {
      commit("setVerifiedPhones", phones);
    },
    addVerifiedPhone({ commit }, phone) {
      commit("addVerifiedPhone", phone);
    },
    setDefaultForwardingEmail({ commit }, email) {
      commit("setDefaultForwardingEmail", email);
    },
    setDefaultForwardingPhone({ commit }, email) {
      commit("setDefaultForwardingPhone", email);
    },
    setVisibleBanner({ commit }, visible) {
      commit("setVisibleBanner", visible);
    },
    openCloakCreate({ commit, dispatch }) {
      commit("openCloakCreate");
      dispatch("updateCloaks", [
        {
          id: -1,
          nickname: "",
          created_at: moment().toISOString(),
          last_used_at: moment().toISOString(),
        },
      ]);
    },
    updateTempCloak({ dispatch }, cloak) {
      dispatch("updateCloaks", [
        {
          ...cloak,
          id: -1,
          created_at: moment().toISOString(),
          last_used_at: moment().toISOString(),
        },
      ]);
    },
    async updateCloaks({ dispatch, state }, cloaks) {
      const formattedCloaks = await dispatch("updateCloaksAndFormat", {
        cloaks,
        userId: state.authentication.user.id,
      });
      return formattedCloaks;
    },
  },
  modules: {
    analytics,
    rightPanel,
    modal,
    subscription,
    cards,
    media,
    reuse,
    accountsImporter,
    recentlyImported,
    onboarding,
    authentication,
    localdb,
    settings,
    browser,
    autoChange,
    colorScheme,
  },
  plugins: [
    createPersistedState({
      key: "vuex",
      paths: [
        "account",
        "ui.user",
        "ui.showRequests",
        "ui.hide_recommend",
        "onboarding",
        "encryption",
        "authentication",
        "colorScheme",
      ],
      getState: (key) => {
        return JSON.parse(localStorage.getItem(key));
      },
      setState: (key, state) => {
        if (!state.locked) {
          const capture = localStorage.setItem(key, JSON.stringify(state));
          window.dispatchEvent(new Event("storage:write"));
          return capture;
        }
      },
    }),
  ],
});
