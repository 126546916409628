var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ModalApp",
    _vm._g(
      {
        on: {
          click: function ($event) {
            return _vm.$store.dispatch("onboarding/close")
          },
        },
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }