var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { class: { hidden: _setup.route.fullPath.includes("settings") } },
    [
      _c("div", { staticClass: "title" }, [
        _setup.pageTitle.icon
          ? _c(
              "div",
              { staticClass: "icon" },
              [
                _c(_setup.InlineSvg, {
                  key: _setup.componentKey,
                  attrs: { name: _setup.pageTitle.icon },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("span", [_vm._v(_vm._s(_setup.pageTitle.title))]),
      ]),
      !_setup.inbox
        ? _c("div", { staticClass: "search", on: { click: _setup.search } }, [
            _c(
              "span",
              [
                _c(_setup.InlineSvg, { attrs: { name: "search" } }),
                _vm._v(" Search all identities"),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", { ref: "target", staticClass: "username-dropdown" }, [
        _c(
          "div",
          { staticClass: "username", on: { click: _setup.activateDropdown } },
          [
            _c("div", { staticClass: "text" }, [
              _setup.username
                ? _c("span", [_vm._v(_vm._s(_setup.username))])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "icon", class: { active: _setup.dropdown } },
              [
                _setup.username
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_setup.getAccountInitials(_setup.username))
                      ),
                    ])
                  : _c(
                      "span",
                      [
                        _c(_setup.InlineSvg, {
                          attrs: { name: "user-icon-filled" },
                        }),
                      ],
                      1
                    ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "dropdown", class: { active: _setup.dropdown } },
          [
            _c(
              "router-link",
              {
                staticClass: "user",
                class: { disabled: _setup.subscriptionState === "CANCELLED" },
                attrs: { to: "/settings" },
                nativeOn: {
                  click: function ($event) {
                    return _setup.closeDropdown()
                  },
                },
              },
              [
                _c("div", { staticClass: "icon" }, [
                  _setup.username
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_setup.getAccountInitials(_setup.username))
                        ),
                      ])
                    : _c(
                        "span",
                        [
                          _c(_setup.InlineSvg, {
                            attrs: { name: "user-icon-filled" },
                          }),
                        ],
                        1
                      ),
                ]),
                _c("div", { staticClass: "text" }, [
                  _setup.username
                    ? _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(_setup.username)),
                      ])
                    : _c("span", { staticClass: "name" }, [
                        _vm._v("Cloaked User"),
                      ]),
                  _setup.subscriptionState !== "CANCELLED"
                    ? _c("span", { staticClass: "info" }, [
                        _vm._v("Edit my information"),
                      ])
                    : _c("span", { staticClass: "info" }, [
                        _vm._v("Read only account"),
                      ]),
                ]),
              ]
            ),
            _setup.subscriptionState === "CANCELLED"
              ? _c(
                  "div",
                  { staticClass: "button", on: { click: _setup.upgradeModal } },
                  [_c("span", [_vm._v("Upgrade now")])]
                )
              : _vm._e(),
            _c("div", { staticClass: "divider" }),
            _c(
              "router-link",
              {
                staticClass: "item link",
                attrs: { to: "/settings" },
                nativeOn: {
                  click: function ($event) {
                    return _setup.closeDropdown()
                  },
                },
              },
              [
                _c(_setup.InlineSvg, { attrs: { name: "cog" } }),
                _c("span", [_vm._v("Settings")]),
              ],
              1
            ),
            _c(
              "router-link",
              {
                staticClass: "item link",
                attrs: { to: "/autocloak" },
                nativeOn: {
                  click: function ($event) {
                    return _setup.closeDropdown()
                  },
                },
              },
              [
                _c(_setup.InlineSvg, { attrs: { name: "auto-change" } }),
                _c("span", [_vm._v("AutoCloak Identity")]),
              ],
              1
            ),
            _c(
              "router-link",
              {
                staticClass: "item link",
                attrs: { to: "/import" },
                nativeOn: {
                  click: function ($event) {
                    return _setup.closeDropdown()
                  },
                },
              },
              [
                _c(_setup.InlineSvg, { attrs: { name: "key-filled" } }),
                _c("span", [_vm._v("Import passwords")]),
              ],
              1
            ),
            _c(
              "router-link",
              {
                staticClass: "item link",
                attrs: { to: "/cloaked-daily" },
                nativeOn: {
                  click: function ($event) {
                    return _setup.closeDropdown()
                  },
                },
              },
              [
                _c(_setup.InlineSvg, { attrs: { name: "paper" } }),
                _c("span", [_vm._v("Cloaked Daily")]),
              ],
              1
            ),
            _c(
              "router-link",
              {
                staticClass: "item link",
                attrs: { to: "/referrals" },
                nativeOn: {
                  click: function ($event) {
                    return _setup.closeDropdown()
                  },
                },
              },
              [
                _c(_setup.InlineSvg, { attrs: { name: "gift" } }),
                _c("span", [_vm._v("Invite a friend")]),
              ],
              1
            ),
            _c("div", { staticClass: "divider" }),
            _c(
              "a",
              {
                staticClass: "item download",
                attrs: {
                  href: "https://download.cloaked.app",
                  target: "_blank",
                },
                on: {
                  click: function ($event) {
                    return _setup.closeDropdown()
                  },
                },
              },
              [
                _c(_setup.InlineSvg, { attrs: { name: "browser" } }),
                _c("span", [_vm._v("Download extension")]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "item download",
                on: { click: _setup.toggleDownloadAppModal },
              },
              [
                _c(_setup.InlineSvg, { attrs: { name: "block-active" } }),
                _c("span", [_vm._v("Download mobile app")]),
              ],
              1
            ),
            _c("div", { staticClass: "divider" }),
            _c(
              "div",
              {
                staticClass: "item toggle",
                on: { click: _setup.toggleDarkMode },
              },
              [
                _c(_setup.InlineSvg, { attrs: { name: "moon" } }),
                _c("span", [_vm._v("Dark mode")]),
                _c(
                  "button",
                  {
                    staticClass: "toggle",
                    class: { enabled: _setup.isDarkMode },
                  },
                  [_c("span")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "item", on: { click: _setup.openHelp } }, [
              _c("span", [_vm._v("Get help or share feedback")]),
            ]),
            _c(
              "div",
              {
                staticClass: "item",
                on: {
                  click: function ($event) {
                    return _setup.logout()
                  },
                },
              },
              [_c("span", [_vm._v("Log out")])]
            ),
          ],
          1
        ),
      ]),
      _c(_setup.ModalApp, {
        on: { click: _setup.toggleDownloadAppModal },
        model: {
          value: _setup.isDownloadAppVisible,
          callback: function ($$v) {
            _setup.isDownloadAppVisible = $$v
          },
          expression: "isDownloadAppVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }