<script setup>
import MenuBlock from "./MenuBlock.vue";
import MenuItem from "./MenuItem.vue";
</script>

<template>
  <MenuBlock>
    <MenuItem link="/trash" name="Trash" icon="delete-trash" />
  </MenuBlock>
</template>
