<template>
  <div class="forwarding-phone">
    <Phone v-if="step < 6 && step >= 3" />
    <PhoneDone v-else-if="step === 6" class="forwarding-phone__done" />
    <AppModalTitle v-if="step === 3" class="forwarding-phone__choice-title">
      Where should calls sent to your Cloaked phone numbers go?
    </AppModalTitle>
    <AppModalTitle v-else-if="step === 4"> Add a phone number </AppModalTitle>
    <AppModalTitle v-else-if="step === 5">
      Verify your phone number
    </AppModalTitle>
    <AppModalTitle v-else-if="step === 6" class="forwarding-phone__verified">
      Your phone number has been verified
    </AppModalTitle>
    <AppModalParagraph v-if="step === 4">
      Calls made to your Cloaked phone numbers will be routed to this phone
      number
    </AppModalParagraph>
    <AppModalParagraph v-else-if="step === 5">
      Enter the 6-digit code sent to <strong>{{ phone }}</strong
      ><br /><button class="forwarding-phone__resend" @click="$emit('resend')">
        Resend code
      </button>
    </AppModalParagraph>
    <AppModalParagraph
      v-else-if="step === 6"
      class="forwarding-phone__verified-subtitle"
    >
      Calls made to your Cloaked phone numbers will be routed to
      <strong>{{ phone }}</strong
      >. You can change this setting for individual Cloaked identities.
    </AppModalParagraph>
    <OnboardingInputPhone
      :value="phone"
      @input="$emit('input-phone', $event)"
      v-if="step === 4"
      placeholder="Phone number"
    />
    <OnboardingInputCode
      :value="phoneCode"
      v-else-if="step === 5"
      @input="$emit('input-phone-code', $event)"
      v-on="$listeners"
    />
    <div v-if="step === 3">
      <fieldset class="forwarding-phone__forwarding-choice">
        <OnboardingInputRichRadio
          @change="$emit('input-phone-forwarding', $event.target.value)"
          :checked="phoneForwarding === 'cloaked'"
          pre-title="RECOMMENDED"
          title="Keep texts in Cloaked"
          text="Texts only go to your Cloaked inbox"
          id="cloaked"
          ref="radioOne"
          @keydown.down.native="$emit('input-phone-forwarding', 'phone')"
        >
          <template #image>
            <PhoneForwardingCloaked />
          </template>
        </OnboardingInputRichRadio>
        <OnboardingInputRichRadio
          @change="$emit('input-phone-forwarding', $event.target.value)"
          title="Forward texts to your personal phone number"
          text="Texts go to your Cloaked inbox and are also forwarded to your personal
          phone number"
          id="phone"
          :checked="phoneForwarding === 'phone'"
          @keydown.up.native="$emit('input-phone-forwarding', 'cloaked')"
        >
          <template #image>
            <PhoneForwardingPersonal />
          </template>
        </OnboardingInputRichRadio>
      </fieldset>
    </div>
    <PreferencesCheckParagraph
      v-if="step === 4"
      :value="phoneForwardingConsent"
      @input="$emit('input-phone-forwarding-consent', $event)"
      class="forwarding-phone__consent"
      tabindex="0"
      @keydown.native.enter="
        $emit('input-phone-forwarding-consent', !phoneForwardingConsent)
      "
      @keydown.native.space="
        $emit('input-phone-forwarding-consent', !phoneForwardingConsent)
      "
    >
      I understand that Cloaked will forward calls from third parties to the
      phone number I provide.
      <a
        href="https://cloaked.app/privacy-policy"
        target="_blank"
        class="forwarding-phone__consent-link"
        >Learn more</a
      >.
    </PreferencesCheckParagraph>
  </div>
</template>

<script>
import AppModalTitle from "@/components/ui/AppModalTitle";
import AppModalParagraph from "@/components/ui/AppModalParagraph";
import Phone from "@/assets/icons/onboarding/phone.svg";
import PhoneDone from "@/assets/icons/onboarding/phone-done.svg";
import OnboardingInputPhone from "@/components/feature/onboarding/OnboardingInputPhone";
import OnboardingInputCode from "@/components/feature/onboarding/OnboardingInputCode";
import OnboardingInputRichRadio from "@/components/feature/onboarding/OnboardingInputRichRadio";
import PhoneForwardingCloaked from "@/assets/icons/onboarding/phone-forwarding-cloaked.svg";
import PhoneForwardingPersonal from "@/assets/icons/onboarding/phone-forwarding-personal.svg";
import PreferencesCheckParagraph from "@/routes/modals/preferences/PreferencesCheckParagraph";

export default {
  components: {
    PreferencesCheckParagraph,
    OnboardingInputRichRadio,
    OnboardingInputCode,
    OnboardingInputPhone,
    AppModalTitle,
    AppModalParagraph,
    Phone,
    PhoneDone,
    PhoneForwardingCloaked,
    PhoneForwardingPersonal,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
    phoneCode: {
      type: String,
      required: true,
    },
    phoneForwarding: {
      type: String,
      required: true,
    },
    phoneForwardingConsent: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    step: {
      immediate: true,
      async handler() {
        await this.$nextTick();
        this.$refs?.radioOne?.$refs?.input?.focus();
      },
    },
  },
};
</script>

<style lang="scss">
.forwarding-phone {
  &__done {
    animation: scale-in 0.35s 0.1s cubic-bezier(0.3, 1.6, 0.9, 0.95) forwards;
    opacity: 0;

    @at-root .forwarding-phone--verified & {
      animation: none;
      opacity: 1;
    }
  }

  &__choice-title {
    text-align: left;
    padding: 0 32px !important;
    margin-top: 0 !important;
  }

  &__verified {
    opacity: 0;
    animation: appear-bottom-5 0.4s 0.4s ease-out forwards;

    @at-root .forwarding-phone--verified & {
      animation: none;
      opacity: 1;
    }

    &-subtitle {
      opacity: 0;
      animation: appear-bottom-5 0.4s 0.5s ease-out forwards;

      @at-root .forwarding-phone--verified & {
        animation: none;
        opacity: 1;
      }
    }
  }

  &__forwarding-choice {
    border: 0;
    margin: 0;
    padding: 0;

    .onboarding-input-radio {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__resend {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;

    &:hover {
      opacity: 0.9;
    }
  }

  strong {
    font-weight: 500;
  }

  & &__consent {
    text-align: left;
    margin: 32px 32px 0;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: $color-primary-90;
    gap: 16px;

    svg {
      margin-top: 3px;
    }

    &-link {
      color: #7772f8;

      &:hover {
        text-decoration: underline;
      }
    }

    .check-button {
      color: #7772f8;
    }
  }
}
</style>
