<script setup>
import {
  Button,
  ContactIcon,
  UiMenu,
  UiMenuButton,
  UiMenuSeparator,
} from "@/components";
import {
  BlockContact,
  CopyIcon,
  EditPencilIcon,
  KabobIcon,
} from "@/assets/icons";
import { CloakInfoRowButton } from "@/components/cloakDetails/index";
import { defineProps, ref } from "vue";
import {
  getAccountInitials,
  getContactName,
  phone_format,
} from "@/scripts/format";
import { constants, tools } from "@/scripts";

defineProps({
  contact: {
    type: Object,
    required: true,
  },
});

const isMenuOpen = ref(false);

const getContactDisplayName = (contactDetails) => {
  const { firstName, lastName } = getContactName(contactDetails);
  if (firstName || lastName) {
    return `${firstName} ${lastName}`;
  } else if (contactDetails?.cloak_contact_phone) {
    return phone_format(contactDetails.cloak_contact_phone);
  } else if (contactDetails?.cloaked_contact_email) {
    return contactDetails.cloaked_contact_email;
  }
};

const getContactInitialsDisplay = (contactDetails) => {
  const { firstName, lastName } = getContactName(contactDetails);
  if (firstName || lastName) {
    return getAccountInitials(firstName, lastName);
  } else if (contactDetails.original_name) {
    const nameArray = contactDetails.original_name.split(" ");
    return nameArray[1]
      ? getAccountInitials(nameArray[0], nameArray[1])
      : getAccountInitials(nameArray[0]);
  }
  return "";
};

const copy = (value) => {
  tools.copyToClipboard(value);
};
</script>

<template>
  <div
    class="cloak-contact centered-flex-row"
    :class="{ 'cloak-contact--menu-open': isMenuOpen }"
  >
    <div class="centered-flex-row">
      <ContactIcon
        :showBlockIcon="contact.status === constants.CONTACT_STATUS.BLOCKED"
        :initials="getContactInitialsDisplay(contact)"
        :override="{ width: '24px', height: '24px' }"
      />
      <h5>{{ getContactDisplayName(contact) }}</h5>
    </div>
    <UiMenu
      v-if="contact.status !== constants.CONTACT_STATUS.BLOCKED"
      v-model="isMenuOpen"
      width="188px"
      placement="bottom-end"
    >
      <CloakInfoRowButton class="mini-btn" icon :active="isMenuOpen">
        <KabobIcon />
      </CloakInfoRowButton>

      <template #content>
        <UiMenuButton
          title="Edit"
          @click="
            $emit('edit', {
              contactId: contact.id,
              contactType: contact?.original_phone
                ? constants.CONTACT_TYPE.PHONE
                : constants.CONTACT_TYPE.EMAIL,
            })
          "
        >
          <template v-slot:icon>
            <EditPencilIcon />
          </template>
        </UiMenuButton>
        <UiMenuButton
          title="Copy"
          @click="
            copy(
              contact?.original_phone && contact?.cloak_contact_phone
                ? contact.cloak_contact_phone
                : contact.cloaked_contact_email
            )
          "
        >
          <template v-slot:icon>
            <CopyIcon />
          </template>
        </UiMenuButton>
        <UiMenuSeparator />
        <UiMenuButton
          title="Block"
          @click="
            $emit('change-status', {
              contactId: contact.id,
              contactType: contact?.original_phone
                ? constants.CONTACT_TYPE.PHONE
                : constants.CONTACT_TYPE.EMAIL,
              newStatus: constants.CONTACT_ACTION.BLOCK,
            })
          "
        >
          <template v-slot:icon>
            <BlockContact />
          </template>
        </UiMenuButton>
      </template>
    </UiMenu>

    <Button
      v-if="contact.status === constants.CONTACT_STATUS.BLOCKED"
      @click="
        $emit('change-status', {
          contactId: contact.id,
          contactType: contact?.original_phone
            ? constants.CONTACT_TYPE.PHONE
            : constants.CONTACT_TYPE.EMAIL,
          newStatus: constants.CONTACT_ACTION.APPROVE,
        })
      "
      class="unblock-button"
    >
      Unblock
    </Button>
  </div>
</template>

<style lang="scss" scoped>
.centered-flex-row {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  max-width: calc(100% - 26px);
  overflow: hidden;
  color: $color-primary-100;
  position: relative;

  .ui-menu {
    visibility: hidden;
  }

  &.cloak-contact {
    border-radius: 99px;
    padding: 4px;
    justify-content: space-between;
    border: 1px solid transparent;

    &--menu-open,
    &:hover {
      border: 1px solid $color-primary-10-light;
      .ui-menu {
        visibility: visible;
      }
    }

    h5 {
      font-size: 12px;
      font-weight: 500;
      margin-left: 12px;
    }

    .ui-menu {
      .mini-btn {
        width: 24px;
        height: 24px;
        min-width: 24px;
        color: $color-primary-100;
      }
    }

    .unblock-button {
      font-size: 10px;
      padding: 3px 4px;
      height: 24px;
      flex-shrink: 0;
      position: absolute;
      right: 3px;
    }
  }
}
</style>
