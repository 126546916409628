<template>
  <div class="new-message-container" v-if="visible">
    <div class="new-message" @click="($event) => $event.stopPropagation()">
      <div class="full-height recipients">
        <div class="flex-row flex-between header-line">
          <h1 v-if="!isLockedtoItem">New Message</h1>
          <h1 v-else-if="firstItemIsEmail">New Email</h1>
          <h1 v-else-if="firstItemIsPhone">New Text Message</h1>
          <button @click="cancel">
            <CloseIcon />
          </button>
        </div>
        <h3
          v-if="error_message && error_message.type === 'button'"
          class="error_message"
        >
          Please create {{ error_message.text }} for this identity before
          sending a message by
          <button @click="openDetails">clicking here</button>
        </h3>
        <h3
          v-if="error_message && error_message.type === 'text'"
          class="error_message"
        >
          {{ error_message.text }}
        </h3>
        <div class="flex-row flex-gap border-bottom">
          <label>From:</label>
          <div class="from-email" v-if="from_email">
            <div>
              {{ from_email }}
              <button @click="clear_from">
                <RemoveIcon />
              </button>
            </div>
          </div>
          <input
            v-else
            aria-id="ComposeSelectIdentity"
            maxlength="50"
            autocomplete="off"
            data-lpignore="true"
            data-form-type="other"
            type="text"
            ref="cInput"
            autofocus
            class="add-from"
            placeholder="Enter any Cloaked identity, phone number, or email"
            v-model="search"
            @keyup.enter="handleEnter"
            @keyup.up="(event) => nav(-1, event)"
            @keyup.down="(event) => nav(1, event)"
          />
        </div>
        <div v-if="!from_email" class="search-results">
          <div class="results" v-if="search !== null" ref="scroll_results">
            <div class="search-items" v-if="groups.length > 0">
              <div v-for="(item, index) in groups" :key="index">
                <span class="group-title" v-if="item.type === 'group'">{{
                  item.name
                }}</span>
                <div
                  v-else
                  class="group_item"
                  @mousemove="current = index"
                  @click="clickSearchItem(item)"
                >
                  <div
                    class="search-item"
                    :class="{ active: current === index }"
                    :id="`item-${index}`"
                  >
                    <IdentityIcon :identity="item" />
                    <div class="item-pill">
                      <div>
                        <h1>
                          {{ item.n ? item.n : item.nickname }}
                        </h1>
                        <h3 v-if="item.display" v-html="item.display"></h3>
                      </div>
                    </div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.78711 11.1582C5.0918 11.1582 5.33008 11.0312 5.50195 10.7773L11.0098 2.19336C11.0684 2.09961 11.1113 2.01172 11.1387 1.92969C11.166 1.84375 11.1797 1.75977 11.1797 1.67773C11.1797 1.47461 11.1152 1.31055 10.9863 1.18555C10.8574 1.05664 10.6914 0.992188 10.4883 0.992188C10.3398 0.992188 10.2188 1.02148 10.125 1.08008C10.0312 1.13477 9.93945 1.23047 9.84961 1.36719L4.76367 9.44727L2.13867 6.07812C1.97461 5.86328 1.77344 5.75586 1.53516 5.75586C1.32812 5.75586 1.15625 5.82227 1.01953 5.95508C0.886719 6.08789 0.820312 6.25586 0.820312 6.45898C0.820312 6.54883 0.835938 6.63672 0.867188 6.72266C0.902344 6.80859 0.957031 6.89648 1.03125 6.98633L4.06641 10.7832C4.26562 11.0332 4.50586 11.1582 4.78711 11.1582Z"
                        fill="#191E23"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <InfiniteTrigger @infinite="loadMore" />
            </div>
          </div>
        </div>
        <div v-else class="full-height recipients">
          <div class="flex-row flex-gap border-bottom">
            <label>To:</label>
            <div
              class="to-row"
              :class="{ error_m: hasErrors('to') }"
              aria-id="ComposeToField"
            >
              <PillInput
                @value="setPills"
                :defaultValue="pillData(to)"
                ref="toInput"
                :filter="check"
                :multiple="!!firstItemIsPhone"
              />
              <button
                class="cc-button"
                v-if="!enable_cc && firstItemIsEmail && canEmail"
                @click="enableCC"
              >
                CC
              </button>
            </div>
          </div>
          <div
            class="flex-row flex-gap border-bottom"
            v-if="enable_cc && firstItemIsEmail && canEmail"
          >
            <label>CC</label>

            <div class="to-row" :class="{ error_m: hasErrors('cc') }">
              <PillInput
                ref="ccInput"
                @value="setCC"
                :filter="check"
                :defaultValue="pillData(cc)"
              />
            </div>
          </div>
          <div v-if="ready" class="full-height">
            <div
              class="flex-row flex-gap border-bottom"
              v-if="!firstItemIsPhone"
            >
              <label>Subject:</label>
              <input
                aria-id="ComposeSubjectInput"
                :class="{ error_m: hasErrors('subject') }"
                type="email"
                maxlength="255"
                autocomplete="off"
                data-lpignore="true"
                data-form-type="other"
                v-model="subject"
                ref="subjectInput"
              />
            </div>
            <div class="full-height">
              <textarea
                aria-id="ComposeEmailTextArea"
                rows="6"
                placeholder="Start a new message"
                v-model="text"
                :class="{ error_m: hasErrors('body') }"
                ref="bodyInput"
                @keydown.enter.exact.prevent="addEnter"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <FileList
        v-if="canAttach"
        :files="files"
        :error="hasErrors('files')"
        :errors="firstItemIsPhone && 'Files must be an pdf, png, jpg only'"
        @remove="removeFile"
      />
      <div class="flex-row flex-between" v-if="!search">
        <FileAttachments
          v-if="canAttach"
          ref="fileAttachment"
          :endpoint="
            firstItemIsPhone
              ? '/api/v1/cloaked/activity/upload_media_urls/'
              : '/api/v1/cloaked/activity/upload_urls/'
          "
          @change="handleFileChange"
          :filter="firstItemIsPhone && attachFilter"
        />
        <div v-else></div>
        <button
          aria-id="ComposeSendButton"
          class="send"
          @click="send"
          :disabled="!canSend"
          :class="{
            active: canSend,
          }"
        >
          <span :class="{ sent: sent, sending: sending }"><SendIcon /></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  email,
  maxPayload,
  phone,
  formatMultiplePillEntries,
} from "@/scripts/validation";
import PillInput from "@/components/ui/input/pill-input";
import RemoveIcon from "@/assets/icons/remove.svg";
import CloseIcon from "@/assets/icons/close-x-borderless.svg";
import SendIcon from "@/assets/icons/compose-send.svg";
import { searchLocalCloaksGrouped } from "@/scripts/search";
import IdentityIcon from "@/components/ui/IdentityIcon.vue";
import InfiniteTrigger from "@/components/global/InfiniteTrigger";
import { dbMixin } from "@/mixins/db";

import FileAttachments from "@/components/global/FileAttachments";
import FileList from "@/components/global/FileList";
import { TEXT_ATTACHMENTS_ENABLED } from "@/scripts/featureFlags";
import { FILE_PERMISSIONS } from "@/scripts/constants";

let timeout;
let searchTimeout;
export default {
  name: "Compose",
  props: ["open", "onSend"],
  components: {
    FileList,
    FileAttachments,
    InfiniteTrigger,
    PillInput,
    SendIcon,
    RemoveIcon,
    CloseIcon,
    IdentityIcon,
  },
  mixins: [dbMixin],
  data() {
    return {
      visible: true,
      contacts: [],
      identities: [],
      to: this.defaultTo ? this.defaultTo : [],
      enable_cc: false,
      cc: [],
      from: null,
      subject: this.defaultSubject ? this.defaultSubject : "",
      text: "",
      files: [],
      sending: false,
      sent: false,
      current: 0,
      limit: 20,
      errors: [],
      dirty: false,
      search: "",
      searchText: "",
    };
  },
  mounted() {
    if (this.defaultTo) {
      this.$refs.toInput.setValue(this.pillData(this.defaultTo));
    }
    if (this.defaultSubject) {
      this.subject = this.defaultSubject;
    }
    if (this.$refs.cInput) {
      this.$refs.cInput.focus();
    }
    this.files = [];
  },
  beforeDestroy() {
    this.$store.commit("compose", null);
  },
  watch: {
    search(value) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      searchTimeout = setTimeout(() => {
        this.searchText = value;
      }, 150);
    },
    searchText() {
      this.current = 0;
      this.limit = 20;
      this.$nextTick(() => {
        if (this.$refs.scroll_results) {
          this.$refs.scroll_results.scrollTo(0, 0);
        }
      });
    },
    subject() {
      this.dirty = true;
    },
    to(value) {
      if (!value || value.length === 0) {
        this.subject = "";
        this.text = "";
      }
      this.dirty = true;
    },
    from_email() {
      this.$nextTick(() => {
        if (this.$refs.toInput) {
          this.$refs.toInput.recalculate();
        }
        if (this.$refs.ccInput) {
          this.$refs.ccInput.recalculate();
        }
      });
    },
    from(value) {
      this.dirty = true;
      if (!value) {
        this.text = "";
        this.subject = "";
      }
    },
    text() {
      this.dirty = true;
    },
    ready(value) {
      if (value) {
        if (this.firstItemIsEmail) {
          if (this.$refs.subjectInput) {
            this.$refs.subjectInput.focus();
          }
        } else {
          if (this.$refs.bodyInput) {
            this.$refs.bodyInput.focus();
          }
        }
      }
    },
  },
  computed: {
    canAttach() {
      if (!TEXT_ATTACHMENTS_ENABLED) {
        return this.firstItemIsEmail && this.canEmail;
      }
      return (
        (this.firstItemIsEmail && this.canEmail) ||
        (this.firstItemIsPhone && this.canSms)
      );
    },
    maxPayload() {
      return maxPayload(this.files);
    },
    defaultTo() {
      if (
        this.$store.state.compose_message &&
        this.$store.state.compose_message.payload
      ) {
        return [this.$store.state.compose_message.payload.sender_email];
      }
      return null;
    },
    defaultSubject() {
      if (
        this.$store.state.compose_message &&
        this.$store.state.compose_message.payload
      ) {
        return [this.$store.state.compose_message.payload.subject];
      }
      return null;
    },
    emailId() {
      if (
        this.$store.state.compose_message &&
        this.$store.state.compose_message.payload
      ) {
        return [this.$store.state.compose_message.payload.id];
      }
      return null;
    },
    error_message() {
      if (this.from || this.from_email) {
        if (this.firstItemIsPhone && !this.canSms) {
          return {
            type: "button",
            text: "a phone number",
          };
        }
        if (this.firstItemIsEmail && !this.canEmail) {
          return {
            type: "button",
            text: "an email address",
          };
        }
        if (this.hasEmail && this.hasPhone) {
          return {
            type: "text",
            text: "Cloaked can’t send a message to an email and a phone number at the same time, please remove one (or more) conflicting contacts.",
          };
        }
      }
      return null;
    },
    groups() {
      let results = [];
      if (this.searchText) {
        const data = searchLocalCloaksGrouped(this.cloaks, this.searchText);
        Object.keys(data).map((k) => {
          if (data[k].length > 0) {
            results.push({ type: "group", name: k });
            const items = data[k].map((i) => {
              i.type = "item";
              return i;
            });
            results = [...results, ...items];
          }
        });
      }
      return results.slice(0, this.limit);
    },
    has_email() {
      return this.to && email(this.to);
    },
    identity() {
      return this.from || this.$store.state.compose_message.identity;
    },
    from_identity() {
      if (this.from === null) {
        return this.$store.state.compose_message.identity;
      }
      return this.from;
    },
    from_email() {
      const identity = this.from_identity;
      if (identity && identity.id) {
        return identity.nickname || "(No Nickname)";
      }
      return null;
    },
    canSms() {
      return !!this.identity.cloaked_phone;
    },
    canEmail() {
      return !!this.identity.cloaked_email;
    },
    firstItemIsEmail() {
      return this.to.length > 0 && email(this.to[0]);
    },
    firstItemIsPhone() {
      return this.to.length > 0 && phone(this.to[0]);
    },
    hasEmail() {
      return this.to.findIndex((f) => email(f)) !== -1;
    },
    hasPhone() {
      return this.to.findIndex((f) => phone(f)) !== -1;
    },
    isLockedtoItem() {
      return this.firstItemIsEmail || this.firstItemIsPhone;
    },
    ready() {
      return this.to.filter((a) => this.check(a)).length > 0;
    },
    canSend() {
      const fileHold = this.files.filter((f) => {
        return f.uploading || f.error;
      });
      if (fileHold.length === 0) {
        if (this.errors.length === 0 && !this.errorMessage) {
          if (this.firstItemIsEmail && this.canEmail) {
            return this.ready && this.subject && this.text;
          }
          if (this.firstItemIsPhone && this.canSms) {
            return this.ready && this.text;
          }
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["openModal"]),
    attachFilter(file) {
      if (this.firstItemIsPhone) {
        const ext = (file.name || file.filename)
          ?.split(".")
          .pop()
          .toLowerCase();
        return !FILE_PERMISSIONS.TEXT_ALLOWED.find((type) => type === ext);
      }
      return true;
    },
    removeFile(index) {
      this.$refs.fileAttachment.removeFile(index);
    },
    handleFileChange(files) {
      this.files = files;
    },
    addEnter(e) {
      if (e.key === "Enter") {
        const position = e.target.selectionEnd;
        this.text =
          e.target.value.substring(0, position) +
          "\n" +
          e.target.value.substring(position);
        const extra = 1;
        this.$nextTick(() => {
          this.$refs.bodyInput.selectionEnd = position + extra;
          this.$refs.bodyInput.selectionStart = position + extra;
          e.target.selectionEnd = position + extra;
          e.target.selectionStart = position + extra;
        });
      }
    },
    openDetails() {
      this.$store.dispatch("fetchPopulatedData", this.identity).then((data) => {
        this.$store.commit("compose", null);
        this.$store.dispatch("openCloakDetails", { cloak: data });
      });
    },
    pillData(data) {
      return data.map((d) => ({
        display: d,
        value: d,
      }));
    },
    loadMore($state) {
      this.limit = this.limit + 20;
      if (this.limit > this.groups.length) {
        $state.complete();
      }
    },
    handleEnter() {
      const item = this.groups[this.current];
      if (item && item.type === "item") {
        this.setFrom(item);
      }
      this.search = "";
    },
    clickSearchItem(item) {
      this.setFrom(item);
      this.search = "";
    },
    getNextItem(index, direction) {
      const item = this.groups[index];
      if (item) {
        if (item.type === "item") {
          return index;
        }
        return this.getNextItem(index + direction, direction);
      }
      return index;
    },
    nav(direction, event) {
      event.preventDefault();
      let next = this.current + direction;
      next = this.getNextItem(next, direction);
      if (next < 0) {
        next = this.getNextItem(this.groups.length - 1, -1);
      }
      if (next >= this.groups.length) {
        next = this.getNextItem(0, 1);
      }
      this.current = next;
      document
        .getElementById(`item-${next}`)
        .scrollIntoView({ behavior: "smooth", block: "center" });
    },
    clearSearch() {
      this.search = null;
    },
    focusSearch() {
      this.$refs.cInput.focus();
    },

    setFrom(cloak) {
      this.search = "";
      const payload = {
        id: cloak.id,
        nickname: cloak.nickname,
      };
      if (cloak.phone && cloak.is_cloaked_phone) {
        payload.cloaked_phone = {
          phone_number: cloak.phone,
        };
      }
      if (cloak.email && cloak.is_cloaked_email) {
        payload.cloaked_email = {
          email: cloak.email,
        };
      }
      this.from = payload;
      this.$nextTick(() => {
        this.$refs.toInput.selectInput();
      });
    },
    clear_from() {
      this.from = false;
    },
    enableCC() {
      this.enable_cc = true;
      this.$nextTick(() => {
        this.$refs.ccInput.selectInput();
      });
    },
    check(value) {
      if (this.isLockedtoItem && this.firstItemIsEmail) {
        return email(value) && this.canEmail;
      }
      if (this.isLockedtoItem && this.firstItemIsPhone) {
        return phone(value) && this.canSms;
      }
      return (email(value) && this.canEmail) || (phone(value) && this.canSms);
    },
    setPills(value) {
      /* if incoming emails or phone numbers are copy/pasted without commas or spaces */
      const incoming = value.map((v) => formatMultiplePillEntries(v.value));
      const updatedValues = incoming.flat();
      this.to = updatedValues;
      this.$nextTick(() => {
        this.$refs.toInput.recalculate();
      });
    },
    setCC(value) {
      this.cc = value.map((v) => v.value);
      this.$nextTick(() => {
        this.$refs.ccInput.recalculate();
      });
    },
    hasErrors(type) {
      return this.errors.includes(type);
    },
    undo() {
      clearTimeout(timeout);
      this.visible = true;
      this.$nextTick(() => {
        if (this.$refs.toInput) {
          this.$refs.toInput.recalculate();
        }
        if (this.$refs.ccInput) {
          this.$refs.ccInput.recalculate();
        }
      });
    },
    cancel() {
      if (this.dirty) {
        this.openModal({
          header: `Discard Draft?`,
          subheader: `This message will be permanently deleted.`,
          button: {
            text: "Yes, Delete",
            danger: true,
            onClick: () => {
              this.$store.commit("compose", null);
              this.visible = false;
              this.$toast.success("Message Deleted");
            },
          },
        });
      } else {
        this.$store.commit("compose", null);
      }
    },
    send() {
      if (!this.sending) {
        const errors = [];
        const isEmail = this.firstItemIsEmail;
        this.to.map((em) => {
          if (!this.check(em)) {
            errors.push("to");
          }
        });
        if (this.maxPayload) {
          errors.push("files");
        }
        if (!this.subject && isEmail) {
          errors.push("subject");
        }
        if (this.cc.length > 0 && isEmail) {
          this.cc.map((em) => {
            if (!email(em)) {
              errors.check("cc");
            }
          });
        }
        if (!this.text) {
          errors.push("body");
        }

        if (errors.length === 0) {
          const data = {
            to: this.to,
            identity_id: this.from_identity.id,
            isEmail: !!this.firstItemIsEmail,
            isSms: !!this.firstItemIsPhone,
            emailId: this.emailId,
            text: this.text,
            subject: this.subject,
            activityId: this.$store.state.compose_message.activity_id,
            originalHTMLContent:
              this.$store.state.compose_message?.payload?.html.trim() ||
              this.$store.state.compose_message?.payload?.text.trim(),
            originalTextContent:
              this.$store.state.compose_message?.payload?.text.trim(),
          };
          if (this.cc.length > 0) {
            data.cc = this.cc;
          }
          if (this.files.length > 0) {
            if (this.firstItemIsPhone) {
              data.media = this.files;
            } else {
              data.attachments = this.files;
            }
          }
          this.sending = true;
          this.onSend(data)
            .then((resp) => {
              if (resp.status === 200) {
                this.sent = true;
                if (
                  this.$store.state.compose_message.payload &&
                  this.$store.state.compose_message.payload.onSend
                ) {
                  this.$store.state.compose_message.payload.onSend();
                }
                this.$store.commit("compose", null);
                this.from = null;
                this.sending = false;
                this.$toast.success("Message sent");
              } else {
                throw "error";
              }
            })
            .catch(() => {
              this.sending = false;
              this.$toast.error("Failed to send message");
            });
        } else {
          this.errors = errors;
          setTimeout(() => {
            this.errors = [];
          }, 2500);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error_message {
  font-size: 14px;
  line-height: 20px;
  background-color: $color-primary-5;
  color: $color-primary-100;
  padding: 5px;
  border-radius: 4px;

  button {
    border: none;
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
    background-color: transparent;
    color: inherit;
  }
}

.sending {
  opacity: 0.3;
  pointer-events: none;
}
.sent {
  svg {
    path {
      fill: green;
    }
  }
}

.filelist {
  padding: 5px;
  border-radius: 8px;
  .error_m {
    background-color: #ffe8e6;
    border: 1px solid #ff877d;
  }
  .file {
    margin: 5px 0;
    padding: 4px 8px 4px 4px;
    background: $color-primary-10;
    border-radius: 24px;
    font-size: 12px;
    display: inline-flex;
    gap: 5px;

    &.error {
      background-color: #ffe8e6;
      border: 1px solid #ff877d;
    }

    &:hover {
      background: $color-primary-10;
      .delete {
        display: block;
      }
      .icon {
        display: none;
      }
    }
    button {
      border: none;
      background-color: transparent;
      height: 15px;
      width: 18px;
      svg {
        height: 13px;
        width: auto;
      }
    }
    .delete {
      display: none;
    }
  }
}
button {
  cursor: pointer !important;
}
.search-results {
  flex: 1;
  max-height: 300px;
  overflow-y: auto;
  @include custom-scroll-bar();

  .results {
    .search-items {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .group-title {
      font-weight: bold;
      font-size: 12px;
      color: $color-primary-70;
      text-transform: capitalize;
    }
    .search-item {
      gap: 15px;
      align-items: center;
      border-radius: 50px;
      display: inline-flex;
      padding: 3px 12px;
      background-color: transparent;
      .item-pill {
        h1 {
          margin: 0;
          font-size: 14px;
          color: $color-primary-100;
          padding: 0;
        }
        h3 {
          margin: 0;
          padding: 0;
          font-size: 10px;
          color: $color-primary-70;
          line-height: 15px;
          font-weight: normal;
        }
      }
      &.active {
        background-color: $color-primary-10;
        svg {
          display: inline-block;
        }
      }
      &.selected {
        svg {
          display: block;
        }
      }
    }
  }
}
.header-line {
  margin-bottom: 5px;
  button {
    cursor: pointer;

    svg {
      color: $color-primary-100;
    }
  }
}
.new-message-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1150;
}
.disabled {
  opacity: 0.5;
}
.error_m {
  background-color: #fae3e3 !important;
  transition: background-color 1s ease;
}
.to-row {
  width: 100%;
  border-radius: 8px;
  display: grid;
  align-items: start;
  grid-template-columns: 90% 10%;
  .cc-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 100%;
    color: $color-primary-100;
  }
}
.new-message {
  background: $color-background;
  box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
    13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
  border-radius: 10px;
  border: 1px solid $color-primary-5;
  position: fixed;
  bottom: 16px;
  right: 20px;
  width: 570px;
  padding: 20px;
  font-family: $poppins;
  z-index: 1000;
  min-height: 400px;
  max-height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  .v-select {
    --vs-border-width: 0;

    width: 100% !important;
    font-size: 11px;
    border: 1px solid transparent !important;
    .vs__dropdown-toggle {
      border: 1px solid transparent !important;
    }
  }
  .vs__dropdown-toggle {
    border: 1px solid transparent !important;
  }

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    color: $color-primary-100;
  }
  label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.1px;
    text-align: center;
    color: $color-primary-70;
  }
  .add-from {
    background-color: transparent;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.1px;
    color: $color-primary-50;
  }
  .from-email {
    background-color: $color-primary-10;
    color: $color-primary-100;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin: 3px 7px;
    &:hover {
      svg {
        cursor: pointer;
      }
    }
    padding: 3px 5px 3px 10px;
    margin: 3px 7px;
    min-width: 50px;
    border-radius: 999px;
    display: flex !important;
    gap: 5px;
    &.pill_error {
      background-color: $color-alert;
      color: $color-primary-0;
      svg {
        path {
          fill: #fff !important;
        }
        fill: #fff !important;
      }
    }
    button {
      background-color: transparent;
      border: none;
      display: inline-block;
      text-transform: uppercase;
      height: 14px;
      width: 14px;
      position: relative;
      top: 2px;
      margin-right: 8px;
      text-align: center;
      font-size: 10px;
    }
  }
  .border-bottom {
    padding: 5px 0;
    border-bottom: 1px solid #8b8b8b30;
  }
  .flex-row {
    display: flex;
    align-items: center;
    label {
      position: relative;
      text-align: left;
    }
    &.flex-between {
      justify-content: space-between;
      button {
        background-color: transparent;
        border: none;
      }
    }
    &.flex-end {
      justify-content: flex-end;
    }
  }

  textarea,
  input {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    text-align: left;
    transition: background-color 1s ease;
    background-color: transparent;
    border: none;
    border-radius: 9px;
    padding: 8px;
    outline: none;
    color: $color-primary-100;
  }

  .full-height {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .recipients {
    overflow-y: scroll;
  }
  textarea {
    font-family: $poppins;
    flex: 1;
    resize: none;
    padding: 8px 0;
  }
  .cancel,
  .send {
    &:active {
      opacity: 0.9;
    }
  }
  .cancel {
    background-color: transparent;
    margin-right: 6px;
  }
  .send {
    &.active {
      opacity: 1;
      background-color: $color-primary-100 !important;
      svg {
        color: $color-primary-0;
      }
    }
    width: 30px;
    height: 30px;
    line-height: 12px;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    display: flex !important;
    background-color: $color-primary-70 !important;
    opacity: 0.4;
    border: 1px solid $color-primary-0;

    svg {
      color: $color-primary-100;
    }
  }
}
:focus {
  outline: none;
  background-color: $color-primary-100-30;
}
</style>
