import api from "@/api/api";
import store from "@/store";

export default class UserService {
  static async userDetails() {
    return await api().get("/api/v1/user/");
  }

  static async changeUserPassword(data) {
    const endpoint = `/api/v1/auth/change-password/`;
    return await api().post(endpoint, data);
  }

  static async deactivateUser() {
    const endpoint = `/api/v1/user/${store.state.authentication.user.id}/`;
    const payload = { is_active: false };
    return await api().patch(endpoint, payload);
  }

  static async collections() {
    const endpoint = "/api/v1/installedcollection/";
    return await api().get(endpoint);
  }

  static async getFlags() {
    return api()
      .get("/api/v1/settings/product-use/")
      .then(({ data }) => {
        return store.commit("onboardFlags", { flags: data.onboarding_meta });
      });
  }

  static async resetFlag() {
    return api()
      .post("/api/v1/settings/product-use/", {
        onboarding_meta: {},
      })
      .then(() => {
        UserService.getFlags();
      });
  }

  static async setFlags(params) {
    let current = store.state.flags.onboarding || {};
    current = { ...current, ...params };
    store.commit("onboardFlags", { flags: current });
    const key = Object.keys(params)[0];
    const value = params[key];
    return api(null, { "content-type": "application/json-patch+json" })
      .patch("/api/v1/settings/product-use/", [
        {
          op: "add",
          path: `/onboarding_meta/${key}`,
          value: value,
        },
      ])
      .then(() => {
        UserService.getFlags();
      });
  }

  static async setFlag({ name, value }) {
    const current = store.state.flags.onboarding || {};
    current[name] = value;
    store.commit("onboardFlags", { flags: current });
    return api()
      .post("/api/v1/settings/product-use/", {
        onboarding_meta: current,
      })
      .then(() => {
        UserService.getFlags();
      });
  }

  static async deleteUserAccount({ userId, params }) {
    return api().post(`/api/v1/user/${userId}/state/`, params);
  }
}
