<template>
  <ModalTemplate
    :show="visibleModals[params.id]"
    key="modal-import-processing"
    class="modal-import-processing"
    :width="512"
  >
    <template #header>
      <div class="modal-import-processing__header">
        <Component :is="icon" class="modal-import-processing__icon" />
        <h1>{{ title }}</h1>
      </div>
    </template>
    <template #body>
      <div>
        <p class="modal-import-processing__subheader">
          {{ subheader }}
        </p>
        <Tip
          type="info"
          class="modal-import-processing__tip"
          v-if="hasImportSucceeded !== false"
        >
          <div>
            <strong>Suggestion:</strong> Delete the import file from your
            computer to keep your information secure.
          </div>
        </Tip>
      </div>
    </template>
    <template #footer>
      <template v-if="hasImportSucceeded !== false">
        <Button type="secondary" @click="params.onImportMore()">
          Import More
        </Button>
        <Button @click="params.onDone()"> Done </Button>
      </template>
      <template v-else>
        <Button type="secondary" @click.native="params.onDone()">
          Cancel
        </Button>
        <Button @click="params.onImportMore()"> Start Over </Button>
      </template>
    </template>
  </ModalTemplate>
</template>

<script>
import Processing from "@/assets/icons/import/feedback/processing.svg";
import Finished from "@/assets/icons/import/feedback/finished.svg";
import Error from "@/assets/icons/import/feedback/error.svg";
import { Button, ModalTemplate } from "@/components";
import Tip from "@/components/ui/Tip";
import { mapState } from "vuex";
import {
  IMPORT_STATUS_FAILURE,
  IMPORT_STATUS_FINISHED,
} from "@/store/modules/accounts-importer/shared.js";

export default {
  components: {
    Processing,
    Finished,
    Error,
    ModalTemplate,
    Button,
    Tip,
  },
  props: ["params"],
  data() {
    return {
      hasImportSucceeded: null,
    };
  },
  watch: {
    importStatus: {
      immediate: true,
      handler(value) {
        if (this.hasImportSucceeded !== null) {
          return;
        }

        if (value === IMPORT_STATUS_FINISHED) {
          this.hasImportSucceeded = true;
        }

        if (value === IMPORT_STATUS_FAILURE) {
          this.hasImportSucceeded = false;
        }
      },
    },
  },
  computed: {
    ...mapState({
      modals: (state) => state.modal.modals,
      visibleModals: (state) => state.modal.visibleModals,
    }),
    importStatus() {
      return this.$store.getters["accountsImporter/getImportStatus"];
    },
    icon() {
      if (this.hasImportSucceeded === null) {
        return "Processing";
      }

      return this.hasImportSucceeded ? "Finished" : "Error";
    },
    title() {
      if (this.hasImportSucceeded === null) {
        return `Importing ${this.params.numberOfIdentities} ${
          this.params.numberOfIdentities > 1 ? "identities" : "identity"
        }...`;
      }

      return this.hasImportSucceeded
        ? "Your import is complete!"
        : "We couldn't import your file";
    },
    subheader() {
      if (this.hasImportSucceeded === null) {
        return "Most imports take about 5 minutes to complete. You’ll receive an email when your import is done.";
      }

      return this.hasImportSucceeded
        ? "Your import has been completed. If you’re finished, click “Done” or you can import another file."
        : "Sorry, we were unable to process your import. Please check your internet connection and file for any errors and try again.";
    },
  },
};
</script>

<style lang="scss">
.modal-import-processing {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__icon {
    margin-bottom: 16px;
    width: 72px;
    height: 72px;
  }

  & &__subheader {
    margin-bottom: 16px;
  }

  &__tip {
    margin: 24px 0 8px;
    font-weight: 400;

    strong {
      font-weight: 500;
    }
  }
}
</style>
