<template>
  <div
    class="ui-banner"
    :class="{
      'ui-banner--danger': danger,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "UiBanner",
  props: {
    danger: Boolean,
  },
};
</script>

<style lang="scss">
.ui-banner {
  width: 100%;
  height: 40px;
  background: $color-primary-100;
  color: $color-primary-0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  strong {
    font-weight: 600;
  }

  &--danger {
    background: $color-alert;
    color: $color-primary-100;
  }
}
</style>
