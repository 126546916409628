<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import store from "@/store";
import api from "@/api/api";
import {
  ChevronRight,
  KabobIcon,
  DeleteTrashIcon,
  CardIcon,
  BankIcon,
} from "@/assets/icons";
import UiSwitchInput from "@/components/UiSwitchInput";
onMounted(() => {
  window.addEventListener("focus", fundingCheck);
  getDefaultFundingSource();
  api()
    .get(`/api/v1/card/`)
    .then(({ data }) => {
      store.commit("fundingSourcesList", data.results);
    });
});
onUnmounted(() => {
  window.removeEventListener("focus", fundingCheck);
});
const cards = computed(() => {
  return store.state.cards.fundingSources;
});
const kycValidated = computed(() => {
  return store.state.authentication.user.cloaked_card_kyc_configured;
});
const AchDebitEnabled = computed(() => {
  return store.state.authentication.user.allow_ach_debit_credit;
});
function addACH() {
  api()
    .post(`/api/v1/card/vendor-flow/`, {
      flow: "ach",
    })
    .then(({ data }) => {
      window
        .open(
          data.flow_url,
          "",
          "resizable=1,width=500,height=580,top=80,left=50"
        )
        .focus();
    });
}
function addDebitCard() {
  api()
    .post(`/api/v1/card/vendor-flow/`, {
      flow: "debit_card",
    })
    .then(({ data }) => {
      window
        .open(
          data.flow_url,
          "",
          "resizable=1,width=500,height=580,top=80,left=50"
        )
        .focus();
    });
}
function addCreditCard() {
  api()
    .post(`/api/v1/card/vendor-flow/`, {
      flow: "credit_card",
    })
    .then(({ data }) => {
      window
        .open(
          data.flow_url,
          "",
          "resizable=1,width=500,height=580,top=80,left=50"
        )
        .focus();
    });
}
function fundingCheck() {
  api()
    .get(`/api/v1/card/`)
    .then(({ data }) => {
      store.commit("fundingSourcesList", data.results);
    });
}
function formatCard(value) {
  if (value) {
    return "•••• " + value;
  } else {
    return "•••• " + "####";
  }
}
const activeDropdown = ref(null);
function currentDropdown(value) {
  if (activeDropdown.value === value) {
    activeDropdown.value = null;
  } else {
    activeDropdown.value = value;
  }
}
function deleteFundingSource(value) {
  api()
    .delete(`/api/v1/card/${value}/?force=true`)
    .then(() => {
      activeDropdown.value = null;
      fundingCheck();
    })
    .catch((data) => {
      alert(data.response.data.detail);
    });
}
const defaultFundingSource = ref(null);
function getDefaultFundingSource() {
  api()
    .get(`/api/v1/card/primary`)
    .then((data) => {
      if (data.status === 200) {
        defaultFundingSource.value = data.data.id;
      } else {
        defaultFundingSource.value = null;
      }
    });
}
function setDefaultFundingSource(value) {
  api()
    .patch(`/api/v1/card/${value}/`, {
      primary: true,
    })
    .then(() => {
      getDefaultFundingSource();
    });
}
function removeDefaultFundingSource(value) {
  api()
    .patch(`/api/v1/card/${value}/`, {
      primary: false,
    })
    .then(() => {
      defaultFundingSource.value = null;
    });
}
function toggleDefaultFundingSource(value) {
  if (defaultFundingSource.value === value) {
    removeDefaultFundingSource(value);
  } else {
    setDefaultFundingSource(value);
  }
}
function getProviderType(value) {
  if (value === "stripe_flow_debit") {
    return "Debit";
  } else if (value === "stripe_flow_credit") {
    return "Credit";
  } else if (value === "plaid_stripe_flow_ach") {
    return "ACH";
  } else {
    return "Unknown";
  }
}
</script>

<template>
  <div class="funding-source" v-if="kycValidated">
    <div class="title" id="funding">
      <h1>Funding sources</h1>
      <p>
        Add a debit card to begin using Cloaked Cards. We use Stripe to connect
        your debit card to your Cloaked account. This will launch a new window.
      </p>
    </div>

    <div class="content">
      <div class="cards" v-if="cards">
        <div
          class="card"
          v-for="card in cards"
          :key="card.id"
          :class="`${card.card_brand}`"
        >
          <div class="icon"></div>

          <div class="content">
            <p>
              <span v-if="card.card_brand === 'mastercard'"
                >Mastercard ({{ getProviderType(card.provider) }})
                <i v-if="defaultFundingSource === card.id">Default</i></span
              >
              <span v-if="card.card_brand === 'visa'"
                >Visa ({{ getProviderType(card.provider) }})
                <i v-if="defaultFundingSource === card.id">Default</i></span
              >
              <span v-if="card.card_brand === 'unionpay'"
                >UnionPay ({{ getProviderType(card.provider) }})
                <i v-if="defaultFundingSource === card.id">Default</i></span
              >
              <span v-if="card.card_brand === 'discover'"
                >Discover ({{ getProviderType(card.provider) }})
                <i v-if="defaultFundingSource === card.id">Default</i></span
              >
            </p>
            <p class="card-number">{{ formatCard(card.pan_last_four) }}</p>
            <p v-if="false" class="auto-debit">Auto-debit enabled</p>

            <div
              class="card-dropdown"
              :class="{ active: activeDropdown === card.id }"
            >
              <div class="dropdown-icon" @click="currentDropdown(card.id)">
                <KabobIcon />
              </div>

              <div class="dropdown">
                <div class="content">
                  <div v-if="false" class="button disabled">
                    <div class="text">
                      <p>Auto-debit</p>
                      <p>Transactions debit as you spend</p>
                    </div>

                    <UiSwitchInput :value="true" />
                  </div>

                  <div class="button">
                    <div class="text">
                      <p>Set as default funding source</p>
                    </div>

                    <UiSwitchInput
                      :value="defaultFundingSource === card.id"
                      @change="toggleDefaultFundingSource(card.id)"
                    />
                  </div>

                  <div
                    class="button delete"
                    @click="deleteFundingSource(card.id)"
                  >
                    <div class="text">
                      <p>Remove account connection</p>
                    </div>

                    <DeleteTrashIcon />
                  </div>
                </div>

                <div class="background" @click="currentDropdown(null)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons">
        <h1 v-if="cards">Connect another</h1>

        <div
          class="button bank-account"
          @click="addACH()"
          v-if="AchDebitEnabled"
        >
          <div class="icon">
            <BankIcon />
          </div>

          <div class="text">
            <p>Bank account</p>
            <ChevronRight />
          </div>
        </div>

        <div
          class="button debit-card"
          @click="addDebitCard()"
          v-if="AchDebitEnabled"
        >
          <div class="icon">
            <CardIcon />
          </div>

          <div class="text">
            <p>Debit card</p>
            <ChevronRight />
          </div>
        </div>

        <div class="button credit-card" @click="addCreditCard()">
          <div class="icon">
            <CardIcon />
          </div>

          <div class="text">
            <p>Credit card</p>
            <ChevronRight />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.funding-source {
  padding-bottom: 80px;
  margin-top: 48px;
  .title {
    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      color: $color-primary-100;
    }
    p {
      margin-top: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
      color: $color-primary-70;
    }
  }
  .content {
    margin-top: 24px;
    .cards {
      width: 100%;
      .card {
        color: $color-primary-100;
        border-bottom: 1px solid $color-primary-10;
        padding: 16px 8px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &:last-of-type {
          border-bottom-width: 0;
        }
        .icon {
          width: 40px;
          height: 40px;
          border: 1px solid $color-primary-10;
          background: $white;
          border-radius: 45px;
          margin-right: 16px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          margin-top: 8px;
        }
        .content {
          width: calc(100% - 68px);
          margin-top: 6px;
          position: relative;
          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.2px;
            i {
              font-weight: 600;
              font-size: 10px;
              line-height: 15px;
              font-style: normal;
              color: $color-primary-0;
              background-color: $color-primary-100;
              padding: 0 10px;
              border-radius: 20px;
              display: inline-block;
              margin-left: 4px;
            }
            &.card-number {
              color: $color-primary-50;
            }
            &.auto-debit {
              font-weight: 600;
              font-size: 10px;
              line-height: 15px;
              color: $color-primary-100;
              &.disabled {
                opacity: 0.4;
              }
            }
          }
          .card-dropdown {
            &.active {
              .dropdown-icon {
                &:before {
                  width: 36px;
                  height: 36px;
                }
              }
              .dropdown {
                opacity: 1;
                visibility: visible;
                top: 32px;
              }
            }
            .dropdown-icon {
              position: absolute;
              top: 50%;
              @include transform(translateY(-50%));
              right: -8px;
              z-index: 1;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $color-primary-100;
              &:hover {
                cursor: pointer;
                &:before {
                  width: 36px;
                  height: 36px;
                }
              }
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
                @include transition(all 0.4s ease);
                background-color: $color-primary-10;
                z-index: -1;
                border-radius: 50%;
                width: 0px;
                height: 0px;
              }
            }
            .dropdown {
              position: absolute;
              top: 30px;
              right: -34px;
              width: 360px;
              z-index: 2;
              opacity: 0;
              visibility: hidden;
              @include transition(all 0.4s ease);
              .content {
                width: 100%;
                border: 1px solid rgba(25, 30, 35, 0.1);
                box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.25);
                border-radius: 15px;
                padding: 20px 16px;
                background-color: $color-background;
                position: relative;
                z-index: 2;
                .button {
                  color: $color-primary-100;
                  margin-top: 24px;
                  position: relative;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0 4px;
                  &:first-of-type {
                    margin-top: 0;
                  }
                  &.disabled {
                    opacity: 0.2;
                    pointer-events: none;
                  }
                  p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: $color-primary-50;
                    &:first-of-type {
                      font-weight: 600;
                      font-size: 12px;
                      line-height: 18px;
                      color: $color-primary-100;
                    }
                  }
                  &.delete {
                    p {
                      color: $color-alert;
                    }
                    svg {
                      width: 36px;
                      height: 36px;
                      display: inline-block;
                      padding: 6px;
                      color: $color-alert;
                    }
                    &:hover {
                      cursor: pointer;
                    }
                  }
                }
              }
              .background {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
              }
            }
          }
          .number {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            span {
              font-weight: 500;
              font-size: 13px;
              line-height: 21px;
              color: $color-primary-100;
              display: flex;
              &.dots {
                margin-right: 10px;
                i {
                  width: 4px;
                  height: 4px;
                  background-color: $color-primary-50;
                  border-radius: 4px;
                  display: inline-block;
                  margin-right: 2px;
                  &:last-of-type {
                    margin-right: 0;
                  }
                }
              }
              &.verified {
                background-color: $color-success;
                color: $color-primary-0;
                padding: 2px 6px;
                border-radius: 4px;
                margin-left: 10px;
              }
            }
          }
          .limit {
            margin-top: 3px;
            span {
              display: block;
              font-weight: 500;
              font-size: 13px;
              line-height: 21px;
              color: $color-primary-100;
            }
          }
          .expiry {
            margin-top: 3px;
            span {
              display: block;
              font-weight: 500;
              font-size: 13px;
              line-height: 21px;
              color: $color-primary-100;
            }
          }
        }
        &.mastercard {
          .icon {
            background-image: url("https://asset.brandfetch.io/idFw8DodCr/idKRU3EJKM.jpeg?updated=1667560171163");
          }
        }
        &.visa {
          .icon {
            background-image: url("https://asset.brandfetch.io/idhem73aId/idoOleMCqQ.png?updated=1679062241241");
          }
        }
        &.unionpay {
          .icon {
            background-image: url("https://asset.brandfetch.io/idmUZnnx1B/id0gZkqbXl.png");
          }
        }
        &.discover {
          .icon {
            background-image: url("https://asset.brandfetch.io/idyXDiKxGF/id0t2Nwffx.jpeg?updated=1667576052215");
          }
        }

        &.amex {
          .icon {
            background-image: url("https://asset.brandfetch.io/idaYeBHZgd/idCi8OKryB.png?updated=1700420925086");
          }
        }
      }
    }
    .buttons {
      width: 100%;
      h1 {
        margin: 16px 0;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: $color-primary-100;
      }
      .button {
        border: 0;
        padding: 16px 8px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        text-align: left;
        position: relative;
        border-bottom: 1px solid $color-primary-10;
        .icon {
          width: 48px;
          height: 48px;
          background-color: $color-primary-5;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 24px;
            height: 24px;
            color: $white;
          }
        }
        .text {
          width: calc(100% - 64px);
          margin-left: 16px;
          position: relative;
          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.2px;
            color: $color-primary-100;
          }
          svg {
            position: absolute;
            right: 8px;
            top: 50%;
            @include transform(translateY(-50%));
            color: $color-primary-100;
          }
        }
        &.disabled {
          pointer-events: none;
          .icon,
          .text {
            opacity: 0.2;
          }
        }
        &:hover {
          cursor: pointer;
        }
        &.bank-account {
          .icon {
            background-color: $color-brand-5-100;
          }
        }
        &.debit-card {
          .icon {
            background-color: $color-brand-4-100;
          }
        }

        &.credit-card {
          .icon {
            background-color: #f0536b;
          }
        }
      }
    }
  }
}
</style>
