<script setup>
import CustomFieldFormWrapper from "@/components/cloakDetails/CustomFields/CustomFieldForm/CustomFieldFormWrapper.vue";
import BorderInputSelect from "@/components/BorderInputSelect.vue";
import ButtonAdd from "@/components/ButtonAdd.vue";
import { getCopyValue } from "@/components/cloakDetails/CustomFields/utils";
import { getDefaultLabel } from "@/components/cloakDetails/CustomFields/CustomFieldForm/utils";

const getAddressStringValue = (addressObject) => {
  return getCopyValue("address", addressObject);
};
</script>

<template>
  <CustomFieldFormWrapper
    v-bind="$attrs"
    v-on="$listeners"
    class="custom-field-existing-address"
  >
    <BorderInputSelect
      label="Choose existing address"
      placeholder="Select"
      :options="$attrs.existingAddresses"
      :value="getAddressStringValue($attrs.value.value)"
      dropdown-width="300"
      @input="
        $emit('input', {
          ...$attrs.value,
          label: getDefaultLabel('address'),
          value: { ...$event },
        })
      "
    >
      <template #selected-value="{ value }">
        {{ value }}
      </template>
      <template #option="{ option }">
        <span class="custom-field-existing-address__option">
          {{ getAddressStringValue(option) }}
        </span>
      </template>
    </BorderInputSelect>
    <ButtonAdd label="Or enter new address" @click="$emit('add-new')" />
  </CustomFieldFormWrapper>
</template>

<style lang="scss">
@import "@/assets/scss/recursive/_mixins.scss";

.custom-field-existing-address {
  &__option {
    @include line-clamp(1);
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.1px;
    color: $color-primary-50;
  }
}
</style>
