var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _c("div", { staticClass: "button" }, [
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _setup.emit("toggleVisible")
              },
            },
          },
          [
            _vm._v(" Continue "),
            _c(_setup.InlineSvg, { attrs: { name: "arrow-right" } }),
          ],
          1
        ),
      ]),
      _vm._m(3),
    ]),
    _c("div", { staticClass: "cards" }, [
      _c(
        "div",
        { staticClass: "card-group" },
        _vm._l(4, function (index) {
          return _c(
            "span",
            { key: index },
            [_c(_setup.InlineSvg, { attrs: { name: "cloaked-icon" } })],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("h1", [_vm._v("Welcome to Cloaked Pay")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text" }, [
      _c("p", [
        _vm._v(
          " Protect your payments using Cloaked Pay. Keep your current cards’ airline miles, cashback rewards, or other perks, while protecting your privacy, reducing fraud, and preventing identity theft. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text" }, [
      _c("h1", [_vm._v("Cloaked Pay perks")]),
      _c("ul", [
        _c("li", [_vm._v("0% APR, no fees")]),
        _c("li", [_vm._v("Unlimited virtual cards")]),
        _c("li", [_vm._v("User-controlled self-destruct timer")]),
        _c("li", [_vm._v("Connect your bank account, debit, or credit cards")]),
        _c("li", [_vm._v("User controls and merchant locking")]),
        _c("li", [_vm._v("Instant card issuance and virtual card numbers")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text terms" }, [
      _c("p", [
        _vm._v(
          " Cloaked Pay is available to eligible U.S.-verified customers in certain states. Payment methods for Cloaked Pay may vary by jurisdiction. Cloaked Pay is issued by Patriot Bank, N.A., pursuant to licenses from Mastercard® International Incorporated. The card can be used everywhere Mastercard is accepted. Mastercard is a registered trademark of Mastercard International. For more information, please take a look at the "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://help.cloaked.app/hc/en-us",
              target: "_blank",
            },
          },
          [_vm._v("Frequently Asked Questions")]
        ),
        _vm._v(", "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.cloaked.app/terms-of-service",
              target: "_blank",
            },
          },
          [_vm._v("Terms of Service")]
        ),
        _vm._v(" and "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.cloaked.app/privacy-policy",
              target: "_blank",
            },
          },
          [_vm._v("Privacy Policy")]
        ),
        _vm._v(". "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }