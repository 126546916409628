var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal-container",
      class: {
        active: _vm.show,
        "modal-container--enter-state": _vm.isEnterState,
        "modal-container--leave-state": _vm.isLeaveState,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "content",
          class: { large: _vm.large, fullHeight: _vm.fullHeight },
          style: _vm.style,
        },
        [
          this.$slots.header
            ? _c(
                "header",
                {
                  staticClass: "modal-header",
                  class: {
                    "modal-header--bordered": _vm.headerBorder,
                    "modal-header--no-padding": _vm.withoutHeaderPadding,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-header__title" },
                    [
                      _vm._t("header", function () {
                        return [_vm._v(" This is the default title! ")]
                      }),
                    ],
                    2
                  ),
                  _vm.showCloseInHeader
                    ? _c(
                        "div",
                        { staticClass: "close", on: { click: _vm.close } },
                        [_c("ModalX")],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          this.$slots.body
            ? _c(
                "section",
                { ref: "modal_body", staticClass: "modal-body" },
                [_vm._t("body")],
                2
              )
            : _vm._e(),
          this.$slots.input
            ? _c(
                "section",
                { ref: "modal_input", staticClass: "modal-input" },
                [_vm._t("input")],
                2
              )
            : _vm._e(),
          this.$slots.footer
            ? _c(
                "footer",
                {
                  staticClass: "modal-footer",
                  class: {
                    "modal-footer--bordered": _vm.footerBorder,
                  },
                },
                [
                  _vm._t("footer", function () {
                    return [_vm._v(" This is the default footer! ")]
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "background", on: { click: _vm.close } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }