<script setup>
import MenuBlock from "./MenuBlock.vue";
import MenuItem from "./MenuItem.vue";
</script>

<template>
  <MenuBlock title="Identities">
    <MenuItem link="/" name="All identities" icon="blocks" />
    <MenuItem link="/favorites" name="Favorites" icon="favorite-filled" />
    <MenuItem link="/ignored" name="Ignored" icon="user-block" />
  </MenuBlock>
</template>
