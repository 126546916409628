<script setup>
import api from "@/api/api";
import store from "@/store";
import PlusIcon from "@/assets/icons/PlusIcon";
import CloakCard from "@/components/global/CloakCard.vue";
import { coordinateOverlaps } from "@/scripts/multiselect";
import InfiniteTrigger from "@/components/global/InfiniteTrigger";
import MultiSelectToolbar from "@/routes/your-cloaks/MultiSelectToolbar";

import { HAS_CREATED_SAMPLE_IDENTITY } from "@/scripts/userFlags";
import UserService from "@/api/actions/user-service";

import UiTooltip from "@/components/ui/ui-tooltip";

import { min as lodashMin, max as lodashMax } from "lodash-es";

import {
  defineProps,
  watch,
  defineEmits,
  onMounted,
  onBeforeUnmount,
  nextTick,
  reactive,
  computed,
  ref,
} from "vue";

import { useToast } from "@/hooks";
const toast = useToast();

let interval;

const props = defineProps({
  hoverText: String,
  openAddCloaksModal: Function,
  showModalOnHover: Boolean,
  identityList: Array,
  identifierPriority: String,
});

const emit = defineEmits(["multiselect"]);

const state = reactive({
  dragStart: false,
  dragEnd: false,
  shiftKey: false,
  selected: [],
  loading: false,
  keysPressed: [],
});

const isMultiselect = computed(() => {
  return state.shiftKey || state.selected.length > 0;
});

const ignoreDisplay = computed(() => {
  let status;
  for (const cloakId of state.selected) {
    const cloak = props.identityList.find((c) => c.id === cloakId);

    if (cloak) {
      const currCloakStatus = cloak.muted ? "Unignore" : "Ignore";
      if (!status) {
        status = currCloakStatus;
      } else if (currCloakStatus !== status) {
        // if selection has mixed muted status, behvaior should mute all
        status = "Ignore";
        break;
      }
    }
  }
  return status || "";
});

const hasCreatedSampleIdentity = computed(() => {
  return store.getters.getFlag(HAS_CREATED_SAMPLE_IDENTITY);
});

watch(
  () => isMultiselect.value,
  (val) => {
    store.commit("setIsMultiSelect", val);
  }
);

watch(
  () => store.getters.identities,
  (value) => {
    if (value.length > 0 && state.loading) {
      state.loading = false;
    }
  }
);

watch(
  () => state.selected,
  (value) => {
    emit("multiselect", value.length > 0);
  }
);

onMounted(() => {
  document.body.addEventListener("keydown", setKeys);
  document.body.addEventListener("keyup", unsetShift);
  window.addEventListener("blur", unsetShift);

  window.Appcues?.on("flow_attempted", ({ flowId }) => {
    if (
      flowId === "40db6122-aa6c-4c97-962e-a6b4216056a7" &&
      !hasCreatedSampleIdentity.value &&
      !store.state.localdb.db_cloaks.find(
        (cloak) => cloak.nickname === "Sample Identity"
      )
    ) {
      UserService.setFlags({ [HAS_CREATED_SAMPLE_IDENTITY]: true });
      api()
        .post("/api/v1/cloaked/identity/", {
          website_url: "https://your.cloaked.app",
          app_ref: "cloaked.id",
          nickname: "Sample Identity",
          user: `${global.ENV.VUE_APP_API}api/v1/user/${store.state.authentication.user.id}/`,
        })
        .then((cloak) => {
          store.dispatch("updateCloaks", [cloak.data]);
          selectIdentity(cloak.data);
          window.Appcues?.off("flow_attempted");
        });
    }
  });
});

onBeforeUnmount(() => {
  document.body.removeEventListener("keydown", setKeys);
  document.body.removeEventListener("keyup", unsetShift);
  window.removeEventListener("blur", unsetShift);
  if (interval) {
    clearInterval(interval);
  }
});

function startDrag(event) {
  state.dragStart = event;
}

function onDrag(event) {
  if (state.dragStart) {
    const top = Math.min(state.dragStart.clientY, event.clientY);
    const bottom = Math.max(state.dragStart.clientY, event.clientY);
    const left = Math.min(state.dragStart.clientX, event.clientX);
    const right = Math.max(state.dragStart.clientX, event.clientX);
    const height = bottom - top;
    const width = right - left;
    state.dragEnd = {
      top,
      left,
      right,
      bottom,
      height,
      width,
    };
    const items = document.getElementsByClassName("item");
    let selected = [];
    if (state.shiftKey || (height < 10 && width < 10)) {
      selected = [...state.selected];
    }
    for (let item of items) {
      const pos = item.getBoundingClientRect();
      if (
        coordinateOverlaps(
          {
            left,
            top,
            right,
            bottom,
            width,
            height,
          },
          {
            left: pos.x,
            top: pos.y,
            right: pos.x + pos.width,
            bottom: pos.y + pos.height,
            width: pos.width,
            height: pos.height,
          }
        )
      ) {
        selected = [...selected, parseInt(item.getAttribute("id"), 10)].filter(
          (i) => i
        );
      }
    }
    const diff1 = state.selected.filter((x) => !selected.includes(x));
    const diff2 = selected.filter((x) => !state.selected.includes(x));
    if (diff1.length > 0 || diff2.length > 0) {
      nextTick(() => {
        state.selected = selected;
      });
    }
  }
}

function endDrag() {
  state.dragStart = null;
  state.dragEnd = null;
}

function setKeys(event) {
  if (isMultiselect.value && event?.key?.toLowerCase() === "escape") {
    setTimeout(() => {
      state.selected = [];
    }, 200);
  } else {
    state.keysPressed = [...state.keysPressed, event.key];
  }

  if (state.keysPressed.length > 1) {
    // NOTE: some key combinations do not trigger keyup event
    // if user presses any key combo we assume they are no longer in multi-select mode
    unsetShift();
  } else {
    state.shiftKey = event.shiftKey;
  }
}

function unsetShift() {
  state.keysPressed = [];
  state.shiftKey = false;
}

function handleClick(event) {
  event.stopPropagation();
}

function move({ category, identity }) {
  if (!isSelected(identity)) {
    toggleSelect(identity);
  }
  nextTick(() => {
    transferList(category);
  });
}

function toggleList() {
  if (state.selected.length > 0) {
    state.selected = [];
  } else {
    state.selected = props.identityList.map((d) => d.id);
  }
}

function transferList(cat) {
  const url = `/api/v1/category/${cat.id}/identity/?remove=true`;
  const payload = {
    identity_ids: [...new Set(state.selected)],
  };
  api()
    .post(url, payload)
    .then(() => {
      state.selected = [];
    });
}

function unselectAll() {
  state.selected = [];
}

function selectAll() {
  state.selected = props.identityList.map((d) => d.id);
}

function ignoreList() {
  const mute = true;
  refresh(state.selected, { muted: mute });
  api()
    .post("api/v1/cloaked/identity/mute/", {
      mute,
      identity_ids: [...new Set(state.selected)],
    })
    .then(() => {
      const phrase =
        state.selected.length > 1 ? "Identities Ignored" : "Identity Ignored";
      toast.success(phrase);
      state.selected = [];
    });
}

function unignoreList() {
  const mute = false;
  refresh(state.selected, { muted: mute });
  api()
    .post("api/v1/cloaked/identity/mute/", {
      mute,
      identity_ids: [...new Set(state.selected)],
    })
    .then(() => {
      const phrase =
        state.selected.length > 1
          ? "Identities Unignored"
          : "Identity Unignored";
      toast.success(phrase);
    });
}

function deleteList() {
  if (state.selected.length === 1) {
    const identity = props.identityList.find((f) => f.id === state.selected[0]);
    if (identity) {
      const name = identity.nickname || identity.app_ref;

      return store.dispatch("openModal", {
        header: `Delete ${name}?`,
        paragraphs: [
          `Deleting this cloak means that ${name} will no longer be able to contact you unless you give them new contact information.`,
          "It also means that we won’t be able to help you sign in to any account associated with that information.",
        ],
        closeAfterOnClick: true,
        button: {
          text: "Yes, delete",
          danger: true,
          onClick: triggerDelete,
        },
      });
    }
  } else {
    return store.dispatch("openModal", {
      header: "Are you sure you want to delete all selected cloaks?",
      paragraphs: [
        "Deleting these cloaks means that they will no longer be able to contact you unless you give them new contact information.",
        "It also means that we won’t be able to help you sign in to any account associated with the cloaks.",
      ],
      closeAfterOnClick: true,
      button: {
        text: "Yes, delete",
        danger: true,
        onClick: triggerDelete,
      },
    });
  }
}

function triggerDelete() {
  store.dispatch("deleteCloakFromLocalDB", state.selected);
  return api()
    .delete("api/v1/cloaked/identity/delete/", {
      data: {
        identity_ids: [...new Set(state.selected)],
      },
    })
    .then(() => {
      store.dispatch("recentlyImported/fetch", true);
      state.selected = [];
    })
    .catch(() => {
      toast.error("Error deleting your identity.");
    });
}

function toggleSelect(identity, off) {
  if (state.shiftKey) {
    const range = props.identityList
      .map((a, i) => {
        if (state.selected.findIndex((b) => b === a.id) !== -1) {
          return i;
        }
        return -1;
      })
      .filter((a) => a !== -1);
    const currentIndex = props.identityList.findIndex(
      (cloak) => cloak.id === identity.id
    );
    range.push(currentIndex);
    const min = lodashMin(range);
    const max = lodashMax(range);
    const filtered = props.identityList
      .filter((a, b) => {
        return b >= min && b <= max;
      })
      .map((d) => d.id);
    state.selected = [...state.selected, ...filtered];
  } else {
    if (off || isSelected(identity)) {
      state.selected = [...state.selected].filter((d) => d !== identity.id);
    } else {
      state.selected = [...state.selected, identity.id];
    }
  }
}

function isSelected(identity) {
  return state.selected.includes(identity?.id);
}

function openModal() {
  if (props.openAddCloaksModal) {
    return props.openAddCloaksModal();
  }
  store.dispatch("openCloakCreate");
}

function selectIdentity(identity) {
  if (isMultiselect.value || state.shiftKey) {
    toggleSelect(identity);
  } else {
    store.dispatch("openCloakDetails", { cloak: identity });
  }
}

function loadMore($state) {
  emit("loadNextPage", $state);
}

function refresh(identities, payload) {
  const updated = identities.map((id) => {
    const find = props.identityList.find((f) => f.id === id);
    return {
      ...find,
      ...payload,
    };
  });
  store.dispatch("updateCloaks", updated);
}

const isLoading = ref(true);
</script>

<template>
  <div
    class="drag-container"
    :class="{ dragging: state.dragStart }"
    @mousedown="startDrag"
    @mouseup="endDrag"
    @mouseleave="endDrag"
    @mousemove="onDrag"
  >
    <div
      class="dragArea"
      v-if="state.dragEnd"
      :style="`left: ${state.dragEnd.left}px; top: ${state.dragEnd.top}px; width: ${state.dragEnd.width}px; height: ${state.dragEnd.height}px; `"
    ></div>
    <MultiSelectToolbar
      v-if="props.identityList.length > 0"
      :isMultiselect="isMultiselect"
      :identityList="props.identityList"
      :selected="state.selected"
      :ignoreDisplay="ignoreDisplay"
      @select="toggleList"
      @ignore="ignoreList"
      @unignore="unignoreList"
      @delete="deleteList"
      @transferList="transferList"
      @auto-change="$store.dispatch('autoChange/autoChangeIds', $event)"
    />

    <slot name="no-results" v-if="!isLoading && identityList.length === 0" />
    <div class="items">
      <UiTooltip
        v-if="showModalOnHover"
        :title="hoverText"
        align-x="right"
        offset-x="10"
        offset-y="-60"
      >
        <button
          aria-id="AddCloaktoCategory"
          id="add-new-cloak-button"
          class="add_new"
          @click="openModal"
        >
          <div class="plus">
            <PlusIcon height="19" width="19" />
          </div>
        </button>
      </UiTooltip>

      <div
        class="item"
        v-for="(identity, id_index) in props.identityList"
        :id="identity.id"
        @mousedown="handleClick"
        :key="`${identity.id}-${id_index}`"
      >
        <CloakCard
          :aria-id="`CloakCardIdentity${identity?.id || ''}`"
          :identity="identity"
          :id_index="id_index"
          :noSelect="identity.id === -1"
          :selected="isSelected(identity) || identity.id === -1"
          :isMultiselect="isMultiselect"
          :count="state.selected.length"
          :with-right-click="true"
          :identifierPriority="props.identifierPriority"
          :ignoreDisplay="ignoreDisplay"
          @refresh="refresh"
          @ignore="ignoreList"
          @unignore="unignoreList"
          @delete="deleteList"
          @select="toggleSelect(identity)"
          @move="move"
          @unselectAll="unselectAll"
          @unselect="toggleSelect(identity, true)"
          @selectAll="selectAll"
          @click="selectIdentity(identity)"
        />
      </div>
      <InfiniteTrigger
        ref="infinite"
        @infinite="loadMore"
        @loading="isLoading = $event"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dragArea {
  z-index: 2000;
  position: fixed;
  background-color: $color-primary-100-30;
  border: 4px;
}

.drag-container {
  padding: 0 24px 24px 24px;
  width: 100%;
  user-select: none;
  height: calc(100vh - 165px);
}

.add_new {
  display: inline-flex;
  border: none;
  cursor: pointer;
  align-items: center;
  height: 240px;
  border-radius: 20px;
  justify-content: center;
  background: $color-primary-20;
  height: 240px;
  width: 100%;

  &:hover {
    background-color: $color-primary-10;
    @include transition(all 0.5s ease);
  }

  .plus {
    color: $color-primary-100;
  }
}

.title {
  margin-bottom: 28px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    width: 100%;
  }
  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 60px;
    /* identical to box height */

    // display: flex;
    align-items: center;
    letter-spacing: -1px;

    /* Primary/Light Mode/100 */

    color: $color-primary-100;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  h3 {
    display: block;
    font-family: $poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.408px;
    color: $color-primary-100-60;
  }
  .visible_button {
    cursor: pointer;
  }
  .visible_button svg {
    background-color: transparent;
    border: none;
    margin-left: 10px;
  }

  .link {
    font-family: $poppins;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: $color-primary-100;
    padding: 5px;
    border: 0;
    border-radius: 4px;
    background: transparent;

    &:hover {
      cursor: pointer;
      background-color: rgba(98, 159, 193, 0.1);
    }
  }
}

.items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));
  gap: 12px;
  padding-top: 24px;
}

.loading {
  background: $color-primary-100-5;
  border-radius: 8px;
  padding: 10px 20px;
  text-align: center;

  span {
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    width: 100%;
    text-align: center;
    letter-spacing: 0.1px;
    color: $color-primary-100;
  }
}

.showModalOnHover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 37px;
  font-size: 11px;
  color: $color-primary-5;
  position: absolute;
  margin-top: 170px;
  margin-left: 100px;
  border-radius: 6px;
  background-color: $color-primary-100-90;
  box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
    13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
  backdrop-filter: blur(100px);
  z-index: 1;
}

.app--visible-banner {
  .drag-container {
    // initial min-height minus banner height
    min-height: calc(100vh - 165px - 40px);
  }
}
</style>
