var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.AutoChangePage, {
    attrs: {
      items: _setup.autoChangeItems,
      verification: _setup.autoChangeVerificationProgress,
      updates: _setup.autoChangeUpdatesProgress,
      support: _setup.autoChangeSupport,
      "is-loading": _vm.$store.getters["autoChange/getIsFetching"],
      enabled: _vm.$store.getters["autoChange/getAutoChangeEnabled"],
    },
    on: {
      "auto-change": function ($event) {
        return _vm.$store.dispatch(
          "autoChange/autoChange",
          _setup.autoChangeRequest
        )
      },
      "stop-auto-change": function ($event) {
        return _vm.$store.dispatch("autoChange/autoChangeStop")
      },
      "start-over": _setup.onStartOver,
      "cell-click": _setup.onCellClick,
      "import-more": function ($event) {
        return _vm.$router.push({ name: "Import" })
      },
    },
    model: {
      value: _setup.autoChangeRequest,
      callback: function ($$v) {
        _setup.autoChangeRequest = $$v
      },
      expression: "autoChangeRequest",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }