var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "restore-wrapper" }, [
    _c("div", { staticClass: "restore-tables" }, [
      _c("h1", [_vm._v("Restore Identifiers")]),
      _c("div", [
        _c("h2", [_vm._v("Numbers")]),
        _c(
          "table",
          { staticClass: "restore-table" },
          [
            _vm._m(0),
            _vm._l(_vm.numbers, function (num) {
              return _c(
                "tr",
                {
                  key: num.id,
                  staticStyle: { cursor: "pointer" },
                  on: { click: () => _vm.setSelectedNumber(num.id) },
                },
                [
                  _c(
                    "td",
                    { staticStyle: { width: "50px" } },
                    [
                      _c("RadioButton", {
                        attrs: {
                          name: "select_number_" + num.id,
                          value: num.id,
                          checked: _vm.selectedNumber
                            ? _vm.selectedNumber.id === num.id
                            : false,
                        },
                        on: { update: _vm.setSelectedNumber },
                      }),
                    ],
                    1
                  ),
                  _c("td", [_vm._v(_vm._s(num.phone_number))]),
                  _c("td", [_vm._v(_vm._s(_vm.fmtDateTime(num.deleted_at)))]),
                  _c("td", [_vm._v(_vm._s(num.website && num.website.name))]),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _c("hr"),
      _c("div", [
        _c("h2", [_vm._v("Emails")]),
        _c(
          "table",
          { staticClass: "restore-table" },
          [
            _vm._m(1),
            _vm._l(_vm.emails, function (em) {
              return _c(
                "tr",
                {
                  key: em.id,
                  staticStyle: { cursor: "pointer" },
                  on: { click: () => _vm.setSelectedEmail(em.id) },
                },
                [
                  _c(
                    "td",
                    { staticStyle: { width: "50px" } },
                    [
                      _c("RadioButton", {
                        attrs: {
                          name: "select_email_" + em.id,
                          value: em.id,
                          checked: _vm.selectedEmail
                            ? _vm.selectedEmail.id === em.id
                            : false,
                        },
                        on: { update: _vm.setSelectedEmail },
                      }),
                    ],
                    1
                  ),
                  _c("td", [_vm._v(_vm._s(em.email))]),
                  _c("td", [_vm._v(_vm._s(_vm.fmtDateTime(em.deleted_at)))]),
                  _c("td", [_vm._v(_vm._s(em.website && em.website.name))]),
                ]
              )
            }),
          ],
          2
        ),
      ]),
    ]),
    _c("div", { staticClass: "restore-sidebar" }, [
      _c("div", { staticClass: "restore-title" }, [
        _c("h2", [_vm._v("Selected Number")]),
        _c(
          "p",
          [
            _c("ChevronRight"),
            _vm._v(
              _vm._s(_vm.selectedNumber && _vm.selectedNumber.phone_number) +
                " "
            ),
          ],
          1
        ),
      ]),
      _c("hr"),
      _c("div", { staticClass: "restore-title" }, [
        _c("h2", [_vm._v("Selected Email")]),
        _c(
          "p",
          [
            _c("ChevronRight"),
            _vm._v(_vm._s(_vm.selectedEmail && _vm.selectedEmail.email)),
          ],
          1
        ),
      ]),
      _c("hr"),
      _c("div", [
        _c(
          "span",
          [
            _c(
              "Button",
              {
                style: {
                  cursor: _vm.hasSelection ? "pointer" : "auto",
                },
                attrs: { disabled: !_vm.hasSelection },
                on: { click: _vm.restoreIdentifiers },
              },
              [_vm._v(" Restore to New Identity ")]
            ),
            _c(
              "Button",
              {
                style: {
                  cursor: _vm.hasSelection ? "pointer" : "auto",
                },
                attrs: { disabled: !_vm.hasSelection },
                on: { click: _vm.clearSelections },
              },
              [_vm._v(" Clear Selection ")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticStyle: { width: "50px" } }, [_vm._v("Selected")]),
      _c("th", [_vm._v("Number")]),
      _c("th", [_vm._v("Deleted")]),
      _c("th", [_vm._v("Website")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticStyle: { width: "50px" } }, [_vm._v("Selected")]),
      _c("th", [_vm._v("Email")]),
      _c("th", [_vm._v("Deleted")]),
      _c("th", [_vm._v("Website")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }