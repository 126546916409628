var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.isOldExtension
    ? _c(_setup.Upgrade)
    : _setup.share
    ? _c(_setup.Shared)
    : !_setup.authenticated || _setup.auth_route
    ? _c(_setup.Guest)
    : _c(_setup.User)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }