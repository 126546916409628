<template>
  <PreferencesPanel class="preference-dual-panel">
    <div v-if="!right_panel" class="your-pref-top">
      <PreferencesTitle>Basic information</PreferencesTitle>

      <ValueDisplay
        label="Name"
        :value="full_name"
        name="info_name"
        @click="go_right('info_name')"
      />

      <ValueDisplay
        label="Birthday"
        :value="birthday"
        name="info_birthday"
        @click="go_right('info_birthday')"
      />
      <ValueDisplay
        label="Gender"
        :value="capitalize(info_gender)"
        name="info_gender"
        @click="go_right('info_gender')"
      />
      <ValueDisplay
        label="Address"
        :value="address_display"
        name="address"
        @click="go_right('address')"
      />
      <PreferencesTitle v-if="showCCSection">Payment methods </PreferencesTitle>

      <SettingsParagraph v-if="showCCSection">
        Cloaked can securely store your stored payment information to
        automatically fill out forms via the extension or autofill on your
        mobile device.
      </SettingsParagraph>
      <div style="margin-top: 20px" v-if="showCCSection">
        <CreditCard
          v-for="(card, index) in creditCards"
          :key="index"
          :card="card"
          @refresh="refreshCards"
          @click="selectCard(card)"
        />
        <ValueDisplay
          label="Add a credit or debit card"
          :value="''"
          name="info_name"
          @click="selectCard(null)"
        />
      </div>
    </div>

    <Name
      v-if="right_panel === 'info_name'"
      @toggleBack="toggleBack"
      @update="on_update_value"
      :first_name="info_name"
      :last_name="info_last_name"
      :middle_name="info_middle_name"
      :id="info_id"
    />

    <Birthday
      v-if="right_panel === 'info_birthday'"
      @toggleBack="toggleBack"
      :birthday="info_birthday"
      :id="info_id"
    />

    <Gender
      v-if="right_panel === 'info_gender'"
      @toggleBack="toggleBack"
      :gender="info_gender"
      :id="info_id"
    />

    <Address
      v-if="right_panel === 'address'"
      @toggleBack="toggleBack"
      @update="on_update_value"
      :current="address"
      :id="info_id"
    />

    <div class="payment_section" v-if="showCCSection">
      <Payment
        v-if="right_panel === 'payment'"
        @toggleBack="toggleBack"
        @update="on_update_value"
        @refresh="refreshCards"
        @back="back"
        :current="address"
        :id="info_id"
        :selectedCard="selectedCard"
        :creditCards="creditCards"
      />
    </div>
  </PreferencesPanel>
</template>

<script>
import { capitalize } from "lodash-es";
import moment from "moment";

import api from "@/api/api";

import CreditCard from "./CreditCard";

import Name from "./YourInformation/Name";
import Gender from "./YourInformation/Gender";
import Address from "./YourInformation/Address";
import Payment from "./YourInformation/Payment";
import Birthday from "./YourInformation/Birthday";
import PreferencesTitle from "./PreferencesTitle";
import PreferencesPanel from "./PreferencesPanel";
import { SettingsParagraph } from "@/components";
import ValueDisplay from "@/components/ui/value-display";
import PersonalInfoService from "@/api/settings/personal-services";
import { AUTOFILL_CC_ENABLED } from "@/scripts/featureFlags";

export default {
  name: "YourInformation",
  props: ["isOpen"],
  components: {
    Name,
    Gender,
    Address,
    Payment,
    Birthday,
    CreditCard,
    ValueDisplay,
    PreferencesTitle,
    PreferencesPanel,
    SettingsParagraph,
  },
  data() {
    return {
      info_id: null,
      info_autofill: false,
      info_name: null,
      info_last_name: null,
      info_middle_name: null,
      info_birthday: null,
      info_email: null,
      info_phone: null,
      info_gender: null,
      autofill_street_address: null,
      autofill_unit: null,
      autofill_country: null,
      autofill_address_level1: null,
      autofill_address_level2: null,
      autofill_postal_code: null,
      showDate: false,
      emails: [],
      phones: [],
      creditCards: [],
      selectedCard: null,
    };
  },
  computed: {
    isV2User() {
      return this.$store.state.authentication.user.encryption_status === 3;
    },
    AUTOFILL_CC_ENABLED() {
      return AUTOFILL_CC_ENABLED;
    },
    showCCSection() {
      return this.AUTOFILL_CC_ENABLED && this.isV2User;
    },
    emailFieldValue() {
      const verified = this.emails.filter((item) => item.verified);

      if (!verified.length) {
        return "";
      }

      let field = verified[0].email;

      const otherEmails = verified.slice(1);

      if (otherEmails.length) {
        field = `${field} and ${otherEmails.length} more`;
      }

      return field;
    },

    phoneFieldValue() {
      const verified = this.phones.filter((item) => item.verified);

      if (!verified.length) {
        return "Add a phone number";
      }

      return verified[0].phone_number;
    },

    birthday() {
      if (this.info_birthday) {
        return moment(this.info_birthday, "").format("MM/DD/YYYY");
      }
      return null;
    },
    address() {
      return {
        autofill_street_address: this.autofill_street_address,
        autofill_unit: this.autofill_unit,
        autofill_country: this.autofill_country,
        autofill_address_level1: this.autofill_address_level1,
        autofill_address_level2: this.autofill_address_level2,
        autofill_postal_code: this.autofill_postal_code,
      };
    },
    address_display() {
      // NOTE: address level1 = state, level2=city
      const {
        autofill_street_address,
        autofill_unit,
        autofill_address_level1,
        autofill_address_level2,
        autofill_country,
        autofill_postal_code,
      } = this.address;
      let line = "";
      if (autofill_street_address) {
        line = autofill_street_address.replaceAll(/\s+/g, " ");
      }
      if (autofill_unit) {
        line += ` ${autofill_unit}`;
      }
      if (autofill_address_level1 && autofill_address_level2) {
        line += `, ${autofill_address_level2}, ${autofill_address_level1}`;
      } else if (autofill_address_level1 || autofill_address_level2) {
        line += ` ${
          autofill_address_level2
            ? autofill_address_level2
            : autofill_address_level1
        }`;
      }
      if (autofill_country) {
        line += ` ${autofill_country}`;
      }
      if (autofill_postal_code) {
        line += autofill_country
          ? `, ${autofill_postal_code}`
          : ` ${autofill_postal_code}`;
      }
      return line;
    },
    right_panel() {
      return this.$store.state.ui.preference.right;
    },
    right_panel_is_open() {
      return !!this.$store.state.ui.preference.right;
    },
    full_name() {
      const name = `${this.info_name || ""} ${this.info_last_name || ""}`
        .replaceAll(/\s+/g, " ")
        .trim();
      if (name.length > 0) {
        return name;
      }
      return null;
    },
  },
  mounted() {
    this.refreshCards();
  },
  methods: {
    selectCard(selectedCard) {
      this.selectedCard = selectedCard;
      this.$nextTick(() => this.go_right("payment"));
    },
    refreshCards() {
      this.loading = true;
      return PersonalInfoService.getAutofillCards()
        .then(({ data }) => {
          this.creditCards = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    back(message) {
      if (message) {
        this.$toast.success(message);
      }
      this.go_right("");
    },
    capitalize(value) {
      return capitalize(value);
    },
    on_update_value({ name, value }) {
      this[name] = value;
    },
    go_right(panel) {
      if (panel) {
        this.$store.commit("openPreference", {
          selected: "your_information",
          right: panel,
        });
      } else {
        this.$store.commit("openPreference", { selected: "your_information" });
      }
    },
    toggleBack(data) {
      if (data) {
        Object.keys(data).map((key) => {
          this[key] = data[key];
        });
      }
      this.$store.commit("openPreference", { selected: "your_information" });
      this.setAutofillData();
    },
    setAutofillData() {
      const result = this.$store.state.autofill;
      if (result) {
        this.info_id = result.id;
        this.info_autofill = !!result.autofill;
        this.info_name = result.first_name;
        this.info_last_name = result.last_name;
        this.info_birthday = result.dob ? result.dob : "";
        this.info_email = result.email;
        this.info_phone = result.phone_number;
        this.info_gender = result.sex;
        this.autofill_street_address = result.street_address;
        this.autofill_unit = result.unit;
        this.autofill_country = result.country;
        this.autofill_address_level1 = result.address_level1;
        this.autofill_address_level2 = result.address_level2;
        this.autofill_postal_code = result.postal_code;
      }
    },

    refreshEmails() {
      return api()
        .get("/api/v1/email/")
        .then((response) => {
          this.emails = response.data.results;
        });
    },

    refreshPhones() {
      return api()
        .get("/api/v1/phone/")
        .then((response) => {
          this.phones = response.data.results;
        });
    },
  },
  beforeMount() {
    const isAutofillEmpty =
      this.$store.state.autofill &&
      !Object.keys(this.$store.state.autofill).length;
    if (isAutofillEmpty) {
      PersonalInfoService.getInfo().then(() => {
        this.setAutofillData();
      });
    } else {
      this.setAutofillData();
    }
    this.refreshEmails();
    this.refreshPhones();
  },
};
</script>

<style lang="scss">
.your-pref-top {
  &__rows {
    margin-top: 24px;
  }
}

.payment_section {
  margin-bottom: 40px;
}
</style>
