var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "activity-call-display-voicemail" }, [
    _c("div", { staticClass: "vm-play" }, [
      _vm.playing && _vm.playing.id === _vm.recording.id
        ? _c(
            "button",
            {
              staticClass: "playing",
              on: {
                click: function ($event) {
                  return _vm.pauseVoicemail(_vm.recording)
                },
              },
            },
            [_c("ActivityPauseIcon")],
            1
          )
        : _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.playVoicemail(_vm.recording)
                },
              },
            },
            [_c("PlayVoicemailIconDark")],
            1
          ),
      _c("div", { staticClass: "vm-line" }, [
        _c("div", {
          staticClass: "vm-circle",
          style: `animation-duration: ${_vm.animationLengthSec(
            _vm.recording
          )}s`,
          attrs: { id: `vm-circle-${_vm.recording.id}` },
        }),
      ]),
      _c("div", { staticClass: "vm-duration" }, [
        _vm._v(" " + _vm._s(_vm.durationDisplay(_vm.recording)) + " "),
      ]),
    ]),
    _vm.recording.transcription_status === "available"
      ? _c("div", { staticClass: "vm-title" }, [
          _vm._v(
            " Transcription " +
              _vm._s(!_vm.recording.transcription_text ? "unavailable" : "") +
              " "
          ),
        ])
      : _vm._e(),
    _vm.recording.transcription_status === "available"
      ? _c(
          "div",
          {
            staticClass: "vm-body truncate",
            attrs: { id: `vm-body-${_vm.idx}` },
          },
          [_vm._v(" " + _vm._s(_vm.recording.transcription_text) + " ")]
        )
      : _vm._e(),
    _vm.recording.transcription_status === "available" &&
    _vm.isTruncated(_vm.idx)
      ? _c(
          "div",
          {
            staticClass: "vm-read-more",
            on: {
              click: function ($event) {
                return _vm.expandTranscript(_vm.idx)
              },
            },
          },
          [_vm._v(" Read more ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }