var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "referral-container" }, [
    _c(
      "header",
      [
        _c(
          "router-link",
          { staticClass: "logo", attrs: { to: "/" } },
          [
            _c(_setup.CloakedLogoLight, { staticClass: "hidden-dark" }),
            _c(_setup.CloakedLogoDark, { staticClass: "hidden-light" }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", [
      _c(
        "div",
        [
          _c("div", { staticClass: "referral-hero" }, [
            _c("h1", [_vm._v("Claim your invitation")]),
            _c("div", { staticClass: "input-group" }, [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Enter invitation code"),
              ]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.ui.referral_code,
                      expression: "ui.referral_code",
                    },
                  ],
                  attrs: { type: "text", placeholder: "example: w3xnai59NQr" },
                  domProps: { value: _setup.ui.referral_code },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_setup.ui, "referral_code", $event.target.value)
                    },
                  },
                }),
                _c("p", { staticClass: "small" }, [
                  _vm._v(
                    " By claiming this invitation, you agree to the claim terms. "
                  ),
                ]),
                _c(
                  "button",
                  {
                    attrs: { disabled: !_setup.ui.referral_code },
                    on: { click: _setup.activate },
                  },
                  [_vm._v(" Claim invite ")]
                ),
              ]),
            ]),
            _c("p", { staticClass: "big" }, [
              _vm._v(
                " By claiming this invitation, you agree to the claim terms. "
              ),
            ]),
            _setup.state.error
              ? _c("p", { staticClass: "error" }, [
                  _vm._v(" " + _vm._s(_setup.state.error) + " "),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "actions" }, [
            _c("ul", [
              _c("li", [
                _c("span", [_c(_setup.Referral30)], 1),
                _c("h3", [_vm._v("30 days free")]),
                _c("p", [
                  _vm._v(
                    " Your referral code includes a 30 day free trial to Cloaked Private Beta with no account limits "
                  ),
                ]),
              ]),
              _c("li", [
                _c("span", [_c(_setup.ReferralPrivacy)], 1),
                _c("h3", [_vm._v("Privacy by design")]),
                _c("p", [
                  _vm._v(
                    " Access to a fully encrypted, unique database where only you have access to use and view your data. "
                  ),
                ]),
              ]),
              _c("li", [
                _c("span", [_c(_setup.ReferralCard)], 1),
                _c("h3", [_vm._v("Cancel anytime")]),
                _c("p", [
                  _vm._v(
                    " A credit card is required to sign up for a Cloaked subscription, you can choose to cancel anytime. "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(_setup.ReferralCardsGraphic, { staticClass: "referral-graphic" }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }