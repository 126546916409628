<script setup>
import store from "@/store";
import MainNav from "@/components/mainNav";
import {
  HAS_PLUGIN,
  SHOWN_PLUGIN,
  HAS_COMPLETED_MOBILE_ONBOARDING,
} from "@/scripts/userFlags";
import RightPanel from "@/components/global/rightPanel";
import SearchModal from "@/components/global/search";
import { logout } from "@/scripts/actions/auth";
import { appBoot } from "@/scripts/actions/auth";
import AppBanners from "@/components/global/AppBanners";
import { STEP_DEMO } from "@/store/modules/onboarding";
import Loading from "@/components/ui/loading.vue";
import SecretKey from "@/routes/SecretKey";
import MountEvent from "@/components/MountEvent";
import { Modal, Toast, VirtualCardCreation } from "@/components";
import { dbMixin, getLatestTimestamp } from "@/mixins/db";
import { initiateEncryption } from "@/scripts/actions/encryption";
import { IMPORT_STATUS_FINISHED } from "@/store/modules/accounts-importer/shared";
import MigrationModalFlow from "@/components/modals/migrationFlow/MigrationModalFlow.vue";
import AutoChangeContainer from "@/components/auto-change/AutoChangeContainer.vue";
import { AutoChangeMessages, extensionMessaging } from "@/scripts/messaging";
import { loadSupport, toggleSupport } from "@/scripts/cloakHelpers";
import NavigationLeftPanel from "@/components/Navigation/NavigationLeftPanel.vue";
import NavigationTopBar from "@/components/Navigation/NavigationTopBar.vue";

import ExtensionService from "@/api/actions/extension-service";
import PersonalInfoService from "@/api/settings/personal-services";
import { useRoute } from "vue-router/composables";

import {
  onMounted,
  reactive,
  onBeforeUnmount,
  nextTick,
  computed,
  watch,
} from "vue";

const route = useRoute();

onMounted(() => {
  const url = new URL(window.location.href);
  if (url.searchParams.get("isExtensionCommsDummy")) {
    return;
  }
  pluginListener();
  checkBrowserSupport().then(() => {
    authCheck();
  });
  window.addEventListener("logout", logoutListen);

  extensionMessaging.addListener(({ type }) => {
    if (type === AutoChangeMessages.AUTO_CHANGE_FINISHED) {
      updateIdentities();
    }
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("logout", logoutListen);
});

const state = reactive({
  exitDownload: false,
  bootReady: false,
  offset: "",
  mobileDevice: false,
});

const importStatus = computed(() => {
  return store.state.accountsImporter.importStatus;
});
const overflow = computed(() => {
  return store.state.ui.bodyOverflowHidden;
});
const hasVisibleBanner = computed(() => {
  return store.state.hasVisibleBanner;
});

const dbLoaded = computed(() => {
  return store.state.localdb.dbLoaded;
});

const rightPanelState = computed(() => {
  return store.getters["getRightPanelStatus"];
});

const hasPlugin = computed(() => {
  return store.getters["browser/pluginDetected"];
});

const showDownload = computed(() => {
  return !hasPlugin.value && !shownPlugin.value;
});

const authenticated = computed(() => {
  return store.state.account.access_token;
});

const extensionInstalled = computed(() => {
  return store.getters.getFlag(HAS_PLUGIN) || hasPlugin.value;
});

const hasCompletedMobileOnboarding = computed(() => {
  return store.getters.getFlag(HAS_COMPLETED_MOBILE_ONBOARDING);
});

const cardsEnabled = computed(() => {
  return store.state.authentication.user.cloaked_card_enabled;
});

const shouldClipBody = computed(() => {
  return store.state.ui.open_dialog;
});

const shownPlugin = computed(() => {
  return store.getters.getFlag(SHOWN_PLUGIN);
});

const shouldGetKeys = computed(() => {
  const userIsV2User = store.state.authentication.user.encryption_status === 3;
  if (userIsV2User) {
    return false;
  } else if (
    store.state.authentication.user &&
    store.state.authentication.user.flags?.encryption &&
    (!store.state.encryption || !store.state.encryption.secret_key)
  ) {
    return true;
  }
  return false;
});

const shouldShowEncryptionMigrationModal = computed(() => {
  const userIsV2User =
    store.state.authentication?.user?.encryption_status === 3;
  if (userIsV2User) {
    return false;
  } else if (store.state.authentication?.user?.flags?.migrate_enc_v2) {
    return true;
  }
  return false;
});

const userEncryptionVersion = computed(() => {
  return store.state.authentication?.user?.encryption_status;
});

const pluginId = computed(() => {
  if (window.ENV.VUE_APP_EXTENSION_ID !== undefined) {
    return window.ENV.VUE_APP_EXTENSION_ID;
  }
  return "oppdbdefikkkihgbliidckokhgcmmiga";
});

watch(
  () => rightPanelState.value,
  (value) => {
    if (value === true) {
      document.body.classList.add("active-right-panel");
    } else {
      document.body.classList.remove("active-right-panel");
    }
  }
);

watch(
  () => showDownload.value,
  (value) => {
    if (!value) {
      setTimeout(() => {
        state.exitDownload = true;
      }, 5000);
    }
  }
);

watch(
  () => authenticated.value,
  (value) => {
    if (!value) {
      logout();
    }
  }
);

watch(
  () => shouldClipBody.value,
  (value) => {
    if (value) {
      state.offset = window.scrollY.toString();
    } else {
      const reset_top = parseInt(state.offset, 10);
      state.offset = 0;
      window.scrollTo(0, reset_top);
      setTimeout(() => {
        window.scrollTo(0, reset_top);
      }, 3);
    }
  }
);

watch(
  () => overflow.value,
  (value) => {
    if (value === true) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }
);

watch(
  () => importStatus.value,
  (newValue) => {
    if (newValue === IMPORT_STATUS_FINISHED) {
      updateIdentities();
    }
  }
);

function logoutListen() {
  state.bootReady = false;
  store.dispatch("logout");
}

function listenForCloaks() {
  setInterval(updateIdentities, 30 * 1000);
  window.addEventListener("cloak:identities", updateIdentities);
}
async function updateIdentities() {
  let url = "/api/v1/search/identity/";
  const timestamp = await getLatestTimestamp();
  // NOTE: if no timestamp, then db is empty - fetch all cloaks
  if (timestamp) {
    url += `?updated_at__gt=${timestamp}`;
  }
  dbMixin.methods.frontLoadCloaks(false, url);
}
async function appReady() {
  await initiateEncryption();
  state.bootReady = true;
  listenForCloaks();
  loadSupport().then(() => {
    if (route.meta.support) {
      setTimeout(() => {
        toggleSupport();
      }, 1000);
    }
  });
  window.Appcues?.identify(store.state.authentication.user.appcues_uuid, {
    hasCompletedMobileOnboarding: hasCompletedMobileOnboarding.value || false,
  });
}

function pluginListener() {
  const checkPlugin = (message) => {
    if (message.data.cloaked_plugin) {
      store.dispatch("browser/savePluginDetected");
      window.removeEventListener("message", checkPlugin);
    }
  };
  if (pluginId.value || !extensionInstalled.value) {
    ExtensionService.getChromeExtension(pluginId.value).catch(() => {
      if (!extensionInstalled.value) {
        store.dispatch("browser/savePluginDetected", true);
        store.dispatch("onboarding/completeStep", STEP_DEMO);
      }
    });
  }

  window.addEventListener("message", checkPlugin);
  setTimeout(() => {
    window.postMessage({ has_plugin: true }, "*");
    setTimeout(() => {
      window.removeEventListener("message", checkPlugin);
    }, 1000);
  }, 100);
}

function handleKey(message) {
  if (message.data.success) {
    const handler = () => {
      window.dispatchEvent(new Event("storage"));
      window.removeEventListener("storage:write", handler);
    };
    window.addEventListener("storage:write", handler);
    setTimeout(() => {
      store.commit("setSecretKey", message.data.secretKey);
      try {
        // NOTE: where does this come from?
        // initiate();
        store.commit("setEncryptionStatus", true);
      } catch {
        store.commit("setEncryptionStatus", false);
      }
    }, 20);
  }
}

function getUserProfileSettings() {
  PersonalInfoService.getUserProfile();
}

async function checkLoaded() {
  state.exitDownload = hasPlugin.value || shownPlugin.value;
  getUserProfileSettings();
  await dbMixin.methods.frontLoadCloaks(true);

  nextTick(() => {
    store.dispatch("init");
  });
}

async function authCheck() {
  appBoot().then(() => {
    nextTick(() => {
      checkLoaded();
    });
  });
}
async function checkBrowserSupport() {
  // Get the user-agent string
  let userAgentString = navigator.userAgent;

  // Detect Chrome
  let chromeAgent = userAgentString.indexOf("Chrome") > -1;

  // NOTE: leaving these commented out so we can easily add them back in if needed
  // Detect Internet Explorer
  // let IExplorerAgent =
  //   userAgentString.indexOf("MSIE") > -1 ||
  //   userAgentString.indexOf("rv:") > -1;

  // Detect Firefox
  // let firefoxAgent = userAgentString.indexOf("Firefox") > -1;

  // Detect Safari
  // let safariAgent = userAgentString.indexOf("Safari") > -1;

  state.mobileDevice = userAgentString.match(
    /(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/
  );
  if (state.mobileDevice) {
    store.commit("skipOnboarding");
  }
  if (chromeAgent) {
    store.dispatch("browser/saveBrowserSupported", true);
  } else {
    store.dispatch("browser/saveBrowserSupported", false);
  }
}

function setVisibleBanner(visible) {
  store.dispatch("setVisibleBanner", visible);
}
</script>

<template>
  <div
    id="app"
    class="app"
    :class="{
      'app--visible-banner': hasVisibleBanner,
    }"
  >
    <div v-if="dbLoaded" class="app-div">
      <SecretKey
        @message="handleKey"
        v-if="shouldGetKeys"
        :hasPlugin="hasPlugin || shownPlugin"
      />
      <MountEvent @mounted="appReady" v-else>
        <div class="app-wrapper" v-if="state.bootReady">
          <AppBanners @visible="setVisibleBanner" ref="banner" />
          <MigrationModalFlow
            v-if="shouldShowEncryptionMigrationModal"
            :userEncryptionVersion="userEncryptionVersion"
          />

          <SearchModal />
          <RightPanel />
          <VirtualCardCreation v-if="cardsEnabled" />
          <NavigationLeftPanel />
          <NavigationTopBar />
          <Modal />
          <Toast />
          <AutoChangeContainer />
          <div
            :class="['content-container', { 'hide-overflow': shouldClipBody }]"
          >
            <MainNav :mobileDevice="state.mobileDevice" />
          </div>
        </div>
      </MountEvent>
      <portal-target name="root" multiple />
      <portal-target name="modals" />
    </div>
    <Loading v-else />
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/main.scss";
[data-lastpass-icon-root] {
  display: none !important;
}
#app {
  height: 100vh;
  .app-div {
    height: 100%;
    .app-wrapper {
      height: 100%;
      .content-container {
        height: 100%;
        > div {
          height: 100%;
        }
      }
    }
  }
}
</style>
