<script setup>
import AppModal from "@/components/ui/AppModal.vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import Button from "@/components/Button.vue";
import AutoChangeIcon from "@/assets/icons/auto-change.svg";
import { computed, defineProps } from "vue";

const props = defineProps({
  success: {
    type: Number,
    default: 0,
  },
  skipped: {
    type: Number,
    default: 0,
  },
  error: {
    type: Number,
    default: 0,
  },
});

const ModalStatus = {
  SUCCESS: "success",
  SKIPPED: "skipped",
  ERROR: "error",
  PARTIAL_ERROR: "partial-error",
  PARTIAL_SUCCESS: "partial-success",
};

const status = computed(() => {
  const allIdentities = props.success + props.skipped + props.error;

  if (allIdentities === 0) {
    return ModalStatus.SKIPPED;
  }

  if (props.success === allIdentities) {
    return ModalStatus.SUCCESS;
  }

  if (props.success > 0) {
    return ModalStatus.PARTIAL_SUCCESS;
  }

  if (props.error === allIdentities) {
    return ModalStatus.ERROR;
  }

  if (props.error > 0) {
    return ModalStatus.PARTIAL_ERROR;
  }

  return ModalStatus.SKIPPED;
});

const modalTitle = computed(() => {
  const titleMap = {
    [ModalStatus.SUCCESS]: "AutoCloak complete!",
    [ModalStatus.SKIPPED]: "AutoCloak canceled!",
    [ModalStatus.ERROR]: "AutoCloak could not complete!",
    [ModalStatus.PARTIAL_ERROR]: "AutoCloak could not complete!",
    [ModalStatus.PARTIAL_SUCCESS]: "AutoCloak partially completed!",
  };

  return titleMap[status.value];
});
</script>

<template>
  <AppModal v-on="$listeners" v-bind="$attrs">
    <AppModalContent
      class="auto-change-complete"
      :class="`auto-change-complete--${status}`"
    >
      <AutoChangeIcon class="auto-change-complete__icon" />
      <AppModalTitle> {{ modalTitle }} </AppModalTitle>
      <template v-if="success + error + skipped > 0">
        <AppModalParagraph v-if="status === 'success'">
          {{
            `${success} ${success !== 1 ? "identities have" : "identity has"}`
          }}
          been protected with Cloaked account information.
        </AppModalParagraph>
        <AppModalParagraph v-else-if="status === 'error'">
          {{ `${error} ${error !== 1 ? "identities" : "identity"}` }}
          could not be AutoCloaked.
        </AppModalParagraph>
        <AppModalParagraph v-else-if="status === 'skipped'">
          No identities have been AutoCloaked.
        </AppModalParagraph>
        <ul
          v-else-if="status === 'partial-success' || status === 'partial-error'"
          class="auto-change-complete__list"
        >
          <li v-if="success > 0" class="auto-change-complete__list-item">
            {{
              `${success} ${success !== 1 ? "identities have" : "identity has"}`
            }}
            been protected with Cloaked account information
          </li>
          <li v-if="error > 0" class="auto-change-complete__list-item">
            {{ `${error} ${error !== 1 ? "identities have" : "identity has"}` }}
            an error
          </li>
          <li v-if="skipped > 0" class="auto-change-complete__list-item">
            {{
              `${skipped} ${skipped !== 1 ? "identities were" : "identity was"}`
            }}
            skipped
          </li>
        </ul>
      </template>
      <AppModalParagraph v-else>
        No identities have been updated.
      </AppModalParagraph>
      <AppModalFooter>
        <Button
          type="secondary"
          @click="
            $emit('input', false);
            $emit('close');
          "
          >Close</Button
        >
        <Button
          @click="
            $emit('input', false);
            $emit('close');
            $emit('review');
          "
          >Review all changes</Button
        >
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>

<style lang="scss">
.auto-change-complete {
  &__icon {
    width: 48px;
    height: 48px;
    margin: 32px 32px 0;

    @at-root .auto-change-complete--success & {
      color: $color-success;
    }

    @at-root .auto-change-complete--skipped & {
      color: $color-primary-30;

      path {
        fill: transparent;
      }
    }

    @at-root .auto-change-complete--error & {
      color: $color-alert;
    }

    @at-root .auto-change-complete--partial-error & {
      color: $color-alert;
    }

    @at-root .auto-change-complete--partial-success & {
      color: $color-success;

      path {
        fill: transparent;
      }
    }
  }

  &__list {
    padding: 0 32px 0 32px + 20px;
    list-style-type: disc;
    margin-top: 16px;

    &-item {
      margin-top: 2px;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
      color: $color-primary-100;
    }
  }
}
</style>
