var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "app-table" },
    [
      _c("div", { staticClass: "app-table__head" }, [
        _c(
          "div",
          { staticClass: "app-table__head-row" },
          [
            _vm._t("head-row", null, {
              row: _setup.props.columns,
              cellClass: "app-table__head-cell",
            }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "app-table__body" },
        _vm._l(_setup.props.rows, function (row, index) {
          return _c(
            "div",
            { key: row.id || index, staticClass: "app-table__body-row" },
            [
              _vm._t("body-row", null, {
                row: row,
                cellClass: "app-table__body-cell",
              }),
            ],
            2
          )
        }),
        0
      ),
      _vm._t("footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }