var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.CustomFieldFormWrapper,
    _vm._g(
      _vm._b(
        { staticClass: "custom-field-form-address" },
        "CustomFieldFormWrapper",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "custom-field-form-address__row" },
        [
          _c(_setup.BorderInputSelect, {
            attrs: {
              label: "Address type",
              options: _setup.addressTypes,
              value: _vm.$attrs.value.value.type,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", {
                  ..._vm.$attrs.value,
                  value: { ..._vm.$attrs.value.value, type: $event.value },
                })
              },
            },
            scopedSlots: _vm._u([
              {
                key: "selected-value",
                fn: function ({ value, options }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          options.find((option) => option.value === value).label
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "option",
                fn: function ({ option }) {
                  return [
                    _c(
                      "span",
                      { staticClass: "custom-field-form-address__type-icon" },
                      [
                        _c(_setup.CustomFieldAddressIcon, {
                          attrs: { type: option.value },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" " + _vm._s(option.label) + " "),
                  ]
                },
              },
            ]),
          }),
          _c(_setup.BorderInputText, {
            attrs: { label: "Address label", value: _vm.$attrs.value.label },
            on: {
              input: function ($event) {
                return _vm.$emit("input", {
                  ..._vm.$attrs.value,
                  label: $event,
                })
              },
            },
          }),
        ],
        1
      ),
      _c(_setup.BorderInputText, {
        attrs: {
          label: "Street",
          value: _vm.$attrs.value.value.street_address,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", {
              ..._vm.$attrs.value,
              value: { ..._vm.$attrs.value.value, street_address: $event },
            })
          },
        },
      }),
      _c(
        "div",
        { staticClass: "custom-field-form-address__row" },
        [
          _c(_setup.BorderInputText, {
            attrs: {
              label: "Apartment, suite",
              value: _vm.$attrs.value.value.unit,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", {
                  ..._vm.$attrs.value,
                  value: { ..._vm.$attrs.value.value, unit: $event },
                })
              },
            },
          }),
          _c(_setup.BorderInputText, {
            attrs: { label: "City", value: _vm.$attrs.value.value.city },
            on: {
              input: function ($event) {
                return _vm.$emit("input", {
                  ..._vm.$attrs.value,
                  value: { ..._vm.$attrs.value.value, city: $event },
                })
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "custom-field-form-address__row" },
        [
          _c(_setup.BorderInputSelect, {
            attrs: {
              label: "State",
              placeholder: "Select",
              options: _setup.StateList,
              value: _vm.$attrs.value.value.state,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", {
                  ..._vm.$attrs.value,
                  value: { ..._vm.$attrs.value.value, state: $event.value },
                })
              },
            },
            scopedSlots: _vm._u([
              {
                key: "selected-value",
                fn: function ({ value, options }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          options.find((option) => option.value === value).label
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "option",
                fn: function ({ option }) {
                  return [_vm._v(" " + _vm._s(option.label) + " ")]
                },
              },
            ]),
          }),
          _c(_setup.BorderInputText, {
            attrs: {
              label: "Zip code",
              value: _vm.$attrs.value.value.postal_code,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", {
                  ..._vm.$attrs.value,
                  value: { ..._vm.$attrs.value.value, postal_code: $event },
                })
              },
            },
          }),
        ],
        1
      ),
      _c(_setup.BorderInputSelect, {
        attrs: {
          label: "Country",
          placeholder: "Select",
          options: _setup.countries(),
          value: _vm.$attrs.value.value.country,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", {
              ..._vm.$attrs.value,
              value: { ..._vm.$attrs.value.value, country: $event.name },
            })
          },
        },
        scopedSlots: _vm._u([
          {
            key: "selected-value",
            fn: function ({ value, options }) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      options.find((option) => option.name === value).name
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "option",
            fn: function ({ option }) {
              return [_vm._v(" " + _vm._s(option.name) + " ")]
            },
          },
        ]),
      }),
      _c(_setup.CustomFieldFormInputIsSensitive, {
        attrs: { value: _vm.$attrs.value.isSecret },
        on: {
          input: function ($event) {
            return _vm.$emit("input", { ..._vm.$attrs.value, isSecret: $event })
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }