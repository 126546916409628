var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "full" },
    [
      _c(_setup.Logo),
      _c("div", [
        _vm._v("Please upgrade your extension to continue using Cloaked."),
      ]),
      _c(
        "a",
        { attrs: { href: "https://download.cloaked.app", target: "_new" } },
        [_vm._v("Get Cloaked")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }