var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "footer",
    {
      staticClass: "app-modal-footer",
      class: { "app-modal-footer--with-border": _setup.props.withBorder },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }