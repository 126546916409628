var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "div",
        { staticClass: "import-banner" },
        [
          _c("Import", { staticClass: "import-banner__icon" }),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "import-banner__controls" },
            [
              _c(
                "router-link",
                {
                  staticClass: "import-banner__start",
                  attrs: { to: { name: "Import" } },
                },
                [_vm._v("Get started")]
              ),
              _c(
                "button",
                {
                  staticClass: "import-banner__close",
                  on: { click: _vm.onClose },
                },
                [_c("Close")],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "import-banner__content" }, [
      _c("h2", { staticClass: "import-banner__title" }, [
        _vm._v(" Import your accounts to secure them with Cloaked "),
      ]),
      _c("p", { staticClass: "import-banner__subtitle" }, [
        _vm._v(
          " Import your accounts from 1Password, LastPass, Chrome, and more "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }