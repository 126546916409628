<template>
  <AppModal v-on="$listeners" @close="$store.dispatch('onboarding/close')">
    <AppModalContent>
      <AppModalClose @click="$store.dispatch('onboarding/close')" is-absolute />
      <AppModalImage
        :src="require('@/assets/images/onboarding/onboarding-import-large.jpg')"
        :alt="title"
      />
      <AppModalTitle>
        {{ title }}
      </AppModalTitle>
      <AppModalParagraph>
        Cloaked can automatically replace passwords, email addresses, and phone
        numbers for your most important accounts
      </AppModalParagraph>
      <AppModalFooter with-border>
        <Button type="secondary" @click="completeStep"> No, thanks </Button>
        <Button @click="$router.push({ name: 'Import' })"> Get started </Button>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>

<script>
import AppModal from "@/components/ui/AppModal";
import AppModalClose from "@/components/ui/AppModalClose";
import AppModalTitle from "@/components/ui/AppModalTitle";
import AppModalParagraph from "@/components/ui/AppModalParagraph";
import AppModalContent from "@/components/ui/AppModalContent";
import AppModalImage from "@/components/ui/AppModalImage";
import AppModalFooter from "@/components/ui/AppModalFooter";
import Button from "@/components/Button.vue";
import { STEP_IMPORT } from "@/store/modules/onboarding";

export default {
  components: {
    AppModalFooter,
    AppModalImage,
    AppModalContent,
    AppModalParagraph,
    AppModalTitle,
    AppModalClose,
    AppModal,
    Button,
  },
  methods: {
    completeStep() {
      this.$store.dispatch("onboarding/completeStep", STEP_IMPORT);
      this.$store.dispatch("onboarding/close");
    },
  },
  computed: {
    title() {
      return "Replace personal info in your online accounts with secure data";
    },
  },
};
</script>
