var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "recovery-container" }, [
    _setup.shouldShowBackButton
      ? _c(
          "button",
          {
            staticClass: "preferences-header__back-button",
            on: { click: _setup.toggle_back },
          },
          [_c(_setup.ChevronLeft), _c("span", [_vm._v("Back")])],
          1
        )
      : _vm._e(),
    !_setup.state.downloaded
      ? _c(
          "div",
          [
            _c(_setup.PreferencesTitle, [
              _vm._v("Download your recovery passphrase"),
            ]),
            _c(_setup.PreferencesParagraph, [
              _vm._v(
                " This can be used to make sure it's really you signing in, reach you if there's suspicious activity in your account, or recover a lost password. "
              ),
            ]),
            _c(
              "div",
              { staticClass: "input-container" },
              [
                !_setup.shouldSkipPassword
                  ? _c(_setup.PreferencesInput, {
                      attrs: {
                        label: "Master Password",
                        type: "password",
                        placeholder: "",
                        error: _setup.state.error,
                      },
                      on: { save: _setup.getRecoveryKey },
                      model: {
                        value: _setup.state.currentPassword,
                        callback: function ($$v) {
                          _vm.$set(_setup.state, "currentPassword", $$v)
                        },
                        expression: "state.currentPassword",
                      },
                    })
                  : _vm._e(),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "download-button",
                      class: { disabled: _setup.disabled },
                      on: { click: _setup.getRecoveryKey },
                    },
                    [_vm._v(" Download recovery passphrase ")]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "recovery-container" },
          [
            _c(_setup.PreferencesTitle, [
              _vm._v("Successfully downloaded recovery passphrase"),
            ]),
            _c(_setup.PreferencesParagraph, [
              _vm._v(
                " Make sure your recovery passphrase is safe, so write it down, or keep it in multiple secret spots so you never lose it. "
              ),
            ]),
            _c("div", { staticClass: "recovery-container" }, [
              _c("div", { staticClass: "action-buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "download-again-button",
                    class: { disabled: _setup.disabled },
                    on: { click: _setup.getRecoveryKey },
                  },
                  [_vm._v(" Download again ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "done-button",
                    class: { disabled: _setup.disabled },
                    on: { click: _setup.reset },
                  },
                  [_vm._v(" Done ")]
                ),
              ]),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }