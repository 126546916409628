<script setup>
import { defineProps } from "vue";

defineProps({
  isHidden: {
    type: Boolean,
    required: true,
  },
  obfuscatedValue: {
    type: String,
    default: "••••••••••••••••••••••",
  },
});
</script>

<template>
  <div>
    <template v-if="isHidden">{{ obfuscatedValue }}</template>
    <slot v-else></slot>
  </div>
</template>
