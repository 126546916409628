<template>
  <section class="fwd-section-wrapper">
    <h2>{{ title }}</h2>

    <CloakForwardToggleRow
      :aria-id="`CloakForwardToggleRow.${field || ''}`"
      :field="field"
      :selected="selected"
      :options="options"
      :allowed="allowed"
      :defaultValue="defaultValue"
      :unverified-entries="unverifiedEntries"
      @select="(selection) => $emit('select', selection)"
      @deselect="$emit('deselect')"
      @sentToSettings="$emit('sentToSettings')"
    />
    <div
      v-if="LOCKED_NUMBERS_ENABLED && locked"
      class="fwd-toggle-wrapper__label"
    >
      <div class="fwd-toggle-wrapper__label-title">
        {{ sectionTitle }}
      </div>

      <div
        class="fwd-toggle-wrapper__label-selection"
        :class="{
          'fwd-toggle-wrapper__label-selection--selected': selected,
          'fwd-toggle-wrapper__label-selection--disabled': locked,
        }"
      >
        <span>{{ selectedFilterRuleDisplay }}</span>
      </div>
    </div>
    <UiMenu v-else width="263px" placement="bottom-start">
      <div class="fwd-toggle-wrapper__label">
        <div class="fwd-toggle-wrapper__label-title">
          {{ sectionTitle }}
        </div>

        <div
          class="fwd-toggle-wrapper__label-selection"
          :class="{
            'fwd-toggle-wrapper__label-selection--selected': selected,
          }"
        >
          <span>{{ selectedFilterRuleDisplay }}</span>

          <ChevronDown />
        </div>
      </div>

      <template #content>
        <UiMenuButton
          v-for="filterKey in commFilters"
          :key="filterKey"
          :title="getFilterDisplay(filterKey)"
          :active="filterKey === selectedFilterRuleKey"
          @click="() => handleSelectSetting(filterKey)"
        >
          <template v-slot:icon>
            <CheckIcon v-if="filterKey === selectedFilterRuleKey" />
            <span v-else></span>
          </template>

          <template v-if="field === 'email'" v-slot:title>
            <UiEmailOptionLabel :email="getFilterDisplay(filterKey)" />
          </template>
          <template v-else v-slot:title>
            <div>{{ getFilterDisplay(filterKey) }}</div>
          </template>
        </UiMenuButton>
      </template>
    </UiMenu>
    <div v-if="!LOCKED_NUMBERS_ENABLED" class="comm-notice">
      <h3 v-if="noticeDisplay.length">
        {{ noticeDisplay }}
      </h3>
    </div>
  </section>
</template>
<script>
import { CloakForwardToggleRow } from "@/components/cloakDetails";
import { CheckIcon, ChevronDown } from "@/assets/icons";
import { LOCKED_NUMBERS_ENABLED } from "@/scripts/featureFlags";
import { UiMenu, UiMenuButton, UiEmailOptionLabel } from "@/components";

const COMM_FILTERS_MAP = LOCKED_NUMBERS_ENABLED
  ? {
      email: {
        block: "Block all emails",
        manual_screen: false,
        allow_all: "Unrestricted",
      },
      message: {
        block: "Block new senders",
        manual_screen: "Request approval",
        allow_all: "Unrestricted (not recommended)",
      },
      call: {
        block: "Block new callers",
        manual_screen: "Request approval",
        allow_all: "Unrestricted (not recommended)",
      },
    }
  : {
      email: {
        block: "Block new senders",
        manual_screen: false,
        allow_all: "Anyone",
      },
      message: {
        block: "Block new senders",
        manual_screen: "Screened contacts",
        allow_all: "Anyone (not recommended)",
      },
      call: {
        block: "Block new callers",
        manual_screen: "Screened contacts",
        allow_all: "Anyone (not recommended)",
      },
    };
const SECTION_TITLE = LOCKED_NUMBERS_ENABLED
  ? { email: "New emails", message: "New texts", call: "New calls" }
  : {
      email: "Who can reach this email?",
      message: "Who can reach this number?",
      call: "Who can reach this number?",
    };
const COMM_NOTICES_MAP = {
  email: {
    block: "New senders will be blocked",
    manual_screen: false,
    allow_all: "Emails will be stored in your Cloaked app",
  },
  message: {
    block: "Any new senders will be blocked",
    manual_screen: "Texts will be stored in your Cloaked app",
    allow_all: "Texts will be stored in your Cloaked app",
  },
  call: {
    block: "New callers will be blocked",
    manual_screen:
      "Calls will be sent to voicemail and stored in your Cloaked app",
    allow_all: "Calls will be sent to voicemail and stored in your Cloaked app",
  },
};

export default {
  name: "CloakForwardingSection",
  props: [
    "field",
    "selected",
    "options",
    "allowed",
    "defaultValue",
    "title",
    "rules",
    "unverifiedEntries",
    "locked",
  ],
  components: {
    CloakForwardToggleRow,
    UiMenu,
    UiMenuButton,
    UiEmailOptionLabel,
    CheckIcon,
    ChevronDown,
  },
  created() {
    this.LOCKED_NUMBERS_ENABLED = LOCKED_NUMBERS_ENABLED;
  },
  methods: {
    getFilterDisplay(filterKey) {
      return COMM_FILTERS_MAP[this.field][filterKey];
    },
    handleSelectSetting(filterKey) {
      this.$emit("updateFilterRule", filterKey);
    },
  },
  computed: {
    sectionTitle() {
      return SECTION_TITLE[this.field];
    },
    commFilters() {
      if (LOCKED_NUMBERS_ENABLED) {
        if (this.field === "message" || this.field === "call") {
          return Object.keys(COMM_FILTERS_MAP[this.field]).filter((key) => {
            /* If the selected setting is already block, show block, if not, do not add it to the list of available */
            if (this.selectedFilterRuleKey === "block") {
              return key;
            }
            return key !== "block";
          });
        }
      }
      return Object.keys(COMM_FILTERS_MAP[this.field]).filter(
        (key) => COMM_FILTERS_MAP[this.field][key]
      );
    },
    noticeDisplay() {
      if (this.field && this.rules && this.rules.filter_rule) {
        return COMM_NOTICES_MAP[this.field][this.rules.filter_rule];
      }
      return "";
    },
    selectedFilterRuleDisplay() {
      if (this.rules) {
        return COMM_FILTERS_MAP[this.field][this.rules.filter_rule];
      } else if (this.field === "email") {
        return;
      }
      return "";
    },
    selectedFilterRuleKey() {
      if (this.rules) {
        return this.rules.filter_rule;
      }
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.fwd-section-wrapper {
  border: 1px solid $color-primary-5;
  border-radius: 15px;
  padding: 20px;
  margin-top: 12px;
  > h2 {
    color: $color-primary-100;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }
  .comm-notice {
    background-color: $color-primary-100-10;
    border-radius: 11px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin-top: 12px;

    > h3 {
      line-height: 18px;
      color: $color-primary-100;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
    }
  }
}
</style>
