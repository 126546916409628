var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "componentElement",
      staticClass: "cloak-category-section",
      class: { readOnly: _setup.props.readOnly },
    },
    [
      _c(_setup.CloakDetailsInputRow, {
        attrs: {
          value: _setup.selectedCategoryId,
          label: "Category",
          placeholder: "Add category",
          "loading-message": _setup.state.loadingMessage,
          loading: _setup.state.loading || _setup.state.expectsRefresh,
          "is-menu-open": _setup.state.isMenuOpen,
        },
        scopedSlots: _vm._u([
          {
            key: "input-before",
            fn: function () {
              return [
                _c(_setup.UiMenu, {
                  staticClass: "cloak-category-section__menu",
                  attrs: {
                    width: "188px",
                    "max-height": "300px",
                    placement: "left-start",
                  },
                  on: { close: _setup.handleMenuClose },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _setup.categories && _setup.categories.length
                            ? _c(_setup.UiMenuInput, {
                                attrs: {
                                  "aria-id":
                                    "FilterInputCategoriesCloakDetails",
                                  placeholder: "Filter category...",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                                model: {
                                  value: _setup.state.categoryFilter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _setup.state,
                                      "categoryFilter",
                                      $$v
                                    )
                                  },
                                  expression: "state.categoryFilter",
                                },
                              })
                            : _vm._e(),
                          _setup.categories && _setup.categories.length
                            ? _c(_setup.UiMenuSeparator)
                            : _vm._e(),
                          _setup.categories && _setup.categories.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "cloak-category-section-categories",
                                },
                                _vm._l(
                                  _setup.filteredCategories,
                                  function (category) {
                                    return _c(_setup.UiMenuButton, {
                                      key: category.id,
                                      attrs: {
                                        "aria-id": `AddToCategory.${
                                          category?.name || ""
                                        }`,
                                        title: category.name,
                                        active:
                                          category.id ===
                                          _setup.selectedCategoryId,
                                      },
                                      on: {
                                        click: () => _setup.moveToCat(category),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "icon",
                                            fn: function () {
                                              return [
                                                category.id ===
                                                _setup.selectedCategoryId
                                                  ? _c(_setup.CheckIcon)
                                                  : _c("span"),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _setup.filteredCategories &&
                          _setup.filteredCategories.length
                            ? _c(_setup.UiMenuSeparator)
                            : _vm._e(),
                          _c(_setup.UiMenuButton, {
                            attrs: {
                              "aria-id": "CreateNewCategoryCloakedDetails",
                              title: "Create new Category",
                              "dark-font": "",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _setup.handleCategoryCreate.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "icon",
                                fn: function () {
                                  return [_c(_setup.Plus)]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "icon",
            fn: function () {
              return [
                _c(
                  "button",
                  { staticClass: "cloak-category-section__icon-button" },
                  [
                    _setup.props.readOnly
                      ? _c(_setup.LockIcon)
                      : _setup.hasCategory
                      ? _c(_setup.ChevronDown)
                      : _c(_setup.Plus),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "input",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "category-button",
                    class: {
                      "category-button--active": _setup.hasCategory,
                    },
                    attrs: {
                      id: "cloak-details-category-button",
                      "aria-id": "CloakDetailsAddToCategoryButton",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_setup.categoryName))])]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _setup.hasCategory
                  ? _c(
                      _setup.UiMenu,
                      {
                        attrs: { width: "188px", placement: "bottom-end" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _c(_setup.UiMenuButton, {
                                    attrs: { title: "Remove" },
                                    on: {
                                      click: function ($event) {
                                        return _setup.showRemoveModal()
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "icon",
                                          fn: function () {
                                            return [_c(_setup.DeleteMinusIcon)]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1116373917
                                    ),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3271950528
                        ),
                        model: {
                          value: _setup.state.isMenuOpen,
                          callback: function ($$v) {
                            _vm.$set(_setup.state, "isMenuOpen", $$v)
                          },
                          expression: "state.isMenuOpen",
                        },
                      },
                      [
                        _c(
                          _setup.CloakInfoRowButton,
                          {
                            attrs: {
                              icon: "",
                              active: _setup.state.isMenuOpen,
                            },
                          },
                          [_c(_setup.KabobIcon)],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(_setup.AddEditNewCategory, {
        ref: "categoryModal",
        attrs: {
          id: "add-category-navigation",
          isModalVisible: _setup.state.isNewCategoryModalVisible,
          "no-redirect": true,
          prefilled: _setup.state.prefilledCategoryName,
        },
        on: {
          closeModal: _setup.closeNewCategoryModal,
          category: _setup.handleNewCategory,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }