var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "preferences-input",
      class: {
        "preferences-input--error": _setup.props.error,
        "preferences-input--has-actions": _setup.hasActions,
      },
    },
    [
      _c("label", [_vm._v(_vm._s(_setup.props.label))]),
      _c(
        "div",
        { staticClass: "preferences-input__wrapper" },
        [
          _c("input", {
            ref: "input",
            staticClass: "pref-input-items",
            attrs: {
              placeholder: _setup.props.placeholder,
              readonly: _setup.props.readonly,
              autocomplete: "off",
              "data-lpignore": "true",
              "data-form-type": "other",
              type:
                _setup.props.type === "password" &&
                !_setup.state.passwordIsVisible
                  ? "password"
                  : "text",
              maxlength: _setup.props.max,
            },
            domProps: { value: _setup.innerValueDisplay },
            on: {
              input: _setup.handleInput,
              keypress: _setup.handleKeydown,
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  $event.stopPropagation()
                  return _setup.handleEsc.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _setup.handleEnter.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  )
                    return null
                  return _setup.handleDown.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  )
                    return null
                  return _setup.handleUp.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                  )
                    return null
                  $event.preventDefault()
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  )
                    return null
                  return _setup.moveNext.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                  )
                    return null
                  $event.preventDefault()
                  if (!$event.shiftKey) return null
                  return _setup.movePrev.apply(null, arguments)
                },
              ],
              focus: _setup.handleFocus,
              blur: _setup.handleBlur,
            },
          }),
          _setup.props.type === "password" ||
          _vm.$slots.actions ||
          _setup.hasMenu
            ? _c(
                "div",
                { staticClass: "preferences-input__actions" },
                [
                  _vm._t("actions"),
                  _setup.props.type === "password"
                    ? _c(
                        "button",
                        {
                          staticClass: "preferences-input__password-reveal",
                          on: { click: _setup.togglePasswordReveal },
                        },
                        [
                          _setup.state.passwordIsVisible
                            ? _c(_setup.EyeSlash)
                            : _c(_setup.Eye),
                        ],
                        1
                      )
                    : _vm._e(),
                  _setup.hasMenu
                    ? _c(
                        "button",
                        {
                          staticClass: "preferences-input__down-chevron",
                          on: {
                            click: function ($event) {
                              _setup.state.menuIsVisible = true
                            },
                          },
                        },
                        [_c(_setup.ChevronDown)],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _setup.hasMenu
            ? _c(_setup.UiMenu, {
                attrs: {
                  value:
                    _setup.state.menuIsVisible &&
                    !!_setup.visibleOptions.length,
                  placement: "bottom-start",
                  width: "300px",
                  height: "350px",
                  "has-click-outside-close": false,
                },
                on: {
                  close: function ($event) {
                    _setup.state.menuIsVisible = false
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c(_setup.SectionList, {
                            attrs: {
                              items: _setup.visibleOptions,
                              active: _setup.state.active,
                            },
                            on: {
                              setActive: _setup.handleSetActive,
                              select: _setup.handleSelect,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2902442252
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _setup.props.error && _setup.props.errorMessage
        ? _c("div", { staticClass: "errorMessage" }, [
            _vm._v(" " + _vm._s(_setup.props.errorMessage) + " "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }