var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _setup.hideContext,
              expression: "hideContext",
            },
          ],
          staticClass: "card",
          class: {
            active: _vm.selected,
            muted: _setup.muted,
            visibleRightPanel:
              _vm.identity.temp ||
              _vm.identity.id ===
                (_setup.rightPanelCloak && _setup.rightPanelCloak.id),
            "card--auto-change-success":
              _setup.hasAutoChangeUi && _setup.autoChangeStatus === "success",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
            contextmenu: function ($event) {
              $event.preventDefault()
              return _setup.rightClick.apply(null, arguments)
            },
          },
        },
        [
          _c("div"),
          _setup.hasAutoChangeUi
            ? _c(_setup.AutoChangeIcon, {
                staticClass: "card__auto-change",
                attrs: { status: _setup.autoChangeStatus },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _setup.autoChangeStatus === "needsReview"
                      ? _vm.$store.dispatch("autoChange/reviewItem", {
                          identityId: _vm.identity.id,
                        })
                      : _vm.$router.push({ name: "AutoCloak" })
                  },
                },
              })
            : !_vm.noSelect
            ? _c(
                "button",
                { staticClass: "select", on: { click: _setup.toggleSelect } },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "11",
                        height: "8",
                        viewBox: "0 0 11 8",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M3.5837 6.43252L1.55953 4.40835C1.33203 4.18085 0.964531 4.18085 0.737031 4.40835C0.509531 4.63585 0.509531 5.00335 0.737031 5.23085L3.17536 7.66919C3.40286 7.89669 3.77036 7.89669 3.99786 7.66919L10.1695 1.49752C10.397 1.27002 10.397 0.90252 10.1695 0.67502C9.94203 0.44752 9.57453 0.44752 9.34703 0.67502L3.5837 6.43252Z",
                          fill: "currentColor",
                        },
                      }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(_setup.IdentityIcon, {
                attrs: {
                  identity: _vm.identity,
                  override: {
                    width: "88px",
                    height: "88px",
                    "align-self": "center",
                  },
                },
              }),
              _setup.nickname
                ? _c(
                    "div",
                    { staticClass: "text" },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "h1",
                            {
                              class: { muted: _setup.muted },
                              attrs: { anchor: `id_anchor_${_vm.id_index}` },
                            },
                            [_vm._v(" " + _vm._s(_setup.nickname) + " ")]
                          ),
                          _setup.muted ? _c(_setup.MutedIcon) : _vm._e(),
                        ],
                        1
                      ),
                      _vm.identity.app_ref !== "cloaked" && !_setup.showTotp
                        ? _c("div", { class: { muted: _setup.muted } }, [
                            _vm._v(" " + _vm._s(_setup.website) + " "),
                          ])
                        : _vm._e(),
                      _setup.identifier
                        ? _c("div", { class: { muted: _setup.muted } }, [
                            _vm._v(" " + _vm._s(_setup.identifier) + " "),
                          ])
                        : _vm._e(),
                      _c(
                        _setup.AppTooltip,
                        {
                          attrs: { "offset-away": 15 },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(_setup.copyTotpTooltip) + " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1968040566
                          ),
                        },
                        [
                          _setup.showTotp
                            ? _c(_setup.TOTPToken, {
                                staticClass: "card__totp",
                                attrs: {
                                  url: _setup.totp?.value.startsWith(
                                    "otpauth://"
                                  )
                                    ? _setup.totp?.value
                                    : undefined,
                                  secret: _setup.totp?.value.startsWith(
                                    "otpauth://"
                                  )
                                    ? undefined
                                    : _setup.totp?.value,
                                  "is-hidden": "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _setup.copyTotp.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  "new-token": function ($event) {
                                    _setup.totpToken = $event
                                  },
                                  mouseleave: _setup.resetTotpTooltip,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _setup.showContext
        ? _c(_setup.ContextMenu, {
            ref: "contextMenu",
            attrs: {
              count: _vm.count,
              isMultiselect: _vm.isMultiselect,
              selected: _vm.selected,
              event: _setup.event,
              identity: _vm.identity,
              ignoreDisplay: _vm.ignoreDisplay,
            },
            on: {
              unselectAll: function ($event) {
                return _setup.emit("unselectAll")
              },
              selectAll: function ($event) {
                return _setup.emit("selectAll")
              },
              unselect: function ($event) {
                return _setup.emit("unselect")
              },
              select: function ($event) {
                return _setup.emit("select")
              },
              delete: function ($event) {
                return _setup.emit("delete")
              },
              ignore: function ($event) {
                return _setup.emit("ignore")
              },
              unignore: function ($event) {
                return _setup.emit("unignore")
              },
              move: _setup.move,
              close: _setup.hideContext,
              "auto-change": _setup.autoChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }