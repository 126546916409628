<template>
  <section class="credit-card-display" @click="$emit('click')">
    <CardLogo :cardType="card.card_type" />
    <div class="card-id">
      <div class="card-info">
        <div class="card-name">
          {{ cardTypeFormatted }}
        </div>
      </div>
      <div class="card-address">
        •••• {{ card.last_4_digits }}, Exp {{ card.expiry_date }}
      </div>
    </div>
    <div class="card-actions">
      <ChevronRight />
    </div>
  </section>
</template>

<script>
import { ChevronRight } from "@/assets/icons";
import { CardLogo } from "@/components";

export default {
  name: "CreditCard",
  props: ["card"],
  components: {
    CardLogo,
    ChevronRight,
  },
  computed: {
    cardTypeFormatted() {
      return this.card.card_type.replace("-", " ");
    },
  },
};
</script>
<style lang="scss" scoped>
.credit-card-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 10px;
  border-top: 1px solid $color-primary-10;
  cursor: pointer;
  &:first-of-type {
    border-top: 1px solid transparent;
  }

  &:last-of-type {
    border-bottom: 1px solid $color-primary-10;
  }
  &:hover + .credit-card-display {
    border-top: 1px solid transparent;
  }
  &:hover {
    border-radius: 16px;
    border-top: 1px solid $color-primary-5;
    background: $color-primary-5;

    > div:first-child {
      background-color: $color-primary-10;
    }
  }

  .card-id {
    flex: 1;
    width: 100%;
    justify-content: space-between;
    .card-info {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .card-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $color-primary-100;
        text-transform: capitalize;
      }
      .card-number {
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.2px;
        color: $color-primary-70;
      }
    }
    .card-address {
      font-size: 13px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.2px;
      color: $color-primary-50;
    }
  }
  > svg {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border: 1px solid $color-primary-10;
    border-radius: 20px;
  }
  .card-actions {
    cursor: pointer;
    align-self: center;
    background-color: transparent;
    border: none;
    color: $color-primary-100;
  }
}
</style>
