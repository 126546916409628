var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", [
    _setup.show
      ? _c("div", { staticClass: "toast-wrapper" }, [
          _c("div", {
            staticClass: "toast-item",
            class: { error: _setup.isError },
            domProps: { innerHTML: _vm._s(_setup.message) },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }