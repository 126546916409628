export const AUTOFILL_CC_ENABLED = true;
export const MULTI_RECIPIENT_ENABLED = true;
export const TEXT_ATTACHMENTS_ENABLED = true;
export const ANALYTICS_ENABLED = false;
export const DARK_MODE_ENABLED = true;
export const RECOVERY_KEY_ENABLE = true;
export const NEW_DEFAULT_IDENTITY_ICONS = true;
export const ENABLE_CARDS = true;
export const MFA_ENABLED = true;
export const CAN_INVITE = true;
export const ACCOUNT_RECOVERY_NEW_MENU = true;
export const CLOAKED_DAILY_ENABLED = true;
export const LOCKED_NUMBERS_ENABLED = true;
