import { phone_format } from "@/scripts/format";
import moment from "moment";
import store from "@/store";
export const cloaked_email = (cloak) => {
  const cloaked = cloak && cloak.cloaked_email && cloak.cloaked_email.email;
  const autofill = autofill_field(cloak, "email");
  return {
    value: autofill || cloaked,
    cloaked: cloaked && (!autofill || autofill === cloaked),
  };
};

export const cloaked_phone = (cloak) => {
  const object =
    cloak &&
    (cloak.number_for_app || cloak.number_for_personal || cloak.cloaked_phone);
  let cloaked = object && object.phone_number;
  const autofill = autofill_field(cloak, "phone_number");
  if (object) {
    cloaked = phone_format(cloaked);
    if (object.ext) {
      cloaked = `${cloaked.replaceAll(" ", "")} ext. ${object.ext}`;
    }
  }
  return {
    value: autofill || cloaked,
    cloaked: cloaked && (!autofill || autofill === cloaked),
  };
};

export const cloaked_password = (cloak) => {
  const cloaked =
    cloak && cloak.stored_password && cloak.stored_password.password;
  const autofill = autofill_field(cloak, "password");
  return {
    value: autofill || cloaked,
    cloaked: cloaked && (!autofill || autofill === cloaked),
  };
};

export const autofill_field = (cloak, field) => {
  return cloak && cloak.stored_autofill && cloak.stored_autofill[field];
};

export const getLatestDetailValue = (key, cloak) => {
  const { autofill, cloaked } = getDetailHelper(key, cloak);
  const latestDetailType = getLatestDetailType(key, cloak);

  if (key.includes("phone")) {
    key = "phone_number";
  }

  switch (latestDetailType) {
    case "cloaked": {
      return cloaked ? cloaked[key] : null;
    }
    case "user_defined": {
      return autofill ? autofill[key] : null;
    }
    default: {
      if (key.includes("phone")) {
        return cloak.number_for_personal || cloak.number_for_app;
      }
      return null;
    }
  }
};

export const getLatestDetailObject = (key, cloak) => {
  const { autofill, cloaked } = getDetailHelper(key, cloak);
  const latestDetailType = getLatestDetailType(key, cloak);
  switch (latestDetailType) {
    case "cloaked": {
      return cloaked;
    }
    case "user_defined": {
      return autofill;
    }
    default: {
      return null;
    }
  }
};

export const getDetailHelper = (key, cloak) => {
  const autofill = cloak.stored_autofill;
  const cloaked_key = getCloakedKey(key);

  let cloaked = null;
  if (cloak[cloaked_key]) {
    cloaked = cloak[cloaked_key];
  }
  return { autofill, cloaked };
};

export const getLatestDetailType = (key, cloak) => {
  if (key.includes("phone")) {
    key = "phone_number";
  }

  const autofill_updated_at = `${key}_updated_at`;
  const cloaked_updated_at = "updated_at";
  const autofill = cloak.stored_autofill;
  const cloaked_key = getCloakedKey(key);
  const cloaked = cloak[cloaked_key];

  const autofill_time =
    autofill && autofill[key] && autofill[autofill_updated_at];
  const cloaked_time = cloaked && cloaked[key] && cloaked[cloaked_updated_at];

  if (autofill_time && cloaked_time) {
    if (moment(autofill_time) > moment(cloaked_time)) {
      return "user_defined";
    } else {
      return "cloaked";
    }
  } else if (autofill && autofill[key] && autofill_time) {
    return "user_defined";
  } else if (cloaked && cloaked[key] && cloaked_time) {
    return "cloaked";
  } else if (autofill && autofill[key]) {
    return "user_defined";
  } else if (cloaked && cloaked[key]) {
    return "cloaked";
  } else if (
    key.includes("phone") &&
    (cloak.number_for_personal || cloak.number_for_app)
  ) {
    return "cloaked";
  }
  return "none";
};

const getCloakedKey = (key) => {
  let cloaked_key = `cloaked_${key}`;

  if (key.includes("phone")) {
    cloaked_key = "cloaked_phone";
  }

  if (key === "password") {
    cloaked_key = "stored_password";
  }

  return cloaked_key;
};

export const cloakDetailRouteNames = [
  "cloak",
  "categorywithcloak",
  "favoriteswithcloak",
  "mutedwithcloak",
  "all",
];

export function loadSupport() {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src =
      "https://static.zdassets.com/ekr/snippet.js?key=6e7aefc9-a475-439d-ae75-8d32c8ce0fca";
    script.id = "ze-snippet";
    script.async = true;
    script.onload = () => {
      window.zE("messenger:on", "open", () => {
        store.dispatch("setSupport", true);
        setTimeout(() => {
          const iframe = document.getElementById("launcher");
          if (iframe) {
            iframe.id = "launcher_open";
          }
          if (resolveSupport) {
            resolveSupport();
          }
        }, 1000);
      });
      window.zE("messenger:on", "close", () => {
        store.dispatch("setSupport", false);
        const iframe = document.getElementById("launcher_open");
        if (iframe) {
          iframe.id = "launcher";
        }
        if (resolveSupport) {
          resolveSupport();
        }
      });
      resolve();
    };
    document.body.appendChild(script);
  });
}
let resolveSupport;
export function toggleSupport() {
  return new Promise((resolve) => {
    resolveSupport = resolve;
    if (store.state.support) {
      window.zE("messenger", "close");
    } else {
      window.zE("messenger", "open");
    }
  });
}
