var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.UiBanner,
    { staticClass: "auto-change-banner" },
    [
      _vm._v(" Do not close your tabs while we AutoCloak your identities. "),
      _c(
        "router-link",
        {
          staticClass: "auto-change-banner__link",
          attrs: { to: { name: "AutoCloak" } },
        },
        [_vm._v(" Review AutoCloak status › ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }