var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.FormContainer, { attrs: { icon: "pay/basic-information" } }, [
        _c("div", { staticClass: "input-wrapper" }, [
          _c("label", [_vm._v("Legal first name")]),
          _c("div", { staticClass: "input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _setup.formData.first_name,
                  expression: "formData.first_name",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _setup.formData.first_name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_setup.formData, "first_name", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "input-wrapper" }, [
          _c("label", [_vm._v("Legal last name")]),
          _c("div", { staticClass: "input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _setup.formData.last_name,
                  expression: "formData.last_name",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _setup.formData.last_name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_setup.formData, "last_name", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "input-wrapper" }, [
          _c("label", [_vm._v("Date of birth (MM/DD/YYYY)")]),
          _c("div", { staticClass: "input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _setup.formData.dob,
                  expression: "formData.dob",
                },
              ],
              attrs: { type: "text", maxlength: "10" },
              domProps: { value: _setup.formData.dob },
              on: {
                keyup: function ($event) {
                  return _setup.formatDate()
                },
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                      "Backspace",
                      "Delete",
                      "Del",
                    ])
                  )
                    return null
                  return _setup.removeDate()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_setup.formData, "dob", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "input-wrapper" }, [
          _c("label", [_vm._v("SSN or ITIN")]),
          _c("div", { staticClass: "input toggle" }, [
            (_setup.passwordVisible ? "text" : "password") === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.formData.government_id,
                      expression: "formData.government_id",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_setup.formData.government_id)
                      ? _vm._i(_setup.formData.government_id, null) > -1
                      : _setup.formData.government_id,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _setup.formData.government_id,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _setup.formData,
                              "government_id",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _setup.formData,
                              "government_id",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_setup.formData, "government_id", $$c)
                      }
                    },
                  },
                })
              : (_setup.passwordVisible ? "text" : "password") === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.formData.government_id,
                      expression: "formData.government_id",
                    },
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    checked: _vm._q(_setup.formData.government_id, null),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_setup.formData, "government_id", null)
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.formData.government_id,
                      expression: "formData.government_id",
                    },
                  ],
                  attrs: { type: _setup.passwordVisible ? "text" : "password" },
                  domProps: { value: _setup.formData.government_id },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _setup.formData,
                        "government_id",
                        $event.target.value
                      )
                    },
                  },
                }),
            _c(
              "div",
              {
                staticClass: "toggle",
                on: {
                  click: function ($event) {
                    return _setup.togglePasswordVisible()
                  },
                },
              },
              [
                _c(_setup.InlineSvg, {
                  key: _setup.count,
                  attrs: { name: _setup.passwordVisible ? "eye-slash" : "eye" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _setup.toggleSSNModal()
                },
              },
            },
            [
              _vm._v("Why do we need this "),
              _c(_setup.InlineSvg, { attrs: { name: "lock-shield" } }),
            ],
            1
          ),
        ]),
        !_setup.formData.email
          ? _c("div", { staticClass: "input-wrapper full-width" }, [
              _c("label", [_vm._v("Personal email")]),
              _c("div", { staticClass: "input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.formData.email,
                      expression: "formData.email",
                    },
                  ],
                  attrs: { type: "email" },
                  domProps: { value: _setup.formData.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_setup.formData, "email", $event.target.value)
                    },
                  },
                }),
              ]),
            ])
          : _vm._e(),
      ]),
      _c(_setup.SSNModal, {
        attrs: { show: _setup.ssnModal },
        on: {
          close: function ($event) {
            return _setup.toggleSSNModal()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }