var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "form" }, [
    _setup.props.icon
      ? _c(
          "div",
          { staticClass: "icon" },
          [_c(_setup.InlineSvg, { attrs: { name: _setup.props.icon } })],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "inputs" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }