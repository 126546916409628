<script setup>
import { defineProps } from "vue";
import {
  IconText,
  IconAddress,
  IconUrl,
  IconDate,
  IconBank,
  IconAuth,
  IconIdentification,
} from "@/assets/icons/custom-fields";

defineProps({
  type: {
    type: String,
    validator: (value) =>
      [
        "text",
        "address",
        "url",
        "date",
        "bank",
        "auth",
        "identification",
        "secret",
      ].includes(value),
  },
});

const iconByType = {
  text: IconText,
  address: IconAddress,
  url: IconUrl,
  date: IconDate,
  bank: IconBank,
  auth: IconAuth,
  identification: IconIdentification,
};
</script>

<template>
  <Component :is="iconByType[type]" class="custom-field-icon" />
</template>

<style lang="scss">
.custom-field-icon {
  color: $color-primary-100;
}
</style>
