var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "trash-identity-container" }, [
    _setup.ui.total > 0
      ? _c("div", [
          _c("div", { staticClass: "trash-info" }, [
            _c("div", { staticClass: "trash-info-header" }, [
              _vm._v(
                " Items in the Trash will be automatically deleted after 30 days. "
              ),
            ]),
            _c("div", { staticClass: "empty-trash-icon" }, [
              _c("button", { on: { click: _setup.emptyTrash } }, [
                _c("span", [_vm._v("Empty trash now")]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "trash-data-header" }, [
            _c("div", { staticClass: "actions" }, [
              _setup.data.selected.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "button",
                        {
                          staticClass: "select-all-button",
                          on: { click: _setup.unselectAll },
                        },
                        [_c(_setup.SelectAll)],
                        1
                      ),
                      _c(
                        _setup.UiTooltip,
                        {
                          attrs: {
                            title: "Remove the currently selected items",
                            position: "top",
                            "align-x": "center",
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "delete-button",
                              attrs: { disabled: !_setup.canDelete },
                              on: { click: _setup.deleteSelected },
                            },
                            [
                              _c(_setup.DeleteTrashIcon),
                              _vm._v(" Delete permanently "),
                            ],
                            1
                          ),
                        ]
                      ),
                      _setup.canRestore
                        ? _c(
                            _setup.UiTooltip,
                            {
                              attrs: {
                                title:
                                  "Restore the selected items to their original position",
                                position: "top",
                                "align-x": "center",
                              },
                            },
                            [
                              _c(
                                "button",
                                { on: { click: _setup.restoreToIdentity } },
                                [
                                  _c(_setup.Restore),
                                  _vm._v(" Restore to original identity "),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c(
                            _setup.UiTooltip,
                            {
                              attrs: {
                                title:
                                  "Cannot restore the selected item to their original identity",
                                position: "top",
                                "align-x": "center",
                              },
                            },
                            [
                              _c(
                                "button",
                                { staticClass: "disabled" },
                                [
                                  _c(_setup.Restore),
                                  _vm._v(" Restore to original identity "),
                                ],
                                1
                              ),
                            ]
                          ),
                      _setup.canRestoreNew
                        ? _c(
                            _setup.UiTooltip,
                            {
                              attrs: {
                                title:
                                  "Restores the selected items to a new identity",
                                position: "top",
                                "align-x": "center",
                              },
                            },
                            [
                              _c(
                                "button",
                                { on: { click: _setup.restoreToNewIdentity } },
                                [
                                  _c(_setup.RestoreNew),
                                  _vm._v(" Restore to new identity "),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c(
                            _setup.UiTooltip,
                            {
                              attrs: {
                                title:
                                  "Selected item cannot be restored to a new identity, you can copy its value instead",
                                position: "top",
                                "align-x": "center",
                              },
                            },
                            [
                              _c(
                                "button",
                                { staticClass: "disabled" },
                                [
                                  _c(_setup.RestoreNew),
                                  _vm._v(" Restore to new identity "),
                                ],
                                1
                              ),
                            ]
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "right" }, [
              false
                ? _c("div", { staticClass: "trash-type-select" }, [
                    _c("span", [_vm._v("View:")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _setup.ui.type,
                            expression: "ui.type",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _setup.ui,
                              "type",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          { domProps: { value: _setup.props.types.IDENTITY } },
                          [_vm._v("Identity")]
                        ),
                        _c(
                          "option",
                          { domProps: { value: _setup.props.types.ACTIVITY } },
                          [_vm._v("Activity")]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "pagination" }, [
                _setup.pages > 1
                  ? _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            return _setup.navPage(-1)
                          },
                        },
                      },
                      [_c(_setup.ChevronLeft)],
                      1
                    )
                  : _vm._e(),
                _c("span", [_vm._v(" " + _vm._s(_setup.currentSummary) + " ")]),
                _setup.pages > 1
                  ? _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            return _setup.navPage(1)
                          },
                        },
                      },
                      [_c(_setup.ChevronRight)],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "data-table",
              class: { selecting: _setup.data.selected.length > 0 },
            },
            [
              _c("table", [
                _vm._m(0),
                _c(
                  "tbody",
                  _vm._l(_setup.data.trash, function (item, index) {
                    return _c(
                      "tr",
                      {
                        key: index,
                        class: {
                          selected: _setup.isSelected(item),
                          "can-select": _setup.canSelect(item),
                        },
                      },
                      [
                        _c("td", [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _setup.toggleSelect(item)
                                },
                              },
                            },
                            [_c(_setup.Checkmark)],
                            1
                          ),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "item-value" }, [
                            _c("input", {
                              attrs: {
                                readonly: "true",
                                type: _setup.shouldShowItem(item)
                                  ? "text"
                                  : "password",
                              },
                              domProps: { value: item.item },
                            }),
                            _c(
                              "div",
                              { staticClass: "hover-reveal" },
                              [
                                _setup.isPasswordObject(item)
                                  ? _c(
                                      _setup.UiTooltip,
                                      {
                                        attrs: {
                                          title: "Show/Hide value",
                                          position: "top",
                                          "align-x": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _setup.toggleShowItem(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _setup.shouldShowItem(item)
                                              ? _c(_setup.Eye)
                                              : _c(_setup.EyeSlash),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _setup.shouldCopy(item)
                                  ? _c(
                                      _setup.UiTooltip,
                                      {
                                        attrs: {
                                          title: item.title || "copy",
                                          position: "top",
                                          "align-x": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _setup.copyItem(item)
                                              },
                                            },
                                          },
                                          [_c(_setup.CopyIcon)],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("td", { staticClass: "identity-name" }, [
                          item.logo
                            ? _c("img", {
                                staticClass: "identity-logo",
                                attrs: {
                                  src: item.logo,
                                  width: "24",
                                  height: "24",
                                },
                              })
                            : _vm._e(),
                          _c("span", [_vm._v(_vm._s(item.identity_name))]),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_setup.dateFormat(item.created_at))),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_setup.domainOnly(item.website_url))),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_setup.deletesIn(item.created_at))),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          ),
        ])
      : _c("div", { staticClass: "trash-empty" }, [
          _c(
            "div",
            [
              _c(_setup.TrashIcon),
              _c("h3", [_vm._v("There’s nothing here yet.")]),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("Item")]),
        _c("th", [_vm._v("Identity")]),
        _c("th", [_vm._v("Deleted On")]),
        _c("th", [_vm._v("URL")]),
        _c("th", [_vm._v("Gone In")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }