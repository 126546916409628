var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.Popper,
    _vm._g(
      _vm._b(
        {
          staticClass: "app-tooltip",
          attrs: {
            "has-click-open": false,
            "has-click-close": false,
            "has-outside-click-close": false,
            "has-mouse-enter-open": true,
            "has-mouse-leave-close": true,
            transition: "fade-top-200",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "app-tooltip__content" },
                      [_vm._t("content")],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "Popper",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }