var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesParagraph",
    { staticClass: "preferences-check-paragraph" },
    [
      _c("CheckButton", {
        attrs: { value: _vm.value, square: true, disabled: _vm.disabled },
        on: { input: _vm.toggle },
      }),
      _c(
        "span",
        {
          staticClass: "preferences-check-paragraph__label",
          on: { click: _vm.toggle },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }