var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "toggle", class: { active: _setup.props.status } },
    [_c("span")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }