import { createPassword } from "@/scripts/actions/crypto";
import { authEncrypt } from "@/scripts/actions/encryption";

export const generateSharingPassword = () =>
  createPassword({
    letters: true,
    numbers: true,
    symbols: true,
    similar: false,
    size: 20,
    words: false,
  });

export const generateSharingEncryption = async (
  password = generateSharingPassword()
) => {
  const cloakedEncryption = await window.CloakedEncryption.build();
  const sharedKeyPair = await cloakedEncryption.generateAsymmetricKeys();
  const salt = await cloakedEncryption.generateUserSalt();
  const sharedPasswordSecretBoxKey =
    await cloakedEncryption.generatePasswordSecretBoxKey(password, salt);
  const privateKey = await cloakedEncryption.encryptPrivateKey(
    sharedPasswordSecretBoxKey,
    sharedKeyPair.privateKey
  );

  const encryptedPassword = await authEncrypt(password);

  return {
    password: encryptedPassword,
    privateKey,
    salt,
    publicKey: sharedKeyPair.publicKey,
  };
};

export const generateNewPassword = async ({
  salt,
  private_key,
  public_key,
  decryptedPassword,
}) => {
  const cloakedEncryption = await window.CloakedEncryption.build();

  const sharedPasswordSecretBoxKey =
    await cloakedEncryption.generatePasswordSecretBoxKey(
      decryptedPassword,
      salt
    );

  const privateKey = await cloakedEncryption.decryptPrivateKey(
    sharedPasswordSecretBoxKey,
    private_key
  );

  const newPassword = generateSharingPassword();

  const newSharedPasswordSecretBoxKey =
    await cloakedEncryption.generatePasswordSecretBoxKey(newPassword, salt);

  const newPrivateKey = await cloakedEncryption.encryptPrivateKey(
    newSharedPasswordSecretBoxKey,
    privateKey
  );

  const newEncryptedPassword = await authEncrypt(newPassword);

  return {
    salt,
    publicKey: public_key,
    password: newEncryptedPassword,
    privateKey: newPrivateKey,
  };
};
