<script setup>
import AppModal from "@/components/ui/AppModal.vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import Button from "@/components/Button.vue";
import AutoChangeIcon from "@/assets/icons/auto-change.svg";
import { defineProps } from "vue";
import AutoChangeTermsOfUse from "@/components/auto-change/AutoChangeTermsOfUse.vue";

defineProps({
  identities: {
    type: Number,
    required: true,
  },
});
</script>

<template>
  <AppModal v-on="$listeners" v-bind="$attrs">
    <AppModalContent class="auto-change-start">
      <span class="auto-change-start__icon">
        <AutoChangeIcon class="auto-change-start__icon-icon" />
      </span>
      <AppModalTitle>
        AutoCloak {{ identities }} selected
        {{ identities !== 1 ? "identities" : "identity" }}
      </AppModalTitle>
      <AppModalParagraph>
        Cloaked will automatically...
        <ul class="auto-change-start__list">
          <li>launch a new tab in the background per selected identity</li>
          <li>log into the identity using the Cloaked extension</li>
          <li>navigate to the identity’s account settings</li>
          <li>
            generate a new email address, phone number, username and password
          </li>
          <li>update the information in Cloaked</li>
        </ul>
      </AppModalParagraph>
      <AppModalFooter>
        <AutoChangeTermsOfUse />
        <Button
          type="secondary"
          @click="
            $emit('input', false);
            $emit('close');
          "
          >Close</Button
        >
        <Button
          @click="
            $emit('input', false);
            $emit('close');
            $emit('auto-change');
          "
          >Start AutoCloak</Button
        >
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>

<style lang="scss">
.auto-change-start {
  &__icon {
    width: 72px;
    height: 72px;
    margin: 32px 32px 0;
    display: flex;
    color: $color-primary-0;
    background-color: $color-primary-100;
    border-radius: 100%;

    &-icon {
      width: 57px;
      height: 57px;
      margin: auto;
    }
  }

  &__list {
    list-style: initial;
    padding-left: 20px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
