<script setup>
import AutoChangeIcon from "@/assets/icons/auto-change.svg";
import { defineProps } from "vue";

defineProps({
  status: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <transition name="auto-change-spinner" appear>
    <button
      class="auto-change-spinner"
      :class="`auto-change-spinner--${status}`"
      v-on="$listeners"
    >
      <AutoChangeIcon class="auto-change-spinner__icon" />
    </button>
  </transition>
</template>

<style lang="scss">
.auto-change-spinner {
  border: none;
  cursor: pointer;
  width: 64px;
  height: 64px;
  background-color: $color-primary-0;
  color: $color-success;
  box-shadow: 0 30px 120px 0 rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 100ms ease-out;

  &--needsReview {
    color: $color-warning;
  }

  @keyframes bounce {
    50% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    from,
    to {
      transform: none;
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &--blockedByReview {
    color: $color-warning;
    animation: bounce 500ms 2;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &__icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 1.5s infinite linear;

    @at-root .auto-change-spinner--blockedByReview & {
      animation: unset;
    }
  }

  &-enter-active,
  &-leave-active {
    transition: all 250ms ease-in-out;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(100px);
  }
}
</style>
