<template>
  <section class="activity-empty-wrapper">
    <OctagonWarningIcon />
    <h1>Couldn't find</h1>
    <h1>"{{ searchText }}"</h1>
    <div>We are sorry, but your search didn’t have any results.</div>
  </section>
</template>
<script>
import OctagonWarningIcon from "@/assets/icons/octagon-warning.svg";
export default {
  name: "ActivityEmptyScreen",
  props: ["searchText"],
  components: {
    OctagonWarningIcon,
  },
};
</script>
<style lang="scss" scoped>
.activity-empty-wrapper {
  color: $color-primary-50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 160px);
  align-self: center;
  > h1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    text-align: center;
    &:first-of-type {
      margin-top: 10px;
    }
  }
  > div {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $color-primary-60;
    text-align: center;
  }
}
</style>
