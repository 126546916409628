var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "video",
    _vm._b(
      {
        key: _setup.props.src,
        staticClass: "app-modal-video",
        attrs: { width: _setup.props.width, height: _setup.props.height },
      },
      "video",
      _vm.$attrs,
      false
    ),
    [
      _c("source", {
        attrs: { src: _setup.props.src, type: _setup.props.type },
      }),
      _vm._v(" Your browser does not support the video tag. "),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }