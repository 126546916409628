<template>
  <div class="activity-call-display-voicemail">
    <div class="vm-play">
      <button
        v-if="playing && playing.id === recording.id"
        class="playing"
        @click="pauseVoicemail(recording)"
      >
        <ActivityPauseIcon />
      </button>
      <button v-else @click="playVoicemail(recording)">
        <PlayVoicemailIconDark />
      </button>
      <div class="vm-line">
        <div
          class="vm-circle"
          :id="`vm-circle-${recording.id}`"
          :style="`animation-duration: ${animationLengthSec(recording)}s`"
        ></div>
      </div>
      <div class="vm-duration">
        {{ durationDisplay(recording) }}
      </div>
    </div>

    <div v-if="recording.transcription_status === 'available'" class="vm-title">
      Transcription {{ !recording.transcription_text ? "unavailable" : "" }}
    </div>
    <div
      v-if="recording.transcription_status === 'available'"
      class="vm-body truncate"
      :id="`vm-body-${idx}`"
    >
      {{ recording.transcription_text }}
    </div>
    <div
      v-if="recording.transcription_status === 'available' && isTruncated(idx)"
      class="vm-read-more"
      @click="expandTranscript(idx)"
    >
      Read more
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import { ActivityPauseIcon, PlayVoicemailIconDark } from "@/assets/icons";

export default {
  name: "VoicemailPlayer",
  props: {
    recording: Object,
    idx: Number,
  },
  components: {
    PlayVoicemailIconDark,
    ActivityPauseIcon,
  },
  computed: {
    playing() {
      return this.$store.state.media.playing;
    },
  },
  beforeMount() {
    this.$store.dispatch("media/register", {
      recording: this.recording,
      id: `${this.idx}-vm`,
    });
  },
  beforeDestroy() {
    this.$store.dispatch("media/unregister", {
      recording: this.recording,
      id: `${this.idx}-vm`,
    });
  },
  methods: {
    durationDisplay(voicemail) {
      const duration = moment.duration(voicemail.duration);
      if (this.playing && this.playing.id === voicemail.id) {
        const played = this.$store.state.media.progress;
        duration.subtract(played, "milliseconds");
      }
      const min = duration.minutes().toString().padStart(2, "0");
      const sec = duration.seconds().toString().padStart(2, "0");
      return `${min}:${sec}`;
    },
    pauseVoicemail(recording) {
      this.$store.dispatch("media/playAudio", recording);
    },
    playVoicemail(recording) {
      this.$store.dispatch("media/playAudio", recording);
    },
    resetVoicemailData() {
      this.audioState = null;
      this.activeRecordingId = null;
      this.activeVoicemail = null;
    },
    isTruncated(idx) {
      const el = document.getElementById(`vm-body-${idx}`);
      if (!el) return false;
      return el.scrollHeight > el.clientHeight;
    },
    expandTranscript(idx) {
      const el = document.getElementById(`vm-body-${idx}`);
      el.classList.remove("truncate");
    },
    animationLengthSec(recording) {
      const duration = moment.duration(recording.duration);
      return duration.asSeconds();
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-call-display-voicemail {
  .vm-play {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 5px;

    button {
      padding: 0;
      padding-top: 5px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      &.playing {
        svg {
          rect {
            filter: invert(100%);
          }
        }
      }
    }
    .vm-duration {
      font-weight: 400;
      font-size: 11px;
      text-align: right;
      letter-spacing: -0.1px;
      color: $color-primary-60;
    }
    .vm-line {
      height: 4px;
      width: 100%;
      min-width: 125px;
      background-color: $color-primary-30;
      position: relative;

      .vm-circle {
        height: 100%;
        width: 0;
        background-color: $color-primary-100;
        position: absolute;
        left: 0;
        animation: animate;
        animation-timing-function: linear;
        animation-play-state: paused;

        &.playVoicemail {
          animation-play-state: running;
        }
        &.pauseVoicemail {
          animation-play-state: paused;
        }
      }
    }
  }

  .vm-title {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    margin-top: 8px;
  }

  .vm-body {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    &.truncate {
      @include line-clamp(3);
    }
  }
  .vm-read-more {
    margin-top: 22.5px;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: -0.1px;
    color: $color-primary-100;
  }
}
</style>
