var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "custom-field-form" },
    [
      _c("h3", { staticClass: "custom-field-form__title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _vm._t("default"),
      _c(
        "div",
        { staticClass: "custom-field-form__actions" },
        [
          _c(
            _setup.Button,
            {
              attrs: { type: "secondary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            _setup.Button,
            {
              attrs: {
                loading: _vm.$attrs.isLoading,
                disabled: _setup.isSaveDisabled,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("attempt-save")
                },
              },
            },
            [_vm._v(" Save ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }