var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "onboarding",
      class: { "onboarding--animating-done": _vm.isInitialAnimationDone },
    },
    [
      _c("h1", { staticClass: "onboarding__title" }, [
        _vm._v("Get started with Cloaked"),
      ]),
      _c("h2", { staticClass: "onboarding__subtitle" }, [
        _vm._v(
          " Going through the following guide will help you better understand how Cloaked works. "
        ),
      ]),
      _c(
        "TransitionGroup",
        {
          staticClass: "onboarding__steps",
          attrs: { tag: "ul", name: "reorder" },
        },
        _vm._l(_vm.orderedSteps, function (step) {
          return _c(
            "li",
            { key: step, staticClass: "onboarding__steps-item" },
            [
              _c("OnboardingStep", {
                attrs: {
                  title: _vm.STEPS_CONFIGURATION[step].title,
                  subTitle: _vm.STEPS_CONFIGURATION[step].subTitle,
                  image: _vm.STEPS_CONFIGURATION[step].image,
                  clickable: !!_vm.STEPS_CONFIGURATION[step].clickable,
                  "is-completed":
                    _vm.$store.getters["onboarding/isStepCompleted"](step),
                  "is-active": _vm.$route.meta.step === step,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.$router.push(_vm.STEPS_CONFIGURATION[step].route)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "Button",
        {
          staticClass: "onboarding__dismiss",
          attrs: { type: "secondary" },
          on: {
            click: function ($event) {
              _vm.isDismissOpen = true
            },
          },
        },
        [_vm._v(" Dismiss checklist ")]
      ),
      _c("OnboardingDismiss", {
        model: {
          value: _vm.isDismissOpen,
          callback: function ($$v) {
            _vm.isDismissOpen = $$v
          },
          expression: "isDismissOpen",
        },
      }),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }