<script setup>
import Back from "@/assets/icons/back-modal-full.svg";
import { defineProps, defineEmits } from "vue";

const emit = defineEmits(["click"]);

const props = defineProps({
  isAbsolute: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <Back
    class="app-modal-back"
    :class="{ 'app-modal-back--absolute': props.isAbsolute }"
    @click.native="emit('click')"
    v-on="$listeners"
  />
</template>

<style lang="scss">
.app-modal-back {
  padding: 24px;
  box-sizing: content-box;
  cursor: pointer;

  path {
    fill: $color-primary-100;
  }

  &:hover {
    opacity: 0.8;
  }

  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
