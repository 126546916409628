var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "referral-container" },
    [
      _c(_setup.ModalTemplate, {
        attrs: { show: _setup.ui.modal },
        on: {
          close: function ($event) {
            _setup.ui.modal = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("div", { staticClass: "invite-icon" }, [
                  _c("div", [_c(_setup.InviteSent)], 1),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "invite-modal-body" }, [
                  _c("h1", [_vm._v("Invite sent!")]),
                  _c("p", [
                    _vm._v(
                      " Be sure to let the person you’ve invited to Cloaked know to check their inbox. "
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  _setup.Button,
                  {
                    on: {
                      click: function ($event) {
                        _setup.ui.modal = false
                      },
                    },
                  },
                  [_vm._v(" Done ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("transition", { attrs: { name: "fade-500" } }, [
        !_setup.ui.details
          ? _c("div", { staticClass: "referral-general" }, [
              _c("div", { staticClass: "referral-hero" }, [
                _c("div", { staticClass: "referral-hero-title" }, [
                  _c("div", { staticClass: "referral-hero-wrap" }, [
                    _c("h1", [_vm._v("Share the power of privacy")]),
                  ]),
                  _setup.hasInvites
                    ? _c("h3", [_vm._v("Add a recipient’s email address")])
                    : _c("h3", [
                        _vm._v(
                          " You’ve reached your referral limit. Thanks for sharing Cloaked. "
                        ),
                      ]),
                  _setup.hasInvites
                    ? _c(
                        "div",
                        {
                          staticClass: "hero-actions",
                          class: { disabled: !_setup.hasInvites },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _setup.ui.input,
                                expression: "ui.input",
                              },
                            ],
                            attrs: {
                              readonly: !_setup.hasInvites,
                              type: "text",
                              placeholder: "Invite email address",
                            },
                            domProps: { value: _setup.ui.input },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _setup.send.apply(null, arguments)
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _setup.ui,
                                  "input",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c(
                            "button",
                            {
                              class: {
                                active: _setup.canSend && !_setup.ui.sending,
                                disabled: !_setup.hasInvites,
                              },
                              on: { click: _setup.send },
                            },
                            [
                              _setup.ui.sending
                                ? _c("span", [_vm._v("Sending..")])
                                : _c("span", [_vm._v("Send invite")]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "referral-progress-container",
                    class: {
                      "referral-progress-container__none-sent":
                        _setup.data.emails.length === 0,
                    },
                    on: {
                      click: function ($event) {
                        _setup.data.emails.length > 0
                          ? (_setup.ui.details = true)
                          : null
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "referral-progress" },
                      [
                        _setup.data.emails.length > 0
                          ? _c(
                              _setup.UiTooltip,
                              {
                                attrs: {
                                  position: "bottom",
                                  "align-x": "center",
                                  width: "225",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "content",
                                      fn: function () {
                                        return [_vm._v(" Review invites ")]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  282033076
                                ),
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pie animate progress-bar",
                                    style: `--p:${_setup.remainingInvitesCssNumber};`,
                                  },
                                  [
                                    _c("div", [
                                      _c("h1", [
                                        _c("strong", [
                                          _vm._v(_vm._s(_setup.remaining)),
                                        ]),
                                        _vm._v(
                                          " /" +
                                            _vm._s(_setup.allowedReferrals) +
                                            " "
                                        ),
                                      ]),
                                      _c("span", [
                                        _vm._v("available "),
                                        _c("br"),
                                        _vm._v("invites"),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "pie animate progress-bar",
                                style: `--p:${_setup.remainingInvitesCssNumber};`,
                              },
                              [
                                _c("div", [
                                  _c("h1", [
                                    _c("strong", [
                                      _vm._v(_vm._s(_setup.remaining)),
                                    ]),
                                    _vm._v(
                                      " /" +
                                        _vm._s(_setup.allowedReferrals) +
                                        " "
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v("available "),
                                    _c("br"),
                                    _vm._v("invites"),
                                  ]),
                                ]),
                              ]
                            ),
                        _c(
                          "div",
                          { staticClass: "referral-progress-text" },
                          [
                            _c(_setup.Hourglass),
                            _c("span", [
                              _c("strong", [
                                _vm._v(_vm._s(_setup.inviteCounts.pending)),
                              ]),
                              _vm._v(" pending invites "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "referral-content" }, [
                _c("div", { staticClass: "referral-content-left" }, [
                  _c("h1", [_vm._v("How to send new invites")]),
                  _c("ul", [
                    _c("li", [
                      _c("span", [_vm._v("1")]),
                      _c("div", [
                        _c("h2", [_vm._v("You’ll need their email address")]),
                        _c("p", [
                          _vm._v(
                            "Your invitation will be sent to them instantly"
                          ),
                        ]),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", [_vm._v("2")]),
                      _c("div", [
                        _c("h2", [
                          _vm._v("Invites are exclusive to each person"),
                        ]),
                        _c("p", [
                          _vm._v(" Invites are claimed using a referral code,"),
                          _c("br"),
                          _vm._v(" once they are claimed you’ll be notified "),
                        ]),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", [_vm._v("3")]),
                      _c("div", [
                        _c("h2", [_vm._v("You get to decide")]),
                        _c("p", [
                          _vm._v("Help us spread the word about Cloaked"),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "referral-content-right" },
                  [
                    _c("h1", [_vm._v("Sent invitations")]),
                    _vm._l(
                      _setup.mainPagePreviewEmails,
                      function (email, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _setup.mainPagePreviewEmails.length > 0,
                                expression: "mainPagePreviewEmails.length > 0",
                              },
                            ],
                            key: index,
                            staticClass: "email-list",
                          },
                          [
                            email.status.toLowerCase() === "pending"
                              ? _c(
                                  "div",
                                  { staticClass: "email-item" },
                                  [
                                    _c(_setup.YellowClock),
                                    _c(
                                      "div",
                                      { staticClass: "email-content" },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(email.sent_to_email)),
                                        ]),
                                        _c("div", { staticClass: "subtitle" }, [
                                          _vm._v(
                                            " Your friend hasn't performed a qualifying action yet "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : email.status.toLowerCase() === "accepted"
                              ? _c(
                                  "div",
                                  { staticClass: "email-item" },
                                  [
                                    _c(_setup.Checkmark),
                                    _c(
                                      "div",
                                      { staticClass: "email-content" },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(email.sent_to_email)),
                                        ]),
                                        _c("div", { staticClass: "subtitle" }, [
                                          _vm._v(
                                            " Done! Your friend has performed a qualifying action "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                    _c("div", [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _setup.data.emails.length > 0,
                              expression: "data.emails.length > 0",
                            },
                          ],
                          staticClass: "community-link",
                          on: {
                            click: function ($event) {
                              _setup.ui.details = true
                            },
                          },
                        },
                        [_vm._v(" Review Invites ")]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _setup.data.emails.length === 0,
                            expression: "data.emails.length === 0",
                          },
                        ],
                        staticClass: "invites-empty",
                      },
                      [
                        _c(_setup.InviteSent),
                        _c("h3", [_vm._v("No invitations sent")]),
                        _c("p", [
                          _vm._v("Your sent invitations will appear here."),
                        ]),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "term-links" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.cloaked.app/terms-of-service",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Terms and conditions")]
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.cloaked.app/faq",
                      target: "_blank",
                    },
                  },
                  [_vm._v("FAQ")]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fade-500" } }, [
        _setup.ui.details
          ? _c("div", { staticClass: "referral-details" }, [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      _setup.ui.details = false
                    },
                  },
                },
                [_c(_setup.Chevron), _vm._v(" Back")],
                1
              ),
              _c("div", { staticClass: "referral-details-container" }, [
                _c("div", { staticClass: "referral-details-main" }, [
                  _c("div", { staticClass: "referral-detail-left" }, [
                    _c("div", { staticClass: "referral-detail-hero" }, [
                      _c("h1", [_vm._v("Review Invites")]),
                      _c("p", [
                        _vm._v(
                          " Below are the individuals you’ve invited who have accepted your Cloaked Private Beta invitations. "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "review-list" }, [
                      _c("h1", [
                        _vm._v(
                          _vm._s(_setup.inviteCounts.pending) +
                            " Pending invites"
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _setup.inviteCounts.pending > 0,
                              expression: "inviteCounts.pending > 0",
                            },
                          ],
                          staticClass: "email-list",
                        },
                        _vm._l(
                          _setup.invitesPagePreviewPending,
                          function (email, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "email-item email-details",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(_setup.YellowClock),
                                    _c(
                                      "div",
                                      { staticClass: "email-content" },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(email.sent_to_email)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  _setup.Button,
                                  {
                                    attrs: {
                                      secondary: "",
                                      primary: false,
                                      tertiary: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _setup.withdraw(email)
                                      },
                                    },
                                  },
                                  [_vm._v(" Withdraw ")]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                      _setup.inviteCounts.pending > 3
                        ? _c(
                            "button",
                            {
                              staticClass: "see-toggle",
                              on: {
                                click: function ($event) {
                                  return _setup.showMoreToggle(
                                    _setup.PENDING,
                                    _setup.emailsToShow.pending
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _setup.showButtonText(
                                      _setup.emailsToShow.pending
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _setup.invitesPagePreviewAccepted.length > 0
                      ? _c("div", { staticClass: "review-list" }, [
                          _c("h1", [_vm._v("Accepted invites")]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _setup.inviteCounts.accepted > 0,
                                  expression: "inviteCounts.accepted > 0",
                                },
                              ],
                              staticClass: "email-list",
                            },
                            _vm._l(
                              _setup.invitesPagePreviewAccepted,
                              function (email, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "email-item" },
                                  [
                                    _c(_setup.Checkmark),
                                    _c(
                                      "div",
                                      { staticClass: "email-content" },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(email.sent_to_email)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                          _setup.inviteCounts.accepted > 3
                            ? _c(
                                "button",
                                {
                                  staticClass: "see-toggle",
                                  on: {
                                    click: function ($event) {
                                      return _setup.showMoreToggle(
                                        _setup.ACCEPTED,
                                        _setup.emailsToShow.accepted
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _setup.showButtonText(
                                          _setup.emailsToShow.accepted
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "referral-detail-right" }, [
                    _c(
                      "div",
                      {
                        staticClass: "referral-progress-container",
                        class: {
                          "referral-progress-container__none-sent":
                            _setup.ui.details,
                        },
                      },
                      [
                        _c("div", { staticClass: "referral-progress" }, [
                          _c(
                            "div",
                            {
                              staticClass: "pie animate progress-bar",
                              style: `--p:${_setup.remainingInvitesCssNumber};`,
                            },
                            [
                              _c("div", [
                                _c("h1", [
                                  _c("strong", [
                                    _vm._v(_vm._s(_setup.remaining)),
                                  ]),
                                  _vm._v(
                                    " /" + _vm._s(_setup.allowedReferrals) + " "
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v("available "),
                                  _c("br"),
                                  _vm._v("invites"),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "referral-progress-text" },
                            [
                              _c(_setup.Hourglass),
                              _c("span", [
                                _c("strong", [
                                  _vm._v(_vm._s(_setup.inviteCounts.pending)),
                                ]),
                                _vm._v(" pending invites "),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "term-links" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.cloaked.app/terms-of-service",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Terms and conditions")]
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://www.cloaked.app/faq",
                        target: "_blank",
                      },
                    },
                    [_vm._v("FAQ")]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }