var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.AppModal,
    _vm._g(_vm._b({}, "AppModal", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        _setup.AppModalContent,
        [
          _c(_setup.AppModalTitle, [
            _vm._v(" Are you sure you want to delete this information? "),
          ]),
          _c(_setup.AppModalParagraph, [
            _vm._v(" You will not be able to recover it. "),
          ]),
          _c(
            _setup.AppModalFooter,
            [
              _c(
                _setup.Button,
                {
                  attrs: { type: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("input", false)
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                _setup.Button,
                {
                  attrs: { type: "danger", loading: _vm.$attrs.isLoading },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("delete")
                    },
                  },
                },
                [_vm._v(" Yes, delete ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }