var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      staticClass: "sidebar-header-button",
      class: {
        "sidebar-header-button--no-background": _vm.noBackground,
        "sidebar-header-button--active": _vm.active,
        "sidebar-header-button--wide": _vm.wide,
      },
      attrs: { "aria-id": "SidebarCloseButton", disabled: _vm.disabled },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }