<script setup>
import { UiBanner } from "@/components";
import Button from "@/components/Button.vue";
import { AddVerifyEmail } from "@/components/modals";
import { confirm_contact } from "@/api/actions/feedback";
import EmailService from "@/api/actions/email-service";
import { useToast } from "@/hooks";

import { reactive, watch, onBeforeUnmount, defineEmits, onMounted } from "vue";
import store from "@/store";

const toast = useToast();

const state = reactive({
  email: null,
  isVisible: false,
});
const emit = defineEmits(["visible"]);

watch(
  () => state.isVisible,
  (newValue) => {
    emit("visible", newValue);
  },
  { immediate: true }
),
  onMounted(() => {
    window.addEventListener("cloak:refresh-emails", getEmail);
    getEmail();
  });

onBeforeUnmount(() => {
  window.removeEventListener("cloak:refresh-emails", getEmail);
});

function getEmail() {
  return EmailService.getUserEmails().then(({ data }) => {
    const hasVerifiedAndPrimary = data.results.find(
      (f) => f.verified && f.primary
    );
    state.isVisible = false;

    if (!hasVerifiedAndPrimary) {
      state.email = data.results.filter((f) => !f.verified)[0];
      state.isVisible = true;
    }
  });
}

function open() {
  confirm_contact({
    type: "email",
    contact: state.email.email,
    id: state.email.id,
    callback: () => {
      const email = { ...state.email };
      EmailService.makePrimary(email.id);
      state.email = null;
      toast.success("email confirmed");
    },
  });
}

function addVerify() {
  store.dispatch("openModal", {
    customTemplate: {
      template: AddVerifyEmail,
      props: {
        setPrimary: true,
      },
      events: {
        "email-verified": () => {
          state.isVisible = false;
        },
      },
    },
  });
}
</script>

<template>
  <UiBanner class="confirm-banner" danger v-if="state.isVisible">
    <div v-if="state.email">
      <div>Please verify your recovery email {{ state.email.email }}</div>
      <Button type="danger" @click="open">Verify Email</Button>
    </div>
    <div v-else>
      <div>Add recovery email to keep your account safe</div>
      <Button type="danger" @click="addVerify">Add Email</Button>
    </div>
  </UiBanner>
</template>

<style lang="scss" scoped>
.confirm-banner {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: $color-alert;
  color: $white;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .button {
    height: 32px;
    color: $white;
  }
}
</style>
