<script setup>
import { ref, defineProps, computed } from "vue";
import store from "@/store";
import { IdentityIcon } from "@/components";
import InlineSvg from "@/components/InlineSvg";
import { useRoute } from "vue-router/composables";
import { useToast } from "@/hooks";

const route = useRoute();
const toast = useToast();

function identity(id) {
  const identity = store.state.localdb.db_cloaks.find((item) => item.id === id);
  return identity;
}

const props = defineProps({
  status: {
    type: Boolean,
    default: false,
  },
  card: {
    type: Object,
  },
});

const card = ref(props.card);

const realCardNumbers = computed(() => {
  return store.state.cards.currentCard;
});

const available = computed(() => {
  return card.value.transaction_period_limit - card.value.spent_period;
});

function convertToDollar(amount) {
  return (amount / 100)
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    .replace(/\.0+$/, "");
}

function lastFourDigits(number) {
  return number.slice(-4);
}

const hiddenPan = (pan) => {
  return "••••••••••••" + lastFourDigits(pan);
};

const splitPan = (pan) => {
  return (
    pan.slice(0, 4) +
    "  " +
    pan.slice(4, 8) +
    "  " +
    pan.slice(8, 12) +
    "  " +
    lastFourDigits(pan)
  );
};

function copyToClipboard(text, data) {
  navigator.clipboard.writeText(data);
  toast.success(text + " copied to clipboard");
}

function openIdentity(identity) {
  if (
    !store.state.rightPanel.cloak ||
    store.state.rightPanel.cloak.id !== identity.id
  ) {
    store.dispatch("fetchPopulatedData", identity).then((data) => {
      store.commit("compose", null);
      store.dispatch("openCloakDetails", {
        cloak: data,
        skipNav: true,
      });
    });
  }
}
</script>

<template>
  <router-link
    :to="'/wallet/card/' + card.id"
    class="card"
    :class="{ active: route.params.id === card.id }"
    @click="toggleCard()"
    v-if="props.card"
  >
    <div class="title">
      <IdentityIcon
        class="icon"
        :identity="identity(JSON.parse(card.identity_id))"
        :override="{ height: '60px', width: '60px' }"
        @click.native="openIdentity(identity(JSON.parse(card.identity_id)))"
      />
      <div class="info">
        <h1 v-if="card.identity_id">
          {{
            identity(JSON.parse(card.identity_id))?.nickname || "New identity"
          }}
        </h1>
        <p>{{ convertToDollar(available) }} available</p>
      </div>
    </div>

    <div class="card-details">
      <div class="card-number">
        <p
          class="hover"
          v-if="realCardNumbers.pan && route.params.id === card.id"
          @click="copyToClipboard('Card number', realCardNumbers.pan)"
        >
          {{ splitPan(realCardNumbers.pan) }}
        </p>
        <p v-else>{{ splitPan(hiddenPan(card.pan)) }}</p>
      </div>

      <div class="valid">
        <p>Valid thru</p>
        <p
          class="hover"
          v-if="
            realCardNumbers.expMonth &&
            realCardNumbers.expYear &&
            route.params.id === card.id
          "
          @click="
            copyToClipboard(
              'Valid thru date',
              realCardNumbers.expMonth + '/' + realCardNumbers.expYear
            )
          "
        >
          {{ realCardNumbers.expMonth }}/{{ realCardNumbers.expYear }}
        </p>

        <p v-else>••/••</p>
      </div>

      <div class="cvv">
        <p>CVV</p>
        <p
          class="hover"
          @click="copyToClipboard('CVV', realCardNumbers.cvv)"
          v-if="realCardNumbers.cvv && route.params.id === card.id"
        >
          {{ realCardNumbers.cvv }}
        </p>
        <p v-else>•••</p>
      </div>
    </div>

    <InlineSvg class="card-icon" name="mastercard" />
  </router-link>
</template>

<style lang="scss" scoped>
.card {
  --distance: calc(222px - 95px);

  width: 100%;
  height: 222px;
  border-radius: 12px;
  border: 1.009px solid $color-primary-10;
  background: radial-gradient(
    157.8% 94.4% at 54.96% 48%,
    $color-surface 34.38%,
    $color-primary-20 100%
  );
  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  padding: 22px 17px;
  overflow: hidden;
  position: relative;
  display: block;

  &:not(:first-child) {
    margin-top: calc(var(--distance) * -1);
  }

  &:hover {
    cursor: pointer;
  }

  &.active ~ .card {
    transform: translateY(calc(var(--distance) + 10px));
  }

  &.active {
    background: radial-gradient(
      157.8% 94.4% at 54.96% 48%,
      $color-primary-100 34.38%,
      $color-primary-90 100%
    );

    &:hover {
      cursor: default;
    }

    .title {
      .icon {
        pointer-events: unset;
        cursor: pointer;
      }

      h1 {
        color: $color-primary-0;
      }

      p {
        color: $color-primary-0;
      }
    }

    .card-details {
      color: $color-primary-0;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;

    .icon {
      pointer-events: none;
    }

    h1 {
      font-size: 18.974px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.422px;
      color: $color-primary-100;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      font-size: 12.65px;
      font-style: normal;
      font-weight: 500;
      margin-top: 1px;
      color: $color-primary-100;
    }
  }

  .card-details {
    position: relative;
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    color: $color-primary-100;

    .card-number {
      width: 100%;
      margin-bottom: 13px;

      p {
        font-size: 20.171px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.202px;
        display: inline-block;
        font-family: Monospace;

        &.hover {
          &:hover {
            background-color: $color-primary-90;
            cursor: pointer;
          }
        }
      }
    }

    .valid {
      margin-right: 57px;
    }

    .valid,
    .cvv {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;

      p {
        &:first-child {
          font-size: 12.103px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 4px;
          font-family: inherit;
        }

        font-size: 16.866px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.211px;
        display: inline-block;
        font-family: Monospace;

        &.hover {
          &:hover {
            background-color: $color-primary-90;
            cursor: pointer;
          }
        }
      }
    }
  }

  .card-icon {
    position: absolute;
    bottom: 22px;
    right: 17px;
    width: 64px;
    height: auto;
  }
}
</style>
