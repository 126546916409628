var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recent-imports" },
    [
      _c("h1", { staticClass: "recent-imports__title" }, [
        _vm._v("Recently imported"),
      ]),
      _vm.recentImports.length > 0
        ? _vm._l(_vm.recentImports, function (recentImport) {
            return _c(
              "div",
              { key: recentImport.uuid, staticClass: "recent-imports__import" },
              [
                _c("div", { staticClass: "recent-imports__import-head" }, [
                  _c("span", { staticClass: "recent-imports__import-date" }, [
                    _vm._v(
                      _vm._s(
                        new Intl.DateTimeFormat("en-US", {
                          dateStyle: "long",
                        }).format(new Date(recentImport.date))
                      )
                    ),
                  ]),
                ]),
                _c(
                  "ul",
                  { staticClass: "recent-imports__import-list" },
                  _vm._l(recentImport.identities, function (identity) {
                    return _c(
                      "li",
                      { key: identity.id },
                      [
                        _c("CloakCard", {
                          attrs: { identity: identity, "no-select": true },
                          on: {
                            click: function ($event) {
                              return _vm.onCloakClick(identity)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm.$store.getters["recentlyImported/hasNextPage"]
                  ? _c("InfiniteTrigger", {
                      staticClass: "recent-imports__infinite-trigger",
                      on: {
                        infinite: function ($event) {
                          return _vm.$store.dispatch("recentlyImported/fetch")
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          })
        : _c("div", { staticClass: "recent-imports__empty" }, [
            _vm._v("No identities found"),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }