var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "transactions" }, [
    _c("div", { staticClass: "title" }, [
      !_setup.route.params.id && !_setup.loading
        ? _c("h1", [_vm._v("All wallet activity")])
        : _vm._e(),
      _setup.route.params.id && !_setup.loading
        ? _c("h1", [
            _vm._v(
              " " +
                _vm._s(
                  _setup.identity(_setup.currentCard.identity_id).nickname ||
                    "New identity"
                ) +
                " card activity "
            ),
          ])
        : _vm._e(),
      _setup.loading ? _c("h1", { staticClass: "loading" }) : _vm._e(),
    ]),
    !_setup.loading
      ? _c("div", { staticClass: "list" }, [
          _setup.transactions.count !== 0
            ? _c(
                "div",
                _vm._l(_setup.transactions.results, function (transaction) {
                  return _c(
                    "div",
                    { key: transaction.id, staticClass: "transaction" },
                    [
                      _c(_setup.IdentityIcon, {
                        staticClass: "icon",
                        attrs: {
                          identity: _setup.identity(
                            JSON.parse(transaction.identity)
                          ),
                        },
                      }),
                      _c("div", { staticClass: "information" }, [
                        _c("p", [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _setup.identity(
                                  JSON.parse(transaction.identity)
                                )?.nickname || "Deleted Identity"
                              )
                            ),
                          ]),
                          _c(
                            "span",
                            [
                              _vm._v(
                                _vm._s(
                                  _setup.transactionStatus(transaction.status)
                                    .text
                                ) + " "
                              ),
                              _c(_setup.InlineSvg, {
                                attrs: {
                                  name: _setup.transactionStatus(
                                    transaction.status
                                  ).icon,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _setup
                                  .moment(transaction.created_at)
                                  .format("MMMM Do YYYY, h:mm A")
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "amount", class: transaction.status },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _setup.convertToDollar(
                                  transaction.transaction_amount
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _c("div", { staticClass: "empty" }, [
                _c("p", [_vm._v("No transactions found")]),
              ]),
        ])
      : _vm._e(),
    _setup.loading
      ? _c(
          "div",
          { staticClass: "loader" },
          [_c(_setup.InlineSvg, { attrs: { name: "loading-small" } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }