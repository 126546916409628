var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ModalTemplate, {
    attrs: { show: _setup.props.show, "no-close": "", width: "375px" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _setup.currentCard
              ? _c("div", { staticClass: "text" }, [
                  _c("div", { staticClass: "title" }, [
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _c(_setup.InlineSvg, {
                          attrs: { name: "spending-limit" },
                        }),
                      ],
                      1
                    ),
                    _c("h1", [_vm._v("Spending limit")]),
                    _c("p", [_vm._v(_vm._s(_setup.title))]),
                  ]),
                  _c("div", { staticClass: "block" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _setup.dollars,
                          expression: "dollars",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _setup.dollars },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _setup.dollars = $event.target.value
                          },
                          _setup.updateCents,
                        ],
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        on: {
                          click: function ($event) {
                            return _setup.saveForm()
                          },
                        },
                      },
                      [_vm._v("Save")]
                    ),
                    _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            return _setup.emit("close")
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }