var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _c("transition", { attrs: { name: "fade-100", mode: "out-in" } }, [
        _vm.status === _setup.AutoChangeStatus.WAITING
          ? _c(
              "span",
              _vm._g(
                {
                  key: "waiting",
                  class: `auto-change-icon auto-change-icon--${_vm.status}`,
                },
                _vm.$listeners
              ),
              [
                _c(_setup.AutoChangeIcon, {
                  staticClass: "auto-change-icon__icon",
                }),
              ],
              1
            )
          : _vm.status === _setup.AutoChangeStatus.IN_PROGRESS
          ? _c(
              "span",
              _vm._g(
                {
                  key: "inProgress",
                  class: `auto-change-icon auto-change-icon--${_vm.status}`,
                },
                _vm.$listeners
              ),
              [
                _c(_setup.AutoChangeIcon, {
                  staticClass: "auto-change-icon__icon",
                }),
              ],
              1
            )
          : _vm.status === _setup.AutoChangeStatus.SKIPPED
          ? _c(
              "span",
              _vm._g(
                {
                  key: "skipped",
                  class: `auto-change-icon auto-change-icon--${_vm.status}`,
                },
                _vm.$listeners
              ),
              [
                _c(_setup.AutoChangeIcon, {
                  staticClass: "auto-change-icon__icon",
                }),
              ],
              1
            )
          : _vm.status === _setup.AutoChangeStatus.NEEDS_REVIEW
          ? _c(
              "span",
              _vm._g(
                {
                  key: "needsReview",
                  class: `auto-change-icon auto-change-icon--${_vm.status}`,
                },
                _vm.$listeners
              ),
              [
                _c(_setup.AutoChangeIcon, {
                  staticClass: "auto-change-icon__icon",
                }),
              ],
              1
            )
          : _vm.status === _setup.AutoChangeStatus.ERROR
          ? _c(
              "span",
              _vm._g(
                {
                  key: "error",
                  class: `auto-change-icon auto-change-icon--${_vm.status}`,
                },
                _vm.$listeners
              ),
              [
                _c(_setup.AutoChangeIcon, {
                  staticClass: "auto-change-icon__icon",
                }),
              ],
              1
            )
          : _vm.status === _setup.AutoChangeStatus.SUCCESS
          ? _c(
              "span",
              _vm._g(
                {
                  key: "success",
                  class: `auto-change-icon auto-change-icon--${_vm.status}`,
                },
                _vm.$listeners
              ),
              [
                _c(_setup.AutoChangeIcon, {
                  staticClass: "auto-change-icon__icon",
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }