<template>
  <div>
    <div class="page">
      <div class="activity-list">
        <ActivityPreviewList
          ref="list"
          :cloakId="cloakId"
          :type="type"
          :isRequests="type === 'requests'"
          :key="type || 'all'"
          :multiSelected="multiSelected"
          :singleSelected="singleSelected"
          :multiSelectEnabled="multiSelectEnabled"
          @select="select"
          @enableMultiSelect="enableMultiSelect"
          @setMultiSelect="setMultiSelect"
        />
      </div>
      <div class="activity-container">
        <ActivityEmailDisplay
          v-if="singleSelected && singleSelected.thread_id"
          :thread="singleSelected"
          @removed="removed"
          @updated="updated"
          @refresh="refresh"
          :key="singleSelected.thread_id"
        />
        <ActivityConversationDisplay
          v-else-if="singleSelected && singleSelected?.conversation_id"
          :thread="singleSelected"
          @removed="removed"
          @updated="updated"
          @refresh="refresh"
          :key="singleSelected?.conversation_id"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  ActivityPreviewList,
  ActivityEmailDisplay,
  ActivityConversationDisplay,
} from "@/components";
import ActivityService from "@/api/actions/activity-service";
import { decode } from "@/scripts/hash";
import api from "@/api/api";

export default {
  name: "ActivityPage",
  components: {
    ActivityPreviewList,
    ActivityEmailDisplay,
    ActivityConversationDisplay,
  },

  data() {
    return {
      loadingUnknown: false,
      unknownContacts: [],
      unknownNext: null,
      unknownPrev: null,
      singleSelected: null,
      multiSelected: {
        threadIds: [],
        conversationIds: [],
      },
      multiSelectEnabled: false,
    };
  },
  mounted() {
    if (this.$route && this.$route.params && this.$route.params.type) {
      this.updateType(this.$route.params.type);
    }
    if (this.$route && this.$route.params && this.$route.params.id) {
      if (this.$route.meta && this.$route.meta.cloak) {
        this.updateCloak(decode(this.$route.params.id));
      }
    }
    if (this.$route && this.$route.params && this.$route.params.activity_id) {
      this.loadActivityId(decode(this.$route.params.activity_id));
    }
    this.$nextTick(() => {
      window.addEventListener("contact:status", this.contactHasBeenUpdated);
    });
  },
  beforeDestroy() {
    this.$store.commit("setActivityCloak", null);
    window.removeEventListener("contact:status", this.contactHasBeenUpdated);
  },
  watch: {
    type(routeValue) {
      this.updateType(routeValue);
    },
    cloakId() {
      this.updateCloak(this.cloakId);
    },
  },
  methods: {
    contactHasBeenUpdated() {
      if (this.type === "request") {
        this.refresh();
      }
    },
    /* TODO(madeline): transfer activities to IndexedDB so we can delete things in one place and changes will flow through app */
    refresh() {
      this.$refs.list.toggleRefresh();
    },
    page(next) {
      let url = this.unknownNext;
      if (!next) {
        url = this.unknownPrev;
      }
      if (url) {
        this.loadingUnknown = true;
        api()
          .get(url)
          .then(({ data }) => {
            this.unknownContacts = data.results.map((r) =>
              this.convertActivityToThread(r)
            );
            this.unknownPrev = data.previous;
            this.unknownNext = data.next;
          })
          .finally(() => {
            this.loadingUnknown = false;
          });
      }
    },
    getContentFromActivity(activity) {
      return activity.email || activity.message || activity.call;
    },
    convertActivityToThread(activity) {
      return {
        ...activity,
        ...this.getContentFromActivity(activity),
        threadType: this.getActivityType(activity),
        activity_id: activity.id,
      };
    },
    getActivityType(activity) {
      if (activity.email) {
        return "email";
      }
      if (activity.message) {
        return "text";
      }
      if (activity.call) {
        return "call";
      }
    },
    loadActivityId(id, isLoop) {
      return ActivityService.loadActivityId(id).then(({ data }) => {
        const payload = data.email || data.message || data.call;
        const thread = {
          activity_id: id,
          identity: data.identity,
          ...payload,
        };
        this.singleSelect(thread, isLoop);
      });
    },
    updateType(value) {
      this.$store.commit("setActivity", value);
    },
    updateCloak(value) {
      this.$store.commit("setActivityCloak", value);
    },
    removed(removedItem) {
      this.$refs.list.removeItem(removedItem);
      if (this.singleSelected && this.singleSelected.id === removedItem.id) {
        this.singleSelect(null);
      }
    },
    updated(updatedItem) {
      if (this.singleSelected && this.singleSelected.id === updatedItem.id) {
        this.singleSelect(updatedItem);
      }
      this.$refs.list.updatedItem(updatedItem);
    },
    singleSelect(thread, isLoop) {
      if (
        !isLoop &&
        thread &&
        !(thread.text || thread.html) &&
        !(thread.body || thread.media) &&
        !thread.call_id
      ) {
        return this.loadActivityId(thread.activity_id, true);
      }

      let cloak;
      if (thread && typeof thread.identity === "string") {
        const urlSplit = thread.identity.split("/").filter((i) => i);
        const cloakId = urlSplit[urlSplit.length - 1];
        cloak = { id: cloakId };
      } else if (thread && thread.identity) {
        cloak = thread.identity;
      }
      this.$store
        .dispatch("fetchPopulatedData", cloak)
        .then((identity) => {
          this.singleSelected = { ...thread, identity };
        })
        .catch(() => {
          this.singleSelected = thread;
        });
    },
    multiSelect(thread, alwaysAdd) {
      if (thread?.conversation_id) {
        if (
          this.multiSelected.conversationIds.includes(thread.conversation_id) &&
          !alwaysAdd
        ) {
          this.multiSelected = {
            ...this.multiSelected,
            conversationIds: this.multiSelected.conversationIds.filter(
              (conversation_id) => conversation_id !== thread.conversation_id
            ),
          };
        } else {
          this.multiSelected = {
            ...this.multiSelected,
            conversationIds: [
              ...new Set([
                ...this.multiSelected.conversationIds,
                thread?.conversation_id,
              ]),
            ],
          };
        }
      }
      if (thread.thread_id) {
        if (
          this.multiSelected.threadIds.includes(thread.thread_id) &&
          !alwaysAdd
        ) {
          this.multiSelected = {
            ...this.multiSelected,
            threadIds: this.multiSelected.threadIds.filter(
              (thread_id) => thread_id !== thread.thread_id
            ),
          };
        } else {
          this.multiSelected = {
            ...this.multiSelected,
            threadIds: [
              ...new Set([...this.multiSelected.threadIds, thread.thread_id]),
            ],
          };
        }
      }
    },
    enableMultiSelect() {
      this.setMultiSelect(true, false);
    },
    setMultiSelect(enabled, autoSelect = true) {
      this.multiSelectEnabled = enabled;
      if (!enabled) {
        this.multiSelected = {
          threadIds: [],
          conversationIds: [],
        };
      } else if (enabled && autoSelect && this.singleSelected) {
        this.multiSelect(this.singleSelected, true);
      }
    },
    select(thread) {
      if (this.multiSelectEnabled) {
        return this.multiSelect(thread);
      }
      return this.singleSelect(thread);
    },
  },
  computed: {
    unknownSize() {
      if (this.$refs.unknownContainer) {
        const size = Math.floor(this.$refs.unknownContainer.offsetWidth / 320);
        return size;
      }
      return 0;
    },
    showingRequests() {
      return this.$store.state.ui.showRequests;
    },
    type() {
      return this.$route.meta && this.$route.meta.type;
    },
    cloakId() {
      if (this.$route.meta && this.$route.meta.cloak) {
        return decode(this.$route.params.id);
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-header {
  width: 100%;
  height: 172px;

  &.short-list {
    height: 47px;
    overflow-x: hidden;
    .sticky-header {
    }
  }
  .sticky-header {
    width: calc(100% - 241px);
    position: fixed;
    z-index: 100;
    padding-right: 15px;
    padding-bottom: 12px;
    background-color: $color-primary-0;
    border-bottom: 1px solid $color-primary-10;
  }
}
.page {
  background-color: $color-surface;
  min-height: calc(100vh - 65px);
  display: flex;
  flex-direction: row;
  position: relative;

  .activity-container {
    flex: 1;
    position: relative;
    width: calc(100% - 400px);
  }
  @media (max-width: 1024px) {
    width: 100vw;
  }
}

.app--visible-banner {
  .page {
    min-height: calc(100vh - 65px - 40px);
  }
}
</style>
