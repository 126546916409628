var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ui-menu-title",
      attrs: { title: _vm.title },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return (() => {}).apply(null, arguments)
        },
      },
    },
    [
      _vm._t("default", function () {
        return [_c("span", [_vm._v(_vm._s(_vm.title))])]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }