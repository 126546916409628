var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.AppModal,
    _vm._g(_vm._b({}, "AppModal", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        _setup.AppModalContent,
        {
          staticClass: "sharing-modal-create",
          class: {
            "sharing-modal-create--loading": _vm.isLoading,
            "sharing-modal-create--expanded":
              _setup.areCustomPermissionsExpanded,
          },
          style: _setup.modalContentStyle,
        },
        [
          _c(
            _setup.AppModalTitle,
            { staticClass: "sharing-modal-create__title" },
            [
              _c(_setup.IdentityIcon, {
                attrs: {
                  override: { width: "44px", height: "44px" },
                  identity: _vm.identity,
                },
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isLoading
                      ? _vm.isShared
                        ? "Publishing your changes..."
                        : "Generating your share link..."
                      : `Share ${_vm.identity?.nickname} identity`
                  ) +
                  " "
              ),
              _vm.isLoading
                ? _c(_setup.Spinner, {
                    staticClass: "sharing-modal-create__spinner",
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "sharing-modal-create__content" }, [
            _c("div", { staticClass: "sharing-modal-create__permissions" }, [
              _c(
                "label",
                { staticClass: "sharing-modal-create__permissions-all" },
                [
                  _c(_setup.ReviewToggleAll, {
                    attrs: {
                      values: _setup.permissionIds,
                      value: _vm.sharing.data.map((item) => item.id),
                    },
                    on: {
                      input: function ($event) {
                        _vm.$emit("update-sharing", {
                          ..._vm.sharing,
                          data: _vm.permissions.filter((item) =>
                            $event.includes(item.id)
                          ),
                        })
                      },
                    },
                  }),
                  _c("span", [
                    _c(
                      "span",
                      {
                        staticClass: "sharing-modal-create__permissions-title",
                      },
                      [_vm._v(" Full read permission: ")]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "sharing-modal-create__permissions-paragraph",
                      },
                      [
                        _vm._v(
                          " All contents of this identity except calls, texts and emails will be accessible by the recipient. "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "sharing-modal-create__permissions-custom" },
                [
                  _c(
                    "h3",
                    {
                      staticClass: "sharing-modal-create__permissions-title",
                      on: {
                        click: function ($event) {
                          _setup.areCustomPermissionsExpanded =
                            !_setup.areCustomPermissionsExpanded
                        },
                      },
                    },
                    [
                      _vm._v(" Custom permissions "),
                      _c(_setup.ChevronDown, {
                        staticClass: "sharing-modal-create__chevron",
                        class: {
                          "sharing-modal-create__chevron--expanded":
                            _setup.areCustomPermissionsExpanded,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "sharing-modal-create__permissions-custom-list",
                      style: _setup.permissionsListStyle,
                    },
                    [
                      _c(
                        "div",
                        {
                          ref: "permissionsList",
                          staticClass:
                            "sharing-modal-create__permissions-custom-list-wrapper",
                        },
                        _vm._l(_vm.permissions, function (permission) {
                          return _c(_setup.IdentitySharingPermission, {
                            key: permission.id,
                            staticClass:
                              "sharing-modal-create__permissions-custom-list-item",
                            attrs: {
                              id: permission.id,
                              type: permission.type,
                              "is-secret": permission.isSecret,
                              "shared-value": permission.sharedValue,
                              label: permission.label,
                              value: _vm.sharing.data.map((item) => item.id),
                            },
                            on: {
                              input: function ($event) {
                                _vm.$emit("update-sharing", {
                                  ..._vm.sharing,
                                  data: _vm.permissions.filter((item) =>
                                    $event.includes(item.id)
                                  ),
                                })
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _c(
            _setup.AppModalFooter,
            { staticClass: "sharing-modal-create__footer" },
            [
              _vm.isShared
                ? [
                    _c(
                      _setup.Button,
                      {
                        attrs: { type: "secondary" },
                        on: {
                          click: function ($event) {
                            _vm.$emit("discard-changes")
                            _vm.$emit("open-published")
                          },
                        },
                      },
                      [_vm._v("Go back")]
                    ),
                    _c(
                      _setup.Button,
                      {
                        attrs: {
                          disabled:
                            _vm.sharing.data.length === 0 ||
                            _setup.isEqual(
                              _vm.sharing.data,
                              _vm.identity.sharing.data
                            ),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("update")
                          },
                        },
                      },
                      [_vm._v("Publish new permissions")]
                    ),
                  ]
                : [
                    _c(
                      _setup.Button,
                      {
                        attrs: { type: "secondary" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("input", false)
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      _setup.Button,
                      {
                        attrs: { disabled: _vm.sharing.data.length === 0 },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("create")
                          },
                        },
                      },
                      [_vm._v("Create link")]
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }