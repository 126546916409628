<script setup>
import { defineProps, defineEmits } from "vue";
import Button from "@/components/Button.vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import AppModalImage from "@/components/ui/AppModalImage.vue";
import AppModal from "@/components/ui/AppModal.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
defineProps({
  username: {
    type: String,
    required: true,
  },
  isV1User: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(["input", "close"]);
</script>
<template>
  <AppModal v-on="$listeners" v-bind="$attrs">
    <AppModalContent>
      <AppModalImage
        :src="require('@/assets/images/migration-announcement.png')"
        alt="Security upgrade required"
      />
      <AppModalTitle> Security upgrade required </AppModalTitle>
      <AppModalParagraph>
        Hey {{ username }}, to continue using Cloaked, you’ll need to complete a
        quick security upgrade. To begin, you’ll need to download your secret
        key and use it to create a new master password.
      </AppModalParagraph>
      <AppModalParagraph>
        This process should take less than 5 minutes to complete.
      </AppModalParagraph>
      <AppModalFooter>
        <Button
          @click="
            emit('input', false);
            emit('close');
          "
          >{{ isV1User ? "Download and continue" : "Continue" }}</Button
        >
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>
