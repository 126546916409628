<template>
  <div
    class="onboarding-input-radio"
    :class="{ 'onboarding-input-radio--checked': $attrs.checked }"
  >
    <input
      type="radio"
      class="onboarding-input-radio__input"
      name="forwarding"
      ref="input"
      :id="id"
      :value="id"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <div class="onboarding-input-radio__content">
      <span v-if="preTitle" class="onboarding-input-radio__pre-title">{{
        preTitle
      }}</span>
      <label v-if="title" class="onboarding-input-radio__title" :for="id">{{
        title
      }}</label>
      <span v-if="text" class="onboarding-input-radio__text">{{ text }}</span>
    </div>
    <div class="onboarding-input-radio__image">
      <slot name="image" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    preTitle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.onboarding-input-radio {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  margin: 16px 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  position: relative;

  &:after {
    content: "";
    inset: -2px;
    position: absolute;
    border: 2px solid $color-primary-100;
    border-radius: 16px;
    opacity: 0;
  }

  &:hover {
    &:after {
      opacity: 0.1;
    }
  }

  &--checked {
    &:after {
      opacity: 1 !important;
    }
  }

  &:first-child {
    margin-top: 32px;
  }

  &__input {
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
    flex-shrink: 0;
    margin: 16px 0 0 16px;

    &:focus {
      outline: none;
    }

    &:before {
      content: "";
      inset: 0;
      position: absolute;
      border: 2px solid $color-primary-90;
      border-radius: 999px;
    }

    &:after {
      content: "";
      position: absolute;
      inset: 5px;
      background: $color-primary-100;
      border-radius: 999px;
      opacity: 0;
    }

    &:checked {
      &:before {
        border: 2px solid $color-primary-100;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  &__content {
    margin: 12px 0 0 24px;
  }

  &__pre-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $color-primary-100;
    display: block;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    margin-top: 8px;
    display: block;

    &:first-child {
      margin-top: 0;
    }

    &:before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: 1;
      cursor: pointer;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: $color-primary-60;
    margin-top: 8px;
    display: block;
  }

  &__image {
    flex-shrink: 0;
    margin-left: 16px;
    width: 142px;
    height: 142px;
  }
}
</style>
