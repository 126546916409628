<script setup>
import IconLink from "@/assets/icons/link-chain.svg";
import ChevronTooltip from "@/assets/icons/chevron-tooltip.svg";
import { defineProps } from "vue";
import Popper from "@/components/Popper.vue";

defineProps({
  isShared: {
    type: Boolean,
    default: false,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  hasAnnouncementTooltip: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <Popper
    :width="hasAnnouncementTooltip ? '250px' : 'initial'"
    :offset-along="hasAnnouncementTooltip ? 10 : 0"
    :offset-away="hasAnnouncementTooltip ? 16 : 2"
    :placement="hasAnnouncementTooltip ? 'left' : 'bottom-end'"
    :has-click-open="false"
    :has-click-close="false"
    :has-outside-click-close="false"
    :has-mouse-enter-open="!hasAnnouncementTooltip"
    :has-mouse-leave-close="!hasAnnouncementTooltip"
    v-bind="$attrs"
    v-on="$listeners"
    :transition="hasAnnouncementTooltip ? 'fade-100' : 'fade-top-200'"
  >
    <button
      class="identity-sharing-button"
      :class="{
        'identity-sharing-button--shared': isShared,
        'identity-sharing-button--active': isActive || $attrs.value,
      }"
      v-on="$listeners"
    >
      <IconLink class="identity-sharing-button__icon" />
    </button>
    <template #content>
      <div
        class="identity-sharing-button__tooltip--announcement"
        v-if="hasAnnouncementTooltip"
      >
        <ChevronTooltip class="identity-sharing-button__tooltip-chevron" />
        Tap the link icon to generate a share link and password.<br />
        <button
          class="identity-sharing-button__tooltip-button"
          @click="$emit('input', false)"
        >
          Got it
        </button>
      </div>
      <div class="identity-sharing-button__tooltip" v-else-if="isShared">
        Identity is currently being shared
      </div>
      <div class="identity-sharing-button__tooltip" v-else>Share identity</div>
    </template>
  </Popper>
</template>

<style lang="scss">
.identity-sharing-button {
  border: none;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $color-primary-100;
  background-color: $color-primary-5;
  border-radius: 100%;
  transition: background-color 0.2s ease-out;

  &--shared {
    background-color: $color-warning;
    color: $color-primary-100-light;
  }

  &--active,
  &:hover {
    color: $color-primary-0;
    background-color: $color-primary-100;
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__tooltip {
    background: $color-primary-90;
    color: $color-primary-0;
    border-radius: 6px;
    box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
      13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
    padding: 10px;
    line-height: 16px;
    font-size: 11px;
    letter-spacing: -0.1px;
    font-weight: 500;

    &--announcement {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.2px;
      padding: 12px 16px;
      background-color: $color-primary-100;
      color: $color-primary-0;
      border-radius: 8px;
    }

    &-chevron {
      color: $color-primary-100;
      position: absolute;
      left: 100%;
      top: 22px;
    }

    &-button {
      background: none;
      padding: 0;
      border: none;
      margin-top: 8px;
      text-decoration: underline;
      color: $color-primary-0;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
