var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ui-input",
      class: {
        "ui-input--clearable": _vm.clearable,
        "ui-input--focused": _vm.focused,
      },
    },
    [
      _c(
        "div",
        { staticClass: "ui-input__wrapper" },
        [
          _vm._t("default", function () {
            return [
              _c("input", {
                staticClass: "ui-input__field",
                attrs: {
                  type: _vm.type,
                  placeholder: _vm.placeholder,
                  "data-lpignore": "true",
                  "data-form-type": "other",
                  autocomplete: "off",
                  autofocus: _vm.autofocus,
                },
                domProps: { value: _vm.value },
                on: {
                  focus: function ($event) {
                    _vm.focused = true
                  },
                  blur: function ($event) {
                    _vm.focused = false
                  },
                  input: _vm.handleInputUpdate,
                },
              }),
            ]
          }),
        ],
        2
      ),
      _vm.hasActions
        ? _c("div", { staticClass: "ui-input__actions" }, [
            _vm.clearable
              ? _c(
                  "button",
                  { on: { click: _vm.handleClearInput } },
                  [_c("InputClear")],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }