var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c("div", { staticClass: "new-message-container" }, [
        _c(
          "div",
          {
            staticClass: "new-message",
            on: { click: ($event) => $event.stopPropagation() },
          },
          [
            _c("div", { staticClass: "full-height recipients" }, [
              _c("div", { staticClass: "flex-row flex-between header-line" }, [
                !_vm.isLockedtoItem
                  ? _c("h1", [_vm._v("New Message")])
                  : _vm.firstItemIsEmail
                  ? _c("h1", [_vm._v("New Email")])
                  : _vm.firstItemIsPhone
                  ? _c("h1", [_vm._v("New Text Message")])
                  : _vm._e(),
                _c(
                  "button",
                  { on: { click: _vm.cancel } },
                  [_c("CloseIcon")],
                  1
                ),
              ]),
              _vm.error_message && _vm.error_message.type === "button"
                ? _c("h3", { staticClass: "error_message" }, [
                    _vm._v(
                      " Please create " +
                        _vm._s(_vm.error_message.text) +
                        " for this identity before sending a message by "
                    ),
                    _c("button", { on: { click: _vm.openDetails } }, [
                      _vm._v("clicking here"),
                    ]),
                  ])
                : _vm._e(),
              _vm.error_message && _vm.error_message.type === "text"
                ? _c("h3", { staticClass: "error_message" }, [
                    _vm._v(" " + _vm._s(_vm.error_message.text) + " "),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "flex-row flex-gap border-bottom" }, [
                _c("label", [_vm._v("From:")]),
                _vm.from_email
                  ? _c("div", { staticClass: "from-email" }, [
                      _c("div", [
                        _vm._v(" " + _vm._s(_vm.from_email) + " "),
                        _c(
                          "button",
                          { on: { click: _vm.clear_from } },
                          [_c("RemoveIcon")],
                          1
                        ),
                      ]),
                    ])
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search,
                          expression: "search",
                        },
                      ],
                      ref: "cInput",
                      staticClass: "add-from",
                      attrs: {
                        "aria-id": "ComposeSelectIdentity",
                        maxlength: "50",
                        autocomplete: "off",
                        "data-lpignore": "true",
                        "data-form-type": "other",
                        type: "text",
                        autofocus: "",
                        placeholder:
                          "Enter any Cloaked identity, phone number, or email",
                      },
                      domProps: { value: _vm.search },
                      on: {
                        keyup: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleEnter.apply(null, arguments)
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp",
                              ])
                            )
                              return null
                            return ((event) => _vm.nav(-1, event)).apply(
                              null,
                              arguments
                            )
                          },
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown",
                              ])
                            )
                              return null
                            return ((event) => _vm.nav(1, event)).apply(
                              null,
                              arguments
                            )
                          },
                        ],
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.search = $event.target.value
                        },
                      },
                    }),
              ]),
              !_vm.from_email
                ? _c("div", { staticClass: "search-results" }, [
                    _vm.search !== null
                      ? _c(
                          "div",
                          { ref: "scroll_results", staticClass: "results" },
                          [
                            _vm.groups.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "search-items" },
                                  [
                                    _vm._l(_vm.groups, function (item, index) {
                                      return _c("div", { key: index }, [
                                        item.type === "group"
                                          ? _c(
                                              "span",
                                              { staticClass: "group-title" },
                                              [_vm._v(_vm._s(item.name))]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "group_item",
                                                on: {
                                                  mousemove: function ($event) {
                                                    _vm.current = index
                                                  },
                                                  click: function ($event) {
                                                    return _vm.clickSearchItem(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "search-item",
                                                    class: {
                                                      active:
                                                        _vm.current === index,
                                                    },
                                                    attrs: {
                                                      id: `item-${index}`,
                                                    },
                                                  },
                                                  [
                                                    _c("IdentityIcon", {
                                                      attrs: { identity: item },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-pill",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c("h1", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.n
                                                                    ? item.n
                                                                    : item.nickname
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          item.display
                                                            ? _c("h3", {
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      item.display
                                                                    ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          width: "12",
                                                          height: "12",
                                                          viewBox: "0 0 12 12",
                                                          fill: "none",
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg",
                                                        },
                                                      },
                                                      [
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M4.78711 11.1582C5.0918 11.1582 5.33008 11.0312 5.50195 10.7773L11.0098 2.19336C11.0684 2.09961 11.1113 2.01172 11.1387 1.92969C11.166 1.84375 11.1797 1.75977 11.1797 1.67773C11.1797 1.47461 11.1152 1.31055 10.9863 1.18555C10.8574 1.05664 10.6914 0.992188 10.4883 0.992188C10.3398 0.992188 10.2188 1.02148 10.125 1.08008C10.0312 1.13477 9.93945 1.23047 9.84961 1.36719L4.76367 9.44727L2.13867 6.07812C1.97461 5.86328 1.77344 5.75586 1.53516 5.75586C1.32812 5.75586 1.15625 5.82227 1.01953 5.95508C0.886719 6.08789 0.820312 6.25586 0.820312 6.45898C0.820312 6.54883 0.835938 6.63672 0.867188 6.72266C0.902344 6.80859 0.957031 6.89648 1.03125 6.98633L4.06641 10.7832C4.26562 11.0332 4.50586 11.1582 4.78711 11.1582Z",
                                                            fill: "#191E23",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                      ])
                                    }),
                                    _c("InfiniteTrigger", {
                                      on: { infinite: _vm.loadMore },
                                    }),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _c("div", { staticClass: "full-height recipients" }, [
                    _c(
                      "div",
                      { staticClass: "flex-row flex-gap border-bottom" },
                      [
                        _c("label", [_vm._v("To:")]),
                        _c(
                          "div",
                          {
                            staticClass: "to-row",
                            class: { error_m: _vm.hasErrors("to") },
                            attrs: { "aria-id": "ComposeToField" },
                          },
                          [
                            _c("PillInput", {
                              ref: "toInput",
                              attrs: {
                                defaultValue: _vm.pillData(_vm.to),
                                filter: _vm.check,
                                multiple: !!_vm.firstItemIsPhone,
                              },
                              on: { value: _vm.setPills },
                            }),
                            !_vm.enable_cc &&
                            _vm.firstItemIsEmail &&
                            _vm.canEmail
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "cc-button",
                                    on: { click: _vm.enableCC },
                                  },
                                  [_vm._v(" CC ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.enable_cc && _vm.firstItemIsEmail && _vm.canEmail
                      ? _c(
                          "div",
                          { staticClass: "flex-row flex-gap border-bottom" },
                          [
                            _c("label", [_vm._v("CC")]),
                            _c(
                              "div",
                              {
                                staticClass: "to-row",
                                class: { error_m: _vm.hasErrors("cc") },
                              },
                              [
                                _c("PillInput", {
                                  ref: "ccInput",
                                  attrs: {
                                    filter: _vm.check,
                                    defaultValue: _vm.pillData(_vm.cc),
                                  },
                                  on: { value: _vm.setCC },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.ready
                      ? _c("div", { staticClass: "full-height" }, [
                          !_vm.firstItemIsPhone
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-row flex-gap border-bottom",
                                },
                                [
                                  _c("label", [_vm._v("Subject:")]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.subject,
                                        expression: "subject",
                                      },
                                    ],
                                    ref: "subjectInput",
                                    class: {
                                      error_m: _vm.hasErrors("subject"),
                                    },
                                    attrs: {
                                      "aria-id": "ComposeSubjectInput",
                                      type: "email",
                                      maxlength: "255",
                                      autocomplete: "off",
                                      "data-lpignore": "true",
                                      "data-form-type": "other",
                                    },
                                    domProps: { value: _vm.subject },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.subject = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "full-height" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.text,
                                  expression: "text",
                                },
                              ],
                              ref: "bodyInput",
                              class: { error_m: _vm.hasErrors("body") },
                              attrs: {
                                "aria-id": "ComposeEmailTextArea",
                                rows: "6",
                                placeholder: "Start a new message",
                              },
                              domProps: { value: _vm.text },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  if (
                                    $event.ctrlKey ||
                                    $event.shiftKey ||
                                    $event.altKey ||
                                    $event.metaKey
                                  )
                                    return null
                                  $event.preventDefault()
                                  return _vm.addEnter.apply(null, arguments)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.text = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
            ]),
            _vm.canAttach
              ? _c("FileList", {
                  attrs: {
                    files: _vm.files,
                    error: _vm.hasErrors("files"),
                    errors:
                      _vm.firstItemIsPhone &&
                      "Files must be an pdf, png, jpg only",
                  },
                  on: { remove: _vm.removeFile },
                })
              : _vm._e(),
            !_vm.search
              ? _c(
                  "div",
                  { staticClass: "flex-row flex-between" },
                  [
                    _vm.canAttach
                      ? _c("FileAttachments", {
                          ref: "fileAttachment",
                          attrs: {
                            endpoint: _vm.firstItemIsPhone
                              ? "/api/v1/cloaked/activity/upload_media_urls/"
                              : "/api/v1/cloaked/activity/upload_urls/",
                            filter: _vm.firstItemIsPhone && _vm.attachFilter,
                          },
                          on: { change: _vm.handleFileChange },
                        })
                      : _c("div"),
                    _c(
                      "button",
                      {
                        staticClass: "send",
                        class: {
                          active: _vm.canSend,
                        },
                        attrs: {
                          "aria-id": "ComposeSendButton",
                          disabled: !_vm.canSend,
                        },
                        on: { click: _vm.send },
                      },
                      [
                        _c(
                          "span",
                          { class: { sent: _vm.sent, sending: _vm.sending } },
                          [_c("SendIcon")],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }