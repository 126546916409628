var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { class: { active: _setup.virtualCardCreationModal } }, [
    _c("div", { staticClass: "content" }, [
      _c("header", [
        _setup.identity
          ? _c("div", { staticClass: "identity" }, [
              _c("div", { staticClass: "icon" }, [
                _c("span", {
                  style: {
                    backgroundImage: `url(${_setup.identity.logo_url})`,
                  },
                }),
              ]),
              _c("p", [_vm._v(_vm._s(_setup.identity.nickname))]),
            ])
          : _vm._e(),
        _vm._m(0),
      ]),
      _c("div", { staticClass: "main-content" }, [
        _c("div", { staticClass: "input" }, [
          _vm._m(1),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _setup.dollarAmount,
                expression: "dollarAmount",
              },
            ],
            attrs: { type: "number", placeholder: "0" },
            domProps: { value: _setup.dollarAmount },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _setup.dollarAmount = $event.target.value
              },
            },
          }),
          _c("div", { staticClass: "choices" }, [
            _c(
              "div",
              {
                staticClass: "choice",
                on: {
                  click: function ($event) {
                    return _setup.toggleDropdown()
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(_setup.activeSelection.name))])]
            ),
            _c(
              "div",
              {
                staticClass: "options",
                class: { active: _setup.selectionDropdown },
              },
              _vm._l(_setup.selections, function (s) {
                return _c(
                  "div",
                  {
                    key: s.value,
                    staticClass: "option",
                    class: { selected: s.active },
                    on: {
                      click: function ($event) {
                        return _setup.selectOption(s)
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(s.name))])]
                )
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "source" }, [
          _c("h1", [_vm._v("Funding source")]),
          _c(
            "div",
            { staticClass: "card", class: { loading: _setup.loading } },
            [
              _vm._m(2),
              _c("div", { staticClass: "text" }, [
                _c("div", { staticClass: "name" }, [
                  _c("span", [
                    _vm._v(_vm._s(_setup.defaultFundingSource.card_brand)),
                  ]),
                ]),
                _c("div", { staticClass: "number" }, [
                  _c("span", [
                    _vm._m(3),
                    _vm._v(
                      " " +
                        _vm._s(_setup.defaultFundingSource.pan_last_four) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _vm._m(4),
            ]
          ),
          _vm._m(5),
        ]),
      ]),
      _c("footer", [
        _c(
          "button",
          {
            on: {
              click: function ($event) {
                return _setup.closeVirtualCardCreationModal()
              },
            },
          },
          [_vm._v("Cancel")]
        ),
        _c(
          "button",
          {
            staticClass: "primary",
            class: {
              disabled: _setup.disabledButton,
              generating: _setup.generating,
            },
            on: {
              click: function ($event) {
                return _setup.generateCard()
              },
            },
          },
          [_vm._v(" Generate card "), _c("i")]
        ),
      ]),
    ]),
    _c("div", {
      staticClass: "overlay",
      on: {
        click: function ($event) {
          return _setup.closeVirtualCardCreationModal()
        },
      },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "information" }, [
      _c("span", [_vm._v("Spending limits "), _c("i")]),
      _c("div", { staticClass: "tooltip" }, [
        _c("p", [
          _vm._v(
            " Spending limits are the maximum amount of money that can be spent with this card. You can change the spending limits at any time. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "currency" }, [_c("span", [_vm._v("$")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "icon" }, [_c("span")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dots" }, [
      _c("i"),
      _c("i"),
      _c("i"),
      _c("i"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "label" }, [
      _c("span", [_vm._v("Default")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(
        " Your default funding source is a credit card. This credit card will be charged for the entered amount when you press “Generate card”. "
      ),
      _c("a", { attrs: { href: "#" } }, [_vm._v("Change funding source")]),
      _vm._v(". "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }