var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "page", class: { active: _setup.ui.bannerActive } },
    [
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "banner",
              class: { active: _setup.ui.bannerActive },
            },
            [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "content" }, [
                  _c("h1", [_vm._v("Stolen card numbers, be gone.")]),
                  _c("p", [
                    _vm._v(
                      " Keep your personal cards protected with Cloaked virtual cards - powered by Mastercard. 0% APR. No hidden fees. "
                    ),
                  ]),
                  _c("button", { on: { click: _setup.kycOpen } }, [
                    _vm._v("Get verified"),
                  ]),
                  _c("p", { staticClass: "small" }, [
                    _vm._v(
                      " Eligible U.S. Cloaked customers will verify their identity. For more information, please take a look at the Terms of Service and Privacy Policy. "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "cards" }, [
                  _c(
                    "div",
                    { staticClass: "card-group" },
                    _vm._l(4, function (index) {
                      return _c(
                        "span",
                        { key: index },
                        [
                          _c(_setup.CloakedIcon),
                          _c("div", { staticClass: "mastercard" }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]
          ),
          _setup.ui.step === 0
            ? _c(_setup.KYC, {
                attrs: { active: _setup.ui.bannerActive },
                on: { close: _setup.kycClose, next: _setup.kycNext },
              })
            : _vm._e(),
          _setup.ui.step === 1
            ? _c(_setup.CreateCard, {
                attrs: {
                  status: _setup.ui.kycStatus,
                  active: _setup.ui.bannerActive,
                },
                on: { close: _setup.kycClose, next: _setup.done },
              })
            : _vm._e(),
          _vm._m(0),
          _c("div", { staticClass: "faq" }, [
            _c("div", { staticClass: "container" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "items" },
                _vm._l(_setup.accordianItems, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.q,
                      staticClass: "item",
                      class: { active: item.open },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "icon",
                          on: {
                            click: function ($event) {
                              item.open = !item.open
                            },
                          },
                        },
                        [
                          item.open ? _c(_setup.Minus) : _vm._e(),
                          !item.open ? _c(_setup.PlusIcon) : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "question" }, [
                        _c("h1", [_vm._v(_vm._s(item.q))]),
                      ]),
                      _c("div", { staticClass: "answer" }, [
                        _c(
                          "div",
                          { staticClass: "text" },
                          _vm._l(item.a, function (a) {
                            return _c("p", { key: a }, [_vm._v(_vm._s(a))])
                          }),
                          0
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "how-it-works" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "title" }, [
          _c("h1", [_vm._v("How it works")]),
        ]),
        _c("div", { staticClass: "items" }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "image" }, [
              _c("img", {
                staticStyle: { width: "70%" },
                attrs: { src: require("@/assets/images/card-hero-1.png") },
              }),
            ]),
            _c("div", { staticClass: "content" }, [
              _c("h1", [_vm._v("Connect your funding source to Cloaked")]),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c(
              "div",
              {
                staticClass: "image",
                staticStyle: { "justify-content": "flex-end" },
              },
              [
                _c("img", {
                  staticStyle: {
                    width: "80%",
                    height: "45%",
                    "margin-right": "-10px",
                  },
                  attrs: { src: require("@/assets/images/card-hero-2.png") },
                }),
              ]
            ),
            _c("div", { staticClass: "content" }, [
              _c("h1", [_vm._v("Set your spending limits")]),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "image" }, [
              _c("img", {
                staticStyle: {
                  margin: "20px",
                  "max-height": "inherit",
                  "background-color": "transparent",
                },
                attrs: { src: require("@/assets/images/card-hero-3.png") },
              }),
            ]),
            _c("div", { staticClass: "content" }, [
              _c("h1", [_vm._v("Generate a Cloaked card for any identity")]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("h1", [_vm._v("Frequently asked questions")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }