<script setup>
import { watch, ref, onMounted, computed } from "vue";
import store from "@/store";
import { useRoute } from "vue-router/composables";
import InlineSvg from "@/components/InlineSvg.vue";
import { getAccountInitials } from "@/scripts/format";
import ModalApp from "@/components/feature/onboarding/ModalApp";
import { logout } from "@/scripts/actions/auth";
import { onClickOutside } from "@vueuse/core";
import { toggleSupport } from "@/scripts/cloakHelpers";
const route = useRoute();

watch(route, () => {
  pageTitle.value = route.meta;

  if (route.fullPath.includes("inbox")) {
    inbox.value = true;
  } else {
    inbox.value = false;
  }
});

onMounted(() => {
  if (route.fullPath.includes("inbox")) {
    inbox.value = true;
  } else {
    inbox.value = false;
  }
});
const pageTitle = ref(route.meta);

const inbox = ref();

const username = computed(() => {
  return (
    store.state.authentication?.username ||
    store.state.authentication?.user?.username
  );
});

function search() {
  store.dispatch("setSearch", true);
}

const componentKey = ref(0);

watch(pageTitle, () => {
  componentKey.value += 1;
});

const dropdown = ref(false);

function activateDropdown() {
  dropdown.value = !dropdown.value;
}

function closeDropdown() {
  dropdown.value = false;
}

const isDownloadAppVisible = ref(false);

function toggleDownloadAppModal() {
  isDownloadAppVisible.value = !isDownloadAppVisible.value;
  dropdown.value = false;
}

function openHelp() {
  toggleSupport();
  dropdown.value = false;
}

const isDarkMode = ref(store.getters["colorScheme/isDarkMode"]);

function toggleDarkMode() {
  store.dispatch("colorScheme/changeColorTheme");
  dropdown.value = false;

  if (isDarkMode.value) {
    isDarkMode.value = false;
  } else {
    isDarkMode.value = true;
  }
}

const subscriptionState = computed(() => {
  return store.state.settings.subscription.state;
});

function upgradeModal() {
  store.dispatch("subscription/openSubscriptionModal");
  dropdown.value = false;
}

const target = ref(null);

onClickOutside(target, () => (dropdown.value = false));
</script>

<template>
  <section :class="{ hidden: route.fullPath.includes('settings') }">
    <div class="title">
      <div class="icon" v-if="pageTitle.icon">
        <inlineSvg :name="pageTitle.icon" :key="componentKey" />
      </div>
      <span>{{ pageTitle.title }}</span>
    </div>

    <div class="search" @click="search" v-if="!inbox">
      <span><inlineSvg name="search" /> Search all identities</span>
    </div>

    <div class="username-dropdown" ref="target">
      <div class="username" @click="activateDropdown">
        <div class="text">
          <span v-if="username">{{ username }}</span>
        </div>

        <div class="icon" :class="{ active: dropdown }">
          <span v-if="username">{{ getAccountInitials(username) }}</span>
          <span v-else><InlineSvg name="user-icon-filled" /></span>
        </div>
      </div>

      <div class="dropdown" :class="{ active: dropdown }">
        <router-link
          to="/settings"
          class="user"
          @click.native="closeDropdown()"
          :class="{ disabled: subscriptionState === 'CANCELLED' }"
        >
          <div class="icon">
            <span v-if="username">{{ getAccountInitials(username) }}</span>
            <span v-else><InlineSvg name="user-icon-filled" /></span>
          </div>

          <div class="text">
            <span class="name" v-if="username">{{ username }}</span>
            <span class="name" v-else>Cloaked User</span>
            <span class="info" v-if="subscriptionState !== 'CANCELLED'"
              >Edit my information</span
            >
            <span class="info" v-else>Read only account</span>
          </div>
        </router-link>

        <div
          class="button"
          @click="upgradeModal"
          v-if="subscriptionState === 'CANCELLED'"
        >
          <span>Upgrade now</span>
        </div>

        <div class="divider"></div>

        <router-link
          to="/settings"
          class="item link"
          @click.native="closeDropdown()"
        >
          <InlineSvg name="cog" />
          <span>Settings</span>
        </router-link>

        <router-link
          to="/autocloak"
          class="item link"
          @click.native="closeDropdown()"
        >
          <InlineSvg name="auto-change" />
          <span>AutoCloak Identity</span>
        </router-link>

        <router-link
          to="/import"
          class="item link"
          @click.native="closeDropdown()"
        >
          <InlineSvg name="key-filled" />
          <span>Import passwords</span>
        </router-link>

        <router-link
          to="/cloaked-daily"
          class="item link"
          @click.native="closeDropdown()"
        >
          <InlineSvg name="paper" />
          <span>Cloaked Daily</span>
        </router-link>

        <router-link
          to="/referrals"
          class="item link"
          @click.native="closeDropdown()"
        >
          <InlineSvg name="gift" />
          <span>Invite a friend</span>
        </router-link>

        <div class="divider"></div>

        <a
          href="https://download.cloaked.app"
          target="_blank"
          class="item download"
          @click="closeDropdown()"
        >
          <InlineSvg name="browser" />
          <span>Download extension</span>
        </a>

        <div class="item download" @click="toggleDownloadAppModal">
          <InlineSvg name="block-active" />
          <span>Download mobile app</span>
        </div>

        <div class="divider"></div>

        <div class="item toggle" @click="toggleDarkMode">
          <InlineSvg name="moon" />
          <span>Dark mode</span>

          <button class="toggle" :class="{ enabled: isDarkMode }">
            <span />
          </button>
        </div>

        <div class="item" @click="openHelp">
          <span>Get help or share feedback</span>
        </div>

        <div class="item" @click="logout()">
          <span>Log out</span>
        </div>
      </div>
    </div>

    <ModalApp v-model="isDownloadAppVisible" @click="toggleDownloadAppModal" />
  </section>
</template>

<style lang="scss" scoped>
section {
  position: fixed;
  top: 0;
  right: 0;
  height: 60px;
  border-bottom: 1px solid $color-primary-10;
  width: calc(100% - 240px);
  background-color: $color-surface;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  z-index: 90;

  .app--visible-banner & {
    top: 40px;
  }

  body.active-right-panel & {
    width: calc(100% - 639px);
    right: 399px;
  }

  body.wallet & {
    width: calc(100% - 236px);
    right: 0;
  }

  &.hidden {
    display: none;
  }

  .title {
    display: flex;
    align-items: center;
    flex: 1;

    .icon {
      margin-right: 8px;
      width: 24px;
      height: 24px;
      background-color: $color-primary-100;
      color: $color-primary-0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 12px;
        height: auto;
        max-height: 14px;
      }
    }

    span {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.4px;
      color: $color-primary-100;
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .search {
    width: 40px;
    height: 40px;
    background-color: $color-primary-5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;

    @media (min-width: 1200px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 450px;
      border-radius: 5px;
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
      background-color: $color-primary-10;
    }

    body.active-right-panel & {
      @media (min-width: 1200px) {
        width: 280px;
      }
    }

    body.wallet & {
      @media (min-width: 1200px) {
        width: 450px;
      }
    }

    span {
      display: flex;
      align-items: center;
      color: $color-primary-70;
      font-size: 0;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      position: relative;

      @media (min-width: 1200px) {
        font-size: 12px;
      }

      svg {
        width: 16px;
        height: auto;
        margin-right: 10px;

        @media (max-width: 1199px) {
          position: absolute;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
          margin-right: 0;
        }
      }
    }
  }

  .username-dropdown {
    position: relative;

    .username {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-primary-100;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      cursor: pointer;

      .text {
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: $color-primary-100;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          max-width: 100px;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .icon {
        width: 32px;
        height: 32px;
        background-color: $color-primary-20;
        color: $color-primary-0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          right: -15px;
          top: 50%;
          @include transform(rotate(45deg));
          transform-origin: center center;
          width: 8px;
          height: 8px;
          border: 2px solid $color-primary-100;
          border-top-width: 0;
          border-left-width: 0;
          border-bottom-right-radius: 2px;
          margin-top: -4px;
        }

        span {
          text-transform: uppercase;
          color: $color-primary-100;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.2px;

          svg {
            width: 12px;
            height: auto;
            margin-right: 0;
          }
        }
      }
    }

    .dropdown {
      position: absolute;
      top: 44px;
      right: 0;
      width: 240px;
      max-height: calc(100vh - 56px);
      background-color: $color-background;
      border-radius: 8px;
      box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      opacity: 0;
      visibility: hidden;
      @include transition(all 0.2s ease-in-out);
      padding: 12px 0;
      color: $color-primary-100;
      overflow: auto;
      @include custom-scroll-bar();

      &.active {
        opacity: 1;
        visibility: visible;
        top: 36px;
      }

      .user {
        display: flex;
        align-items: center;
        padding: 12px 12px 12px 12px;
        cursor: pointer;

        &.disabled {
          pointer-events: none;

          .text {
            &:after {
              display: none;
            }
          }
        }

        .icon {
          width: 32px;
          height: 32px;
          background-color: $color-primary-20;
          color: $color-primary-0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          position: relative;

          span {
            text-transform: uppercase;
            color: $color-primary-100;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.2px;

            svg {
              width: 12px;
              height: auto;
              margin-right: 0;
            }
          }
        }

        .text {
          width: calc(100% - 40px);
          padding-right: 30px;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            right: 6px;
            top: 50%;
            @include transform(rotate(-45deg));
            transform-origin: center center;
            width: 8px;
            height: 8px;
            border: 1.5px solid $color-primary-70;
            border-top-width: 0;
            border-left-width: 0;
            border-bottom-right-radius: 2px;
            margin-top: -4px;
          }

          span {
            color: $color-primary-100;
            display: block;
            width: 100%;

            &.name {
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              overflow: hidden;
            }

            &.info {
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }

      .button {
        margin: 6px 12px 18px 12px;

        span {
          color: $color-primary-100;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
          width: 100%;
          border: 2px solid $color-primary-100;
          border-radius: 999px;
          padding: 8px 12px;
          display: block;
          text-align: center;
          overflow: hidden;
        }

        &:hover {
          span {
            cursor: pointer;
            background-color: $color-primary-10;
          }
        }
      }

      .divider {
        height: 1px;
        background-color: $color-primary-10;
        margin: 12px 0;
      }

      .item {
        padding: 8px 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        &.link {
          &:after {
            content: "";
            position: absolute;
            right: 18px;
            top: 50%;
            @include transform(rotate(-45deg));
            transform-origin: center center;
            width: 8px;
            height: 8px;
            border: 1.5px solid $color-primary-70;
            border-top-width: 0;
            border-left-width: 0;
            border-bottom-right-radius: 2px;
            margin-top: -4px;
          }
        }

        &.download {
          &:after {
            content: "";
            position: absolute;
            right: 18px;
            top: 50%;
            @include transform(rotate(-90deg));
            transform-origin: center center;
            width: 8px;
            height: 8px;
            border: 1.5px solid $color-primary-70;
            border-top-width: 0;
            border-left-width: 0;
            border-bottom-right-radius: 2px;
            margin-top: -4px;
          }

          &:before {
            content: "";
            position: absolute;
            right: 18px;
            top: 50%;
            @include transform(rotate(45deg));
            transform-origin: top right;
            width: 1.5px;
            height: 12px;
            margin-top: -3px;
            border-radius: 2px;
            background: $color-primary-70;
          }
        }

        &.toggle {
          button {
            position: absolute;
            right: 12px;
            top: 50%;
            @include transform(translateY(-50%));
            width: 26px;
            height: 16px;
            border-radius: 8px;
            background-color: $color-primary-30;
            border: none;

            span {
              position: absolute;
              left: 2px;
              top: 2px;
              width: 12px;
              height: 12px;
              border-radius: 6px;
              background-color: $color-primary-0;
              @include transition(all 0.2s ease-in-out);
            }

            &.enabled {
              background-color: $color-success;

              span {
                left: 12px;
                background-color: $color-primary-10;
              }
            }
          }
        }

        svg {
          width: 24px;
          height: 24px;
          padding: 4px;
          margin-right: 8px;
          color: $color-primary-90;
        }

        span {
          color: $color-primary-100;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}
</style>
