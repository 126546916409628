var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ModalTemplate", {
    staticClass: "add-verify-phone-modal",
    attrs: { show: true },
    on: { close: _vm.handleCancel },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            !_vm.confirming
              ? _c("div", [
                  _c("h1", [_vm._v(_vm._s(_vm.title || "Add a phone number"))]),
                ])
              : _c("div", [_c("h1", [_vm._v("Verify your phone number")])]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            !_vm.confirming
              ? _c(
                  "div",
                  [
                    _c("OnboardingInputPhone", {
                      attrs: { value: _vm.phone, placeholder: "Phone number" },
                      on: { input: _vm.setPhone },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "subheader" },
                  [
                    _vm._v(" Enter the 6-digit code sent to "),
                    _c("strong", [_vm._v(_vm._s(_vm.formattedPhoneNumber))]),
                    _c("br"),
                    _c(
                      "OnboardingInputCode",
                      _vm._g(
                        {
                          attrs: { value: _vm.code },
                          on: {
                            input: function ($event) {
                              _vm.code = $event
                            },
                          },
                        },
                        _vm.$listeners
                      )
                    ),
                  ],
                  1
                ),
            _vm.phoneError || _vm.codeError
              ? _c("div", { staticClass: "input_error" }, [
                  _vm._v(" " + _vm._s(_vm.phoneError || _vm.codeError) + " "),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            !_vm.confirming
              ? [
                  _c(
                    "Button",
                    {
                      attrs: { type: "secondary" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "Button",
                    {
                      attrs: { disabled: !_vm.validPhone },
                      on: { click: _vm.savePhone },
                    },
                    [_vm._v(" Continue ")]
                  ),
                ]
              : [
                  _c(
                    "Button",
                    { attrs: { type: "secondary" }, on: { click: _vm.resend } },
                    [
                      _vm.loading
                        ? _c("span", { staticClass: "spin-loader" })
                        : _vm._e(),
                      _vm._v(" Resend code "),
                    ]
                  ),
                  _c(
                    "Button",
                    {
                      attrs: { disabled: !_vm.validCode },
                      on: { click: _vm.verify },
                    },
                    [_vm._v(" Verify ")]
                  ),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }