<script setup>
import { computed, defineProps } from "vue";
import PasswordText from "@/components/ui/PasswordText.vue";
import AutoChangeIcon from "@/components/auto-change/AutoChangeIcon.vue";
import IconWarning from "@/assets/icons/icon-warning-fill.svg";
import InputCheckbox from "@/components/InputCheckbox.vue";
import { getFieldStatus } from "@/components/auto-change/getStatus";
import { AutoChangeStatus } from "@/components/auto-change/utils";

const props = defineProps({
  field: {
    type: Object,
    default: undefined,
  },
  verification: {
    type: Array,
    default: () => [],
  },
  update: {
    type: Object,
    default: undefined,
  },
  isSupported: {
    type: Boolean,
    required: true,
  },
  hidesSecretValues: {
    type: Boolean,
    required: true,
  },
});

const status = computed(() =>
  getFieldStatus({
    change: props.field,
    verification: props.verification,
    update: props.update,
  })
);

const isNewValueShown = computed(
  () =>
    props.field?.newValue &&
    ![AutoChangeStatus.ERROR, AutoChangeStatus.SKIPPED].includes(status.value)
);
</script>

<template>
  <Component
    :is="!!status ? 'span' : InputCheckbox"
    :id="field?.fieldType ?? ''"
    class="auto-change-cell"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span class="auto-change-cell__field-text" v-if="!isSupported">
      Not supported
    </span>
    <span class="auto-change-cell__field-text" v-else-if="!field?.oldValue">
      -
    </span>
    <template v-else>
      <transition name="fade-100" mode="out-in">
        <div
          v-if="status === 'needsReview'"
          class="auto-change-cell__needs-review"
          key="needsReview"
        >
          <IconWarning class="auto-change-cell__needs-review-icon" />
          <div>
            <div class="auto-change-cell__needs-review-title">Needs review</div>
            <div class="auto-change-cell__needs-review-message">
              Verify information to finish AutoCloak
            </div>
          </div>
        </div>
        <div v-else class="auto-change-cell__progress" key="progress">
          <AutoChangeIcon
            v-if="status"
            :status="status"
            class="auto-change-cell__status"
          />
          <div
            v-if="field?.fieldType === 'password'"
            class="auto-change-cell__field"
            :class="{
              'auto-change-cell__field--done': isNewValueShown,
            }"
          >
            <PasswordText
              :password="field?.newValue ?? ''"
              :is-hidden="hidesSecretValues"
              class="auto-change-cell__field-text auto-change-cell__field--new-value"
            />
            <PasswordText
              :password="field?.oldValue ?? ''"
              :is-hidden="hidesSecretValues"
              class="auto-change-cell__field-text auto-change-cell__field--old-value"
            />
          </div>
          <div
            v-else
            class="auto-change-cell__field"
            :class="{
              'auto-change-cell__field--done': isNewValueShown,
            }"
          >
            <span
              class="auto-change-cell__field-text auto-change-cell__field--new-value"
            >
              {{ field?.newValue }}
            </span>
            <span
              class="auto-change-cell__field-text auto-change-cell__field--old-value"
            >
              {{ field?.oldValue }}
            </span>
          </div>
        </div>
      </transition>
    </template>
  </Component>
</template>

<style lang="scss">
.auto-change-cell {
  display: flex;
  align-items: center;
  position: relative;

  &__field {
    position: relative;
    width: 100%;

    &-text {
      font-weight: 400;
      margin-left: 12px;
      line-height: 130%;
      color: $color-primary-100;
      @include line-clamp(1);

      .password-text__password {
        user-select: initial;
      }
    }

    &--old-value {
      font-size: 14px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 150ms ease-out;

      @at-root .auto-change-cell__field--done & {
        font-size: 11px;
        text-decoration: line-through;
        opacity: 0.5;
        transform: translateY(calc(-50% + 10px));
      }
    }

    &--new-value {
      position: absolute;
      font-size: 14px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 150ms ease-out;
      opacity: 0;

      @at-root .auto-change-cell__field--done & {
        transform: translateY(calc(-50% - 8px));
        opacity: 1;
      }
    }
  }

  &__needs-review {
    background-color: $color-warning-light;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    padding: 0 8px;
    flex-grow: 1;
    position: absolute;
    inset: 0;

    &-icon {
      color: $color-primary-100-light;
      flex-shrink: 0;
    }

    &-title {
      color: $color-primary-100-light;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      @include line-clamp(1);
    }

    &-message {
      color: $color-primary-100-light;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      opacity: 0.5;
      @include line-clamp(1);
    }
  }

  &__progress {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__status {
    width: 21px;
    height: 21px;

    .auto-change-icon__icon {
      width: 21px;
      height: 21px;
    }
  }
}
</style>
