<template>
  <div class="recent-imports">
    <h1 class="recent-imports__title">Recently imported</h1>
    <template v-if="recentImports.length > 0">
      <div
        v-for="recentImport in recentImports"
        :key="recentImport.uuid"
        class="recent-imports__import"
      >
        <div class="recent-imports__import-head">
          <span class="recent-imports__import-date">{{
            new Intl.DateTimeFormat("en-US", { dateStyle: "long" }).format(
              new Date(recentImport.date)
            )
          }}</span>
        </div>
        <ul class="recent-imports__import-list">
          <li v-for="identity in recentImport.identities" :key="identity.id">
            <CloakCard
              :identity="identity"
              :no-select="true"
              @click="onCloakClick(identity)"
            />
          </li>
        </ul>
        <InfiniteTrigger
          v-if="$store.getters['recentlyImported/hasNextPage']"
          @infinite="$store.dispatch('recentlyImported/fetch')"
          class="recent-imports__infinite-trigger"
        />
      </div>
    </template>
    <div v-else class="recent-imports__empty">No identities found</div>
  </div>
</template>

<script>
import CloakCard from "@/components/global/CloakCard.vue";
import InfiniteTrigger from "@/components/global/InfiniteTrigger";
import { hash } from "@/scripts";
import { decode } from "@/scripts/hash";

export default {
  components: {
    InfiniteTrigger,
    CloakCard,
  },
  computed: {
    recentlyImported() {
      return this.$store.getters["recentlyImported/getRecentlyImported"];
    },
    recentImports() {
      return this.$store.getters["recentlyImported/getRecentImports"];
    },
    cloak_id() {
      if (this.$route.params.id) {
        return decode(this.$route.params.id);
      }
      return false;
    },
  },
  created() {
    if (this.recentlyImported.length === 0) {
      this.$router.push({ name: "All" });
    }
  },
  watch: {
    cloak_id(value) {
      if (value) {
        this.$store
          .dispatch("fetchPopulatedData", { id: value })
          .then((data) => {
            this.$store.dispatch("openCloakDetails", {
              cloak: data,
            });
          });
      }
    },
    recentlyImported() {
      if (this.recentlyImported.length === 0) {
        this.$router.push({ name: "All" });
      }
    },
  },
  methods: {
    onCloakClick(identity) {
      this.$router.push(`/recently-imported/cloak/${hash.encode(identity.id)}`);
    },
  },
};
</script>

<style lang="scss">
.recent-imports {
  &__title {
    padding: 0 24px;
    margin-top: 24px;
    //font-weight: 300;
    //font-size: 40px;
    //line-height: 60px;
    //letter-spacing: -1px;
    //color: $color-primary-100;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: $color-primary-100;
  }

  &__import {
    margin-top: 58px;

    &:nth-child(2) {
      margin-top: 20px;
    }

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
    }

    &-date {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.5px;
      color: $color-primary-100;
    }

    &-undo {
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      color: #7772f8;
      display: flex;
      align-items: center;
      background: none;
      padding: 0;
      border: none;
      cursor: pointer;

      &-icon {
        margin-right: 6px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(171px, 1fr));
      gap: 12px;
      padding: 20px 24px 0;
    }
  }

  &__empty {
    margin-top: 20px;
    padding: 0 24px;
  }

  &__infinite-trigger {
    display: flex;
    justify-content: center;
  }
}
</style>
