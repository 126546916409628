var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "trash-activity-container" }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _c(_setup.ActivityPreviewList, {
          ref: "list",
          attrs: {
            type: "all",
            isRequests: false,
            multiSelected: {
              threadIds: [],
              conversationIds: [],
            },
            singleSelected: null,
            multiSelectEnabled: [],
          },
          on: {
            select: () => {},
            enableMultiSelect: () => {},
            setMultiSelect: () => {},
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "right" }, [_vm._v("right hand")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }