import api from "@/api/api";
import store from "@/store";
export default class CardsServices {
  static async postCreateAPaymentSource(payload) {
    return await api().post(`/api/v1/card/vendor-flow/`, payload);
  }
  static async postActiveKYC(values) {
    return api().post("/api/v1/kyc/", values);
  }

  static async getPaymentSource() {
    return await api()
      .get(`/api/v1/card/`)
      .then((resp) => {
        store.dispatch("fundingSourcesList", resp.data.results);
      })
      .catch(() => {});
  }

  static async deleteFundingSource(id) {
    return await api().delete(`/api/v1/card/${id}/?force=true`);
  }

  static async patchUpdateCardDetails(id, payload) {
    return await api().patch(`/api/v1/card/${id}/`, payload);
  }

  static async getPrimaryCard() {
    return await api()
      .get(`/api/v1/card/primary/`)
      .then((resp) => {
        store.dispatch("addPrimaryCard", resp.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return;
        }
      });
  }

  static async getCardList() {
    return await api()
      .get(`/api/v1/cloaked/card?page_size=50`)
      .then((response) => {
        store.dispatch("addCardList", response.data);
      });
  }

  static async createIdentityCard(payload) {
    alert("createIdentityCard");
    return await api().post(
      `/api/v1/cloaked/identity/${store.state.rightPanel.cloak.id}/card/mastercard/`,
      payload
    );
  }

  static async getTransactions() {
    return await api().get(`/api/v1/cloaked/card/transaction/?page_size=100`);
  }

  static async getIdentityTransactions(id) {
    return await api().get(`/api/v1/cloaked/identity/${id}/transaction/`);
  }

  static async getIdentityCards(cloakId) {
    const url = `/api/v1/cloaked/identity/${cloakId}/card/`;
    return api()
      .get(url)
      .then(({ data }) => {
        store.commit("identityCards", data.results);
      });
  }

  static async getSingleIdentityCard(cloakId, cardId) {
    const url = `/api/v1/cloaked/identity/${cloakId}/card/${cardId}`;
    return api()
      .get(url)
      .then(({ data }) => {
        store.commit("currentCard", data);
      });
  }
}
