<template>
  <div class="expansion-panel">
    <div>
      <span class="button-label" v-on:click="handleOnClick">
        {{ buttonText }}
      </span>
      <ArrowButton
        :onClick="handleOnClick"
        :isOpen="isOpen"
        class="dark-arrow"
      />
    </div>
    <div :style="contentStyles">
      <slot>
        if you see me, you need to add content to the expansion panel component
      </slot>
    </div>
  </div>
</template>
<script>
import ArrowButton from "./ArrowButton";

export default {
  name: "ExpansionPanel",
  props: {
    /* required */
    buttonText: String,
    /* optional, in milliseconds */
    duration: Number,
    onClick: Function,
  },
  components: {
    ArrowButton,
  },
  data() {
    return {
      isOpen: false,
      height: 0,
    };
  },
  methods: {
    handleOnClick(e) {
      this.isOpen = !this.isOpen;
      if (
        this.isOpen &&
        this.$slots?.default[0]?.componentInstance?.$el?.scrollHeight
      ) {
        this.height = this.$slots.default[0].componentInstance.$el.scrollHeight;
      } else if (this.isOpen && this.$slots?.default[0]?.elm?.scrollHeight) {
        this.height = this.$slots.default[0].elm.scrollHeight;
      } else {
        this.height = 0;
      }
      if (this.onClick) {
        this.onClick(e);
      }
    },
  },
  computed: {
    contentStyles() {
      return `
      transition: height ${this.duration || 200}ms ease;
    -moz-transition: height ${this.duration || 200}ms ease;
    -webkit-transition: height ${this.duration || 200}ms ease;
    -o-transition: height ${this.duration || 200}ms ease;
    overflow: hidden;
    height: ${this.height}px;
      `;
    },
  },
};
</script>

<style scoped lang="scss">
.expansion-panel {
  color: $color-primary-100;
  .button-label {
    color: $color-primary-100;
  }
  svg {
    color: $color-primary-100;
  }
}
.button-label {
  text-decoration: underline;
  font-family: $poppins;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
.dark-arrow {
  color: $color-primary-90;
}
</style>
