<script setup>
import {
  defineProps,
  nextTick,
  watch,
  reactive,
  onBeforeUnmount,
  ref,
  computed,
  onMounted,
} from "vue";

const tooltipWrapper = ref(null);
const tooltip = ref(null);

const props = defineProps({
  title: { type: String, default: "" },
  position: { type: String, default: "bottom" },
  alignX: { type: String, default: "left" },
  maxWidth: { type: [String, Number], default: null },
  width: { type: [String, Number], default: null },
  offsetX: { type: [Number, String], default: 0 },
  offsetY: { type: [Number, String], default: 0 },
  canShow: { type: Boolean, default: true },
  isOnSharedPage: { type: Boolean, default: false },
});

const POSITIONS = ["bottom", "top"];
const ALIGNMENTS_X = ["left", "center", "right"];

const state = reactive({
  hoverRect: {},
  tooltipPosition: {},
  isVisible: false,
  isEnterState: false,
  isLeaveState: false,
  leaveTimeoutId: null,
});

const style = computed(() => {
  const calculatedStyle = {};

  if (state.tooltipPosition.top && state.tooltipPosition.left) {
    calculatedStyle.top = `${state.tooltipPosition.top}px`;
    calculatedStyle.left = `${state.tooltipPosition.left}px`;
  }

  if (props.maxWidth) {
    calculatedStyle.maxWidth = `${props.maxWidth}px`;
  }

  if (props.width) {
    calculatedStyle.width = `${props.width}px`;
  }

  return calculatedStyle;
});

const isMobileDevice = computed(() => {
  return navigator.maxTouchPoints > 0;
});

watch(
  () => props.title,
  () => {
    if (!props.title) {
      hideTooltip();
    }
  }
);

watch(
  () => props.canShow,
  (value) => {
    if (!value) {
      hideTooltip();
    }
  }
);

onMounted(() => {
  window.addEventListener("scroll", handleWindowScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleWindowScroll);
  hideTooltip();
});

function handleWindowScroll() {
  if (!state.isVisible) {
    return;
  }

  setPositions();
}

function handleMouseMove() {
  if (!state.isVisible) {
    return;
  }

  state.hoverRect = tooltipWrapper.value.getBoundingClientRect();

  nextTick().then(() => {
    setPositions();
  });
}

function handleMouseEnter() {
  if (!props.canShow || isMobileDevice.value) {
    return;
  }

  clearTimeout(state.leaveTimeoutId);

  state.isLeaveState = false;

  state.hoverRect = tooltipWrapper.value.getBoundingClientRect();

  showTooltip();

  nextTick()
    .then(setPositions)
    .then(() => {
      state.isEnterState = true;
    });
}

function handleTouch() {
  if (!props.canShow) {
    return;
  }

  clearTimeout(state.leaveTimeoutId);

  state.isLeaveState = false;

  state.hoverRect = tooltipWrapper.value.getBoundingClientRect();

  showTooltip();

  nextTick()
    .then(setPositions)
    .then(() => {
      state.isEnterState = true;

      // Hiding after 1 second on mobile devices
      state.leaveTimeoutId = setTimeout(() => {
        state.isLeaveState = false;

        hideTooltip();
      }, 1000);
    });
}

function handleMouseLeave() {
  state.isEnterState = false;
  state.isLeaveState = true;

  state.leaveTimeoutId = setTimeout(() => {
    state.isLeaveState = false;

    hideTooltip();
  }, 100);
}

function setPositions() {
  const position = {
    left: getLeftPosition(props.alignX) + Number(props.offsetX),
    top: getTopPosition(props.position) + Number(props.offsetY),
  };

  const { innerWidth, innerHeight } = window;

  const tooltipRect = tooltip.value.getBoundingClientRect();

  if (position.top < 0) {
    position.top = getTopPosition("bottom");
  } else {
    if (position.top + tooltipRect.height > innerHeight) {
      position.top = getTopPosition("top");
    }
  }

  // check if tooltip overflows to the right
  if (position.left + tooltipRect.width > innerWidth) {
    position.left = getLeftPosition("right");
  } else {
    // check if tooltip overflows left
    if (position.left < 0) {
      position.left = getLeftPosition("left");
    }
  }

  state.tooltipPosition = position;

  return nextTick();
}

function getTopPosition(position) {
  if (!POSITIONS.includes(position)) {
    return 0;
  }

  const tooltipRect = tooltip.value.getBoundingClientRect();
  const { y, height } = state.hoverRect;

  switch (position) {
    case "bottom":
      return y + height;
    case "top":
      return y - tooltipRect.height;
  }

  return 0;
}

function getLeftPosition(alignX) {
  if (!ALIGNMENTS_X.includes(alignX)) {
    return 0;
  }

  const tooltipRect = tooltip.value.getBoundingClientRect();
  const { x, width } = state.hoverRect;

  switch (alignX) {
    case "left":
      return x;
    case "center":
      return x - tooltipRect.width / 2 + width / 2;
    case "right":
      return x - tooltipRect.width + width;
  }
}

function showTooltip() {
  const conditions = [props.canShow, props.title];

  if (conditions.every(Boolean)) {
    moveToBody().then(() => {
      state.isVisible = true;
    });
  }
}

function hideTooltip() {
  if (!state.isVisible) {
    return;
  }

  state.isVisible = false;
  moveBack();
}

function moveToBody() {
  return nextTick().then(() => {
    document.body.appendChild(tooltip.value);
  });
}

function moveBack() {
  tooltipWrapper.value.appendChild(tooltip.value);
  state.tooltipPosition = {};
  state.isEnterState = false;
  state.isLeaveState = false;
}
</script>

<template>
  <div
    ref="tooltipWrapper"
    class="ui-tooltip-wrapper"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @mousemove="handleMouseMove"
    @touchstart="handleTouch"
    v-on="$listeners"
  >
    <slot />

    <div
      ref="tooltip"
      class="ui-tooltip"
      :class="{
        'ui-tooltip--visible': state.isVisible,
        'ui-tooltip--enter-state': state.isEnterState,
        'ui-tooltip--leave-state': state.isLeaveState,
      }"
      :style="style"
    >
      <div
        class="ui-tooltip__content"
        :class="{
          'ui-tooltip__content--shared-page': props.isOnSharedPage,
        }"
      >
        <slot name="content">{{ props.title }}</slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.ui-tooltip-wrapper {
  display: inline-flex;
  align-items: center;

  .ui-tooltip {
    display: none;
  }
}

.ui-tooltip {
  visibility: hidden;
  padding: 4px;
  position: fixed;
  pointer-events: none;
  z-index: -1;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: $color-primary-90;
    border-radius: 6px;
    box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
      13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
    backdrop-filter: blur(50px);
    padding: 10px;
    line-height: 16px;
    color: $color-primary-0;
    font-size: 11px;
    letter-spacing: -0.1px;
    font-weight: 500;

    &--shared-page {
      background: $color-primary-90-light;
      color: $color-background-light;
    }
  }

  svg {
    max-height: 24px;
    max-width: 24px;
    fill: currentColor;
  }

  &--visible {
    visibility: visible;
    z-index: 10000;
  }

  &--enter-state {
    animation: ui-tooltip-enter 0.2s ease-in-out;
  }

  &--leave-state {
    animation: ui-tooltip-leave 0.2s ease-in-out;
  }

  @keyframes ui-tooltip-enter {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes ui-tooltip-leave {
    0% {
      opacity: 1;
      transform: translateY(0px);
    }

    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
}
</style>
