var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ModalTemplate, {
    ref: "modal",
    attrs: { show: _vm.isModalVisible, fullHeight: "" },
    on: {
      close: function ($event) {
        return _setup.emit("closeModal")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("h1", [_vm._v("Move to " + _vm._s(_setup.categoryName))]),
            _c(
              _setup.Button,
              {
                attrs: {
                  "aria-id": "CategorySaveButton",
                  disabled: _setup.state.selected.length === 0,
                },
                nativeOn: {
                  click: function ($event) {
                    return _setup.addCloaksToCategory.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Done ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "input-wrapper" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _setup.state.searchText,
                    expression: "state.searchText",
                  },
                ],
                ref: "searchCloaks",
                attrs: {
                  "aria-id": "CategoryAddIDInput",
                  type: "text",
                  placeholder: "Type contact name, URL, or app",
                  autocomplete: "off",
                },
                domProps: { value: _setup.state.searchText },
                on: {
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return (() =>
                      _setup.select(
                        _setup.displayResults[_setup.state.active]
                      )).apply(null, arguments)
                  },
                  keyup: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      )
                        return null
                      return _setup.nav(-1)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      )
                        return null
                      return _setup.nav(1)
                    },
                  ],
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_setup.state, "searchText", $event.target.value)
                  },
                },
              }),
            ]),
            _setup.displayResults && _setup.displayResults.length
              ? _c("div", { ref: "category_results", staticClass: "results" }, [
                  _c(
                    "ul",
                    [
                      _vm._l(_setup.displayResults, function (identity, idx) {
                        return _c(
                          "li",
                          {
                            key: identity.id,
                            staticClass: "resultItem",
                            class: {
                              selected: _setup.isSelected(identity),
                              active: _setup.state.active === idx,
                            },
                            attrs: {
                              "aria-id": `ResultIdentityName.${
                                identity?.nickname || ""
                              }`,
                              id: `i-${idx}`,
                            },
                            on: {
                              mousemove: function ($event) {
                                return _setup.nav(idx, true)
                              },
                              click: () => _setup.select(identity),
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(_setup.IdentityIcon, {
                                  attrs: { identity: identity },
                                }),
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_setup.formatNickname(identity))
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _setup.isSelected(identity)
                              ? _c(_setup.CheckActive)
                              : _c(_setup.CheckInactive),
                          ],
                          1
                        )
                      }),
                      _setup.state.searchResults.length >
                      _setup.displayResults.length
                        ? _c(
                            "li",
                            [
                              _c(_setup.InfiniteTrigger, {
                                ref: "infinite",
                                on: { infinite: _setup.addSize },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ])
              : (!_setup.displayResults || !_setup.displayResults.length) &&
                _setup.state.searchText.length &&
                !_setup.state.loadingSearch
              ? _c("div", { staticClass: "emptyResults" }, [
                  _c("span", [_vm._v("No search results found for")]),
                  _c("span", [
                    _vm._v('"' + _vm._s(_setup.state.searchText) + '"'),
                  ]),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }