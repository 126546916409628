<script setup>
import SettingsChildPage from "./SettingsChildPage.vue";
import { SettingsTitle, SettingsParagraph } from "@/components";
import EmailGen from "@/routes/modals/preferences/EmailGen.vue";
</script>
<template>
  <SettingsChildPage class="email-generation">
    <template v-slot:aside>
      <SettingsTitle>Email generation</SettingsTitle>
      <SettingsParagraph
        >Choose your default email generation format. You can always override
        these default settings when generating a new Cloaked
        email.</SettingsParagraph
      >
    </template>

    <EmailGen />
  </SettingsChildPage>
</template>
