<script setup>
import store from "@/store";
import {
  CloakActions,
  CloakNicknameSection,
  CloakCategorySection,
  CloakWebSection,
  CloakIdentifierSection,
  CloakCommunicationSection,
  CloakVirtualCards,
  CloakNotesSection,
  CloakContacts,
} from "@/components/cloakDetails";
import CustomFieldsSection from "@/components/cloakDetails/CustomFields/CustomFieldsContainer.vue";

import {
  SidebarHeaderButton,
  SidebarHeader,
  SidebarSeparator,
} from "@/components";

import { DoubleChevronRight } from "@/assets/icons";
import { getAddressFromAutofill } from "@/components/cloakDetails/CustomFields/CustomFieldForm/utils";

import { reactive, computed, onMounted, watch } from "vue";

const state = reactive({
  loading: false,
  apiCloak: null,
});

onMounted(refreshApi);

const storeCloak = computed(() => {
  return store.getters.getCloak;
});

const isCancelled = computed(() => {
  return store.getters["settings/isCancelled"];
});

const isTrial = computed(() => {
  return store.getters["settings/isTrial"];
});

const showCloakDetails = computed(() => {
  return store.getters.getCloakDetails;
});

const cloak = computed(() => {
  if (state.apiCloak) {
    return {
      ...state.apiCloak,
      ...storeCloak.value,
    };
  }
  return storeCloak.value;
});

const cardsEnabled = computed(() => {
  return store.state.authentication.user?.cloaked_card_enabled;
});

const kycValidated = computed(() => {
  return store.state.authentication.user?.cloaked_card_kyc_configured;
});

const customFields = computed(() => {
  return cloak.value.customFields?.filter(
    (field) => !["totp_url", "totp_secret"].includes(field.type)
  );
});

const existingAddresses = computed(() => {
  if (store.state.autofill) {
    return [getAddressFromAutofill(store.state.autofill)].filter((address) =>
      [
        "country",
        "state",
        "city",
        "street_address",
        "unit",
        "postal_code",
      ].some((key) => !!address?.[key])
    );
  }
  return [];
});

watch(
  () => showCloakDetails.value,
  (value) => {
    if (value === true) {
      refreshApi();
    }
  }
);

function openSubscribeModal() {
  store.dispatch("subscription/openSubscriptionModal");
}

function refreshApi() {
  return store.dispatch("fetchPopulatedData", cloak.value).then((data) => {
    state.apiCloak = data;
  });
}

function handleClosePanel() {
  store.dispatch("closeRightPanel");
}

async function refreshCloak(newCloakData, callback) {
  state.loading = true;
  return store
    .dispatch("fetchPopulatedData", newCloakData)
    .then((data) => {
      state.loading = false;
      if (callback) {
        callback();
      }
      state.apiCloak = data;
      return store.dispatch("updateCloakDetails", data);
    })
    .catch(() => {
      if (callback) {
        callback();
      }
      state.loading = false;
    });
}
</script>

<template>
  <section
    v-if="cloak"
    class="cloak-details-wrapper"
    :key="`cloak-details-${cloak.id}`"
  >
    <SidebarHeader>
      <SidebarHeaderButton noBackground @click.native="handleClosePanel">
        <DoubleChevronRight />
      </SidebarHeaderButton>

      <CloakActions :cloak="cloak" @refresh="refreshCloak" />
    </SidebarHeader>

    <CloakNicknameSection
      :readOnly="isCancelled"
      :cloak="cloak"
      @refresh="refreshCloak"
    />
    <button class="read-only" v-if="isCancelled" @click="openSubscribeModal">
      Your identities are read-only • Upgrade now
    </button>
    <CloakCategorySection
      :readOnly="isCancelled"
      :refreshing="state.loading"
      :cloak="cloak"
      @refresh="refreshCloak"
    />

    <CloakWebSection
      :readOnly="isCancelled"
      :key="`web-${cloak.id}`"
      :refreshing="state.loading"
      :cloak="cloak"
      @refresh="refreshCloak"
    />

    <SidebarSeparator />

    <CloakIdentifierSection
      :refreshing="state.loading"
      :cloak="cloak"
      @refresh="refreshCloak"
      :readOnly="isCancelled"
      :showLimits="isTrial"
    />
    <SidebarSeparator />

    <CustomFieldsSection
      :readOnly="isCancelled"
      :identity="cloak"
      :items="customFields"
      :existing-addresses="existingAddresses"
      @refresh="refreshCloak"
    />
    <SidebarSeparator v-if="$store.getters.isV2User" />

    <CloakContacts :identityId="cloak.id" :readOnly="isCancelled" />

    <SidebarSeparator />

    <CloakVirtualCards
      v-if="cardsEnabled && kycValidated"
      :class="{ disable: isCancelled }"
    />

    <SidebarSeparator v-if="cardsEnabled && kycValidated" />

    <CloakCommunicationSection
      :refreshing="state.loading"
      :cloak="cloak"
      :readOnly="isCancelled"
      @refresh="refreshCloak"
    />

    <SidebarSeparator />

    <CloakNotesSection
      :cloak="cloak"
      :readOnly="isCancelled"
      @refresh="refreshCloak"
    >
      <portal-target name="identity-detail" multiple />
    </CloakNotesSection>

    <SidebarSeparator />
  </section>
</template>

<style lang="scss" scoped>
.disable {
  pointer-events: none;
  opacity: 0.4;
}
.read-only {
  border: none;
  cursor: pointer;
  font-size: 12px;
  width: calc(100% - 40px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: $color-primary-0;
  background-color: $color-primary-100;
  border-radius: 100px;
  padding: 8px 20px;
  text-align: center;
  margin: 0 20px;
}
.cloak-details-wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  .sidebar-header {
    justify-content: space-between;
    position: relative;
    z-index: 1001;
    background-color: $color-surface;
  }

  &__body {
    flex: 1 1 0;
    overflow: auto;
  }
}
</style>
