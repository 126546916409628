<script setup>
import AccountDeleteBanner from "./AccountDeleteBanner";
import ConfirmBanner from "./ConfirmBanner";
import { get } from "lodash-es";
import AutoChangeBanner from "@/components/auto-change/AutoChangeBanner.vue";
import UpgradeAccount from "@/components/Navigation/NavigationUpgradeAccount.vue";
import TrialAccount from "@/components/Navigation/NavigationTrialAccount.vue";
import { reactive, computed, watch, defineEmits } from "vue";
import store from "@/store";

const emit = defineEmits(["visible"]);

const state = reactive({
  emailNeedsConfirmation: false,
});

const userDeleteIsScheduled = computed(() => {
  return !!get(store.state.authentication.user, "deletion_date");
});

const isAutoChangeInProgress = computed(() => {
  return store.getters["autoChange/isInProgress"];
});

const hasVisibleBanner = computed(() => {
  return (
    [
      userDeleteIsScheduled.value,
      state.emailNeedsConfirmation,
      isAutoChangeInProgress.value,
      isCancelled.value,
      isTrial.value,
    ].filter((d) => !!d).length > 0
  );
});

const isCancelled = computed(() => {
  return store.getters["settings/isCancelled"];
});

const isTrial = computed(() => {
  return store.getters["settings/isTrial"];
});

watch(
  () => hasVisibleBanner.value,
  (newValue) => {
    emit("visible", newValue);
  },
  { immediate: true }
);

function setVisible(visible) {
  state.emailNeedsConfirmation = visible;
}
</script>

<template>
  <div class="app-banners">
    <UpgradeAccount v-if="isCancelled" />
    <TrialAccount v-else-if="isTrial" />
    <AccountDeleteBanner v-else-if="userDeleteIsScheduled" />
    <template v-else>
      <AutoChangeBanner v-if="isAutoChangeInProgress" />
      <ConfirmBanner v-else @visible="setVisible" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.app-banners {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
</style>
