<script setup>
import { ref, computed } from "vue";
import store from "@/store";
import CloakedLogo from "@/assets/images/CloakedLogo.svg";
import Categories from "./NavigationLeftPanel/CategoriesMenuBlock.vue";
import Inbox from "./NavigationLeftPanel/InboxMenuBlock.vue";
import Trash from "./NavigationLeftPanel/TrashMenuBlock.vue";
import Identities from "./NavigationLeftPanel/IdentitiesMenuBlock.vue";
import Wallet from "./NavigationLeftPanel/WalletMenuBlock.vue";
import { PlusIcon, Compose } from "@/assets/icons";
import { useRoute } from "vue-router/composables";
import api from "@/api/api";
import ActivityService from "@/api/actions/activity-service";

//

import AddEditNewCategory from "@/routes/modals/categories/AddEditNewCategory";
import ComposePanel from "@/components/global/compose";

const route = useRoute();

const isCatActionsVisible = ref(false);
const closeEditCategoryModal = () => {
  isCatActionsVisible.value = false;
};

function openCategory() {
  isCatActionsVisible.value = true;
}

function newIdentity() {
  store.dispatch("openCloakCreate");
}

function isCloakSpecific() {
  if (store.state.ui.activities.cloak_id) {
    return true;
  }
  return false;
}

function openCompose() {
  if (isCloakSpecific()) {
    store.dispatch("compose", {
      cloak_id: store.state.ui.activities.cloak_id,
    });
  } else {
    store.commit("compose", {});
  }
}

const new_message = computed(() => {
  return !!store.state.compose_message.identity;
});

function send(data) {
  if (data.isSms) {
    const payload = {
      text: data.text,
      to: data.to,
      identity_id: data.identity_id,
    };
    if (data.media) {
      payload.media = data.media;
    }
    return api().post("/api/v1/cloaked/activity/message/compose/", payload);
  }
  const payload = {};
  if (data.cc) {
    payload.cc = data.cc;
  }
  if (data.attachments) {
    payload.attachments = data.attachments;
  }
  if (data.emailId) {
    payload.to = data.to;
    payload.subject = data.subject[0];
    payload.original_html = data.originalHTMLContent;
    payload.html = data.text;

    if (data.originalTextContent) {
      payload.original_text = data.originalTextContent;
      payload.text = data.text;
    }
    return ActivityService.postActivityReply(data.activityId, payload);
  }
  payload.text = data?.text || "";
  payload.to = data.to;
  payload.subject = data.subject;
  payload.identity_id = data.identity_id;
  return api().post("/api/v1/cloaked/activity/email/compose/", payload);
}

const subscriptionState = computed(() => {
  return store.state.settings.subscription.state;
});
</script>

<template>
  <section :class="{ hidden: route.fullPath.includes('settings') }">
    <div class="logo">
      <router-link to="/">
        <CloakedLogo />
      </router-link>
    </div>

    <nav>
      <Identities />
      <Wallet />
      <Inbox />
      <Categories @addCategory="openCategory" />
      <Trash />
    </nav>

    <div class="buttons" v-if="subscriptionState !== 'CANCELLED'">
      <button class="primary" @click="newIdentity()">
        <PlusIcon />
        <span>New identity</span>
      </button>
      <button @click="openCompose">
        <Compose />
        <span>Compose message</span>
      </button>
    </div>

    <!-- your days are numbered old components... -->

    <AddEditNewCategory
      :isModalVisible="isCatActionsVisible"
      @closeModal="closeEditCategoryModal"
    />
    <ComposePanel v-if="new_message" :onSend="send" />

    <!-- very soon you will face the wrath of a stubborn British person -->
  </section>
</template>

<style lang="scss" scoped>
section {
  width: 240px;
  height: 100vh;
  background-color: $color-surface;
  position: fixed;
  left: 0;
  top: 0;
  border-right: 1px solid $color-primary-10;
  overflow: auto;
  @include custom-scroll-bar();
  z-index: 101;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  .app--visible-banner & {
    top: 40px;
    height: calc(100vh - 40px);
  }

  &.hidden {
    display: none;
  }

  .logo {
    color: $color-primary-100;
    position: sticky;
    top: 0;
    background-color: $color-surface;
    padding: 20px 18px 8px 18px;
    margin-bottom: 16px;
    z-index: 3;

    svg {
      width: 97px;
      height: auto;
    }
  }

  nav {
    padding: 0 18px 22px 18px;
    position: relative;
    z-index: 2;
    flex: 1;
  }

  .buttons {
    position: sticky;
    bottom: 0;
    background-color: $color-surface;
    padding: 16px 18px 32px 18px;
    z-index: 3;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: $color-primary-10;
    }

    button {
      margin-top: 8px;
      width: 100%;
      padding: 11px 0;
      border-radius: 999px;
      font-size: 12px;
      font-weight: 500;
      border: 1px solid $color-primary-100;
      background: transparent;
      color: $color-primary-100;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-of-type {
        margin-top: 0;
      }

      &.primary {
        background-color: $color-primary-100;
        color: $color-primary-0;

        &:hover {
          background-color: $color-primary-100;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: $color-primary-10;
      }

      svg {
        margin-right: 6px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
