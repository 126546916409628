<script setup>
import { computed, onBeforeMount, onUnmounted, reactive, watch } from "vue";
import { get } from "lodash-es";
import { password_confirm } from "@/scripts/actions/encryption";
import AuthService from "@/api/actions/auth-service";
import EmailService from "@/api/actions/email-service";
import PhoneSerivce from "@/api/actions/phone-service";
import MfaService from "@/api/actions/mfa-service";
import store from "@/store";
import { useToast } from "@/hooks";

import { ACCOUNT_RECOVERY_NEW_MENU, MFA_ENABLED } from "@/scripts/featureFlags";
import { getAccountInitials, phone_format } from "@/scripts/format";
import { Button } from "@/components";
import ValueDisplay from "@/components/ui/value-display";
import {
  PreferencesParagraph,
  PreferencesTitle,
  PreferencesPanel,
  ChangePassword,
  AccountRecoveryPhone,
  AccountRecoveryEmail,
  AccountRecovery,
  AuthorizedDevices,
  DeleteAccount,
  ExportData,
  ManageAccount,
  MfaSetup,
} from "@/routes/modals/preferences";
import AsyncText from "@/components/AsyncText";

const showAccountRecoveryMenu = ACCOUNT_RECOVERY_NEW_MENU;

const toast = useToast();

const state = reactive({
  emails: [],
  phones: [],
  mfaMethods: [],
  mfaDevices: [],
  kycPass: false,
  password: null,
  passwordError: null,
  kyc: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  },
  card: {
    name: "",
    pan: "",
    expiry: "",
    code: "",
  },
  username: "",
});

const isMfaActive = computed(() => {
  return state.mfaMethods.length > 0;
});

const rightPanel = computed(() => {
  return store.state.ui.preference.right;
});
const fullName = computed(() => {
  if (store.state.autofill) {
    if (
      store.state.autofill.first_name ||
      "" ||
      store.state.autofill.last_name ||
      ""
    ) {
      return `${store.state.autofill.first_name} ${store.state.autofill.last_name}`.replaceAll(
        /\s+/g,
        " "
      );
    }
  }
  return false;
});
const accountInitialsDisplay = computed(() => {
  if (
    store.state.autofill &&
    (store.state.autofill.first_name ||
      "" ||
      store.state.autofill.last_name ||
      "")
  ) {
    return getAccountInitials(
      store.state.autofill.first_name,
      store.state.autofill.last_name
    );
  }
  return getAccountInitials(state.username);
});
const userDeleteIsScheduled = computed(() => {
  return !!get(store.state.user, "deletion_date");
});
const showMfaSection = computed(() => {
  /* Only show MFA to v2 users */
  if (MFA_ENABLED && store.state.authentication.user.encryption_status === 3) {
    return true;
  }
  return false;
});
const closeModal = () => {
  store.dispatch("closeModal");
};
const getUsername = () => {
  return store.dispatch("authentication/getUsername");
};
const getName = (value) => {
  return fullName.value
    ? accountInitialsDisplay.value
    : value.charAt(0).toUpperCase();
};
const goRight = (panel) => {
  if (panel) {
    store.commit("openPreference", {
      selected: "account",
      right: panel,
    });
  } else {
    store.commit("openPreference", { selected: "account" });
  }
};
const toggleBack = (data) => {
  if (data) {
    Object.keys(data).map((key) => {
      this[key] = data[key];
    });
  }
  store.commit("openPreference", { selected: "account" });
};
const refreshEmails = async () => {
  try {
    const res = await EmailService.getUserEmails();
    const { data } = res;
    state.emails = data.results;
  } catch (e) {
    toast.error("Unable to fech emails");
  }
};
const refreshPhones = async () => {
  try {
    const res = await PhoneSerivce.getUserPhoneNumbers();
    const { data } = res;
    state.phones = data.results;
  } catch (e) {
    toast.error("Unable to fech phones");
  }
};
const getPhoneById = (id) => {
  return MfaService.getPhoneById(id)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      toast.error("Something went wrong. Please try again later.");
    });
};
const getEmailById = (id) => {
  return MfaService.getEmailById(id)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      toast.error("Something went wrong. Please try again later.");
    });
};
const getMethodSettingsDisplayLabel = (method) => {
  switch (method) {
    case "sms":
      return "2FA SMS number";
    case "call":
      return "2FA phone call number";
    case "email":
      return "2FA email address";
    default:
      break;
  }
};
const getMfaMethods = async () => {
  MfaService.getEnabledMfaMethods()
    .then(async (response) => {
      const { enabled_methods } = response.data;
      state.mfaMethods = await Promise.all(
        enabled_methods.map(async (res) => {
          if (res.personal_phone_id) {
            const mfaPhoneDetails = await getPhoneById(res.personal_phone_id);
            mfaPhoneDetails.displayValue = phone_format(
              mfaPhoneDetails.phone_number
            );
            mfaPhoneDetails.method = res.method;
            mfaPhoneDetails.settingsDisplayLabel =
              getMethodSettingsDisplayLabel(res.method);
            return mfaPhoneDetails;
          } else if (res.personal_email_id) {
            const mfaEmailDetails = await getEmailById(res.personal_email_id);
            mfaEmailDetails.displayValue = mfaEmailDetails.email;
            mfaEmailDetails.method = res.method;
            mfaEmailDetails.settingsDisplayLabel =
              getMethodSettingsDisplayLabel(res.method);
            return mfaEmailDetails;
          }
        })
      );
    })
    .catch(() => {
      toast.error("Something went wrong. Please try again later.");
    });
};
const getMfaDevices = async () => {
  return MfaService.getMfaDevices()
    .then(async (response) => {
      const { results } = response.data;
      state.mfaDevices = results
        .filter((res) => res.confirmed === true && res?.device_id)
        .filter((value, index, self) => {
          return (
            self.findIndex((v) => v.device_id === value.device_id) === index
          );
        });
    })
    .catch(() => {
      toast.error("Something went wrong. Please try again later.");
    });
};
const getMfaMethodsAndDevices = async () => {
  if (showMfaSection.value) {
    getMfaMethods();
    getMfaDevices();
  }
};
const isMethodEmail = (method) => {
  /* For now it's just email or phone/sms for copy purposes */
  return method === "email";
};
const handleOpenRemoveModal = (method) => {
  state.password = null;
  state.passwordError = null;
  if (state.mfaMethods?.length === 1) {
    store.dispatch("openModal", {
      header:
        "Removing this method will disable two-factor authentication (2FA)",
      paragraphs: [
        "Since this is your only method for logging in with 2FA, removing it will also disable two-factor authentication.",
        "Please enter your password to confirm.",
      ],
      button: {
        text: "Remove",
        onClick: async () => {
          const isVerifiedPassword = await verifyPassword();
          if (isVerifiedPassword) {
            removeMethodAndDevices(method.id, method.method);
          }
        },
        danger: true,
        disabled: !state.password,
      },
      closeAfterOnClick: false,
      width: 512,
      input: {
        type: "password",
        placeholder: "Enter your password",
        label: "Password",
        value: state.password,
        handleInput: (value) => {
          state.password = value;
        },
        error: !!state.passwordError,
        errorMessage: state.passwordError,
      },
      events: {
        "mfa-devices-updated": getMfaMethodsAndDevices,
      },
    });
  } else {
    store.dispatch("openModal", {
      header: `Remove two-factor authentication ${
        isMethodEmail(method.method) ? "email" : "number"
      }?`,
      subheader: `You won’t be able to sign in with two-factor authentication using this ${
        isMethodEmail(method.method) ? "email" : "number"
      } again. You can always verify another ${
        isMethodEmail(method.method) ? "email address" : "phone number"
      } to continue using 2FA.`,
      button: {
        text: "Remove",
        onClick: () => removeMethodAndDevices(method.id, method.method),
        danger: true,
      },
      closeAfterOnClick: false,
      width: 512,
      events: {
        "mfa-devices-updated": getMfaMethodsAndDevices,
      },
    });
  }
};
const handleOpenMfaToggleModal = () => {
  state.passwordError = null;
  state.password = null;
  if (isMfaActive.value) {
    store.dispatch("openModal", {
      header: "Disable two-factor authentication",
      subheader:
        "Please enter your password to disable two-factor authentication. This will remove all devices and verified 2FA methods from your Cloaked account.",
      button: {
        text: "Disable",
        onClick: async () => {
          const isVerifiedPassword = await verifyPassword();
          if (isVerifiedPassword) {
            turnOffAllMfa();
            closeModal();
          }
        },
        danger: true,
        disabled: !state.password,
      },
      closeAfterOnClick: true,
      width: 512,
      input: {
        type: "password",
        placeholder: "Enter your password",
        label: "Password",
        value: state.password,
        handleInput: (value) => {
          state.password = value;
        },
        error: !!state.passwordError,
        errorMessage: state.passwordError,
      },
      events: {
        "mfa-devices-updated": getMfaMethodsAndDevices,
      },
    });
  }
};
const removeMethodAndDevices = (id, method) => {
  return MfaService.removeMethodAndDevices(id, method)
    .then(() => {
      toast.success("2FA method successfully removed.");
      getMfaMethodsAndDevices();
    })
    .catch(() => {
      toast.error("Something went wrong. Please try again later.");
    });
};
const verifyPassword = async () => {
  try {
    const hash = await password_confirm(state.password);
    const user = store.getters["authentication/user"];
    const userId = user.id;
    const passwordIsCorrect = await AuthService.confirmPassword(userId, hash);
    if (passwordIsCorrect) {
      state.password = null;
      state.passwordError = null;
      return true;
    } else {
      throw "invalid password";
    }
  } catch (e) {
    state.passwordError = "Invalid password, please try again.";
    toast.error("Invalid password, please try again.");
  }
};
const turnOffAllMfa = () => {
  return MfaService.disableAllMfaForAccount()
    .then(() => {
      toast.success("Two-factor authentication disabled.");
      getMfaMethodsAndDevices();
    })
    .catch(() => {
      toast.error("Something went wrong. Please try again later.");
    });
};

const launchMfaSetup = () => {
  goRight("mfa-setup");
};

watch(
  () => showMfaSection.value,
  (newVal, oldVal) => {
    if (!oldVal && newVal) {
      getMfaMethodsAndDevices();
    }
  }
);

onBeforeMount(() => {
  refreshEmails();
  refreshPhones();
  getMfaMethodsAndDevices();
  state.username = getUsername();
});

onUnmounted(() => {
  store.commit("setPreferenceRightPanel", {
    right: null,
  });
  store.commit("setPreferenceStep", {
    step: null,
  });
});
</script>
<template>
  <section class="preferences-account">
    <PreferencesPanel v-if="!rightPanel">
      <div class="preferences-account__username-row">
        <div class="preferences-account__username-avatar">
          <span>
            <AsyncText :getValue="getUsername" :format="getName" />
          </span>
        </div>

        <div class="username" aria-id="CloakedUsername">
          <div class="username-label">Username</div>
          <AsyncText :getValue="getUsername" />
        </div>
      </div>

      <ValueDisplay
        label="Change password"
        dark-label
        @click="goRight('password')"
      />

      <ValueDisplay
        v-if="showAccountRecoveryMenu"
        label="Account recovery"
        dark-label
        @click="goRight('recovery')"
      />

      <ValueDisplay
        label="Export my data"
        dark-label
        @click="goRight('export')"
      />

      <ValueDisplay
        v-if="!userDeleteIsScheduled"
        label="Delete account"
        danger
        @click="goRight('delete')"
      />

      <ValueDisplay
        v-if="userDeleteIsScheduled"
        label="Manage Account"
        dark-label
        @click="goRight('manage-account')"
      />

      <PreferencesTitle v-if="!showAccountRecoveryMenu"
        >Maintain your account access</PreferencesTitle
      >

      <PreferencesParagraph v-if="!showAccountRecoveryMenu"
        >These can be used to make sure it's really you signing in, reach you if
        there's suspicious activity in your account, or recover a lost
        password.</PreferencesParagraph
      >
      <ValueDisplay
        v-if="!showAccountRecoveryMenu"
        label="Recovery email"
        :value="primaryEmail ? primaryEmail.email : ''"
        @click="goRight('recovery-email')"
      />
      <ValueDisplay
        v-if="!showAccountRecoveryMenu"
        label="Recovery phone"
        :value="primaryPhone ? primaryPhone.phone_number : ''"
        @click="goRight('recovery-phone')"
      />

      <PreferencesTitle v-if="showMfaSection"
        >Two-factor authentication (2FA)</PreferencesTitle
      >

      <PreferencesParagraph v-if="showMfaSection"
        >For enhanced security, Cloaked recommends enabling two-factor
        authentication to ensure it's really you when signing
        in.</PreferencesParagraph
      >

      <ValueDisplay
        v-for="mfaMethod in state.mfaMethods"
        :key="mfaMethod?.id ? `${mfaMethod.id}-${mfaMethod.method}` : 'id'"
        :label="
          mfaMethod?.settingsDisplayLabel
            ? mfaMethod.settingsDisplayLabel
            : 'mfa device'
        "
        :value="
          mfaMethod?.displayValue ? mfaMethod.displayValue : 'an mfa device'
        "
        @delete="() => handleOpenRemoveModal(mfaMethod)"
        xIcon
      />

      <ValueDisplay
        v-if="showMfaSection"
        :label="
          state.mfaMethods.length > 0
            ? 'Add another option'
            : 'Set up two-factor authentication'
        "
        @click="launchMfaSetup"
        @mfa-devices-updated="getMfaMethodsAndDevices"
      />
      <ValueDisplay
        v-if="showMfaSection && isMfaActive"
        label="Authorized devices"
        :value="
          state.mfaDevices.length < 1
            ? 'No authorized devices'
            : state.mfaDevices.length > 1
            ? `${state.mfaDevices[0].name} and ${
                state.mfaDevices.length - 1
              } other${state.mfaDevices.length > 2 ? 's' : ''}`
            : `${state.mfaDevices[0].name}`
        "
        @click="
          state.mfaDevices.length > 0 ? goRight('authorized-devices') : () => {}
        "
        :class="{ no_devices: state.mfaDevices.length < 1 }"
      />

      <div
        v-if="showMfaSection && isMfaActive"
        @mfa-devices-updated="getMfaMethodsAndDevices"
        style="width: 100%; display: flex; padding: 24px 0px"
      >
        <Button @click="handleOpenMfaToggleModal" type="danger">
          Disable two-factor authentication
        </Button>
      </div>
    </PreferencesPanel>

    <MfaSetup
      v-if="rightPanel === 'mfa-setup'"
      @toggleBack="toggleBack"
      @mfa-devices-updated="getMfaMethodsAndDevices"
    />

    <ChangePassword @toggleBack="toggleBack" v-if="rightPanel === 'password'" />

    <AccountRecovery
      @toggleBack="toggleBack"
      v-if="rightPanel === 'recovery'"
    />

    <AuthorizedDevices
      @toggleBack="toggleBack"
      v-if="rightPanel === 'authorized-devices'"
      :devices="state.mfaDevices"
      @mfa-devices-updated="getMfaMethodsAndDevices"
    />

    <AccountRecoveryPhone
      v-if="rightPanel === 'recovery-phone' && !showAccountRecoveryMenu"
      @toggleBack="toggleBack"
      @refresh="refreshPhones"
      @delete="(phoneId) => deletePhone(phoneId)"
      :phones="state.phones"
    />

    <AccountRecoveryEmail
      v-if="rightPanel === 'recovery-email' && !showAccountRecoveryMenu"
      @toggleBack="toggleBack"
      @refresh="refreshEmails"
      @delete="(emailId) => deleteEmail(emailId)"
      :emails="state.emails"
    />

    <ExportData @toggleBack="toggleBack" v-if="rightPanel === 'export'" />

    <DeleteAccount @toggleBack="toggleBack" v-if="rightPanel === 'delete'" />

    <ManageAccount
      @toggleBack="toggleBack"
      v-if="rightPanel === 'manage-account'"
    />
  </section>
</template>

<style lang="scss">
.preferences-account {
  &__username-row {
    display: flex;
    gap: 18px;
    margin-bottom: 10px;
  }

  .username-label {
    font-size: 14px;
    font-weight: 400;
    color: $color-primary-70;
  }

  span {
    font-weight: 500;
  }

  &__username-avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-primary-70;
      color: $color-primary-0;
      border-radius: 50%;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -0.4px;
    }
  }

  .no_devices {
    &:hover {
      div,
      span,
      button {
        cursor: default !important;
      }
    }
  }
}
</style>
