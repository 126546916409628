var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesPanel",
    {
      staticClass: "change-password",
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("PreferencesHeader", { on: { "go-back": _vm.toggle_back } }),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "PreferencesFooter",
                [
                  _c(
                    "Button",
                    {
                      attrs: {
                        loading: _vm.loading,
                        disabled: _vm.loading || _vm.disabled,
                      },
                      on: { click: _vm.change_password },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("PreferencesTitle", [_vm._v("Change password")]),
      _c(
        "div",
        { staticClass: "change-password-inputs" },
        [
          _c("PreferencesInput", {
            attrs: {
              label: "Current Password",
              type: "password",
              placeholder: "",
              error: _vm.invalidPassword,
              disabled: _vm.loading,
            },
            on: { save: _vm.change_password },
            model: {
              value: _vm.currentPassword,
              callback: function ($$v) {
                _vm.currentPassword = $$v
              },
              expression: "currentPassword",
            },
          }),
          _c("PreferencesInput", {
            attrs: {
              label: "New Password",
              type: "password",
              placeholder: "",
              disabled: _vm.loading,
              error: _vm.fieldErrors.newPassword,
            },
            on: { save: _vm.change_password },
            nativeOn: {
              blur: function ($event) {
                return _vm.check_password({
                  name: "newPassword",
                  value: _vm.newPassword,
                })
              },
            },
            model: {
              value: _vm.newPassword,
              callback: function ($$v) {
                _vm.newPassword = $$v
              },
              expression: "newPassword",
            },
          }),
          _c(
            "PreferencesParagraph",
            [
              _c("PasswordStrength", {
                attrs: { password: _vm.newPassword },
                on: { passwordStrengthCheck: _vm.setCheck },
              }),
            ],
            1
          ),
          _c("PreferencesInput", {
            attrs: {
              label: "Confirm New Password",
              type: "password",
              placeholder: "",
              error:
                _vm.showPasswordsMatchError || _vm.fieldErrors.confirmPassword,
              disabled: _vm.loading,
            },
            on: { save: _vm.change_password },
            nativeOn: {
              blur: function ($event) {
                return _vm.check_password({
                  name: "confirmPassword",
                  value: _vm.confirmPassword,
                })
              },
            },
            model: {
              value: _vm.confirmPassword,
              callback: function ($$v) {
                _vm.confirmPassword = $$v
              },
              expression: "confirmPassword",
            },
          }),
          _c("PreferencesParagraph", [
            _c("div", { staticClass: "match_error" }, [
              _vm.newPassword && _vm.confirmPasswordError
                ? _c("div", [_vm._v(" Passwords do not match ")])
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }