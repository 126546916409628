<script setup>
import { defineProps } from "vue";
import InlineSvg from "@/components/InlineSvg";

const props = defineProps({
  icon: {
    type: String,
    default: "clap",
  },
  clickable: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: "Text is needed",
  },
  title: {
    type: String,
    default: "Title is needed",
  },
  subtext: {
    type: String,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <div class="button" :class="{ clickable: clickable, loading: props.loading }">
    <div class="icon">
      <InlineSvg :name="props.icon" :key="props.icon" />
    </div>

    <div class="text">
      <p>{{ props.text }}</p>
      <h1>{{ props.title }}</h1>
      <p v-if="props.subtext">{{ props.subtext }}</p>
      <slot v-else />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.button {
  width: 100%;
  border-radius: 20px;
  color: $color-primary-100;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  border: 1px solid $color-primary-10;
  padding: 16px;

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: $color-primary-5;
    }
  }

  &.loading {
    cursor: default;
    position: relative;

    .icon {
      background-color: $color-primary-5;
      border-radius: 50%;
      -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%)
        right/300% 100%;
      background-repeat: no-repeat;
      @include animation(shimmer 1s infinite);

      svg {
        opacity: 0;
      }
    }

    .text {
      h1 {
        position: relative;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          height: 80%;
          background-color: $color-primary-5;
          border-radius: 20px;
          -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%)
            right/300% 100%;
          background-repeat: no-repeat;
          @include animation(shimmer 1s infinite);
        }
      }

      p {
        position: relative;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 80%;
          height: 70%;
          background-color: $color-primary-5;
          border-radius: 20px;
          -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%)
            right/300% 100%;
          background-repeat: no-repeat;
          @include animation(shimmer 1s infinite);
        }

        &:last-of-type {
          &:before {
            width: 90%;
          }
        }
      }
    }

    &:hover {
      background-color: transparent;
    }

    @keyframes shimmer {
      100% {
        -webkit-mask-position: left;
      }
    }
  }

  .icon {
    width: 29px;
    height: 29px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 95%;
      height: auto;
      max-height: 80%;
      color: $color-primary-100;
    }
  }

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    &:first-child {
      margin-bottom: 4px;
    }
  }

  h1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}
</style>
