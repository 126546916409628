var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        _setup.UiMenu,
        {
          attrs: {
            width: "190px",
            placement: "bottom-start",
            "has-content-click-close": "",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "span",
                      { staticClass: "border-input-select__options" },
                      [
                        _vm._l(_vm.options, function (option, index) {
                          return [
                            _c(
                              "span",
                              {
                                key: index,
                                staticClass:
                                  "border-input-select__options-item",
                                on: {
                                  click: function ($event) {
                                    _vm.$emit("input", option)
                                    _vm.$emit("change", option)
                                  },
                                },
                              },
                              [
                                _vm._t(
                                  "option",
                                  function () {
                                    return [_vm._v(" " + _vm._s(option) + " ")]
                                  },
                                  { option: option }
                                ),
                              ],
                              2
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [
          _c(
            "label",
            {
              staticClass: "border-input-select",
              class: { "border-input-select--error": _vm.errors.length },
            },
            [
              _vm.label
                ? _c("span", { staticClass: "border-input-select__label" }, [
                    _vm._v(" " + _vm._s(_vm.label) + " "),
                  ])
                : _vm._e(),
              _c(
                "button",
                { staticClass: "border-input-select__button" },
                [
                  _vm.value
                    ? _vm._t(
                        "selected-value",
                        function () {
                          return [_vm._v(" " + _vm._s(_vm.value) + " ")]
                        },
                        { value: _vm.value, options: _vm.options }
                      )
                    : _c(
                        "span",
                        { staticClass: "border-input-select__placeholder" },
                        [_vm._v(" " + _vm._s(_vm.placeholder) + " ")]
                      ),
                  _c(_setup.IconChevron, {
                    staticClass: "border-input-select__chevron",
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _vm._l(_vm.errors, function (error) {
        return _vm._t(
          "error",
          function () {
            return [
              _c(_setup.InputValidationError, { key: error }, [
                _vm._v(" " + _vm._s(error) + " "),
              ]),
            ]
          },
          { error: error }
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }