var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    _vm._g({ staticClass: "button-add" }, _vm.$listeners),
    [_c(_setup.Plus), _vm._v(" " + _vm._s(_vm.label) + " ")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }