var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.props.isVisible
    ? _c("div", { staticClass: "search-footer" }, [
        _setup.props.leftText
          ? _c("span", { staticClass: "leftText" }, [
              _vm._v(" " + _vm._s(_setup.props.leftText) + " "),
            ])
          : _vm._e(),
        _c("div", [
          _c(
            "svg",
            {
              attrs: {
                width: "6",
                height: "8",
                viewBox: "0 0 6 8",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M2.99609 7.35156C3.26172 7.35156 3.44531 7.16797 3.44531 6.89453V2.46875L3.41406 1.66797L4.30469 2.67188L5.14844 3.50781C5.23047 3.58984 5.33984 3.64062 5.46875 3.64062C5.71094 3.64062 5.89453 3.46094 5.89453 3.20703C5.89453 3.08984 5.84766 2.98047 5.75391 2.88672L3.32812 0.457031C3.24219 0.367188 3.12109 0.316406 2.99609 0.316406C2.875 0.316406 2.75391 0.367188 2.66797 0.457031L0.242188 2.88672C0.148438 2.98047 0.101562 3.08984 0.101562 3.20703C0.101562 3.46094 0.285156 3.64062 0.527344 3.64062C0.660156 3.64062 0.769531 3.58984 0.847656 3.50781L1.69141 2.67188L2.58203 1.66406L2.55078 2.46875V6.89453C2.55078 7.16797 2.73438 7.35156 2.99609 7.35156Z",
                  fill: "white",
                },
              }),
            ]
          ),
        ]),
        _c("div", [
          _c(
            "svg",
            {
              attrs: {
                width: "6",
                height: "8",
                viewBox: "0 0 6 8",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M2.99609 0.316406C2.73438 0.316406 2.55078 0.5 2.55078 0.773438V5.19922L2.58203 6.00391L1.69141 4.99609L0.847656 4.16016C0.769531 4.07812 0.660156 4.02734 0.527344 4.02734C0.285156 4.02734 0.101562 4.20703 0.101562 4.46094C0.101562 4.57812 0.148438 4.6875 0.242188 4.78125L2.66797 7.21094C2.75391 7.30078 2.875 7.35156 2.99609 7.35156C3.12109 7.35156 3.24219 7.30078 3.32812 7.21094L5.75391 4.78125C5.84766 4.6875 5.89453 4.57812 5.89453 4.46094C5.89453 4.20703 5.71094 4.02734 5.46875 4.02734C5.33984 4.02734 5.23047 4.07812 5.14844 4.16016L4.30469 4.99609L3.41406 6L3.44531 5.19922V0.773438C3.44531 0.5 3.26172 0.316406 2.99609 0.316406Z",
                  fill: "white",
                },
              }),
            ]
          ),
        ]),
        _c("span", [_vm._v(" to navigate ")]),
        _c("div", [
          _c(
            "svg",
            {
              attrs: {
                width: "8",
                height: "7",
                viewBox: "0 0 8 7",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M7.01953 0.710938C6.75781 0.710938 6.58203 0.890625 6.58203 1.15625C6.58203 1.30078 6.59766 1.46094 6.59766 1.68359C6.59766 2.84375 6.18359 3.31641 5.03125 3.31641H2.71875L1.84766 3.36719L2.85938 2.44922L3.69141 1.61328C3.77344 1.53125 3.82812 1.42188 3.82812 1.29297C3.82812 1.05078 3.64453 0.867188 3.38672 0.867188C3.27344 0.867188 3.16406 0.914062 3.06641 1.00391L0.640625 3.42969C0.550781 3.51953 0.496094 3.64062 0.496094 3.76562C0.496094 3.88672 0.550781 4.00781 0.640625 4.09766L3.0625 6.51562C3.16406 6.61719 3.27344 6.66016 3.38672 6.66016C3.64453 6.66016 3.82812 6.48047 3.82812 6.23438C3.82812 6.10547 3.77344 5.99609 3.69141 5.91406L2.85938 5.07812L1.85156 4.16406L2.71875 4.21094H5.09375C6.77734 4.21094 7.5 3.41016 7.5 1.70312C7.5 1.48828 7.49219 1.29297 7.46094 1.125C7.42578 0.929688 7.30078 0.710938 7.01953 0.710938Z",
                  fill: "white",
                },
              }),
            ]
          ),
        ]),
        _c("span", [_vm._v("to select")]),
        _c("div", [
          _c(
            "svg",
            {
              attrs: {
                width: "16",
                height: "7",
                viewBox: "0 0 16 7",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M2.99023 6.08789C4.26465 6.08789 4.93848 5.35059 5.09473 4.51562H4.06445C3.93262 4.95996 3.55664 5.2334 3.00488 5.2334C2.26758 5.2334 1.79883 4.65723 1.79883 3.78809V3.66602H5.15332V3.17285C5.15332 1.71777 4.32324 0.750977 2.94629 0.750977C1.58887 0.750977 0.729492 1.68359 0.729492 3.12402V3.64648C0.729492 5.1748 1.6084 6.08789 2.99023 6.08789ZM1.79883 2.90918V2.89941C1.79883 2.16699 2.25293 1.59082 2.95117 1.59082C3.63965 1.59082 4.09375 2.16699 4.09375 2.89941V2.90918H1.79883ZM6.12012 2.34766C6.12012 3.29004 6.88672 3.6123 7.8291 3.8125C8.51758 3.94922 9.11816 4.03711 9.11816 4.57422C9.11816 4.95508 8.80078 5.28223 8.1123 5.28223C7.50195 5.28223 7.13574 4.99902 7.07715 4.5791H6.02246C6.02246 5.50195 6.80371 6.08789 8.04395 6.08789C9.34277 6.08789 10.1729 5.48242 10.1729 4.4375C10.1729 3.46094 9.4209 3.16309 8.46387 2.99219C7.87793 2.88477 7.16016 2.78223 7.16016 2.24023C7.16016 1.80566 7.53613 1.55664 8.08789 1.55664C8.79102 1.55664 9.01562 1.95703 9.0498 2.31836H10.0264C10.0264 1.40527 9.33789 0.750977 8.11719 0.750977C7.07227 0.750977 6.12012 1.22949 6.12012 2.34766ZM11.0518 3.62695C11.0518 5.07227 11.877 6.08789 13.2783 6.08789C14.4795 6.08789 15.2266 5.47266 15.3633 4.44238H14.3379C14.2646 4.84277 13.9424 5.18945 13.3418 5.18945C12.6045 5.18945 12.1406 4.58398 12.1406 3.62695V3.1875C12.1406 2.23535 12.585 1.64941 13.3369 1.64941C13.8496 1.64941 14.2207 2.03027 14.3086 2.46484H15.334C15.2949 1.56152 14.5332 0.750977 13.2783 0.750977C11.8818 0.750977 11.0518 1.75195 11.0518 3.16309V3.62695Z",
                  fill: "white",
                },
              }),
            ]
          ),
        ]),
        _c("span", [_vm._v("to dismiss")]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }