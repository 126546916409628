var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "cloak-identifier-section" }, [
    _c("header", { staticClass: "cloak-identifier-section__header" }, [
      _c(
        "h3",
        { staticClass: "cloak-identifier-section__header-title" },
        [
          _c("span", [_vm._v("Your identity")]),
          _c(
            _setup.UiTooltip,
            {
              attrs: {
                title: _setup.helpTooltip,
                position: "top",
                "max-width": "192",
                "align-x": "center",
              },
            },
            [_c(_setup.MoreInfoQIcon)],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass:
          "cloak-identifier-section__group cloak-identifier-section__group--no-separator",
      },
      [
        _setup.showPhoneLimits
          ? _c(
              "div",
              {
                staticClass: "number-remaining",
                class: { none: !_setup.hasPhoneRemaining },
              },
              [
                _vm._v(
                  " You have " +
                    _vm._s(
                      _setup.hasPhoneRemaining
                        ? _setup.getPhoneNumberRemaining
                        : 0
                    ) +
                    " phone numbers left "
                ),
              ]
            )
          : _vm._e(),
        _c(_setup.CloakInfoRow, {
          attrs: {
            field: "phone",
            placeholder: "Enter a phone number",
            initialValue: _setup.formattedPhone,
            loading: _setup.state.updatingCloak.phone,
            fieldType: _setup.getFieldType("phone"),
            error: !!_setup.state.errors.phone_number,
            readOnly: _setup.props.readOnly,
            nickname: _setup.props.cloak.nickname,
            phoneObject: _setup.props.cloak.cloaked_phone,
          },
          on: {
            generate: _setup.showConfirmPhoneModal,
            save: _setup.savePhone,
            delete: _setup.deletePhone,
            refresh: function ($event) {
              return _vm.$emit("refresh")
            },
          },
        }),
        _setup.willExpire
          ? _c("div", { staticClass: "alert-phone-expire" }, [
              _c("span", [
                _vm._v(
                  "This number will be auto-deleted on " +
                    _vm._s(_setup.willExpire)
                ),
              ]),
              _c(
                "button",
                { on: { click: _setup.removeModal } },
                [_c(_setup.MoreInfoQIcon)],
                1
              ),
            ])
          : _vm._e(),
        _c(_setup.CloakInfoRow, {
          attrs: {
            field: "email",
            placeholder: "Enter an email address",
            initialValue: _setup.email,
            loading: _setup.state.updatingCloak.email,
            fieldType: _setup.getFieldType("email"),
            error: !!_setup.state.errors.email,
            readOnly: _setup.props.readOnly,
          },
          on: {
            generate: _setup.handleGenerateEmail,
            save: (newVal, isAutofill) =>
              _setup.save("email", newVal, isAutofill),
            delete: _setup.deleteEmail,
          },
          scopedSlots: _vm._u([
            {
              key: "input-before",
              fn: function () {
                return [
                  _c(_setup.CloakGenerateEmailFlyout, {
                    attrs: { visible: _setup.state.showFlyout === "email" },
                    on: {
                      generate: _setup.showConfirmEmailModal,
                      close: function ($event) {
                        _setup.state.showFlyout = null
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c(_setup.CloakInfoRow, {
          attrs: {
            field: "username",
            placeholder: "Enter a username",
            initialValue: _setup.username,
            loading: _setup.state.updatingCloak.username,
            fieldType: _setup.getFieldType("username"),
            error: !!_setup.state.errors.username,
            readOnly: _setup.props.readOnly,
          },
          on: {
            generate: _setup.showConfirmUsernameModal,
            save: (newVal, isAutofill) =>
              _setup.save("username", newVal, isAutofill),
            delete: _setup.deleteUsername,
          },
        }),
        _c(_setup.CloakInfoRow, {
          attrs: {
            field: "password",
            placeholder: "Enter a password",
            initialValue: _setup.password,
            loading: _setup.state.updatingCloak.password,
            fieldType: "user_defined",
            error: !!_setup.state.errors.password,
            readOnly: _setup.props.readOnly,
          },
          on: {
            generate: _setup.handleGeneratePassword,
            save: (newVal, isAutofill) =>
              _setup.save("password", newVal, isAutofill),
            delete: _setup.deletePassword,
          },
          nativeOn: {
            input: function ($event) {
              return _setup.handlePasswordInput.apply(null, arguments)
            },
            keypress: function ($event) {
              return _setup.handlePasswordTyping.apply(null, arguments)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "input-before",
              fn: function () {
                return [
                  _c(_setup.CloakGeneratePasswordFlyout, {
                    attrs: {
                      visible: _setup.state.showFlyout === "password",
                      showGenerateOptions: true,
                    },
                    on: {
                      generate: _setup.generatePassword,
                      close: function ($event) {
                        _setup.state.showFlyout = null
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm.$store.getters.isV2User
          ? _c(_setup.TOTPContainer, {
              attrs: {
                identity: _setup.props.cloak,
                loading: _setup.state.updatingCloak.totp,
                readOnly: _setup.props.readOnly,
                errors: _setup.state.errors.totp,
              },
              on: {
                "set-loading": function ($event) {
                  _setup.state.updatingCloak.totp = $event
                },
                "set-errors": function ($event) {
                  _setup.state.errors.totp = $event
                },
                refresh: function ($event) {
                  return _setup.emit("refresh", $event)
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }