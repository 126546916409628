<template>
  <label class="switch">
    <input
      type="checkbox"
      name="checkbox"
      :checked="value"
      @change="handleChange"
      autocomplete="off"
      data-lpignore="true"
      data-form-type="other"
      :disabled="disabled"
    />
    <span></span>
  </label>
</template>

<script>
export default {
  props: ["value", "disabled"],
  methods: {
    handleChange() {
      this.$emit("change", !this.value);
    },
  },
};
</script>

<!-- NOTE: may be able to deprecate, check back -->
