var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "settings-page" },
    [
      _c("SettingsNavigation"),
      _c(
        "div",
        { staticClass: "settings-page__children" },
        [_c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }