var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "onboarding-step",
      class: {
        "onboarding-step--clickable": _vm.isCompleted && _vm.clickable,
        "onboarding-step--completed": _vm.isCompleted,
        "onboarding-step--active": _vm.isActive,
        "onboarding-step--disabled": _vm.isCompleted && !_vm.clickable,
      },
    },
    [
      _c("div", {
        staticClass: "onboarding-step__image",
        style: { "background-image": "url(" + _vm.image + ")" },
      }),
      _c("div", { staticClass: "onboarding-step__content" }, [
        _vm.title
          ? _c(
              "h2",
              { staticClass: "onboarding-step__title onboarding-step__link" },
              [_vm._v(" " + _vm._s(_vm.title) + " ")]
            )
          : _vm._e(),
        _c("p", { staticClass: "onboarding-step__subtitle" }, [
          _vm._v(" " + _vm._s(_vm.subTitle) + " "),
          _c(
            "svg",
            {
              attrs: {
                width: "17",
                height: "15",
                viewBox: "0 0 17 15",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M16.8525 7.64551C16.8525 7.37012 16.75 7.12988 16.5449 6.9248L10.7529 1.15039C10.6475 1.04492 10.5361 0.96582 10.4189 0.913086C10.3018 0.860352 10.1787 0.833984 10.0498 0.833984C9.78027 0.833984 9.55469 0.921875 9.37305 1.09766C9.19727 1.27344 9.10938 1.49316 9.10938 1.75684C9.10938 1.89746 9.13281 2.02637 9.17969 2.14355C9.23242 2.25488 9.30273 2.35449 9.39062 2.44238L11.3594 4.4375L14.6377 7.43457L14.9453 6.86328L11.8955 6.67871H1.2168C0.923828 6.67871 0.686523 6.76953 0.504883 6.95117C0.329102 7.12695 0.241211 7.3584 0.241211 7.64551C0.241211 7.92676 0.329102 8.1582 0.504883 8.33984C0.686523 8.51562 0.923828 8.60352 1.2168 8.60352H11.8955L14.9453 8.42773L14.6377 7.86523L11.3594 10.8535L9.39062 12.8398C9.30273 12.9277 9.23242 13.0303 9.17969 13.1475C9.13281 13.2646 9.10938 13.3906 9.10938 13.5254C9.10938 13.7891 9.19727 14.0088 9.37305 14.1846C9.55469 14.3604 9.78027 14.4482 10.0498 14.4482C10.3076 14.4482 10.5391 14.3486 10.7441 14.1494L16.5449 8.36621C16.75 8.16113 16.8525 7.9209 16.8525 7.64551Z",
                  fill: "currentColor",
                },
              }),
            ]
          ),
        ]),
      ]),
      _c("span"),
      _vm.isCompleted
        ? _c("StepCheckComplete", {
            staticClass: "onboarding-step__complete-icon",
          })
        : _c("StepCheck", { staticClass: "onboarding-step__complete-icon" }),
      _vm.isCompleted
        ? _c("div", { staticClass: "onboarding-step__overlay-grayscale" })
        : _vm._e(),
      _vm.isCompleted
        ? _c("div", { staticClass: "onboarding-step__overlay" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }