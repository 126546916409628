var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "ui-progress",
        {
          "ui-progress--done": _vm.isDone,
        },
      ],
    },
    [
      _c("div", { staticClass: "ui-progress__header" }, [
        _c("div", { staticClass: "ui-progress__label" }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ]),
        _vm.counter || _vm.counterTotal
          ? _c("div", { staticClass: "ui-progress__counter" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.counter || 0) +
                  _vm._s(_vm.counterTotal ? ` of ${_vm.counterTotal}` : "") +
                  " "
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "ui-progress__bar", style: _vm.barStyle }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }