<template>
  <div class="custom-actions">
    <span> Request approved </span>
    <a class="link-style" @click="manage">Name Contact</a> |
    <a class="link-style" @click="undo">Undo</a>
  </div>
</template>
<script>
export default {
  name: "BlockedActions",
  methods: {
    manage() {
      window.dispatchEvent(new CustomEvent("contact:manage"));
    },
    undo() {
      window.dispatchEvent(new CustomEvent("contact:undo"));
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-actions {
  font-size: 13px;
  color: $color-primary-0;
  background-color: $color-primary-100;
  display: flex;
  gap: 4px;
  span {
    font-size: 14px;
    display: inline-block;
    margin-right: 10px;
  }
  a.link-style {
    cursor: pointer;
    background-color: transparent;
    color: $color-primary-0;
    text-decoration: underline;
    border-radius: 12px;
    border: 0 solid transparent;
    font-size: 13px;
    font-weight: normal;
    position: relative;
  }
}
</style>
