<script setup>
import CustomFieldFormWrapper from "@/components/cloakDetails/CustomFields/CustomFieldForm/CustomFieldFormWrapper.vue";
import BorderInputText from "@/components/BorderInputText.vue";
import CustomFieldFormInputIsSensitive from "@/components/cloakDetails/CustomFields/CustomFieldFormInputIsSensitive.vue";
import { computed, defineProps, defineEmits, onMounted, ref } from "vue";
import BorderInputMultiline from "@/components/BorderInputMultiline.vue";
import { getDefaultLabel } from "@/components/cloakDetails/CustomFields/CustomFieldForm/utils";

const props = defineProps({
  fieldType: {
    type: String,
    required: true,
  },
  value: {
    type: Object,
    required: true,
  },
  errors: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["input", "validate"]);

const label = ref(null);

onMounted(() => {
  label.value.$refs.input.focus();
});

const labelPlaceholder = computed(() => getDefaultLabel(props.fieldType));

const valuePlaceholder = computed(() => {
  const typeToPlaceholder = {
    text: `Type anything here`,
    url: `https://`,
    date: `YYYY-MM-DD`,
    bank: `Type anything here`,
    auth: `Type anything here`,
    identification: `Type anything here`,
  };

  return typeToPlaceholder[props.fieldType];
});

const onValueInput = (value) => {
  emit("input", { ...props.value, value });

  if (props.errors?.value?.length) {
    emit("validate", { value, field: "value" });
  }
};
</script>

<template>
  <CustomFieldFormWrapper
    class="custom-field-form-text"
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
  >
    <BorderInputText
      label="Label"
      :value="value.label"
      @input="$emit('input', { ...value, label: $event })"
      ref="label"
      :placeholder="labelPlaceholder"
    />
    <BorderInputMultiline
      v-if="fieldType === 'url'"
      label="Content"
      :value="value.value"
      :errors="errors?.value"
      @input="onValueInput"
      @change="$emit('validate', { value: $event, field: 'value' })"
      :placeholder="valuePlaceholder"
    />
    <BorderInputText
      v-else
      label="Content"
      :value="value.value"
      :errors="errors?.value"
      @input="onValueInput"
      @change="$emit('validate', { value: $event, field: 'value' })"
      :placeholder="valuePlaceholder"
    />
    <CustomFieldFormInputIsSensitive
      :value="value.isSecret"
      @input="$emit('input', { ...value, isSecret: $event })"
    />
  </CustomFieldFormWrapper>
</template>
