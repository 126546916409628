var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "fwd-section-wrapper" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c("CloakForwardToggleRow", {
        attrs: {
          "aria-id": `CloakForwardToggleRow.${_vm.field || ""}`,
          field: _vm.field,
          selected: _vm.selected,
          options: _vm.options,
          allowed: _vm.allowed,
          defaultValue: _vm.defaultValue,
          "unverified-entries": _vm.unverifiedEntries,
        },
        on: {
          select: (selection) => _vm.$emit("select", selection),
          deselect: function ($event) {
            return _vm.$emit("deselect")
          },
          sentToSettings: function ($event) {
            return _vm.$emit("sentToSettings")
          },
        },
      }),
      _vm.LOCKED_NUMBERS_ENABLED && _vm.locked
        ? _c("div", { staticClass: "fwd-toggle-wrapper__label" }, [
            _c("div", { staticClass: "fwd-toggle-wrapper__label-title" }, [
              _vm._v(" " + _vm._s(_vm.sectionTitle) + " "),
            ]),
            _c(
              "div",
              {
                staticClass: "fwd-toggle-wrapper__label-selection",
                class: {
                  "fwd-toggle-wrapper__label-selection--selected": _vm.selected,
                  "fwd-toggle-wrapper__label-selection--disabled": _vm.locked,
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.selectedFilterRuleDisplay))])]
            ),
          ])
        : _c(
            "UiMenu",
            {
              attrs: { width: "263px", placement: "bottom-start" },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return _vm._l(_vm.commFilters, function (filterKey) {
                      return _c("UiMenuButton", {
                        key: filterKey,
                        attrs: {
                          title: _vm.getFilterDisplay(filterKey),
                          active: filterKey === _vm.selectedFilterRuleKey,
                        },
                        on: { click: () => _vm.handleSelectSetting(filterKey) },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "icon",
                              fn: function () {
                                return [
                                  filterKey === _vm.selectedFilterRuleKey
                                    ? _c("CheckIcon")
                                    : _c("span"),
                                ]
                              },
                              proxy: true,
                            },
                            _vm.field === "email"
                              ? {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c("UiEmailOptionLabel", {
                                        attrs: {
                                          email:
                                            _vm.getFilterDisplay(filterKey),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getFilterDisplay(filterKey)
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                          ],
                          null,
                          true
                        ),
                      })
                    })
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "fwd-toggle-wrapper__label" }, [
                _c("div", { staticClass: "fwd-toggle-wrapper__label-title" }, [
                  _vm._v(" " + _vm._s(_vm.sectionTitle) + " "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "fwd-toggle-wrapper__label-selection",
                    class: {
                      "fwd-toggle-wrapper__label-selection--selected":
                        _vm.selected,
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.selectedFilterRuleDisplay))]),
                    _c("ChevronDown"),
                  ],
                  1
                ),
              ]),
            ]
          ),
      !_vm.LOCKED_NUMBERS_ENABLED
        ? _c("div", { staticClass: "comm-notice" }, [
            _vm.noticeDisplay.length
              ? _c("h3", [_vm._v(" " + _vm._s(_vm.noticeDisplay) + " ")])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }