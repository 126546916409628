<template>
  <div class="ui-menu-title" :title="title" @click.stop="() => {}">
    <slot>
      <span>{{ title }}</span>
    </slot>
  </div>
</template>

<script>
export default {
  name: "UiMenuTitle",
  props: {
    title: { type: String, default: null },
  },
};
</script>

<style lang="scss">
.ui-menu-title {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 0 10px;
  overflow: hidden;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
