<script setup>
import { onMounted, ref, onUnmounted, computed } from "vue";
import store from "@/store";
import { MastercardIcon } from "@/assets/icons";
import CardsService from "@/api/actions/cards-services";
import router from "@/routes/router";
import { useRoute } from "vue-router/composables";
import { useToast } from "@/hooks";
import api from "@/api/api";

const toast = useToast();
const route = useRoute();

const cards = computed(() => {
  return store.state.cards?.identityCards[0];
});
const loading = ref(true);

function getCardInformation() {
  CardsService.getIdentityCards(store.state.rightPanel.cloak.id).then(() => {
    setTimeout(() => {
      loading.value = false;
    }, 1000);
  });
}

const defaultFundingSource = computed(() => {
  return store.state.cards?.primaryFundingSource;
});

function getDefaultFundingSource() {
  CardsService.getPrimaryCard();
}

const cardPayload = computed(() => {
  return {
    type: "MULTI_USE",
    currency: "usd",
    funding_source: defaultFundingSource.value?.id,
    transaction_period_limit: 5000,
    transaction_period: "monthly",
    valid_for_months: 24,
    transaction_period_max_transactions: 100,
  };
});

function generateIdentityCard() {
  loading.value = true;
  api()
    .post(
      `/api/v1/cloaked/identity/${store.state.rightPanel.cloak.id}/card/mastercard/`,
      cardPayload.value
    )
    .then(() => {
      api()
        .get(
          `/api/v1/cloaked/identity/${store.state.rightPanel.cloak.id}/card/`
        )
        .then(({ data }) => {
          store.commit("identityCards", data.results);
          CardsService.getCardList();
        });

      setTimeout(() => {
        loading.value = false;
      }, 1000);
    })
    .catch((error) => {
      setTimeout(() => {
        toast.error(error.response.data);
        loading.value = false;
      }, 500);
    });
}

function lastCharacters(string, length) {
  return string.substring(string.length - length, string.length);
}

function openCard(id) {
  if (store.state.rightPanel.active && route.params.id === id) {
    store.dispatch("closeRightPanel");
  } else {
    router.push({
      path: `/wallet/card/${id}`,
    });
  }
}

onMounted(() => {
  loading.value = true;
  getCardInformation();
  getDefaultFundingSource();
});

onUnmounted(() => {
  loading.value = true;
});
</script>

<template>
  <div class="block">
    <div class="header">
      <h1>Your Cloaked card</h1>

      <button @click="openCard(cards.id)" v-if="cards && !loading">
        View details
      </button>
    </div>

    <div class="card">
      <svg
        class="cloaked-icon"
        width="46"
        height="36"
        viewBox="0 0 46 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        v-if="cards && !loading"
      >
        <path
          opacity="0.2"
          d="M11.3845 35.3999C11.3845 35.3999 -2.64235 30.0235 0.443564 24.3484C2.40733 20.7641 6.89592 24.6471 13.9094 28.5301L11.3845 35.3999Z"
          fill="white"
        />
        <path
          d="M28.5017 33.6073C39.1621 34.5033 43.8833 29.4613 45.0055 27.9679C45.0055 27.9679 38.6411 19.4908 34.8905 13.8043C32.3365 9.93202 29.0638 4.42638 27.1912 1.23057C26.5249 0.0934141 24.9346 0.140662 24.2549 1.26884C17.2944 12.823 4.47698 21.4843 1.60352 23.1531C3.87587 22.1674 8.0225 25.2439 13.6332 28.5295C13.6332 28.5295 17.8021 30.7969 20.6239 31.845C24.2429 33.1891 28.5017 33.6073 28.5017 33.6073Z"
          fill="#191E23"
        />
      </svg>

      <MastercardIcon class="mastercard-icon" v-if="cards && !loading" />

      <div class="loading" v-if="loading"></div>

      <div class="information" v-if="cards && !loading">
        <div class="card-number">
          <h1>•••• •••• •••• {{ lastCharacters(cards.pan, 4) }}</h1>
        </div>

        <div class="card-details">
          <span>••/••</span>
          <span>•••</span>
        </div>
      </div>

      <div class="no-cards" v-if="!cards && !loading">
        <button @click="generateIdentityCard()">Create new card</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.block {
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 0;
    margin-bottom: 8px;

    h1 {
      color: $color-primary-100;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
    }

    button {
      background-color: transparent;
      border: 0;
      color: $color-primary-100;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      padding-right: 24px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 7px;
        right: 10px;
        width: 6px;
        height: 6px;
        border: 1px solid $color-primary-100;
        border-top: 0;
        border-right: 0;
        transform: rotate(-135deg);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .card {
    width: 100%;
    height: 213px;
    border-radius: 20px;
    background: radial-gradient(
      157.8% 94.4% at 54.96% 48%,
      #656565 0%,
      #38393a 100%
    );
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    display: flex;
    padding: 20px;

    .cloaked-icon {
      position: absolute;
      top: 20px;
      left: 24px;
      width: 45px;
      height: auto;
    }

    .mastercard-icon {
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 52px;
      height: auto;
    }

    .card-number {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);

      h1 {
        display: inline-block;
        color: #fff;
        font-size: 16px;
        letter-spacing: 0.5px;
        font-style: normal;
        font-weight: 500;
      }
    }

    .card-details {
      position: absolute;
      bottom: 30px;
      left: 24px;

      span {
        display: inline-block;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-left: 45px;
        letter-spacing: 0.5px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .loading {
      width: 30px;
      height: 30px;
      border: 4px solid rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      border-top-color: #fff;
      animation: spin 1s ease-in-out infinite;
      -webkit-animation: spin 1s ease-in-out infinite;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;

      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    }

    .no-cards {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        color: #000;
        background: #fff;
        border: 0;
        padding: 8px 30px;
        border-radius: 30px;

        &:hover {
          cursor: pointer;
          background: darken(#fff, 20%);
        }
      }
    }
  }

  .buttons {
    margin-top: 8px;

    button {
      margin-top: 8px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      color: $color-primary-100;
      width: 100%;
      background: $color-primary-10;
      border: 0;
      padding: 8px;
      border-radius: 30px;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        cursor: pointer;
        background: $color-primary-20;
      }
    }
  }
}
</style>
