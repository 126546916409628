var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.AppModal,
    _vm._g(_vm._b({}, "AppModal", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        _setup.AppModalContent,
        { staticClass: "auto-change-start" },
        [
          _c(
            "span",
            { staticClass: "auto-change-start__icon" },
            [
              _c(_setup.AutoChangeIcon, {
                staticClass: "auto-change-start__icon-icon",
              }),
            ],
            1
          ),
          _c(_setup.AppModalTitle, [
            _vm._v(
              " AutoCloak " +
                _vm._s(_vm.identities) +
                " selected " +
                _vm._s(_vm.identities !== 1 ? "identities" : "identity") +
                " "
            ),
          ]),
          _c(_setup.AppModalParagraph, [
            _vm._v(" Cloaked will automatically... "),
            _c("ul", { staticClass: "auto-change-start__list" }, [
              _c("li", [
                _vm._v(
                  "launch a new tab in the background per selected identity"
                ),
              ]),
              _c("li", [
                _vm._v("log into the identity using the Cloaked extension"),
              ]),
              _c("li", [_vm._v("navigate to the identity’s account settings")]),
              _c("li", [
                _vm._v(
                  " generate a new email address, phone number, username and password "
                ),
              ]),
              _c("li", [_vm._v("update the information in Cloaked")]),
            ]),
          ]),
          _c(
            _setup.AppModalFooter,
            [
              _c(_setup.AutoChangeTermsOfUse),
              _c(
                _setup.Button,
                {
                  attrs: { type: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.$emit("input", false)
                      _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _c(
                _setup.Button,
                {
                  on: {
                    click: function ($event) {
                      _vm.$emit("input", false)
                      _vm.$emit("close")
                      _vm.$emit("auto-change")
                    },
                  },
                },
                [_vm._v("Start AutoCloak")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }