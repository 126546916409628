<template>
  <AppModal v-on="$listeners" v-bind="$attrs" class="onboarding-app">
    <AppModalContent>
      <AppModalClose v-on="$listeners" is-absolute />
      <AppModalImage
        :src="require('@/assets/images/onboarding/onboarding-app-large.jpg')"
        :alt="title"
      />
      <AppModalTitle>
        {{ title }}
      </AppModalTitle>
      <div class="onboarding-app__qr">
        <img
          src="@/assets/images/qr-download.png"
          alt="Cloaked App QR Code"
          class="onboarding-app__qr-image"
        />
        <div class="onboarding-app__qr-content">
          <h4 class="onboarding-app__qr-title">
            Scan this QR code with your phone camera
          </h4>
          <p class="onboarding-app__qr-text">
            Or go to
            <a
              href="https://download.cloaked.app"
              target="_blank"
              class="onboarding-app__qr-link"
              >download.cloaked.app</a
            >
            on your mobile browser
          </p>
        </div>
      </div>
    </AppModalContent>
  </AppModal>
</template>

<script>
import AppModal from "@/components/ui/AppModal";
import AppModalClose from "@/components/ui/AppModalClose";
import AppModalTitle from "@/components/ui/AppModalTitle";
import AppModalContent from "@/components/ui/AppModalContent";
import AppModalImage from "@/components/ui/AppModalImage";

export default {
  components: {
    AppModalImage,
    AppModalContent,
    AppModalTitle,
    AppModalClose,
    AppModal,
  },
  computed: {
    title() {
      return "Generate phone numbers, email addresses, and passwords on the go";
    },
  },
};
</script>

<style lang="scss">
.onboarding-app {
  &__qr {
    display: flex;
    margin: 32px;
    padding: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;

    &-content {
      margin: auto 16px;
    }

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: $color-primary-70;
      margin: 0;
    }

    &-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.2px;
      color: $color-primary-60;
      margin: 16px 0 0 0;
    }

    &-link {
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
