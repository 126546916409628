var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    _vm._g(
      _vm._b({ staticClass: "onboarding-app" }, "AppModal", _vm.$attrs, false),
      _vm.$listeners
    ),
    [
      _c(
        "AppModalContent",
        [
          _c(
            "AppModalClose",
            _vm._g({ attrs: { "is-absolute": "" } }, _vm.$listeners)
          ),
          _c("AppModalImage", {
            attrs: {
              src: require("@/assets/images/onboarding/onboarding-app-large.jpg"),
              alt: _vm.title,
            },
          }),
          _c("AppModalTitle", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
          _c("div", { staticClass: "onboarding-app__qr" }, [
            _c("img", {
              staticClass: "onboarding-app__qr-image",
              attrs: {
                src: require("@/assets/images/qr-download.png"),
                alt: "Cloaked App QR Code",
              },
            }),
            _c("div", { staticClass: "onboarding-app__qr-content" }, [
              _c("h4", { staticClass: "onboarding-app__qr-title" }, [
                _vm._v(" Scan this QR code with your phone camera "),
              ]),
              _c("p", { staticClass: "onboarding-app__qr-text" }, [
                _vm._v(" Or go to "),
                _c(
                  "a",
                  {
                    staticClass: "onboarding-app__qr-link",
                    attrs: {
                      href: "https://download.cloaked.app",
                      target: "_blank",
                    },
                  },
                  [_vm._v("download.cloaked.app")]
                ),
                _vm._v(" on your mobile browser "),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }