var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesPanel",
    { staticClass: "email-gen-container" },
    [
      _c("PreferencesTitle", [_vm._v("Email address style")]),
      _c(
        "div",
        { staticClass: "email-gen-container__radios" },
        [
          _c("PreferencesRadio", {
            attrs: {
              label: "Professional (i.e. username22@cloak.id)",
              value: _vm.email_type,
              "input-value": "professional",
              "group-name": "email",
              disabled: _vm.loading,
            },
            on: { update: _vm.handleUpdate },
          }),
          _c("PreferencesRadio", {
            attrs: {
              label: "Random (i.e. glass.frog.bugs@cloak.id)",
              value: _vm.email_type,
              "input-value": "random",
              "group-name": "email",
              disabled: _vm.loading,
            },
            on: { update: _vm.handleUpdate },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }