var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "activity-message-section" },
    [
      !_vm.sameAsPrevSender
        ? _c("ActivityConvoNameTimeDisplay", {
            attrs: {
              senderDisplay: _vm.senderDisplay,
              inbound: _vm.message.inbound,
              createdAt: _vm.message.created_at,
            },
            on: {
              openCloak: function ($event) {
                return _vm.$emit("openCloak")
              },
            },
          })
        : _vm._e(),
      _vm.bodyLines.length
        ? _c(
            "div",
            {
              staticClass: "activity-message-body",
              class: { inbound: _vm.message.inbound },
            },
            [
              _c("SafeUGC", {
                attrs: {
                  color: _vm.color,
                  content: _vm.safe_html(_vm.message.body),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.message.media, function (img, i) {
        return _c("div", { key: i }, [
          _vm.isImage(img)
            ? _c(
                "a",
                {
                  staticClass: "media-link",
                  attrs: { href: img, target: "_blank", download: "" },
                },
                [
                  _c("img", {
                    staticClass: "activity-message-media",
                    attrs: { src: img },
                  }),
                ]
              )
            : _c("div", { staticClass: "activity-message-pdf" }, [
                _c("embed", {
                  attrs: {
                    src: img,
                    width: "200",
                    height: "260",
                    type: "application/pdf",
                  },
                }),
                _c(
                  "a",
                  {
                    staticClass: "media-link",
                    attrs: { href: img, target: "_blank", download: "" },
                  },
                  [_c("Download")],
                  1
                ),
              ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }