import { createMessaging } from "@/scripts/messaging/messaging";

export const AutoChangeMessages = {
  AUTO_CHANGE_START: "AUTO_CHANGE_START",
  AUTO_CHANGE_STOP: "AUTO_CHANGE_STOP",
  AUTO_CHANGE_STATE_REQUEST: "AUTO_CHANGE_STATE_REQUEST",
  AUTO_CHANGE_STATE_UPDATED: "AUTO_CHANGE_STATE_UPDATED",
  AUTO_CHANGE_FINISHED: "AUTO_CHANGE_FINISHED",
  AUTO_CHANGE_REVIEW_ITEM: "AUTO_CHANGE_REVIEW_ITEM",
  AUTO_CHANGE_SUPPORT_REQUEST: "AUTO_CHANGE_SUPPORT_REQUEST",
  AUTO_CHANGE_SUPPORT_RESPONSE: "AUTO_CHANGE_SUPPORT_RESPONSE",
  AUTO_CHANGE_LOGGED_IN_REQUEST: "AUTO_CHANGE_LOGGED_IN_REQUEST",
  AUTO_CHANGE_LOGGED_IN_UPDATED: "AUTO_CHANGE_LOGGED_IN_UPDATED",
};

export const extensionMessaging = createMessaging({
  targetOrigin: window.location.origin,
  sourceId: "dashboard",
});
