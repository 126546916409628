<script setup>
import { defineProps, defineEmits, ref, computed, watch } from "vue";
import SecretToggle from "@/components/ui/SecretToggle.vue";
import AppTable from "@/components/ui/AppTable.vue";
import AutoChangePageIdentity from "@/components/auto-change/AutoChangePageIdentity.vue";
import InputToggleAll from "@/components/feature/import/ReviewToggleAll.vue";
import AutoChangePageTableCell from "@/components/auto-change/AutoChangePageTableCell.vue";
import { SupportedAutoChangeFieldTypes } from "@/components/auto-change/utils";

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  items: {
    type: Array,
    default: () => [],
  },
  verification: {
    type: Array,
    default: () => [],
  },
  updates: {
    type: Array,
    default: () => [],
  },
  support: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["input", "cell-click"]);

const hasHiddenPasswords = ref(true);

const isAutoChangeInProgress = computed(() =>
  props.items.some((item) => item.fields.some((field) => field?.status))
);

const onToggleIdentity = (identityId) => {
  const identityItem = props.items.find(
    (item) => item.identityId === identityId
  );

  const identityValueItem = props.value.find(
    (item) => item.identityId === identityId
  );

  if (
    !identityValueItem ||
    identityValueItem.fields.length <
      identityItem.fields.filter((field) => !!field.oldValue).length
  ) {
    onUpdateIdentity(
      identityId,
      identityItem.fields
        .filter((field) => !!field.oldValue)
        .map((field) => field.fieldType)
    );
  } else {
    onUpdateIdentity(identityId, []);
  }
};

const withUpdatedIdentity = (value, identityId, fieldTypes) => {
  const newValue = [
    ...value.filter((request) => request.identityId !== identityId),
  ];

  const identityItem = props.items.find(
    (item) => item.identityId === identityId
  );

  if (fieldTypes.length) {
    newValue.push({
      identityId,
      identityWebsite: identityItem.identityWebsite ?? null,
      identityNickname: identityItem.identityNickname ?? null,
      identityLogo: identityItem.identityLogo ?? null,
      identityColor: identityItem.identityColor ?? null,
      fields: identityItem.fields.filter(
        (field) => fieldTypes.includes(field.fieldType) && !!field.oldValue
      ),
    });
  }

  return newValue;
};

const onUpdateIdentity = (identityId, fieldTypes) => {
  emit("input", withUpdatedIdentity(props.value, identityId, fieldTypes));
};

const onToggleFieldType = (fieldType, identityIds) => {
  let newValue = [...props.value];

  props.items.forEach((item) => {
    let newFieldTypes =
      props.value
        .find((valueItem) => valueItem.identityId === item.identityId)
        ?.fields.map((field) => field.fieldType) ?? [];

    if (identityIds.includes(item.identityId)) {
      newFieldTypes = newFieldTypes.includes(fieldType)
        ? newFieldTypes
        : [...newFieldTypes, fieldType];
    } else {
      newFieldTypes = newFieldTypes.includes(fieldType)
        ? newFieldTypes.filter(
            (currentFieldType) => currentFieldType !== fieldType
          )
        : newFieldTypes;
    }

    newValue = withUpdatedIdentity(newValue, item.identityId, newFieldTypes);
  });

  emit("input", newValue);
};

const columnToFieldTypeMap = {
  "Email address": "email",
  Password: "password",
  Username: "username",
  "Phone number": "phone",
};

const table = ref(null);
watch(
  () => isAutoChangeInProgress.value,
  (newValue, oldValue) => {
    if (newValue && !oldValue && table.value) {
      table.value.$el.scrollTop = 0;
    }
  }
);
</script>

<template>
  <AppTable
    :columns="['Identity', 'Email address', 'Password', 'Username']"
    :rows="items"
    class="auto-change-table"
    ref="table"
  >
    <template #head-row="{ row, cellClass }">
      <label
        v-for="(cell, index) in row"
        :key="index"
        class="auto-change-table__head-cell"
        :class="{
          [cellClass]: true,
          'auto-change-table__head-cell--clickable': cell !== 'Identity',
          'auto-change-table__head-cell--disabled':
            isAutoChangeInProgress ||
            !items.filter((item) =>
              item.fields.some(
                (field) =>
                  field.fieldType === columnToFieldTypeMap[cell] &&
                  !!field.oldValue
              )
            ).length,
        }"
      >
        <InputToggleAll
          v-if="cell !== 'Identity'"
          :disabled="
            isAutoChangeInProgress ||
            !items.filter((item) =>
              item.fields.some(
                (field) =>
                  field.fieldType === columnToFieldTypeMap[cell] &&
                  !!field.oldValue
              )
            ).length
          "
          :values="
            items
              .filter((item) =>
                item.fields.some(
                  (field) =>
                    field.fieldType === columnToFieldTypeMap[cell] &&
                    !!field.oldValue
                )
              )
              .map((item) => item.identityId)
          "
          :value="
            value
              .filter((item) =>
                item.fields.some(
                  (field) =>
                    field.fieldType === columnToFieldTypeMap[cell] &&
                    !!field.oldValue
                )
              )
              .map((item) => item.identityId)
          "
          @input="onToggleFieldType(columnToFieldTypeMap[cell], $event)"
        />
        <span class="auto-change-table__head-cell-text">
          {{ cell }}
        </span>
        <SecretToggle
          v-model="hasHiddenPasswords"
          v-if="cell === 'Password'"
          class="auto-change-table__password-toggle"
        />
      </label>
    </template>
    <template
      #body-row="{
        row: {
          identityId,
          identityWebsite,
          identityNickname,
          identityLogo,
          identityColor,
          fields,
        },
        cellClass,
      }"
    >
      <AutoChangePageIdentity
        :website="identityWebsite"
        :nickname="identityNickname"
        :logo="identityLogo"
        :color="identityColor"
        @click="isAutoChangeInProgress || onToggleIdentity(identityId)"
        class="auto-change-table__identity"
        :class="{
          [cellClass]: true,
          'auto-change-table__identity--in-progress': isAutoChangeInProgress,
          'auto-change-table__identity--selected': value.find(
            (item) => item.identityId === identityId
          )?.fields.length,
        }"
      >
        <template #left>
          <InputToggleAll
            :id="identityId"
            class="auto-change-table__identity-checkbox"
            :disabled="isAutoChangeInProgress"
            :values="
              items
                .find((item) => item.identityId === identityId)
                ?.fields.map((field) => field.fieldType) ?? []
            "
            :value="
              value
                .find((item) => item.identityId === identityId)
                ?.fields.map((field) => field.fieldType) ?? []
            "
          />
        </template>
      </AutoChangePageIdentity>
      <AutoChangePageTableCell
        v-for="fieldType in SupportedAutoChangeFieldTypes"
        :key="fieldType"
        :id="fieldType"
        :isAutoChangeInProgress="isAutoChangeInProgress"
        :class="cellClass"
        :field="fields.find((field) => field.fieldType === fieldType)"
        :verification="
          verification
            .find((item) => item.identityId === identityId)
            ?.fields.filter((field) => field.fieldType === fieldType)
        "
        :update="
          updates
            .find((item) => item.identityId === identityId)
            ?.fields.find((field) => field.fieldType === fieldType)
        "
        :is-supported="
          !!support
            .find((item) => identityWebsite?.endsWith(item.websiteUrl))
            ?.fieldTypes?.includes(fieldType)
        "
        :hidesSecretValues="hasHiddenPasswords"
        :disabled="
          isAutoChangeInProgress ||
          !fields.find((field) => field.fieldType === fieldType)?.oldValue
        "
        :value="
          value
            ?.find((item) => item.identityId === identityId)
            ?.fields.map((field) => field.fieldType) ?? []
        "
        @input="
          (updatedFieldTypes) => onUpdateIdentity(identityId, updatedFieldTypes)
        "
        @click="
          $emit('cell-click', {
            identityId,
            fieldType,
          })
        "
      />
    </template>
  </AppTable>
</template>

<style lang="scss">
.auto-change-table {
  width: 100%;
  margin: 28px 0 0;
  border-radius: 8px;

  .app-table__head {
    box-shadow: none;
  }

  .app-table__head-row {
    height: 40px;
  }

  .app-table__head-cell,
  .app-table__body-cell {
    width: calc(100% / 4);
    padding: 0 0 0 12px;
    border-left: 1px solid $color-primary-5;
    height: 100%;
    display: flex;

    &:first-child {
      border-left: 0;
    }
  }

  .app-table__head-row,
  .app-table__body-row {
    gap: 0;

    &:last-child {
      border-bottom: 0;
    }
  }

  .app-table__body-cell {
    justify-content: flex-start;
    align-items: center;
    height: 64px;
    padding-right: 8px;
  }

  &__identity {
    cursor: pointer;

    &--in-progress {
      cursor: default;
    }

    $icon-to-checkbox-transition-duration: 0ms;

    .auto-change-identity__icon {
      transition: all $icon-to-checkbox-transition-duration ease-in;
    }

    @at-root .auto-change-table
        .app-table__body-row:hover:hover
        .auto-change-table__identity:not(
          .auto-change-table__identity--in-progress
        )
        .auto-change-identity__icon,
      .auto-change-table__identity--selected .auto-change-identity__icon {
      opacity: 0;
      transform: translate3d(-50%, -50%, 0) scale(0.6);
    }

    &-checkbox {
      position: absolute;
      left: 50%;
      top: 50%;
      transition: all $icon-to-checkbox-transition-duration ease-in;
      opacity: 0;
      transform: translate3d(-50%, -50%, 0) scale(1.6);

      @at-root .auto-change-table
          .app-table__body-row:hover
          .auto-change-table__identity:not(
            .auto-change-table__identity--in-progress
          )
          &,
        .auto-change-table__identity--selected & {
        opacity: 1;
        transform: translate3d(-50%, -50%, 0) scale(1);
      }
    }
  }

  &__head-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: $color-primary-60;

    &--clickable {
      cursor: pointer;

      &:hover {
        color: $color-primary-50;
      }
    }

    &--disabled {
      cursor: not-allowed;

      &:hover {
        color: $color-primary-60;
      }
    }

    &-text {
      @include line-clamp(1);
    }
  }

  &__password-toggle {
    margin: 0 8px 0 auto;

    .eye-lash__path,
    .eye__path {
      fill: $color-primary-60;
    }
  }
}
</style>
