var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    {
      key: `cloak-notes-${_vm.cloak.id}`,
      staticClass: "cloak-identifier-section",
    },
    [
      _c(
        "div",
        { staticClass: "cloak-identifier-section__group" },
        [
          _c(_setup.CloakInfoRow, {
            attrs: {
              field: "notes",
              placeholder: "Add secure notes",
              initialValue: _setup.notes,
              loading: _setup.state.loading,
              readOnly: _setup.props.readOnly,
              "enter-ctrl": true,
            },
            on: {
              save: (newVal) => _setup.save(newVal),
              delete: _setup.deleteNotes,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }