var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.SettingsChildPage,
    {
      staticClass: "email-generation",
      scopedSlots: _vm._u([
        {
          key: "aside",
          fn: function () {
            return [
              _c(_setup.SettingsTitle, [_vm._v("Email generation")]),
              _c(_setup.SettingsParagraph, [
                _vm._v(
                  "Choose your default email generation format. You can always override these default settings when generating a new Cloaked email."
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c(_setup.EmailGen)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }