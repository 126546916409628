export const CountryList = {
  US: "United States",
  AF: "Afghanistan",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas (the)",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia (Plurinational State of)",
  BQ: "Bonaire, Sint Eustatius and Saba",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory (the)",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Cabo Verde",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  KY: "Cayman Islands (the)",
  CF: "Central African Republic (the)",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands (the)",
  CO: "Colombia",
  KM: "Comoros (the)",
  CD: "Congo (the Democratic Republic of the)",
  CG: "Congo (the)",
  CK: "Cook Islands (the)",
  CR: "Costa Rica",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czechia",
  CI: "Côte d'Ivoire",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic (the)",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  SZ: "Eswatini",
  ET: "Ethiopia",
  FK: "Falkland Islands (the) [Malvinas]",
  FO: "Faroe Islands (the)",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories (the)",
  GA: "Gabon",
  GM: "Gambia (the)",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island and McDonald Islands",
  VA: "Holy See (the)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran (Islamic Republic of)",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Korea (the Democratic People's Republic of)",
  KR: "Korea (the Republic of)",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic (the)",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands (the)",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia (Federated States of)",
  MD: "Moldova (the Republic of)",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands (the)",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger (the)",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands (the)",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestine, State of",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines (the)",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  MK: "Republic of North Macedonia",
  RO: "Romania",
  RU: "Russian Federation (the)",
  RW: "Rwanda",
  RE: "Réunion",
  BL: "Saint Barthélemy",
  SH: "Saint Helena, Ascension and Tristan da Cunha",
  KN: "Saint Kitts and Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin (French part)",
  PM: "Saint Pierre and Miquelon",
  VC: "Saint Vincent and the Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten (Dutch part)",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia and the South Sandwich Islands",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan (the)",
  SR: "Suriname",
  SJ: "Svalbard and Jan Mayen",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania, United Republic of",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands (the)",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates (the)",
  GB: "United Kingdom of Great Britain and Northern Ireland (the)",
  UM: "United States Minor Outlying Islands (the)",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela (Bolivarian Republic of)",
  VN: "Viet Nam",
  VG: "Virgin Islands (British)",
  VI: "Virgin Islands (U.S.)",
  WF: "Wallis and Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  AX: "Åland Islands",
};

export const phoneCountries = ["us", "gb", "es", "fr", "it", "de", "in"];

export const phone_codes = [
  { name: "USA", code: "1" },
  { name: "UK", code: "44" },
  { name: "Algeria", code: "213" },
  { name: "Andorra", code: "376" },
  { name: "Angola", code: "244" },
  { name: "Anguilla", code: "1264" },
  { name: "Antigua & Barbuda", code: "1268" },
  { name: "Argentina", code: "54" },
  { name: "Armenia", code: "374" },
  { name: "Aruba", code: "297" },
  { name: "Australia", code: "61" },
  { name: "Austria", code: "43" },
  { name: "Azerbaijan", code: "994" },
  { name: "Bahamas", code: "1242" },
  { name: "Bahrain", code: "973" },
  { name: "Bangladesh", code: "880" },
  { name: "Barbados", code: "1246" },
  { name: "Belarus", code: "375" },
  { name: "Belgium", code: "32" },
  { name: "Belize", code: "501" },
  { name: "Benin", code: "229" },
  { name: "Bermuda", code: "1441" },
  { name: "Bhutan", code: "975" },
  { name: "Bolivia", code: "591" },
  { name: "Bosnia Herzegovina", code: "387" },
  { name: "Botswana", code: "267" },
  { name: "Brazil", code: "55" },
  { name: "Brunei", code: "673" },
  { name: "Bulgaria", code: "359" },
  { name: "Burkina Faso", code: "226" },
  { name: "Burundi", code: "257" },
  { name: "Cambodia", code: "855" },
  { name: "Cameroon", code: "237" },
  { name: "Canada", code: "1" },
  { name: "Cape Verde Islands", code: "238" },
  { name: "Cayman Islands", code: "1345" },
  { name: "Central African Republic", code: "236" },
  { name: "Chile", code: "56" },
  { name: "China", code: "86" },
  { name: "Colombia", code: "57" },
  { name: "Comoros", code: "269" },
  { name: "Congo", code: "242" },
  { name: "Cook Islands", code: "682" },
  { name: "Costa Rica", code: "506" },
  { name: "Croatia", code: "385" },
  { name: "Cuba", code: "53" },
  { name: "Cyprus North", code: "90392" },
  { name: "Cyprus South", code: "357" },
  { name: "Czech Republic", code: "42" },
  { name: "Denmark", code: "45" },
  { name: "Djibouti", code: "253" },
  { name: "Dominica", code: "1809" },
  { name: "Dominican Republic", code: "1809" },
  { name: "Ecuador", code: "593" },
  { name: "Egypt", code: "20" },
  { name: "El Salvador", code: "503" },
  { name: "Equatorial Guinea", code: "240" },
  { name: "Eritrea", code: "291" },
  { name: "Estonia", code: "372" },
  { name: "Ethiopia", code: "251" },
  { name: "Falkland Islands", code: "500" },
  { name: "Faroe Islands", code: "298" },
  { name: "Fiji", code: "679" },
  { name: "Finland", code: "358" },
  { name: "France", code: "33" },
  { name: "French Guiana", code: "594" },
  { name: "French Polynesia", code: "689" },
  { name: "Gabon", code: "241" },
  { name: "Gambia", code: "220" },
  { name: "Georgia", code: "7880" },
  { name: "Germany", code: "49" },
  { name: "Ghana", code: "233" },
  { name: "Gibraltar", code: "350" },
  { name: "Greece", code: "30" },
  { name: "Greenland", code: "299" },
  { name: "Grenada", code: "1473" },
  { name: "Guadeloupe", code: "590" },
  { name: "Guam", code: "671" },
  { name: "Guatemala", code: "502" },
  { name: "Guinea", code: "224" },
  { name: "Guinea - Bissau", code: "245" },
  { name: "Guyana", code: "592" },
  { name: "Haiti", code: "509" },
  { name: "Honduras", code: "504" },
  { name: "Hong Kong", code: "852" },
  { name: "Hungary", code: "36" },
  { name: "Iceland", code: "354" },
  { name: "India", code: "91" },
  { name: "Indonesia", code: "62" },
  { name: "Iran", code: "98" },
  { name: "Iraq", code: "964" },
  { name: "Ireland", code: "353" },
  { name: "Israel", code: "972" },
  { name: "Italy", code: "39" },
  { name: "Jamaica", code: "1876" },
  { name: "Japan", code: "81" },
  { name: "Jordan", code: "962" },
  { name: "Kazakhstan", code: "7" },
  { name: "Kenya", code: "254" },
  { name: "Kiribati", code: "686" },
  { name: "Korea North", code: "850" },
  { name: "Korea South", code: "82" },
  { name: "Kuwait", code: "965" },
  { name: "Kyrgyzstan", code: "996" },
  { name: "Laos", code: "856" },
  { name: "Latvia", code: "371" },
  { name: "Lebanon", code: "961" },
  { name: "Lesotho", code: "266" },
  { name: "Liberia", code: "231" },
  { name: "Libya", code: "218" },
  { name: "Liechtenstein", code: "417" },
  { name: "Lithuania", code: "370" },
  { name: "Luxembourg", code: "352" },
  { name: "Macao", code: "853" },
  { name: "Macedonia", code: "389" },
  { name: "Madagascar", code: "261" },
  { name: "Malawi", code: "265" },
  { name: "Malaysia", code: "60" },
  { name: "Maldives", code: "960" },
  { name: "Mali", code: "223" },
  { name: "Malta", code: "356" },
  { name: "Marshall Islands", code: "692" },
  { name: "Martinique", code: "596" },
  { name: "Mauritania", code: "222" },
  { name: "Mayotte", code: "269" },
  { name: "Mexico", code: "52" },
  { name: "Micronesia", code: "691" },
  { name: "Moldova", code: "373" },
  { name: "Monaco", code: "377" },
  { name: "Mongolia", code: "976" },
  { name: "Montserrat", code: "1664" },
  { name: "Morocco", code: "212" },
  { name: "Mozambique", code: "258" },
  { name: "Myanmar", code: "95" },
  { name: "Namibia", code: "264" },
  { name: "Nauru", code: "674" },
  { name: "Nepal", code: "977" },
  { name: "Netherlands", code: "31" },
  { name: "New Caledonia", code: "687" },
  { name: "New Zealand", code: "64" },
  { name: "Nicaragua", code: "505" },
  { name: "Niger", code: "227" },
  { name: "Nigeria", code: "234" },
  { name: "Niue", code: "683" },
  { name: "Norfolk Islands", code: "672" },
  { name: "Northern Marianas", code: "670" },
  { name: "Norway", code: "47" },
  { name: "Oman", code: "968" },
  { name: "Palau", code: "680" },
  { name: "Panama", code: "507" },
  { name: "Papua New Guinea", code: "675" },
  { name: "Paraguay", code: "595" },
  { name: "Peru", code: "51" },
  { name: "Philippines", code: "63" },
  { name: "Poland", code: "48" },
  { name: "Portugal", code: "351" },
  { name: "Puerto Rico", code: "1787" },
  { name: "Qatar", code: "974" },
  { name: "Reunion", code: "262" },
  { name: "Romania", code: "40" },
  { name: "Russia", code: "7" },
  { name: "Rwanda", code: "250" },
  { name: "San Marino", code: "378" },
  { name: "Sao Tome & Principe", code: "239" },
  { name: "Saudi Arabia", code: "966" },
  { name: "Senegal", code: "221" },
  { name: "Serbia", code: "381" },
  { name: "Seychelles", code: "248" },
  { name: "Sierra Leone", code: "232" },
  { name: "Singapore", code: "65" },
  { name: "Slovak Republic", code: "421" },
  { name: "Slovenia", code: "386" },
  { name: "Solomon Islands", code: "677" },
  { name: "Somalia", code: "252" },
  { name: "South Africa", code: "27" },
  { name: "Spain", code: "34" },
  { name: "Sri Lanka", code: "94" },
  { name: "St. Helena", code: "290" },
  { name: "St. Kitts", code: "1869" },
  { name: "St. Lucia", code: "1758" },
  { name: "Sudan", code: "249" },
  { name: "Suriname", code: "597" },
  { name: "Swaziland", code: "268" },
  { name: "Sweden", code: "46" },
  { name: "Switzerland", code: "41" },
  { name: "Syria", code: "963" },
  { name: "Taiwan", code: "886" },
  { name: "Tajikstan", code: "7" },
  { name: "Thailand", code: "66" },
  { name: "Togo", code: "228" },
  { name: "Tonga", code: "676" },
  { name: "Trinidad & Tobago", code: "1868" },
  { name: "Tunisia", code: "216" },
  { name: "Turkey", code: "90" },
  { name: "Turkmenistan", code: "993" },
  { name: "Turks & Caicos Islands", code: "1649" },
  { name: "Tuvalu", code: "688" },
  { name: "Uganda", code: "256" },
  { name: "Ukraine", code: "380" },
  { name: "United Arab Emirates", code: "971" },
  { name: "Uruguay", code: "598" },
  { name: "Uzbekistan", code: "7" },
  { name: "Vanuatu", code: "678" },
  { name: "Vatican City", code: "379" },
  { name: "Venezuela", code: "58" },
  { name: "Vietnam", code: "84" },
  { name: "Virgin Islands - British", code: "1284" },
  { name: "Virgin Islands - US", code: "1340" },
  { name: "Wallis & Futuna", code: "681" },
  { name: "Yemen North", code: "969" },
  { name: "Yemen Sount", code: "967" },
  { name: "Zambia", code: "260" },
  { name: "Zimbabwe", code: "263" },
];

export const countries = () => {
  return Object.keys(CountryList).map((key) => ({
    name: CountryList[key],
    code: key,
  }));
};
