<template>
  <PreferencesPanel class="forwarding-pref">
    <PreferencesTitle>{{ header }}</PreferencesTitle>

    <div class="sub-feat-list">
      <h2 v-if="isRestrictedPlan">You will continue to have access to</h2>
      <div
        class="sub-feat-row"
        v-for="(feats, idx) in features"
        :key="`sub-feats-${idx}`"
      >
        <CheckActive />
        <span>{{ feats }}</span>
        <sup v-if="feats.toLowerCase().includes('phone numbers')">1</sup>
        <sup v-else-if="feats.toLowerCase().includes('auto-change')">2</sup>
      </div>
    </div>

    <div class="sub-feat-list">
      <h2 v-if="isRestrictedPlan">You are no longer getting</h2>
      <div
        class="sub-feat-row"
        v-for="(feats, idx) in expiredFeatures"
        :key="`sub-feats-${idx}`"
      >
        <CheckCancelled class="cancelled" />
        <span>{{ feats }}</span>
        <sup v-if="feats.toLowerCase().includes('phone numbers')">1</sup>
        <sup v-else-if="feats.toLowerCase().includes('auto-change')">2</sup>
      </div>
    </div>

    <div class="footnotes-wrapper">
      <div v-if="showPhoneFootnote" class="footnotes-row">
        <div>1.</div>
        <div>
          New phone number availability is subject to our bad actor mitigation
          system. Our phone numbers use a combination of daily creation
          behavior, quantity of messages/calls created, and various rules in
          order to prevent abuse on cloaked phone numbers.
        </div>
      </div>
      <div v-if="showAutoChangeFootnote" class="footnotes-row">
        <div>2.</div>
        <div>
          AutoCloak is one of the many upcoming features that will be included
          in your subscription plan.
        </div>
      </div>
    </div>

    <a v-if="stripeEmail" class="manage-link" :href="href" target="_blank">
      <span>{{ linkText }}</span>
      <LinkOutIcon />
    </a>
  </PreferencesPanel>
</template>

<script>
import PreferencesTitle from "./PreferencesTitle";
import PreferencesPanel from "./PreferencesPanel";
import { CheckActive, LinkOutIcon, CheckCancelled } from "@/assets/icons";
import { SubscriptionService } from "@/api";
import {
  STRIPE_MANAGE_URL,
  STRIPE_RESTRICTED_PLAN_NAME,
} from "@/scripts/constants";

export default {
  name: "Subscription",
  components: {
    PreferencesTitle,
    PreferencesPanel,
    CheckActive,
    LinkOutIcon,
    CheckCancelled,
  },
  data() {
    return {
      footnotes: [],
    };
  },
  beforeMount() {
    // NOTE: purposefully querying backend every time in case user makes to stripe
    SubscriptionService.getStripeInfo();
  },
  computed: {
    linkText() {
      return this.isRestrictedPlan
        ? "Upgrade subscription"
        : "Manage subscription";
    },
    header() {
      if (this.isRestrictedPlan) {
        return "You are now on a limited plan";
      }
      return "Your plan includes";
    },
    isRestrictedPlan() {
      const sub = this.$store.getters["settings/getSubscription"];
      return (
        sub?.service_plan?.plan_name?.toLowerCase() ===
        STRIPE_RESTRICTED_PLAN_NAME.toLowerCase()
      );
    },
    showPhoneFootnote() {
      const expiredFeatures = this.expiredFeatures ? this.expiredFeatures : [];
      const allFeats = [...this.features, ...expiredFeatures];
      const hasFeat = allFeats.filter((feat) =>
        feat.toLowerCase().includes("phone numbers")
      );
      return !!hasFeat.length;
    },
    showAutoChangeFootnote() {
      const expiredFeatures = this.expiredFeatures ? this.expiredFeatures : [];
      const allFeats = [...this.features, ...expiredFeatures];
      const hasFeat = allFeats.filter((feat) =>
        feat.toLowerCase().includes("auto-change")
      );
      return !!hasFeat.length;
    },
    features() {
      if (this.isRestrictedPlan) {
        return this.restrictedPlanFeatures;
      }
      return this.paidFeatures;
    },
    paidFeatures() {
      const allFeatures = [
        "Unlimited email addresses",
        "Unlimited usernames and passwords",
        "Your own encrypted database",
        "Personalized communication controls",
        "AutoCloak old accounts (coming soon)",
      ];

      allFeatures.unshift("Unlimited identities");
      allFeatures.unshift("Unlimited phone numbers");

      // NOTE: want to only show unlimited for now until all edge cases /language to user has been fully thought out

      // const sub = this.$store.getters["settings/getSubscription"];
      // if (sub?.service_plan?.max_identities != null) {
      //   allFeatures.unshift(
      //     `Up to ${sub?.service_plan?.max_identities} identities`
      //   );
      // } else {
      //  allFeatures.unshift("Unlimited identities");
      // }

      // if (sub?.service_plan?.max_phone_numbers != null) {
      //   allFeatures.unshift(
      //     `Up to ${sub?.service_plan?.max_phone_numbers} phone numbers`
      //   );
      // } else {
      //  allFeatures.unshift("Unlimited phone numbers");
      // }

      return allFeatures;
    },
    expiredFeatures() {
      if (this.isRestrictedPlan) {
        return this.restrictedPlanExpiredFeatures;
      }
      return null;
    },
    restrictedPlanFeatures() {
      return [
        "Previously created identities",
        "Sending and receiving calls, texts, and emails",
      ];
    },
    restrictedPlanExpiredFeatures() {
      return [
        "Editing of existing identities",
        "Unlimited phone numbers",
        "Unlimited email addresses",
        "Unlimited usernames and passwords",
      ];
    },
    href() {
      let stripeUrl = STRIPE_MANAGE_URL;

      if (this.stripeEmail) {
        stripeUrl += `?prefilled_email=${this.stripeEmail}`;
      }
      return stripeUrl;
    },
    stripeEmail() {
      const stripeUserData = this.$store.getters["settings/getStripe"];
      return stripeUserData?.email || null;
    },
  },
};
</script>

<style lang="scss">
.sub-feat-list {
  h2 {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: $color-primary-100;
    margin-top: 11px;
    margin-bottom: 7px;
  }
  &:not(:first-child) {
    h2 {
      margin-top: 24px;
    }
  }
  .sub-feat-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $color-primary-70;
    margin-top: 8px;
    svg {
      color: $color-success;
      height: 16px;
      width: 16px;
      &.cancelled {
        color: $color-alert;
      }
    }
    span {
      margin-left: 6px;
    }
    sup {
      vertical-align: top;
      font-size: 0.6em;
      margin-top: -5px;
      margin-left: 2px;
    }
  }
}
.manage-link {
  cursor: pointer;
  color: $color-primary-100;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  margin-top: 34px;
  display: inline-block;
  svg {
    margin-left: 10px;
    margin-bottom: -2px;
    color: $color-primary-100;
    display: inline-block;
    height: 12px;
    width: 12px;
  }
}

.footnotes-wrapper {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: $color-primary-70;
  margin-top: 10px;
  .footnotes-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: top;
    margin-top: 10px;
    margin-left: 3px;
    > div:last-child {
      margin-left: 5px;
    }
  }
}
</style>
