<template>
  <div
    class="activity-list-wrapper"
    :class="{
      'overflow-hidden': searchLoading || !loaded,
      'shorter-list': areRequestsVisible,
    }"
  >
    <button
      class="refresh"
      :class="{ visible: showRefresh }"
      @click="toggleRefresh"
    >
      New Messages Available
    </button>
    <ActivityActionBar
      :multiSelectEnabled="multiSelectEnabled"
      :multiSelected="multiSelected"
      :selectTooltipText="selectTooltipText"
      :numSelected="numSelected"
      :searchEnabled="searchEnabled"
      :searchText="searchText"
      :allSelectedAreRead="allSelectedAreRead"
      :singleSelected="singleSelected"
      @setSearchEnabled="setSearchEnabled"
      @setSearchText="setSearchText"
      @searchThreads="searchThreads"
      @setMultiSelect="(enabled) => $emit('setMultiSelect', enabled)"
      @toggleSelectAll="toggleSelectAll"
      @showDeleteModal="showDeleteModal"
      @markAsRead="markAsRead"
    />
    <div
      aria-id="ActivityLoadedState"
      v-if="groups.length && !searchLoading"
      class="activity-group-wrapper"
    >
      <div
        v-for="group in groups"
        :key="`thread-groups-${group.name}`"
        style="width: 100%"
        class="activity-group"
        :class="{
          fullHeight: searchedThreads !== null && searchedThreads.length === 0,
        }"
      >
        <h2 class="activity-list-day">{{ group.name }}</h2>
        <ActivityEmptySearch
          v-if="
            searchedThreads !== null &&
            searchedThreads.length === 0 &&
            !searchLoading
          "
          :searchText="searchText"
        />
        <ActivityPreviewItem
          v-for="(thread, idx) in group.items"
          :key="`activity-preview-today-${idx}`"
          :thread="thread"
          :threadType="thread.threadType"
          :selected="isThreadSelected(thread)"
          :multiSelectEnabled="multiSelectEnabled"
          :isCloakSpecificView="!!cloakId"
          :searchText="searchEnabled ? searchText : null"
          :allSelectedAreRead="allSelectedAreRead"
          @select="$emit('select', thread)"
          @showDeleteModal="showDeleteModal"
          @markAsRead="markAsRead"
          @setMultiSelect="(enabled) => $emit('setMultiSelect', enabled)"
          @toggleSelectAll="selectAll"
        />
      </div>
      <InfiniteTrigger
        key="activity"
        ref="infiniteLoader"
        @infinite="getMoreData"
        style="align-self: center"
      ></InfiniteTrigger>
    </div>
    <div
      v-else-if="loaded && !error && !searchLoading"
      aria-id="ActivityLoadingEmptyState"
      class="preview-empty-state"
      :style="{
        height: `calc(100vh - 65px - 70px - ${
          hasVisibleBanner ? '40px' : '0'
        })`,
      }"
    >
      <div v-if="!isRequests">
        <InboxIcon />
        <h1>There’s nothing here yet.</h1>
        <p>
          Calls, texts, and emails associated with this identity will appear
          here.
        </p>
      </div>
      <div v-else class="request-empty">
        <RequestIcon width="35" height="37" />
        <h1>No requests</h1>
        <p>
          Calls and texts will appear here that been marked as needing approval.
        </p>
      </div>
    </div>

    <div
      v-else-if="error && loaded && !searchLoading"
      aria-id="ActivityFailedEmptyState"
      class="preview-empty-state"
      :style="{
        height: `calc(100% - 65px - 70px - ${hasVisibleBanner ? '40px' : '0'})`,
      }"
    >
      <ErrorTriangleIcon />
      <h1>Failed to load inbox list.</h1>
      <p>
        Sorry, we couldn’t load this inbox list right now. Try refreshing your
        browser.
      </p>
    </div>

    <SkeletonLoader v-if="searchLoading || !loaded"></SkeletonLoader>
  </div>
</template>
<script>
import moment from "moment";
import api from "@/api/api";
import { mapGetters, mapActions } from "vuex";

let interval;
import axios from "axios";
import {
  ActivityPreviewItem,
  ActivityActionBar,
  ActivityEmptySearch,
  ActivityPreviewListSkeleton,
} from "@/components";
import InboxIcon from "@/assets/icons/inbox.svg";
import RequestIcon from "@/assets/icons/requests.svg";
import ErrorTriangleIcon from "@/assets/icons/error-triangle.svg";
import InfiniteTrigger from "@/components/global/InfiniteTrigger";
import colors from "@/assets/scss/recursive/_colors.scss";
import ActivityService from "@/api/actions/activity-service";
const source = axios.CancelToken.source();

export default {
  name: "ActivityPreviewList",
  props: [
    "cloakId",
    "multiSelected",
    "singleSelected",
    "multiSelectEnabled",
    "type",
    "isRequests",
    "areRequestsVisible",
  ],
  components: {
    ActivityPreviewItem,
    InboxIcon,
    RequestIcon,
    ErrorTriangleIcon,
    ActivityActionBar,
    ActivityEmptySearch,
    InfiniteTrigger,
    SkeletonLoader: ActivityPreviewListSkeleton,
  },
  data() {
    return {
      latestThreads: [],
      nextUrl: null,
      showRefresh: false,
      loaded: false,
      error: false,
      fetching: false,
      searchedThreads: null,
      lastTimestamp: moment().utc().format(),
      searchEnabled: false,
      searchText: "",
      searchCount: null,
      searchLoading: false,
      nextSearchUrl: null,
      primaryWhite10Lt: colors.primaryWhite10Lt,
    };
  },
  mounted() {
    this.nextUrl = this.defaultNext(this.cloakId);
    interval = setInterval(() => {
      if (this.$store.getters.auth_token) {
        let url = "/api/v1/cloaked/activity/summary/";
        if (this.lastTimestamp) {
          url = `${url}?gt=${this.lastTimestamp}`;
        }
        api()
          .get(url)
          .then(({ data }) => {
            if (data.results.length > 0) {
              this.lastTimestamp = moment().utc().format();
              this.showRefresh = true;
            }
          });
      }
    }, 30 * 1000);
  },

  beforeDestroy() {
    source.cancel();
    clearInterval(interval);
  },
  watch: {
    cloakId: {
      immediate: true,
      handler(val) {
        this.nextUrl = this.defaultNext(val);
        this.latestThreads = [];
        this.loaded = false;
        this.$nextTick(() => {
          this.getMessages();
        });
      },
    },
    type: {
      immediate: true,
      handler() {
        this.loaded = false;
        this.nextUrl = this.defaultNext();
        this.latestThreads = [];
        this.$nextTick(() => {
          this.getMessages();
        });
      },
    },
    numSelected(value) {
      if (!value) {
        this.$emit("setMultiSelect", false);
        this.$emit("select", this.latestThreads[0]);
      }
    },
    searchText(val) {
      if (val.length === 0) {
        this.searchLoading = false;
        this.searchedThreads = null;
      }
    },
    latestThreads(val) {
      if (val.length > 0 && window.location.href.includes("inbox/requests")) {
        window.Appcues?.track(
          "Visited inbox requests with an active request for the first time"
        );
        window.Appcues?.page();
      }
    },
  },
  methods: {
    ...mapActions(["openModal"]),
    toggleRefresh() {
      this.showRefresh = false;
      this.nextUrl = this.defaultNext(this.cloakId);
      this.$nextTick(() => {
        this.getMessages(this.$refs.infiniteLoader, true);
      });
    },
    removeItem(removeItem) {
      let found = null;
      this.combinedThreads = [...this.combinedThreads].filter((t, i) => {
        if (t.id !== removeItem.id) {
          return true;
        }
        found = this.latestThreads[i + 1];
        return false;
      });
      if (this.singleSelected && this.singleSelected.id == removeItem.id) {
        if (!found) {
          found = this.latestThreads[0];
        }
        this.$emit("select", found);
      }
    },
    updatedItem(updatedItem) {
      const index = this.combinedThreads.findIndex(
        (i) => i.id === updatedItem.id
      );
      if (index > -1) {
        const threads = [...this.combinedThreads];
        threads[index] = updatedItem;
        this.combinedThreads = threads;
      }
    },
    getMoreData($state) {
      if (this.searchEnabled) {
        return this.searchThreads($state, true);
      }
      return this.getMessages($state);
    },
    updateReadStatus(markedAs) {
      const readStatus = markedAs === "read";
      let threads = this.searchEnabled
        ? this.searchedThreads
        : this.latestThreads;
      threads = threads.map((thread) => {
        const isSelected = this.isThreadSelected(thread);
        if (isSelected) {
          thread.read = readStatus;
        }
        return thread;
      });

      if (this.searchEnabled) {
        this.searchedThreads = threads;
      } else {
        this.latestThreads = threads;
      }
      this.$emit("setMultiSelect", false);
      if (!this.multiSelectEnabled) {
        const singleSelected = { ...this.singleSelected, read: readStatus };
        this.$emit("select", singleSelected);
      }
    },
    removeDeleted() {
      let threads = this.searchEnabled
        ? this.searchedThreads
        : this.latestThreads;

      let currentIndex = -1;
      if (this.singleSelected) {
        currentIndex = threads.findIndex(
          (t) => t.id === this.singleSelected.id
        );
      }

      threads = threads.filter((thread) => {
        const isSelected = this.isThreadSelected(thread);
        return !isSelected;
      });
      const updatedIndex = threads.findIndex(
        (t) => t.id === this.singleSelected.id
      );

      if (this.searchEnabled) {
        this.searchedThreads = threads;
        /* also filter out deleted from latest if search is enabled */
        const latestFilteredThreads = this.latestThreads
          .filter((thread) => {
            const isSelected = this.isThreadSelected(thread);
            return !isSelected;
          })
          .filter((thread) => {
            return !threads.find((t) => t.id === thread.id);
          });
        /* set combinedThreads as well to trigger groups computed property/rerender */
        this.combinedThreads = [...threads, ...latestFilteredThreads];
      } else {
        this.latestThreads = threads;
        /* set combinedThreads as well to trigger groups computed property/rerender */
        this.combinedThreads = threads;
      }
      if (updatedIndex > -1) {
        this.$emit("select", threads[updatedIndex]);
      } else if (threads[currentIndex]) {
        this.$emit("select", threads[currentIndex]);
      } else {
        this.$emit("select", threads[0]);
      }
      this.$emit("setMultiSelect", false);
    },
    setSearchEnabled(enabled) {
      this.searchEnabled = enabled;
      if (!enabled) {
        this.searchText = "";
        this.nextSearchUrl = null;
        this.searchLoading = false;
        this.searchedThreads = null;
      }
    },
    setSearchText(searchText) {
      this.searchText = searchText;
      this.searchLoading = true;
      if (searchText.length > 0) {
        const search_text = encodeURIComponent(this.searchText);
        this.nextSearchUrl =
          ActivityService.nextActivityUrlWithSearch(search_text);
      }
    },
    isThreadSelected(thread) {
      if (thread.threadType === "email") {
        const isMultiSelected = this.multiSelected.threadIds.includes(
          thread.thread_id
        );
        const isSingleSelected =
          this.singleSelected &&
          this.singleSelected.thread_id === thread.thread_id;

        return this.multiSelectEnabled ? isMultiSelected : isSingleSelected;
      }

      const isMultiSelected = this.multiSelected.conversationIds.includes(
        thread.conversation_id
      );
      const isSingleSelected =
        this.singleSelected &&
        this.singleSelected.conversation_id === thread.conversation_id;
      return this.multiSelectEnabled ? isMultiSelected : isSingleSelected;
    },
    getMessages($state, reset) {
      if (!this.fetching && this.nextUrl) {
        this.fetching = true;
        api(source)
          .get(this.nextUrl)
          .then(({ data }) => {
            let activityConvertedToThreads = data.results.map((activity) =>
              this.convertActivityToThread(activity)
            );
            this.loaded = true;
            this.fetching = false;
            if (
              !this.nextUrl.includes("page=") ||
              /page=1\b/.test(this.nextUrl)
            ) {
              if (!this.singleSelected) {
                this.$emit("select", activityConvertedToThreads[0]);
              }
            } else {
              const latestThreads =
                this.combinedThreads && !reset ? this.combinedThreads : [];
              activityConvertedToThreads = [
                ...latestThreads,
                ...activityConvertedToThreads,
              ];
            }
            this.combinedThreads = activityConvertedToThreads;
            this.$nextTick(() => {
              if (!$state) {
                $state = this.$refs.infiniteLoader;
              }

              if ($state) {
                if (!data.next) {
                  $state.complete();
                } else {
                  $state.loaded();
                }
              }
            });

            this.nextUrl = data.next;
          });
      } else {
        if ($state) {
          $state.complete();
        }
      }
    },
    getContentFromActivity(activity) {
      return activity.email || activity.message || activity.call;
    },
    getActivityType(activity) {
      if (activity.email) {
        return "email";
      }
      if (activity.message) {
        return "text";
      }
      if (activity.call) {
        return "call";
      }
    },
    convertActivityToThread(activity) {
      return {
        ...this.getContentFromActivity(activity),
        threadType: this.getActivityType(activity),
        identity: activity.identity,
        activity_id: activity.id,
      };
    },
    searchThreads($state, paginate) {
      if (this.nextSearchUrl) {
        this.searchLoading = !paginate;
        api()
          .get(this.nextSearchUrl)
          .then(({ data }) => {
            this.saveSearchResults(data, paginate);
            this.nextSearchUrl = data.next;
            this.searchLoading = false;

            this.$nextTick(() => {
              if (!$state) {
                $state = this.$refs.infiniteLoader;
              }

              if ($state) {
                if (!data.next) {
                  $state.complete();
                } else {
                  $state.loaded();
                }
              }
            });
          })
          .catch(() => {
            this.searchLoading = false;
          });
      }
    },
    saveSearchResults(apiResponse, paginate) {
      if (apiResponse) {
        let newThreads = [];
        if (paginate) {
          newThreads = this.searchedThreads ? this.searchedThreads : [];
        }
        this.searchedThreads = [
          ...newThreads,
          ...apiResponse.results.map((activity) =>
            this.convertActivityToThread(activity)
          ),
        ];
        this.searchCount = apiResponse.count;
      } else {
        this.searchCount = null;
        this.searchedThreads = null;
      }
    },
    toggleSelectAll() {
      if (this.numSelected === this.combinedThreads.length) {
        this.$emit("setMultiSelect", false);
      } else {
        this.$emit("setMultiSelect", false);
        this.$nextTick(() => {
          this.selectAll();
        });
      }
    },
    selectAll() {
      this.$emit("enableMultiSelect", true);
      this.$nextTick(() => {
        const threadsNotSelected = this.combinedThreads.filter((thread) => {
          if (thread.thread_id) {
            return !this.multiSelected.threadIds.includes(thread.thread_id);
          }
          return !this.multiSelected.conversationIds.includes(
            thread.conversation_id
          );
        });
        threadsNotSelected.forEach((thread) => {
          this.$emit("select", thread);
        });
      });
    },
    defaultNext(cloakId) {
      const params = {
        page_size: 20,
        group_threads: true,
        page: 1,
      };
      if (cloakId) {
        params.identity = cloakId;
        params.contact_status = "approved&contact_status=unknown";
      } else {
        params.muted = false;
      }
      if (this.type) {
        switch (this.type) {
          case "requests":
            params.contact_status = "unknown";
            break;
          case "emails":
            params.has_email = true;
            params.has_message = false;
            params.has_call = false;
            break;
          case "texts":
            params.has_email = false;
            params.has_call = false;
            params.has_message = true;
            break;
          case "calls":
            params.has_email = false;
            params.has_message = false;
            params.has_call = true;
            break;
        }
      }
      const terms = [];
      Object.keys(params).map((k) => {
        terms.push(`${k}=${params[k]}`);
      });
      return `/api/v1/cloaked/activity/?${terms.join("&")}`;
    },
    showDeleteModal() {
      const amountSelected = this.multiSelectEnabled ? this.numSelected : 1;

      const pluralize = amountSelected > 1 ? true : false;
      this.openModal({
        header: `Delete ${amountSelected} message${pluralize ? "s" : ""}?`,
        subheader: `Any copies of ${
          pluralize ? "these messages" : "this message"
        } that have been forwarded to your personal email will be unaffected. This cannot be undone.`,
        button: {
          text: "Yes, Delete",
          onClick: this.bulkDelete,
          danger: true,
        },
      });
    },
    bulkDelete() {
      const url = "/api/v1/cloaked/activity/bulk-delete/";
      let payload = {};
      if (this.multiSelectEnabled) {
        payload = {
          conversation_ids: this.multiSelected.conversationIds,
          thread_ids: this.multiSelected.threadIds,
        };
      } else if (this.singleSelected.threadType === "email") {
        payload = {
          thread_ids: [this.singleSelected.thread_id],
        };
      } else {
        payload = {
          conversation_ids: [this.singleSelected.conversation_id],
        };
      }
      api()
        .post(url, payload)
        .then(() => {
          this.removeDeleted();
        });
    },
    markAsRead(readOrUnread) {
      const url = `/api/v1/cloaked/activity/${readOrUnread}/`;

      let payload = {};
      if (this.multiSelectEnabled) {
        payload = {
          conversation_ids: this.multiSelected.conversationIds,
          thread_ids: this.multiSelected.threadIds,
        };
      } else if (this.singleSelected.threadType === "email") {
        payload = {
          thread_ids: [this.singleSelected.thread_id],
        };
      } else {
        payload = {
          conversation_ids: [this.singleSelected.conversation_id],
        };
      }

      api()
        .post(url, payload)
        .then(() => {
          this.updateReadStatus(readOrUnread);
        });
    },
  },
  computed: {
    ...mapGetters(["hasVisibleBanner"]),
    combinedThreads: {
      get() {
        if (this.searchedThreads !== null) {
          return this.searchedThreads;
        }
        return this.latestThreads;
      },
      set(value) {
        if (this.searchText !== null) {
          this.searchedThreads = value;
        }
        this.latestThreads = value;
      },
    },
    allSelectedAreRead() {
      if (this.numSelected === 0) {
        return null;
      }
      const threads =
        this.searchedThreads !== null
          ? this.searchedThreads
          : this.latestThreads;
      let selectedAreRead = true;
      threads.forEach((thread) => {
        if (
          thread.thread_id &&
          this.multiSelected.threadIds.includes(thread.thread_id) &&
          !thread.read
        ) {
          selectedAreRead = false;
        } else if (
          thread.conversation_id &&
          this.multiSelected.conversationIds.includes(thread.conversation_id) &&
          !thread.read
        ) {
          selectedAreRead = false;
        }
      });
      return selectedAreRead;
    },
    numSelected() {
      return (
        this.multiSelected.conversationIds.length +
        this.multiSelected.threadIds.length
      );
    },
    selectTooltipText() {
      return this.numSelected === this.latestThreads.length
        ? "Deselect"
        : "Select All";
    },
    groups() {
      const today = moment().startOf("day");
      const yesterday = moment().subtract(24, "hours").startOf("day");
      const lastYear = moment().subtract(1, "year").startOf("day");
      const filteredThreadsGrouped = [];
      const threads = this.combinedThreads;

      // today
      const todaysThreads = threads.filter((thread) => {
        return moment(thread.created_at).isAfter(today);
      });
      if (todaysThreads && todaysThreads.length) {
        filteredThreadsGrouped.push({
          name: "Today",
          items: todaysThreads.map((thread) => ({
            ...thread,
            timeDisplay: moment(thread.created_at).format("hh:mm a"),
          })),
        });
      }
      // yeterday
      const yesterdaysThreads = threads.filter((thread) => {
        const when = moment(thread.created_at);
        return when.isAfter(yesterday) && when.isBefore(today);
      });
      if (yesterdaysThreads && yesterdaysThreads.length) {
        filteredThreadsGrouped.push({
          name: "Yesterday",
          items: yesterdaysThreads.map((thread) => ({
            ...thread,
            timeDisplay: moment(thread.created_at).format("hh:mm a"),
          })),
        });
      }

      // earlier
      const earlierThreads = threads.filter((thread) => {
        return moment(thread.created_at).isBefore(yesterday);
      });
      if (earlierThreads && earlierThreads.length) {
        filteredThreadsGrouped.push({
          name: "Earlier",
          items: earlierThreads.map((thread) => ({
            ...thread,
            timeDisplay: moment(thread.created_at).isBefore(lastYear)
              ? moment(thread.created_at).format("MMM YYYY")
              : moment(thread.created_at).format("MMM Do"),
          })),
        });
      }

      return filteredThreadsGrouped;
    },
  },
};
</script>
<style lang="scss" scoped>
.activity-list-wrapper {
  min-width: 400px;
  max-width: 400px;
  background-color: $color-surface;
  overflow-y: auto;
  border-right: 1px solid $color-primary-10;
  display: flex;
  flex-direction: column;
  /* firefox */
  height: calc(100vh - 65px - 40px);
  /* chrome */
  height: -webkit-fill-available;
  @include custom-scroll-bar();

  &.overflow-hidden {
    overflow: hidden;
  }

  .activity-group-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 24px;
  }
  .activity-list-day {
    color: $color-primary-70;
    font-weight: 500;
    font-size: 11px;
    line-height: 16.5px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }
  .preview-empty-state {
    display: flex;
    height: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .request-empty {
      svg {
        color: $color-primary-50;
      }
    }
    > div > h1 {
      color: $color-primary-100;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      margin-top: 12px;
    }
    > div > p {
      color: $color-primary-70;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      max-width: 232px;
      margin-top: 2px;
    }
  }
}

.fullHeight {
  min-height: 100%;
  max-height: 100%;
}
@keyframes shimmer {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.refresh {
  background-color: $color-primary-100;
  color: $color-primary-0;
  padding: 10px 20px;
  border-radius: 8px;
  position: fixed;
  margin-left: 80px;
  border: none;
  margin-top: -250px;
  transition: margin-top 600ms ease-out;
  z-index: 50;
}
.refresh.visible {
  margin-top: 14.5px;
  transition: margin-top 600ms ease-in;
  cursor: pointer;
}

.activity-group {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
