var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesPanel",
    {
      staticClass: "your-pref-top",
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("PreferencesHeader", { on: { "go-back": _vm.toggle_back } }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("EditAddress", {
        key: _vm.id,
        ref: "editAddressRef",
        attrs: {
          id: _vm.id,
          label: "address",
          placeholder: `add address`,
          current: _vm.values,
          name: "address",
        },
        on: { update: _vm.on_update_value, save: _vm.save },
      }),
      _c(
        "PreferencesFooter",
        [
          _c(
            "Button",
            {
              attrs: { disabled: _vm.loading, loading: _vm.loading },
              on: { click: _vm.save },
            },
            [_vm._v("Save changes")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }