<script setup>
import Popper from "@/components/Popper.vue";
</script>

<template>
  <Popper
    v-bind="$attrs"
    v-on="$listeners"
    :has-click-open="false"
    :has-click-close="false"
    :has-outside-click-close="false"
    :has-mouse-enter-open="true"
    :has-mouse-leave-close="true"
    transition="fade-top-200"
    class="app-tooltip"
  >
    <slot />
    <template #content>
      <div class="app-tooltip__content">
        <slot name="content" />
      </div>
    </template>
  </Popper>
</template>

<style lang="scss">
.app-tooltip {
  &__content {
    background: $color-primary-90;
    color: $color-primary-0;
    border-radius: 6px;
    box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
      13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
    padding: 10px;
    line-height: 16px;
    font-size: 11px;
    letter-spacing: -0.1px;
    font-weight: 500;
  }
}
</style>
