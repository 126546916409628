var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-tel-input", {
    ref: "tel",
    staticClass: "onboarding-input-phone",
    class: { settings: _vm.$router.history.current.name.includes("settings") },
    attrs: {
      autofocus: "",
      defaultCountry: _vm.country,
      onlyCountries: _vm.phoneCountries,
      validCharactersOnly: true,
    },
    on: { "country-changed": _vm.setCountry },
    model: {
      value: _vm.tel,
      callback: function ($$v) {
        _vm.tel = $$v
      },
      expression: "tel",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }