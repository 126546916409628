var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      staticClass: "ui-menu-button",
      class: {
        "ui-menu-button--active": _setup.props.active,
        "ui-menu-button--uppercase": _setup.props.uppercase,
        "ui-menu-button--disabled": _setup.props.disabled,
        "ui-menu-button--dark-font": _setup.props.darkFont,
        "ui-menu-button--center": _setup.props.center,
        "ui-menu-button--rtl": _setup.props.rtl,
        "ui-menu-button--has-subtitle": !!_setup.props.subtitle,
      },
      on: {
        click: _setup.handleClick,
        mouseover: function ($event) {
          return _vm.$emit("mouseover")
        },
        mouseleave: function ($event) {
          return _vm.$emit("mouseleave")
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _setup.slots.icon
            ? _c(
                "div",
                {
                  staticClass: "ui-menu-button__icon",
                  class: { danger: _setup.props.danger },
                },
                [_vm._t("icon")],
                2
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "ui-menu-button__label" },
            [
              _vm._t("title", function () {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "ui-menu-button__title",
                      class: { danger: _setup.props.danger },
                      attrs: { title: _setup.props.title },
                    },
                    [_vm._v(_vm._s(_setup.props.title))]
                  ),
                  _setup.props.subtitle
                    ? _c(
                        "span",
                        {
                          staticClass: "ui-menu-button__subtitle",
                          attrs: { title: _setup.props.subtitle },
                        },
                        [_vm._v(" " + _vm._s(_setup.props.subtitle) + " ")]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }