<script setup>
import { ref } from "vue";
import Button from "./WalletSettingsButton";
import IdentityService from "@/api/actions/identity-service";
import CardsServices from "@/api/actions/cards-services";
import { useRoute } from "vue-router/composables";
import { useToast } from "@/hooks";

const toast = useToast();

const route = useRoute();

const generating = ref(false);

function createNewIdentity() {
  generating.value = true;
  IdentityService.createIdentity({
    website_url: "cloaked.app",
    card: true,
  }).then(() => {
    CardsServices.getCardList()
      .then(() => {
        generating.value = false;
      })
      .catch(() => {
        generating.value = false;
        toast("Error while creating card");
      });
  });
}
</script>

<template>
  <Button
    v-if="!route.params.id"
    clickable
    :icon="generating ? 'loading-small' : 'plus'"
    text="Generate"
    title="New card"
    subtext="Default $50 per month"
    @click.native="createNewIdentity()"
    :class="{ generating: generating }"
  />
</template>

<style lang="scss" scoped>
.generating {
  &.button {
    background: $color-primary-10;
    color: $color-primary-100;
    border-color: $color-primary-10;
    pointer-events: none;
  }
}
</style>
