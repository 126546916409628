<template>
  <PreferencesParagraph class="preferences-check-paragraph">
    <CheckButton
      :value="value"
      @input="toggle"
      :square="true"
      :disabled="disabled"
    />

    <span class="preferences-check-paragraph__label" @click="toggle">
      <slot />
    </span>
  </PreferencesParagraph>
</template>

<script>
import CheckButton from "@/components/CheckButton.vue";
import PreferencesParagraph from "./PreferencesParagraph";

export default {
  name: "PreferencesCheckParagraph",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    CheckButton,
    PreferencesParagraph,
  },

  methods: {
    toggle() {
      if (this.disabled) {
        return;
      }

      this.$emit("input", !this.value);
    },
  },
};
</script>

<style lang="scss">
.preferences-check-paragraph {
  display: flex;
  gap: 8px;
  user-select: none;

  .check-button {
    display: block;
    flex-shrink: 0;
  }

  &__label {
    display: block;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
