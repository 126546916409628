<template>
  <PreferencesPanel class="change-password">
    <template v-slot:header>
      <PreferencesHeader @go-back="toggle_back" />
    </template>

    <PreferencesTitle>Change password</PreferencesTitle>
    <div class="change-password-inputs">
      <PreferencesInput
        v-model="currentPassword"
        label="Current Password"
        type="password"
        placeholder=""
        :error="invalidPassword"
        :disabled="loading"
        @save="change_password"
      />

      <PreferencesInput
        v-model="newPassword"
        label="New Password"
        type="password"
        placeholder=""
        :disabled="loading"
        :error="fieldErrors.newPassword"
        @blur.native="
          check_password({ name: 'newPassword', value: newPassword })
        "
        @save="change_password"
      />
      <PreferencesParagraph>
        <PasswordStrength
          :password="newPassword"
          @passwordStrengthCheck="setCheck"
        />
      </PreferencesParagraph>
      <PreferencesInput
        v-model="confirmPassword"
        label="Confirm New Password"
        type="password"
        placeholder=""
        :error="showPasswordsMatchError || fieldErrors.confirmPassword"
        :disabled="loading"
        @blur.native="
          check_password({ name: 'confirmPassword', value: confirmPassword })
        "
        @save="change_password"
      />
      <PreferencesParagraph>
        <div class="match_error">
          <div v-if="newPassword && confirmPasswordError">
            Passwords do not match
          </div>
        </div>
      </PreferencesParagraph>
    </div>
    <template v-slot:footer>
      <PreferencesFooter>
        <Button
          @click="change_password"
          :loading="loading"
          :disabled="loading || disabled"
          >Save</Button
        >
      </PreferencesFooter>
    </template>
  </PreferencesPanel>
</template>

<script>
import { uniq } from "lodash-es";

import api from "@/api/api";

import UserService from "@/api/actions/user-service";
import {
  PreferencesHeader,
  PreferencesFooter,
  PreferencesPanel,
  PreferencesParagraph,
  PreferencesTitle,
  PreferencesInput,
} from "@/routes/modals/preferences";

import { Button } from "@/components";
import { password } from "@/scripts/validation";
import { change_password, sanity_check } from "@/scripts/actions/encryption";
import PasswordStrength from "@/components/feature/password/PasswordStrength";
let timeout;
export default {
  name: "ChangePassword",
  components: {
    PasswordStrength,
    PreferencesHeader,
    PreferencesFooter,
    PreferencesPanel,
    Button,
    PreferencesParagraph,
    PreferencesTitle,
    PreferencesInput,
  },
  data() {
    return {
      saved: false,
      clicked: false,
      passwordStrenghCheck: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      confirmPasswordError: false,
      newPassword_error: "",
      showMatchError: false,
      loading: false,
      invalidPassword: false,
      invalidNewPassword: false,
      fieldErrors: {},
    };
  },
  watch: {
    newPassword() {
      this.newPassword_error = "";
      this.confirmPasswordError = false;
    },
    confirmPassword() {
      this.confirmPasswordError = false;
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        if (this.newPassword !== this.confirmPassword) {
          this.confirmPasswordError = true;
        }
      }, 500);
    },
  },
  computed: {
    disabled() {
      const all_are_set =
        this.currentPassword && this.newPassword && this.confirmPassword;
      const no_errors = !this.confirmPasswordError && !this.newPassword_error;
      const password_confirmed = this.newPassword === this.confirmPassword;
      const password_strong_enough = this.passwordStrenghCheck;
      if (
        all_are_set &&
        no_errors &&
        password_confirmed &&
        password_strong_enough
      ) {
        return false;
      }
      return true;
    },

    passwordsMatch() {
      return this.confirmPassword === this.newPassword;
    },

    showPasswordsMatchError() {
      if (this.confirmPassword && this.newPassword) {
        return !this.passwordsMatch;
      }

      return false;
    },
  },
  methods: {
    setCheck(value) {
      this.passwordStrenghCheck = value;
    },
    check(field, value) {
      this.$delete(this.fieldErrors, field);

      if (!value) {
        return;
      }

      const errorMessage =
        "Password must only include letters, numbers, and symbols from 8 to 20 characters";

      if (!password(value)) {
        this.$set(this.fieldErrors, field, errorMessage);
      }
    },

    // open_input(input) {
    //   if (this.$refs[input]) {
    //     this.$refs[input].toggle_edit();
    //   }
    // },
    check_password({ name, value }) {
      this.check(name, value);
    },

    // close(fields) {
    //   fields.map((field) => {
    //     this.$refs[`${field}Password`].toggle_update();
    //   });
    // },
    toggle_back() {
      this.$emit("toggleBack", {});
    },
    change_password() {
      const userIsV2User =
        this.$store.state.authentication.user.encryption_status === 3;
      if (userIsV2User) {
        this.change_password_v2();
      } else {
        this.change_password_v1();
      }
    },
    async change_password_v2() {
      const { encryptedPrivateKey, passwordAuthKey, currentPassword } =
        await change_password(
          this.currentPassword,
          this.newPassword,
          this.$store.state.authentication.encryption.account_salt,
          this.$store.state.authentication.encryption.private_key
        );
      const check = await sanity_check(
        this.$store.state.authentication.encryption.account_salt,
        this.newPassword,
        this.$store.state.authentication.encryption.private_key,
        encryptedPrivateKey
      );
      if (check) {
        const payload = {
          current_password: currentPassword,
          new_password: passwordAuthKey,
          encrypted_private_key: encryptedPrivateKey,
        };
        api()
          .post("/api/v2/auth/change-password/", payload)
          .then(() => {
            this.saved = true;
            this.clicked = false;
            this.currentPassword = "";
            this.newPassword = "";
            this.confirmPassword = "";
            this.toggle_back();
            this.$toast.success("Password saved.");
            this.loading = false;
          })
          .catch((err) => {
            const obj_array = err.response.data.errors
              ? err.response.data.errors
              : err.response.data;
            let errs = [];
            Object.keys(obj_array).map((k) => {
              if (Array.isArray(obj_array[k])) {
                errs = [...errs, ...obj_array[k]];
              } else {
                errs.push(obj_array[k]);
              }
            });
            this.$toast.error(uniq(errs).join(" "));
            this.loading = false;
          });
      } else {
        this.$toast.error("Could not change your password, please try again");
      }
    },
    change_password_v1() {
      const payload = {
        current_password: this.currentPassword,
        password1: this.newPassword,
        password2: this.confirmPassword,
      };

      this.loading = true;

      UserService.changeUserPassword(payload)
        .then(() => {
          this.saved = true;
          this.clicked = false;
          this.currentPassword = "";
          this.newPassword = "";
          this.confirmPassword = "";
          this.toggle_back();
          this.$toast.success("Password saved.");
          this.loading = false;
        })
        .catch((err) => {
          const obj_array = err.response.data.errors
            ? err.response.data.errors
            : err.response.data;
          let errs = [];
          Object.keys(obj_array).map((k) => {
            if (Array.isArray(obj_array[k])) {
              errs = [...errs, ...obj_array[k]];
            } else {
              errs.push(obj_array[k]);
            }
          });
          this.$toast.error(uniq(errs).join(" "));
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.change-password-inputs {
  margin-top: 24px;
}
.match_error {
  min-height: 25px;
  > div {
    color: red;
    font-size: 13px;
  }
}
</style>
