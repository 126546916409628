import api from "@/api/api";
import querystring from "querystring";
import router from "@/routes/router";
import { refresh_channel, refreshToken } from "@/scripts/actions/auth";
import { authDecrypt } from "@/scripts/actions/encryption";
import PersonalInfoService from "@/api/settings/personal-services";
import moment from "moment";

const defaultState = () => ({
  auth: null,
  encryption: null,
  user: null,
  username: null,
  encrypted_username: null,
  migration: null,
  extension: null,
  collections: [],
});
let timeout;
export default {
  namespaced: true,
  state: defaultState(),

  mutations: {
    setPayload: (state, { payload }) => {
      state.encryption = payload.encryption;
      state.collections = payload.user.collections;

      state.user = payload.user;
      if (payload.user.account_version === 2) {
        state.encrypted_username = payload.user.username_encrypted;
      }

      const extension_key = Object.keys(payload.auth).filter(
        (k) => k !== window.ENV.VUE_APP_CLIENT_ID
      );

      state.extension = payload.auth[extension_key[0]] || null;
    },
    setAuth(state, { oauth }) {
      state.auth = {
        access_token: oauth.access_token,
        refresh_token: oauth.refresh_token,
        expires_in: moment().add(oauth.expires_in, "seconds").format(),
      };
      state.migration = null;
    },
    setUser(state, user) {
      state.user = user;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setMigration(state, payload) {
      state.migration = payload;
    },
    setLogout: (state) => {
      Object.assign(state, defaultState());
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.auth && state.auth.access_token;
    },
    username(state) {
      if (state.username) {
        return state.username;
      }
      return (state.user && state.user.username) || "...";
    },
    user(state) {
      return state.user;
    },
    authToken(state) {
      return state.auth.access_token;
    },
    collection: (state) => (collection_name) => {
      return state.collections.find((f) => f.name === collection_name).url;
    },
  },
  actions: {
    bootFromMigration: ({ dispatch, commit, state }) => {
      commit("setPayload", { payload: state.migration.payload });
      dispatch("setAccessToken", { oauth: state.migration.oauth });
      router.push({ path: "/" });
    },
    getUsername({ state, commit }) {
      if (state.username && !state.username.match(/^boxSeal/)) {
        return state.username;
      }
      if (state.encrypted_username) {
        return authDecrypt(state.encrypted_username).then((decrypted) => {
          commit("setUsername", decrypted);
          return decrypted;
        });
      }
      return (state.user && state.user.username) || "...";
    },
    setAuthPayload: (
      { dispatch, commit, state },
      { payload, codeVerifier }
    ) => {
      if (state.user && state.user.id !== payload.user.id) {
        return dispatch("getAuthToken", {
          payload,
          codeVerifier,
          handler: (oauth) => {
            dispatch("logout", {}, { root: true });
            setTimeout(() => {
              commit("setPayload", { payload });
              dispatch("setAccessToken", { oauth: oauth.data });
              refresh_channel.postMessage("refresh");
              window.location.reload();
            }, 500);
          },
        });
      } else {
        return dispatch("getAuthToken", {
          payload,
          codeVerifier,
          handler: (oauth) => {
            commit("setPayload", { payload });
            dispatch("setAccessToken", { oauth: oauth.data });
          },
        });
      }
    },
    getAuthToken(ignore, { payload, codeVerifier, handler }) {
      const code = payload.auth[window.ENV.VUE_APP_CLIENT_ID].code;
      const headers = {
        headers: { "Content-type": "application/x-www-form-urlencoded" },
      };
      const data = {
        grant_type: "authorization_code",
        client_id: window.ENV.VUE_APP_CLIENT_ID,
        redirect_uri:
          payload.auth[window.ENV.VUE_APP_CLIENT_ID].redirect_uri.split("?")[0],
        code: code,
        code_verifier: codeVerifier,
      };
      const anonymous = true;
      return api(anonymous)
        .post("/o/token/", querystring.stringify(data), headers)
        .then(handler);
    },
    getUser({ dispatch, commit }) {
      return api()
        .get(`/api/v1/user/`)
        .then(({ data }) => {
          const user = data.results[0];
          commit("encryptionStatus", user.has_setup_encryption, { root: true });
          commit("encryptionFeatureFlag", user.flags.encryption, {
            root: true,
          });
          commit("setUser", user);
          setTimeout(() => {
            dispatch("getUsername");
          }, 500);
          return PersonalInfoService.getInfo();
        });
    },
    setTimeout(ignore, expires) {
      if (timeout) {
        clearTimeout(timeout);
      }
      const time = expires.diff(moment(), "seconds") - 30;
      const fireTime = time > 30 ? time : 1;
      timeout = setTimeout(refreshToken, fireTime * 1000);
    },
    setAccessToken({ commit, dispatch }, { oauth }) {
      const expires = moment().add(oauth.expires_in, "seconds");
      dispatch("setTimeout", expires);
      commit("setAuth", { oauth });
    },
    setRefreshTimeout({ state, dispatch }) {
      const now = moment().add(30, "seconds");
      const expires = moment(state.auth.expires_in);
      const test = state.auth && state.auth.expires_in && now.isAfter(expires);
      if (test) {
        refreshToken();
      } else {
        dispatch("setTimeout", expires);
      }
    },
    logout({ commit }) {
      commit("setLogout");
    },
  },
};
