<template>
  <PreferencesPanel class="your-pref-top">
    <template v-slot:header>
      <PreferencesHeader @go-back="toggle_back" />
    </template>

    <EditAddress
      :key="id"
      :id="id"
      ref="editAddressRef"
      label="address"
      :placeholder="`add address`"
      :current="values"
      name="address"
      @update="on_update_value"
      @save="save"
    />

    <PreferencesFooter>
      <Button :disabled="loading" :loading="loading" @click="save"
        >Save changes</Button
      >
    </PreferencesFooter>
  </PreferencesPanel>
</template>

<script>
import {
  PreferencesHeader,
  PreferencesFooter,
  PreferencesPanel,
} from "@/routes/modals/preferences";

import EditAddress from "./EditAddress";

import { Button } from "@/components";

import PersonalInfoService from "@/api/settings/personal-services";

// NOTE: address level1 = state, level2=city

export default {
  name: "Address",
  props: ["current", "id"],
  data() {
    return {
      autofill_street_address:
        this.current && this.current.autofill_street_address,
      autofill_unit: this.current && this.current.autofill_unit,
      autofill_country: this.current && this.current.autofill_country,
      autofill_address_level2:
        this.current && this.current.autofill_address_level2,
      autofill_address_level1:
        this.current && this.current.autofill_address_level1,
      autofill_postal_code: this.current && this.current.autofill_postal_code,
      loading: false,
    };
  },
  components: {
    PreferencesHeader,
    PreferencesFooter,
    EditAddress,
    PreferencesPanel,
    Button,
  },
  computed: {
    values() {
      return {
        autofill_street_address: this.autofill_street_address,
        autofill_unit: this.autofill_unit,
        autofill_country: this.autofill_country,
        autofill_address_level2: this.autofill_address_level2,
        autofill_address_level1: this.autofill_address_level1,
        autofill_postal_code: this.autofill_postal_code,
      };
    },
  },
  methods: {
    on_update_value({ name, value }) {
      this[name] = value;
      this.$emit("update", { name, value });
    },
    payload() {
      return {
        autofill_street_address: this.autofill_street_address,
        autofill_unit: this.autofill_unit,
        autofill_country: this.autofill_country,
        autofill_address_level2: this.autofill_address_level2,
        autofill_address_level1: this.autofill_address_level1,
        autofill_postal_code: this.autofill_postal_code,
      };
    },
    async save() {
      this.loading = true;

      if (this.id) {
        PersonalInfoService.updateInfo(this.id, this.values)
          .then(() => {
            this.toggle_back();
            this.$toast.success("Address saved.");
            this.loading = false;
          })
          .catch(() => {
            this.$toast.error("Error saving your address.");
            this.loading = false;
          });
      } else {
        PersonalInfoService.createInfo(this.values)
          .then(() => {
            this.toggle_back();
            this.$toast.success("Address saved.");
            this.loading = false;
          })
          .catch(() => {
            this.$toast.error("Error saving your address.");
            this.loading = false;
          });
      }
    },

    toggle_back() {
      this.$emit("toggleBack");
    },
  },
};
</script>
