var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "section",
        {
          staticClass: "rightPanel",
          class: { cards: _setup.showCardPanel },
          attrs: { "aria-id": "RightPanel" },
        },
        [
          _setup.showCloakCreate
            ? _c(_setup.CloakCreate, {
                on: { done: _setup.handleCloakCreateDone },
              })
            : _setup.showCloakDetails
            ? _c(_setup.CloakDetails, { key: _setup.cloak && _setup.cloak.id })
            : _vm._e(),
        ],
        1
      ),
      _c(_setup.CardPanel),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }