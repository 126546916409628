<template>
  <ModalApp v-on="$listeners" @click="$store.dispatch('onboarding/close')" />
</template>

<script>
import ModalApp from "@/components/feature/onboarding/ModalApp";
import { STEP_APP } from "@/store/modules/onboarding";

export default {
  components: {
    ModalApp,
  },
  beforeDestroy() {
    this.$store.dispatch("onboarding/completeStep", STEP_APP);
  },
};
</script>
