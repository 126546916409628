var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "h3",
    {
      class: [
        "preferences-title",
        {
          "preferences-title--big": _vm.big,
        },
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }