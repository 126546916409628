var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "auto-change-skeleton" },
    [
      _c("div", { staticClass: "auto-change-skeleton__select" }),
      _c(
        _setup.SkeletonTable,
        _vm._b(
          { staticClass: "auto-change-skeleton__table" },
          "SkeletonTable",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }