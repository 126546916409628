<script setup>
import { onMounted, ref, reactive, computed, nextTick, watch } from "vue";

import store from "@/store";
import { ModalTemplate } from "@/components";
import { UiTooltip } from "@/components";
import CloakedIcon from "@/assets/icons/cloaked-logo-orange-white.svg";
import ClapIcon from "@/assets/icons/clap.svg";
import CheckMark from "@/assets/icons/check-mark-rounded.svg";
import RedMark from "@/assets/icons/x-red.svg";
import Logo from "@/assets/icons/cloaked.svg";
import { SubscriptionService } from "@/api";
import { DownloadRecovery } from "@/components/modals";
import router from "@/routes/router";

const stripeRef = ref(null);
const stripePayment = ref(null);
const state = reactive({
  show: true,
  pending: false,
  sending: false,
  loaded: false,
  ready: false,
  intent: null,
  sent: false,
  error: null,
  currentPlan: null,
  plans: [],
});

let codeTimeout = null;

const promo = reactive({
  code: null,
  checked: false,
  valid: false,
  price: null,
  message: "",
});

watch(
  () => promo.code,
  (code) => {
    clearTimeout(codeTimeout);
    codeTimeout = setTimeout(() => {
      checkCode(code);
    }, 500);
  }
);

function checkCode(code) {
  if (code) {
    SubscriptionService.checkPromoCode(state.currentPlan.product_id, code)
      .then((valid) => {
        promo.checked = true;
        promo.valid = !!valid.terms;
        promo.message = valid.terms;
        SubscriptionService.getPaymentIntent(
          state.currentPlan.product_id,
          code
        ).then((intent) => {
          loadIntent(intent);
        });
      })
      .catch(() => {
        /* If first call fails, check the other plan ID to see if code it valid */
        const alternatePlan =
          state.currentPlan.product_id === yearlyPlan.value.product_id
            ? monthlyPlan
            : yearlyPlan;
        const alteratePlanName =
          state.currentPlan.product_id === yearlyPlan.value.product_id
            ? "monthly"
            : "annual";
        SubscriptionService.checkPromoCode(alternatePlan.value.product_id, code)
          .then((valid) => {
            promo.checked = true;
            promo.valid = !!valid.terms;
            if (promo.valid) {
              SubscriptionService.getPaymentIntent(
                alternatePlan.value.product_id,
                code
              ).then((intent) => {
                state.currentPlan = alternatePlan.value;
                loadIntent(intent);
              });
            }
            promo.message = `Code is only valid for ${alteratePlanName} subscription. ${valid.terms}`;
          })
          .catch(() => {
            promo.checked = true;
            promo.valid = false;
            promo.message = "Code is invalid or no longer active.";
            promo.price = null;
          });
      });
  } else {
    if (promo.checked && promo.valid && promo.price) {
      SubscriptionService.getPaymentIntent(state.currentPlan.product_id).then(
        (intent) => {
          loadIntent(intent);
        }
      );
    }
    promo.checked = false;
    promo.valid = null;
    promo.message = null;
    promo.price = null;
  }
}

function close() {
  setTimeout(() => {
    state.show = false;
    state.sending = true;
    state.pending = false;
    state.sent = true;
    state.loaded = false;
    state.ready = false;
    state.currentPlan = null;
    state.plans = [];
    state.error = null;
  }, 500);
  return store.dispatch("closeModal");
}

function subscribeUser() {
  const handler = stripePayment.value;
  if (typeof handler === "function") {
    return handler();
  }
  return Promise.reject();
}

const darkMode = computed(() => store.getters["colorScheme/isDarkMode"]);

const planTitleForTooltip = computed(() => {
  if (!promo?.message) {
    return "";
  }
  const tooltipMessage = promo.message.toLowerCase();
  return tooltipMessage;
});

let stripeImport;
let stripe;
function readyStripe(plan) {
  state.currentPlan = plan;
  SubscriptionService.getPaymentIntent(plan.product_id).then((intent) => {
    state.sending = false;
    stripeImport = require("@stripe/stripe-js");
    stripeImport.loadStripe(window.ENV.VUE_APP_STRIPE).then((stripeData) => {
      stripe = stripeData;
      loadIntent(intent);
    });
  });
}

const currentPrice = computed(() => {
  let price;
  if (promo.price) {
    price = promo.price;
  } else if (state.intent) {
    price = state.intent.original_price;
  }

  if (price) {
    return `$${(price / 100).toFixed(2)}`;
  }
  return null;
});

const planType = computed(() => {
  return state.currentPlan.product_id === yearlyPlan.value.product_id
    ? "yearly"
    : "monthly";
});

function loadIntent(intent) {
  state.error = null;
  state.intent = intent;
  if (intent.original_price !== intent.price) {
    promo.price = intent.price;
  } else {
    promo.price = null;
    //promo.code = null;
    // promo.valid = false;
    // promo.checked = false;
  }
  const appearance = {
    theme: darkMode.value ? "night" : "flat",
    variables: {
      colorPrimary: darkMode.value ? "white" : "black",
    },
    fields: {
      billingDetails: {
        name: "never",
        email: "never",
      },
    },
  };
  const clientSecret = intent.client_secret;
  const elements = stripe.elements({ appearance, clientSecret });

  const paymentElementOptions = {
    layout: "tabs",
    defaultCollapsed: true,
    paymentMethodOrder: ["card"],
  };

  const paymentElement = elements.create("payment", paymentElementOptions);
  paymentElement.mount(stripeRef.value);
  paymentElement.on("change", (event) => {
    if (event.complete) {
      state.ready = true;
    } else {
      state.ready = false;
    }
  });
  paymentElement.on("ready", () => {
    state.loaded = true;
  });
  stripePayment.value = async () => {
    state.pending = true;
    state.error = null;
    try {
      const result =
        intent.type === "payment"
          ? await stripe.confirmPayment({
              elements: elements,
              redirect: "if_required",
              confirmParams: {
                payment_method_data: {
                  billing_details: {
                    name: "dashboard user",
                    email: intent.email,
                  },
                },
              },
            })
          : await stripe.confirmSetup({
              elements: elements,
              redirect: "if_required",
              confirmParams: {
                payment_method_data: {
                  billing_details: {
                    name: "dashboard user",
                    email: intent.email,
                  },
                },
              },
            });

      if (result.error) {
        state.error = result.error.message;
        state.sent = false;
      } else {
        state.sending = true;
        setTimeout(
          () => {
            state.sent = true;
            SubscriptionService.getSubscription();
          },
          intent.type === "payment" ? 1500 : 3000
        );
      }
    } catch {
      state.sent = false;
    } finally {
      state.pending = false;
      state.sending = false;
    }
  };
}

const monthlyPlan = computed(() =>
  state.plans.find((plan) => plan.recurring_interval === "monthly")
);
const yearlyPlan = computed(() =>
  state.plans.find((plan) => plan.recurring_interval === "annually")
);

const isDiscount = (plan) => {
  return (
    promo.valid &&
    promo.price &&
    plan.product_id === state.currentPlan.product_id
  );
};

onMounted(() => {
  state.sending = true;
  SubscriptionService.getSubscriptionPlans().then((plans) => {
    state.plans = plans;
    nextTick(() => {
      readyStripe(monthlyPlan.value);
    });
  });
});

function selectPlan(plan) {
  state.sending = true;
  promo.code = null;
  promo.checked = false;
  promo.valid = false;
  promo.price = null;
  promo.message = "";
  readyStripe(plan);
}

function openSettings() {
  close();
  nextTick(() => {
    router.push({ name: "settings.recovery" });
  });
}
function confirmClose() {
  close().then(() => {
    nextTick(() => {
      store.dispatch("openModal", {
        customTemplate: {
          template: DownloadRecovery,
        },
      });
    });
  });
}
</script>
<template>
  <ModalTemplate :show="state.show" :width="1000" @close="close">
    <template #body>
      <div class="subscribe-body">
        <div class="subscribe-intro">
          <div class="video-container">
            <video autoplay loop muted>
              <source src="/videos/checkout-bg.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="subscribe-overlay">
            <div class="subscribe-overlay-content">
              <CloakedIcon />
              <strong> Turn a privacy miss into a Cloaked Moment. </strong>
            </div>
          </div>
        </div>
        <div class="subscribe-form">
          <div v-if="state.sending" class="subscribe-sending">
            <span>
              <Logo />
            </span>
          </div>
          <div v-else>
            <div v-if="state.sent" class="subscribe-sent">
              <div>
                <span class="clap">
                  <ClapIcon />
                </span>
                <h1>Your subscription is almost ready</h1>
                <p class="subscribe-thanks">
                  Before we finish your subscription, we need to make sure you
                  have your recovery key. It’s a unique string of numbers and
                  letters that allows you to regain access to your account
                  should you ever lose your password.
                </p>
                <p class="subscribe-cta">
                  Before continuing, make sure you have your key saved and
                  stored in a secure location.
                </p>
                <button @click="openSettings" class="subscribe-invite">
                  Download recovery key
                </button>
                <button class="subscribe-close" @click="confirmClose">
                  I already have my recovery key
                </button>
              </div>
            </div>
            <div v-else class="subscribe-payment-form">
              <h1 v-if="state.loaded">Choose your plan</h1>
              <div class="subscribe-actions" v-if="state.loaded">
                <button
                  @click="selectPlan(monthlyPlan)"
                  class="plans"
                  v-if="monthlyPlan"
                  :class="{
                    active:
                      state.currentPlan.product_id === monthlyPlan.product_id,
                  }"
                >
                  <div class="price-title">
                    <strong>Monthly</strong>
                  </div>
                  <div>
                    <div class="description">Billed every 30 days</div>
                    <div v-if="isDiscount(monthlyPlan)">
                      <div class="strike">$10.00</div>
                      ${{ (promo.price / 100).toFixed(2) }}
                    </div>
                    <div v-else>
                      ${{ (monthlyPlan.price / 100).toFixed(2) }}
                    </div>
                  </div>
                </button>
                <button
                  @click="selectPlan(yearlyPlan)"
                  class="plans"
                  v-if="yearlyPlan"
                  :class="{
                    active:
                      state.currentPlan.product_id === yearlyPlan.product_id,
                  }"
                >
                  <div class="price-title">
                    <strong>Annual</strong>
                    <sup v-if="!(promo.valid && promo.price)">Save 20%</sup>
                  </div>
                  <div>
                    <div class="description">Billed every 365 days</div>
                    <div v-if="isDiscount(yearlyPlan)">
                      <div class="strike">$96.00</div>
                      ${{ (promo.price / 100).toFixed(2) }}
                    </div>
                    <div v-else>${{ (yearlyPlan.price / 100).toFixed(2) }}</div>
                  </div>
                </button>
              </div>
              <div v-if="state.loaded" class="promo-section">
                <h1>Promo Code</h1>
                <div class="promo-content">
                  <div class="input-section">
                    <input
                      type="text"
                      placeholder="Promo code"
                      v-model="promo.code"
                    />
                    <div class="checkmark" v-if="promo.checked && promo.valid">
                      <CheckMark />
                    </div>
                    <div
                      class="checkmark"
                      v-if="promo.checked && promo.valid === false"
                    >
                      <RedMark />
                    </div>
                  </div>
                  <span>
                    <p v-if="!promo.checked">
                      If you have a promo code for a discounted Cloaked
                      subscription, enter it here.
                    </p>
                    <p v-if="promo.checked && promo.valid">
                      {{ promo.message }}
                    </p>
                    <p v-if="promo.checked && !promo.valid">
                      Code is invalid or no longer active.
                    </p>
                  </span>
                </div>
              </div>
              <div v-if="state.loaded" class="promo-section">
                <h1>Payment method</h1>
              </div>

              <div ref="stripeRef" class="element-ref"></div>
              <p>
                <span v-if="state.loaded">
                  No commitment. Cancel anytime from Settings > Manage
                  subscription at least a day before each renewal date. Plan
                  automatically renews until canceled.
                </span>
              </p>
            </div>
          </div>
          <div class="action-footer" v-if="!state.sending && !state.sent">
            <div v-if="state.error" class="error">{{ state.error }}</div>
            <div class="action-footer-buttons">
              <button @click="close" class="close-button">Close</button>
              <UiTooltip
                :title="`Subscribing to ${planType} plan ${planTitleForTooltip}`"
                position="top"
                align-x="center"
              >
                <button
                  class="activate"
                  @click="subscribeUser"
                  :class="{ pending: state.pending, ready: state.ready }"
                >
                  Activate subscription
                  <span v-if="currentPrice">{{ currentPrice }}</span>
                </button>
              </UiTooltip>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>
<style lang="scss" scoped>
.promo-section {
  h1 {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    margin: 10px 0;
  }
  .promo-content {
    display: flex;
    @media (max-width: 666px) {
      display: block;
    }
    align-items: center;
    .input-section {
      position: relative;
      @media (min-width: 999px) {
        width: 50% !important;
      }
      .checkmark {
        position: absolute;
        right: 8px;
        top: 12px;
      }
    }
    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.2px;
      @media (min-width: 999px) {
        width: 50% !important;
      }
    }
    gap: 15px;
  }
  input {
    padding: 8px 8px 8px 16px;
    height: 50px;
    width: 100%;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: none;
    background-color: $color-primary-5;
    color: $color-primary-100;
  }
}
.element-ref {
  color: $color-primary-100;
}
.strike {
  text-decoration: line-through;
}
.modal-container .content .modal-body {
  @media (max-width: 666px) {
    position: absolute;
    top: 0;
  }
}
.modal-container .content .modal-body {
  @media (max-width: 666px) {
    position: absolute;
    top: 0;
  }
}
.subscribe-body {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
  min-height: 100%; /*height or min-height both work */
  gap: 20px;

  .subscribe-intro,
  .subscribe-form {
    width: 50%;
    @media (max-width: 666px) {
      width: 100%;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .subscribe-intro {
    @media (max-width: 666px) {
      display: none;
    }
    width: 50%;
    border-radius: 28px 0 0 28px;
    margin: -8px 0 -8px -33px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    .video-container {
      border-radius: 28px 0 0 28px;
      overflow: hidden;
      height: 100%;
      display: flex;
      justify-content: center;
      video {
        border-radius: 28px 0 0 28px;
        height: 100%;
        width: auto;
        aspect-ratio: auto 530 / 620;
      }
    }
    .subscribe-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #00000030;
      z-index: 1;
      color: white;
      display: flex;
      align-items: flex-end;
      padding: 30px;
      .subscribe-overlay-content {
        text-align: center;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
          width: 100px;
          height: 100px;
        }
        strong {
          color: white;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }
    }
  }
  .subscribe-form {
    .action-footer {
      position: absolute;
      bottom: 0;
      background-color: $color-surface;
      color: $color-primary-100;
      padding: 10px;
      width: 110%;
      @media (max-width: 666px) {
        width: 110%;
      }
      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
        margin: 5px 10px;
        text-align: center;
      }
      .action-footer-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        button {
          border: none;
          &.activate {
            &.ready {
              cursor: pointer;
              color: white;
              background-color: $color-info;
            }
            &.pending {
              opacity: 0.5;
              pointer-events: none;
            }
          }
          &.close-button {
            cursor: pointer;
            background-color: transparent;
            color: $color-primary-100;
            border: 1px solid $color-primary-100;
          }
          padding: 13px 20px;
          font-size: 12px;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          border-radius: 999px;
        }
      }
    }
    .subscribe-sending {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      span {
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color-info;
        border-radius: 50%;
        box-shadow: 0 0 0;
        @include animation(1.3s ease infinite pulse);

        .loaded & {
          opacity: 0;
          visibility: hidden;
          @include transition-delay(0.2s);
        }

        svg {
          width: 30px;
          height: auto;
        }

        @keyframes pulse {
          0% {
            box-shadow: 0 0 0 0 rgba(#ff550c, 0.6);
          }
          100% {
            box-shadow: 0 0 0 24px rgba(#ff550c, 0);
          }
        }
      }
    }
    .subscribe-sent {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 30px;
      > div {
        margin: 0 auto;
        display: inline-flex;
        flex-direction: column;
        gap: 20px;
        color: $color-primary-100;
        .clap {
          background-color: #00c47d;
          border-radius: 500px;
          height: 72px;
          width: 72px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
        }
        h1 {
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px; /* 125% */
          letter-spacing: -0.5px;
          text-transform: capitalize;
        }
        .subscribe-thanks {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.2px;
        }
        .subscribe-cta {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.2px;
          a {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .subscribe-invite {
          border: none;
          width: 100%;
          border-radius: 50px;
          padding: 11px;
          background-color: $color-primary-100;
          color: $color-primary-0;
          text-align: center;
        }
        button {
          border: none;
          width: 100%;
          border-radius: 50px;
          padding: 11px;
          cursor: pointer;
          &.subscribe-close {
            background-color: $color-primary-0;
            border: 1px solid $color-primary-100;
            color: $color-primary-100;
          }
        }
      }
    }
    .subscribe-payment-form {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 32px 32px 100px 32px;
      overflow-y: auto;
      max-height: 650px;
      width: calc(100% + 50px);
      margin-left: -10px;
      @include custom-scroll-bar();

      h1 {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
      }
      .error {
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        color: $color-alert;
      }
      .subscribe-actions {
        display: flex;
        @media (max-width: 666px) {
          flex-direction: column;
          button.plans {
            width: 100%;
          }
        }
        gap: 10px;
      }
      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
      button.plans {
        border-radius: 10px;
        border: 2px solid $color-primary-30;
        &.active {
          border: 2px solid $color-primary-100;
        }
        background: $color-primary-0;
        color: $color-primary-100;
        padding: 16px 12px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 2px;
        .price-title {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.1px;
          margin-bottom: 10px;
        }
        > div {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          align-items: center;
          sup {
            background-color: $color-primary-100;
            color: $color-primary-0;
            padding: 2px 7px;
            font-size: 9px;
            border-radius: 10px;
          }
          strong {
            text-transform: capitalize;
          }
          .description {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-right: 10px;
          }
        }
      }
      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-primary-60;
        padding: 0 2px;
      }
    }
  }
}
</style>
