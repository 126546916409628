var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _setup.steps.currentStep === _setup.ANNOUNCEMENT
        ? _c(_setup.MigrationAnnouncement, {
            attrs: { username: _setup.username, isV1User: _setup.isV1User },
            on: { close: _setup.onDownloadAndContinue },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }