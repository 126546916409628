var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("nav", { staticClass: "settings-navigation" }, [
    _c(
      "header",
      { staticClass: "settings-navigation__header" },
      [
        _c(
          "router-link",
          { staticClass: "logo", attrs: { to: "/" } },
          [
            _c(_setup.CloakedLogoLight, { staticClass: "hidden-dark" }),
            _c(_setup.CloakedLogoDark, { staticClass: "hidden-light" }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "settings-navigation__back",
        attrs: { "aria-id": "NavigationBack" },
      },
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _c(
            "span",
            { staticClass: "settings-navigation__back-icon" },
            [_c(_setup.ChevronLeft600)],
            1
          ),
          _vm._v(" Settings "),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "settings-navigation__items" },
      _vm._l(_setup.items, function (item, index) {
        return _c(
          "router-link",
          {
            key: index,
            staticClass: "settings-navigation__item",
            attrs: { "aria-id": `${item?.to?.name || ""}`, to: item.to },
          },
          [_vm._v(" " + _vm._s(item.name) + " ")]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }