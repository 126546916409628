<template>
  <ModalTemplate
    class="add-verify-phone-modal"
    :show="true"
    @close="handleCancel"
  >
    <template #header>
      <div v-if="!confirming">
        <h1>{{ title || "Add a phone number" }}</h1>
      </div>

      <div v-else>
        <h1>Verify your phone number</h1>
      </div>
    </template>

    <template #body>
      <div v-if="!confirming">
        <OnboardingInputPhone
          :value="phone"
          @input="setPhone"
          placeholder="Phone number"
        />
      </div>
      <div v-else class="subheader">
        Enter the 6-digit code sent to
        <strong>{{ formattedPhoneNumber }}</strong
        ><br />
        <OnboardingInputCode
          :value="code"
          @input="code = $event"
          v-on="$listeners"
        />
      </div>
      <div class="input_error" v-if="phoneError || codeError">
        {{ phoneError || codeError }}
      </div>
    </template>

    <template #footer>
      <template v-if="!confirming">
        <Button type="secondary" @click="handleCancel"> Cancel </Button>

        <Button @click="savePhone" :disabled="!validPhone"> Continue </Button>
      </template>

      <template v-else>
        <Button type="secondary" @click="resend">
          <span v-if="loading" class="spin-loader" />
          Resend code
        </Button>

        <Button @click="verify" :disabled="!validCode"> Verify </Button>
      </template>
    </template>
  </ModalTemplate>
</template>
<script>
import store from "@/store";
import { phone } from "@/scripts/validation";
import { phone_package, phone_format } from "@/scripts/format";
import ModalTemplate from "@/components/ModalTemplate.vue";
import { Button } from "@/components";
import OnboardingInputPhone from "@/components/feature/onboarding/OnboardingInputPhone";
import OnboardingInputCode from "@/components/feature/onboarding/OnboardingInputCode";
import PhoneService from "@/api/actions/phone-service";

export default {
  name: "AddVerifyPhone",
  components: {
    ModalTemplate,
    Button,
    OnboardingInputPhone,
    OnboardingInputCode,
  },
  props: ["index", "alert", "setPrimary", "title"],
  data() {
    return {
      phoneDirty: false,
      confirming: false,
      token: null,
      code: "",
      country: "US",
      country_code: "1",
      verifying: null,
      verified: false,
      saving: null,
      phone: "",
      loading: true,
      savingPhone: false,
      phoneError: null,
      codeError: null,
      phoneVerified: false,
    };
  },
  watch: {
    phone() {
      this.phoneVerified = false;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs["ui-input"]) {
        const input = this.$refs["ui-input"].$el.querySelector("input");

        if (input) {
          input.focus();
        }
      }
    }, 100);
  },
  computed: {
    validPhone() {
      if (this.phone) {
        return phone(this.phone, this.country);
      }
      return false;
    },
    validCode() {
      if (this.code) {
        return this.code.match(/[0-9]{6}/i);
      }
      return false;
    },
    formattedPhoneNumber() {
      return phone_format(this.phone, this.country);
    },
  },
  methods: {
    handleEnterCode() {
      if (this.validCode) {
        return this.verify();
      }
    },
    handleEnterPhone() {
      if (this.validPhone) {
        return this.savePhone();
      }
    },
    setPhone({ value, country }) {
      if (phone(value, country)) {
        const formatted = phone_package(value, country);
        this.phone = formatted.phone_number || value;
        this.country = country;
      } else {
        this.phone = "";
        this.country = "us";
      }
    },
    handleCancel() {
      this.$emit("cancel");
      this.handleClose();
    },
    handleClose() {
      this.$store.dispatch("closeModal");
    },

    update_country(country) {
      this.country = country.iso2;
      this.country_code = this.dialCode;
    },
    async savePhone() {
      if (this.validPhone && !this.savingPhone) {
        this.phoneError = null;
        this.savingPhone = true;
        const phone_payload = phone_package(this.phone, this.country);

        const payload = {
          ...phone_payload,
          primary: !!this.setPrimary,
          collection_name: "phone",
          user: store.state.authentication.user.url,
          collection: store.getters["authentication/collection"]("phone"),
        };

        try {
          const { data: phone } = await PhoneService.addPhone(payload);
          this.phoneDirty = false;
          if (!phone.verified) {
            this.confirm_contact(phone);
          } else {
            await PhoneService.makePrimary(phone.id);
            this.$emit("phone-created", { ...phone, primary: true });
            this.handleClose();
            this.$toast.success("Phone number added.");
          }
        } catch (err) {
          this.savingPhone = false;
          const { response } = err;
          const { data } = response;
          let errors = data.errors || data;
          if (errors.phone_number) {
            errors = errors.phone_number;
          }
          if (Array.isArray(errors)) {
            this.phoneError = errors.join("\n");
          } else {
            this.phoneError = errors;
          }
        }
      }
    },
    confirm_contact(phone) {
      this.saving = phone;
      this.loading = true;
      this.confirming = true;
      PhoneService.sendVerificationCode(phone.id)
        .then((verify_response) => {
          this.$toast.success("Verification code sent.");
          if (!verify_response.data.verified) {
            this.loading = false;
            this.token = verify_response.data.session_token;
            setTimeout(() => {
              if (this.$refs.verify) {
                this.$refs.verify.focus();
              }
            }, 100);
          }
        })
        .catch(() => {
          this.$toast.error("Error resending verification code.");
        });
    },
    setSaving(status) {
      this.saving = status;
    },
    resend() {
      this.loading = true;
      this.codeError = null;
      this.code = "";

      PhoneService.sendVerificationCode(this.saving.id)
        .then((verify_response) => {
          this.$toast.success("Verification code sent.");
          if (!verify_response.data.verified) {
            this.token = verify_response.data.session_token;
          }
          this.loading = false;
          setTimeout(() => {
            if (this.$refs.verify) {
              this.$refs.verify.focus();
            }
          }, 100);
        })
        .catch(() => {
          this.$toast.error("Error resending verification code.");
        });
    },
    async verify() {
      if (this.code && !this.verifying && !this.verified) {
        this.codeError = null;
        this.verifying = true;

        try {
          await PhoneService.verifyVerificationCode(this.saving.id, {
            security_code: this.code,
            phone_number: this.saving.phone_number,
            session_token: this.token,
          });

          await PhoneService.makePrimary(this.saving.id);

          this.verifying = false;
          this.verified = true;
          this.handleClose();
          this.$emit("phone-verified", {
            ...this.saving,
            verified: true,
          });
          this.$toast.success("Verification successful.");
        } catch (e) {
          this.$toast.error("Error with verification.");
          this.verifying = false;
          this.verified = false;
          this.codeError = "Verification code is incorrect.";
        }
      }
    },

    handleCodeComplete(code) {
      this.code = code;
      this.verify();
    },
  },
};
</script>
<style lang="scss">
.add-verify-phone-modal {
  .content {
    max-width: 512px !important;
  }
  .modal-body {
    overflow: unset !important;
  }

  .ui-input {
    position: relative;
    background-color: $color-primary-10;
  }

  .vue-tel-input {
    box-shadow: none;
    border: none;
    width: 100%;

    .vti__input {
      background: none;
      color: $color-primary-100;
    }

    .vti__dropdown {
      position: unset;
      background: $color-primary-5;
    }

    .vti__dropdown-list {
      left: 0;
      top: 100%;
      transform: translateY(6px);
      width: 100%;
      z-index: 100;
      box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
        13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
      border-radius: 8px;
      border: 1px solid $color-primary-5;
      background: $color-background;

      .vti__dropdown-item {
        color: $color-primary-100 !important;
      }
    }

    .vti__dropdown-item {
      display: flex;
      align-items: center;
      gap: 4px;
      color: $color-primary-100;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.1px;

      strong {
        font-weight: 600;
      }
    }
  }

  .vue-tel-input:focus-within {
    box-shadow: none;
    border: none;
  }

  .ui-confirm-code {
    margin-top: 32px;
  }

  .error {
    font-size: 12px;
    color: red;
  }

  .input_error {
    font-size: 12px;
    color: $color-alert;
    border: 1px solid $color-alert;
    border-radius: 10px;
    padding: 10px;
    margin: 20px 0px 5px 0px;
    background-color: #f82f2810;
  }
}
.subheader {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: $color-primary-100;
}
</style>
