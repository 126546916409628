var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.SettingsChildPage,
    {
      staticClass: "privacy",
      scopedSlots: _vm._u([
        {
          key: "aside",
          fn: function () {
            return [
              _c(_setup.SettingsTitle, [_vm._v("Privacy")]),
              _c(_setup.SettingsParagraph, [
                _vm._v(
                  "Help Cloaked improve its products and services by allowing anonymized analytics about usage of the product."
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_c(_setup.Privacy)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }