var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "radio-button",
      class: {
        "radio-button--checked": _vm.checked,
      },
      on: { click: _vm.handleClick },
    },
    [
      _c("input", {
        attrs: { type: "radio", name: _vm.name },
        domProps: { value: _vm.value, checked: _vm.checked },
      }),
      _vm.checked ? _c("RadioActive") : _c("RadioInactive"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }