var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "block" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("h1", [_vm._v("Card Settings")]),
        _c(
          _setup.UiTooltip,
          {
            attrs: {
              title: " ",
              position: "top",
              "max-width": "192",
              "align-x": "center",
            },
          },
          [_c(_setup.MoreInfoQIcon)],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-item" },
        [
          _vm._m(0),
          _c(_setup.UiSwitchInput, {
            attrs: {
              value: _setup.lockCardSwitch,
              disabled: _setup.apiLoading,
            },
            on: { change: _setup.saveLockState },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("h1", [_vm._v("Lock card")]),
      _c("p", { staticClass: "description" }, [
        _vm._v("Prevent unwanted transactions"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }