var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.cardEnabled
    ? _c(
        _setup.MenuBlock,
        { attrs: { title: "Pay" } },
        [
          _c(_setup.MenuItem, {
            attrs: {
              link: "/wallet",
              name: _setup.kycValidated ? "Wallet" : "Activate Now",
              icon: "credit-card",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }