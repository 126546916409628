<script setup>
import { ref, computed, watch } from "vue";
import api from "@/api/api";
import store from "@/store";
import { useToast } from "@/hooks";

const toast = useToast();

const selectionDropdown = ref(false);
const selections = ref([
  {
    name: "Daily limit",
    value: "daily",
    active: false,
  },
  {
    name: "Weekly limit",
    value: "weekly",
    active: false,
  },
  {
    name: "Monthly limit",
    value: "monthly",
    active: false,
  },
  {
    name: "Yearly limit",
    value: "yearly",
    active: false,
  },
  {
    name: "One-time transaction",
    value: "forever",
    active: true,
  },
]);
const loading = ref(true);
const defaultFundingSource = ref("");
const dollarAmount = ref("");
const generating = ref(false);
const disabledButton = computed(() => {
  return !centAmount.value || generating.value;
});
const virtualCardCreationModal = computed(() => {
  return store.state.cards.generateCardModal;
});

const centAmount = computed(() => {
  return dollarAmount.value * 100;
});

const identity = computed(() => {
  return store.state.rightPanel.cloak;
});

function getDefaultFundingSource() {
  api()
    .get(`/api/v1/card/primary`)
    .then((data) => {
      if (data.status === 200) {
        defaultFundingSource.value = data.data;
        setTimeout(() => {
          loading.value = false;
        }, 1000);
      } else {
        defaultFundingSource.value = null;
        loading.value = false;
      }
    });
}

function toggleDropdown() {
  selectionDropdown.value = !selectionDropdown.value;
}
function selectOption(option) {
  selections.value.forEach((s) => {
    s.active = false;
  });
  selections.value.find((s) => s.value === option.value).active = true;
  selectionDropdown.value = false;
  setActiveSelection();
}

const activeSelection = ref(selections.value.find((s) => s.active === true));

function setActiveSelection() {
  activeSelection.value = selections.value.find((s) => s.active);
}

function revertDefaultSelection() {
  selections.value.forEach((s) => {
    s.active = false;
  });
  selections.value.find((s) => s.value === "forever").active = true;
  setActiveSelection();
}

const cardPayload = computed(() => {
  return {
    type: "MULTI_USE",
    currency: "usd",
    funding_source: defaultFundingSource.value.id,
    transaction_period_limit: centAmount.value,
    transaction_period: activeSelection.value.value,
    valid_for_months: 12,
    transaction_period_max_transactions:
      activeSelection.value.value === "forever" ? 2 : 100,
  };
});

function closeVirtualCardCreationModal() {
  store.dispatch("closeGenerateCardModal");

  setTimeout(() => {
    loading.value = true;
    revertDefaultSelection();
    defaultFundingSource.value = "";
    dollarAmount.value = "";
  }, 300);
}

function generateCard() {
  generating.value = true;
  api()
    .post(
      `/api/v1/cloaked/identity/${store.state.rightPanel.cloak.id}/card/mastercard/`,
      cardPayload.value
    )
    .then(() => {
      api()
        .get(
          `/api/v1/cloaked/identity/${store.state.rightPanel.cloak.id}/card/`
        )
        .then(({ data }) => {
          store.commit("identityCards", data.results);
        });
      setTimeout(() => {
        generating.value = false;
        closeVirtualCardCreationModal();
        toast.success("Card generated successfully");
      }, 500);
    })
    .catch((error) => {
      setTimeout(() => {
        toast.error(error.response.data);
        generating.value = false;
      }, 500);
    });
}

watch(virtualCardCreationModal, () => {
  if (virtualCardCreationModal.value === true) {
    getDefaultFundingSource();
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
});
</script>

<template>
  <section :class="{ active: virtualCardCreationModal }">
    <div class="content">
      <header>
        <div class="identity" v-if="identity">
          <div class="icon">
            <span
              :style="{ backgroundImage: `url(${identity.logo_url})` }"
            ></span>
          </div>

          <p>{{ identity.nickname }}</p>
        </div>

        <div class="information">
          <span>Spending limits <i></i></span>

          <div class="tooltip">
            <p>
              Spending limits are the maximum amount of money that can be spent
              with this card. You can change the spending limits at any time.
            </p>
          </div>
        </div>
      </header>

      <div class="main-content">
        <div class="input">
          <div class="currency">
            <span>$</span>
          </div>
          <input type="number" placeholder="0" v-model="dollarAmount" />

          <div class="choices">
            <div class="choice" @click="toggleDropdown()">
              <span>{{ activeSelection.name }}</span>
            </div>

            <div class="options" :class="{ active: selectionDropdown }">
              <div
                class="option"
                :class="{ selected: s.active }"
                v-for="s in selections"
                :key="s.value"
                @click="selectOption(s)"
              >
                <span>{{ s.name }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="source">
          <h1>Funding source</h1>

          <div class="card" :class="{ loading: loading }">
            <div class="icon">
              <span></span>
            </div>

            <div class="text">
              <div class="name">
                <span>{{ defaultFundingSource.card_brand }}</span>
              </div>

              <div class="number">
                <span>
                  <div class="dots">
                    <i></i>
                    <i></i>
                    <i></i>
                    <i></i>
                  </div>

                  {{ defaultFundingSource.pan_last_four }}
                </span>
              </div>
            </div>

            <div class="label">
              <span>Default</span>
            </div>
          </div>

          <p>
            Your default funding source is a credit card. This credit card will
            be charged for the entered amount when you press “Generate card”.
            <a href="#">Change funding source</a>.
          </p>
        </div>
      </div>

      <footer>
        <button @click="closeVirtualCardCreationModal()">Cancel</button>
        <button
          class="primary"
          @click="generateCard()"
          :class="{ disabled: disabledButton, generating: generating }"
        >
          Generate card

          <i></i>
        </button>
      </footer>
    </div>

    <div class="overlay" @click="closeVirtualCardCreationModal()"></div>
  </section>
</template>

<style lang="scss" scoped>
section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  @include transition(all 0.3s ease);

  &.active {
    opacity: 1;
    visibility: visible;

    .content {
      @include transform(translateY(0));
    }
  }

  .content {
    width: calc(100% - 24px);
    max-width: 512px;
    height: auto;
    background-color: $color-surface;
    position: relative;
    z-index: 2;
    padding: 32px;
    border-radius: 28px;
    @include transform(translateY(20px));
    @include transition(all 0.3s ease);

    header {
      margin-bottom: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .identity {
        display: flex;
        align-items: center;

        .icon {
          margin-right: 12px;

          span {
            display: inline-block;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: $color-primary-50;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
        }

        p {
          color: $color-primary-100;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.2px;
        }
      }

      .information {
        position: relative;

        span {
          color: $color-primary-100;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.2px;
          position: relative;
          padding-right: 24px;

          &:after {
            content: "i";
            position: absolute;
            top: 50%;
            right: 0;
            @include transform(translateY(-50%));
            color: $color-primary-100;
            font-size: 10px;
            width: 15px;
            height: 15px;
            border: 1px solid $color-primary-100;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
          }

          &:hover {
            cursor: help;
          }
        }

        &:hover {
          .tooltip {
            opacity: 1;
            visibility: visible;
            @include transform(translateY(0));
          }
        }

        .tooltip {
          position: absolute;
          top: 100%;
          right: 0;
          width: 260px;
          background-color: $color-primary-100;
          box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.25);
          border-radius: 12px;
          z-index: 4;
          padding: 16px;
          opacity: 0;
          visibility: hidden;
          @include transform(translateY(10px));
          @include transition(all 0.3s ease);
          pointer-events: none;

          &:after {
            content: "";
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $color-background;
            position: absolute;
            top: -10px;
            right: 10px;
          }

          p {
            color: $color-primary-0;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.2px;
          }
        }
      }
    }

    .main-content {
      .input {
        margin-bottom: 32px;
        position: relative;

        .currency {
          position: absolute;
          top: 50%;
          left: 20px;
          @include transform(translateY(-50%));

          span {
            color: $color-primary-100;
            font-size: 36px;
            font-weight: 400;
            letter-spacing: 0.2px;
            line-height: 36px;
          }
        }

        input {
          width: 100%;
          border: 1px solid $color-primary-100;
          border-radius: 72px;
          padding: 19px 200px 19px 55px;
          background-color: $color-surface;
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.2px;
          color: $color-primary-100;
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .choices {
          .choice {
            padding-right: 27px;
            color: $color-primary-100;
            position: absolute;
            top: 50%;
            right: 20px;
            @include transform(translateY(-50%));

            &:after {
              content: "";
              width: 8px;
              height: 8px;
              position: absolute;
              top: 6px;
              right: 4px;
              @include transform(rotate(45deg));
              border-right: 1px solid $color-primary-100;
              border-bottom: 1px solid $color-primary-100;
            }

            &:hover {
              cursor: pointer;
            }
          }

          .options {
            position: absolute;
            top: 58px;
            right: 16px;
            background-color: $color-background;
            box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.25);
            border-radius: 12px;
            z-index: 3;
            width: 218px;
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            @include transform(translateY(10px));
            @include transition(all 0.3s ease);

            &.active {
              opacity: 1;
              visibility: visible;
              @include transform(translateY(0));
            }

            .option {
              padding: 12px 40px 12px 15px;
              position: relative;

              span {
                color: $color-primary-100;
                font-size: 14px;
                font-weight: 400;
              }

              &:hover {
                background-color: $color-primary-5;
                cursor: pointer;
              }

              &.selected {
                &:after {
                  content: "";
                  width: 6px;
                  height: 12px;
                  border-right: 1px solid $color-primary-100;
                  border-bottom: 1px solid $color-primary-100;
                  position: absolute;
                  right: 20px;
                  top: 50%;
                  @include transform(translateY(-50%) rotate(45deg));
                }
              }
            }
          }
        }
      }

      .source {
        h1 {
          color: $color-primary-100;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 16px;
        }

        .card {
          display: flex;
          align-items: center;
          position: relative;

          .label {
            position: absolute;
            top: 50%;
            right: 0;
            @include transform(translateY(-50%));
            background: $color-primary-100;
            color: $color-primary-0;
            font-size: 10px;
            font-weight: 600;
            padding: 2px 10px;
            border-radius: 99px;
          }

          .icon {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: $color-primary-50;
            margin-right: 12px;
          }

          .text {
            color: $color-primary-100;

            .name {
              span {
                font-size: 13px;
                font-weight: 600;
              }
            }

            .number {
              span {
                font-size: 14px;
                font-weight: 400;
                letter-spacing: -0.2px;
                display: flex;
                align-items: center;

                .dots {
                  margin-right: 5px;
                  display: inherit;

                  i {
                    width: 4px;
                    height: 4px;
                    background-color: $color-primary-50;
                    border-radius: 4px;
                    display: inline-block;
                    margin-right: 2px;

                    &:last-of-type {
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }

          &.loading {
            .icon {
              background-color: $color-primary-10;
              -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%)
                right/300% 100%;
              @include animation(shimmer 1s infinite);
            }

            .label {
              background-color: $color-primary-10;
              width: 57px;
              height: 19px;
              display: inline-block;
              border-radius: 99px;
              -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%)
                right/300% 100%;
              @include animation(shimmer 1s infinite);

              span {
                text-indent: 100%;
                white-space: nowrap;
                overflow: hidden;
                display: none;
              }
            }

            .text {
              .name {
                span {
                  background-color: $color-primary-10;
                  width: 220px;
                  height: 15px;
                  display: inline-block;
                  border-radius: 99px;
                  text-indent: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  -webkit-mask: linear-gradient(
                      -60deg,
                      #000 30%,
                      #0005,
                      #000 70%
                    )
                    right/300% 100%;
                  @include animation(shimmer 1s infinite);
                }
              }

              .number {
                span {
                  background-color: $color-primary-10;
                  width: 140px;
                  height: 15px;
                  display: inline-block;
                  border-radius: 99px;
                  text-indent: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  -webkit-mask: linear-gradient(
                      -60deg,
                      #000 30%,
                      #0005,
                      #000 70%
                    )
                    right/300% 100%;
                  @include animation(shimmer 1s infinite);
                }
              }

              @keyframes shimmer {
                100% {
                  -webkit-mask-position: left;
                }
              }
            }
          }
        }

        p {
          margin-top: 16px;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $color-primary-70;

          a {
            color: $color-primary-100;
            text-decoration: underline;
          }
        }
      }
    }

    footer {
      margin-top: 32px;
      display: flex;
      justify-content: flex-end;

      button {
        border: 1px solid $color-primary-100;
        border-radius: 99px;
        background-color: transparent;
        color: $color-primary-100;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 12px 18px;
        margin-left: 9px;

        &:first-of-type {
          margin-left: 0;
        }

        &:hover {
          cursor: pointer;
          background-color: $color-primary-10;
        }

        &.primary {
          background-color: $color-primary-100;
          color: $color-primary-0;
          position: relative;
          @include transition(all 0.3s ease);

          &:hover {
            background-color: $color-primary-90;
          }

          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }

          i {
            width: 22px;
            height: 22px;
            position: absolute;
            top: 50%;
            right: 8px;
            border: 3px solid $color-primary-90;
            border-radius: 50%;
            border-top-color: $color-primary-0;
            @include animation(spin 1s ease-in-out infinite);
            margin-top: -11px;
            opacity: 0;
            visibility: hidden;
            @include transition(all 0.3s ease);

            @keyframes spin {
              to {
                transform: rotate(360deg);
              }
            }
          }

          &.generating {
            padding-right: 40px;

            i {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(23, 23, 23, 0.75);
    z-index: 1;
  }
}
</style>
