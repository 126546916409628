var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "kyc", class: { active: _setup.props.active } },
    [
      _c(
        "div",
        {
          staticClass: "back",
          on: {
            click: function ($event) {
              return _setup.emits("close")
            },
          },
        },
        [_c(_setup.ChevronLeft)],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _vm._m(0),
        _c("div", { staticClass: "form" }, [
          _c("div", { staticClass: "input-group" }, [
            _vm._m(1),
            _c("div", { staticClass: "inputs" }, [
              _c("div", { staticClass: "input" }, [
                _c("label", [_vm._v("Legal first name")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.data.kyc.firstName,
                      expression: "data.kyc.firstName",
                    },
                  ],
                  domProps: { value: _setup.data.kyc.firstName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _setup.data.kyc,
                        "firstName",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input" }, [
                _c("label", [_vm._v("Legal last name")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.data.kyc.lastName,
                      expression: "data.kyc.lastName",
                    },
                  ],
                  domProps: { value: _setup.data.kyc.lastName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_setup.data.kyc, "lastName", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "inputs" }, [
              _c("div", { staticClass: "input" }, [
                _c("label", [_vm._v("Date of birth (MM/DD/YYYY)")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.data.kyc.dob,
                      expression: "data.kyc.dob",
                    },
                  ],
                  domProps: { value: _setup.data.kyc.dob },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_setup.data.kyc, "dob", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input" }, [
                _c("label", [_vm._v("SSN or ITIN")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.data.kyc.government_id,
                      expression: "data.kyc.government_id",
                    },
                  ],
                  domProps: { value: _setup.data.kyc.government_id },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _setup.data.kyc,
                        "government_id",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "input-group" }, [
            _vm._m(2),
            _c("div", { staticClass: "inputs" }, [
              _c("div", { staticClass: "input" }, [
                _c("label", [_vm._v("Phone number")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.data.kyc.phoneNumber,
                      expression: "data.kyc.phoneNumber",
                    },
                  ],
                  domProps: { value: _setup.data.kyc.phoneNumber },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _setup.data.kyc,
                        "phoneNumber",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input" }, [
                _c("label", [_vm._v("Email address")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.data.kyc.email,
                      expression: "data.kyc.email",
                    },
                  ],
                  domProps: { value: _setup.data.kyc.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_setup.data.kyc, "email", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "input-group" }, [
            _vm._m(3),
            _c("div", { staticClass: "inputs" }, [
              _c("div", { staticClass: "input" }, [
                _c("label", [_vm._v("Street address")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.data.kyc.address.street,
                      expression: "data.kyc.address.street",
                    },
                  ],
                  domProps: { value: _setup.data.kyc.address.street },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _setup.data.kyc.address,
                        "street",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input" }, [
                _c("label", [_vm._v("City")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.data.kyc.address.city,
                      expression: "data.kyc.address.city",
                    },
                  ],
                  domProps: { value: _setup.data.kyc.address.city },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _setup.data.kyc.address,
                        "city",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "inputs" }, [
              _c("div", { staticClass: "input" }, [
                _c("label", [_vm._v("State")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.data.kyc.address.state_province,
                      expression: "data.kyc.address.state_province",
                    },
                  ],
                  domProps: { value: _setup.data.kyc.address.state_province },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _setup.data.kyc.address,
                        "state_province",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "input" }, [
                _c("label", [_vm._v("Zip")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.data.kyc.address.postcode,
                      expression: "data.kyc.address.postcode",
                    },
                  ],
                  domProps: { value: _setup.data.kyc.address.postcode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _setup.data.kyc.address,
                        "postcode",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "inputs" }, [
              _c("div", { staticClass: "input" }, [
                _c("label", [_vm._v("Country")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _setup.data.kyc.address.country,
                      expression: "data.kyc.address.country",
                    },
                  ],
                  domProps: { value: _setup.data.kyc.address.country },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _setup.data.kyc.address,
                        "country",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "terms" }, [
              _c("p", [
                _vm._v(
                  " Cloaked will never store your personally identifiable information (PI) and these are passed along to our banking partner using bank grade security (insert security standard we use, e.g. 256 bit SSL transport layer security encryption). For more information, please review our Privacy Policy, "
                ),
              ]),
              _vm._m(4),
              _c(
                "button",
                {
                  class: { enabled: _setup.enabled },
                  attrs: { disabled: !_setup.enabled },
                  on: { click: _setup.submitKyc },
                },
                [_c("span", [_vm._v("Submit Application")])]
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "cards" },
        _vm._l(2, function (index) {
          return _c(
            "span",
            { key: index },
            [_c(_setup.CloakedIcon), _c("div", { staticClass: "mastercard" })],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("h1", [_vm._v("Get your Cloaked card")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "group-title" }, [
      _c("h1", [_vm._v("Personal info")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "group-title" }, [
      _c("h1", [_vm._v("Contact info")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "group-title" }, [
      _c("h1", [_vm._v("Permanent address")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(" By submitting this application you agree to our "),
      _c("a", { attrs: { href: "" } }, [_vm._v("Cardholder Agreement")]),
      _vm._v(", "),
      _c("a", { attrs: { href: "" } }, [_vm._v("Terms of Service")]),
      _vm._v(", "),
      _c("a", { attrs: { href: "" } }, [_vm._v("Rates & Fees")]),
      _vm._v(", "),
      _c("a", { attrs: { href: "" } }, [_vm._v("Privacy Policy")]),
      _vm._v(" and "),
      _c("a", { attrs: { href: "" } }, [_vm._v("E-Sign")]),
      _vm._v(" Agreement "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }