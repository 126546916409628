var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showNewMenu
    ? _c(
        "PreferencesPanel",
        [
          !_vm.primaryPhone
            ? _c("ValueDisplay", {
                attrs: { label: "Add a recovery phone number" },
                on: {
                  add: _vm.handleAddPhoneNumber,
                  click: _vm.handleAddPhoneNumber,
                },
              })
            : _c("ValueDisplay", {
                attrs: {
                  label: "Recovery phone number",
                  value: _vm.formattedPhoneNumber,
                },
                on: {
                  edit: _vm.handleAddPhoneNumber,
                  delete: function ($event) {
                    return _vm.handleDelete(_vm.primaryPhone.id)
                  },
                },
              }),
        ],
        1
      )
    : _c(
        "PreferencesPanel",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("PreferencesHeader", {
                    on: { "go-back": _vm.toggle_back },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("PreferencesTitle", [_vm._v("Recovery phone number")]),
          !_vm.primaryPhone
            ? _c("PreferencesParagraph", [
                _vm._v(
                  " Add a recovery phone number to ensure you can access your Cloaked account. "
                ),
              ])
            : _c("ValueDisplay", {
                attrs: { value: _vm.formattedPhoneNumber },
                on: {
                  delete: function ($event) {
                    return _vm.handleDelete(_vm.primaryPhone.id)
                  },
                },
              }),
          _c("Button", { on: { click: _vm.handleAddPhoneNumber } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.primaryPhone
                    ? "Use a different number"
                    : "Add a phone number"
                ) +
                " "
            ),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }