<script setup>
import { CloakCreate, CloakDetails } from "@/routes";
import CardPanel from "@/routes/CloakedCards/CardPanel.vue";
import store from "@/store";

import { onMounted, computed } from "vue";

onMounted(() => {
  document.addEventListener("keydown", checkForSpecialKeys);
});

function checkForSpecialKeys($event) {
  $event.stopPropagation();
  if ($event?.key?.toLowerCase() === "escape") {
    if (!store.state.isMultiSelect) {
      handleClosePanel();
    }
  }
  if (
    $event?.target?.value === undefined &&
    $event?.key?.toLowerCase() === "n"
  ) {
    if (!showRightPanel.value) {
      store.dispatch("openCloakCreate");
    }
  }
}

function handleClosePanel() {
  store.dispatch("closeRightPanel");
}

async function handleCloakCreateDone(newCloak) {
  store.dispatch("openCloakDetails", { cloak: newCloak });
  store.dispatch("updateCloaks", [newCloak]);
  const newEvent = new CustomEvent("cloak:created");
  newEvent.data = newCloak;
  window.dispatchEvent(newEvent);
}

const showRightPanel = computed(() => {
  return store.getters.getRightPanelStatus;
});

const showCloakCreate = computed(() => {
  return store.getters.getCloakCreate;
});

const showCloakDetails = computed(() => {
  return store.getters.getCloakDetails;
});

const cloak = computed(() => {
  return store.getters.getCloak;
});

const showCardPanel = computed(() => {
  return store.getters.getCardPanel;
});
</script>

<template>
  <div>
    <section
      class="rightPanel"
      aria-id="RightPanel"
      :class="{ cards: showCardPanel }"
    >
      <CloakCreate @done="handleCloakCreateDone" v-if="showCloakCreate" />
      <CloakDetails v-else-if="showCloakDetails" :key="cloak && cloak.id" />
    </section>

    <CardPanel />
  </div>
</template>

<style lang="scss" scoped>
.rightPanel {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100vh;
  background: $color-surface;
  border-left: 1px solid $color-primary-10;
  @include transform(translateX(400px));

  body.active-right-panel & {
    right: 0;
    @include transform(translateX(0));
  }

  &.cards {
    body.active-right-panel & {
      @include transform(translateX(-50px));
    }
  }
}

.app--visible-banner {
  .rightPanel {
    top: 40px;
    height: calc(100vh - 40px);
  }
}
</style>
