var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _setup.activeStep.step !== 4
      ? _c(
          "div",
          {
            staticClass: "close",
            on: {
              click: function ($event) {
                return _setup.close()
              },
            },
          },
          [_c(_setup.InlineSvg, { attrs: { name: "chevron-left" } })],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(_setup.ProgressBar, {
          attrs: {
            maxSteps: _setup.steps.length - 1,
            currentStep: _setup.activeStep.step,
            title: _setup.activeStep.title,
          },
        }),
        _setup.activeStep.step === 1
          ? _c(_setup.Step1, {
              attrs: { form: _setup.formData },
              on: {
                validateStep: function ($event) {
                  return _setup.validateStep()
                },
                invalidateStep: function ($event) {
                  return _setup.invalidateStep()
                },
              },
            })
          : _vm._e(),
        _setup.activeStep.step === 2
          ? _c(_setup.Step2, {
              attrs: { form: _setup.formData },
              on: {
                validateStep: function ($event) {
                  return _setup.validateStep()
                },
                invalidateStep: function ($event) {
                  return _setup.invalidateStep()
                },
              },
            })
          : _vm._e(),
        _setup.activeStep.step === 3
          ? _c(_setup.Step3, {
              on: {
                validateStep: function ($event) {
                  return _setup.validateStep()
                },
              },
            })
          : _vm._e(),
        _setup.activeStep.step === 4
          ? _c(_setup.Step4, {
              attrs: { form: _setup.formData },
              on: {
                validateStep: function ($event) {
                  return _setup.validateStep()
                },
                invalidateStep: function ($event) {
                  return _setup.invalidateStep()
                },
              },
            })
          : _vm._e(),
        _setup.activeStep.step !== 4
          ? _c("div", { staticClass: "button" }, [
              _c(
                "button",
                {
                  attrs: { disabled: !_setup.activeStep.validated },
                  on: {
                    click: function ($event) {
                      return _setup.nextStep()
                    },
                  },
                },
                [
                  _setup.activeStep.step === 3
                    ? _c(
                        "span",
                        [
                          _vm._v("Agree & submit application "),
                          _c(_setup.InlineSvg, {
                            key: _setup.activeStep.step,
                            attrs: { name: "user-verification" },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "span",
                        [
                          _vm._v("Continue "),
                          _c(_setup.InlineSvg, {
                            key: _setup.activeStep.step,
                            attrs: { name: "arrow-right" },
                          }),
                        ],
                        1
                      ),
                ]
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }