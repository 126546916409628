var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ModalTemplate", {
    staticClass: "add-verify-email-mail",
    attrs: { show: true, "no-close": "" },
    on: { close: _vm.handleClose },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _vm.isMigration && _vm.logoutStep
              ? _c("div", [_c("h1", [_vm._v("Sign in to continue")])])
              : !_vm.confirming
              ? _c("div", [
                  _c("h1", [
                    _vm._v(_vm._s(_vm.title || "Add an email address")),
                  ]),
                ])
              : _c("div", [_c("h1", [_vm._v("Verify your email address")])]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _vm.isMigration && _vm.logoutStep
              ? _c("div", { staticClass: "subheader" }, [
                  _vm._v(
                    " In order to finish your security upgrade, we need to log you out of your Cloaked account. Please sign in again to complete the upgrade. "
                  ),
                ])
              : !_vm.confirming
              ? _c(
                  "div",
                  { staticClass: "subheader" },
                  [
                    _c("OnboardingInputEmail", {
                      attrs: { value: _vm.email, placeholder: "Email" },
                      on: {
                        input: _vm.handleEmailInput,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.saveEmail.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _vm._v(" Enter the 6-digit code sent to "),
                    _c("strong", [_vm._v(_vm._s(_vm.email))]),
                    _c("br"),
                    _c("OnboardingInputCode", {
                      attrs: { value: _vm.code },
                      on: {
                        input: _vm.handleCodeInput,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.verify.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                ),
            _vm.emailError || _vm.codeError
              ? _c("div", { staticClass: "input_error" }, [
                  _vm._v(" " + _vm._s(_vm.emailError || _vm.codeError) + " "),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _vm.isMigration && _vm.logoutStep
              ? [
                  _c("Button", { on: { click: _vm.logout } }, [
                    _vm._v(" Continue "),
                  ]),
                ]
              : !_vm.confirming
              ? [
                  !_vm.isMigration
                    ? _c(
                        "Button",
                        {
                          attrs: { type: "secondary" },
                          on: { click: _vm.handleCancel },
                        },
                        [_vm._v(" Cancel ")]
                      )
                    : _vm._e(),
                  _c(
                    "Button",
                    {
                      attrs: { disabled: !_vm.validEmail },
                      on: { click: _vm.saveEmail },
                    },
                    [_vm._v(" Continue ")]
                  ),
                ]
              : [
                  _vm.isMigration
                    ? _c(
                        "Button",
                        {
                          attrs: { type: "secondary" },
                          on: {
                            click: function ($event) {
                              _vm.confirming = false
                              _vm.savingEmail = false
                            },
                          },
                        },
                        [_vm._v(" Back ")]
                      )
                    : _vm._e(),
                  _c(
                    "Button",
                    { attrs: { type: "secondary" }, on: { click: _vm.resend } },
                    [
                      _vm.loading
                        ? _c("span", { staticClass: "spin-loader" })
                        : _vm._e(),
                      _vm._v(" Resend code "),
                    ]
                  ),
                  _c(
                    "Button",
                    {
                      attrs: { disabled: !_vm.validCode },
                      on: { click: _vm.verify },
                    },
                    [_vm._v(" Verify ")]
                  ),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }