<template>
  <PreferencesCheckParagraph
    v-model="optedIn"
    class="marketing-opt-in"
    :disabled="optedIn === null || loading === true"
  >
    Opt in to receive updates and announcements from Cloaked
  </PreferencesCheckParagraph>
</template>

<script>
import api from "@/api/api";
import PreferencesCheckParagraph from "@/routes/modals/preferences/PreferencesCheckParagraph";

export default {
  components: { PreferencesCheckParagraph },
  data() {
    return {
      optedIn: null,
      loading: false,
    };
  },
  async created() {
    try {
      const response = await api().get(`/api/v1/settings/marketing/`);

      if (response.status === 200) {
        this.optedIn = response.data.opted_in;
      } else {
        this.optedIn = false;
      }
    } catch (e) {
      // the endpoint fails with 404 if user hasn't explicitly set his / her preference - we default to false
      this.optedIn = false;
    }
  },
  watch: {
    async optedIn(value, oldValue) {
      if (oldValue === null) {
        return;
      }

      this.loading = true;
      try {
        await api().post("/api/v1/settings/marketing/", {
          opted_in: value,
        });
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.marketing-opt-in {
  margin-top: 20px;
}
</style>
