var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      staticClass: "progress",
      class: { "progress--anti-clockwise": _vm.antiClockWise },
      attrs: {
        width: _vm.size,
        height: _vm.size,
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        staticClass: "progress__background",
        attrs: {
          d: "M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10Z",
          "stroke-width": _setup.strokeWidth,
        },
      }),
      _c("path", {
        staticClass: "progress__indicator",
        attrs: {
          d: "M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10Z",
          "stroke-width": _setup.strokeWidth,
          pathLength: "1",
          transform: "rotate(-90 10 10)",
          "stroke-dasharray": _setup.indicatorDasharray,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }