<template>
  <div class="container">
    <div>
      <FileIcon />
      <div class="filetype" width="50">{{ type }}</div>
    </div>
  </div>
</template>

<script>
import FileIcon from "@/assets/icons/file.svg";
export default {
  name: "GenericMedia",
  props: ["attachment"],
  components: {
    FileIcon,
  },
  computed: {
    type() {
      return this.attachment.content_type.split("/")[1];
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  width: 140px;
  border-radius: 12px;
  background-color: $color-primary-0;
  padding: 10px;
  height: 110px;
  justify-content: center;
  align-items: center;
}
.container > div {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -30px;
}
.filetype {
  background: linear-gradient(
    140deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(254, 229, 228, 1) 100%
  );
  color: #f86d49;
  padding: 1px 6px;
  margin-top: 20px;
  margin-left: -60px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
svg {
  width: 40px;
}
</style>
