var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "no-identities" }, [
    _c(
      "div",
      { staticClass: "no-identities__content" },
      [
        _vm.identityType === "favorite"
          ? [
              _c(_setup.InlineSvg, {
                staticClass: "no-identities__icon",
                attrs: { name: "favorite-outline-thin" },
              }),
              _c("h2", { staticClass: "no-identities__title" }, [
                _vm._v("No favorites"),
              ]),
              _c(
                "p",
                { staticClass: "no-identities__text" },
                [
                  _vm._v(
                    " To add an identity to your favorites, tap on an identity in your “All identities” view and click the "
                  ),
                  _c(_setup.InlineSvg, {
                    staticClass: "no-identities__text-icon",
                    attrs: { name: "favorite-outline-thin" },
                  }),
                  _vm._v(" icon on the details sidebar. "),
                ],
                1
              ),
            ]
          : _vm.identityType === "ignored"
          ? [
              _c(_setup.InlineSvg, {
                staticClass: "no-identities__icon",
                attrs: { name: "ignored-outline-thin" },
              }),
              _c("h2", { staticClass: "no-identities__title" }, [
                _vm._v("No ignored identities"),
              ]),
              _c(
                "p",
                { staticClass: "no-identities__text" },
                [
                  _vm._v(
                    " You can ignore identities you no longer want to hear from by clicking on a Cloaked identity from the “All identities” view and clicking the "
                  ),
                  _c(_setup.InlineSvg, {
                    staticClass: "no-identities__text-icon",
                    attrs: { name: "bell" },
                  }),
                  _vm._v(" icon in the Cloaked details panel. "),
                ],
                1
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }