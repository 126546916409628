<template>
  <label
    class="radio-button"
    :class="{
      'radio-button--checked': checked,
    }"
    @click="handleClick"
  >
    <input type="radio" :name="name" :value="value" :checked="checked" />
    <RadioActive v-if="checked" />
    <RadioInactive v-else />
  </label>
</template>

<script>
import { RadioActive, RadioInactive } from "@/assets/icons";

export default {
  name: "RadioButton",
  props: ["checked", "name", "value"],
  components: {
    RadioActive,
    RadioInactive,
  },
  methods: {
    handleClick() {
      this.$emit("update", this.value);
    },
  },
};
</script>

<style scoped lang="scss">
.radio-button {
  width: 19px;
  height: 22px;
  position: relative;
  color: $color-primary-100;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    visibility: hidden;
    position: absolute;
  }

  &--checked {
    color: $color-primary-100;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
