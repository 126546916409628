<script setup>
import { computed, defineProps, onMounted, reactive, watch } from "vue";

import { DefaultIdentityIcon } from "@/assets/icons";
import { NEW_DEFAULT_IDENTITY_ICONS } from "@/scripts/featureFlags";
import { convertHexToRgba } from "@/scripts/format";

const BRAND_COLOR_DICTIONARY = {
  spirit_rose: { color: "#E75D72", secondaryColor: "#ED0061" },

  crest_blue: { color: "#0094C6", secondaryColor: "#3673AE" },

  arctic_lime: { color: "#E0FF4F", secondaryColor: "#DBDF00" },

  violet_reflection: { color: "#9C95DC", secondaryColor: "#8D4FBD" },

  caribbean_green: { color: "#00C49A", secondaryColor: "#2AC1AF" },

  foam_blue: { color: "#5ADBFF", secondaryColor: "#2AB5DC" },
};

const props = defineProps({
  identity: { type: Object, default: null },
  onClick: { type: Function, default: null },
  override: { type: Object, default: null },
});
const state = reactive({ logo: "", colors: {} });

const height = computed(
  () => (props.override && props.override.height) || "30px"
);

const width = computed(
  () => (props.override && props.override.width) || "30px"
);

const newDefaultIconsEnabled = computed(() => NEW_DEFAULT_IDENTITY_ICONS);

watch(
  () => props.identity,
  () => {
    state.logo = getLogo();
    state.colors = getColors();
  }
);

onMounted(() => {
  state.logo = getLogo();
  state.colors = getColors();
});

const getLogo = () => {
  if (props.identity) {
    return props.identity.logo_url;
  }
  return null;
};
const getBrandColorHexes = (brandColorName) => {
  return {
    color: BRAND_COLOR_DICTIONARY[brandColorName].color,
    secondaryColor: BRAND_COLOR_DICTIONARY[brandColorName].secondaryColor,
  };
};
const getColors = () => {
  if (props.identity) {
    if (
      NEW_DEFAULT_IDENTITY_ICONS &&
      props.identity?.cloak_brand_color &&
      BRAND_COLOR_DICTIONARY[props.identity?.cloak_brand_color]
    ) {
      if (props.identity?.muted) {
        return getMutedColors(props.identity.cloak_brand_color);
      }
      /* If we have the new field, look up the hex codes in our color dictionary */
      return getBrandColorHexes(props.identity?.cloak_brand_color);
    }
    /* If we have the new field but the value isn't in our dictionary, default to Crest Blue */
    return getBrandColorHexes("crest_blue");
  }
  return null;
};
const handleClick = (e) => {
  if (props.onClick) {
    return props.onClick(e);
  }
};
const getLegacyGradient = (muted) => {
  const finalStyle = {
    height: "100%",
    width: "100%",
  };
  const primaryOpacity = muted ? 0.025 : 0.05;
  const secondaryOpacity = muted ? 0.05 : 0.1;
  const primaryColor = convertHexToRgba(
    (props.identity && props.identity.color) || "#8c8e91",
    muted ? 0.5 : 1
  );
  const secondaryColor = convertHexToRgba(
    (props.identity && props.identity.secondary_color) || "#191e23",
    muted ? 0.5 : 1
  );

  const primaryRadial = convertHexToRgba("#191e23", primaryOpacity);
  const secondaryRadial = convertHexToRgba("#191e23", secondaryOpacity);

  finalStyle.background = `radial-gradient(73.12% 73.12% at 38.48% 26.88%, ${primaryRadial} 0%, ${secondaryRadial} 100%), linear-gradient(180deg, ${primaryColor} 0%, ${secondaryColor} 100%)`;

  return finalStyle;
};
const getBkgdWrapperStyle = () => {
  return {
    ...props.override,
    height: height.value,
    width: width.value,
  };
};
const getBkgdLogoStyle = () => {
  const finalStyle = {
    height: "100%",
    width: "100%",
  };

  if (state.logo) {
    finalStyle.backgroundImage = "url(" + state.logo + ")";
  }
  return finalStyle;
};
const getMutedColors = (brandColorName) => {
  const brandColors = getBrandColorHexes(brandColorName);
  const primaryColor = convertHexToRgba(brandColors.color, 0.5);
  const secondaryColor = convertHexToRgba(brandColors.secondaryColor, 0.5);
  return {
    color: primaryColor,
    secondaryColor: secondaryColor,
  };
};
</script>
<template>
  <div
    v-if="props.identity && state.logo"
    class="identity-icon"
    :style="getBkgdWrapperStyle()"
    @click="handleClick"
  >
    <div :style="getBkgdLogoStyle()" class="logo"></div>
  </div>
  <DefaultIdentityIcon
    v-else-if="newDefaultIconsEnabled"
    :key="`${state.colors?.color}${props.identity?.nickname}`"
    :color="state.colors?.color"
    :secondaryColor="state.colors?.secondaryColor"
    :height="props.override?.height"
    :width="props.override?.width"
  />
  <div
    v-else
    class="identity-icon"
    :style="getBkgdWrapperStyle()"
    @click="handleClick"
  >
    <div :style="getLegacyGradient(props.identity?.muted)" class="logo"></div>
  </div>
</template>

<style lang="scss" scoped>
.identity-icon {
  background-color: $color-background;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: -1px -1px 8px rgba(1, 2, 24, 0.05),
    1.5px 1.5px 8px rgba(1, 2, 24, 0.1),
    inset 0px 0px 0px 0.2px rgba(255, 255, 255, 0.2);

  .logo {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
</style>
