var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "ui-menu-separator",
    on: {
      click: function ($event) {
        $event.stopPropagation()
        return (() => {}).apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }