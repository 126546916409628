<script setup>
import { defineProps, defineEmits, watch, ref, nextTick } from "vue";
import { createWidget } from "@typeform/embed";
import { encode } from "@/scripts/hash";

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "dark",
    validator: (value) => ["dark", "light"].includes(value),
  },
  userId: {
    type: [Number, String],
    required: true,
  },
  formId: {
    type: [Number, String],
    required: true,
  },
  width: {
    type: Number,
    default: 300,
  },
  height: {
    type: Number,
    default: 400,
  },
  hideAfterSubmitTimeout: {
    type: Number,
    default: 2500,
  },
});

const emit = defineEmits(["input", "submit", "close"]);
const isSubmitted = ref(false);
const container = ref(null);

watch(
  () => props.value,
  (isOpen) => {
    if (isOpen) {
      nextTick(() => {
        createWidget(props.formId, {
          container: container.value,
          hidden: {
            userid: encode(props.userId),
          },
          onSubmit: () => {
            isSubmitted.value = true;
            emit("submit");
            setTimeout(() => {
              emit("input", false);
            }, props.hideAfterSubmitTimeout);
          },
          width: props.width,
          height: props.height,
        });
      });
    }
  },
  { immediate: true }
);
</script>

<template>
  <transition name="fade-250" appear>
    <div
      v-if="value"
      :class="['app-survey-typeform', `app-survey-typeform--${type}`]"
      :style="{
        width: `${width}px`,
        height: `${height}px`,
      }"
    >
      <button
        @click="
          $emit('input', false);
          $emit('close');
        "
        class="app-survey-typeform__close"
      >
        <svg
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          class="app-survey-typeform__close-icon"
        >
          <path
            d="M0.992188 13.5391C0.710938 13.8203 0.710938 14.2773 0.992188 14.5586C1.27344 14.8281 1.73047 14.8281 2.01172 14.5586L7.98828 8.57031L13.9766 14.5586C14.2578 14.8281 14.7148 14.8398 14.9961 14.5586C15.2656 14.2773 15.2656 13.8203 14.9961 13.5391L9.00781 7.55078L14.9961 1.57422C15.2656 1.29297 15.2773 0.824219 14.9961 0.554688C14.7148 0.273438 14.2578 0.273438 13.9766 0.554688L7.98828 6.53125L2.01172 0.554688C1.73047 0.273438 1.26172 0.273438 0.992188 0.554688C0.710938 0.835938 0.710938 1.29297 0.992188 1.57422L6.98047 7.55078L0.992188 13.5391Z"
            fill="currentColor"
          />
        </svg>
      </button>
      <div class="app-survey-typeform__container">
        <div v-if="!isSubmitted" ref="container"></div>
        <transition name="fade-500" appear>
          <h1 v-if="isSubmitted" class="app-survey-typeform__thank-you">
            Thank you for your feedback!
          </h1>
        </transition>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
@import "@typeform/embed/build/css/widget.css";

.app-survey-typeform {
  position: fixed;
  z-index: 100;
  bottom: 10px;
  right: 10px;

  &__close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -45px;
    background-color: $color-primary-0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 100%;

    &:hover {
      background-color: $color-primary-5;
    }

    &-icon {
      color: $color-primary-100;
      width: 16px;
      height: 16px;
    }
  }

  &__container {
    height: 100%;
    box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
      13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
    border-radius: 15px;
    overflow: hidden;
    display: flex;

    @at-root .app-survey-typeform--dark & {
      background-color: $color-primary-100;
    }

    @at-root .app-survey-typeform--light & {
      background-color: $color-primary-0;
    }
  }

  &__thank-you {
    padding: 40px;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    margin: auto;

    @at-root .app-survey-typeform--dark & {
      background-color: $color-primary-100;
      color: $color-primary-0;
    }

    @at-root .app-survey-typeform--light & {
      background-color: $color-primary-0;
      color: $color-primary-100;
    }
  }
}
</style>
