import api from "@/api/api";
// import store from "@/store";
import { ANALYTICS_ENABLED } from "@/scripts/featureFlags";

const analyticsEndpoint = "/api/v1/settings/analytics/";

export default class PrivacyServices {
  static async getAnalytics() {
    if (ANALYTICS_ENABLED) {
      return await api().get(analyticsEndpoint);
    }
  }

  static async setAnalytics(allow_analytics) {
    if (ANALYTICS_ENABLED) {
      return await api().post(analyticsEndpoint, allow_analytics);
    }
  }
}
