<script setup>
import SettingsChildPage from "./SettingsChildPage.vue";
import { SettingsTitle, SettingsParagraph } from "@/components";
import RecoveryPhrase from "@/components/Settings/RecoveryPhrase";
</script>
<template>
  <SettingsChildPage class="forwarding-page">
    <template v-slot:aside>
      <SettingsTitle>Recovery passphrase</SettingsTitle>
      <SettingsParagraph> Save your recovery passphrase </SettingsParagraph>
    </template>

    <RecoveryPhrase />
  </SettingsChildPage>
</template>
