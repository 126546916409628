var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "shared" }, [
    _c("div", { staticClass: "shared__background-blur" }),
    _c(
      "div",
      { staticClass: "shared__header" },
      [
        _c(_setup.CloakedLogoDark, {
          staticClass: "shared__header__cloaked-logo",
          on: { click: _setup.openHomePage },
        }),
        _c(_setup.Timer, {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isSharedIdentityAvailable,
              expression: "isSharedIdentityAvailable",
            },
          ],
          staticClass: "shared__header__timer-container",
          attrs: {
            startDate: _setup.startDate,
            endDate: _setup.endDate,
            isOnSharedPage: true,
            isOneTimeView: _setup.isOneTimeView,
          },
        }),
      ],
      1
    ),
    _setup.loading
      ? _c(
          "div",
          { staticClass: "shared__loading-container" },
          [_c(_setup.Spinner)],
          1
        )
      : _c("div", [
          _setup.shouldShowIdentityForm
            ? _c(
                "div",
                { staticClass: "shared__identity-container" },
                [
                  _vm._m(0),
                  _c(_setup.PreferencesInput, {
                    attrs: {
                      label: "Password",
                      type: "password",
                      placeholder: "",
                    },
                    model: {
                      value: _setup.password,
                      callback: function ($$v) {
                        _setup.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _setup.isPasswordIncorrect
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "shared__identity-container__error-container",
                        },
                        [
                          _c(_setup.ErrorTriangleFilled),
                          _c(
                            "span",
                            {
                              staticClass:
                                "shared__identity-container__error-container__text",
                            },
                            [_vm._v("Incorrect password")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    _setup.Button,
                    {
                      staticClass: "shared__identity-container__button",
                      attrs: { primary: true },
                      on: { click: _setup.decryptJSON },
                    },
                    [_vm._v("View identity ")]
                  ),
                ],
                1
              )
            : _setup.identity.length > 0
            ? _c(
                "div",
                {
                  staticClass:
                    "shared__identity-container shared__with-identity",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "shared__identity-container__identity-details",
                    },
                    [_vm._v("Identity details")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "shared__identity-container__identity-fields",
                    },
                    _vm._l(_setup.identity, function (identityProperty) {
                      return _c(
                        "div",
                        { key: identityProperty.i },
                        [
                          _c(_setup.CloakInfoRow, {
                            attrs: {
                              fieldLabel: identityProperty.l,
                              field: _setup.getFieldType(identityProperty.t),
                              initialValue: _setup.getCopyValue(
                                identityProperty.t,
                                identityProperty.v
                              ),
                              isOnSharedPage: true,
                              isSensitive: identityProperty.s,
                              "copy-value": identityProperty.t.includes("totp")
                                ? _setup.totpToken
                                : "",
                            },
                            scopedSlots: _vm._u(
                              [
                                identityProperty.t.includes("totp") &&
                                identityProperty.v
                                  ? {
                                      key: "input",
                                      fn: function () {
                                        return [
                                          _c(_setup.TOTPToken, {
                                            attrs: {
                                              url: identityProperty.v?.startsWith(
                                                "otpauth://"
                                              )
                                                ? identityProperty.v
                                                : undefined,
                                              secret:
                                                identityProperty.v?.startsWith(
                                                  "otpauth://"
                                                )
                                                  ? undefined
                                                  : identityProperty.v,
                                            },
                                            on: {
                                              "new-token": function ($event) {
                                                _setup.totpToken = $event
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              )
            : _c("div", { staticClass: "shared__no-identity-container" }, [
                _vm._m(1),
              ]),
          _c(
            "div",
            { staticClass: "shared__cloaked-trial-container" },
            [
              _vm._m(2),
              _c(
                _setup.Button,
                {
                  staticClass: "shared__cloaked-trial-container__button",
                  attrs: { primary: false },
                  on: { click: _setup.openTrialPage },
                },
                [_vm._v("Try Cloaked free for 14 days ")]
              ),
            ],
            1
          ),
        ]),
    _c("div", { staticClass: "shared__footer" }, [
      _vm._m(3),
      _c("div", { staticClass: "shared__footer__divider" }),
      _c(
        "div",
        { staticClass: "shared__footer__legal" },
        [
          _c(_setup.CloakedLogoDark, {
            staticClass: "shared__footer__legal__cloaked-logo",
            on: { click: _setup.openHomePage },
          }),
          _vm._m(4),
        ],
        1
      ),
    ]),
    _vm._m(5),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "shared__identity-container__description" },
      [
        _c(
          "span",
          { staticClass: "shared__identity-container__description__text" },
          [_vm._v("Someone shared an identity with you")]
        ),
        _c(
          "span",
          {
            staticClass:
              "shared__identity-container__description__text shared__identity-container__description__subtext",
          },
          [_vm._v("This page is password protected.")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "shared__no-identity-container__description" },
      [
        _c(
          "span",
          { staticClass: "shared__no-identity-container__description__text" },
          [_vm._v("The share link is no longer valid.")]
        ),
        _c(
          "span",
          {
            staticClass:
              "shared__no-identity-container__description__text shared__no-identity-container__description__subtext",
          },
          [_vm._v("If you still need access, please reach out to the sender.")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "shared__cloaked-trial-container__cloaked-trial-description",
      },
      [
        _c(
          "span",
          {
            staticClass:
              "shared__cloaked-trial-container__cloaked-trial-description__text",
          },
          [_vm._v("Cloaked makes privacy easy.")]
        ),
        _c(
          "span",
          {
            staticClass:
              "shared__cloaked-trial-container__cloaked-trial-description__text shared__cloaked-trial-container__cloaked-trial-description__subtext",
          },
          [_vm._v("Cloaked protects your privacy online and in real life")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "shared__footer__links" }, [
      _c(
        "a",
        {
          staticClass: "shared__footer__links__link",
          attrs: { href: "https://www.cloaked.app/blog", target: "_blank" },
        },
        [_vm._v("Blog")]
      ),
      _c(
        "a",
        {
          staticClass: "shared__footer__links__link",
          attrs: {
            href: "https://twitter.com/keepitcloaked",
            target: "_blank",
          },
        },
        [_vm._v("Twitter")]
      ),
      _c(
        "a",
        {
          staticClass: "shared__footer__links__link",
          attrs: { href: "https://cloaked.community/", target: "_blank" },
        },
        [_vm._v("Discord")]
      ),
      _c(
        "a",
        {
          staticClass: "shared__footer__links__link",
          attrs: {
            href: "https://keepitcloaked.medium.com/",
            target: "_blank",
          },
        },
        [_vm._v("Medium")]
      ),
      _c(
        "a",
        {
          staticClass: "shared__footer__links__link",
          attrs: {
            href: "https://jobs.lever.co/cloaked-app",
            target: "_blank",
          },
        },
        [_vm._v("Join our Team")]
      ),
      _c(
        "a",
        {
          staticClass: "shared__footer__links__link",
          attrs: {
            href: "https://www.cloaked.app/changelog",
            target: "_blank",
          },
        },
        [_vm._v("Changelog")]
      ),
      _c(
        "a",
        {
          staticClass: "shared__footer__links__link",
          attrs: { href: "https://www.cloaked.app/faq", target: "_blank" },
        },
        [_vm._v("FAQ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "shared__footer__legal__links" }, [
      _c(
        "a",
        {
          staticClass: "shared__footer__legal__links__link",
          attrs: {
            href: "https://www.cloaked.app/privacy-policy",
            target: "_blank",
          },
        },
        [_vm._v("Privacy Policy")]
      ),
      _c(
        "a",
        {
          staticClass: "shared__footer__legal__links__link",
          attrs: {
            href: "https://www.cloaked.app/terms-of-service",
            target: "_blank",
          },
        },
        [_vm._v("Terms of Service")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "shared__mobile-footer" }, [
      _c("div", { staticClass: "shared__mobile-footer__links" }, [
        _c(
          "a",
          {
            staticClass: "shared__mobile-footer__links__link",
            attrs: { href: "https://www.cloaked.app/blog", target: "_blank" },
          },
          [_vm._v("Blog")]
        ),
        _c(
          "a",
          {
            staticClass: "shared__mobile-footer__links__link",
            attrs: {
              href: "https://twitter.com/keepitcloaked",
              target: "_blank",
            },
          },
          [_vm._v("Twitter")]
        ),
        _c(
          "a",
          {
            staticClass: "shared__mobile-footer__links__link",
            attrs: { href: "https://cloaked.community/", target: "_blank" },
          },
          [_vm._v("Discord")]
        ),
        _c(
          "a",
          {
            staticClass: "shared__mobile-footer__links__link",
            attrs: {
              href: "https://keepitcloaked.medium.com/",
              target: "_blank",
            },
          },
          [_vm._v("Medium")]
        ),
        _c(
          "a",
          {
            staticClass: "shared__mobile-footer__links__link",
            attrs: {
              href: "https://jobs.lever.co/cloaked-app",
              target: "_blank",
            },
          },
          [_vm._v("Join our Team")]
        ),
      ]),
      _c("div", { staticClass: "shared__mobile-footer__links" }, [
        _c(
          "a",
          {
            staticClass: "shared__mobile-footer__links__link",
            attrs: {
              href: "https://www.cloaked.app/changelog",
              target: "_blank",
            },
          },
          [_vm._v("Changelog")]
        ),
        _c(
          "a",
          {
            staticClass: "shared__mobile-footer__links__link",
            attrs: { href: "https://www.cloaked.app/faq", target: "_blank" },
          },
          [_vm._v("FAQ")]
        ),
        _c(
          "a",
          {
            staticClass: "shared__mobile-footer__links__link",
            attrs: {
              href: "https://www.cloaked.app/privacy-policy",
              target: "_blank",
            },
          },
          [_vm._v("Privacy Policy")]
        ),
        _c(
          "a",
          {
            staticClass: "shared__mobile-footer__links__link",
            attrs: {
              href: "https://www.cloaked.app/terms-of-service",
              target: "_blank",
            },
          },
          [_vm._v("Terms of Service")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }