<script setup>
import SettingsChildPage from "./SettingsChildPage.vue";
import { SettingsTitle, SettingsParagraph } from "@/components";
import Forwarding from "@/routes/modals/preferences/Forwarding.vue";
</script>
<template>
  <SettingsChildPage class="forwarding-page">
    <template v-slot:aside>
      <SettingsTitle>Forwarding</SettingsTitle>
      <SettingsParagraph>
        The following preferences will be used on all new Cloaked identities.
        You can always override these default settings on individual identities.
      </SettingsParagraph>
    </template>

    <Forwarding />
  </SettingsChildPage>
</template>

<style lang="scss">
.forwarding-page {
  .settings-links-list {
    margin-top: 22px;
  }
}
</style>
