var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "page" }, [
      _c(
        "div",
        { staticClass: "activity-list" },
        [
          _c("ActivityPreviewList", {
            key: _vm.type || "all",
            ref: "list",
            attrs: {
              cloakId: _vm.cloakId,
              type: _vm.type,
              isRequests: _vm.type === "requests",
              multiSelected: _vm.multiSelected,
              singleSelected: _vm.singleSelected,
              multiSelectEnabled: _vm.multiSelectEnabled,
            },
            on: {
              select: _vm.select,
              enableMultiSelect: _vm.enableMultiSelect,
              setMultiSelect: _vm.setMultiSelect,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "activity-container" },
        [
          _vm.singleSelected && _vm.singleSelected.thread_id
            ? _c("ActivityEmailDisplay", {
                key: _vm.singleSelected.thread_id,
                attrs: { thread: _vm.singleSelected },
                on: {
                  removed: _vm.removed,
                  updated: _vm.updated,
                  refresh: _vm.refresh,
                },
              })
            : _vm.singleSelected && _vm.singleSelected?.conversation_id
            ? _c("ActivityConversationDisplay", {
                key: _vm.singleSelected?.conversation_id,
                attrs: { thread: _vm.singleSelected },
                on: {
                  removed: _vm.removed,
                  updated: _vm.updated,
                  refresh: _vm.refresh,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }