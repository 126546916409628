var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card-icon-wrapper",
      class: { "add-padding": !_vm.logoShouldFillSpace },
    },
    [_c(_vm.cardIcon, { tag: "Component" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }