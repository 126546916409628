var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.AppModal,
    _vm._g(_vm._b({}, "AppModal", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        _setup.AppModalContent,
        { staticClass: "identity-sharing-expired" },
        [
          _c(
            _setup.AppModalCustomWrapper,
            { staticClass: "identity-sharing-expired__head" },
            [
              _c(_setup.IdentityIcon, {
                attrs: {
                  identity: _vm.identity,
                  override: { width: "72px", height: "72px" },
                },
              }),
            ],
            1
          ),
          _c(
            _setup.AppModalTitle,
            { staticClass: "identity-sharing-expired__title" },
            [_vm._v(" Your share link has expired ")]
          ),
          _c(_setup.IdentitySharingTerms),
          _c(
            _setup.AppModalFooter,
            { attrs: { "with-border": "" } },
            [
              _c(
                _setup.Button,
                {
                  attrs: { type: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("input", false)
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _c(
                _setup.Button,
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("open-create")
                    },
                  },
                },
                [_vm._v("Generate new share link")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }