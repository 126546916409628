<script setup>
import Logo from "@/assets/images/CloakedLogo.svg";
</script>

<template>
  <div class="full">
    <Logo />
    <div>Please upgrade your extension to continue using Cloaked.</div>
    <a href="https://download.cloaked.app" target="_new">Get Cloaked</a>
  </div>
</template>

<style lang="scss" scoped>
.full {
  color: $color-primary-100;
  text-align: center;
  display: flex;
  flex-direction: column;
  svg {
    width: auto;
    height: 50px;
    color: $color-primary-100;
  }
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    &:hover {
      background-color: $color-primary-30;
    }
    font-size: 1.12rem;
    margin-top: 20px;
    display: inline-block;
    padding: 10px 35px;
    border: 1px solid $color-primary-100;
    border-radius: 10px;
  }
}
</style>
