<script setup>
import { defineProps, defineEmits, computed, ref, watch } from "vue";
import ModalTemplate from "@/components/ModalTemplate";
import { useRoute } from "vue-router/composables";
import store from "@/store";
import InlineSvg from "@/components/InlineSvg.vue";
import api from "@/api/api";

const route = useRoute();

const props = defineProps({
  show: Boolean,
});

const emit = defineEmits(["close"]);

const currentCard = computed(() => {
  if (route.params.id && store.state.cards.cards.results) {
    return store.state.cards.cards.results.find(
      (card) => card.id === route.params.id
    );
  } else {
    return "";
  }
});

const currentCardCopy = computed(() => {
  return JSON.parse(JSON.stringify(currentCard.value));
});

const localCard = ref(currentCardCopy.value);

const periodConversion = (period) => {
  if (period === "daily") {
    return "per day";
  } else if (period === "weekly") {
    return "per week";
  } else if (period === "monthly") {
    return "per month";
  } else if (period === "yearly") {
    return "per year";
  } else if (
    currentCard.value.transaction_period === "forever" &&
    currentCard.value.transaction_period_max_transactions === 2
  ) {
    return "one time";
  } else if (
    currentCard.value.transaction_period === "forever" &&
    currentCard.value.transaction_period_max_transactions !== 2
  ) {
    return "Up to";
  } else {
    return "unknown";
  }
};

const title = computed(() => {
  if (
    localCard.value.transaction_period === "forever" &&
    localCard.value.transaction_period_max_transactions !== 2
  ) {
    return `You can spend up to $${spendingLimit.value}.`;
  } else if (
    localCard.value.transaction_period === "forever" &&
    localCard.value.transaction_period_max_transactions === 2
  ) {
    return `You can make a single $${spendingLimit.value} transaction.`;
  } else {
    return `You can spend up to $${spendingLimit.value} ${periodConversion(
      localCard.value.transaction_period
    )}.`;
  }
});

function convertToDollar(amount) {
  return Number(amount / 100)
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    })
    .replace(/[^\d.]/g, "");
}

function convertToCent(amount) {
  return Number(amount.replace(/[^\d.]/g, "")) * 100;
}

const spendingLimit = computed(() => {
  return convertToDollar(localCard.value.transaction_period_limit);
});

function saveForm() {
  api()
    .patch(`/api/v1/cloaked/card/${currentCard.value.id}/`, {
      transaction_period_limit: localCard.value.transaction_period_limit,
      transaction_period: localCard.value.transaction_period,
      transaction_period_max_transactions:
        localCard.value.transaction_period_max_transactions,
    })
    .then(() => {
      store.dispatch("updateCard", localCard.value);
      emit("close");
    })
    .catch(() => {
      alert("Something went wrong. Please try again later.");
    });
}

const dollars = ref(convertToDollar(localCard.value.transaction_period_limit));

function updateCents() {
  localCard.value.transaction_period_limit = convertToCent(dollars.value);
}

watch(
  () => props.show,
  (value) => {
    if (value) {
      localCard.value = JSON.parse(JSON.stringify(currentCard.value));
      dollars.value = convertToDollar(localCard.value.transaction_period_limit);
    }
  }
);
</script>

<template>
  <ModalTemplate :show="props.show" no-close width="375px">
    <template #body>
      <div class="text" v-if="currentCard">
        <div class="title">
          <div class="icon">
            <InlineSvg name="spending-limit" />
          </div>

          <h1>Spending limit</h1>

          <p>{{ title }}</p>
        </div>

        <div class="block">
          <input type="text" v-model="dollars" @input="updateCents" />
        </div>

        <div class="buttons">
          <button class="primary" @click="saveForm()">Save</button>
          <button @click="emit('close')">Cancel</button>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<style lang="scss" scoped>
.text {
  padding: 24px 0;

  .title {
    margin-bottom: 34px;

    .icon {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background-color: $color-primary-100;
      position: relative;

      svg {
        width: 34px;
        height: auto;
        color: $color-primary-0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    h1 {
      margin-top: 16px;
      color: $color-primary-100;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.4px;
    }

    p {
      margin-top: 8px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.1px;
      color: $color-primary-70;
    }
  }

  .block {
    input {
      width: 100%;
      padding: 11px;
      background-color: $color-primary-5;
      border: 0;
      border-radius: 4px;
      color: $color-primary-100;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .buttons {
    margin-top: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;

    button {
      border: 0;
      background: transparent;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.1px;
      color: $color-primary-100;
      margin-top: 16px;
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }

      &.primary {
        width: 100%;
        background: $color-primary-100;
        padding: 11px;
        color: $color-primary-0;
        border-radius: 999px;

        &:hover {
          background: $color-primary-90;
        }
      }
    }
  }
}
</style>
