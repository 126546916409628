<script setup>
import { computed, defineProps, defineEmits, ref, watch } from "vue";
import TOTPInputRow from "@/components/cloakDetails/TOTP/TOTPInputRow.vue";
import { TOTP, URI } from "otpauth";
import { withPatchedValues } from "@/store/modules/accounts-importer/automatedFixes";
import {
  encryptCustomField,
  withDecryptedCustomFields,
} from "@/scripts/customFields";
import IdentityService from "@/api/actions/identity-service";

const props = defineProps({
  identity: {
    type: Object,
    required: true,
  },
});

const totp = computed(() =>
  props.identity.customFields?.find((field) =>
    ["totp_url", "totp_secret"].includes(field.type)
  )
);

const totpToken = ref(null);

const emit = defineEmits(["refresh", "set-loading", "set-errors"]);

const refreshCustomFields = () => {
  return new Promise((resolve) => {
    const unwatch = watch(
      () => props.identity.customFields,
      () => {
        unwatch();
        resolve();
      },
      { deep: true }
    );

    emit("refresh", { id: props.identity.id });
  });
};

const saveTotp = async (input) => {
  const value = input.replaceAll(" ", "");
  try {
    emit("set-loading", true);

    const { data } = await IdentityService.fetchPopulatedIdentityV2(
      props.identity.id
    );

    const identity = await withDecryptedCustomFields(data);
    const mostRecentTotp = identity.customFields?.find((field) =>
      ["totp_url", "totp_secret"].includes(field.type)
    );

    const hasExistingPasscode = !!mostRecentTotp;
    const isTotpUrl = value.startsWith("otpauth://");

    const totpObject = isTotpUrl
      ? URI.parse(value)
      : new TOTP({
          secret: value,
        });

    const customField = withPatchedValues({
      id: mostRecentTotp?.id ?? self.crypto.randomUUID(),
      type: isTotpUrl ? "totp_url" : "totp_secret",
      value: isTotpUrl ? totpObject.toString() : value,
      label: "One-time password",
      isSecret: false,
    });

    const encryptedCustomField = await encryptCustomField(customField);

    await IdentityService.patchCustomField(
      props.identity.id,
      JSON.stringify({
        custom_data: [
          {
            op: hasExistingPasscode ? "replace" : "add",
            path: `/custom_field/${encryptedCustomField.id}`,
            value: encryptedCustomField.value,
          },
        ],
      })
    );

    await refreshCustomFields();
    window.$vue.$toast.success(
      hasExistingPasscode
        ? "One-time passcode updated."
        : "New one-time passcode added."
    );

    emit("set-errors", false);
  } catch (e) {
    emit("set-errors", true);
    window.$vue.$toast.error("Not a valid secret key or otpauth link.");
  } finally {
    emit("set-loading", false);
  }
};

const deleteTotp = async () => {
  try {
    emit("set-loading", true);

    await IdentityService.patchCustomField(
      props.identity.id,
      JSON.stringify({
        custom_data: [
          {
            op: "remove",
            path: `/custom_field/${totp.value?.id}`,
          },
        ],
      })
    );

    await refreshCustomFields();
    window.$vue.$toast.success("One-time passcode deleted.");
    emit("set-errors", false);
  } catch (e) {
    emit("set-errors", true);
    window.$vue.$toast.error("Something went wrong. Please try again later.");
  } finally {
    emit("set-loading", false);
  }
};

const isTotpIntroDisplayed = ref(false);
</script>

<template>
  <div>
    <TOTPInputRow
      @save="saveTotp"
      @delete="deleteTotp"
      :totp="totp"
      :isTotpIntroDisplayed="isTotpIntroDisplayed"
      @set-is-totp-intro-displayed="isTotpIntroDisplayed = $event"
      :totpToken="totpToken"
      @set-totp-token="totpToken = $event"
      :loading="$attrs.loading"
    />
  </div>
</template>
