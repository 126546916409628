var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_setup.route.params.id
    ? _c(_setup.Button, {
        class: { generating: _setup.generating },
        attrs: {
          clickable: "",
          icon: _setup.generating ? "loading-small" : "plus",
          text: "Generate",
          title: "New card",
          subtext: "Default $50 per month",
        },
        nativeOn: {
          click: function ($event) {
            return _setup.createNewIdentity()
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }