<template>
  <div class="restore-wrapper">
    <div class="restore-tables">
      <h1>Restore Identifiers</h1>
      <div>
        <h2>Numbers</h2>
        <table class="restore-table">
          <tr>
            <th style="width: 50px">Selected</th>
            <th>Number</th>
            <th>Deleted</th>
            <th>Website</th>
          </tr>
          <tr
            v-for="num in numbers"
            :key="num.id"
            style="cursor: pointer"
            @click="() => setSelectedNumber(num.id)"
          >
            <td style="width: 50px">
              <RadioButton
                :name="'select_number_' + num.id"
                :value="num.id"
                :checked="selectedNumber ? selectedNumber.id === num.id : false"
                @update="setSelectedNumber"
              />
            </td>
            <td>{{ num.phone_number }}</td>
            <td>{{ fmtDateTime(num.deleted_at) }}</td>
            <td>{{ num.website && num.website.name }}</td>
          </tr>
        </table>
      </div>
      <hr />
      <div>
        <h2>Emails</h2>
        <table class="restore-table">
          <tr>
            <th style="width: 50px">Selected</th>
            <th>Email</th>
            <th>Deleted</th>
            <th>Website</th>
          </tr>
          <tr
            v-for="em in emails"
            :key="em.id"
            style="cursor: pointer"
            @click="() => setSelectedEmail(em.id)"
          >
            <td style="width: 50px">
              <RadioButton
                :name="'select_email_' + em.id"
                :value="em.id"
                :checked="selectedEmail ? selectedEmail.id === em.id : false"
                @update="setSelectedEmail"
              />
            </td>
            <td>{{ em.email }}</td>
            <td>{{ fmtDateTime(em.deleted_at) }}</td>
            <td>{{ em.website && em.website.name }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="restore-sidebar">
      <div class="restore-title">
        <h2>Selected Number</h2>
        <p>
          <ChevronRight />{{ selectedNumber && selectedNumber.phone_number }}
        </p>
      </div>
      <hr />
      <div class="restore-title">
        <h2>Selected Email</h2>
        <p><ChevronRight />{{ selectedEmail && selectedEmail.email }}</p>
      </div>
      <hr />
      <div>
        <span>
          <Button
            :style="{
              cursor: hasSelection ? 'pointer' : 'auto',
            }"
            :disabled="!hasSelection"
            @click="restoreIdentifiers"
          >
            Restore to New Identity
          </Button>
          <Button
            :style="{
              cursor: hasSelection ? 'pointer' : 'auto',
            }"
            :disabled="!hasSelection"
            @click="clearSelections"
          >
            Clear Selection
          </Button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/routes/router";
import moment from "moment";
import { ChevronRight } from "@/assets/icons";
import { hash } from "@/scripts";
import { Button, RadioButton } from "@/components";
import RestoreIdentifiersService from "@/api/restore/restore-service";

export default {
  name: "Restore",

  data() {
    return {
      numbers: [],
      emails: [],
      selectedNumber: null,
      selectedEmail: null,
    };
  },

  beforeMount() {
    this.getIdentifiers();
  },

  components: {
    Button,
    ChevronRight,
    RadioButton,
  },

  computed: {
    hasSelection() {
      return this.selectedNumber || this.selectedEmail;
    },
  },

  methods: {
    fmtDateTime(value) {
      return moment(value).format("MMM Do, y h:mm a");
    },

    getIdentifiers() {
      return RestoreIdentifiersService.getIdentifiers().then(
        ({ numbers, emails }) => {
          this.numbers = numbers;
          this.emails = emails;
        }
      );
    },

    clearSelections() {
      this.selectedNumber = null;
      this.selectedEmail = null;
    },

    setSelectedNumber(numberId) {
      this.selectedNumber = this.numbers.find(({ id }) => id === numberId);
    },

    setSelectedEmail(emailId) {
      this.selectedEmail = this.emails.find(({ id }) => id === emailId);
    },

    restoreIdentifiers() {
      return RestoreIdentifiersService.restoreIdentifiers(
        this.selectedNumber,
        this.selectedEmail
      ).then((newIdentityId) => {
        const newPath = `/cloak/${hash.encode(newIdentityId)}`;
        router.push({ path: newPath });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.restore-wrapper {
  height: 100%;
  margin: 10px;
  display: flex;
  flex-direction: row;
  position: relative;

  h1 {
    font-size: 32px;
    font-style: bold;
  }

  h2 {
    font-size: 24px;
    font-style: bold;
  }

  hr {
    display: block;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 4px;
    margin-right: 4px;
    border-style: inset;
    border-width: 1px;
  }

  .restore-tables {
    flex: 2;
    position: relative;
    margin: 20px;
    background-color: $color-primary-0;
    justify-content: left;
    align-items: left;
    overflow-y: scroll;

    .restore-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 8px;

      th,
      td {
        border: 1px solid $color-primary-100;
        margin: 4px;
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  .restore-sidebar {
    flex: 1;
    position: relative;
    margin: 20px;

    .restore-title {
      margin-top: 8px;
      padding: 4px;

      svg {
        padding-top: 1px;
      }

      p {
        font-weight: 600;
      }
    }

    button {
      margin-right: 8px;
    }

    svg {
      margin-right: 6px;
    }
  }
}
</style>
