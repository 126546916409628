var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c(_setup.CardsListing),
      _c("main", [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(_setup.WalletSettings),
            _c(_setup.TransactionsListing),
            _setup.route.params.id && _setup.cardUrl
              ? _c("iframe", {
                  key: _setup.route.params.id,
                  staticClass: "card-iframe",
                  attrs: {
                    src: _setup.cardUrl,
                    width: "100%",
                    height: "100%",
                    frameborder: "0",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }