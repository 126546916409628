var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ModalTemplate", {
    key: "modal-import-processing",
    staticClass: "modal-import-processing",
    attrs: { show: _vm.visibleModals[_vm.params.id], width: 512 },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "modal-import-processing__header" },
              [
                _c(_vm.icon, {
                  tag: "Component",
                  staticClass: "modal-import-processing__icon",
                }),
                _c("h1", [_vm._v(_vm._s(_vm.title))]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("p", { staticClass: "modal-import-processing__subheader" }, [
                  _vm._v(" " + _vm._s(_vm.subheader) + " "),
                ]),
                _vm.hasImportSucceeded !== false
                  ? _c(
                      "Tip",
                      {
                        staticClass: "modal-import-processing__tip",
                        attrs: { type: "info" },
                      },
                      [
                        _c("div", [
                          _c("strong", [_vm._v("Suggestion:")]),
                          _vm._v(
                            " Delete the import file from your computer to keep your information secure. "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _vm.hasImportSucceeded !== false
              ? [
                  _c(
                    "Button",
                    {
                      attrs: { type: "secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.params.onImportMore()
                        },
                      },
                    },
                    [_vm._v(" Import More ")]
                  ),
                  _c(
                    "Button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.params.onDone()
                        },
                      },
                    },
                    [_vm._v(" Done ")]
                  ),
                ]
              : [
                  _c(
                    "Button",
                    {
                      attrs: { type: "secondary" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.params.onDone()
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "Button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.params.onImportMore()
                        },
                      },
                    },
                    [_vm._v(" Start Over ")]
                  ),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }