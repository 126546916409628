<template>
  <div
    aria-id="ContextMenu"
    ref="menu"
    class="context-menu"
    :style="location"
    :class="{ flipped: flipped }"
    v-click-outside="vcoConfig"
    @click="(event) => event.stopPropagation()"
  >
    <ul v-if="count < 2">
      <li v-if="!selected">
        <button aria-id="SelectContextMenu" @click="emit('select')">
          <span>
            <span class="context-menu__icon">
              <SelectIcon />
            </span>
            <span>Select</span>
          </span>
          <span>
            <span>hold</span>
            <CommandKeyIcon />
          </span>
        </button>
      </li>
      <li v-else>
        <button aria-id="UnselectContextMenu" @click="emit('unselect')">
          <span>
            <span class="context-menu__icon">
              <SelectIcon />
            </span>
            <span>Unselect</span>
          </span>
          <span>
            <span>hold</span>
            <CommandKeyIcon />
          </span>
        </button>
      </li>
      <li>
        <button aria-id="SelectAllContextMenu" @click="emit('selectAll')">
          <span>
            <span class="context-menu__icon">
              <CheckListIcon />
            </span>
            <span>Select All</span>
          </span>
          <span>
            <CommandKeyIcon />
            <UpKeyIcon />
            <span>A</span>
          </span>
        </button>
      </li>
      <li
        @mouseover="toggleCats(true)"
        @mouseout="toggleCats(false)"
        v-if="categories.length > 0"
      >
        <button aria-id="MoveToCategoryContextMenu">
          <span>
            <span class="context-menu__icon">
              <MoveIcon />
            </span>
            <span> Move To</span>
          </span>
          <span>
            <span>M</span>
            <ArrowKeyRightIcon />
          </span>
        </button>
        <div
          class="cats"
          :class="{ flipped: flipped_cats }"
          v-if="showCats"
          @mouseover="toggleCats(true)"
        >
          <ul>
            <li v-for="(cat, index) in categories" :key="index">
              <button
                :aria-id="`Category.${cat.name}ContextMenu`"
                @click="move(cat)"
              >
                <span class="context-menu__icon" v-if="isInCategory(cat)">
                  <CheckIcon />
                </span>
                {{ cat.name }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li v-if="can_mute && !is_muted">
        <button aria-id="MuteContextMenu" @click="emit('ignore')">
          <span>
            <span class="context-menu__icon">
              <MutedContextIcon />
            </span>
            <span>Ignore</span>
          </span>
          <span>
            <CommandKeyIcon />
            <span>M</span>
          </span>
        </button>
      </li>
      <li v-if="can_mute && is_muted">
        <button aria-id="MuteContextMenu" @click="emit('unignore')">
          <span>
            <span class="context-menu__icon">
              <UnmuteIcon />
            </span>
            <span>Unignore</span>
          </span>
          <span>
            <CommandKeyIcon />
            <span>M</span>
          </span>
        </button>
      </li>
      <li class="hr">
        <hr />
      </li>
      <li v-if="email">
        <button
          aria-id="CopyEmailContextMenu"
          @click="(e) => copy('email', email, e)"
        >
          <span>
            <span class="context-menu__icon">
              <CopyIcon />
            </span>
            <span>Copy Email Address</span>
          </span>
        </button>
      </li>
      <li v-if="phone">
        <button
          aria-id="CopyPhoneContextMenu"
          @click="(e) => copy('phone', phone, e)"
        >
          <span>
            <span class="context-menu__icon">
              <CopyIcon />
            </span>
            <span>Copy Phone Number</span>
          </span>
        </button>
      </li>
      <li v-if="password">
        <button @click="(e) => copy('password', password, e)">
          <span>
            <span class="context-menu__icon">
              <CopyIcon />
            </span>
            <span>Copy Password</span>
          </span>
        </button>
      </li>
      <li v-if="username">
        <button
          aria-id="CopyUsernameContextMenu"
          @click="(e) => copy('username', username, e)"
        >
          <span>
            <span class="context-menu__icon">
              <CopyIcon />
            </span>
            <span>Copy Username</span>
          </span>
        </button>
      </li>
      <li class="hr">
        <hr />
      </li>
      <li v-if="cloaked_email">
        <button aria-id="EmailContactContextMenu" @click="compose('email')">
          <span>
            <span class="context-menu__icon">
              <EmailOutlineIcon />
            </span>
            <span>Email Contact</span>
          </span>
        </button>
      </li>
      <li class="hr">
        <hr />
      </li>
      <li>
        <button aria-id="DeleteContextMenu" @click="emit('delete')">
          <span>
            <span class="context-menu__icon">
              <DeleteActionBarIcon class="delete-icon" />
            </span>
            <span>Delete</span>
          </span>
        </button>
      </li>
    </ul>
    <ul v-else>
      <li v-if="selected">
        <button aria-id="UnselectContextMenu" @click="emit('unselect')">
          <span>
            <span class="context-menu__icon">
              <SelectIcon />
            </span>
            <span>Unselect</span>
          </span>
          <span>
            <span>hold</span>
            <CommandKeyIcon />
          </span>
        </button>
      </li>
      <li>
        <button aria-id="SelectAllContextMenu" @click="emit('selectAll')">
          <span>
            <span class="context-menu__icon">
              <CheckListIcon />
            </span>
            <span>Select All</span>
          </span>
          <span>
            <span>hold</span>
            <CommandKeyIcon />
          </span>
        </button>
      </li>
      <li v-if="count > 1">
        <button aria-id="UnselectAllContextMenu" @click="emit('unselectAll')">
          <span>
            <span class="context-menu__icon">
              <CheckListIcon />
            </span>
            <span>Unselect All</span>
          </span>
          <span>
            <CommandKeyIcon />
            <UpKeyIcon />
            <span>A</span>
          </span>
        </button>
      </li>
      <li
        @mouseover="toggleCats(true)"
        @mouseout="toggleCats(false)"
        v-if="categories.length > 0"
      >
        <button aria-id="MoveToCategoryContextMenu">
          <span>
            <span class="context-menu__icon">
              <MoveIcon />
            </span>
            <span> Move To</span>
          </span>
          <span>
            <span>M</span>
            <ArrowKeyRightIcon />
          </span>
        </button>
        <div
          class="cats"
          :class="{ flipped: flipped_cats }"
          v-if="showCats"
          @mouseover="toggleCats(true)"
        >
          <ul>
            <li v-for="(cat, index) in categories" :key="index">
              <button
                :aria-id="`Category.${cat.name}ContextMenu`"
                @click="move(cat)"
              >
                {{ cat.name }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <button
          aria-id="MuteContextMenu"
          @click="$emit(`${ignoreDisplay.toLowerCase()}`)"
        >
          <span>
            <span class="context-menu__icon">
              <MutedContextIcon
                v-if="ignoreDisplay.toLowerCase() === 'ignore'"
              />
              <UnmuteIcon v-else />
            </span>
            <span>{{ ignoreDisplay }}</span>
          </span>
          <span>
            <CommandKeyIcon />
            <span>M</span>
          </span>
        </button>
      </li>
      <li class="hr">
        <hr />
      </li>
      <li>
        <button aria-id="DeleteConextMenu" @click="emit('delete')">
          <span>
            <span class="context-menu__icon">
              <DeleteActionBarIcon />
            </span>
            <span>Delete</span>
          </span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "v-click-outside";
import { copy } from "@/scripts/tools";
import { analyticsExport } from "@/mixins/Analytics";
import { authDecrypt } from "@/scripts/actions/encryption";
import {
  CommandKeyIcon,
  UnmuteIcon,
  SelectIcon,
  CheckListIcon,
  UpKeyIcon,
  MoveIcon,
  DeleteActionBarIcon,
  MutedContextIcon,
  ArrowKeyRightIcon,
  EmailOutlineIcon,
  CopyIcon,
  CheckIcon,
} from "@/assets/icons";

export default {
  props: [
    "isMultiselect",
    "event",
    "identity",
    "selected",
    "count",
    "ignoreDisplay",
  ],
  name: "ContextMenu",
  directives: {
    ClickOutside: ClickOutside.directive,
  },
  components: {
    CommandKeyIcon,
    UnmuteIcon,
    SelectIcon,
    CheckListIcon,
    UpKeyIcon,
    MoveIcon,
    DeleteActionBarIcon,
    MutedContextIcon,
    ArrowKeyRightIcon,
    EmailOutlineIcon,
    CopyIcon,
    CheckIcon,
  },
  data() {
    return {
      showCats: false,
      location: {},
      flipped: false,
      password: null,
      flipped_cats: false,
      lock: false,
    };
  },
  mounted() {
    setTimeout(() => {
      window.addEventListener("contextmenu", this.hideMenu);
    }, 100);

    window.addEventListener("keydown", this.handleWindowKeydown);

    const password = this.get_password();
    if (password) {
      authDecrypt(password).then((text) => {
        this.password = text;
      });
    }
    this.$nextTick(() => {
      const clientX = this.event.clientX;
      const clientY = this.event.clientY;
      const pageY = this.event.pageY;
      const screenH = window.innerHeight;
      const screenW = window.innerWidth;
      const menuH = (this.$refs.menu && this.$refs.menu.offsetHeight) || 0;
      const menuW = (this.$refs.menu && this.$refs.menu.offsetWidth) || 0;
      let left = clientX + 2;
      let top = pageY + 2;

      if (clientX + (menuW + 5) > screenW) {
        left = clientX - (menuW + 2);
        this.flipped = true;
        this.flipped_cats = true;
      } else if (clientX + (menuW + 120 + 5) > screenW) {
        this.flipped_cats = true;
      }
      if (clientY + (menuH + 5) > screenH) {
        top = pageY - (menuH + 2);
      }
      this.location = {
        top: `${top}px`,
        left: `${left}px`,
      };
    });
  },
  beforeDestroy() {
    window.removeEventListener("contextmenu", this.hideMenu);
    window.removeEventListener("keydown", this.handleWindowKeydown);

    this.location = {};
    this.flipped = false;
    this.password = null;
  },
  computed: {
    is_muted() {
      return this.identity.muted;
    },
    can_mute() {
      return this.identity.is_cloaked_phone || this.identity.is_cloaked_email;
    },
    categories() {
      return this.$store.state.categories.custom;
    },
    email() {
      return this.identity.email;
    },
    phone() {
      return this.identity.phone;
    },
    cloaked_email() {
      if (this.identity.cloaked_email) {
        return this.identity.email;
      }
      return null;
    },
    cloaked_phone() {
      if (this.identity.cloaked_phone) {
        return this.identity.phone;
      }
      return null;
    },
    username() {
      return this.identity.username;
    },
  },
  methods: {
    get_password() {
      return (
        (this.identity.stored_autofill &&
          this.identity.stored_autofill.password) ||
        (this.identity.stored_password &&
          this.identity.stored_password.password)
      );
    },
    get_cloaked_objects() {
      const email = this.identity.cloaked_email;
      const phone =
        this.identity.number_for_personal ||
        this.identity.number_for_app ||
        this.identity.cloaked_phone;
      return { phone, email };
    },
    vcoConfig() {
      return {
        handler: this.hideMenu,
        events: ["dblclick", "click"],
        isActive: true,
        detectIFrame: true,
        capture: false,
      };
    },
    isInCategory(cat) {
      if (this.identity.categories) {
        return this.identity.categories.includes(cat.id);
      }
      return false;
    },
    toggleCats(status) {
      this.lock = status;
      if (status) {
        this.showCats = true;
      } else {
        setTimeout(() => {
          if (!this.lock) {
            this.showCats = false;
          }
        }, 200);
      }
    },

    handleWindowKeydown(event) {
      if (event && event.keyCode === 27) {
        this.hideMenu();
      }
    },

    hideMenu() {
      this.$emit("close");
    },
    emit(event) {
      this.$emit(event);
    },
    move(category) {
      this.$emit("move", category);
    },
    compose() {
      this.$store.commit("compose", this.identity);
      this.$emit("close");
    },
    copy(field, data, e) {
      let payload = {
        field_copied: field,
        cloak_name: this.identity.nickname
          ? this.identity.nickname
          : this.identity.app_ref,
        cloak_tags: this.identity.tags,
        cloak_url: this.identity.url,
        cloak_id: this.identity.id,
      };
      analyticsExport.methods.sendEvent("Copy Information", payload);
      copy(data, e);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.context-menu {
  background-color: $color-background;
  padding: 5px;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: absolute;
  z-index: 100;
  fill: $color-primary-70;

  &__icon {
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    display: flex;
    flex-direction: column;
    li {
      &.hr {
        margin: 0;
        padding: 0;
        hr {
          display: block;
          height: 1px;
          border: 0;
          border-top: 1px solid $color-primary-5;
          margin: 0;
          padding: 0;
        }
      }

      .cats {
        position: absolute;
        box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        color: $color-primary-0;
        padding: 4px;
        left: calc(100%);
        width: 150px;
        white-space: nowrap;
        max-height: 300px;
        overflow: hidden;
        overflow-y: scroll;
        &.flipped {
          left: -150px !important;
        }
        background-color: $color-primary-0;
        margin-top: -30px;
        button {
          width: 150px;
          border-radius: 8px;
          text-align: left !important;
          background-color: transparent;
          display: block;
          width: 100%;
          padding: 5px 24px;
          height: auto;
          border: none;
          overflow: hidden;
          text-overflow: ellipsis;
          svg {
            position: absolute;
            left: 9px;
            margin-top: 30px;
          }
          &:hover {
            background-color: $color-primary-5;
          }
        }
      }
      button {
        margin: 3px 0;
        width: 100%;
        border: none;
        display: flex;
        border-radius: 8px;
        justify-content: space-between;
        background-color: transparent;
        cursor: pointer;
        color: $color-primary-100;

        > span {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          padding: 4px 8px 4px 0;
          gap: 6px;
          &:nth-child(2) {
            display: none;
            span {
              font-size: 11px;
              color: $color-primary-100-60;
              opacity: 0.8;
              text-transform: uppercase;
            }
          }
        }
        &:hover {
          background-color: $color-primary-5;
        }
      }
    }
  }
}
.delete-icon {
  height: 14px;
}
</style>
