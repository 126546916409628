var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "settings" }, [
    _c("header", [
      _c(
        "div",
        { staticClass: "title" },
        [
          _setup.route.params.id
            ? _c(
                "router-link",
                { staticClass: "back-button", attrs: { to: "/wallet" } },
                [_c(_setup.InlineSvg, { attrs: { name: "chevron-left" } })],
                1
              )
            : _c("h1", [_vm._v("Wallet settings")]),
          _c("div", { staticClass: "icons" }, [
            _setup.route.params.id && _setup.card
              ? _c(
                  "div",
                  {
                    staticClass: "icon",
                    on: {
                      click: function ($event) {
                        _setup.openIdentity(
                          _setup.identity(_setup.card.identity_id)
                        )
                      },
                    },
                  },
                  [
                    _c(_setup.InlineSvg, {
                      attrs: { name: "users-circle-icon" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _setup.route.params.id && _setup.card
        ? _c(
            "div",
            {
              staticClass: "identity",
              on: {
                click: function ($event) {
                  _setup.openIdentity(_setup.identity(_setup.card.identity_id))
                },
              },
            },
            [
              _c(_setup.IdentityIcon, {
                attrs: {
                  identity: _setup.identity(_setup.card.identity_id),
                  override: { height: "32px", width: "32px" },
                },
              }),
              _c("div", { staticClass: "info" }, [
                _c("h1", [
                  _vm._v(
                    _vm._s(
                      _setup.identity(_setup.card.identity_id)?.nickname ||
                        "New identity"
                    )
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "buttons" },
      [
        !_setup.route.params.id ? _c(_setup.CreateCard) : _vm._e(),
        _setup.route.params.id ? _c(_setup.AvailableSpendindButton) : _vm._e(),
        _setup.route.params.id ? _c(_setup.SpendingLimit) : _vm._e(),
        !_setup.route.params.id ? _c(_setup.DefaultFundingSource) : _vm._e(),
        _setup.route.params.id ? _c(_setup.SelfDestruct) : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }