var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesPanel",
    {
      staticClass: "export-data",
      scopedSlots: _vm._u(
        [
          !_vm.navDisabled
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c("PreferencesHeader", {
                      on: { "go-back": _vm.handleGoBack },
                    }),
                  ]
                },
                proxy: true,
              }
            : null,
          {
            key: "footer",
            fn: function () {
              return [
                _vm.currentStep === "password"
                  ? _c(
                      "PreferencesFooter",
                      [
                        _c(
                          "Button",
                          {
                            attrs: {
                              disabled: _vm.loadingPassword || !_vm.password,
                              loading: _vm.loadingPassword,
                            },
                            on: { click: _vm.validatePassword },
                          },
                          [_vm._v("Continue")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.currentStep === "export"
                  ? _c(
                      "PreferencesFooter",
                      [
                        _c(
                          "Button",
                          {
                            attrs: {
                              type: "secondary",
                              disabled:
                                _vm.loadingIdentities || !_vm.downloadReady,
                              loading: _vm.loadingIdentities,
                            },
                            on: { click: _vm.handleDownloadAgain },
                          },
                          [_vm._v(" Download again ")]
                        ),
                        _c(
                          "Button",
                          {
                            attrs: {
                              loading: _vm.loadingIdentities,
                              disabled: _vm.loadingIdentities,
                            },
                            on: { click: _vm.handleDone },
                          },
                          [_vm._v(" Done ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [
      _vm.currentStep === "password"
        ? [
            _c("PreferencesTitle", [_vm._v("Export my data")]),
            _c("PreferencesParagraph", [
              _vm._v(" Please enter your password to continue. "),
            ]),
            _c("PreferencesInput", {
              attrs: {
                label: "Password",
                type: "password",
                placeholder: "Your Password",
                error: _vm.invalidPassword,
                disabled: _vm.loadingPassword,
              },
              on: { save: _vm.validatePassword },
              model: {
                value: _vm.password,
                callback: function ($$v) {
                  _vm.password = $$v
                },
                expression: "password",
              },
            }),
          ]
        : _vm._e(),
      _vm.currentStep === "options"
        ? [
            _c("PreferencesTitle", [_vm._v("Choose data to export")]),
            _c(
              "div",
              { staticClass: "export-data__options" },
              [
                _c("ValueDisplay", {
                  attrs: {
                    label: "Identity data",
                    value:
                      "Passwords, email addresses, phone numbers, usernames, notes",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setOption(_vm.options.IDENTITY)
                    },
                  },
                }),
                _c("ValueDisplay", {
                  attrs: {
                    label: "Inbox data (large file size)",
                    value: "Emails, text messages, calls",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setOption(_vm.options.ACTIVITY)
                    },
                  },
                }),
                _c("ValueDisplay", {
                  attrs: { label: "All data (large file size)" },
                  on: {
                    click: function ($event) {
                      return _vm.setOption(_vm.options.ALL)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.currentStep === "export"
        ? [
            _c(
              "div",
              { staticClass: "header-icon-row" },
              [
                !_vm.downloadReady
                  ? _c("HourglassRound")
                  : _c("DownloadRound", { staticClass: "success" }),
              ],
              1
            ),
            !_vm.downloadReady
              ? [
                  _c("PreferencesTitle", { attrs: { big: "" } }, [
                    _vm._v(" Getting your data ready for export "),
                  ]),
                  _c("PreferencesParagraph", [
                    _vm._v(
                      " While most exports finish in about 5 minutes, this might take a while. Feel free to leave this tab open and return later. "
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm.downloadReady
              ? [
                  _c("PreferencesTitle", { attrs: { big: "" } }, [
                    _vm._v("Export completed"),
                  ]),
                  _c("PreferencesParagraph", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.totalIdentities) +
                        " Cloaked identities exported successfully. "
                    ),
                  ]),
                ]
              : _vm._e(),
            _c("UiProgress", {
              attrs: {
                label: _vm.progress.label,
                counter: _vm.progress.counter,
                "counter-total": _vm.progress.counterTotal,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }