var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "input-checkbox",
      class: { "input-checkbox--disabled": _vm.disabled },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checkboxModel,
            expression: "checkboxModel",
          },
        ],
        staticClass: "input-checkbox__input",
        attrs: { type: "checkbox", id: _vm.id, disabled: _vm.disabled },
        domProps: {
          value: _vm.id,
          checked: Array.isArray(_vm.checkboxModel)
            ? _vm._i(_vm.checkboxModel, _vm.id) > -1
            : _vm.checkboxModel,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.checkboxModel,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = _vm.id,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checkboxModel = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checkboxModel = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checkboxModel = $$c
            }
          },
        },
      }),
      _c("CheckboxChecked", { staticClass: "input-checkbox__input--checked" }),
      _c("CheckboxUnchecked", {
        staticClass: "input-checkbox__input--unchecked",
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }