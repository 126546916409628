var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "progress", class: { hidden: _vm.currentStep === 4 } },
    [
      _c("h1", [_vm._v(_vm._s(_setup.props.title))]),
      _c("div", { staticClass: "progress-bar" }, [
        _c("div", {
          staticClass: "progress-bar__inner",
          style: {
            width:
              (_setup.props.currentStep / _setup.props.maxSteps) * 100 + "%",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }