var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ModalTemplate, {
    staticClass: "num-locking-warning",
    attrs: { show: true },
    on: { close: _setup.onCancel },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("div", {}, [
              _c("div", [
                _c("h1", [_vm._v(" " + _vm._s(_setup.TITLE[_vm.mode]) + " ")]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "num-body" }, [
              _c("p", [_vm._v(" " + _vm._s(_setup.BODY_1[_vm.mode]) + " ")]),
              _c("p", [_vm._v(" " + _vm._s(_setup.BODY_2[_vm.mode]) + " ")]),
              _c("p", [
                _c(
                  "a",
                  {
                    staticClass: "learn-more",
                    attrs: {
                      href: "https://www.cloaked.app/faq/what-is-number-locking-and-how-do-i-use-it",
                      target: "_blank",
                    },
                  },
                  [_vm._v(" Learn more about number locking ")]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            [
              _c(
                "div",
                { staticClass: "flex-footer" },
                [
                  _c(
                    _setup.PreferencesCheckParagraph,
                    {
                      staticClass: "footer-center",
                      model: {
                        value: _setup.shouldDismissWarning,
                        callback: function ($$v) {
                          _setup.shouldDismissWarning = $$v
                        },
                        expression: "shouldDismissWarning",
                      },
                    },
                    [
                      _c("span", { staticClass: "dismiss-warn" }, [
                        _vm._v("Don't show this again"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        _setup.Button,
                        {
                          attrs: { type: "secondary" },
                          on: { click: _setup.onCancel },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(_setup.Button, { on: { click: _setup.onContinue } }, [
                        _vm._v(" Continue "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }