var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.AppModal,
    _vm._g(_vm._b({}, "AppModal", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        _setup.AppModalContent,
        [
          _c(_setup.AppModalImage, {
            attrs: {
              src: require("@/assets/images/migration-announcement.png"),
              alt: "Security upgrade required",
            },
          }),
          _c(_setup.AppModalTitle, [_vm._v(" Security upgrade required ")]),
          _c(_setup.AppModalParagraph, [
            _vm._v(
              " Hey " +
                _vm._s(_vm.username) +
                ", to continue using Cloaked, you’ll need to complete a quick security upgrade. To begin, you’ll need to download your secret key and use it to create a new master password. "
            ),
          ]),
          _c(_setup.AppModalParagraph, [
            _vm._v(
              " This process should take less than 5 minutes to complete. "
            ),
          ]),
          _c(
            _setup.AppModalFooter,
            [
              _c(
                _setup.Button,
                {
                  on: {
                    click: function ($event) {
                      _setup.emit("input", false)
                      _setup.emit("close")
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.isV1User ? "Download and continue" : "Continue")
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }