var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "activity-action-bar-wrapper" }, [
    _c(
      "div",
      { staticClass: "activity-action-bar-top flex-row-center" },
      [
        _vm.searchEnabled
          ? _c(
              "div",
              { staticClass: "activity-search-bar" },
              [
                _c("input", {
                  ref: "searchInput",
                  attrs: { "aria-id": "ActivityInputSearchBar" },
                  domProps: { value: _vm.searchText },
                  on: {
                    input: (e) => _vm.$emit("setSearchText", e.target.value),
                  },
                }),
                _c("SearchIcon", {
                  staticClass: "activity-action-input-search",
                }),
                _c("CloseXIcon", {
                  staticClass: "activity-action-input-x",
                  on: { click: _vm.closeSearch },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.searchEnabled
          ? _c("div", { staticClass: "flex-row-center activity-title" }, [
              _c("h1", [_vm._v("Inbox")]),
              _c(
                "div",
                {
                  staticClass: "activity-action-icons",
                  attrs: { "aria-id": "ActivitySearchButton" },
                },
                [
                  _c(
                    "UiTooltip",
                    { attrs: { title: "Search", "align-x": "center" } },
                    [
                      _c(
                        "span",
                        { on: { click: _vm.showSearch } },
                        [_c("SearchIcon")],
                        1
                      ),
                    ]
                  ),
                  false
                    ? _c(
                        "UiTooltip",
                        { attrs: { title: "Filters", "align-x": "center" } },
                        [_c("span", [_c("FilterIcon")], 1)]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c(
          "UiMenu",
          {
            attrs: { width: "188px", placement: "bottom-end" },
            on: {
              click: function ($event) {
                _vm.shouldShowTooltipOnHover = !_vm.shouldShowTooltipOnHover
              },
            },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c("UiMenuButton", {
                      attrs: {
                        "aria-id": "ActivitySelect",
                        title: `Select messages`,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("setMultiSelect", true)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [_c("SelectMessagesIcon")]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("UiMenuButton", {
                      attrs: {
                        "aria-id": "ActivitySelectAll",
                        title: `Select all`,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("toggleSelectAll")
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [_c("SelectAllIcon")]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("UiMenuButton", {
                      attrs: {
                        "aria-id": "ActivityMarkRead",
                        title: `Mark as ${_vm.readOrUnread}`,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("markAsRead", _vm.readOrUnread)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [_c("ActivityMarkAsReadIcon")]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("UiMenuSeparator"),
                    _c(
                      "div",
                      { staticClass: "alert-text" },
                      [
                        _c("UiMenuButton", {
                          attrs: {
                            "aria-id": "ActivityDelete",
                            title: "Delete",
                            danger: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("showDeleteModal")
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function () {
                                return [_c("DeleteTrashIcon")]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "UiTooltip",
              {
                staticClass: "activity-action-kabob-icon",
                attrs: {
                  "aria-id": "ActivitySearchKabob",
                  title: _vm.shouldShowTooltipOnHover ? "More" : "",
                  "align-x": "center",
                },
              },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.toggleShouldShowTooltip,
                        expression: "toggleShouldShowTooltip",
                      },
                    ],
                  },
                  [_c("KabobIcon")],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm.multiSelectEnabled
      ? _c(
          "div",
          {
            staticClass:
              "activity-action-icons activity-action-multiselect flex-row-center",
          },
          [
            _c(
              "div",
              { staticClass: "flex-row-center" },
              [
                _c(
                  "UiTooltip",
                  {
                    attrs: {
                      "aria-id": "ActivitySelectAllIcon",
                      title: _vm.selectTooltipText,
                      "align-x": "center",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("toggleSelectAll")
                          },
                        },
                      },
                      [
                        _vm.selectTooltipText.toLowerCase().includes("deselect")
                          ? _c("MinusRound")
                          : _c("EmptyRound"),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "UiTooltip",
                  {
                    attrs: {
                      "aria-id": "ActivityMarkReadIcon",
                      title: `Mark as ${_vm.readOrUnread}`,
                      "align-x": "center",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("markAsRead", _vm.readOrUnread)
                          },
                        },
                      },
                      [_c("MarkAsReadIcon")],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "border" }),
                _c(
                  "UiTooltip",
                  {
                    attrs: {
                      "aria-id": "ActivityDeleteIcon",
                      title: "Delete",
                      "align-x": "center",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("showDeleteModal")
                          },
                        },
                      },
                      [_c("DeleteIcon")],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }