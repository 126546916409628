<template>
  <div class="password-check" :class="passwordStrength">
    <ul>
      <li
        :class="{
          success: passwordHasCorrectLength,
          error: passwordHasValue && !passwordHasCorrectLength,
        }"
      >
        <Check v-if="passwordHasCorrectLength" />
        <span v-else />

        Between 12 and 256 characters
      </li>
      <li
        :class="{
          success: passwordHasUpperCase,
          error: passwordHasValue && !passwordHasUpperCase,
        }"
      >
        <Check v-if="passwordHasUpperCase" />
        <span v-else />
        1 Uppercase letter
      </li>
      <li
        :class="{
          success: passwordHasLowerCase,
          error: passwordHasValue && !passwordHasLowerCase,
        }"
      >
        <Check v-if="passwordHasLowerCase" />
        <span v-else />
        1 Lowercase letter
      </li>
      <li
        :class="{
          success: passwordHasNumber,
          error: passwordHasValue && !passwordHasNumber,
        }"
      >
        <Check v-if="passwordHasNumber" />
        <span v-else />
        1 Number
      </li>
      <li
        :class="{
          success: passwordHasSymbol,
          error: passwordHasValue && !passwordHasSymbol,
        }"
      >
        <Check v-if="passwordHasSymbol" />
        <span v-else />
        1 Symbol
      </li>
    </ul>
  </div>
</template>

<script>
import Check from "@/assets/icons/check.svg";
export default {
  name: "PasswordStrength",
  emits: ["passwordStrengthVal", "passwordStrengthCheck"],
  props: ["password"],
  components: { Check },
  computed: {
    passwordStrength() {
      let strengthCount = 0;
      if (this.passwordHasCorrectLength) {
        strengthCount += 1;
      }
      if (this.passwordHasUpperCase && this.passwordHasLowerCase) {
        strengthCount += 1;
      }
      if (this.passwordHasNumber && this.passwordHasSymbol) {
        strengthCount += 1;
      }
      if (strengthCount === 3) {
        this.$emit("passwordStrengthCheck", true);
      } else {
        this.$emit("passwordStrengthCheck", false);
      }
      return ["", "one", "two", "three"][strengthCount];
    },
    passwordHasCorrectLength() {
      const userPassword = this.password || "";
      return userPassword.length > 11 && userPassword.length <= 256;
    },
    passwordHasUpperCase() {
      const userPassword = this.password || "";
      return userPassword.match(/[A-Z]+/);
    },
    passwordHasLowerCase() {
      const userPassword = this.password || "";
      return userPassword.match(/[a-z]/);
    },
    passwordHasNumber() {
      const userPassword = this.password || "";
      return userPassword.match(/[0-9]/);
    },
    passwordHasSymbol() {
      const userPassword = this.password || "";
      return userPassword.match(/[ !"#$%&'()^*+,-./:;<=>?@[\]_`{|}~\\]/);
    },
    passwordHasValue() {
      return this.password;
    },
  },
};
</script>

<style scoped lang="scss">
.password-check {
  font-size: 13px;
  font-family: $poppins;
}

ul {
  li {
    &.success {
      color: $color-success !important;
    }
    &.error {
      color: $color-alert !important;
    }
    svg {
      margin-right: 4px;
    }
    span {
      display: inline-block;
      height: 4px;
      width: 4px;
      border-radius: 4px;
      background-color: $color-primary-100;
      margin: 3px 6px;
    }
  }
}
</style>
