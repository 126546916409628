var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.Button, {
        attrs: {
          loading: _setup.loading,
          icon: "spending-limit",
          text: "Spending limit",
          title: _setup.title,
          subtext: _setup.subtext,
          clickable: "",
        },
        nativeOn: {
          click: function ($event) {
            return _setup.openModal()
          },
        },
      }),
      _c(_setup.SpendingLimitModal, {
        attrs: { show: _setup.show },
        on: {
          close: function ($event) {
            return _setup.closeModal()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }