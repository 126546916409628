var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [
        _vm._v(
          " THESE TERMS OF SERVICE ARE A LEGAL AGREEMENT BETWEEN THE SERVICE USER AND CLOAKED, INC., AND GOVERNS YOUR USE OF THE SERVICES. CAREFULLY READ THIS AGREEMENT, THE CLOAKED PRIVACY POLICY, AND PRODUCT-SPECIFIC DISCLOSURES, ALL OF WHICH ARE PART OF AND GOVERNED BY THIS AGREEMENT. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " BY USING OR ACCESSING THE SERVICES YOU ARE BOUND BY THIS AGREEMENT. IF YOU DO NOT AGREE, OR ARE BELOW THE AGE OF MAJORITY IN THE JURISDICTION YOU ARE LOCATED, YOU ARE NOT PERMITTED TO USE THE SERVICES. CLOAKED IS NOT LIABLE SERVICE USE, OR CONTENT VIEWED BY, MINOR CHILDREN, IN VIOLATION OF THIS AGREEMENT. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " WE WILL USE THE FOLLOWING DEFINITIONS THROUGHOUT THE TERMS OF SERVICE: "
        ),
      ]),
      _c("p", [
        _vm._v(
          " “AGREEMENT” MEANS THE LEGAL AGREEMENT ENTITLED “TERMS OF SERVICE” ENTERED INTO BETWEEN CLOAKED AND USER AND INCLUDES THE CLOAKED PRIVACY POLICY AND PRODUCT-SPECIFIC DISCLOSURES. "
        ),
      ]),
      _c("p", [
        _vm._v(" “CLOAKED,” “WE,” “US” and “OUR” MEANS CLOAKED, INC. "),
      ]),
      _c("p", [
        _vm._v(
          " “USER,” “YOU” and “YOUR” MEANS THE PERSON USING THE SERVICES. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " “SERVICE(S)” MEANS USE OF THE SITE, INCLUDING THE ASSOCIATED SITES, SERVICES, APPLICATIONS, FEATURES AND INFORMATION AVAILABLE ON THE SITE AND MOBILE APPLICATIONS WE OFFER. "
        ),
      ]),
      _c("p", [
        _vm._v("“SITE” MEANS https://cloaked.app/ AND RELATED SUBDOMAINS."),
      ]),
      _c("p", [
        _vm._v(
          " “CLOAKED CARD” MEANS AND REFERS TO USE OF CLOAK PAY, LLC PRODUCTS AND SERVICES. "
        ),
      ]),
      _c("p", [_vm._v("“PRIVACY POLICY” MEANS THE CLOAKED PRIVACY POLICY.")]),
      _c("p", [
        _vm._v(
          ' "YOUR INFORMATION” IS DEFINED AS INFORMATION YOU PROVIDED TO CLOAKED, OR PROVIDED CLOAKED ACCESS TO, INCLUDING INFORMATION RELATED TO: THE REGISTRATION OR TRANSACTION PROCESS, YOUR USER ACCOUNT, AND SERVICE-RELATED EMAIL OR FEATURE. '
        ),
      ]),
      _c("p", [
        _vm._v(
          " 1. Changes to this Agreement. Cloaked reserves the right to modify these terms, with a limited exception for certain Arbitration provisions. Cloaked will use reasonable efforts to provide notification of material changes by email, website posting, or in-service notice. Cloaked will determine what changes are material in its sole discretion. Visit this page to learn if terms have changed. Your continued use of the Service constitutes acceptance of changes. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2. Access to the Service. ‍ 2.1. Cloaked grants to you a non-exclusive, non-transferable, revocable limited license to use the Service for your personal non-commercial use. You agree not to use the Service for any other purpose, or to download, save, copy or distribute content on the Service not owned by you. ‍ 2.2. Cloaked may change, modify, suspend, or discontinue Service(s) at any time. Cloaked may also impose limits on features, or restrict all or part of your access to Service(s) at any time, in our own discretion, without notice or liability. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2.3. Your use of the Service is conditioned upon compliance with this Agreement. Use of Cloaked Service(s) in violation of this Agreement may constitute infringement of Cloaked’s copyright. Cloaked reserves the right to terminate your access to the Service without notice if you violate this Agreement or for any reason, at Cloaked’s discretion. Cloaked or its vendors may require proof of identity to access or use the Service. You agree that you may be denied access if you refuse to provide proof of identity. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2.4. To access certain features and services, you may be required to pay a fee. We may change fees at any time. "
        ),
      ]),
      _c("p", [_vm._v("3. Ownership of Intellectual Property.")]),
      _c("p", [
        _vm._v(
          " 3.1. The Service and all associated materials are owned, controlled, or licensed by Cloaked and its licensors, and are protected from unauthorized use. The Service(s) are copyrighted under the U.S. copyright laws and/or similar laws of other jurisdictions. Cloaked, and the Cloaked logos, are trademarks of Cloaked, and may not be used without the express written permission. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3.2. You do not acquire ownership rights by using the Service, or by copying or downloading material from the Service. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3.3. You agree not to copy, redistribute, publish or otherwise exploit the Service without the express prior written permission of Cloaked. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3.4. You hereby grant to Cloaked a limited, non-exclusive, worldwide, perpetual, irrevocable, royal-free, sublicensable (through multiple tiers), and transferable right and license to use all comments, feedback, suggestions, ideas, emails, and other submissions disclosed or submitted to Cloaked in connection with your use of the Service (collectively, “Submissions”) in any manner Cloaked may desire, including, but not limited to, to make, have made, use, sell, offer to sell, import, reproduce, modify, create derivative versions of, distribute, publicly display and publicly perform the Submissions, in any and all forms and media now known or hereafter devised, without compensation to you and without identifying you as the creator. You agree that the provisions in this Section 3 will survive any termination of your account(s), the Service, or this Agreement. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3.5. You hereby waive and release any claims you may have against Cloaked for any damages, costs or liabilities you incur from Cloaked's use of Your Information, including but not limited to damages caused by any distortion, alteration, composite use, whether intentional or otherwise, that may occur in making, processing, duplicating, distributing or displaying Your Information. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 3.6. You agree to include, and to not remove or alter, Cloaked’s trademark, copyright or other proprietary rights notices, as provided by Cloaked on or in connection with the Service, when using or sharing content, or otherwise using the Service, and you agree to comply with usage guidelines that may be provided by Cloaked from time to time. You agree that all goodwill that arises in connection with your use of Cloaked trademarks inures exclusively to Cloaked, and you agree not to challenge Cloaked’s ownership or control of Cloaked trademarks, nor use or adopt trademarks that might be confusingly similar to the Cloaked trademarks. "
        ),
      ]),
      _c("p", [_vm._v("4. Terms for App Store Apps")]),
      _c("p", [
        _vm._v(
          " 4.1. If you accessed or downloaded the App from the Apple Store, you agree to use the App only: (1) on an Apple-branded product or device that runs iOS (Apple’s proprietary operating system software); and (2) as permitted by the “Usage Rules” set forth in the Apple Store Terms of Service as of the effective date of this Agreement. You further acknowledge and agree that: "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4.2. This Agreement is between you and Cloaked, and not with App Provider, and that, as between Cloaked and the App Provider, Cloaked is solely responsible for the App. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4.3. The App Provider has no obligation to provide App maintenance and support services. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4.4. In the event of App failure to conform to applicable warranties, you may notify App Provider and App Provider will refund to you purchase price you paid for the App (if applicable) and, to the maximum extent permitted by applicable law, App Provider will have no other warranty obligation whatsoever with respect to the App. Other claims, losses, liabilities, damages, costs or expenses attributable to failure of the App to conform to warranty will be Cloaked’s responsibility. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4.5. App Provider is not responsible for addressing claims you or third party may have relating to the App, including, but not limited to: (1) product liability claims; (2) claim(s) that the App fails to conform to applicable legal or regulatory requirement; and (3) claims arising under consumer protection or similar legislation. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4.6. In the event of a third-party claim that the App infringes third party’s intellectual property rights, Cloaked will be responsible for the investigation and defense of such intellectual property infringement claim to the extent required by this Agreement. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4.7. App Provider and its subsidiaries are third-party beneficiaries of this Agreement as related to your license of the App, and, upon your acceptance of this Agreement, App Provider will have the right (and will be deemed to have accepted the right) to enforce this Agreement as related to your license. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 4.8. You agree to comply with U.S. and foreign export laws and regulations to ensure that neither the App nor technical data related thereto is exported or re-exported directly or indirectly in violation of, or used for purposes prohibited by, the laws and regulations. By using the App you represent and warrant that: (1) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (2) you are not listed on any U.S. Government list of prohibited or restricted parties. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 5. Terms for Cloaked Card. If you enter into a Cloaked Card Agreement with Cloaked or Cloaked Pay, LLC and utilize the Services in relation to the Cloaked Card, you agree to comply with the terms of that agreement, as well as this Agreement. If a conflict between the two agreements exists, the Cloaked Card Agreement and associated disclosures prevail. You agree that there may be varying Charges associated with Cloaked Card products and services, or Cloaked may include Cloaked Card in the Cloaked subscription pricing, in Cloaked’s sole discretion. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 6. Passwords. Cloaked allows you to record and store information in your account. You are responsible for actions on the Service under your password or account. You must take reasonable steps to ensure that no unauthorized person accesses your password or account. You are responsible for all actions taken by individuals who use the Service through your password or account. It is your sole responsibility to (1) control the dissemination and use of any login code and password; (2) authorize, monitor, and control access to and use of your Service account and password; (3) promptly inform Cloaked of any need to deactivate a password. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 7. Information Related to Service. You grant Cloaked and those involved in the operation of the Service the right to transmit, monitor, retrieve, store, and use information recorded and/or stored in your account to operate the Service. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 7.1. Cloaked Sharing. Cloaked Sharing enables you to share Cloaked Identities, including all information contained in that identity, such as identity name, transactional information, phone numbers, call history, text message history, emails, email history, password(s), notes within the identity dashboard, and other Identity specifics, with a third-party or other Cloaked user. Cloaked Sharing is available in a view-only mode meaning that other users added to your Cloaked Identity can only access, view and download Your Content. This feature does not allow a Cloaked Shared user to make any changes to your Identity. You may remove a user from the Cloaked Share, at any time. However, deleting a Shared Cloaked identity, or revoking future access, does not delete information that may have previously been captured during sharing permission to a third party. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " Use Cloaked Share at your own risk. Cloaked assumes no responsibility in connection with Cloaked Sharing, including any third-party exceeding your authority. Cloaked does not and will not assume any liability for your actions with your shared Identity or the information contained in that Identity, even if you deem such information to be confidential or privileged. By using Cloaked Share, you understand and acknowledge that it is solely your choice to share your Identity and all Identity contents with a third-party or other uses, and that we do not control your actions or the actions of those third-parties or users. You also understand and acknowledge that third-parties may access, view or download your Identity content without restrictions or compensation to you "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 7.2. AutoCloak. AutoCloak enables you to automatically change existing credentials at certain websites to Cloak credentials. AutoCloak is subject to each website’s ability to allow and replace existing credentials or Cloaked information, and may not work on all websites or apps. AutoCloak at your own risk. AutoCloak is “as-is” and “as-available” without any representation or warranty. To the maximum extent permitted by applicable law, Cloaked specifically disclaims any implied warranties of title, merchantability, fitness for a particular purpose or non-infringement. In no way will Cloaked, its affiliates, or service providers, or any of its respective employees be liable for any issues that arise from use of AutoCloak, including, but not limited to, loss of information, rewards, points, or access to a given site or merchant. Cloaked is also not liable if AutoCloak violates any third-party website terms and conditions, or any acceptable use policy. We may remove or change the ability to AutoCloak, at any time. Cloaked does not and will not assume any liability for your actions, and understand and acknowledge that it is solely your choice to AutoCloak each and/or all identities with a third-party, and that we do not control your actions or the actions of those third-parties or users. "
        ),
      ]),
      _c("p", [
        _vm._v(
          ' 8. Usage Rules. You agree to comply with application-, tool-, or content-specific rules published within the Service. You agree that you will not, related to the Service: ‍ (a) Copy, adapt, reverse engineer, decompile, reverse assemble, modify or attempt to discover software (source code or object code) that the Service creates to generate web pages or software or other products or processes accessible through the Service; ‍ (b) use or launch, develop or distribute automated systems, including, without limitation, spider, robot (or "bot"), cheat utility, scraper or offline reader that accesses the Service, or use or launch unauthorized script or other software; '
        ),
      ]),
      _c("p", [
        _vm._v(
          " (c) distribute virus, time bomb, trap door, Trojan horse, worm, malware, ransomware or harmful, or malicious computer code, mechanism, software, script, agent or program; "
        ),
      ]),
      _c("p", [
        _vm._v(
          " (d) obscure notices, legends, warnings, banners or advertisements on the Service; "
        ),
      ]),
      _c("p", [
        _vm._v(
          " (e) interfere with or circumvent security features of the Service or features that restrict or enforce limitations on use of or access to the Service; "
        ),
      ]),
      _c("p", [_vm._v("(f) sell the Service or any part thereof;")]),
      _c("p", [_vm._v("(g) violate applicable law, including export laws;")]),
      _c("p", [
        _vm._v(
          " (h) infringe or violate the rights of third-parties including intellectual property rights or rights of privacy or publicity; "
        ),
      ]),
      _c("p", [
        _vm._v(
          " (i) engage in conduct that is obscene, offensive, pornographic, fraudulent, deceptive, defamatory, threatening, harassing, abusive, slanderous, hateful, or causes embarrassment to any other person; "
        ),
      ]),
      _c("p", [
        _vm._v(
          " (j) further chain letters or pyramid schemes, transmit unsolicited messages, or engage in “spam;” "
        ),
      ]),
      _c("p", [
        _vm._v(
          " (k) deliberately mislead anyone as to your identity, impersonate another, falsely identify the source of any Submissions, or allow another person or entity to use your identity to access the Service or post or view Submissions; "
        ),
      ]),
      _c("p", [
        _vm._v(
          " (l) engage in conduct that conflicts with the spirit or intent of the Service, including without limitation, exposing Cloaked or another to any liability or detriment; or "
        ),
      ]),
      _c("p", [
        _vm._v(
          " (m) use automated queries, including screen and database scraping, spiders, robots, crawlers, information harvesting, and other automated activity with the purpose of obtaining information or data from the Service. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " Cloaked may modify the usage rules at its discretion and will notify you as required by the Agreement. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 9. Privacy and Protection of Personal Information. Cloaked respects the privacy of visitors to and users of the Service. Information collected from you by Cloaked is subject to the Cloaked Privacy Policy. You acknowledge and agree that the Cloaked Privacy Policy is part of and governed by this Agreement. You agree to all terms of the Cloaked Privacy Policy. You agree to comply with applicable laws on information you receive from Cloaked. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 10. Communications Decency Act Notification. Pursuant to 47 U.S.C. § 230(d) as amended, Cloaked hereby notifies you that parental control protections (such as computer hardware, software, or filtering services) are commercially available that may assist you in limiting access to material that is harmful to minors. Information identifying providers of the protection is available at the following links: ‍ http://staysafeonline.org/stay-safe-online/; https://www.consumer.ftc.gov/features/feature-0038-onguardonline. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " Please note that Cloaked is not affiliated with these sites. Cloaked does not endorse these sites. "
        ),
      ]),
      _c("p", [_vm._v("11. Disclaimers; Limitations; Waivers of Liability.")]),
      _c("p", [
        _vm._v(
          ' 11.1. YOU EXPRESSLY AGREE THAT USE OF THE SERVICE IS AT YOUR SOLE RISK AND IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE (EXCEPT ONLY TO THE EXTENT THIS DISCLAIMER IS PROHIBITED UNDER APPLICABLE LAWS, AND WITH ANY LEGALLY REQUIRED WARRANTY PERIOD LIMITED TO THE SHORTER OF 30 DAYS FROM FIRST USE OR THE MINIMUM PERIOD REQUIRED BY APPLICABLE LAW). WITHOUT LIMITING THE FOREGOING, NEITHER CLOAKED NOR ITS AFFILIATES OR SUBSIDIARIES, OR ANY OF THEIR DIRECTORS, EMPLOYEES, AGENTS, ATTORNEYS, THIRD-PARTY CONTENT PROVIDERS, DISTRIBUTORS, LICENSEES OR LICENSORS (COLLECTIVELY, "CLOAKED PARTIES") WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, UNCORRUPTED, TIMELY, OR ERROR-FREE. '
        ),
      ]),
      _c("p", [
        _vm._v(
          " 11.2. THE CLOAKED PARTIES FURTHER MAKE NO REPRESENTATIONS OR WARRANTIES THAT THE SERVICE OR THE PROXY-BASED OR ENCRYPTED IDENTIFIERS GENERATED BY THE SERVICE WILL BE ACCURATE, RELIABLE, COMPLETE, CURRENT, OR TIMELY. THE SERVICE MAY BE TEMPORARILY UNAVAILABLE FROM TIME TO TIME FOR MAINTENANCE OR OTHER REASONS. CLOAKED IS NOT RESPONSIBLE FOR TECHNICAL MALFUNCTIONS OR OTHER PROBLEMS OF INTERNET NETWORKS OR SERVICES, COMPUTER SYSTEMS, MOBILE PHONE EQUIPMENT, SOFTWARE, OR EMAIL, INCLUDING TECHNICAL PROBLEMS OR TRAFFIC CONGESTION ON THE INTERNET OR AT ANY SITE OR COMBINATION THEREOF, INCLUDING INJURY OR DAMAGE TO A USER'S OR TO ANY OTHER PERSON'S COMPUTER, MOBILE PHONE, OR OTHER HARDWARE OR SOFTWARE, RELATED TO OR RESULTING FROM USING OR DOWNLOADING MATERIALS IN CONNECTION WITH THE INTERNET AND/OR IN CONNECTION WITH THE SERVICE. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 11.3. THE CLOAKED PARTIES DO NOT ENDORSE OR WARRANT ANY PRODUCT, SERVICE, OPINION, OR OTHER INFORMATION THAT MAY BE REFERENCED ON OR THROUGH THE SERVICE. THE SERVICE HEREUNDER IS DESIGNED FOR INFORMATIONAL AND TRANSACTIONAL PURPOSES ONLY. THE CLOAKED PARTIES HAVE NO RESPONSIBILITY FOR OR INVOLVEMENT WITH ANY RELATIONSHIP THAT EXISTS OR COMES TO EXIST BETWEEN A USER OF THE SERVICE AND ANY THIRD PARTY (INCLUDING ANY THIRD PARTY WITH WHOM A USER MAY COMMUNICATE USING THE SERVICE). "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 11.4. THE CLOAKED PARTIES WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, PERSONAL INJURY/WRONGFUL DEATH, LOST PROFITS, LOST DATA, OR BUSINESS INTERRUPTION, THE USE OR MISUSE OF SUBMISSIONS IN ANY WAY WHATSOEVER ARISING OUT OF THE USE OF, OR INABILITY TO USE, THE SERVICE, WHETHER OR NOT THE CLOAKED PARTIES ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN THE EVENT THAT THE FOREGOING EXCLUSION OF LIABILITY IS FOUND BY A COURT OF COMPETENT JURISDICTION TO BE UNENFORCEABLE, AND A DETERMINATION IS MADE THAT CLOAKED IS LIABLE, UNDER NO CIRCUMSTANCES WILL THE CLOAKED PARTIES BE LIABLE TO YOU FOR MORE THAN THE LESSER OF (A) THE AMOUNT YOU HAVE PAID CLOAKED IN THE 90 DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT THE CLAIM OR (B) $100. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 11.5. TO THE FULLEST EXTENT PERMITTED BY LAW, THESE DISCLAIMERS OF LIABILITY CONTAINED HEREIN APPLY TO ANY AND ALL DAMAGES OR INJURY WHATSOEVER CAUSED BY OR RELATED TO USE OF, OR INABILITY TO USE, THE SERVICE UNDER ANY CAUSE OR ACTION WHATSOEVER OF ANY JURISDICTION, INCLUDING, WITHOUT LIMITATION, ACTIONS FOR BREACH OF WARRANTY, BREACH OF CONTRACT OR TORT (INCLUDING NEGLIGENCE). "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 11.6. Release. You forever release, discharge, and covenant not to sue the Cloaked Parties from any and all liability, claims, actions, and expenses that may arise, whether caused by the negligence of the Cloaked Parties or otherwise, in connection with your use of the Service or your interaction with any party through or as a result of the Service. In other words, you cannot sue the Cloaked Parties if anything happens to you or your property from using the Service or interacting with any party through the Service. You agree that the provisions in this Section 9 will survive any termination of your account(s), the Service, or this Agreement. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 12. Indemnification. You agree to defend, indemnify and hold harmless the Cloaked Parties from and against all liability, claims, actions and expenses, including attorneys' fees and costs, arising out of your use of the Service or your breach or alleged breach of any term, condition, obligation, representation or warranty in this Agreement. You agree that the provisions in this paragraph will survive any termination of your account(s) the Service, or this Agreement. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 13. Third Party Sites and Products. We may include links to third-party sites or services, or information about third-party products or services, which are typically subject to service terms and policies that differ from ours. Therefore, you should review the terms of use and privacy policies of all sites and services linked to from or referred to by our Service. We do not endorse or take responsibility for these third-party offerings, nor do we vet or take responsibility for third-party sites, services or products or for the postings or communications of other users. "
        ),
      ]),
      _c("p", [
        _vm._v(
          "14. Governing Law/Waiver of Injunctive Relief/Dispute Resolution."
        ),
      ]),
      _c("p", [
        _vm._v(
          " 14.1. This Agreement and all aspects of the Service will be governed by laws of the U.S. and the Commonwealth of Massachusetts, and contracts are entered into and performed in Massachusetts (thus, without regard to conflict of laws provisions) regardless of your location, except that the arbitration provision set forth in sections 14.4, 14.5, and 14.6 will be governed by the Federal Arbitration Act, 9 U.S.C. § 1, et seq. With respect to any disputes or claims not subject to informal dispute resolution or arbitration (as set forth below), you agree not to commence or prosecute any action in connection therewith other than in the state or federal courts located in Boston, Massachusetts, and you hereby consent to, and waive all defenses of lack of personal jurisdiction and forum non conveniens with respect to, venue and jurisdiction in the state and federal courts located in Boston, Massachusetts. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 14.2. You acknowledge that the rights granted and obligations made hereunder to Cloaked are of a unique and irreplaceable nature, the loss of which will irreparably harm Cloaked and which cannot be replaced by monetary damages alone, so that Cloaked will be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety) in the event of any breach or anticipatory breach by you. You irrevocably waive all rights to seek injunctive or other equitable relief and agree to limit your claims to claims for monetary damages (if any). "
        ),
      ]),
      _c("p", [
        _vm._v(
          ' 14.3. To expedite resolution and control the cost of any dispute, controversy or claim related to this Agreement ("Dispute"), you and Cloaked agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating any arbitration or court proceeding. The informal negotiations commence upon written notice from one person to the other. You will send your notice to Cloaked, Inc., 1075 Westford Street, Suite 305, Lowell, MA 01851 USA. In this Agreement, the term “Dispute” is to have the broadest possible meaning. '
        ),
      ]),
      _c("p", [
        _vm._v(
          " 14.4. Arbitration. If you and Cloaked are unable to resolve a Dispute through informal negotiations within 30 days, either you or Cloaked may elect to have the Dispute (except those Disputes expressly excluded below) finally and exclusively resolved by binding arbitration. Either party may elect arbitration at any time, including after a lawsuit is filed. Any election to arbitrate by one party will be final and binding on the other. YOU UNDERSTAND THAT ABSENT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. BY AGREEING TO THESE TERMS, YOU ARE WAIVING YOUR RIGHT TO HAVE JURIES RESOLVE DISPUTES, TO HAVE COURTS (OTHER THAN SMALL CLAIMS COURTS) SOLVE DISPUTES, TO SERVE AS A PRIVATE ATTORNEY GENERAL OR IN A REPRESENTATIVE CAPACITY, AND TO PARTICIPATE IN A CLASS ACTION LAWSUIT. The arbitration will be commenced and conducted under the Streamlined Arbitration Rules and Procedures (the “Rules”) of JAMS, which is available at the JAMS website www.jamsadr.com. The determination of whether a Dispute is subject to arbitration will be governed by the Federal Arbitration Act and determined by a court rather than an arbitrator. Your arbitration fees and your share of arbitrator compensation will be governed by the Rules. The arbitration may be conducted in person, through the submission of documents, by phone or online. Any in-person hearing will be conducted at a location that is reasonably convenient to you. The arbitrator will make a decision in writing but need not provide a statement of reasons unless requested by a party. The arbitrator must follow applicable law, and any award may be challenged if the arbitrator fails to do so. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 14.5. Notwithstanding the above, you and Cloaked agree that arbitration will be limited to the Dispute between Cloaked and you individually. To the full extent permitted by law, (a) no arbitration will be joined with any other; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons. If a claim seeks public injunctive relief and a court determines that this arbitration provision’s restrictions on serving in a representative capacity or on behalf of the general public are unenforceable with respect to such claim, then after all appeals have been exhausted the claim for public injunctive relief will be determined in court. All individual claims seeking monetary relief will be arbitrated. The parties agree to stay claims for public injunctive relief until the arbitration award pertaining to individual relief has been entered in court. The parties agree that class claims and claims for public injunctive relief will not be arbitrated. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 14.6. You and Cloaked agree that the following Disputes are not subject to the above provisions concerning informal negotiations and binding arbitration: (a) Disputes seeking to enforce or protect, or concerning the validity of, your or Cloaked’s intellectual property rights; (b) Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy or unauthorized use; (c) compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate or enter judgment on the award entered by the arbitrator; and (d) claim brought on an individual basis in a state court of limited jurisdiction that does not have authority to hear class action litigation (“Small Claims Court”). "
        ),
      ]),
      _c("p", [_vm._v("15. Waiver/Severability.")]),
      _c("p", [
        _vm._v(
          " 15.1. Cloaked’s failure to enforce strict performance or exercise any right under this Agreement will not be construed as a waiver of Cloaked’s right to assert or rely upon the provision or right in that or any other instance. "
        ),
      ]),
      _c("p", [
        _vm._v(
          ' 15.2. You and Cloaked agree that if any part of this Agreement is declared invalid, the rest of the Agreement remains valid. If any part of this Agreement conflicts with any law, that law will control. The part of the Agreement that conflicts with any law will be modified to comply with the law. The rest of the Agreement remains valid." If Section 12.4 is found to be illegal or unenforceable then neither you nor Cloaked will elect to arbitrate any Dispute falling within that portion of Section 12.4 found to be illegal or unenforceable and the Dispute will be decided by a court of competent jurisdiction within Boston, Massachusetts, and you and Cloaked agree to submit to the personal jurisdiction of that court. '
        ),
      ]),
      _c("p", [
        _vm._v(
          " 16. Term and Termination. This Agreement will remain in effect as long as your account is up-to-date and you remain in compliance with the Agreement, unless it has been voluntarily suspended or terminated by you or Cloaked. The privileges granted to you under this Agreement will terminate immediately and automatically without notice from Cloaked if, in our sole discretion, you fail to comply with any term or provision of this Agreement or for any reason. Following the termination of this Agreement, your account(s), or the Service, Cloaked will retain all rights to the Submissions under this Agreement. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 17. Miscellaneous. Cloaked operates and controls the Service from its offices in the United States. Cloaked makes no representation that the Service is appropriate, lawful or available in other locations. The information provided on the Service is not intended for distribution to or use by any person or entity in any jurisdiction or country where the distribution or use would be contrary to law or regulation or which would subject Cloaked to any registration requirement within the jurisdiction or country. Accordingly, those persons who choose to access the Service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. Neither the course of conduct between the parties nor trade practice will act to modify this Agreement. Cloaked may assign this Agreement to any party at any time without any notice to you. You may not assign this Agreement without Cloaked’s prior written consent. This Agreement (including the Cloaked Privacy Policy) contains the entire understanding of you and Cloaked’s, and supersedes all prior understandings between the parties concerning its subject matter, and cannot be changed or modified by you. Upon Cloaked’s request, you will furnish Cloaked with any documentation, substantiation or releases necessary to verify your compliance with this Agreement. You agree that this Agreement will not be construed against Cloaked by virtue of having drafted it. You hereby waive any and all defenses you may have based on the electronic form of this Agreement and the lack of signing by the parties hereto to execute this Agreement. "
        ),
      ]),
      _c("p", [
        _vm._v(
          ' 18. Construction. In this Agreement, unless a clear contrary intention appears: (i) where not inconsistent with the context, words used in the present tense include the future tense and vice versa and words in the plural number include the singular number and vice versa; (ii) reference to any person includes the person’s successors and assigns but, if applicable, only if the successors and assigns are not prohibited by this Agreement; (iii) reference to any gender includes each other gender; (iv) reference to any agreement, document or instrument means the agreement, document or instrument as amended or modified and in effect from time to time accordin the terms thereof and includes all addenda, exhibits and schedules thereto; (v) the titles and subtitles used in this Agreement are used for convenience only and are not to be considered in construing or interpreting this Agreement; (vi) “hereunder,” “hereof,” “hereto,” and words of similar import will be deemed references to this Agreement as a whole and not to any particular Section or Subsection of this Agreement; (vii) “including” (and with correlative meaning, “include”) means including without limiting the generality of any description preceding the term; (viii) any reference to “dollars” means United States Dollars; (ix) all references to “days” means calendar days; and (x) the word "or" is not exclusive. This Agreement has been executed in English and the English language version will control notwithstanding any translations of this Agreement. Unless otherwise expressly permitted under this Agreement, all deliverables will be in English. '
        ),
      ]),
      _c("p", [
        _vm._v(
          " 19. Statute of Limitations. You and Cloaked both agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Service or this Agreement (including the Privacy Policy) must be filed within ONE (1) YEAR after the claim or cause of action arose (or, if longer, within the shortest statute of limitations for the claim which the parties may establish by agreement) or the claim will be forever barred. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 20. Questions? If you have any questions about this Agreement, its terms, your account or your rights hereunder, please contact support@cloaked.app. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 21. Payment. Use of the Service may require payment(s) (“Charges”). Cloaked will process payment using the preferred payment method designated. Charges will be inclusive of applicable taxes where required by law. Charges paid by you are final and non-refundable, unless otherwise determined by Cloaked. Questions about charges should be addressed to support@cloaked.app. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " We reserve the right to establish, remove and/or revise Charges for Service(s) at any time in our sole discretion, by posting or otherwise delivering notice to you. Use of the Service after new or revised Charges will be deemed your acceptance of new or revised Charges. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " Cloaked may provide promotional offers and discounts that may result in different Charges for the same or similar Service. You agree that the promotional offers and discounts, will have no bearing on your use of the Service or the Charges applied to you. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " To the extent permitted by applicable law, with respect to paid Service subscription, you agree and acknowledge that you are not entitled to any refund for amounts which were pre-paid on behalf of your account prior to termination of your account. Although under certain circumstances, users located in the European Economic Area may have a right to cancel pre-paid account subscriptions within 14 days of signing up for, upgrading to or renewing a Service account, by accessing your Service account, you acknowledge that PERFORMANCE OF THIS AGREEMENT HAS BEGUN UPON ACCESSING YOUR ACCOUNT AND YOU THEREBY LOSE YOUR RIGHT OF WITHDRAWAL UNDER APPLICABLE LAW, INCLUDING APPLICABLE EUROPEAN UNION LAW, IN RESPECT OF THE PURCHASE OF DIGITAL CONTENT HEREUNDER, AND YOU THEREBY WAIVE RIGHTS YOU MAY HAVE TO A REFUND OF AMOUNTS WHICH WERE PRE-PAID ON BEHALF OF YOUR ACCOUNT PRIOR TO TERMINATION OF YOUR ACCOUNT. If you voluntarily cancel your paid Service subscription, the termination of your account will not occur until the end of the subscription period for which you have paid, and you will be able to continue using your account until such time. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }