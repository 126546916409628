var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "auto-change" },
    [
      _c("header", { staticClass: "auto-change__header" }, [
        _c("h1", { staticClass: "auto-change__title" }, [_vm._v("AutoCloak")]),
        _c(
          "a",
          {
            staticClass: "auto-change__faq",
            attrs: {
              href: "https://help.cloaked.app/hc/en-us/articles/19903643695892-How-To-AutoCloak",
              target: "_blank",
            },
          },
          [
            _vm._v("How does AutoCloak work "),
            _c(_setup.Help, { staticClass: "auto-change__faq-icon" }),
          ],
          1
        ),
      ]),
      _c(
        "transition",
        { attrs: { name: "fade-100", mode: "out-in" } },
        [
          _vm.isLoading
            ? _c(_setup.AutoChangePageSkeleton, {
                staticClass: "auto-change__skeleton",
                attrs: { rows: _vm.items.length ? _vm.items.length + 1 : 11 },
              })
            : _c(
                "div",
                [
                  _vm.items.length
                    ? _c(
                        "label",
                        {
                          staticClass: "auto-change__select-all",
                          class: {
                            "auto-change__select-all--disabled": _vm.items.some(
                              (item) =>
                                item.fields.some((field) => field?.status)
                            ),
                          },
                        },
                        [
                          _c(_setup.InputToggleAll, {
                            attrs: {
                              disabled: _vm.items.some((item) =>
                                item.fields.some((field) => field?.status)
                              ),
                              values: ["some", "all"],
                              value: _setup.areAllSelected
                                ? ["some", "all"]
                                : _setup.areSomeSelected
                                ? ["some"]
                                : [],
                            },
                            on: { input: _setup.onSelectAll },
                          }),
                          _vm._v(" Select all "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.items.length
                    ? _c(_setup.AutoChangePageTable, {
                        staticClass: "auto-change__table",
                        attrs: {
                          items: _vm.items,
                          value: _vm.value,
                          verification: _vm.verification,
                          updates: _vm.updates,
                          support: _vm.support,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$emit("input", $event)
                          },
                          "cell-click": function ($event) {
                            return _vm.$emit("cell-click", $event)
                          },
                        },
                      })
                    : _c("p", { staticClass: "auto-change__unsupported" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              `${
                                _setup.enabledStatusCopy ??
                                "There are no supported identities"
                              }.`
                            ) +
                            " "
                        ),
                      ]),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade-100", mode: "out-in" } },
        [
          _vm.isLoading && _setup.autoChangeProgress !== 1
            ? _c(_setup.AutoChangePageSkeletonFooter)
            : _c(
                "div",
                {
                  ref: "footer",
                  staticClass: "auto-change__footer",
                  class: {
                    "auto-change__footer--deselected": !_vm.value.length,
                  },
                },
                [
                  _setup.autoChangeProgress === 1
                    ? [
                        _c(
                          _setup.Button,
                          {
                            attrs: {
                              type: "secondary",
                              disabled: _vm.isLoading,
                            },
                            on: { click: _setup.onStartOver },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isLoading
                                    ? "Starting over..."
                                    : "Start over"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          _setup.Button,
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("import-more")
                              },
                            },
                          },
                          [_vm._v(" Import more accounts ")]
                        ),
                      ]
                    : _setup.isAutoChangeInProgress
                    ? [
                        _c(
                          _setup.Button,
                          {
                            staticClass: "auto-change__stop",
                            attrs: { disabled: _setup.isBeingStopped },
                            on: { click: _setup.onStop },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.isBeingStopped
                                    ? "Stopping AutoCloak"
                                    : "Stop AutoCloak"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(_setup.LineProgress, {
                          staticClass: "auto-change__progress",
                          attrs: {
                            progress: _setup.autoChangeProgress,
                            width: _setup.autoChangeProgressWidth,
                          },
                        }),
                      ]
                    : [
                        _c(
                          _setup.AppTooltip,
                          {
                            attrs: {
                              value:
                                _vm.enabled !==
                                _setup.AutoChangeEnabledStatus.ENABLED
                                  ? _setup.isStartTooltipDisplayed
                                  : false,
                              placement: "top-start",
                            },
                            on: {
                              input: function ($event) {
                                _setup.isStartTooltipDisplayed = $event
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _setup.enabledStatusCopy ??
                                            "AutoCloaking is currently not available"
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _vm.value.length
                              ? _c(
                                  _setup.Button,
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.enabled !==
                                        _setup.AutoChangeEnabledStatus.ENABLED,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("auto-change")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " AutoCloak " +
                                        _vm._s(
                                          `${_vm.value.length} ${
                                            _vm.value.length === 1
                                              ? "identity"
                                              : "identities"
                                          }`
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          _setup.Button,
                          {
                            attrs: { type: "secondary" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("import-more")
                              },
                            },
                          },
                          [_vm._v(" Import more accounts ")]
                        ),
                      ],
                ],
                2
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }