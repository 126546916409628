var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.Popper,
    _vm._g(
      _vm._b(
        {
          attrs: {
            width: _vm.hasAnnouncementTooltip ? "250px" : "initial",
            "offset-along": _vm.hasAnnouncementTooltip ? 10 : 0,
            "offset-away": _vm.hasAnnouncementTooltip ? 16 : 2,
            placement: _vm.hasAnnouncementTooltip ? "left" : "bottom-end",
            "has-click-open": false,
            "has-click-close": false,
            "has-outside-click-close": false,
            "has-mouse-enter-open": !_vm.hasAnnouncementTooltip,
            "has-mouse-leave-close": !_vm.hasAnnouncementTooltip,
            transition: _vm.hasAnnouncementTooltip
              ? "fade-100"
              : "fade-top-200",
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _vm.hasAnnouncementTooltip
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "identity-sharing-button__tooltip--announcement",
                        },
                        [
                          _c(_setup.ChevronTooltip, {
                            staticClass:
                              "identity-sharing-button__tooltip-chevron",
                          }),
                          _vm._v(
                            " Tap the link icon to generate a share link and password."
                          ),
                          _c("br"),
                          _c(
                            "button",
                            {
                              staticClass:
                                "identity-sharing-button__tooltip-button",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("input", false)
                                },
                              },
                            },
                            [_vm._v(" Got it ")]
                          ),
                        ],
                        1
                      )
                    : _vm.isShared
                    ? _c(
                        "div",
                        { staticClass: "identity-sharing-button__tooltip" },
                        [_vm._v(" Identity is currently being shared ")]
                      )
                    : _c(
                        "div",
                        { staticClass: "identity-sharing-button__tooltip" },
                        [_vm._v("Share identity")]
                      ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        "Popper",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "button",
        _vm._g(
          {
            staticClass: "identity-sharing-button",
            class: {
              "identity-sharing-button--shared": _vm.isShared,
              "identity-sharing-button--active":
                _vm.isActive || _vm.$attrs.value,
            },
          },
          _vm.$listeners
        ),
        [_c(_setup.IconLink, { staticClass: "identity-sharing-button__icon" })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }