var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "menu-block", class: { toggle: _setup.toggle } },
    [
      _setup.props.title
        ? _c("div", { staticClass: "menu-title" }, [
            _c("span", [_vm._v(_vm._s(_setup.props.title))]),
            _c("i", {
              staticClass: "toggle",
              class: { active: _setup.toggle },
              on: {
                click: function ($event) {
                  return _setup.toggleMenu()
                },
              },
            }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "menu-items" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }