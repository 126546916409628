var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.AppModal,
    _vm._g(_vm._b({}, "AppModal", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        _setup.AppModalContent,
        {
          staticClass: "sharing-modal-published",
          class: { "sharing-modal-published--loading": _vm.isLoading },
        },
        [
          _c(
            _setup.AppModalCustomWrapper,
            { staticClass: "sharing-modal-published__head" },
            [
              _c(_setup.IdentityIcon, {
                staticClass: "sharing-modal-published__identity-icon",
                attrs: {
                  identity: _vm.identity,
                  override: { width: "72px", height: "72px" },
                },
              }),
              _c(
                _setup.AppModalTitle,
                { staticClass: "sharing-modal-published__title-top" },
                [_vm._v(" Publishing your changes... ")]
              ),
              _c(_setup.Timer, {
                staticClass: "sharing-modal-published__timer",
                attrs: {
                  "start-date": _vm.identity.sharing?.shared_at,
                  "end-date": _vm.identity.sharing?.expires_at,
                  "is-one-time-view": _vm.identity.sharing?.onetimeview,
                },
              }),
              _c(
                "div",
                { staticClass: "sharing-modal-published__spinner" },
                [_c(_setup.Spinner)],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "sharing-modal-published__content" },
            [
              _c(
                _setup.AppModalTitle,
                { staticClass: "sharing-modal-published__title" },
                [_vm._v(" Your share link is published ")]
              ),
              _c(_setup.IdentitySharingTerms),
              _c(
                _setup.AppModalCustomWrapper,
                [
                  _c(_setup.BorderInputText, {
                    staticClass: "sharing-modal-published__input",
                    attrs: {
                      label: "Link",
                      value: _vm.identity.sharing?.shared_url,
                      disabled: "",
                    },
                    on: {
                      click: function ($event) {
                        _setup.tools.copyToClipboard(
                          _vm.identity.sharing?.shared_url
                        )
                        _vm.$toast?.success("Link copied.")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "after",
                        fn: function () {
                          return [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "sharing-modal-published__icon-action",
                              },
                              [_c(_setup.IconCopy)],
                              1
                            ),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "sharing-modal-published__icon-action",
                                attrs: { disabled: _setup.expiresIn !== null },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.$attrs.isGeneratingLink ||
                                      _vm.$emit("generate-new-link")
                                  },
                                },
                              },
                              [
                                _vm.$attrs.isGeneratingLink
                                  ? _c(_setup.IconSpinner)
                                  : _c(_setup.IconReload),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c(
                    "div",
                    { staticClass: "sharing-modal-published__input-row" },
                    [
                      _c(_setup.BorderInputText, {
                        staticClass: "sharing-modal-published__input",
                        attrs: {
                          label: "Link password",
                          value: _vm.identity.sharing?.decryptedPassword,
                          disabled: "",
                        },
                        on: {
                          click: function ($event) {
                            _setup.tools.copyToClipboard(
                              _vm.identity.sharing?.decryptedPassword
                            )
                            _vm.$toast?.success("Link password copied.")
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "after",
                            fn: function () {
                              return [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "sharing-modal-published__icon-action",
                                  },
                                  [_c(_setup.IconCopy)],
                                  1
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "sharing-modal-published__icon-action",
                                    attrs: {
                                      disabled: _setup.expiresIn !== null,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        _vm.$attrs.isGeneratingPassword ||
                                          _vm.$emit("generate-new-password")
                                      },
                                    },
                                  },
                                  [
                                    _vm.$attrs.isGeneratingPassword
                                      ? _c(_setup.IconSpinner)
                                      : _c(_setup.IconReload),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c(_setup.BorderInputSelect, {
                        staticClass: "sharing-modal-published__expiration",
                        attrs: {
                          label: "Link expires in...",
                          value: _setup.expiresIn ?? _setup.previousExpiresIn,
                          options: [
                            "One-time view",
                            "1 hour",
                            "24 hours",
                            "7 days",
                            "30 days",
                          ],
                          disabled: "",
                        },
                        on: { input: _setup.onExpiresInChanged },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function ({ option }) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "sharing-modal-published__expiration-option",
                                    class: {
                                      "sharing-modal-published__expiration-option--selected":
                                        option === _setup.expiresIn ||
                                        (option === _setup.previousExpiresIn &&
                                          !_setup.expiresIn),
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(option) + " "),
                                    option === _setup.expiresIn ||
                                    (option === _setup.previousExpiresIn &&
                                      !_setup.expiresIn)
                                      ? _c(_setup.CheckMark)
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            _setup.AppModalFooter,
            {
              staticClass: "sharing-modal-published__footer",
              attrs: { "with-border": "" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "sharing-modal-published__change-permission",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("open-create")
                    },
                  },
                },
                [_vm._v(" Change permissions ")]
              ),
              _setup.expiresIn !== null
                ? [
                    _c(
                      _setup.Button,
                      {
                        attrs: { type: "danger-secondary" },
                        on: {
                          click: function ($event) {
                            _vm.$emit("discard-changes")
                            _setup.expiresIn = null
                          },
                        },
                      },
                      [_vm._v("Discard changes")]
                    ),
                    _c(
                      _setup.Button,
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("update")
                          },
                        },
                      },
                      [_vm._v("Publish changes")]
                    ),
                  ]
                : [
                    _c(
                      _setup.Button,
                      {
                        attrs: { type: "secondary" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("open-delete")
                          },
                        },
                      },
                      [_vm._v("Delete link")]
                    ),
                    _c(
                      _setup.Button,
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$emit("input", false)
                          },
                        },
                      },
                      [_vm._v("Close")]
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }