<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
} from "vue";
import Button from "@/components/Button.vue";
import LineProgress from "@/components/ui/LineProgress.vue";
import {
  AutoChangeEnabledStatus,
  AutoChangeStatus,
  AutoChangeVerificationStatus,
  SupportedAutoChangeFieldTypes,
} from "@/components/auto-change/utils";
import AutoChangePageTable from "@/components/auto-change/AutoChangePageTable.vue";
import InputToggleAll from "@/components/feature/import/ReviewToggleAll.vue";
import AutoChangePageSkeleton from "@/components/auto-change/AutoChangePageSkeleton.vue";
import AutoChangePageSkeletonFooter from "@/components/auto-change/AutoChangePageSkeletonFooter.vue";
import Help from "@/assets/icons/help.svg";
import AppTooltip from "@/components/ui/AppTooltip.vue";

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  items: {
    type: Array,
    default: () => [],
  },
  verification: {
    type: Array,
    default: () => [],
  },
  updates: {
    type: Array,
    default: () => [],
  },
  support: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  enabled: {
    type: String,
    default: AutoChangeEnabledStatus.ENABLED,
    validator: (value) =>
      [
        "enabled",
        "extensionNotInstalled",
        "extensionLoggedOut",
        "accountRestricted",
      ].includes(value),
  },
});

const isAutoChangeInProgress = computed(() => autoChangeProgress.value < 1);

const autoChangeProgress = computed(() => {
  const allItemsCount =
    props.items.reduce(
      (result, item) =>
        result +
        item.fields.filter((field) => !!field?.status && !!field.oldValue)
          .length,
      0
    ) +
    props.verification?.reduce(
      (result, item) =>
        result +
        Array.from(new Set(item.fields.map((field) => field.fieldType))).length,
      0
    ) +
    props.updates?.reduce((result, item) => result + item.fields.length, 0);

  const doneItemsCount =
    props.items.reduce(
      (result, item) =>
        result +
        item.fields.filter((field) =>
          [
            AutoChangeStatus.SUCCESS,
            AutoChangeStatus.ERROR,
            AutoChangeStatus.SKIPPED,
          ].includes(field?.status)
        ).length,
      0
    ) +
    props.verification?.reduce(
      (result, item) =>
        result +
        SupportedAutoChangeFieldTypes.filter((fieldType) =>
          item.fields.some((field) => field.fieldType === fieldType)
        ).filter((fieldType) =>
          item.fields
            .filter((field) => field.fieldType === fieldType)
            .every((field) =>
              [
                AutoChangeVerificationStatus.NOT_REQUIRED,
                AutoChangeVerificationStatus.SUCCESS,
                AutoChangeVerificationStatus.ERROR,
                AutoChangeVerificationStatus.SKIPPED,
              ].includes(field.status)
            )
        ).length,
      0
    ) +
    props.updates.reduce(
      (result, item) =>
        result +
        item.fields.filter((field) =>
          [
            AutoChangeVerificationStatus.NOT_REQUIRED,
            AutoChangeVerificationStatus.SUCCESS,
            AutoChangeVerificationStatus.ERROR,
            AutoChangeVerificationStatus.SKIPPED,
          ].includes(field.status)
        ).length,
      0
    );

  return doneItemsCount / allItemsCount;
});

const footer = ref(null);
const autoChangeProgressWidth = ref(0);

const updateAutoChangeProgressWidth = () => {
  autoChangeProgressWidth.value = footer.value
    ? parseInt(getComputedStyle(footer.value).width) - 154
    : 0;
};

watch(() => footer.value, updateAutoChangeProgressWidth);
watch(() => isAutoChangeInProgress.value, updateAutoChangeProgressWidth);
onMounted(() =>
  window.addEventListener("resize", updateAutoChangeProgressWidth)
);
onBeforeUnmount(() =>
  window.removeEventListener("resize", updateAutoChangeProgressWidth)
);

const emit = defineEmits(["input"]);
const areSomeSelected = computed(() => props.value.length);
const areAllSelected = computed(() =>
  props.items.every((item) => {
    const selectedIdentity = props.value.find(
      (selectedIdentity) => selectedIdentity.identityId === item.identityId
    );
    return (
      selectedIdentity &&
      item.fields.every((field) =>
        selectedIdentity.fields.some(
          (selectedField) => selectedField.fieldType === field.fieldType
        )
      )
    );
  })
);

const onSelectAll = () => {
  emit("input", areAllSelected.value ? [] : props.items);
};

const isBeingStopped = ref(false);

const onStartOver = () => {
  isBeingStopped.value = false;
  emit("start-over");
};

const onStop = () => {
  isBeingStopped.value = true;
  emit("stop-auto-change");
};

const isStartTooltipDisplayed = ref(false);
const enabledStatusCopy = computed(() => {
  const enabledStatusCopyMap = {
    [AutoChangeEnabledStatus.EXTENSION_NOT_INSTALLED]:
      "Please install the Cloaked extension to use AutoCloaking",
    [AutoChangeEnabledStatus.EXTENSION_LOGGED_OUT]:
      "Please login to the Cloaked extension to use AutoCloaking",
    [AutoChangeEnabledStatus.ACCOUNT_RESTRICTED]:
      "Please renew your Cloaked subscription to use AutoCloaking",
  };

  return enabledStatusCopyMap[props.enabled];
});

watch(
  () => props.enabled,
  () => {
    isStartTooltipDisplayed.value = false;
  }
);
</script>

<template>
  <div class="auto-change">
    <header class="auto-change__header">
      <h1 class="auto-change__title">AutoCloak</h1>
      <a
        href="https://help.cloaked.app/hc/en-us/articles/19903643695892-How-To-AutoCloak"
        target="_blank"
        class="auto-change__faq"
        >How does AutoCloak work
        <Help class="auto-change__faq-icon" />
      </a>
    </header>
    <transition name="fade-100" mode="out-in">
      <AutoChangePageSkeleton
        class="auto-change__skeleton"
        v-if="isLoading"
        :rows="items.length ? items.length + 1 : 11"
      />
      <div v-else>
        <label
          v-if="items.length"
          class="auto-change__select-all"
          :class="{
            'auto-change__select-all--disabled': items.some((item) =>
              item.fields.some((field) => field?.status)
            ),
          }"
        >
          <InputToggleAll
            :disabled="
              items.some((item) => item.fields.some((field) => field?.status))
            "
            :values="['some', 'all']"
            :value="
              areAllSelected ? ['some', 'all'] : areSomeSelected ? ['some'] : []
            "
            @input="onSelectAll"
          />
          Select all
        </label>
        <AutoChangePageTable
          v-if="items.length"
          :items="items"
          :value="value"
          :verification="verification"
          :updates="updates"
          :support="support"
          @input="$emit('input', $event)"
          @cell-click="$emit('cell-click', $event)"
          class="auto-change__table"
        />
        <p v-else class="auto-change__unsupported">
          {{ `${enabledStatusCopy ?? "There are no supported identities"}.` }}
        </p>
      </div>
    </transition>
    <transition name="fade-100" mode="out-in">
      <AutoChangePageSkeletonFooter
        v-if="isLoading && autoChangeProgress !== 1"
      />
      <div
        class="auto-change__footer"
        ref="footer"
        v-else
        :class="{ 'auto-change__footer--deselected': !value.length }"
      >
        <template v-if="autoChangeProgress === 1">
          <Button type="secondary" @click="onStartOver" :disabled="isLoading">
            {{ isLoading ? "Starting over..." : "Start over" }}
          </Button>
          <Button type="primary" @click="$emit('import-more')">
            Import more accounts
          </Button>
        </template>
        <template v-else-if="isAutoChangeInProgress">
          <Button
            @click="onStop"
            :disabled="isBeingStopped"
            class="auto-change__stop"
          >
            {{ isBeingStopped ? "Stopping AutoCloak" : "Stop AutoCloak" }}
          </Button>
          <LineProgress
            :progress="autoChangeProgress"
            :width="autoChangeProgressWidth"
            class="auto-change__progress"
          />
        </template>
        <template v-else>
          <AppTooltip
            @input="isStartTooltipDisplayed = $event"
            :value="
              enabled !== AutoChangeEnabledStatus.ENABLED
                ? isStartTooltipDisplayed
                : false
            "
            placement="top-start"
          >
            <Button
              @click="$emit('auto-change')"
              v-if="value.length"
              :disabled="enabled !== AutoChangeEnabledStatus.ENABLED"
            >
              AutoCloak
              {{
                `${value.length} ${
                  value.length === 1 ? "identity" : "identities"
                }`
              }}
            </Button>
            <template #content>
              {{
                enabledStatusCopy ?? "AutoCloaking is currently not available"
              }}
            </template>
          </AppTooltip>
          <Button type="secondary" @click="$emit('import-more')">
            Import more accounts
          </Button>
        </template>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
.auto-change {
  padding: 25px 32px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__title {
    color: $color-primary-100;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
  }

  &__faq {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    color: $color-primary-100;
    display: flex;
    align-items: center;
    text-align: right;
    margin-bottom: 10px;

    &:hover {
      opacity: 0.9;
    }

    &-icon {
      margin-left: 4px;
      flex-shrink: 0;
    }
  }

  &__skeleton {
    margin-top: 30px;
  }

  &__select-all {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    color: $color-primary-100;
    font-size: 12px;
    font-weight: 500;
    text-decoration-line: underline;
    cursor: pointer;
    margin-top: 30px;

    &:hover {
      color: $color-primary-70;
    }

    &--disabled {
      color: $color-primary-60 !important;
      cursor: not-allowed;
    }
  }

  &__table {
    max-height: calc(100vh - 332px);
  }

  &__unsupported {
    margin-top: 24px;
    color: $color-primary-100;
  }

  &__footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 8px;

    &--deselected {
      gap: 0;
    }
  }

  &__stop {
    flex-shrink: 0;
  }

  &__progress {
    margin-left: auto;

    .progress-line__indicator {
      stroke: $color-primary-100;
    }
  }
}
</style>
