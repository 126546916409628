<template>
  <UiMenu
    :value="visible"
    placement="left-start"
    width="250px"
    @close="$emit('close')"
    class="cloak-generate-password-flyout"
  >
    <template #content>
      <template v-if="showGenerateOptions">
        <UiMenuButton
          aria-id="ToggleLettersPassword"
          title="Letters (e.g. Aa)"
          :disabled="disabled.includes('letters')"
          @click="letters = !letters"
        >
          <template v-slot:icon>
            <CheckButton
              v-model="letters"
              :disabled="disabled.includes('letters')"
            />
          </template>
        </UiMenuButton>

        <UiMenuButton
          aria-id="ToggleDigitsPassword"
          title="Digits (e.g. 123)"
          :disabled="disabled.includes('numbers')"
          @click="numbers = !numbers"
        >
          <template v-slot:icon>
            <CheckButton
              v-model="numbers"
              :disabled="disabled.includes('numbers')"
            />
          </template>
        </UiMenuButton>

        <UiMenuButton
          aria-id="ToggleSymbolsPassword"
          title="Symbols (!*$?#)"
          @click="symbols = !symbols"
        >
          <template v-slot:icon>
            <CheckButton
              v-model="symbols"
              :disabled="disabled.includes('symbols')"
            />
          </template>
        </UiMenuButton>

        <UiMenuButton
          aria-id="ToggleEasyToRememberPassword"
          title="Easy to remember"
          @click="words = !words"
        >
          <template v-slot:icon>
            <CheckButton
              v-model="words"
              :disabled="disabled.includes('words')"
            />
          </template>
        </UiMenuButton>

        <UiMenuSeparator />

        <div class="password-length">
          <div class="password-length__label">
            <span>
              {{ words ? "Word Count" : "Length" }}
            </span>
          </div>

          <div class="password-length__controls" @click.stop="() => {}">
            <button @click="setLength(-1)">
              <Minus />
            </button>

            <input
              aria-id="PasswordLengthInput"
              type="text"
              v-model="size"
              @keydown="onlyNumbers"
              min="8"
              max="128"
              maxlength="3"
            />

            <button @click="setLength(1)">
              <Plus />
            </button>
          </div>
        </div>

        <UiMenuSeparator />

        <UiMenuButton
          aria-id="GenerateNewPasswordButton"
          title="Generate New Password"
          active
          @click="handleGenerate"
        >
          <template v-slot:icon>
            <GenerateIcon />
          </template>
        </UiMenuButton>
      </template>
    </template>
  </UiMenu>
</template>

<script>
import { GenerateIcon, Plus, Minus } from "@/assets/icons";
import {
  UiMenu,
  UiMenuButton,
  UiMenuSeparator,
  CheckButton,
} from "@/components";

export default {
  name: "CloakGeneratePasswordFlyout",
  props: {
    visible: { type: Boolean, default: false },
    value: { type: String, default: "" },
    showStrength: { type: Boolean, default: false },
    showGenerateOptions: { type: Boolean, default: false },
  },
  components: {
    GenerateIcon,
    UiMenu,
    UiMenuButton,
    UiMenuSeparator,
    CheckButton,
    Plus,
    Minus,
  },
  data() {
    return {
      letters: true,
      numbers: true,
      symbols: true,
      similar: false,
      size: 20,
      words: false,
      disabled: [],
      previousSize: 20,
      strength: 0,
    };
  },

  methods: {
    onlyNumbers(event) {
      if (event.key.match(/^[0-9]$/)) {
        return event;
      }

      event.preventDefault();
    },
    setLength(add) {
      const minPasswordLength = this.words ? 3 : 8;

      let pwdLength = parseInt(this.size, 10) + add;

      if (pwdLength < minPasswordLength) {
        pwdLength = minPasswordLength;
      } else if (pwdLength > 128) {
        pwdLength = 128;
      }

      this.size = pwdLength;
    },
    handleGenerate() {
      const settings = {
        size: parseInt(this.size, 10),
        letters: this.letters,
        numbers: this.numbers,
        symbols: this.symbols,
        similar: this.similar,
        words: this.words,
      };
      const withinWordMinMax =
        settings.words && settings.size >= 3 && settings.size <= 10;
      const withinLetterMinMax =
        !settings.words && settings.size >= 8 && settings.size <= 128;
      if (withinWordMinMax || withinLetterMinMax) {
        this.$emit("generate", settings);
      } else {
        // NOTE: min and max sizes match extension
        const minSize = this.words ? 3 : 8;
        const defaultSize = this.words ? 3 : 20;
        const maxSize = this.words ? 10 : 128;
        this.size = defaultSize;
        this.$toast.error(
          `Password ${
            this.words ? "word count" : "length"
          } must be between ${minSize} and ${maxSize}.`
        );
      }
      this.$emit("close");
    },
  },
  computed: {
    strengthMessage() {
      let message = "";

      switch (this.strength) {
        case 4:
          message = "Strong";
          break;
        case 3:
          message = "Fair";
          break;
        case 2:
        case 1:
          message = "Weak";
          break;
      }

      return message;
    },
  },
  watch: {
    async value(newValue) {
      if (newValue) {
        const { default: zxcvbn } = await import(
          /* webpackChunkName: "zxcvbn" */ "zxcvbn"
        );
        const calc = zxcvbn(newValue);
        this.strength = calc.score;
        return;
      }

      this.strength = 0;
    },
    words(value) {
      if (value) {
        this.numbers = false;
        this.disabled = ["letters", "numbers"];
        this.previousSize = this.size;
        this.size = 3;
      } else {
        this.disabled = [];
        this.size = this.previousSize;
      }
    },
  },
};
</script>

<style lang="scss">
.cloak-generate-password-flyout {
  position: absolute;
  inset: 0;

  .popper__activator {
    position: absolute;
    inset: 0;
    cursor: pointer;
  }

  .strength-menu-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: $color-primary-70;
    margin: 0;
    padding: 6px 10px 0px 10px;
  }

  .strength-meter {
    font-size: 12px;
    padding: 0px 10px 4px 10px;
    --progress-length: 0%;

    &__progress {
      height: 6px;
      width: 100%;
      background: $color-primary-20;
      border-radius: 2px;
      position: relative;
      overflow: hidden;

      &::after {
        display: block;
        content: "";
        top: 0;
        left: 0;
        width: var(--progress-length);
        height: 100%;
        background-color: $color-alert;
        transition: all 0.2s ease-in-out;
      }
    }

    &__message {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: $color-primary-100;
      margin-top: 6px;
    }

    &--none {
      --progress-length: 0%;
    }

    &--low {
      --progress-length: 20%;

      .strength-meter__progress {
        &::after {
          background-color: $color-alert;
        }
      }
    }

    &--medium {
      --progress-length: 70%;

      .strength-meter__progress {
        &::after {
          background-color: $color-warning;
        }
      }
    }

    &--high {
      --progress-length: 100%;

      .strength-meter__progress {
        &::after {
          background-color: $color-success;
        }
      }
    }
  }

  .password-length {
    display: flex;
    justify-content: space-between;
    padding: 6px 10px;
    align-items: center;

    &__label {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $color-primary-100;
    }

    &__controls {
      display: flex;
      align-items: center;
      gap: 8px;

      input {
        width: 36px;
        height: 24px;
        text-align: center;
        border: 1px solid $color-primary-10;
        border-radius: 2px;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: $color-primary-100;
        background-color: transparent;
      }

      button {
        width: 24px;
        height: 24px;
        display: flex;
        border: none;
        background: none;
        padding: 0;
        align-items: center;
        justify-content: center;
        color: $color-primary-100;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
