var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "forwarding-email" },
    [
      _vm.step < 2 ? _c("Email") : _vm._e(),
      _vm.step === -1
        ? _c(
            "div",
            [
              _vm.step === -1
                ? _c(
                    "AppModalTitle",
                    { staticClass: "forwarding-phone__choice-title" },
                    [
                      _vm._v(
                        " Where should emails sent to your Cloaked email addresses go? "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "fieldset",
                { staticClass: "forwarding-phone__forwarding-choice" },
                [
                  _c(
                    "OnboardingInputRichRadio",
                    _vm._g(
                      {
                        ref: "radioOne",
                        attrs: {
                          checked: _vm.emailForwarding === "cloaked",
                          "pre-title": "RECOMMENDED",
                          title: "Keep emails in Cloaked",
                          text: "Emails only go to your Cloaked inbox",
                          id: "cloaked",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$emit(
                              "input-email-forwarding",
                              $event.target.value
                            )
                          },
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "down", 40, $event.key, [
                                "Down",
                                "ArrowDown",
                              ])
                            )
                              return null
                            return _vm.$emit("input-email-forwarding", "email")
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "image",
                              fn: function () {
                                return [_c("EmailForwardingCloaked")]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3427492307
                        ),
                      },
                      _vm.$listeners
                    )
                  ),
                  _c(
                    "OnboardingInputRichRadio",
                    _vm._g(
                      {
                        attrs: {
                          title: "Forward email to your personal email address",
                          text: "Emails go to your Cloaked inbox and are also forwarded to your personal\n        email inbox",
                          id: "email",
                          checked: _vm.emailForwarding === "email",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$emit(
                              "input-email-forwarding",
                              $event.target.value
                            )
                          },
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "up", 38, $event.key, [
                                "Up",
                                "ArrowUp",
                              ])
                            )
                              return null
                            return _vm.$emit(
                              "input-email-forwarding",
                              "cloaked"
                            )
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "image",
                              fn: function () {
                                return [_c("EmailForwardingPersonal")]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          889353248
                        ),
                      },
                      _vm.$listeners
                    )
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.step === 2
        ? _c("EmailDone", { staticClass: "forwarding-email__done" })
        : _vm._e(),
      _vm.step === 0
        ? _c("AppModalTitle", [_vm._v(" Add an email address ")])
        : _vm.step === 1
        ? _c("AppModalTitle", [_vm._v(" Verify your email address ")])
        : _vm.step === 2
        ? _c("AppModalTitle", { staticClass: "forwarding-email__verified" }, [
            _vm._v(" Your email address has been verified "),
          ])
        : _vm._e(),
      _vm.step === 0
        ? _c("AppModalParagraph", [
            _vm._v(
              " Emails sent to your Cloaked email addresses will be forwarded to this email address "
            ),
          ])
        : _vm.step === 1
        ? _c("AppModalParagraph", [
            _vm._v(" Enter the 6-digit code sent to "),
            _c("strong", [_vm._v(_vm._s(_vm.email))]),
            _c("br"),
            _c(
              "button",
              {
                staticClass: "forwarding-email__resend",
                on: {
                  click: function ($event) {
                    return _vm.$emit("resend")
                  },
                },
              },
              [_vm._v(" Resend code ")]
            ),
          ])
        : _vm.step === 2
        ? _c(
            "AppModalParagraph",
            { staticClass: "forwarding-email__verified-subtitle" },
            [
              _vm._v(
                " Emails sent to your Cloaked email addresses will be forwarded to "
              ),
              _c("strong", [_vm._v(_vm._s(_vm.email))]),
              _vm._v(
                ". You can change this setting for individual Cloaked identities. "
              ),
            ]
          )
        : _vm._e(),
      _vm.step === 0
        ? _c(
            "OnboardingInputEmail",
            _vm._g(
              {
                attrs: { value: _vm.email, placeholder: "Email" },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input-email", $event.target.value)
                  },
                },
              },
              _vm.$listeners
            )
          )
        : _vm.step === 1
        ? _c(
            "OnboardingInputCode",
            _vm._g(
              {
                attrs: { value: _vm.emailCode },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input-email-code", $event)
                  },
                },
              },
              _vm.$listeners
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }