var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._g(
      _vm._b(
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: {
                handler: _setup.onOutsideClick,
                events: ["contextmenu", "focusin", "mousedown"],
              },
              expression:
                "{\n    handler: onOutsideClick,\n    events: ['contextmenu', 'focusin', 'mousedown'],\n  }",
            },
          ],
          staticClass: "popper",
          on: {
            mouseenter: _setup.onMouseEnter,
            mouseleave: _setup.onMouseLeave,
          },
        },
        "div",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        {
          ref: "activator",
          staticClass: "popper__activator",
          on: {
            click: _setup.onClick,
            contextmenu: function ($event) {
              $event.preventDefault()
              return _setup.onContextMenu.apply(null, arguments)
            },
          },
        },
        [_vm._t("default")],
        2
      ),
      _setup.internalValue
        ? _c(
            _vm.portal ? "portal" : "div",
            { tag: "component", attrs: { to: _vm.portal } },
            [
              _c(
                "div",
                {
                  key: _setup.uniqueKey,
                  ref: "content",
                  staticClass: "popper__content",
                  style: {
                    width: _vm.width,
                    height: _vm.height,
                    maxHeight: _vm.maxHeight,
                    zIndex: _vm.zIndex,
                  },
                  on: { click: _setup.onContentClick },
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: _vm.transition, appear: "" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _setup.internalValue,
                              expression: "internalValue",
                            },
                          ],
                          ref: "slot",
                          style: {
                            width: _vm.width,
                            height: _vm.height,
                            maxHeight: _vm.maxHeight,
                          },
                        },
                        [_vm._t("content")],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }