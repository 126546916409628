var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ModalTemplate, {
    staticClass: "contacts-modal",
    attrs: { show: true },
    on: { close: _setup.handleClose },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _setup.state.mode === _setup.VIEW
              ? _c("div", { staticClass: "contacts-header" }, [
                  _c("div", [_c("h1", [_vm._v("Contact Details")])]),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "action-btn",
                        on: {
                          click: function ($event) {
                            return _setup.handleChangeMode(_setup.EDIT)
                          },
                        },
                      },
                      [_vm._v("Edit")]
                    ),
                    _c("span", [_vm._v("|")]),
                    _setup.state.status === _setup.BLOCKED
                      ? _c(
                          "span",
                          {
                            staticClass: "action-btn",
                            on: {
                              click: function ($event) {
                                return _setup.handleChangeStatus(_setup.APPROVE)
                              },
                            },
                          },
                          [_vm._v("Unblock")]
                        )
                      : _c(
                          "span",
                          {
                            staticClass: "action-btn",
                            on: {
                              click: function ($event) {
                                return _setup.handleChangeMode(_setup.BLOCK)
                              },
                            },
                          },
                          [_vm._v("Block")]
                        ),
                  ]),
                ])
              : _setup.state.mode === _setup.EDIT
              ? _c("div", { staticClass: "contacts-header" }, [
                  _c("div", [_c("h1", [_vm._v("Edit Contact Details")])]),
                  _c("div", [
                    _setup.state.status === _setup.BLOCKED
                      ? _c(
                          "span",
                          {
                            staticClass: "action-btn",
                            on: {
                              click: function ($event) {
                                return _setup.handleChangeStatus(_setup.APPROVE)
                              },
                            },
                          },
                          [_vm._v("Unblock")]
                        )
                      : _c(
                          "span",
                          {
                            staticClass: "action-btn",
                            on: {
                              click: function ($event) {
                                return _setup.handleChangeMode(_setup.BLOCK)
                              },
                            },
                          },
                          [_vm._v("Block")]
                        ),
                  ]),
                ])
              : _setup.state.mode === _setup.BLOCK
              ? _c("div", { staticClass: "contacts-header" }, [
                  _c("div", [
                    _c("h1", [
                      _vm._v(_vm._s(_setup.modeDisplay) + " this contact?"),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c(_setup.ContactIcon, {
                  attrs: {
                    initials: _setup.getExternalContactInitialsDisplay(),
                    associatedIdentityId: _setup.state.associatedIdentityId,
                    override: { width: "72px", height: "72px" },
                  },
                }),
              ],
              1
            ),
            _setup.state.mode === _setup.VIEW ||
            _setup.state.mode === _setup.EDIT
              ? _c(
                  "div",
                  { staticClass: "contact-body" },
                  [
                    _setup.state.mode === _setup.VIEW
                      ? _c(
                          "span",
                          { staticClass: "contact-saved-name" },
                          [
                            _vm._v(
                              _vm._s(
                                _setup.contactName || _setup.state.cloakedValue
                              ) + " "
                            ),
                            _setup.state.status === _setup.BLOCKED
                              ? _c(_setup.BlockContact)
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _setup.state.mode === _setup.EDIT
                      ? _c(
                          "span",
                          { staticClass: "contact-saved-name" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _setup.state.firstName,
                                  expression: "state.firstName",
                                },
                              ],
                              ref: "firstName",
                              staticClass: "input-contact-name",
                              attrs: {
                                type: "text",
                                placeholder: "First",
                                title: _setup.state.firstName,
                                autocomplete: "off",
                                maxlength: "50",
                                "data-lpignore": "true",
                              },
                              domProps: { value: _setup.state.firstName },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _setup.handleSaveChanges.apply(
                                    null,
                                    arguments
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _setup.state,
                                    "firstName",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _setup.state.lastName,
                                  expression: "state.lastName",
                                },
                              ],
                              ref: "lastName",
                              staticClass: "input-contact-name",
                              attrs: {
                                type: "text",
                                placeholder: "Last",
                                title: _setup.state.lastName,
                                autocomplete: "off",
                                maxlength: "50",
                                "data-lpignore": "true",
                              },
                              domProps: { value: _setup.state.lastName },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _setup.handleSaveChanges.apply(
                                    null,
                                    arguments
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _setup.state,
                                    "lastName",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _setup.state.status === _setup.BLOCKED
                              ? _c(_setup.BlockContact)
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      _setup.ExpansionPanel,
                      {
                        attrs: {
                          buttonText: _setup.originalValueLabel,
                          duration: 200,
                          onClick: _setup.toggleOriginalValueBtn,
                        },
                      },
                      [
                        [
                          _c("div", { staticClass: "exp-content" }, [
                            _c("h5", [
                              _vm._v(_vm._s(_setup.state.originalValue)),
                            ]),
                            _c("h6", { staticClass: "modal-caption" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_setup.originalValueExplainer) +
                                  " "
                              ),
                            ]),
                          ]),
                        ],
                      ],
                      2
                    ),
                    _setup.state.status === _setup.BLOCKED
                      ? _c(
                          "div",
                          { staticClass: "blocked-contact" },
                          _vm._l(
                            _setup.blockedContactExplainerParas,
                            function (blockedContactExplainer, idx) {
                              return _c(
                                "h6",
                                { key: idx, staticClass: "modal-caption" },
                                [
                                  _vm._v(
                                    " " + _vm._s(blockedContactExplainer) + " "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _c("div", [
                          _c(
                            "h6",
                            {
                              staticClass: "modal-caption cloaked-value-label",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_setup.cloakedValueLabel) + " "
                              ),
                            ]
                          ),
                          _c("h5", [_vm._v(_vm._s(_setup.state.cloakedValue))]),
                          _c("h6", { staticClass: "modal-caption" }, [
                            _vm._v(
                              " " + _vm._s(_setup.cloakedValueExplainer) + " "
                            ),
                          ]),
                        ]),
                  ],
                  1
                )
              : _setup.state.mode === _setup.BLOCK
              ? _c("div", { staticClass: "block-contact" }, [
                  _c("h6", { staticClass: "modal-caption" }, [
                    _c("span", [_vm._v(_vm._s(_setup.contactName))]),
                    _vm._v("  "),
                    _c("span", [
                      _vm._v("(" + _vm._s(_setup.state.cloakedValue) + ")"),
                    ]),
                    _vm._v(
                      " will no longer be able to contact you unless you approve them again. "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _setup.state.mode === _setup.EDIT
              ? [
                  _c(
                    _setup.Button,
                    {
                      attrs: { type: "secondary" },
                      on: { click: _setup.handleCancel },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    _setup.Button,
                    { on: { click: _setup.handleSaveChanges } },
                    [_vm._v(" Done ")]
                  ),
                ]
              : _setup.state.mode === _setup.BLOCK
              ? [
                  _c(
                    _setup.Button,
                    {
                      attrs: { type: "secondary" },
                      on: {
                        click: function ($event) {
                          return _setup.handleChangeMode(_setup.VIEW)
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    _setup.Button,
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _setup.handleChangeStatus(_setup.state.mode)
                        },
                      },
                    },
                    [_vm._v(" Yes, " + _vm._s(_setup.state.mode) + " ")]
                  ),
                ]
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }