<script setup>
import AutoChangePage from "@/components/auto-change/AutoChangePage.vue";
import store from "@/store";
import { computed, onBeforeMount, ref, watch } from "vue";
import router from "@/routes/router";

const autoChangeSupport = computed(
  () => store.getters["autoChange/getAutoChangeSupport"]
);

const autoChangeRequests = computed(
  () => store.getters["autoChange/getAutoChangeRequests"]
);

const autoChangeProgress = computed(
  () => store.getters["autoChange/getAutoChangeProgress"]
);

const autoChangeVerificationProgress = computed(
  () => store.getters["autoChange/getAutoChangeVerificationProgress"]
);

const autoChangeUpdatesProgress = computed(
  () => store.getters["autoChange/getAutoChangeUpdatesProgress"]
);

const autoChangeAbleItems = ref([]);
const autoChangeRequest = ref([]);

const orderedAutoChangeAbleItems = (items) => {
  const websiteCount = {};

  items.forEach((item) => {
    if (item.identityWebsite) {
      websiteCount[item.identityWebsite] =
        (websiteCount[item.identityWebsite] ?? 0) + 1;
    }
  });

  return [...items].sort((a, b) => {
    const relativeOrderBasedOnSiteCount =
      (websiteCount[b.identityWebsite ?? ""] ?? 0) -
      (websiteCount[a.identityWebsite ?? ""] ?? 0);
    const relativeOrderBasedOnFieldCount = b.fields.length - a.fields.length;
    const relativeOrderBasedOnWebsite =
      (b.identityWebsite ?? "") > (a.identityWebsite ?? "") ? -1 : 0;
    const relativeOrderBasedOnNickname =
      (b.identityNickname ?? "") > (a.identityNickname ?? "") ? -1 : 0;

    return (
      relativeOrderBasedOnSiteCount ||
      relativeOrderBasedOnFieldCount ||
      relativeOrderBasedOnWebsite ||
      relativeOrderBasedOnNickname
    );
  });
};

const prepareAutoChangeRequest = async (identities) => {
  const newAutoChangeAbleItems = await Promise.all(
    identities.map(
      (identity) =>
        new Promise((resolve) => {
          resolve({
            identityId: identity.id,
            identityWebsite: identity.website_url ?? null,
            identityNickname: identity.nickname ?? null,
            identityLogo: identity.logo_url ?? null,
            identityColor: identity.cloak_brand_color ?? null,
            fields: store.getters["autoChange/getPresentSupportedFieldTypes"](
              identity.id
            ).map((fieldType) => ({
              fieldType,
              oldValue: identity[fieldType].value,
            })),
          });
        })
    )
  );

  autoChangeAbleItems.value = orderedAutoChangeAbleItems(
    newAutoChangeAbleItems
  );

  autoChangeRequest.value = autoChangeRequests.value
    ? autoChangeAbleItems.value
        .filter((identity) =>
          autoChangeRequests.value.some(
            (request) => request.identityId === identity.identityId
          )
        )
        .map((identity) => {
          const request = autoChangeRequests.value.find(
            (request) => request.identityId === identity.identityId
          );

          return {
            ...identity,
            fields: identity.fields.filter((field) =>
              request?.change.includes(field.fieldType)
            ),
          };
        })
    : [];
};

const unwatch = watch(
  () => store.getters["autoChange/autoChangeAbleIdentitiesWithDetails"],
  async (newValue) => {
    if (newValue?.length) {
      await prepareAutoChangeRequest(newValue);
      unwatch();
    }
  },
  { immediate: true }
);

const onStartOver = async () => {
  await store.dispatch("autoChange/startOver");
  return prepareAutoChangeRequest(
    store.getters["autoChange/autoChangeAbleIdentitiesWithDetails"]
  );
};

const onCellClick = ({ identityId, fieldType }) => {
  if (
    autoChangeProgress.value
      .find((item) => item.identityId === identityId)
      .fields.find((field) => field.fieldType === fieldType)?.status ===
      "needsReview" ||
    autoChangeVerificationProgress.value
      .find((item) => item.identityId === identityId)
      .fields.find((field) => field.fieldType === fieldType)?.status ===
      "needsReview"
  ) {
    store.dispatch("autoChange/reviewItem", {
      identityId,
      fieldType,
    });
  }
};

const autoChangeItems = computed(() => [
  ...(autoChangeProgress.value ?? []).map((item) => {
    const originalItem = (autoChangeAbleItems.value ?? []).find(
      (autoChangeAbleItem) => autoChangeAbleItem.identityId === item.identityId
    );

    return {
      ...item,
      fields:
        originalItem?.fields.map(
          (field) =>
            item.fields.find(
              (progressField) => progressField.fieldType === field.fieldType
            ) ?? field
        ) ?? [],
    };
  }),
  ...(autoChangeAbleItems.value ?? []).filter(
    (item) =>
      !(autoChangeProgress.value ?? [])
        .map((item) => item.identityId)
        .includes(item.identityId)
  ),
]);

onBeforeMount(() => {
  if (!store.getters.isV2User) {
    router.push({ name: "All" });
    return;
  }

  store.dispatch("closeRightPanel");
  store.getters["autoChange/getAutoChangeProgress"].length ||
    store.dispatch("autoChange/fetchIdentities");
});
</script>

<template>
  <AutoChangePage
    :items="autoChangeItems"
    :verification="autoChangeVerificationProgress"
    :updates="autoChangeUpdatesProgress"
    :support="autoChangeSupport"
    :is-loading="$store.getters['autoChange/getIsFetching']"
    :enabled="$store.getters['autoChange/getAutoChangeEnabled']"
    v-model="autoChangeRequest"
    @auto-change="$store.dispatch('autoChange/autoChange', autoChangeRequest)"
    @stop-auto-change="$store.dispatch('autoChange/autoChangeStop')"
    @start-over="onStartOver"
    @cell-click="onCellClick"
    @import-more="$router.push({ name: 'Import' })"
  />
</template>
