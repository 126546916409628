<template>
  <PreferencesPanel v-if="showNewMenu">
    <ValueDisplay
      v-if="!primaryPhone"
      label="Add a recovery phone number"
      @add="handleAddPhoneNumber"
      @click="handleAddPhoneNumber"
    />
    <ValueDisplay
      v-else
      label="Recovery phone number"
      :value="formattedPhoneNumber"
      @edit="handleAddPhoneNumber"
      @delete="handleDelete(primaryPhone.id)"
    />
  </PreferencesPanel>
  <PreferencesPanel v-else>
    <template v-slot:header>
      <PreferencesHeader @go-back="toggle_back" />
    </template>

    <PreferencesTitle>Recovery phone number</PreferencesTitle>

    <PreferencesParagraph v-if="!primaryPhone">
      Add a recovery phone number to ensure you can access your Cloaked account.
    </PreferencesParagraph>

    <ValueDisplay
      v-else
      :value="formattedPhoneNumber"
      @delete="handleDelete(primaryPhone.id)"
    />

    <Button @click="handleAddPhoneNumber">
      {{ primaryPhone ? "Use a different number" : "Add a phone number" }}
    </Button>
  </PreferencesPanel>
</template>

<script>
import { ACCOUNT_RECOVERY_NEW_MENU } from "@/scripts/featureFlags";
import api from "@/api/api";
import { mapActions } from "vuex";
import ValueDisplay from "@/components/ui/value-display";
import {
  PreferencesHeader,
  PreferencesTitle,
  PreferencesParagraph,
  PreferencesPanel,
} from "@/routes/modals/preferences";
import { Button } from "@/components";
import { AddVerifyPhone } from "@/components/modals";
import { phone_format } from "@/scripts/format";

export default {
  name: "AccountRecoveryPhone",
  props: ["phones"],
  components: {
    Button,
    PreferencesHeader,
    PreferencesTitle,
    PreferencesParagraph,
    PreferencesPanel,
    ValueDisplay,
  },

  computed: {
    primaryPhone() {
      return this.phones.find((item) => item.verified && item.primary);
    },
    formattedPhoneNumber() {
      return phone_format(this.primaryPhone.phone_number);
    },
    showNewMenu() {
      return ACCOUNT_RECOVERY_NEW_MENU;
    },
  },

  methods: {
    ...mapActions(["openModal", "openGlobalDeleteModal"]),

    toggle_back() {
      this.$emit("toggleBack", {});
    },

    handleAddPhoneNumber() {
      this.openModal({
        customTemplate: {
          template: AddVerifyPhone,
          props: {
            setPrimary: true,
            title: this.primaryPhone
              ? "Enter a new recovery phone number"
              : "Add a phone number",
          },
          events: {
            "phone-verified": this.refresh,
            "phone-created": this.refresh,
          },
        },
      });
    },

    handleDelete(phoneId) {
      const phone = this.phones.find((item) => item.id === phoneId);

      this.openGlobalDeleteModal({
        type: "phone",
        onClick: () => {
          api()
            .delete(phone.url)
            .then(() => {
              this.$emit("delete", phoneId);
              this.$toast.success("Phone number deleted");
            })
            .catch(() => {
              this.$toast.error(
                "Phone number is in use for 2FA. To delete, please remove from 2FA first."
              );
            });
        },
      });
    },

    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>
