var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    ref: "iframe",
    style: `height: ${_vm.height}px;`,
    attrs: {
      srcdoc: _vm.iframeContent,
      allowtransparency: "true",
      frameborder: "0",
      scrolling: "no",
      sandbox: "allow-same-origin allow-popups allow-popups-to-escape-sandbox",
    },
    on: { load: _vm.resize },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }