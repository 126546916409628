var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "password-check", class: _vm.passwordStrength },
    [
      _c("ul", [
        _c(
          "li",
          {
            class: {
              success: _vm.passwordHasCorrectLength,
              error: _vm.passwordHasValue && !_vm.passwordHasCorrectLength,
            },
          },
          [
            _vm.passwordHasCorrectLength ? _c("Check") : _c("span"),
            _vm._v(" Between 12 and 256 characters "),
          ],
          1
        ),
        _c(
          "li",
          {
            class: {
              success: _vm.passwordHasUpperCase,
              error: _vm.passwordHasValue && !_vm.passwordHasUpperCase,
            },
          },
          [
            _vm.passwordHasUpperCase ? _c("Check") : _c("span"),
            _vm._v(" 1 Uppercase letter "),
          ],
          1
        ),
        _c(
          "li",
          {
            class: {
              success: _vm.passwordHasLowerCase,
              error: _vm.passwordHasValue && !_vm.passwordHasLowerCase,
            },
          },
          [
            _vm.passwordHasLowerCase ? _c("Check") : _c("span"),
            _vm._v(" 1 Lowercase letter "),
          ],
          1
        ),
        _c(
          "li",
          {
            class: {
              success: _vm.passwordHasNumber,
              error: _vm.passwordHasValue && !_vm.passwordHasNumber,
            },
          },
          [
            _vm.passwordHasNumber ? _c("Check") : _c("span"),
            _vm._v(" 1 Number "),
          ],
          1
        ),
        _c(
          "li",
          {
            class: {
              success: _vm.passwordHasSymbol,
              error: _vm.passwordHasValue && !_vm.passwordHasSymbol,
            },
          },
          [
            _vm.passwordHasSymbol ? _c("Check") : _c("span"),
            _vm._v(" 1 Symbol "),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }