<script setup>
import BorderInputSwitch from "@/components/BorderInputSwitch.vue";
</script>

<template>
  <BorderInputSwitch
    title="Sensitive Information"
    text="Hide this content by default"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>
