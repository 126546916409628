<script setup>
import { onMounted } from "vue";
import { HAS_SEEN_CLOAKED_DAILY } from "@/scripts/userFlags";
import UserService from "@/api/actions/user-service";
import store from "@/store";

onMounted(() => {
  store.commit("setCloseRightPanel");
  UserService.setFlag({
    name: HAS_SEEN_CLOAKED_DAILY,
    value: true,
  });
});
</script>
<template>
  <div class="cd-wrapper">
    <iframe
      src="https://daily.cloaked.app"
      title="Cloaked Daily - The latest news and updates from Cloaked"
      allowtransparency="true"
      allow="clipboard-read; clipboard-write"
      frameborder="0"
      height="100%"
      width="100%"
    ></iframe>
  </div>
</template>
<style lang="scss" scoped>
.cd-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
