<script setup>
import Popper from "@/components/Popper.vue";
</script>

<template>
  <Popper v-bind="$attrs" v-on="$listeners" class="ui-menu">
    <slot />
    <template #content>
      <div class="ui-menu__content">
        <slot name="content" />
      </div>
    </template>
  </Popper>
</template>

<style lang="scss">
.ui-menu {
  &__content {
    box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
      13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
    background: $color-background;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: auto;
    height: 100%;
  }
}
</style>
