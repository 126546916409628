<script setup>
import Iframe from "./Iframe";
import { logout } from "@/scripts/actions/auth";
import store from "@/store";
import router from "@/routes/router";

import { onMounted, computed } from "vue";

import { useRoute } from "vue-router/composables";
const route = useRoute();

onMounted(() => {
  // unless it's the extension
  //add back the exception if they have query params from extension like client id
  //And log the user out if you detect extension.
  if (store.getters["authentication/isAuthenticated"] && auth_route) {
    if (
      route.query &&
      (route.query.cloaked_client_id ||
        route.query.signup_key ||
        route.query.utm_campaign)
    ) {
      logout();
    } else {
      router.push({ path: "/" });
    }
  }
});

const auth_route = computed(() => {
  return route.path.match(/auth/gi);
});
</script>

<template>
  <Iframe source="auth/login" />
</template>
