var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.WelcomeView, { on: { toggleVisible: _setup.toggleVisible } }),
      _c(_setup.KycFormView, {
        class: { open: _setup.kycVisible },
        on: { toggleVisible: _setup.toggleVisible },
      }),
      _c(_setup.IdentityVerificationModal, {
        attrs: { show: _setup.modal },
        on: {
          close: function ($event) {
            return _setup.toggleModal()
          },
          goBack: function ($event) {
            return _setup.goBack()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }