<template>
  <div class="settings-page">
    <SettingsNavigation />

    <div class="settings-page__children">
      <router-view />
    </div>
  </div>
</template>

<script>
import { SettingsNavigation } from "@/components";

export default {
  name: "SettingsPage",
  components: {
    SettingsNavigation,
  },
};
</script>

<style lang="scss" scoped>
.settings-page {
  display: flex;
  height: 100%;
  overflow: hidden;

  .app--visible-banner & {
    height: calc(100% - 40px);
    margin-top: 41px;
  }

  &__children {
    flex: 1 1 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
