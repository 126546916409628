var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    _vm._g({ staticClass: "auto-change-identity" }, _vm.$listeners),
    [
      _c(
        "span",
        { staticClass: "auto-change-identity__left" },
        [
          _c("IdentityIcon", {
            staticClass: "auto-change-identity__icon",
            attrs: {
              identity: { logo_url: _vm.logo, cloak_brand_color: _vm.color },
              override: {
                width: "32px",
                height: "32px",
              },
            },
          }),
          _vm._t("left"),
        ],
        2
      ),
      _c("span", [
        _vm.nickname
          ? _c("span", { staticClass: "auto-change-identity__nickname" }, [
              _vm._v(" " + _vm._s(_vm.nickname) + " "),
            ])
          : _vm._e(),
        _vm.website
          ? _c("span", { staticClass: "auto-change-identity__website" }, [
              _vm._v(" " + _vm._s(_vm.website) + " "),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }