// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-21.use[1]!../../../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-21.use[2]!../../../node_modules/@typeform/embed/build/css/widget.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":export {\n  defaultWhite: #FFFFFF;\n  primaryWhiteLt: var(--color-primary-100);\n  primaryWhite10Lt: var(--color-primary-10);\n}\n.app-survey-typeform {\n  position: fixed;\n  z-index: 100;\n  bottom: 10px;\n  right: 10px;\n}\n.app-survey-typeform__close {\n  cursor: pointer;\n  position: absolute;\n  right: 0;\n  top: -45px;\n  background-color: var(--color-primary-0);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 40px;\n  height: 40px;\n  border: none;\n  border-radius: 100%;\n}\n.app-survey-typeform__close:hover {\n  background-color: var(--color-primary-5);\n}\n.app-survey-typeform__close-icon {\n  color: var(--color-primary-100);\n  width: 16px;\n  height: 16px;\n}\n.app-survey-typeform__container {\n  height: 100%;\n  box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05), 13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);\n  border-radius: 15px;\n  overflow: hidden;\n  display: flex;\n}\n.app-survey-typeform--dark .app-survey-typeform__container {\n  background-color: var(--color-primary-100);\n}\n.app-survey-typeform--light .app-survey-typeform__container {\n  background-color: var(--color-primary-0);\n}\n.app-survey-typeform__thank-you {\n  padding: 40px;\n  text-align: center;\n  font-size: 20px;\n  line-height: 28px;\n  margin: auto;\n}\n.app-survey-typeform--dark .app-survey-typeform__thank-you {\n  background-color: var(--color-primary-100);\n  color: var(--color-primary-0);\n}\n.app-survey-typeform--light .app-survey-typeform__thank-you {\n  background-color: var(--color-primary-0);\n  color: var(--color-primary-100);\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
