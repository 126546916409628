import { humanize } from "@/scripts/timestamp_format";

export default {
  namespaced: true,
  state: {
    prevRouteName: "All",
    personalEmails: [],
    personalPhones: [],
    subscription: null,
    limits: null,
    stripe: null,
  },

  mutations: {
    setPrevRouteName: (state, routeName) => {
      state.prevRouteName = routeName;
    },
    setPersonalEmails: (state, emails) => {
      state.personalEmails = emails;
    },
    addNewPersonalEmail: (state, email) => {
      state.personalEmails = [...state.personalEmails, email];
    },
    setPersonalPhones: (state, phones) => {
      state.personalPhones = phones;
    },
    setSubscription(state, value) {
      state.subscription = value;
    },
    setStripe(state, value) {
      state.stripe = value;
    },
    setLimits(state, value) {
      state.limits = value;
    },
  },
  getters: {
    getPrevRouteName: (state) => {
      return state.prevRouteName;
    },
    getPersonalEmails: (state) => {
      return state.personalEmails;
    },
    getVerifiedEmails: (state) => {
      return state.personalEmails.filter((e) => e.verified);
    },
    getPersonalPhones: (state) => {
      return state.personalPhones;
    },
    getVerifiedPhones: (state) => {
      return state.personalPhones.filter((e) => e.verified);
    },
    getSubscription(state) {
      return state.subscription;
    },
    isSubscribed(state) {
      return state.subscription?.state === "PAID";
    },
    isCancelled(state) {
      return state.subscription?.state === "CANCELED";
    },
    isTrial(state) {
      return state.subscription?.state === "NEW";
    },
    expiresIn(state) {
      if (state.subscription.expires_date) {
        return humanize(state.subscription.expires_date);
      }
      return "";
    },
    getStripe(state) {
      return state.stripe;
    },
    getLimits(state) {
      return state.limits;
    },
    getPhoneNumberRemaining(state) {
      const limits = state.limits;
      if (limits) {
        return limits.max_phone_numbers - limits.current_phone_usage;
      }
      return -1;
    },
  },
  actions: {
    savePrevRouteName({ commit }, prevRouteName) {
      commit("setPrevRouteName", prevRouteName);
    },
    resetPrevRouteName({ commit }) {
      commit("setPrevRouteName", "All");
    },
    savePersonalEmails({ commit }, emails) {
      commit("setPersonalEmails", emails);
    },
    saveNewPersonalEmail({ commit }, email) {
      commit("addNewPersonalEmail", email);
    },
    savePersonalPhones({ commit }, phones) {
      commit("setPersonalPhones", phones);
    },
    saveSubscription({ commit }, subscription) {
      commit("setSubscription", subscription);
    },
    saveStripe({ commit }, stripeData) {
      commit("setStripe", stripeData);
    },
    saveLimits({ commit }, limitData) {
      commit("setLimits", limitData);
    },
  },
};
