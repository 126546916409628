<template>
  <div class="pill-input" @click="selectInput">
    <div class="pill-display">
      <div
        class="pill"
        v-for="(pill, k) in pills"
        :key="k"
        :class="{ pill_error: !pill.status }"
        :aria-id="`Pill-${pill?.value || ''}`"
      >
        {{ pill.display || pill.value }}
        <button @click="remove(k)">
          <RemoveIcon />
        </button>
      </div>
      <div class="actual">
        <div>{{ value }}</div>
        <div class="border"></div>
      </div>
    </div>
    <input
      type="text"
      ref="input"
      tabindex="0"
      v-model="value"
      @keydown="onkey"
      @blur="onblur"
    />
  </div>
</template>
<script>
import RemoveIcon from "@/assets/icons/remove.svg";
import { phone, formatMultiplePillEntries } from "@/scripts/validation";
import { format_phone_string } from "@/scripts/format";

export default {
  name: "PillInput",
  props: ["filter", "defaultValue", "multiple"],
  components: {
    RemoveIcon,
  },
  data() {
    return {
      disabled: false,
      pills: this.defaultValue ? this.defaultValue : [],
      value: "",
    };
  },
  watch: {
    pills(value) {
      if (!this.disabled) {
        this.value = "";
        this.$emit("value", value);
      }
    },
  },
  methods: {
    selectInput() {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
    remove(k) {
      this.pills = [...this.pills].filter((a, b) => b !== k);
    },
    setValue(value) {
      this.pills = value;
    },
    onblur() {
      if (this.value.trim()) {
        let value = this.value.trim();
        /* if incoming emails or phone numbers are copy/pasted without commas or spaces */
        value = formatMultiplePillEntries(value);
        if (typeof value === "object") {
          value.forEach((item) => {
            if (phone(item)) {
              item = format_phone_string(item);
            }
            this.pills = [
              ...this.pills,
              {
                value: item,
                status: this.filter ? this.filter(item) : true,
              },
            ];
          });
        } else {
          if (phone(value)) {
            value = format_phone_string(value);
          }
          this.pills = [
            ...this.pills,
            {
              value: value,
              status: this.filter ? this.filter(value) : true,
            },
          ];
        }
      }
    },
    recalculate() {
      this.disabled = true;
      this.$nextTick(() => {
        this.pills = [...this.pills].map((item) => ({
          value: item.value,
          status: this.filter ? this.filter(item.value) : true,
        }));
        this.$nextTick(() => {
          this.disabled = false;
        });
      });
    },
    onkey(event) {
      if (event.key.match(/[\s\b,]/) || event.key === "Enter") {
        if (this.value.trim().length > 0) {
          let value = this.value.trim();
          /* if incoming emails or phone numbers are copy/pasted without commas or spaces */
          value = formatMultiplePillEntries(value);
          if (typeof value === "object") {
            value.forEach((item) => {
              if (phone(item)) {
                item = format_phone_string(item);
              }
              this.pills = [
                ...this.pills,
                {
                  value: item,
                  status: this.filter ? this.filter(item) : true,
                },
              ];
            });
          } else {
            if (phone(value)) {
              value = format_phone_string(value);
            }
            this.pills = [
              ...this.pills,
              {
                value: value,
                status: this.filter ? this.filter(value) : true,
              },
            ];
          }
          event.preventDefault();
        }
      }
      if (event.key === "Backspace") {
        if (this.value.length === 0) {
          const copy = [...this.pills];
          copy.pop();
          this.pills = copy.map((value) => ({
            ...value,
            status: this.filter ? this.filter(value) : true,
          }));
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pill-input {
  overflow: hidden;
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  padding: 3px 7px;
  min-height: 24px;
  border: none;
  color: $color-primary-100;
  .pill-display {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .pill {
      background-color: $color-primary-10;
      &:hover {
        svg {
          cursor: pointer;
        }
      }
      padding: 3px 5px 3px 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $color-primary-100;
      border-radius: 999px;
      display: flex;
      gap: 5px;
      align-items: center;
      &.pill_error {
        background-color: $color-alert;
        color: $color-primary-0;
        svg {
          path {
            fill: #fff !important;
          }
          fill: #fff !important;
        }
      }
      button {
        background-color: transparent;
        border: none;
        display: inline-block;
        text-transform: uppercase;
        height: 14px;
        width: 14px;
        margin-right: 8px;
        margin-bottom: 1px;
        text-align: center;
        font-size: 10px;
      }
    }
  }
  input {
    position: absolute;
    left: -100vw;
    color: $color-primary-100;
  }
  .actual {
    position: relative;
    left: -4px;
    padding: 3px 2px;
    min-width: 2px;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    .border {
      position: relative;
      top: 2px;
      left: 2px;
      height: 17px;
      width: 1px;
      background-color: transparent;
    }
  }
  &:focus-within {
    .actual {
      .border {
        animation-name: blinking;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }
  }
}
@keyframes blinking {
  50% {
    background-color: $color-primary-100;
  }
}
</style>
