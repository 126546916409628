<script setup>
import { defineProps } from "vue";
import InlineSvg from "@/components/InlineSvg.vue";

const props = defineProps({
  link: {
    type: String,
    default: null,
  },
  nested: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: null,
  },
  add: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: "clap",
  },
  count: {
    type: Number,
    default: 0,
  },
});

function maxCount(count) {
  if (count > 99) {
    return "99+";
  }
  return count;
}
</script>

<template>
  <div class="menu-item" :class="{ nested: nested }">
    <router-link
      :to="props.link"
      v-if="props.link"
      active-class="active"
      exact-path
    >
      <InlineSvg :name="props.icon" />
      <span>{{ props.name }}</span>

      <i v-if="props.count !== 0" class="count">{{ maxCount(props.count) }}</i>
    </router-link>
    <a v-if="props.add">
      <InlineSvg name="plus" />
      <span>{{ props.name }}</span>
    </a>
    <slot v-if="!props.link && !props.add"></slot>
  </div>
</template>

<style lang="scss" scoped>
.menu-item {
  position: relative;
  margin-top: 4px;

  &:first-of-type {
    margin-top: 0;
  }

  &.nested {
    margin-left: 31px;
  }

  a {
    display: block;
    padding: 6px 0 6px 36px;
    border-radius: 0 4px 4px 0;
    display: flex;
    align-items: flex-end;
    position: relative;
    color: $color-primary-100;
    height: 32px;

    &:hover {
      cursor: pointer;
      background: $color-primary-5;
    }

    &.active {
      background: $color-primary-5;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        height: 100%;
        background: $color-primary-100;
      }

      svg {
        color: $color-primary-100;
      }

      span {
        color: $color-primary-100;
        font-weight: 600;
      }
    }

    svg {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 4px;
      top: 50%;
      @include transform(translateY(-50%));
      padding: 5px;
      color: $color-primary-60;
    }

    span {
      color: $color-primary-70;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.1px;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    .count {
      position: absolute;
      right: 3px;
      top: 50%;
      @include transform(translateY(-50%));
      color: $white !important;
      padding: 0 6.5px;
      background: $color-info;
      border-radius: 999px;
      font-size: 12px;
      line-height: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
</style>
