var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("table", [
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v("Annual fee")]),
            _c("td", [_vm._v("$0*")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Per purchase")]),
            _c("td", [_vm._v("$0")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("ATM withdrawal")]),
            _c("td", [
              _vm._v("$0 in-network"),
              _c("br"),
              _vm._v("$0 out-of-network"),
            ]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Cash reload")]),
            _c("td", [_vm._v("$0")]),
          ]),
          _c("tr", [
            _c("td", [
              _vm._v("ATM balance inquiry (in-network or out-of-network)"),
            ]),
            _c("td", [_vm._v("N/A")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Customer service (automated or live agent)")]),
            _c("td", [_vm._v("$0 per call")]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Inactivity")]),
            _c("td", [_vm._v("$0")]),
          ]),
        ]),
      ]),
      _c("p", [
        _vm._v(
          " *Cloaked Pay Prepaid Card availability is included in your subscription to Cloaked, which includes all Cloaked core and Cloaked Pay products and services. If you are eligible for Cloaked Pay, you can gain Cloaked Pay access with an active and paid Cloaked subscription. "
        ),
      ]),
      _c("p", [_vm._v("No overdraft/credit feature.")]),
      _c("p", [_vm._v("Your funds are not FDIC insured.")]),
      _c("p", [
        _vm._v(
          " For general information about prepaid accounts, visit cfpb.gov/prepaid. Find details and conditions for all fees and services in the cardholder agreement. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }