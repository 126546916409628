var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.ready
    ? _c(
        "div",
        [
          _c(
            "Transition",
            { attrs: { name: "slide-bottom", appear: "" } },
            [_c("router-view")],
            1
          ),
          _setup.state.loading || !_setup.dbLoaded
            ? _c(_setup.Loading)
            : _c(
                "section",
                { staticClass: "main-content" },
                [
                  _c(_setup.ImportBanner),
                  _c("div", { staticClass: "all-title" }, [
                    _setup.hasExpiringNumbers
                      ? _c(
                          "div",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "number-reuse",
                                attrs: { to: "/number-reuse" },
                              },
                              [
                                _c("span", [_vm._v("Number clean up")]),
                                _c(
                                  "span",
                                  [
                                    _vm._v("Review numbers "),
                                    _c(_setup.ArrowNE),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "filters" }, [
                    _c(
                      "div",
                      { staticClass: "facets" },
                      [
                        _c(
                          _setup.Button,
                          {
                            attrs: {
                              type:
                                _setup.state.filter === "has_login"
                                  ? "tag-selected"
                                  : "tag",
                            },
                            on: {
                              click: function ($event) {
                                return _setup.setFilter("has_login")
                              },
                            },
                          },
                          [_vm._v(" Logins ")]
                        ),
                        _c(
                          _setup.Button,
                          {
                            attrs: {
                              type:
                                _setup.state.filter === "has_totp"
                                  ? "tag-selected"
                                  : "tag",
                            },
                            on: {
                              click: function ($event) {
                                return _setup.setFilter("has_totp")
                              },
                            },
                          },
                          [_vm._v(" One-time passcodes ")]
                        ),
                        _c(
                          _setup.Button,
                          {
                            attrs: {
                              type:
                                _setup.state.filter === "has_phone"
                                  ? "tag-selected"
                                  : "tag",
                            },
                            on: {
                              click: function ($event) {
                                return _setup.setFilter("has_phone")
                              },
                            },
                          },
                          [_vm._v(" Phone numbers ")]
                        ),
                        _c(
                          _setup.Button,
                          {
                            attrs: {
                              type:
                                _setup.state.filter === "has_email"
                                  ? "tag-selected"
                                  : "tag",
                            },
                            on: {
                              click: function ($event) {
                                return _setup.setFilter("has_email")
                              },
                            },
                          },
                          [_vm._v(" Email ")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "sort" }, [
                      _c("span", { staticClass: "filter-label" }, [
                        _vm._v("Sort by:"),
                      ]),
                      _c("div", { staticClass: "sort__select-wrapper" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _setup.state.sort,
                                expression: "state.sort",
                              },
                            ],
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _setup.state,
                                  "sort",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "nickname" } }, [
                              _vm._v("Alphabetical"),
                            ]),
                            _c("option", { attrs: { value: "-created_at" } }, [
                              _vm._v("Date Created"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "sort__select-icon" },
                          [_c(_setup.ChevronDown)],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _setup.dbLoaded
                    ? _c(_setup.CloaksList, {
                        ref: "cloakListRef",
                        attrs: {
                          hoverText: "New Identity",
                          add: true,
                          showModalOnHover: _setup.allCloaks.length === 0,
                          identityList: _setup.identityList,
                          identifierPriority: _setup.state.filter,
                        },
                        on: { loadNextPage: _setup.loadNextPage },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }