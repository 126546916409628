<script setup>
import WelcomeView from "./KycFlow/WelcomeView.vue";
import { ref, watch } from "vue";
import KycFormView from "./KycFlow/KycFormView.vue";
import IdentityVerificationModal from "./KycFlow/IdentityVerificationModal.vue";

const kycVisible = ref(false);
const modal = ref(false);

function toggleVisible() {
  kycVisible.value = !kycVisible.value;
}

function toggleModal() {
  modal.value = !modal.value;
}

function goBack() {
  toggleModal();
  toggleVisible();
}

watch(kycVisible, (value) => {
  if (value) {
    document.body.classList.add("overflow-hidden");

    setTimeout(() => {
      toggleModal();
    }, 50);
  } else {
    document.body.classList.remove("overflow-hidden");
  }
});
</script>

<template>
  <div>
    <WelcomeView @toggleVisible="toggleVisible" />
    <KycFormView :class="{ open: kycVisible }" @toggleVisible="toggleVisible" />
    <IdentityVerificationModal
      :show="modal"
      @close="toggleModal()"
      @goBack="goBack()"
    />
  </div>
</template>
