var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.FormContainer, { attrs: { icon: "doc-search" } }, [
        _c("h1", [
          _vm._v("By submitting this application you agree to the following:"),
        ]),
        _c("div", { staticClass: "text" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "description" }, [
              _c("p", [
                _vm._v(
                  " The issuing bank for Cloaked Cards is Patriot Bank. To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions, like Patriot Bank, to obtain, verify, and record information that identifies each person who opens an account. What this means for you: when you open a Cloaked Pay account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see a copy of your driver’s licenses or other identifying documents. "
                ),
              ]),
            ]),
            _c("div", { staticClass: "agreements" }, [
              _c(
                "ul",
                { staticClass: "selections" },
                _vm._l(_setup.selections, function (selection) {
                  return _c(
                    "li",
                    { key: selection.id, class: { active: selection.active } },
                    [
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _setup.changeSelection(selection.id)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(selection.name) + " ")]
                      ),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "selection" },
                [
                  _setup.currentSelection === 1
                    ? _c(_setup.TermsOfService)
                    : _vm._e(),
                  _setup.currentSelection === 2
                    ? _c(_setup.PrivacyPolicy)
                    : _vm._e(),
                  _setup.currentSelection === 3
                    ? _c(_setup.ChargeCardAgreement)
                    : _vm._e(),
                  _setup.currentSelection === 4
                    ? _c(_setup.RatesFees)
                    : _vm._e(),
                  _setup.currentSelection === 5
                    ? _c(_setup.PrepaidAgreement)
                    : _vm._e(),
                  _setup.currentSelection === 6
                    ? _c(_setup.PrepaidLongForm)
                    : _vm._e(),
                  _setup.currentSelection === 7
                    ? _c(_setup.PrepaidShortForm)
                    : _vm._e(),
                  _setup.currentSelection === 8 ? _c(_setup.ESign) : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "legal" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.cloaked.com/cloaked-pay/legal",
                target: "_blank",
              },
            },
            [_vm._v("Cloaked Pay Agreements")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }