<script setup>
import Upgrade from "@/routes/guest/Upgrade";
import User from "@/routes/User";
import Guest from "@/routes/Guest";
import Shared from "@/routes/Shared";
import { auth_channel, refresh_channel, logout } from "@/scripts/actions/auth";
import { computed, onMounted } from "vue";
import store from "@/store";
import router from "@/routes/router";
import { useRoute } from "vue-router/composables";
const route = useRoute();

const isOldExtension = computed(() => {
  return route.query && route.query.code;
});
const authenticated = computed(() => {
  return store.getters["authentication/isAuthenticated"];
});
const auth_route = computed(() => {
  return route.path.match(/auth/gi);
});
const share = computed(() => {
  return route.path.match(/share/gi);
});

onMounted(() => {
  if (store.getters["authentication/isAuthenticated"] && auth_route.value) {
    if (
      route.query &&
      (route.query.cloaked_client_id ||
        route.query.signup_key ||
        route.query.utm_campaign)
    ) {
      logout();
    } else {
      router.push({ path: "/" });
    }
  }
  auth_channel.onmessage = () => {
    logout(true);
  };
  refresh_channel.onmessage = () => {
    window.location.reload();
  };

  const darkMode = store.getters["colorScheme/isDarkMode"]
    ? "theme-dark"
    : "theme-light";

  document.body.classList.add(darkMode);
});
</script>

<template>
  <Upgrade v-if="isOldExtension" />
  <Shared v-else-if="share" />
  <Guest v-else-if="!authenticated || auth_route" />
  <User v-else />
</template>
