<template>
  <section class="activity-convo-header">
    <div class="contact-info">
      <ProfileIconLarge class="icon clickable" @click="$emit('openCloak')" />
      <div>
        <div class="sender clickable">
          {{ senderDisplay }}
        </div>
        <div class="receiver" @click="$emit('openCloak')">
          To: {{ cloakName }} {{ cloakIdentifierString }}
        </div>
      </div>
    </div>
    <UiMenu width="188px" placement="bottom-end">
      <div class="kabob-wrapper">
        <KabobIcon />
      </div>

      <template #content>
        <UiMenuButton
          :title="`Mark as ${read ? 'un' : ''}read`"
          @click="toggleReadStatus"
        >
          <template v-slot:icon>
            <ActivityMarkAsReadIcon />
          </template>
        </UiMenuButton>

        <UiMenuSeparator />

        <div class="alert-text">
          <UiMenuButton title="Delete" @click="showDeleteModal" danger>
            <template v-slot:icon>
              <DeleteTrashIcon />
            </template>
          </UiMenuButton>
        </div>
      </template>
    </UiMenu>
  </section>
</template>
<script>
import ProfileIconLarge from "@/assets/icons/activity-profile-large.svg";
import {
  KabobIcon,
  ActivityMarkAsReadIcon,
  DeleteTrashIcon,
} from "@/assets/icons";
import { UiMenu, UiMenuButton, UiMenuSeparator } from "@/components";
import api from "@/api/api";
import { mapActions } from "vuex";
export default {
  name: "ActivityConvoHeader",
  components: {
    ProfileIconLarge,
    KabobIcon,
    ActivityMarkAsReadIcon,
    DeleteTrashIcon,
    UiMenu,
    UiMenuButton,
    UiMenuSeparator,
  },
  props: [
    "senderDisplay",
    "cloakName",
    "cloakIdentifier",
    "conversationId",
    "read",
  ],

  computed: {
    cloakIdentifierString() {
      return this.cloakIdentifier ? `(${this.cloakIdentifier})` : "";
    },
  },
  methods: {
    ...mapActions(["openModal"]),
    deleteThread() {
      const url = "/api/v1/cloaked/activity/delete-conversation/";
      const payload = {
        conversation_id: this.conversationId,
      };
      api()
        .post(url, payload)
        .then(() => {
          this.$toast.success("Conversation deleted");
          this.$emit("onDelete");
        });
    },
    showDeleteModal() {
      this.openModal({
        header: `Delete message?`,
        subheader:
          "Any copies of this message that have been forwarded to your personal phone will be unaffected. This cannot be undone.",
        button: {
          text: "Yes, Delete",
          onClick: this.deleteThread,
          danger: true,
        },
      });
    },
    toggleReadStatus() {
      const readOrUnread = this.read ? "unread" : "read";
      const url = `/api/v1/cloaked/activity/${readOrUnread}-conversation/`;
      const payload = {
        conversation_id: this.conversationId,
      };
      api()
        .post(url, payload)
        .then(() => {
          this.$toast.success(`Conversation marked as ${readOrUnread}`);
          this.$emit("onReadToggle", !this.read);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.activity-convo-header {
  padding: 0 32px;
  min-height: 89px;
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(217, 217, 217, 0.5);
  .contact-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > div {
      margin-left: 12px;
      .sender {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        color: $color-primary-100;
      }
      .receiver {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $color-primary-100;
      }
    }
  }
}

.kabob-wrapper {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-primary-100;
  &:hover {
    background-color: $color-primary-5;
  }
}
.clickable {
  cursor: pointer;
}
.alert-text {
  color: $color-alert;
}
</style>
