var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "transition",
    { attrs: { name: "auto-change-spinner", appear: "" } },
    [
      _c(
        "button",
        _vm._g(
          {
            staticClass: "auto-change-spinner",
            class: `auto-change-spinner--${_vm.status}`,
          },
          _vm.$listeners
        ),
        [
          _c(_setup.AutoChangeIcon, {
            staticClass: "auto-change-spinner__icon",
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }