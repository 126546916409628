var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ui-email-option-label",
      class: { "ui-email-option-label--ellipsis": _vm.ellipsis },
      attrs: { title: _vm.email },
    },
    [
      _vm.ellipsis
        ? [
            _vm.username
              ? _c("span", { staticClass: "ui-email-option-label__username" }, [
                  _vm._v(" " + _vm._s(_vm.username) + " "),
                ])
              : _vm._e(),
            _vm.domain
              ? _c("span", { staticClass: "ui-email-option-label__domain" }, [
                  _vm._v(" @" + _vm._s(_vm.domain) + " "),
                ])
              : _vm._e(),
          ]
        : _c("span", [_vm._v(_vm._s(_vm.email))]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }