<template>
  <AppModal v-on="$listeners" @close="$store.dispatch('onboarding/close')">
    <AppModalContent class="onboarding-demo">
      <AppModalVideo
        :src="stepsConfiguration[stepIndex].videoSrc"
        autoplay
        loop
      />
      <AppModalTitle>
        <div
          v-if="notInstalled"
          class="install-extension-link"
          @click="linkToDownload"
        >
          Install Extension
        </div>
        {{ stepsConfiguration[stepIndex].title }}
      </AppModalTitle>
      <AppModalParagraph>
        {{ stepsConfiguration[stepIndex].text }}
      </AppModalParagraph>
      <AppModalFooter>
        <div class="onboarding-demo__step">
          {{ stepsConfiguration[stepIndex].step }}
          <LineProgress :progress="(stepIndex + 1) / 3" />
        </div>
        <Button @click="onNextClicked"> {{ nextButtonLabel }}</Button>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>

<script>
import AppModal from "@/components/ui/AppModal";
import AppModalTitle from "@/components/ui/AppModalTitle";
import AppModalContent from "@/components/ui/AppModalContent";
import AppModalParagraph from "@/components/ui/AppModalParagraph";
import AppModalFooter from "@/components/ui/AppModalFooter";
import AppModalVideo from "@/components/ui/AppModalVideo.vue";
import Button from "@/components/Button.vue";
import { STEP_DEMO } from "@/store/modules/onboarding";
import LineProgress from "@/components/ui/LineProgress.vue";

const SCENARIO_EXTENSION_INSTALLED = "SCENARIO_EXTENSION_INSTALLED";
const SCENARIO_EXTENSION_MISSING = "SCENARIO_EXTENSION_MISSING";
const SCENARIO_BROWSER_NOT_SUPPORTED = "SCENARIO_BROWSER_NOT_SUPPORTED";

export default {
  components: {
    LineProgress,
    AppModalVideo,
    AppModalFooter,
    AppModalParagraph,
    AppModalContent,
    AppModalTitle,
    AppModal,
    Button,
  },
  data() {
    return {
      stepIndex: 0,
    };
  },
  computed: {
    notInstalled() {
      return (
        this.scenario === SCENARIO_BROWSER_NOT_SUPPORTED ||
        this.scenario === SCENARIO_EXTENSION_MISSING
      );
    },
    browserSupported() {
      return this.$store.getters["browser/browserSupported"];
    },
    extensionDetected() {
      return this.$store.getters["browser/pluginDetected"];
    },
    scenario() {
      if (this.browserSupported) {
        return this.extensionDetected
          ? SCENARIO_EXTENSION_INSTALLED
          : SCENARIO_EXTENSION_MISSING;
      } else {
        return SCENARIO_BROWSER_NOT_SUPPORTED;
      }
    },

    stepsConfiguration() {
      return {
        0: {
          title: "Sign up using all generated information",
          text: "Any incoming or outgoing contact is auto-assigned a Cloaked email address to keep your information protected.",
          videoSrc: "/videos/extension-generate-demo.mp4",
          step: "How to sign up",
        },
        1: {
          title: "Sign in to websites with a single click",
          text: "Use the widget to fill out entire forms from any of your saved logins, your name, addresses and even payment methods.",
          videoSrc: "/videos/extension-sign-in-demo.mp4",
          step: "How to sign in",
        },
        2: {
          title: "Get one-time-passcodes without leaving the browser",
          text: "When visiting a site that you’ve created an identity for, you’ll receive real-time notifications while viewing the site: including OTP (one-time-passcodes).",
          videoSrc: "/videos/extension-otp-demo.mp4",
          step: "Using one-time-passcodes",
        },
      };
    },
    nextButtonLabel() {
      if (this.stepIndex < 2) {
        return "Next";
      } else if (this.notInstalled) {
        return "Install";
      }
      return "Done";
    },
  },
  methods: {
    linkToDownload() {
      return window.open("https://download.cloaked.app/", "_blank");
    },
    onNextClicked() {
      if (this.stepIndex === 2) {
        if (this.notInstalled) {
          this.linkToDownload();
        }
        this.$store.dispatch("onboarding/completeStep", STEP_DEMO);
      }

      this.stepIndex < 2
        ? this.stepIndex++
        : this.$store.dispatch("onboarding/close");
    },
  },
};
</script>

<style lang="scss">
.onboarding-demo {
  justify-content: flex-start;
  height: 680px;

  &__step {
    font-family: $poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $color-primary-100;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    position: relative;
    top: -12px;

    .progress-line {
      margin-top: 16px;
    }
  }
}

.install-extension-link {
  color: $color-primary-70;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  text-decoration-line: underline;
  margin-bottom: 16px;
  cursor: pointer;
}
</style>
