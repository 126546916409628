var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("header", { staticClass: "cloak-identifier-section__header" }, [
      _c(
        "h3",
        { staticClass: "cloak-identifier-section__header-title" },
        [
          _c("span", [_vm._v("Contacts")]),
          _c(
            _setup.UiTooltip,
            {
              attrs: {
                title:
                  "Contacts you’ve approved will appear here. Review pending activity in the “Requests” section of your dashboard.",
                position: "top",
                "max-width": "192",
                "align-x": "center",
              },
            },
            [_c(_setup.MoreInfoQIcon)],
            1
          ),
        ],
        1
      ),
      _c("button", { on: { click: _setup.toggleShow } }, [
        _vm._v(" " + _vm._s(_setup.state.showing ? "Close" : "Open") + " "),
      ]),
    ]),
    _setup.state.showing
      ? _c("div", [
          !_setup.state.loaded
            ? _c(
                "div",
                { staticClass: "spinner-wrapper" },
                [_c(_setup.InputSpinner)],
                1
              )
            : _setup.state.displayContacts?.length === 0
            ? _c("div", { staticClass: "empty" }, [
                _vm._v(" No contacts available "),
              ])
            : _c(
                "div",
                {
                  staticClass:
                    "cloak-identifier-section__group cloak-identifier-section__group--no-separator",
                },
                _vm._l(_setup.state.displayContacts, function (contact) {
                  return _c(_setup.CloakContactsItem, {
                    key: contact.id,
                    attrs: { contact: contact },
                    on: {
                      "change-status": _setup.handleChangeStatus,
                      edit: _setup.handleOpenContactModal,
                    },
                  })
                }),
                1
              ),
          _setup.pages?.length > 0
            ? _c("div", { staticClass: "cloak-identifier-section__nav-btns" }, [
                _c(
                  "div",
                  [
                    _c(_setup.ArrowButton, {
                      staticClass: "arrow-btn",
                      attrs: {
                        onClick: () =>
                          _setup.fetchNumberContactsPage(
                            _setup.state.currentPage - 1
                          ),
                        disabled:
                          (!_setup.state.previousEmailPage &&
                            !_setup.state.previousPhonePage) ||
                          _setup.state.currentPage === 1,
                        horizontal: "",
                      },
                    }),
                    _vm._l(_setup.pages, function (pageButton, index) {
                      return _c("span", { key: `${pageButton}-${index}` }, [
                        typeof pageButton === "number"
                          ? _c(
                              "button",
                              {
                                staticClass: "page-btn",
                                class: {
                                  active:
                                    _setup.state.currentPage === pageButton,
                                },
                                on: {
                                  click: function ($event) {
                                    return _setup.fetchNumberContactsPage(
                                      pageButton
                                    )
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(pageButton) + " ")]
                            )
                          : _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "click-outside",
                                    rawName: "v-click-outside",
                                    value: {
                                      handler: _setup.hide,
                                      events: ["mousedown"],
                                    },
                                    expression:
                                      "{\n              handler: hide,\n              events: ['mousedown'],\n            }",
                                  },
                                ],
                                staticClass: "nav-anchor",
                              },
                              [
                                _setup.state.showNavPopup
                                  ? _c("div", { staticClass: "nav-popup" }, [
                                      _c("h6", [_vm._v("Jump to page")]),
                                      _c(
                                        "div",
                                        { staticClass: "page-nav" },
                                        _vm._l(
                                          _setup.pageButtons,
                                          function (pageButton) {
                                            return _c(
                                              "button",
                                              {
                                                key: pageButton,
                                                staticClass: "page-btn",
                                                class: {
                                                  active:
                                                    _setup.state.currentPage ===
                                                    pageButton,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _setup.fetchNumberContactsPage(
                                                      pageButton
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(pageButton) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "button",
                                  {
                                    staticClass: "page-btn",
                                    class: {
                                      active:
                                        _setup.state.showNavPopup === true &&
                                        _setup.state.activeButton ===
                                          `ellipses-${index}`,
                                    },
                                    attrs: { id: `ellipses-${index}` },
                                    on: {
                                      click: (e) =>
                                        _setup.toggleShowNavPopup(
                                          e,
                                          `ellipses-${index}`
                                        ),
                                    },
                                  },
                                  [_vm._v(" ... ")]
                                ),
                              ]
                            ),
                      ])
                    }),
                    _c(_setup.ArrowButton, {
                      staticClass: "arrow-btn",
                      attrs: {
                        onClick: () =>
                          _setup.fetchNumberContactsPage(
                            _setup.state.currentPage + 1
                          ),
                        disabled:
                          (!_setup.state.nextEmailPage &&
                            !_setup.state.nextPhonePage) ||
                          _setup.state.currentPage ===
                            _setup.pageButtons.length,
                        horizontal: "",
                        isOpen: "",
                      },
                    }),
                  ],
                  2
                ),
                _c("div", { staticClass: "cloak-identifier-section__count" }, [
                  _c("span", [_vm._v(" " + _vm._s(_setup.getOveralOffset()))]),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }