<script setup>
import { computed, defineEmits, reactive } from "vue";
import store from "@/store";
import { useToast } from "@/hooks";
import moment from "moment";
import {
  PreferencesHeader,
  PreferencesTitle,
  PreferencesParagraph,
  PreferencesPanel,
  PreferencesFooter,
  PreferencesCheckParagraph,
  PreferencesInput,
} from "@/routes/modals/preferences";

import ExportData from "./ExportData";

import UserService from "@/api/actions/user-service";
import { Button } from "@/components";
import { password_confirm } from "@/scripts/actions/encryption";
import AuthService from "@/api/actions/auth-service";

const emit = defineEmits(["toggleBack"]);

const toast = useToast();

const state = reactive({
  step: 0,
  steps: ["password", "confirmation", "before-export", "export"],
  userUnderstands: false,
  loadingDelete: false,
  password: "",
  invalidPassword: false,
  loadingPassword: false,
  deletionDate: null,
});

const deleteDateLabel = computed(() => {
  let then = moment().add(30, "days");

  if (state.deletionDate) {
    then = moment(state.deletionDate);
  }

  return then.format("LL");
});

const currentStep = computed(() => state.steps[state.step]);

const toggleBack = () => {
  emit("toggleBack", {});
};

const user = computed(
  () => store.state.authentication?.user || store.state.user
);

const insertUser = (data) => {
  store.dispatch("insertUser", data);
};

const nextStep = () => {
  let next = state.step + 1;
  const maxStep = state.steps.length - 1;

  if (next >= maxStep) {
    next = maxStep;
  }

  state.step = next;
};

const handleDelete = async () => {
  const userId = user.value.id;

  const params = {
    state: "pending_deletion",
    immediate_delete: false,
  };

  state.loadingDelete = true;
  try {
    const res = await UserService.deleteUserAccount({ userId, params });
    const { data } = res;
    state.deletionDate = data.deletion_date;
    insertUser(data);
    nextStep();
  } catch (e) {
    toast.error("Error scheduling delete action");
  } finally {
    state.loadingDelete = false;
  }
};

const handleLater = () => {
  toggleBack();
};

const handleExportData = () => {
  nextStep();
};

const handleGoBack = () => {
  if (state.step > 0) {
    state.step = state.step - 1;
  } else {
    toggleBack();
  }
};

const validatePassword = async () => {
  const userId = user.value.id;
  let password = state.password;
  if (store.state.authentication?.user?.encryption_status === 3) {
    password = await password_confirm(password);
  }
  state.loadingPassword = true;
  AuthService.confirmPassword(userId, password)
    .then(() => {
      state.invalidPassword = false;
      nextStep();
    })
    .catch(() => {
      state.invalidPassword = true;
      toast.error("Invalid password, please try again");
    })
    .finally(() => {
      state.loadingPassword = false;
    });
};
</script>
<template>
  <PreferencesPanel class="delete-account">
    <template v-slot:header>
      <PreferencesHeader @go-back="handleGoBack" />
    </template>

    <template v-if="currentStep === 'password'">
      <PreferencesTitle>Delete account</PreferencesTitle>
      <PreferencesParagraph>
        This will permanently delete all data associated with your Cloaked
        identities including email addresses, phone numbers, passwords,
        usernames, and notes.
      </PreferencesParagraph>

      <PreferencesParagraph>
        You will no longer be able to send and receive emails, texts, and calls
        from any of your Cloaked identities. This may also affect your ability
        to log in to websites associated with your Cloaked identities.
      </PreferencesParagraph>

      <PreferencesParagraph
        >To continue, enter your account password.</PreferencesParagraph
      >

      <PreferencesInput
        v-model="state.password"
        label="Password"
        type="password"
        placeholder="Your Password"
        :error="state.invalidPassword"
        :disabled="state.loadingPassword"
        @save="validatePassword"
      />
    </template>

    <template v-if="currentStep === 'confirmation'">
      <PreferencesTitle>Confirm account deletion</PreferencesTitle>
      <PreferencesParagraph>
        You’ll still have access to your Cloaked account and be able to restore
        your account within the next 30 days.
      </PreferencesParagraph>

      <PreferencesCheckParagraph
        class="disclaimer-row"
        v-model="state.userUnderstands"
      >
        I understand that all of my account data will be deleted on
        <strong>{{ deleteDateLabel }}</strong> and cannot be retrieved after
        that.
      </PreferencesCheckParagraph>
    </template>

    <template v-if="currentStep === 'before-export'">
      <PreferencesTitle>
        Your account will be deleted on {{ deleteDateLabel }}
      </PreferencesTitle>
      <PreferencesParagraph>
        You’ll still have access to your Cloaked account and be able to restore
        your account within the next 30 days.
      </PreferencesParagraph>

      <PreferencesTitle>Your data is important to us</PreferencesTitle>

      <PreferencesParagraph>
        We value your data and privacy. We strongly encourage you to export your
        data so that you can retain access to it after your account is deleted
        on <strong>{{ deleteDateLabel }}</strong
        >. After that date, it will be permanently deleted and Cloaked will be
        unable to retrieve it.
      </PreferencesParagraph>

      <PreferencesParagraph
        >Click the button below to begin exporting.</PreferencesParagraph
      >
    </template>

    <template v-if="currentStep === 'export'">
      <ExportData view-step="export" nav-disabled @toggleBack="toggleBack" />
    </template>

    <template v-slot:footer>
      <PreferencesFooter v-if="currentStep === 'password'">
        <Button
          @click="validatePassword"
          :loading="state.loadingPassword"
          :disabled="state.loadingPassword || !state.password"
          >Continue</Button
        >
      </PreferencesFooter>

      <PreferencesFooter v-if="currentStep === 'confirmation'">
        <Button
          type="danger"
          :disabled="!state.userUnderstands || state.loadingPassword"
          @click="handleDelete"
        >
          Delete my account
        </Button>
      </PreferencesFooter>

      <PreferencesFooter v-if="currentStep === 'before-export'">
        <Button type="secondary" @click="handleLater"
          >I'll do this later</Button
        >
        <Button @click="handleExportData">Export account data</Button>
      </PreferencesFooter>
    </template>
  </PreferencesPanel>
</template>

<style lang="scss">
.delete-account {
  .preferences-input {
    margin-top: 37px;
  }
}
</style>
