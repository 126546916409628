export const AutoChangeStatus = {
  WAITING: "waiting",
  IN_PROGRESS: "inProgress",
  NEEDS_REVIEW: "needsReview",
  SUCCESS: "success",
  ERROR: "error",
  SKIPPED: "skipped",
};

export const AutoChangeVerificationStatus = {
  WAITING: "waiting",
  IN_PROGRESS: "inProgress",
  NEEDS_REVIEW: "needsReview",
  SUCCESS: "success",
  ERROR: "error",
  SKIPPED: "skipped",
  NOT_REQUIRED: "notRequired",
};

export const AutoChangeUpdateStatus = {
  WAITING: "waiting",
  IN_PROGRESS: "inProgress",
  SUCCESS: "success",
  ERROR: "error",
  SKIPPED: "skipped",
  NOT_REQUIRED: "notRequired",
};

export const AutoChangeFieldTypes = {
  EMAIL: "email",
  PASSWORD: "password",
  USERNAME: "username",
  PHONE: "phone",
};

export const SupportedAutoChangeFieldTypes = [
  AutoChangeFieldTypes.EMAIL,
  AutoChangeFieldTypes.PASSWORD,
  AutoChangeFieldTypes.USERNAME,
];

export const AutoChangeEnabledStatus = {
  ENABLED: "enabled",
  EXTENSION_NOT_INSTALLED: "extensionNotInstalled",
  EXTENSION_LOGGED_OUT: "extensionLoggedOut",
  ACCOUNT_RESTRICTED: "accountRestricted",
};
