<script setup>
import { lowerCase } from "lodash-es";
import {
  Plus,
  LockIcon,
  ChevronDown,
  CheckIcon,
  DeleteMinusIcon,
  KabobIcon,
} from "@/assets/icons";

import {
  UiMenu,
  UiMenuButton,
  UiMenuInput,
  UiMenuSeparator,
} from "@/components";
import CloakDetailsInputRow from "@/components/cloakDetails/CloakDetailsInputRow.vue";
import CloakInfoRowButton from "@/components/cloakDetails/CloakInfoRowButton.vue";
import AddEditNewCategory from "@/routes/modals/categories/AddEditNewCategory";

import CategoryService from "@/api/actions/category-service";
import store from "@/store";

import {
  reactive,
  defineProps,
  ref,
  onBeforeUnmount,
  defineEmits,
  nextTick,
  watch,
  computed,
} from "vue";

const props = defineProps({
  cloak: {
    type: Object,
    required: true,
  },
  refreshing: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["refresh", "refreshCloak"]);

const state = reactive({
  loading: false,
  loadingMessage: "",
  categoryFilter: "",
  prefilledCategoryName: "",
  expectsRefresh: false,
  isMenuOpen: false,
  isNewCategoryModalVisible: false,
});

const componentElement = ref(null);
const categoryModal = ref(null);

onBeforeUnmount(() => {
  componentElement.value.appendChild(categoryModal.value.$el);
});

const categories = computed(() => {
  return store.getters.getCustomCategories;
});

const filteredCategories = computed(() => {
  if (!state.categoryFilter) {
    return categories.value;
  }

  return categories.value.filter((category) => {
    const a = lowerCase(category.name);
    const b = lowerCase(state.categoryFilter);

    return a.includes(b);
  });
});

const selectedCategoryId = computed(() => {
  if (props.cloak && props.cloak.categories && props.cloak.categories.length) {
    const catId = props.cloak.categories[0]; // assumes only one category for now
    return catId;
  }

  return null;
});

const categoryName = computed(() => {
  let name = "Add category";

  if (selectedCategoryId.value) {
    const category = categories.value.find(
      (cat) => cat.id === selectedCategoryId.value
    );

    if (category) {
      name = category.name;
    }
  }
  return name;
});

const hasCategory = computed(() => {
  return props.cloak && props.cloak.categories && props.cloak.categories.length;
});

function moveToCat(category) {
  state.loading = true;
  state.loadingMessage = "Updating category";

  CategoryService.addCloaksToCategory(category.id, [props.cloak.id])
    .then(() => {
      state.expectsRefresh = true;
      emit("refresh", {
        ...props.cloak,
        categories: [category.id],
      });
      state.isMenuOpen = false;
      window.dispatchEvent(new CustomEvent("category:identities"));
    })
    .finally(() => {
      state.loading = false;
    });
}

function showRemoveModal() {
  store.dispatch("openGlobalDeleteModal", {
    type: "category",
    onClick: handleRemove,
  });
}

function handleRemove() {
  if (!selectedCategoryId.value) {
    return;
  }
  state.loading = true;
  state.loadingMessage = "Removing category";

  CategoryService.removeCloaksFromCategory(selectedCategoryId.value, [
    props.cloak.id,
  ])
    .then(() => {
      emit("refresh", props.cloak);
      window.dispatchEvent(new CustomEvent("category:identities"));
    })
    .finally(() => {
      state.loading = false;
    });
}

function handleCategoryCreate() {
  state.prefilledCategoryName = state.categoryFilter;
  state.isNewCategoryModalVisible = true;
  state.isMenuOpen = false;
  state.categoryFilter = "";

  nextTick().then(() => {
    document.body.appendChild(categoryModal.value.$el);
    window.dispatchEvent(new CustomEvent("category:identities"));
  });
}

function handleNewCategory(category) {
  const updatedCloak = { ...props.cloak };
  updatedCloak.categories = Array.isArray(updatedCloak.categories)
    ? [category, ...updatedCloak.categories]
    : [category];
  emit("refreshCloak", updatedCloak);
  moveToCat(category);
  state.prefilledCategoryName = "";
}

function closeNewCategoryModal() {
  state.isNewCategoryModalVisible = false;
  state.prefilledCategoryName = "";
}

function handleMenuClose() {
  state.categoryFilter = "";
}

watch(
  () => props.cloak,
  (newValue, oldValue) => {
    if (newValue.nickname !== oldValue.nickname) {
      state.nickname = newValue.nickname;
    }
  }
);

watch(
  () => props.refreshing,
  (value) => {
    if (!value) {
      state.expectsRefresh = false;
    }
  }
);
</script>

<template>
  <div
    class="cloak-category-section"
    :class="{ readOnly: props.readOnly }"
    ref="componentElement"
  >
    <CloakDetailsInputRow
      :value="selectedCategoryId"
      label="Category"
      placeholder="Add category"
      :loading-message="state.loadingMessage"
      :loading="state.loading || state.expectsRefresh"
      :is-menu-open="state.isMenuOpen"
    >
      <template #input-before>
        <UiMenu
          width="188px"
          max-height="300px"
          placement="left-start"
          @close="handleMenuClose"
          class="cloak-category-section__menu"
        >
          <template #content>
            <UiMenuInput
              v-if="categories && categories.length"
              aria-id="FilterInputCategoriesCloakDetails"
              placeholder="Filter category..."
              v-model="state.categoryFilter"
              @click.stop.native
            />

            <UiMenuSeparator v-if="categories && categories.length" />

            <div
              v-if="categories && categories.length"
              class="cloak-category-section-categories"
            >
              <UiMenuButton
                v-for="category in filteredCategories"
                :aria-id="`AddToCategory.${category?.name || ''}`"
                :key="category.id"
                :title="category.name"
                :active="category.id === selectedCategoryId"
                @click="() => moveToCat(category)"
              >
                <template v-slot:icon>
                  <CheckIcon v-if="category.id === selectedCategoryId" />
                  <span v-else />
                </template>
              </UiMenuButton>
            </div>

            <UiMenuSeparator
              v-if="filteredCategories && filteredCategories.length"
            />

            <UiMenuButton
              aria-id="CreateNewCategoryCloakedDetails"
              title="Create new Category"
              dark-font
              @click.native="handleCategoryCreate"
            >
              <template v-slot:icon>
                <Plus />
              </template>
            </UiMenuButton>
          </template>
        </UiMenu>
      </template>

      <template v-slot:icon>
        <button class="cloak-category-section__icon-button">
          <LockIcon v-if="props.readOnly" />
          <ChevronDown v-else-if="hasCategory" />
          <Plus v-else />
        </button>
      </template>

      <template v-slot:input>
        <button
          class="category-button"
          :class="{
            'category-button--active': hasCategory,
          }"
          id="cloak-details-category-button"
          aria-id="CloakDetailsAddToCategoryButton"
        >
          <span>{{ categoryName }}</span>
        </button>
      </template>

      <template v-slot:actions>
        <UiMenu
          v-if="hasCategory"
          v-model="state.isMenuOpen"
          width="188px"
          placement="bottom-end"
        >
          <CloakInfoRowButton icon :active="state.isMenuOpen">
            <KabobIcon />
          </CloakInfoRowButton>

          <template #content>
            <UiMenuButton title="Remove" @click="showRemoveModal()">
              <template v-slot:icon>
                <DeleteMinusIcon />
              </template>
            </UiMenuButton>
          </template>
        </UiMenu>
      </template>
    </CloakDetailsInputRow>

    <AddEditNewCategory
      ref="categoryModal"
      id="add-category-navigation"
      :isModalVisible="state.isNewCategoryModalVisible"
      :no-redirect="true"
      :prefilled="state.prefilledCategoryName"
      @closeModal="closeNewCategoryModal"
      @category="handleNewCategory"
    />
  </div>
</template>

<style lang="scss">
.readOnly {
  pointer-events: none;
}
.cloak-category-section {
  padding: 20px 20px 2px 20px;
  width: 100%;
  position: relative;

  &__menu {
    position: absolute;
    inset: 0;
    z-index: 100;

    .popper__activator {
      position: absolute;
      inset: 0;
      cursor: pointer;
    }
  }

  &__icon-button {
    border: none;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }

  .category-button {
    background: none;
    border: none;
    display: inline-flex;
    gap: 4px;
    align-items: center;
    padding: 0;
    height: 24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    color: $color-primary-50;

    svg {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
    }

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:first-letter {
        text-transform: capitalize;
      }
    }

    &--active {
      color: $color-primary-100;
    }

    &:hover {
      color: $color-primary-100;
      cursor: pointer;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .ui-menu {
    width: 100%;
    display: flex;
  }
}

.cloak-category-section-categories {
  overflow-x: auto;
  @include custom-scroll-bar();
}
</style>
