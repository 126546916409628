var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._g(
      {
        ref: "tooltipWrapper",
        staticClass: "ui-tooltip-wrapper",
        on: {
          mouseenter: _setup.handleMouseEnter,
          mouseleave: _setup.handleMouseLeave,
          mousemove: _setup.handleMouseMove,
          touchstart: _setup.handleTouch,
        },
      },
      _vm.$listeners
    ),
    [
      _vm._t("default"),
      _c(
        "div",
        {
          ref: "tooltip",
          staticClass: "ui-tooltip",
          class: {
            "ui-tooltip--visible": _setup.state.isVisible,
            "ui-tooltip--enter-state": _setup.state.isEnterState,
            "ui-tooltip--leave-state": _setup.state.isLeaveState,
          },
          style: _setup.style,
        },
        [
          _c(
            "div",
            {
              staticClass: "ui-tooltip__content",
              class: {
                "ui-tooltip__content--shared-page": _setup.props.isOnSharedPage,
              },
            },
            [
              _vm._t("content", function () {
                return [_vm._v(_vm._s(_setup.props.title))]
              }),
            ],
            2
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }