var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "loading" }, [
    _c("span", [_c(_setup.Logo)], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }