<template>
  <div
    class="modal-container"
    :class="{
      active: show,
      'modal-container--enter-state': isEnterState,
      'modal-container--leave-state': isLeaveState,
    }"
  >
    <div
      class="content"
      :class="{ large: large, fullHeight: fullHeight }"
      :style="style"
    >
      <header
        class="modal-header"
        :class="{
          'modal-header--bordered': headerBorder,
          'modal-header--no-padding': withoutHeaderPadding,
        }"
        v-if="this.$slots.header"
      >
        <div class="modal-header__title">
          <slot name="header"> This is the default title! </slot>
        </div>

        <div class="close" @click="close" v-if="showCloseInHeader">
          <ModalX />
        </div>
      </header>

      <section class="modal-body" v-if="this.$slots.body" ref="modal_body">
        <slot name="body"></slot>
      </section>

      <section class="modal-input" v-if="this.$slots.input" ref="modal_input">
        <slot name="input"></slot>
      </section>

      <footer
        v-if="this.$slots.footer"
        class="modal-footer"
        :class="{
          'modal-footer--bordered': footerBorder,
        }"
      >
        <slot name="footer"> This is the default footer! </slot>
      </footer>
    </div>

    <div class="background" @click="close"></div>
  </div>
</template>

<script>
import { ModalX } from "@/assets/icons";

export default {
  name: "ModalTemplate",
  props: {
    show: Boolean,
    large: Boolean,
    fullHeight: Boolean,
    showCloseInHeader: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: null,
    },

    headerBorder: {
      type: Boolean,
      default: false,
    },

    withoutHeaderPadding: {
      type: Boolean,
      default: false,
    },

    footerBorder: {
      type: Boolean,
      default: false,
    },
    preventClose: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ModalX,
  },

  data() {
    return {
      isEnterState: false,
      isLeaveState: false,
    };
  },

  computed: {
    style() {
      const style = {};

      if (this.width) {
        const isString = typeof this.width === "string";

        style.maxWidth = isString ? this.width : `${this.width}px`;
      }

      return style;
    },
  },

  methods: {
    close() {
      if (this.preventClose) {
        return;
      }
      this.$emit("close");
    },
  },

  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value) {
          this.isLeaveState = false;

          this.$nextTick().then(() => {
            this.isEnterState = true;
          });
        } else {
          this.isEnterState = false;

          this.$nextTick().then(() => {
            this.isLeaveState = true;
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1500;
  opacity: 0;
  visibility: hidden;
  @include transition(all 0.3s ease);

  &.active {
    opacity: 1;
    visibility: visible;

    .content {
      opacity: 1;
    }
  }

  .close {
    color: rgba(25, 30, 35, 0.6);
    width: 24px;
    height: 30px; // h1 line-height
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;

    &:hover {
      cursor: pointer;
    }
  }

  .content {
    position: relative;
    z-index: 2;
    width: 100%;
    min-height: 100px;
    max-width: 425px;
    max-height: calc(100% - 96px);
    background: $color-surface;
    border-radius: 28px;
    @include transition(all 0.45s cubic-bezier(0.68, -0.55, 0.27, 1.55));
    @include transition-delay(0.2s);
    opacity: 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 30px 120px rgba($black, 0.25);

    &.large {
      max-width: 670px;
    }

    &.fullHeight {
      height: 620px;
    }

    .modal-header {
      margin-top: 0;
      padding: 32px 32px 8px 32px;
      display: flex;
      align-items: center;

      &__title {
        flex: 1 1 0;
      }

      h1 {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.5px;
        color: $color-primary-100;
      }

      &--bordered {
        border-bottom: 1px solid $color-primary-10;
        padding-bottom: 16px;
        padding-top: 24px;
      }

      &--no-padding {
        padding: 0;
      }
    }

    .modal-body {
      //flex: 1;
      overflow: hidden;
      @include custom-scroll-bar;
      padding-right: 10px;
      position: relative;
      padding: 8px 32px;
      overflow: auto;
      color: $color-primary-100;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.2px;
        color: $color-primary-100;

        + p {
          margin-top: 8px;
        }
      }
    }

    .modal-input {
      padding: 8px 32px;
    }

    .modal-footer {
      margin-top: auto;
      display: flex;
      justify-content: flex-end;
      padding: 12px 32px 28px 32px;
      gap: 6px;

      &--top {
        border-bottom: 1px solid $color-primary-10;
      }
    }
  }

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.5);
    z-index: 1;
  }

  &--enter-state {
    .content {
      animation: modal-template-enter 0.45s
        cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }
  }

  &--leave-state {
    .content {
      animation: modal-template-leave 0.45s
        cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }
  }

  @keyframes modal-template-enter {
    0% {
      opacity: 0;
      @include transform(scale(0.9));
    }

    100% {
      opacity: 1;
      @include transform(scale(1));
    }
  }

  @keyframes modal-template-leave {
    0% {
      opacity: 1;
      @include transform(scale(1));
    }

    100% {
      opacity: 0;
      @include transform(scale(0.9));
    }
  }
}
</style>
