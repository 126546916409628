var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.AppSurveyTypeform, {
    attrs: {
      "form-id": "Ar6LZAUb",
      type: "light",
      "user-id": _vm.$store.state.authentication.user.id,
      height: 450,
      value: true,
    },
    on: {
      close: function ($event) {
        return _vm.$emit("close")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }