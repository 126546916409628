var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _vm.as,
    _vm._g(
      _vm._b(
        {
          tag: "Component",
          class: [
            "button",
            `button--${_vm.type}`,
            `button--${_setup.computedSize}`,
          ],
        },
        "Component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm.loading ? _c(_setup.Spinner) : _vm._t("icon"), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }