<script setup>
import TransactionsListing from "./TransactionsListing.vue";
import CardsListing from "./CardsListing.vue";
import WalletSettings from "./WalletSettings.vue";
import { useRoute } from "vue-router/composables";
import { ref, onMounted, onBeforeUnmount, watch, computed } from "vue";
import api from "@/api/api";
import store from "@/store";
import { useToast } from "@/hooks";
import CardsServices from "@/api/actions/cards-services";

const route = useRoute();
const toast = useToast();

function onCardDetail(message) {
  if (message.origin.indexOf("lithic") > -1) {
    store.commit("currentCard", message.data.data);
  }
}

const cards = computed(() => {
  return store.state.cards.cards;
});

const currentCard = computed(() => {
  return cards.value.results.find((card) => card.id === route.params.id);
});

const cardUrl = ref("");

function getCardInformation() {
  cardUrl.value = "";
  store.commit("currentCard", "");
  api()
    .get(
      `/api/v1/cloaked/identity/${currentCard.value.identity_id}/card/${currentCard.value.id}`
    )
    .then(({ data }) => {
      cardUrl.value = data.url;
    })
    .catch((error) => {
      toast.error(error);
      cardUrl.value = "";
    });
}

onMounted(() => {
  window.addEventListener("message", onCardDetail, true);
  CardsServices.getPrimaryCard();
  document.body.classList.add("wallet");
});

onBeforeUnmount(() => {
  window.removeEventListener("message", onCardDetail);
  document.body.classList.remove("wallet");
});

watch(route, () => {
  if (route.params.id) {
    getCardInformation();
  }
});

watch(cards, () => {
  if (route.params.id && cards.value.results.length > 0) {
    getCardInformation();
  }
});
</script>

<template>
  <section>
    <CardsListing />

    <main>
      <div class="container">
        <WalletSettings />
        <TransactionsListing />

        <iframe
          v-if="route.params.id && cardUrl"
          :src="cardUrl"
          width="100%"
          height="100%"
          frameborder="0"
          :key="route.params.id"
          class="card-iframe"
        ></iframe>
      </div>
    </main>
  </section>
</template>

<style lang="scss" scoped>
section {
  width: 100%;
  display: flex;
  min-height: calc(100vh - 60px);

  main {
    width: calc(100% - 400px);
    padding: 22px;
    margin-left: 400px;
    background-color: $color-surface;

    .container {
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;
      position: relative;
    }
  }
}

.card-iframe {
  position: absolute;
  top: -9999px;
  left: -9999px;
  border: none;
  z-index: 1;
  width: 1px;
  height: 1px;
}
</style>
