<template>
  <svg
    :width="width"
    height="4"
    :viewBox="`0 0 ${width} 4`"
    xmlns="http://www.w3.org/2000/svg"
    class="progress-line"
  >
    <line
      x1="2"
      y1="2"
      :x2="width - 2"
      y2="2"
      stroke-width="4"
      stroke-linecap="round"
      class="progress-line__background"
    />
    <line
      x1="2"
      y1="2"
      :x2="width - 2"
      y2="2"
      stroke-width="4"
      stroke-linecap="round"
      pathLength="1"
      :stroke-dasharray="indicatorDasharray"
      class="progress-line__indicator"
    />
  </svg>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 256,
    },
  },
  computed: {
    indicatorDasharray() {
      return `${Math.min(1, Math.max(0, this.progress))} 1`;
    },
  },
};
</script>

<style lang="scss">
.progress-line {
  &__indicator {
    transition: stroke-dasharray 300ms ease-out;
    stroke: $color-primary-100;
  }

  &__background {
    stroke: $color-primary-10;
  }
}
</style>
