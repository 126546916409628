<script setup>
import { createPopper as initializePopper } from "@popperjs/core";
import VueClickOutside from "v-click-outside";

const vClickOutside = VueClickOutside.directive;

import {
  defineProps,
  defineEmits,
  ref,
  watch,
  onBeforeUnmount,
  computed,
  nextTick,
  onUpdated,
} from "vue";

const props = defineProps({
  value: {
    type: Boolean,
    default: null,
  },
  placement: {
    type: String,
    default: "bottom",
    validator: (value) =>
      [
        "bottom",
        "bottom-start",
        "bottom-end",
        "top",
        "top-start",
        "top-end",
        "left",
        "left-start",
        "left-end",
        "right",
        "right-start",
        "right-end",
      ].includes(value),
  },
  strategy: {
    type: String,
    default: "fixed",
    validator: (value) => ["fixed", "absolute"].includes(value),
  },
  offsetAway: {
    type: Number,
    default: 2,
  },
  offsetAlong: {
    type: Number,
    default: 0,
  },
  transition: {
    type: String,
    default: "fade-100",
  },
  portal: {
    type: [String, null],
    default: null,
  },
  zIndex: {
    type: Number,
    default: 4000,
  },
  width: {
    type: String,
    default: "initial",
  },
  height: {
    type: String,
    default: "initial",
  },
  maxHeight: {
    type: String,
    default: "unset",
  },
  hasClickOpen: {
    type: Boolean,
    default: true,
  },
  hasClickClose: {
    type: Boolean,
    default: true,
  },
  hasMouseEnterOpen: {
    type: Boolean,
    default: false,
  },
  hasMouseLeaveClose: {
    type: Boolean,
    default: false,
  },
  hasRightClickOpen: {
    type: Boolean,
    default: false,
  },
  hasOutsideClickClose: {
    type: Boolean,
    default: true,
  },
  hasContentClickClose: {
    type: Boolean,
    default: false,
  },
  hasEventOffset: {
    type: Boolean,
    default: false,
  },
});

const uniqueKey = ref(self.crypto.randomUUID());
const internalValue = ref(props.value ?? false);
const lastActivatorEvent = ref(null);

const activator = ref(null);
const content = ref(null);
const slot = ref(null);

let popper = null;
const popperOptions = computed(() => ({
  placement:
    props.hasEventOffset && lastActivatorEvent.value
      ? "bottom-start"
      : props.placement,
  strategy: props.strategy,
  modifiers: [
    {
      name: "offset",
      options: {
        offset: (popper) => [
          props.hasEventOffset && lastActivatorEvent.value
            ? lastActivatorEvent.value.offsetX
            : props.offsetAlong,
          props.hasEventOffset && lastActivatorEvent.value
            ? lastActivatorEvent.value.offsetY - popper.reference.height
            : props.offsetAway,
        ],
      },
    },
  ],
}));

const createPopper = () => {
  nextTick(() => {
    if (!popper) {
      popper = initializePopper(
        activator.value,
        content.value,
        popperOptions.value
      );
    }
  });
};

const destroyPopper = () =>
  nextTick(() => {
    popper?.destroy();
    popper = null;
  });

const updatePopperOptions = () =>
  nextTick(() => popper?.setOptions(popperOptions.value));

watch(() => props.placement, updatePopperOptions);
watch(() => props.strategy, updatePopperOptions);
watch(() => props.offsetAway, updatePopperOptions);
watch(() => props.offsetAlong, updatePopperOptions);
watch(
  () => props.value,
  (newValue) => {
    internalValue.value = newValue;
  }
);
watch(
  () => internalValue.value,
  (newValue) => {
    if (newValue) {
      createPopper();
    } else {
      destroyPopper();
      lastActivatorEvent.value = null;
    }
  },
  { immediate: true }
);

onBeforeUnmount(destroyPopper);

const emit = defineEmits(["input", "open", "close"]);

const open = ({ offsetX, offsetY }) => {
  lastActivatorEvent.value = { offsetX, offsetY };

  if (internalValue.value) {
    return;
  }

  if (props.value === null) {
    internalValue.value = true;
  }

  emit("input", true);
  emit("open");
};

const close = () => {
  if (!internalValue.value) {
    return;
  }

  if (props.value === null) {
    internalValue.value = false;
  }

  emit("input", false);
  emit("close");
};

const onClick = (event) => {
  if (internalValue.value) {
    props.hasClickClose && close();
  } else {
    props.hasClickOpen && open(event);
  }
};

const onContextMenu = (event) => {
  if (!props.hasRightClickOpen) {
    return;
  }

  internalValue.value || open(event);
};

const onMouseEnter = (event) => props.hasMouseEnterOpen && open(event);

const onMouseLeave = () => props.hasMouseLeaveClose && close();

const onOutsideClick = () => props.hasOutsideClickClose && close();

const onContentClick = () => props.hasContentClickClose && close();

onUpdated(() => {
  // consider updating only after inner html changes
  if (slot.value) {
    popper?.forceUpdate();
  }
});
</script>

<template>
  <div
    v-click-outside="{
      handler: onOutsideClick,
      events: ['contextmenu', 'focusin', 'mousedown'],
    }"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    v-bind="$attrs"
    v-on="$listeners"
    class="popper"
  >
    <div
      ref="activator"
      @click="onClick"
      @contextmenu.prevent="onContextMenu"
      class="popper__activator"
    >
      <slot name="default" />
    </div>
    <component
      :is="portal ? 'portal' : 'div'"
      :to="portal"
      v-if="internalValue"
    >
      <div
        ref="content"
        @click="onContentClick"
        :style="{ width, height, maxHeight, zIndex }"
        class="popper__content"
        :key="uniqueKey"
      >
        <transition :name="transition" appear>
          <div
            :style="{ width, height, maxHeight }"
            v-show="internalValue"
            ref="slot"
          >
            <slot name="content" />
          </div>
        </transition>
      </div>
    </component>
  </div>
</template>
