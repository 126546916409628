var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h1", [_vm._v("E-Sign Disclosure and Consent")]),
      _c("p", [
        _vm._v(
          " This policy describes how Cloaked delivers communications to you electronically. We may amend this policy at any time by providing a revised version on our website. The revised version will be effective at the time we post it. We will provide you with prior notice of any material changes via our website. "
        ),
      ]),
      _c("h2", [_vm._v("Electronic Delivery of Communications")]),
      _c("p", [
        _vm._v(
          ' You agree and consent to receive electronically all communications, agreements, documents, notices and disclosures (collectively, "Communications") that we provide in connection with your Cloaked Account(s) and your use of Cloaked Services. Communications include: '
        ),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            " Terms of use and policies you agree to (e.g., the Cloaked Terms of Service and Privacy Policy, or other product-specific agreements), including updates to these agreements or policies; "
          ),
        ]),
        _c("li", [
          _vm._v(
            " Account information, transaction receipts, confirmations, and any other Account or transaction information; "
          ),
        ]),
        _c("li", [
          _vm._v(
            " Legal, regulatory, and tax disclosures or statements we may be required to make available to you; and "
          ),
        ]),
        _c("li", [
          _vm._v(
            " Responses to claims or customer support inquiries filed in connection with your Account. "
          ),
        ]),
      ]),
      _c("p", [
        _vm._v(
          " We will provide these Communications to you by posting them on the Cloaked Site, emailing them to you at the primary email address listed in your Cloaked profile, communicating to you via instant chat, and/or through other electronic communication such as text message or mobile push notification. You agree that providing these Communications in any of these mediums is sufficient notice and service to you. "
        ),
      ]),
      _c("h2", [_vm._v("Hardware and Software Requirements")]),
      _c("p", [
        _vm._v(
          " In order to access and retain electronic Communications, you will need the following computer hardware and software: "
        ),
      ]),
      _c("ul", [
        _c("li", [_vm._v("A device with an Internet connection;")]),
        _c("li", [
          _vm._v(
            " A current web browser that includes 128-bit encryption (e.g. Internet Explorer version 9.0 and above, Firefox version 3.6 and above, Chrome version 31.0 and above, or Safari 7.0 and above) with cookies enabled; "
          ),
        ]),
        _c("li", [
          _vm._v(
            " A valid email address (your primary email address on file with Cloaked); and "
          ),
        ]),
        _c("li", [
          _vm._v(
            " Sufficient storage space to save past Communications or an installed printer to print them. "
          ),
        ]),
      ]),
      _c("h2", [_vm._v("How to Withdraw Your Consent")]),
      _c("p", [
        _vm._v(
          " You may withdraw your consent to receive Communications electronically by contacting us at support@cloaked.app. If you fail to provide or if you withdraw your consent to receive Communications electronically, Cloaked reserves the right to immediately close your Cloaked Account or charge you additional fees for paper copies. "
        ),
      ]),
      _c("h2", [_vm._v("Updating your Information")]),
      _c("p", [
        _vm._v(
          " It is your responsibility to provide us with a true, accurate and complete e-mail address and your contact information, and to keep such information up to date. You understand and agree that if Cloaked sends you an electronic Communication but you do not receive it because your primary email address on file is incorrect, out of date, blocked by your service provider, or you are otherwise unable to receive electronic Communications, Cloaked will be deemed to have provided the Communication to you. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " You may update your information by logging into your account and visiting settings or by contacting our support team at support@cloaked.app. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }