var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesPanel",
    { staticClass: "forwarding-pref" },
    [
      _c("PreferencesTitle", [_vm._v(_vm._s(_vm.header))]),
      _c(
        "div",
        { staticClass: "sub-feat-list" },
        [
          _vm.isRestrictedPlan
            ? _c("h2", [_vm._v("You will continue to have access to")])
            : _vm._e(),
          _vm._l(_vm.features, function (feats, idx) {
            return _c(
              "div",
              { key: `sub-feats-${idx}`, staticClass: "sub-feat-row" },
              [
                _c("CheckActive"),
                _c("span", [_vm._v(_vm._s(feats))]),
                feats.toLowerCase().includes("phone numbers")
                  ? _c("sup", [_vm._v("1")])
                  : feats.toLowerCase().includes("auto-change")
                  ? _c("sup", [_vm._v("2")])
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "sub-feat-list" },
        [
          _vm.isRestrictedPlan
            ? _c("h2", [_vm._v("You are no longer getting")])
            : _vm._e(),
          _vm._l(_vm.expiredFeatures, function (feats, idx) {
            return _c(
              "div",
              { key: `sub-feats-${idx}`, staticClass: "sub-feat-row" },
              [
                _c("CheckCancelled", { staticClass: "cancelled" }),
                _c("span", [_vm._v(_vm._s(feats))]),
                feats.toLowerCase().includes("phone numbers")
                  ? _c("sup", [_vm._v("1")])
                  : feats.toLowerCase().includes("auto-change")
                  ? _c("sup", [_vm._v("2")])
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("div", { staticClass: "footnotes-wrapper" }, [
        _vm.showPhoneFootnote
          ? _c("div", { staticClass: "footnotes-row" }, [
              _c("div", [_vm._v("1.")]),
              _c("div", [
                _vm._v(
                  " New phone number availability is subject to our bad actor mitigation system. Our phone numbers use a combination of daily creation behavior, quantity of messages/calls created, and various rules in order to prevent abuse on cloaked phone numbers. "
                ),
              ]),
            ])
          : _vm._e(),
        _vm.showAutoChangeFootnote
          ? _c("div", { staticClass: "footnotes-row" }, [
              _c("div", [_vm._v("2.")]),
              _c("div", [
                _vm._v(
                  " AutoCloak is one of the many upcoming features that will be included in your subscription plan. "
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm.stripeEmail
        ? _c(
            "a",
            {
              staticClass: "manage-link",
              attrs: { href: _vm.href, target: "_blank" },
            },
            [_c("span", [_vm._v(_vm._s(_vm.linkText))]), _c("LinkOutIcon")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }