var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.addingNew
    ? _c(
        _setup.CustomFieldFormAddressNew,
        _vm._g(
          _vm._b({}, "CustomFieldFormAddressNew", _vm.$attrs, false),
          _vm.$listeners
        )
      )
    : _c(
        _setup.CustomFieldFormAddressExisting,
        _vm._g(
          _vm._b(
            {
              attrs: { existingAddresses: _vm.existingAddresses },
              on: { "add-new": _setup.onAddNew },
            },
            "CustomFieldFormAddressExisting",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }