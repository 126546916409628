var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "wrapper" }, on: { click: _setup.handleClick } },
    [
      _c("dialog", { staticClass: "actionsMenu", attrs: { id: _vm.id } }, [
        _c("div", { attrs: { id: "actionsMenuInner" } }, [
          _c(
            "div",
            {
              attrs: { "aria-id": "KabobEditCategory" },
              on: {
                click: function ($event) {
                  return _setup.emit("handleEditCatName", _setup.props.category)
                },
              },
            },
            [_vm._v(" Edit category name ")]
          ),
          _c("div", { staticClass: "divider" }),
          _c(
            "div",
            {
              attrs: { "aria-id": "KabobDeleteCategory" },
              on: {
                click: function ($event) {
                  return _setup.emit("handleDeleteCat")
                },
              },
            },
            [_vm._v(" Delete ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }