<template>
  <label
    class="input-checkbox"
    :class="{ 'input-checkbox--disabled': disabled }"
  >
    <input
      type="checkbox"
      :id="id"
      :value="id"
      :disabled="disabled"
      v-model="checkboxModel"
      class="input-checkbox__input"
    />
    <CheckboxChecked class="input-checkbox__input--checked" />
    <CheckboxUnchecked class="input-checkbox__input--unchecked" />
    <slot />
  </label>
</template>

<script>
import CheckboxChecked from "@/assets/icons/checkbox-checked.svg";
import CheckboxUnchecked from "@/assets/icons/checkbox-unchecked.svg";

export default {
  components: {
    CheckboxChecked,
    CheckboxUnchecked,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkboxModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss">
.input-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $color-primary-100;

  &:hover {
    opacity: 0.9;
  }

  &--disabled {
    opacity: 1;
    color: $color-primary-60;
    cursor: not-allowed;
  }

  &__input {
    margin: 0;

    &--checked,
    &--unchecked {
      flex-shrink: 0;

      @at-root .input-checkbox--disabled & {
        opacity: 0.25;
      }
    }

    &--checked {
      display: none;

      @at-root .input-checkbox__input:checked ~ & {
        display: block;
      }
    }

    &--unchecked {
      display: block;

      @at-root .input-checkbox__input:checked ~ & {
        display: none;
      }
    }
  }
}
</style>
