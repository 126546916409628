var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "skeleton-table" },
    _vm._l(_vm.$attrs.rows ?? 11, function (i) {
      return _c(
        "div",
        { key: i, staticClass: "skeleton-table__row" },
        _vm._l(_vm.$attrs.cols ?? 4, function (i) {
          return _c("div", { key: i, staticClass: "skeleton-table__cell" })
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }