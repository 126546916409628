var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesPanel",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("PreferencesHeader", {
                on: {
                  "go-back": function ($event) {
                    return _vm.$emit("toggleBack", $event)
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("PreferencesTitle", [_vm._v("Birthday")]),
      _c("PreferencesInput", {
        attrs: {
          label: "Birthday (YYYY-MM-DD)",
          type: "date",
          error: _vm.error,
          "error-message": _vm.errorMessage,
        },
        on: { save: _vm.handleSave },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c(
        "PreferencesFooter",
        [
          _c(
            "Button",
            {
              attrs: { disabled: _vm.loading, loading: _vm.loading },
              on: { click: _vm.handleSave },
            },
            [_vm._v("Save changes")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }