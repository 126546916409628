<template>
  <div class="ui-menu-scroll-body">
    <slot />
  </div>
</template>

<script>
export default {
  name: "UiMenuScrollBody",
};
</script>

<style lang="scss">
.ui-menu-scroll-body {
  overflow-x: auto;
  @include custom-scroll-bar();
}
</style>
