<template>
  <div
    class="onboarding"
    :class="{ 'onboarding--animating-done': isInitialAnimationDone }"
  >
    <h1 class="onboarding__title">Get started with Cloaked</h1>
    <h2 class="onboarding__subtitle">
      Going through the following guide will help you better understand how
      Cloaked works.
    </h2>
    <TransitionGroup tag="ul" name="reorder" class="onboarding__steps">
      <li
        v-for="step in orderedSteps"
        :key="step"
        class="onboarding__steps-item"
      >
        <OnboardingStep
          :title="STEPS_CONFIGURATION[step].title"
          :subTitle="STEPS_CONFIGURATION[step].subTitle"
          :image="STEPS_CONFIGURATION[step].image"
          :clickable="!!STEPS_CONFIGURATION[step].clickable"
          :is-completed="$store.getters['onboarding/isStepCompleted'](step)"
          :is-active="$route.meta.step === step"
          @click.native="$router.push(STEPS_CONFIGURATION[step].route)"
        />
      </li>
    </TransitionGroup>
    <Button
      type="secondary"
      class="onboarding__dismiss"
      @click="isDismissOpen = true"
    >
      Dismiss checklist
    </Button>
    <OnboardingDismiss v-model="isDismissOpen" />
    <router-view />
  </div>
</template>

<script>
import OnboardingStep from "@/components/feature/onboarding/OnboardingStep";
import OnboardingDismiss from "@/components/feature/onboarding/OnboardingDismiss";
import { Button } from "@/components";
import {
  STEP_DEMO,
  STEP_FORWARDING,
  STEP_APP,
  STEP_IMPORT,
} from "@/store/modules/onboarding";
import { mapGetters } from "vuex";

const SCENARIO_EXTENSION_INSTALLED = "SCENARIO_EXTENSION_INSTALLED";
const SCENARIO_EXTENSION_MISSING = "SCENARIO_EXTENSION_MISSING";
const SCENARIO_BROWSER_NOT_SUPPORTED = "SCENARIO_BROWSER_NOT_SUPPORTED";

export default {
  components: { OnboardingDismiss, OnboardingStep, Button },
  data() {
    return {
      isDismissOpen: false,
      isInitialAnimationDone: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isInitialAnimationDone = true;
    }, 1000);
  },
  computed: {
    extensionNotInstalled() {
      return (
        this.extensionScenario === SCENARIO_BROWSER_NOT_SUPPORTED ||
        this.extensionScenario === SCENARIO_EXTENSION_MISSING
      );
    },
    browserSupported() {
      return this.$store.getters["browser/browserSupported"];
    },
    extensionDetected() {
      return this.$store.getters["browser/pluginDetected"];
    },
    extensionScenario() {
      if (this.browserSupported) {
        return this.extensionDetected
          ? SCENARIO_EXTENSION_INSTALLED
          : SCENARIO_EXTENSION_MISSING;
      } else {
        return SCENARIO_BROWSER_NOT_SUPPORTED;
      }
    },
    orderedSteps() {
      return [...this.$store.getters["onboarding/availableSteps"]].sort(
        (a, b) => {
          return this.completedSteps.includes(b) &&
            !this.completedSteps.includes(a)
            ? -1
            : 0;
        }
      );
    },
    STEPS_CONFIGURATION() {
      return {
        [STEP_DEMO]: {
          title: this.extensionNotInstalled
            ? "Install and learn how to use the Chrome extension"
            : "How to use the Cloaked browser extension",
          subTitle: "Let's begin",
          image: require("@/assets/images/onboarding/onboarding-demo.jpg"),
          route: { name: "OnboardingDemo" },
          clickable: true,
        },
        [STEP_FORWARDING]: {
          title: "Choose where you want emails, texts, and calls to go",
          subTitle: "Setup Now",
          image: require("@/assets/images/onboarding/onboarding-forwarding.jpg"),
          route: { name: "OnboardingForwarding" },
        },
        [STEP_APP]: {
          title: "Download the Cloaked mobile app",
          subTitle: "Download Now",
          image: require("@/assets/images/onboarding/onboarding-app.jpg"),
          route: { name: "OnboardingApp" },
          clickable: true,
        },
        [STEP_IMPORT]: {
          title: "Import and secure your online accounts",
          subTitle: "Let's begin",
          image: require("@/assets/images/onboarding/onboarding-import.jpg"),
          route: { name: "OnboardingImport" },
          clickable: true,
        },
      };
    },
    ...mapGetters({
      completedSteps: "onboarding/completedSteps",
      availableSteps: "onboarding/availableSteps",
    }),
  },
  watch: {
    completedSteps: {
      immediate: true,
      handler(newValue) {
        if (newValue.length === this.availableSteps.length) {
          this.$toast.success(
            "You’re all done! Redirecting to your identities..."
          );
          setTimeout(() => {
            this.$store.dispatch("onboarding/completeOnboarding");
            this.$router.push({ path: "/" });
          }, 10000);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.onboarding {
  padding: 53px 48px;

  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: $color-primary-100;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.4px;
    color: $color-primary-100;
    max-width: 535px;
    margin-top: 16px;
  }

  &__steps {
    margin-top: 48px;
    display: flex;
    width: 100%;

    @media (max-width: 800px) {
      flex-wrap: wrap;
    }

    &-item {
      animation: appear-bottom-10 0.3s forwards ease-out;
      opacity: 0;
      margin-right: 17px;
      margin-bottom: 22px;
      width: 100%;

      @at-root .onboarding--animating-done & {
        animation: none;
        opacity: 1;
      }

      &:last-of-type {
        margin-left: 0;
      }

      @media (min-width: 1024px) {
        max-width: 312px;
      }

      @for $i from 1 to 5 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 0.05}s;
        }
      }
    }
  }

  &__dismiss {
    margin-top: 10px;
    animation: appear-bottom-5 0.5s forwards ease-out;
    opacity: 0;
    animation-delay: 0.25s;
  }
}

.reorder-move {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
</style>
