var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.files.length > 0
    ? _c(
        "div",
        { staticClass: "file-list", class: { error_m: _vm.ruleErrors } },
        _vm._l(_vm.files, function (file, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "file",
              class: { error: file.error || _vm.maxPayload },
            },
            [
              file.uploading
                ? _c(
                    "button",
                    { staticClass: "icon" },
                    [_c("FileLoadingIcon")],
                    1
                  )
                : _c("button", { staticClass: "icon" }, [_c("FileIcon")], 1),
              _c(
                "button",
                {
                  staticClass: "delete",
                  on: {
                    click: function ($event) {
                      return _vm.removeFile(index)
                    },
                  },
                },
                [_c("CloseXIcon")],
                1
              ),
              _c("div", { staticClass: "file-name" }, [
                _vm._v(" " + _vm._s(file.filename) + " "),
              ]),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }