var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "activity-convo-header" },
    [
      _c(
        "div",
        { staticClass: "contact-info" },
        [
          _c("ProfileIconLarge", {
            staticClass: "icon clickable",
            on: {
              click: function ($event) {
                return _vm.$emit("openCloak")
              },
            },
          }),
          _c("div", [
            _c("div", { staticClass: "sender clickable" }, [
              _vm._v(" " + _vm._s(_vm.senderDisplay) + " "),
            ]),
            _c(
              "div",
              {
                staticClass: "receiver",
                on: {
                  click: function ($event) {
                    return _vm.$emit("openCloak")
                  },
                },
              },
              [
                _vm._v(
                  " To: " +
                    _vm._s(_vm.cloakName) +
                    " " +
                    _vm._s(_vm.cloakIdentifierString) +
                    " "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "UiMenu",
        {
          attrs: { width: "188px", placement: "bottom-end" },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("UiMenuButton", {
                    attrs: { title: `Mark as ${_vm.read ? "un" : ""}read` },
                    on: { click: _vm.toggleReadStatus },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [_c("ActivityMarkAsReadIcon")]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("UiMenuSeparator"),
                  _c(
                    "div",
                    { staticClass: "alert-text" },
                    [
                      _c("UiMenuButton", {
                        attrs: { title: "Delete", danger: "" },
                        on: { click: _vm.showDeleteModal },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [_c("DeleteTrashIcon")]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("div", { staticClass: "kabob-wrapper" }, [_c("KabobIcon")], 1)]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }