<script setup>
import { defineProps, ref } from "vue";

const props = defineProps({
  title: String,
  toggleState: {
    type: Boolean,
    default: false,
  },
});

const toggle = ref(props.toggleState);

function toggleMenu() {
  toggle.value = !toggle.value;
}
</script>

<template>
  <div class="menu-block" :class="{ toggle: toggle }">
    <div class="menu-title" v-if="props.title">
      <span>{{ props.title }}</span>
      <i class="toggle" :class="{ active: toggle }" @click="toggleMenu()"></i>
    </div>

    <div class="menu-items">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu-block {
  margin-top: 22px;

  &:first-child {
    margin-top: 0;
  }

  &.toggle {
    .menu-items {
      height: 0;
    }
  }

  .menu-title {
    position: relative;
    margin-bottom: 10px;

    span {
      color: $color-primary-100;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    i {
      position: absolute;
      right: 0;
      top: 50%;
      @include transform(translateY(-50%));
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      width: 24px;
      height: 24px;

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        @include transform(rotate(45deg));
        transform-origin: center center;
        width: 8px;
        height: 8px;
        border: 2px solid $color-primary-100;
        border-top-width: 0;
        border-left-width: 0;
        border-bottom-right-radius: 2px;
        margin-top: -4px;
        margin-left: -4px;
        @include transition(all 0.2s ease-in-out);
      }

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        @include transform(translate(-50%, -50%));
        width: 0px;
        height: 0px;
        background: $color-primary-10;
        z-index: -1;
        border-radius: 50%;
        @include transition(all 0.3s ease-in-out);
      }

      &.active {
        &:before {
          @include transform(rotate(-45deg));
        }
      }

      &:hover {
        &:after {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .menu-items {
    height: auto;
    overflow: hidden;
  }
}
</style>
