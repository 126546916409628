var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.Button, {
    attrs: {
      loading: _setup.loading,
      icon: "fire",
      text: "Self-destruct",
      title: _setup.yearsMonthsLeft(),
      subtext: _setup.date,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }