<template>
  <ModalTemplate
    class="add-verify-email-mail"
    :show="true"
    @close="handleClose"
    no-close
  >
    <template #header>
      <div v-if="isMigration && logoutStep">
        <h1>Sign in to continue</h1>
      </div>
      <div v-else-if="!confirming">
        <h1>{{ title || "Add an email address" }}</h1>
      </div>
      <div v-else>
        <h1>Verify your email address</h1>
      </div>
    </template>

    <template #body>
      <div class="subheader" v-if="isMigration && logoutStep">
        In order to finish your security upgrade, we need to log you out of your
        Cloaked account. Please sign in again to complete the upgrade.
      </div>
      <div class="subheader" v-else-if="!confirming">
        <OnboardingInputEmail
          :value="email"
          @input="handleEmailInput"
          @keydown.enter="saveEmail"
          placeholder="Email"
        />
      </div>
      <div v-else>
        Enter the 6-digit code sent to <strong>{{ email }}</strong
        ><br />
        <OnboardingInputCode
          :value="code"
          @input="handleCodeInput"
          @keydown.enter="verify"
        />
      </div>
      <div class="input_error" v-if="emailError || codeError">
        {{ emailError || codeError }}
      </div>
    </template>
    <template #footer>
      <template v-if="isMigration && logoutStep">
        <Button @click="logout"> Continue </Button>
      </template>
      <template v-else-if="!confirming">
        <Button v-if="!isMigration" @click="handleCancel" type="secondary">
          Cancel
        </Button>
        <Button @click="saveEmail" :disabled="!validEmail"> Continue </Button>
      </template>
      <template v-else>
        <Button
          v-if="isMigration"
          type="secondary"
          @click="
            confirming = false;
            savingEmail = false;
          "
        >
          Back
        </Button>
        <Button type="secondary" @click="resend">
          <span class="spin-loader" v-if="loading" /> Resend code
        </Button>

        <Button @click="verify" :disabled="!validCode"> Verify </Button>
      </template>
    </template>
  </ModalTemplate>
</template>
<script>
import store from "@/store";
import { email } from "@/scripts/validation";
import ModalTemplate from "@/components/ModalTemplate.vue";
import { Button } from "@/components";
import OnboardingInputEmail from "@/components/feature/onboarding/OnboardingInputEmail";
import OnboardingInputCode from "@/components/feature/onboarding/OnboardingInputCode";
import EmailService from "@/api/actions/email-service";

export default {
  name: "AddVerifyEmail",
  components: {
    ModalTemplate,
    Button,
    OnboardingInputEmail,
    OnboardingInputCode,
  },
  props: ["index", "alert", "setPrimary", "isMigration", "logout", "title"],
  data() {
    return {
      emailDirty: false,
      confirming: false,
      token: null,
      code: "",
      verifying: null,
      verified: false,
      saving: null,
      email: "",
      loading: true,
      savingEmail: false,
      emailError: null,
      codeError: null,
      emailVerified: false,
      logoutStep: false,
    };
  },
  watch: {
    email() {
      this.emailVerified = false;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs["ui-input"]) {
        const input = this.$refs["ui-input"].$el.querySelector("input");

        if (input) {
          input.focus();
        }
      }
    }, 100);
  },
  computed: {
    validEmail() {
      if (this.email) {
        return email(this.email);
      }
      return false;
    },
    validCode() {
      if (this.code) {
        return this.code.match(/[0-9]{6}/i);
      }
      return false;
    },
  },
  methods: {
    handleCodeInput(value) {
      this.code = value;
    },
    handleEmailInput($event) {
      this.email = $event.target.value;
    },
    handleCancel() {
      this.$emit("cancel");
      this.handleClose();
    },
    handleClose() {
      if (!this.isMigration) {
        this.$store.dispatch("closeModal");
      }
    },
    async saveEmail() {
      if (this.validEmail && !this.savingEmail) {
        this.emailError = null;
        this.savingEmail = true;

        const payload = {
          email: this.email.toLowerCase(),
          primary: !!this.setPrimary,
          collection_name: "email",
          collection: store.getters["authentication/collection"]("email"),
          user: store.state.authentication.user.url,
        };

        try {
          const { data: email } = await EmailService.addEmail(payload);
          this.emailDirty = false;
          if (!email.verified) {
            this.confirm_contact(email);
          } else {
            await EmailService.makePrimary(email.id);
            this.$emit("email-created", { ...email, primary: true });
            if (this.isMigration && this.logout) {
              this.logoutStep = true;
              return;
            }
            this.handleClose();
            this.$toast.success("Email address added.");
          }
        } catch (err) {
          this.savingEmail = false;
          const { response } = err;
          const { data } = response;
          let errors = data.errors || data;
          if (errors?.non_field_errors?.length > 0) {
            errors = errors.non_field_errors
              .map((errorMessage) => errorMessage)
              .join("\n");
          }
          if (errors.email) {
            errors = errors.email;
          }
          if (Array.isArray(errors)) {
            this.emailError = errors.join("\n");
          } else {
            this.emailError = errors;
          }
        }
      }
    },
    confirm_contact(email) {
      this.saving = email;
      this.loading = true;
      this.confirming = true;
      EmailService.sendVerificationCode(email.id)
        .then((verify_response) => {
          this.$toast.success("Verification code sent.");
          if (!verify_response.data.verified) {
            this.loading = false;
            this.token = verify_response.data.session_token;
            setTimeout(() => {
              if (this.$refs.verify) {
                this.$refs.verify.focus();
              }
            }, 100);
          }
        })
        .catch(() => {
          this.$toast.error("Error resending verification code.");
        });
    },
    setSaving(status) {
      this.saving = status;
    },
    resend() {
      this.loading = true;
      this.codeError = null;
      this.code = "";
      EmailService.sendVerificationCode(this.saving.id)
        .then((verify_response) => {
          this.$toast.success("Verification code sent.");
          if (!verify_response.data.verified) {
            this.token = verify_response.data.session_token;
          }
          this.loading = false;
          setTimeout(() => {
            this.$refs.verify.focus();
          }, 100);
        })
        .catch(() => {
          this.$toast.error("Error resending verification code.");
        });
    },
    async verify() {
      if (this.code && !this.verifying && !this.verified) {
        this.codeError = null;
        this.verifying = true;

        try {
          await EmailService.verifyVerificationCode(this.saving.id, {
            security_code: this.code,
            email: this.saving.email,
            session_token: this.token,
          });

          await EmailService.makePrimary(this.saving.id);
          this.$toast.success("Verification successful.");
          if (this.isMigration && this.logout) {
            this.logoutStep = true;
            return;
          }
          this.verifying = false;
          this.verified = true;
          window.dispatchEvent(new CustomEvent("cloak:refresh-emails"));
          this.handleClose();
          const newEmail = {
            ...this.saving,
            verified: true,
            primary: true,
          };

          this.$store.dispatch("settings/saveNewPersonalEmail", newEmail);
          this.$emit("email-verified", {
            ...this.saving,
            verified: true,
          });
        } catch (err) {
          this.$toast.error("Error with verification.");
          this.verifying = false;
          this.verified = false;
          this.codeError = "Verification code is incorrect.";
        }
      }
    },

    handleCodeComplete(code) {
      this.code = code;
      this.verify();
    },
  },
};
</script>
<style lang="scss">
.add-verify-email-mail {
  color: $color-primary-100;
  .content {
    max-width: 512px !important;
  }
  .onboarding-input-email {
    width: 100% !important;
    border-radius: 10px;
    background-color: $color-primary-5;
  }
  .ui-confirm-code {
    margin-top: 32px;
  }

  .input_error {
    font-size: 12px;
    color: $color-alert;
    border: 1px solid $color-alert;
    border-radius: 10px;
    padding: 10px;
    margin: 20px 0px 5px 0px;
    background-color: #f82f2810;
  }
}
.subheader {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
}
</style>
