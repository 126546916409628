<template>
  <div
    class="ui-menu-input"
    :class="{
      'ui-menu-input--focused': isFocused,
    }"
  >
    <input
      type="text"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
export default {
  name: "UiMenuInput",
  props: {
    value: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      isFocused: false,
    };
  },

  methods: {
    handleInput($event) {
      this.$emit("input", $event.target.value);
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleBlur() {
      this.isFocused = false;
    },
  },
};
</script>

<style lang="scss">
.ui-menu-input {
  width: 100%;
  padding: 5px 10px;
  height: 40px;
  color: $color-primary-100;

  input {
    border: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    height: 24px;
    height: 30px;
    color: $color-primary-100;
    background: $color-background;

    &:active,
    &:focus-visible {
      outline: none;
    }
  }
}
</style>
