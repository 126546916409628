<script setup>
import PreferencesInput from "@/routes/modals/preferences/PreferencesInput.vue";
import Button from "@/components/Button.vue";
import { CloakInfoRow } from "@/components/cloakDetails";
import CloakedLogoDark from "@/assets/images/cloaked-logo-dark.svg";
import Timer from "@/components/Timer.vue";
import ErrorTriangleFilled from "@/assets/icons/error-triangle-filled.svg";
import Spinner from "@/assets/icons/spinner.svg";
import { computed, onBeforeMount, ref } from "vue";
import { getCopyValue } from "@/components/cloakDetails/CustomFields/utils";
import TOTPToken from "@/components/cloakDetails/TOTP/TOTPToken.vue";

const password = ref("");
const json = ref({});
const hash = computed(() => window.location.hash.substring(1));
const loading = ref(false);

const jsonUrl = computed(() => {
  const url = window.location.href;

  if (url.includes("develop") || url.includes("localhost")) {
    return `https://share.develop.your.cloaked.app/${
      hash.value
    }.json?r=${Math.random()}`;
  } else if (url.includes("staging")) {
    return `https://share.staging.your.cloaked.app/${
      hash.value
    }.json?r=${Math.random()}`;
  } else {
    return `https://share.your.cloaked.app/${
      hash.value
    }.json?r=${Math.random()}`;
  }
});

const getJson = async () => {
  loading.value = true;
  const getJson = await fetch(jsonUrl.value)
    .then((response) => response.json())
    .catch((error) => console.error(error));

  json.value = getJson;
  loading.value = false;
};

onBeforeMount(async () => {
  await getJson();
});

const startDate = computed(() => {
  return json?.value?.shared_at;
});

const endDate = computed(() => {
  return json?.value?.expires_at;
});

const isSharedIdentityAvailable = computed(() => {
  return json?.value?.data && Date.now() < new Date(endDate.value);
});

const identity = ref([]);

const shouldShowIdentityForm = computed(() => {
  return identity.value.length === 0 && isSharedIdentityAvailable.value;
});

const isOneTimeView = computed(() => {
  return json?.value?.onetimeview;
});

const isPasswordIncorrect = ref(false);

const totpToken = ref("");

const decryptJSON = async () => {
  try {
    const cloakedEncryption = await window.CloakedEncryption.build();
    const passwordSecretBoxKey =
      await cloakedEncryption.generatePasswordSecretBoxKey(
        password.value,
        json?.value?.salt
      );
    const privateKey = await cloakedEncryption.decryptPrivateKey(
      passwordSecretBoxKey,
      json?.value?.private_key
    );

    const decryptedData = await cloakedEncryption.decryptWithPrivateKeyPair(
      json?.value?.data,
      json?.value?.public_key,
      privateKey
    );
    identity.value = JSON.parse(decryptedData);
    isPasswordIncorrect.value = false;
  } catch (e) {
    isPasswordIncorrect.value = true;
  }
};

const getFieldType = (fieldType) => {
  return fieldType.includes("std_") ? fieldType.substring(4) : fieldType;
};

const openTrialPage = () => {
  window.open(
    "https://www.cloaked.app/14-days-free-private-beta-sign-up-aae?/utm_source=sharing&utm_medium=cloakedsharing&utm_campaign=sharing",
    "_blank"
  );
};

const openHomePage = () => {
  window.open("https://www.cloaked.app/", "_blank");
};
</script>

<template>
  <div class="shared">
    <div class="shared__background-blur"></div>
    <div class="shared__header">
      <CloakedLogoDark
        class="shared__header__cloaked-logo"
        @click="openHomePage"
      />
      <Timer
        :startDate="startDate"
        :endDate="endDate"
        class="shared__header__timer-container"
        v-show="isSharedIdentityAvailable"
        :isOnSharedPage="true"
        :isOneTimeView="isOneTimeView"
      />
    </div>

    <div v-if="loading" class="shared__loading-container">
      <Spinner />
    </div>

    <div v-else>
      <div v-if="shouldShowIdentityForm" class="shared__identity-container">
        <div class="shared__identity-container__description">
          <span class="shared__identity-container__description__text"
            >Someone shared an identity with you</span
          >
          <span
            class="shared__identity-container__description__text shared__identity-container__description__subtext"
            >This page is password protected.</span
          >
        </div>
        <PreferencesInput
          v-model="password"
          label="Password"
          type="password"
          placeholder=""
        />

        <div
          v-if="isPasswordIncorrect"
          class="shared__identity-container__error-container"
        >
          <ErrorTriangleFilled />
          <span class="shared__identity-container__error-container__text"
            >Incorrect password</span
          >
        </div>

        <Button
          class="shared__identity-container__button"
          @click="decryptJSON"
          :primary="true"
          >View identity
        </Button>
      </div>
      <div
        v-else-if="identity.length > 0"
        class="shared__identity-container shared__with-identity"
      >
        <span class="shared__identity-container__identity-details"
          >Identity details</span
        >
        <div class="shared__identity-container__identity-fields">
          <div v-for="identityProperty in identity" :key="identityProperty.i">
            <CloakInfoRow
              :fieldLabel="identityProperty.l"
              :field="getFieldType(identityProperty.t)"
              :initialValue="
                getCopyValue(identityProperty.t, identityProperty.v)
              "
              :isOnSharedPage="true"
              :isSensitive="identityProperty.s"
              :copy-value="identityProperty.t.includes('totp') ? totpToken : ''"
            >
              <template
                v-if="identityProperty.t.includes('totp') && identityProperty.v"
                #input
              >
                <TOTPToken
                  :url="
                    identityProperty.v?.startsWith('otpauth://')
                      ? identityProperty.v
                      : undefined
                  "
                  :secret="
                    identityProperty.v?.startsWith('otpauth://')
                      ? undefined
                      : identityProperty.v
                  "
                  @new-token="totpToken = $event"
                />
              </template>
            </CloakInfoRow>
          </div>
        </div>
      </div>
      <div v-else class="shared__no-identity-container">
        <div class="shared__no-identity-container__description">
          <span class="shared__no-identity-container__description__text"
            >The share link is no longer valid.</span
          >
          <span
            class="shared__no-identity-container__description__text shared__no-identity-container__description__subtext"
            >If you still need access, please reach out to the sender.</span
          >
        </div>
      </div>

      <div class="shared__cloaked-trial-container">
        <div class="shared__cloaked-trial-container__cloaked-trial-description">
          <span
            class="shared__cloaked-trial-container__cloaked-trial-description__text"
            >Cloaked makes privacy easy.</span
          >
          <span
            class="shared__cloaked-trial-container__cloaked-trial-description__text shared__cloaked-trial-container__cloaked-trial-description__subtext"
            >Cloaked protects your privacy online and in real life</span
          >
        </div>

        <Button
          class="shared__cloaked-trial-container__button"
          @click="openTrialPage"
          :primary="false"
          >Try Cloaked free for 14 days
        </Button>
      </div>
    </div>
    <div class="shared__footer">
      <div class="shared__footer__links">
        <a
          class="shared__footer__links__link"
          href="https://www.cloaked.app/blog"
          target="_blank"
          >Blog</a
        >
        <a
          class="shared__footer__links__link"
          href="https://twitter.com/keepitcloaked"
          target="_blank"
          >Twitter</a
        >
        <a
          class="shared__footer__links__link"
          href="https://cloaked.community/"
          target="_blank"
          >Discord</a
        >
        <a
          class="shared__footer__links__link"
          href="https://keepitcloaked.medium.com/"
          target="_blank"
          >Medium</a
        >
        <a
          class="shared__footer__links__link"
          href="https://jobs.lever.co/cloaked-app"
          target="_blank"
          >Join our Team</a
        >
        <a
          class="shared__footer__links__link"
          href="https://www.cloaked.app/changelog"
          target="_blank"
          >Changelog</a
        >
        <a
          class="shared__footer__links__link"
          href="https://www.cloaked.app/faq"
          target="_blank"
          >FAQ</a
        >
      </div>

      <div class="shared__footer__divider"></div>
      <div class="shared__footer__legal">
        <CloakedLogoDark
          class="shared__footer__legal__cloaked-logo"
          @click="openHomePage"
        />
        <div class="shared__footer__legal__links">
          <a
            class="shared__footer__legal__links__link"
            href="https://www.cloaked.app/privacy-policy"
            target="_blank"
            >Privacy Policy</a
          >
          <a
            class="shared__footer__legal__links__link"
            href="https://www.cloaked.app/terms-of-service"
            target="_blank"
            >Terms of Service</a
          >
        </div>
      </div>
    </div>

    <div class="shared__mobile-footer">
      <div class="shared__mobile-footer__links">
        <a
          class="shared__mobile-footer__links__link"
          href="https://www.cloaked.app/blog"
          target="_blank"
          >Blog</a
        >
        <a
          class="shared__mobile-footer__links__link"
          href="https://twitter.com/keepitcloaked"
          target="_blank"
          >Twitter</a
        >
        <a
          class="shared__mobile-footer__links__link"
          href="https://cloaked.community/"
          target="_blank"
          >Discord</a
        >
        <a
          class="shared__mobile-footer__links__link"
          href="https://keepitcloaked.medium.com/"
          target="_blank"
          >Medium</a
        >
        <a
          class="shared__mobile-footer__links__link"
          href="https://jobs.lever.co/cloaked-app"
          target="_blank"
          >Join our Team</a
        >
      </div>

      <div class="shared__mobile-footer__links">
        <a
          class="shared__mobile-footer__links__link"
          href="https://www.cloaked.app/changelog"
          target="_blank"
          >Changelog</a
        >
        <a
          class="shared__mobile-footer__links__link"
          href="https://www.cloaked.app/faq"
          target="_blank"
          >FAQ</a
        >
        <a
          class="shared__mobile-footer__links__link"
          href="https://www.cloaked.app/privacy-policy"
          target="_blank"
          >Privacy Policy</a
        >
        <a
          class="shared__mobile-footer__links__link"
          href="https://www.cloaked.app/terms-of-service"
          target="_blank"
          >Terms of Service</a
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.shared {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  position: relative;
  width: 100%;
  align-items: center;
  background: $color-primary-100-light;
  z-index: 1;

  &__loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: $color-primary-0-light;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  &__background-blur {
    position: absolute;
    z-index: -1;
    width: 40vw;
    height: 488px;
    border-radius: 488px;
    opacity: 0.4;
    background: $color-background-light;
    filter: blur(252px);
  }

  &__no-identity-container {
    width: 580px;
    height: 108px;
    border-radius: 16px;
    padding: 24px 32px;
    background: $color-background-light;
    box-shadow: 0px 27px 40px -22px rgba(0, 0, 0, 0.06);
    margin: 16px 0;

    &__description {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.4px;
      gap: 12px;
      display: flex;
      flex-direction: column;

      &__text {
        color: $color-primary-100-light;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
      }

      &__subtext {
        color: $color-primary-100-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }

    @media (max-width: 768px) {
      width: 335px;
      height: auto;
      padding: 32px 24px;
    }
  }

  &__identity-container {
    width: 580px;
    display: flex;
    padding: 64px;
    flex-direction: column;
    gap: 24px;
    border-radius: 16px;
    background: $color-background-light;
    box-shadow: 0px 27px 40px -22px rgba(0, 0, 0, 0.06);
    margin: 16px 0;

    .preferences-input {
      input {
        background: $color-primary-5-light;
        color: $color-primary-100-light;
        border: none;
      }

      svg path {
        fill: $color-primary-100-light;
      }

      label {
        color: $color-primary-100-light;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.4px;
      gap: 12px;

      &__text {
        color: $color-primary-100-light;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
      }

      &__subtext {
        color: $color-primary-100-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }

    &__error-container {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      margin-top: -20px;

      &__text {
        color: $color-alert;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    &__button {
      justify-content: center;
      background-color: $color-primary-100-light !important;
      color: $color-background-light !important;

      &:hover {
        background-color: $color-primary-90-light !important;
      }
    }

    &__identity-details {
      color: $color-primary-100-light;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.5px;
    }

    &__identity-fields {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .cloak-info-row-button {
        &:hover {
          svg {
            color: $color-primary-0-light;
          }
        }
        svg {
          color: $color-primary-100-light;
        }
      }

      .cloak-details-input-row {
        .cloak-details-input-row__label {
          color: $color-primary-100-light;
          -webkit-text-fill-color: $color-primary-100-light;
          -webkit-opacity: 1;
          word-break: break-word;
          @include line-clamp(2);
        }

        .cloak-details-input-row__input-wrapper
          > .cloak-details-input-row__icon
          > svg {
          width: 16px;
          height: 16px;
        }

        .cloak-details-input-row__input {
          .totp-token__token {
            font-size: 12px;
            line-height: 18px;
            color: $color-primary-100-light;
            font-weight: 500;
          }
          input,
          textarea {
            color: $color-primary-100-light;
            -webkit-text-fill-color: $color-primary-100-light;
            -webkit-opacity: 1;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .cloak-details-input-row__icon {
          color: $color-primary-100-light;

          svg {
            color: $color-primary-100-light;
          }
        }
      }
    }

    @media (max-width: 768px) {
      width: 335px;
      height: auto;
      padding: 32px 24px;
    }
  }

  &__with-identity {
    padding: 24px 32px;
  }

  &__cloaked-trial-container {
    display: flex;
    flex-direction: row;
    width: 580px;
    height: 126px;
    border-radius: 16px;
    background: $color-brand-5-100-light;
    box-shadow: 0px 27px 40px -22px rgba(0, 0, 0, 0.06);
    padding: 24px 32px;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 48px;

    @media (max-width: 768px) {
      flex-direction: column;
      height: 200px;
      width: 335px;
      justify-content: space-evenly;
      text-align: center;
    }

    &__cloaked-trial-description {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &__text {
        color: $color-background-light;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.4px;
        @media (max-width: 768px) {
          text-align: center;
        }
      }

      &__subtext {
        color: $color-background-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
        width: 249px;

        @media (max-width: 768px) {
          width: 100%;
          text-align: center;
        }
      }
    }

    &__button {
      align-self: center;
      background-color: $color-background-light !important;
      color: $color-primary-100-light !important;
      border-color: $color-background-light !important;

      &:hover {
        background-color: $color-primary-10-light !important;
      }
    }
  }

  &__header {
    width: 580px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 32px;

    &__cloaked-logo {
      align-self: center;
      width: 129px;
      height: 26px;
      cursor: pointer;

      @media (max-width: 768px) {
        margin-left: 32px;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      &__timer-container {
        margin-right: 32px;
      }
    }
  }

  &__mobile-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 80px;
    gap: 40px;

    @media (min-width: 768px) {
      display: none;
    }

    &__links {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &__link {
        color: $color-background-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    color: white;
    width: 100%;
    margin-top: auto;
    margin-bottom: 80px;
    gap: 40px;
    padding: 0px 64px;

    @media (max-width: 768px) {
      display: none;
    }

    a {
      color: $color-background-light;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }

    &__links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__link {
        color: $color-background-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }
    }

    &__divider {
      height: 1px;
      opacity: 0.2;
      background: $color-primary-0-light;
    }

    &__legal {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &__links {
        display: flex;
        flex-direction: row;
        gap: 16px;
        font-size: 13px;

        &__link {
          color: $color-background-light;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
        }
      }

      &__cloaked-logo {
        width: 120px;
        height: 26px;
        margin-left: -5px;
        cursor: pointer;
      }
    }
  }
}
</style>
