<script setup>
import SettingsChildPage from "./SettingsChildPage.vue";
import { SettingsTitle, SettingsParagraph } from "@/components";
import Account from "@/routes/modals/preferences/Account.vue";
</script>
<template>
  <SettingsChildPage class="settings-account-page">
    <template v-slot:aside>
      <SettingsTitle>Account</SettingsTitle>
      <SettingsParagraph>Manage your account settings.</SettingsParagraph>
    </template>

    <Account />
  </SettingsChildPage>
</template>
