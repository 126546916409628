var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.AppModal,
    _vm._g(_vm._b({}, "AppModal", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        _setup.AppModalContent,
        [
          _c(_setup.AppModalTitle, [_vm._v(" Unsaved changes ")]),
          _c(_setup.AppModalParagraph, [_vm._v(" You have unsaved changes. ")]),
          _c(
            _setup.AppModalFooter,
            [
              _c(
                _setup.Button,
                {
                  attrs: { type: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("discard")
                    },
                  },
                },
                [_vm._v(" Discard ")]
              ),
              _c(
                _setup.Button,
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("input", false)
                    },
                  },
                },
                [_vm._v(" Keep editing ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }