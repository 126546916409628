var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        _setup.IdentitySharing,
        _vm._g(
          _vm._b(
            {
              attrs: {
                identity: _vm.identity,
                permissions: _setup.permissions,
                isShared: _setup.isShared,
                "active-modal": _setup.activeModal,
                isLoading: _setup.isLoading,
                isGeneratingLink: _setup.isGeneratingLink,
                isGeneratingPassword: _setup.isGeneratingPassword,
                isTooltipOpen: _setup.isTooltipOpen,
                hasAnnouncementTooltip: _setup.hasAnnouncementTooltip,
                sharing: _setup.sharing,
              },
              on: {
                "set-active-modal": function ($event) {
                  _setup.activeModal = $event
                },
                "set-is-tooltip-open": function ($event) {
                  _setup.isTooltipOpen = $event
                },
                "update-sharing": function ($event) {
                  _setup.sharing = $event
                },
                "discard-changes": _setup.resetChanges,
                create: _setup.onCreate,
                update: _setup.onUpdate,
                delete: _setup.onDelete,
                "generate-new-link": _setup.onGenerateNewLink,
                "generate-new-password": _setup.onGenerateNewPassword,
                expired: _setup.onExpired,
              },
              model: {
                value: _setup.isModalOpen,
                callback: function ($$v) {
                  _setup.isModalOpen = $$v
                },
                expression: "isModalOpen",
              },
            },
            "IdentitySharing",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }