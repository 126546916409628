var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.PreferencesPanel,
    {
      staticClass: "panel-offset",
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(_setup.PreferencesHeader, {
                on: { "go-back": _setup.toggleBack },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(_setup.PreferencesTitle, [_vm._v("Authorized devices")]),
      _c(
        "div",
        { staticClass: "section forward-panel" },
        _vm._l(_setup.props.devices, function (device) {
          return _c(_setup.ValueDisplay, {
            key: device?.uuid ? device.uuid : "uuid",
            attrs: {
              label: device?.name
                ? _setup.getDisplayName(device)
                : "unknown device",
              value: device?.updated_at
                ? `Verified ${_setup.getUserReadableDate(device.updated_at)}`
                : "unknown",
            },
            on: {
              delete: function ($event) {
                return _setup.deleteDevice(device.device_id)
              },
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }