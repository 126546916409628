var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.Back,
    _vm._g(
      {
        staticClass: "app-modal-back",
        class: { "app-modal-back--absolute": _setup.props.isAbsolute },
        nativeOn: {
          click: function ($event) {
            return _setup.emit("click")
          },
        },
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }