var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("table", [
        _c("thead", [
          _c("tr", [_c("th", { attrs: { colspan: "2" } }, [_vm._v("Fees")])]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v("Finance Charge(s)")]),
            _c("td", [_vm._v("None")]),
          ]),
          _c("tr", [
            _c("td", [
              _vm._v(" Transaction Fees "),
              _c("ul", [_c("li", [_vm._v("Foreign Transaction")])]),
            ]),
            _c("td", [_vm._v("None")]),
          ]),
          _c("tr", [
            _c("td", [
              _vm._v(" Penalty Fees "),
              _c("ul", [
                _c("li", [_vm._v("Late Payment")]),
                _c("li", [_vm._v("Returned Payment")]),
              ]),
            ]),
            _c("td", [_vm._v("None")]),
          ]),
        ]),
      ]),
      _c("p", [
        _vm._v(
          " Billing Rights: Information on your rights to dispute transactions and how to exercise those rights is provided in your account agreement. "
        ),
      ]),
      _c("p", [
        _vm._v(
          " Cloaked Pay availability is included in your subscription to Cloaked, which includes all Cloaked core and Cloaked Pay products and services. If you are eligible for Cloaked Pay, you can gain Cloaked Pay access with an active and paid Cloaked subscription. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }