<script setup>
import SkeletonTable from "@/components/SkeletonTable.vue";
</script>

<template>
  <div class="auto-change-skeleton">
    <div class="auto-change-skeleton__select" />
    <SkeletonTable v-bind="$attrs" class="auto-change-skeleton__table" />
  </div>
</template>

<style lang="scss">
.auto-change-skeleton {
  &__select {
    width: 100px;
    height: 21px;
    border-radius: 100px;
    @include skeleton;
  }

  &__table {
    margin-top: 32px;
    margin-bottom: 28px;
    max-height: calc(100vh - 332px);
    overflow: scroll;
  }
}
</style>
