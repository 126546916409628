var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "fade-250", appear: "" } }, [
    _vm.value
      ? _c(
          "div",
          {
            class: ["app-survey-typeform", `app-survey-typeform--${_vm.type}`],
            style: {
              width: `${_vm.width}px`,
              height: `${_vm.height}px`,
            },
          },
          [
            _c(
              "button",
              {
                staticClass: "app-survey-typeform__close",
                on: {
                  click: function ($event) {
                    _vm.$emit("input", false)
                    _vm.$emit("close")
                  },
                },
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "app-survey-typeform__close-icon",
                    attrs: {
                      viewBox: "0 0 16 16",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M0.992188 13.5391C0.710938 13.8203 0.710938 14.2773 0.992188 14.5586C1.27344 14.8281 1.73047 14.8281 2.01172 14.5586L7.98828 8.57031L13.9766 14.5586C14.2578 14.8281 14.7148 14.8398 14.9961 14.5586C15.2656 14.2773 15.2656 13.8203 14.9961 13.5391L9.00781 7.55078L14.9961 1.57422C15.2656 1.29297 15.2773 0.824219 14.9961 0.554688C14.7148 0.273438 14.2578 0.273438 13.9766 0.554688L7.98828 6.53125L2.01172 0.554688C1.73047 0.273438 1.26172 0.273438 0.992188 0.554688C0.710938 0.835938 0.710938 1.29297 0.992188 1.57422L6.98047 7.55078L0.992188 13.5391Z",
                        fill: "currentColor",
                      },
                    }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "app-survey-typeform__container" },
              [
                !_setup.isSubmitted
                  ? _c("div", { ref: "container" })
                  : _vm._e(),
                _c("transition", { attrs: { name: "fade-500", appear: "" } }, [
                  _setup.isSubmitted
                    ? _c(
                        "h1",
                        { staticClass: "app-survey-typeform__thank-you" },
                        [_vm._v(" Thank you for your feedback! ")]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }