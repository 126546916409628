var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tip", class: `tip--${_vm.type}` },
    [
      _c(`tip-${_vm.type}`, { tag: "Component", staticClass: "tip__icon" }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }