<template>
  <div class="global-modal">
    <template v-for="modal in modals">
      <component
        v-if="modal.customTemplate"
        :key="modal.id"
        :is="modal.customTemplate.template"
        :params="modal.customTemplate.params"
        v-bind="modal.customTemplate.props"
        v-on="modal.customTemplate.events"
      />

      <ModalTemplate
        v-else
        :show-close-in-header="modal.showCloseInHeader"
        :show="visibleModals[modal.id]"
        :key="modal?.id"
        :width="modal.width"
        @close="() => handleCloseModal(modal)"
        :preventClose="modal.preventClose"
      >
        <template #header>
          <h1 v-if="modal.header">{{ modal.header }}</h1>
        </template>

        <template #body>
          <div v-if="modal.paragraphs || modal.subheader">
            <p v-if="modal.subheader">{{ modal.subheader }}</p>
            <p v-for="paragraph in modal.paragraphs" v-bind:key="paragraph">
              {{ paragraph }}
            </p>
          </div>
        </template>

        <template #input>
          <PreferencesInput
            v-if="!!modal.input"
            v-model="inputValue"
            :label="modal?.input?.label || undefined"
            :type="modal?.input?.type || 'text'"
            :placeholder="modal?.input?.placeholder || ''"
            :disabled="modal?.input?.disabled || false"
            :error="modal?.input?.error"
            @input.native="modal?.input?.handleInput(inputValue)"
            @focus.native="modal?.input?.handleFocus(inputValue) || undefined"
            @blur.native="modal?.input?.handleBlur(inputValue) || undefined"
          />
        </template>

        <template v-if="!modal.hideFooter" #footer>
          <Button
            aria-id="CancelButton"
            v-if="modal.showCancel"
            type="secondary"
            :disabled="loadingOnClick"
            @click="() => handleCloseModal(modal)"
          >
            {{ modal.cancelText || "Cancel" }}
          </Button>

          <Button
            aria-id="ConfirmButton"
            v-if="modal.button && modal.button.text"
            :type="modal.button.danger ? 'danger' : 'primary'"
            :disabled="isPrimaryBtnDisabled(modal)"
            :loading="loadingOnClick"
            @click="handleClickAction($event, modal)"
          >
            {{ modal.button.text }}
          </Button>
        </template>
      </ModalTemplate>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Button, ModalTemplate } from "@/components";
import PreferencesInput from "@/routes/modals/preferences/PreferencesInput.vue";

export default {
  name: "Modal",
  components: {
    Button,
    ModalTemplate,
    PreferencesInput,
  },

  data() {
    return {
      loadingOnClick: false,
      inputValue: "",
    };
  },
  methods: {
    ...mapActions(["closeModal"]),

    handleClickAction($event, modal) {
      if (typeof modal.button.onClick === "function") {
        const maybePromise = modal.button.onClick($event);

        if (modal.closeAfterOnClick && maybePromise instanceof Promise) {
          this.loadingOnClick = true;

          return maybePromise
            .then(() => {
              this.loadingOnClick = false;
              this.handleCloseModal(modal);
            })
            .finally(() => {
              this.loadingOnClick = false;
            });
        }
      }

      this.handleCloseModal(modal);
    },

    handleKeyPress($event) {
      if ($event?.key?.toLowerCase() === "escape") {
        this.handleCloseModal();
      }
      if ($event?.key?.toLowerCase() === "enter") {
        $event.stopPropagation();
        $event.preventDefault();
        const topModal = this.modals[this.modals.length - 1];
        if (topModal && topModal.button && topModal.button.onClick) {
          topModal.button.onClick($event);
          this.handleCloseModal();
        }
      }
    },

    handleCloseModal(modal) {
      if (this.loadingOnClick) {
        return;
      }

      if (typeof modal?.cancelAction === "function") {
        modal.cancelAction();
      }

      this.inputValue = "";
      if (modal?.preventClose) {
        return;
      }
      this.closeModal(modal);
    },

    isPrimaryBtnDisabled(modal) {
      /* We need this because our modal props
      don't change dynamically, ie if button changes from
      disabled to enabled, without this, the button
      stays disabled */
      if (this.loadingOnClick) {
        return true;
      } else if (this.inputValue) {
        /* For example, buttons that need to change from disabled
        to enabled based on the existance of a password */
        return false;
      } else if (modal.button?.disabled) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapState({
      modals: (state) => state.modal.modals,
      visibleModals: (state) => state.modal.visibleModals,
    }),
  },

  mounted() {
    window.document.addEventListener("keydown", this.handleKeyPress);
  },

  beforeDestroy() {
    window.document.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>
