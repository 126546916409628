<template>
  <div class="message-header" @click="open = index">
    <!-- inbound = if it's coming to the user -->
    <div class="message-contact" v-if="message.email.inbound">
      <div
        class="message-icon click-pointer"
        @click="goToCloak(thread.identity)"
      >
        <ContactIcon
          :initials="getExternalContactInitialsDisplay(message.email)"
        />
      </div>
      <div class="message-info">
        <div class="message-from">
          <div v-if="message.email.sender_name">
            <strong>{{ message.email.sender_name }}</strong>
            <span>{{ message.email.sender_email }}</span>
          </div>
          <div v-else>
            <strong>{{ getAliasFromEmail(message.email.sender_email) }}</strong>
            <span>{{ message.email.sender_email }}</span>
          </div>
        </div>
        <div class="message-to">
          <div>
            <p>
              to
              <strong
                class="click-pointer"
                @click="goToCloak(thread.identity)"
                >{{ recipients(message) }}</strong
              >
            </p>
          </div>
          <div>
            <ArrowButton
              v-if="multiRecipientEnabled"
              :onClick="toggleRecipientDetailsModal"
              :isOpen="isVisible"
            />
          </div>
        </div>
        <ActivityRecipientDetails
          v-if="isVisible"
          :message="message"
          :nickname="thread.identity.nickname"
          v-click-outside="hide"
          @close="hide"
        />
      </div>
    </div>
    <div class="message-contact" v-else>
      <div class="message-icon">
        <div
          class="user-icon click-pointer"
          @click="goToCloak(thread.identity)"
        >
          <UserIcon />
        </div>
      </div>
      <div class="message-info">
        <div class="message-from">
          <strong class="click-pointer" @click="goToCloak(thread.identity)"
            >me</strong
          >&nbsp;
          <span class="click-pointer" @click="goToCloak(thread.identity)">{{
            message.email.sender_email
          }}</span>
        </div>
        <div class="message-to">
          <div>
            <p>
              to
              <strong>{{ recipients(message) }}</strong>
            </p>
          </div>
          <div>
            <ArrowButton
              v-if="multiRecipientEnabled"
              :onClick="toggleRecipientDetailsModal"
              :isOpen="isVisible"
            />
          </div>
        </div>
        <ActivityRecipientDetails
          v-if="isVisible"
          :message="message"
          :nickname="thread.identity.nickname"
          v-click-outside="hide"
          @close="hide"
        />
      </div>
    </div>
    <div class="message-actions">
      <UiMenu
        width="247px"
        max-height="300px"
        placement="bottom-end"
        :z-index="9"
      >
        <UiTooltip title="Options" align-x="center">
          <span class="kabab"> <Kabob /></span>
        </UiTooltip>
        <template #content>
          <UiMenuButton
            :title="message.email.read ? 'Mark as unread' : 'Mark as read'"
            @click="
              mark_read(message);
              toggleShowForwardingEmail(false);
            "
            @mouseover="toggleShowForwardingEmail(false)"
          >
            <template v-slot:icon>
              <Read />
            </template>
          </UiMenuButton>
          <UiMenuSeparator />
          <UiMenuButton
            title="Reply"
            @click="
              replyTo(message, index);
              toggleShowForwardingEmail(false);
            "
            @mouseover="toggleShowForwardingEmail(false)"
          >
            <template v-slot:icon>
              <Reply />
            </template>
          </UiMenuButton>
          <UiMenuSeparator />
          <UiMenuButton
            :title="forwardingOptionTitle"
            @mouseover="
              verifiedEmails.length && toggleShowForwardingEmail(true)
            "
            @mouseout="
              verifiedEmails.length && toggleShowForwardingEmail(false)
            "
            @click="
              !verifiedEmails.length && handleAddNewEmail(message);
              toggleShowForwardingEmail(false);
            "
          >
            <template v-slot:icon>
              <Forward />
            </template>
          </UiMenuButton>

          <UiMenuSeparator />
          <div class="danger">
            <UiMenuButton
              title="Delete"
              danger
              @click="
                trip_delete(message);
                toggleShowForwardingEmail(false);
              "
              @mouseover="toggleShowForwardingEmail(false)"
            >
              <template v-slot:icon>
                <Delete />
              </template>
            </UiMenuButton>
          </div>
        </template>
      </UiMenu>
      <div
        class="fwd"
        v-if="showForwardingEmails"
        @mouseover="verifiedEmails.length && toggleShowForwardingEmail(true)"
        @mouseleave="verifiedEmails.length && toggleShowForwardingEmail(false)"
      >
        <ul>
          <li v-for="email in verifiedEmails" :key="email.email">
            <button
              @click="
                forward_email(message, email);
                toggleShowForwardingEmail(false);
              "
            >
              {{ email.email }}
            </button>
          </li>
          <UiMenuSeparator />
          <li>
            <button
              @click="
                handleAddNewEmail(message);
                toggleShowForwardingEmail(false);
              "
            >
              <PlusIcon />
              <span>Add a new email address</span>
            </button>
          </li>
        </ul>
      </div>
      <div class="time">{{ getTime(message.created_at) }}</div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import { humanize } from "@/scripts/timestamp_format";
import {
  getAliasFromEmail,
  getContactName,
  getAccountInitials,
} from "@/scripts/format";
import { analyticsExport } from "@/mixins/Analytics";
import ContactIcon from "@/components/ui/ContactIcon.vue";
import { UserIcon } from "@/assets/icons";
import { MULTI_RECIPIENT_ENABLED } from "@/scripts/featureFlags";
import Kabob from "@/assets/icons/kabob-actionbar.svg";
import Read from "@/assets/icons/read.svg";
import Reply from "@/assets/icons/reply.svg";
import Forward from "@/assets/icons/forward.svg";
import Delete from "@/assets/icons/delete-trash.svg";
import {
  ActivityRecipientDetails,
  UiMenu,
  UiMenuButton,
  UiMenuSeparator,
  UiTooltip,
  ArrowButton,
} from "@/components";
import { mapActions } from "vuex";
import PersonalInfoServices from "@/api/settings/personal-services";
import { AddVerifyEmail } from "@/components/modals";
import PlusIcon from "@/assets/icons/plus.svg";
import { safe_html } from "@/scripts/format";
import ClickOutside from "v-click-outside";
import ActivityService from "@/api/actions/activity-service";

export default {
  name: "Email",
  props: ["thread", "message", "index", "confirmDelete", "replyTo"],
  mixins: [analyticsExport],
  data() {
    return {
      next: `/api/v1/cloaked/activity/?populated=true&has_email=true&thread_id=${this.thread.thread_id}&ordering=created_at`,
      loaded: false,
      locked: false,
      email: null,
      sent: false,
      title_menu: false,
      item_menu: false,
      sending: false,
      subject: null,
      errors: [],
      html_body: null,
      disabled: false,
      open: null,
      hasReply: {},
      messages: [],
      showForwardingEmails: false,
      isVisible: false,
    };
  },
  components: {
    UiMenu,
    UiMenuButton,
    UiTooltip,
    UiMenuSeparator,
    Read,
    Kabob,
    Delete,
    Forward,
    Reply,
    UserIcon,
    ArrowButton,
    ContactIcon,
    ActivityRecipientDetails,
    PlusIcon,
  },
  mounted() {
    if (!this.thread.read) {
      this.mark_read_thread();
    }
    if (!this.$store.getters["settings/getVerifiedEmails"].length) {
      PersonalInfoServices.getPersonalEmails();
    }

    // Ensure only one recipient modal is open at a time
    setTimeout(() => {
      window.addEventListener("closerecipientmodal", this.hide);
    }, 100);
  },
  computed: {
    verifiedEmails() {
      return this.$store.getters["settings/getVerifiedEmails"];
    },
    forwardingOptionTitle() {
      if (this.verifiedEmails.length) {
        return "Forward to...";
      }
      return "Forward to my personal email";
    },
    multiRecipientEnabled() {
      return MULTI_RECIPIENT_ENABLED;
    },
  },
  watch: {},
  methods: {
    ...mapActions(["openModal"]),
    toggleShowForwardingEmail(value) {
      this.showForwardingEmails = value;
    },
    safe(html) {
      return safe_html(html);
    },
    handleAddNewEmail(message) {
      this.openModal({
        customTemplate: {
          template: AddVerifyEmail,
          props: {
            setPrimary: true,
          },
          events: {
            "email-verified": (email) => this.forward_email(message, email),
          },
        },
      });
    },
    goToCloak(identity) {
      if (
        !this.$store.state.rightPanel.cloak ||
        this.$store.state.rightPanel.cloak.id !== identity.id
      ) {
        this.$store.dispatch("fetchPopulatedData", identity).then((data) => {
          this.$store.commit("compose", null);
          this.$store.dispatch("openCloakDetails", {
            cloak: data,
            skipNav: true,
          });
        });
      }
    },
    forward_email(message, forwardingEmail) {
      this.$nextTick(() => {
        const paylaod = {
          to: forwardingEmail.email,
          subject: message.email.subject,
          original_html: message.email.html.trim() || message.email.text.trim(),
        };

        return ActivityService.postActivityEmailForward(message.id, paylaod)
          .then(() => {
            this.$toast.success("Email forwarded");
          })
          .catch(() => {
            this.$toast.error(
              "Something went wrong trying to send your email. Please try again"
            );
          });
      });
    },
    mark_read(message) {
      const messages = [...this.messages];
      const findIndex = messages.findIndex((m) => m.id === message.id);
      messages[findIndex].email.read = !messages[findIndex].email.read;
      this.messages = [...messages];
      api()
        .patch(message.email.url, {
          read: !messages[findIndex].email.read,
        })
        .then(() => {
          if (messages[findIndex].email.read) {
            this.$toast.success("Message marked as read");
          } else {
            this.$toast.success("Message marked as unread");
          }
        });
    },
    mark_read_thread() {
      let url = "/api/v1/cloaked/activity/read-thread/";
      if (this.thread.read) {
        url = "/api/v1/cloaked/activity/unread-thread/";
      }
      api()
        .post(url, {
          thread_id: this.thread.thread_id,
        })
        .then(() => {
          this.$emit("updated", {
            ...this.thread,
            read: !this.thread.read,
          });
          window.dispatchEvent(new CustomEvent("activity_read"));
        });
    },
    recipients(message) {
      let { to_recipients } = message.email;
      // If the email is inbound, user is the recipient
      if (message.inbound && !to_recipients[0].name) {
        // to_recipients[0].name = this.thread.identity.nickname;
        to_recipients[0].name = "me";
      }
      const contacts = [...to_recipients, ...message.email.cc_recipients].map(
        (m) => m.name || this.getAliasFromEmail(m.email)
      );

      // Show a max of 4 recipient emails
      const totalMaxDisplayRecipients = 4;
      const totalRecipients = contacts.length;
      let displayEmails = `${contacts.join(", ")}`;
      if (totalRecipients > 4) {
        const others = totalRecipients - totalMaxDisplayRecipients;
        contacts.splice(totalMaxDisplayRecipients - 1, others);
        displayEmails = `${contacts.join(", ")}, and ${others} other${
          others > 1 ? "s" : ""
        }`;
      }
      return displayEmails;
    },
    format(text) {
      if (text) {
        return text.replaceAll(/[\n]/g, "<br />");
      }
      return text;
    },
    trip_delete(email) {
      this.openModal({
        header: `Delete message?`,
        subheader:
          "Any copies of this message that have been forwarded to your personal email will be unaffected. This cannot be undone.",
        button: {
          text: "Yes, Delete",
          onClick: () => this.confirmDelete(email),
          danger: true,
        },
      });
    },
    getTime(value) {
      return humanize(value);
    },
    async get_messages($state, reset) {
      if (this.next && !this.locked) {
        this.locked = true;
        return api()
          .get(this.next)
          .then((response) => {
            this.next = response.data.next;
            if (reset) {
              this.messages = response.data.results;
            } else {
              this.messages = [...this.messages, ...response.data.results];
            }
            this.loaded = true;
            this.locked = false;
            if ($state) {
              $state.loaded();
            }
          });
      } else {
        if ($state) {
          $state.complete();
        }
      }
    },
    getAliasFromEmail(value) {
      return getAliasFromEmail(value);
    },
    toggleRecipientDetailsModal(e) {
      if (!this.isVisible) {
        const recipientModalEvent = new Event("closerecipientmodal");
        window.dispatchEvent(recipientModalEvent);
      }
      e.stopPropagation();
      this.isVisible = !this.isVisible;
    },
    hide() {
      this.isVisible = false;
    },
    getExternalContactInitialsDisplay(emailData) {
      const { firstName, lastName } = getContactName(emailData.sender_contact);
      if (firstName || lastName) {
        return getAccountInitials(firstName, lastName);
      } else if (emailData.sender_name) {
        const nameArray = emailData.sender_name.split(" ");
        return nameArray[1]
          ? getAccountInitials(nameArray[0], nameArray[1])
          : getAccountInitials(nameArray[0]);
      }
      return "";
    },
  },
  directives: {
    ClickOutside: ClickOutside.directive,
  },
};
</script>
<style scoped lang="scss">
.message-header {
  display: flex;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  word-break: break-all;

  .kabab {
    svg {
      height: 20px;
      color: $color-primary-100;
    }
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  .message-contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    .message-icon {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .user-icon {
        border-radius: 100px;
        height: 40px;
        width: 40px;
        background-color: $color-primary-100;
        color: $color-primary-0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .message-info {
      font-family: $poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
      color: $color-primary-100;
      strong {
        font-weight: 500;
        display: inline-block;
        margin-right: 5px;
      }
      .message-from {
        span {
          color: $color-primary-70;
        }
        strong {
          padding-right: 4px;
          color: $color-primary-100;
        }
      }
      .message-to {
        display: flex;
        color: $color-primary-70;
        font-weight: 500;
        font-size: 12px;
        &.link {
          button {
            background-color: transparent;
            border: none;
          }
          strong {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .message-actions {
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    .time {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: right;
      color: $color-primary-70;
      word-break: normal;
    }
  }
}

.kabab {
  background-color: transparent;
  border: none;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
header {
  top: 65px;
  width: 100%;
  display: flex;
  padding: 10px 20px;
  position: sticky;
  flex-direction: row;
  background-color: $color-primary-0;
  justify-content: space-between;
  border-bottom: 1px solid rgba(217, 217, 217, 0.5);
  z-index: 10;
  margin-top: 0;

  .header-wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
  }
  .header-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: $color-primary-100;
    padding: 0 15px;
    flex-grow: 1;
  }
  .header-actions {
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    flex-grow: 0;
    .time {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: right;
      color: $color-primary-70;
    }
  }
}
.danger * {
  color: red;
}

.click-pointer {
  cursor: pointer;
}

.fwd {
  position: absolute;
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  color: $color-primary-100;
  padding: 4px;
  right: 270px;
  top: 195px;
  white-space: nowrap;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  background-color: $color-primary-0;
  z-index: 2000;
  font-family: $poppins;
  font-style: normal !important;
  font-weight: 400;
  font-size: 12px !important;
  display: flex;
  flex-direction: row;
  &::-webkit-scrollbar {
    background-color: $color-primary-0;
  }

  div {
    margin: 4px 0;
  }
  button {
    width: 247px;
    height: 40px;
    border-radius: 8px;
    text-align: left !important;
    background-color: transparent;
    color: $color-primary-100;
    display: block;
    padding: 12px 6px;
    height: auto;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $poppins;
    font-style: normal !important;
    font-weight: 400;
    font-size: 12px !important;

    svg {
      position: absolute;
      left: 15px;
      margin-top: 3px;
      margin-right: 8px;
      margin-left: -6px;
    }
    span {
      margin-left: 18px;
    }
    &:hover {
      background-color: $color-primary-5;
    }
  }
}
</style>
