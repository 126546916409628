var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "preferences-header" }, [
    _c(
      "div",
      { staticClass: "preferences-header__left" },
      [
        _c(
          "button",
          {
            staticClass: "preferences-header__back-button",
            on: { click: _vm.handleGoBack },
          },
          [_c("ChevronLeft"), _c("span", [_vm._v("Back")])],
          1
        ),
        _vm._t("post-left"),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "preferences-header__right" },
      [_vm._t("right")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }