<template>
  <span
    class="onboarding-input-code"
    :class="{ 'onboarding-input-code--focused': isFocused }"
  >
    <input
      type="number"
      class="onboarding-input-code__input"
      v-bind="$attrs"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @input="onInput"
      @keydown="$emit('keydown', $event)"
      ref="input"
    />
    <span class="onboarding-input-code__background">
      <span class="onboarding-input-code__focus" :style="focusStyle"></span>
      <span
        class="onboarding-input-code__placeholder"
        v-for="i in 6"
        :key="i"
        :class="{
          'onboarding-input-code__placeholder--focused':
            i - 1 === Math.min($attrs.value.length, 5) && isFocused,
          'onboarding-input-code__placeholder--large-font': largeFont,
        }"
      >
        {{ $attrs.value[i - 1] }}
      </span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    largeFont: Boolean,
  },
  data() {
    return {
      isFocused: false,
      focusStyle: {},
    };
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    onInput(event) {
      event.target.value = event.target.value.slice(0, 6);
      this.$emit("input", event.target.value);
    },
  },
  watch: {
    "$attrs.value": {
      immediate: true,
      handler(newValue, oldValue) {
        const lengthChange = Math.abs(newValue.length - oldValue?.length || 0);

        this.focusStyle = {
          transition: `all ${0.1 + (lengthChange - 1) * 0.025}s ease-out`,
          transform: `translateX(${Math.min(newValue.length, 5) * 76}px)`,
        };
      },
    },
  },
};
</script>

<style lang="scss">
.onboarding-input-code {
  display: block;
  margin: 32px auto 0;
  position: relative;
  height: 60px;
  width: calc(64px * 6 + 12px * 5);

  &__input {
    position: absolute;
    opacity: 0;
    z-index: 1;
    inset: 0;

    &:focus {
      outline: none;
    }
  }

  &__background {
    display: flex;
    justify-content: space-between;
    position: absolute;
    inset: 0;
  }

  &__focus {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 62px;
    height: calc(100% - 2px);
    border: 1px solid $color-primary-100;
    border-radius: 10px;
    opacity: 0;
    display: block;

    @at-root .onboarding-input-code--focused & {
      opacity: 1;
    }
  }

  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 64px;
    background: $color-primary-5;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    color: $color-primary-100;

    &--large-font {
      font-size: 24px;
      font-weight: 500;
    }
    &--focused {
      background: $color-primary-10;
      caret-color: $color-primary-100;
    }
  }
}
</style>
