<template>
  <PreferencesPanel>
    <template v-slot:header>
      <PreferencesHeader @go-back="toggle_back" />
    </template>

    <PreferencesInput
      label="First name"
      placeholder="Enter Your First Name"
      tabindex="1"
      v-model="autofill_first_name"
      @save="handleSave"
    />

    <PreferencesInput
      label="Last name"
      placeholder="Enter Your Last Name"
      tabindex="2"
      v-model="autofill_last_name"
      @save="handleSave"
    />

    <PreferencesFooter>
      <Button :disabled="loading" :loading="loading" @click="handleSave"
        >Save changes</Button
      >
    </PreferencesFooter>
  </PreferencesPanel>
</template>

<script>
import {
  PreferencesHeader,
  PreferencesFooter,
  PreferencesPanel,
  PreferencesInput,
} from "@/routes/modals/preferences";
import PersonalInfoService from "@/api/settings/personal-services";
import { Button } from "@/components";

export default {
  name: "Name",
  components: {
    PreferencesHeader,
    PreferencesFooter,
    PreferencesPanel,
    PreferencesInput,
    Button,
  },
  props: ["id", "first_name", "last_name"],
  mounted() {
    window.document.title = "Name Preference • Cloaked";
    this.autofill_first_name = this.first_name;
    this.autofill_last_name = this.last_name;
  },

  data() {
    return {
      autofill_first_name: "",
      autofill_last_name: "",
      loading: false,
    };
  },

  methods: {
    // on_update_value: lodash.debounce(
    //   function debouncedSave({ name, value }) {
    //     let payload = {};

    //     if (name === "first_name") {
    //       payload["autofill_first_name"] = value;
    //       this.$emit("update", { name: "info_name", value });
    //     }
    //     if (name === "last_name") {
    //       payload["autofill_last_name"] = value;
    //       this.$emit("update", { name: "info_last_name", value });
    //     }
    //     this.save_name(payload);
    //   },
    //   300,
    //   { leading: false, trailing: true }
    // ),

    toggle_back() {
      this.$emit("toggleBack");
    },

    async save_name() {
      const payload = {
        autofill_first_name: this.autofill_first_name || "",
        autofill_last_name: this.autofill_last_name || "",
      };

      this.loading = true;

      if (this.id) {
        PersonalInfoService.updateInfo(this.id, payload)
          .then(() => {
            this.$toast.success("Name saved.");
            this.toggleBack();
            this.loading = false;
          })
          .catch(() => {
            this.$toast.error("Error saving your name.");
            this.loading = false;
          });
      } else {
        PersonalInfoService.createInfo(payload)
          .then(() => {
            this.$toast.success("Name saved.");
            this.toggleBack();
            this.loading = false;
          })
          .catch(() => {
            this.$toast.error("Error saving your name.");
            this.loading = false;
          });
      }
    },

    toggleBack() {
      this.$emit("toggleBack", {
        info_name: this.autofill_first_name,
        info_last_name: this.autofill_last_name,
      });
    },

    handleSave() {
      this.save_name();
    },
  },
};
</script>
