var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "global-modal" },
    [
      _vm._l(_vm.modals, function (modal) {
        return [
          modal.customTemplate
            ? _c(
                modal.customTemplate.template,
                _vm._g(
                  _vm._b(
                    {
                      key: modal.id,
                      tag: "component",
                      attrs: { params: modal.customTemplate.params },
                    },
                    "component",
                    modal.customTemplate.props,
                    false
                  ),
                  modal.customTemplate.events
                )
              )
            : _c("ModalTemplate", {
                key: modal?.id,
                attrs: {
                  "show-close-in-header": modal.showCloseInHeader,
                  show: _vm.visibleModals[modal.id],
                  width: modal.width,
                  preventClose: modal.preventClose,
                },
                on: { close: () => _vm.handleCloseModal(modal) },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          modal.header
                            ? _c("h1", [_vm._v(_vm._s(modal.header))])
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "body",
                      fn: function () {
                        return [
                          modal.paragraphs || modal.subheader
                            ? _c(
                                "div",
                                [
                                  modal.subheader
                                    ? _c("p", [_vm._v(_vm._s(modal.subheader))])
                                    : _vm._e(),
                                  _vm._l(
                                    modal.paragraphs,
                                    function (paragraph) {
                                      return _c("p", { key: paragraph }, [
                                        _vm._v(" " + _vm._s(paragraph) + " "),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "input",
                      fn: function () {
                        return [
                          !!modal.input
                            ? _c("PreferencesInput", {
                                attrs: {
                                  label: modal?.input?.label || undefined,
                                  type: modal?.input?.type || "text",
                                  placeholder: modal?.input?.placeholder || "",
                                  disabled: modal?.input?.disabled || false,
                                  error: modal?.input?.error,
                                },
                                nativeOn: {
                                  input: function ($event) {
                                    modal?.input?.handleInput(_vm.inputValue)
                                  },
                                  focus: function ($event) {
                                    modal?.input?.handleFocus(_vm.inputValue) ||
                                      undefined
                                  },
                                  blur: function ($event) {
                                    modal?.input?.handleBlur(_vm.inputValue) ||
                                      undefined
                                  },
                                },
                                model: {
                                  value: _vm.inputValue,
                                  callback: function ($$v) {
                                    _vm.inputValue = $$v
                                  },
                                  expression: "inputValue",
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                    !modal.hideFooter
                      ? {
                          key: "footer",
                          fn: function () {
                            return [
                              modal.showCancel
                                ? _c(
                                    "Button",
                                    {
                                      attrs: {
                                        "aria-id": "CancelButton",
                                        type: "secondary",
                                        disabled: _vm.loadingOnClick,
                                      },
                                      on: {
                                        click: () =>
                                          _vm.handleCloseModal(modal),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(modal.cancelText || "Cancel") +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              modal.button && modal.button.text
                                ? _c(
                                    "Button",
                                    {
                                      attrs: {
                                        "aria-id": "ConfirmButton",
                                        type: modal.button.danger
                                          ? "danger"
                                          : "primary",
                                        disabled:
                                          _vm.isPrimaryBtnDisabled(modal),
                                        loading: _vm.loadingOnClick,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickAction(
                                            $event,
                                            modal
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(modal.button.text) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }