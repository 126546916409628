<template>
  <PreferencesPanel class="manage-account">
    <template v-slot:header>
      <PreferencesHeader @go-back="handleGoBack" />
    </template>

    <template v-if="currentStep === 'manage'" class="manage-account__body">
      <PreferencesTitle>Manage account</PreferencesTitle>
      <PreferencesParagraph>
        Your account is scheduled to be deleted on
        <strong>{{ deleteDateLabel }}</strong
        >. You can restore your account at any time before this date to continue
        using Cloaked.
      </PreferencesParagraph>

      <PreferencesParagraph>
        You can also delete your account now instead of waiting until
        <strong>{{ deleteDateLabel }}</strong
        >.
      </PreferencesParagraph>

      <div class="manage-account__options">
        <ValueDisplay
          label="Restore my account"
          dark-label
          @click="setOption(options.RESTORE)"
        />

        <ValueDisplay
          label="Permanently delete my account now"
          danger
          @click="setOption(options.DELETE)"
        />
      </div>
    </template>

    <template v-if="currentStep === 'restore'" class="manage-account__body">
      <PreferencesTitle>Restore account</PreferencesTitle>
      <PreferencesParagraph>
        This will cancel the account deletion process and restore your Cloaked
        account. You won’t lose any of your account data and will be able to
        continue using Cloaked.
      </PreferencesParagraph>
    </template>

    <template
      v-if="currentStep === 'delete-verification'"
      class="delete-account__body"
    >
      <PreferencesTitle>Delete account now</PreferencesTitle>
      <PreferencesParagraph>
        To continue, enter your account password.
      </PreferencesParagraph>

      <PreferencesInput
        v-model="password"
        label="Password"
        type="password"
        placeholder="Your Password"
        :error="invalidPassword"
        :disabled="loadingPassword"
        @save="validatePassword"
      />
    </template>

    <template
      v-if="currentStep === 'delete-confirmation'"
      class="delete-account__body"
    >
      <PreferencesTitle>Delete account now</PreferencesTitle>
      <PreferencesParagraph>
        This will permanently delete all data associated with your Cloaked
        identities including email addresses, phone numbers, passwords,
        usernames, and notes.
      </PreferencesParagraph>

      <PreferencesParagraph>
        You will no longer be able to send and receive emails, texts, and calls
        from any of your Cloaked identities. This may also affect your ability
        to log in to websites associated with your Cloaked identities.
      </PreferencesParagraph>

      <PreferencesParagraph>
        <strong>This action cannot be undone.</strong> You will automatically
        lose access to your Cloaked account.
      </PreferencesParagraph>

      <PreferencesCheckParagraph
        class="disclaimer-row"
        v-model="userUnderstands"
      >
        I understand my account, my identities, and all of my data will be
        permanently deleted and cannot be retrieved.
      </PreferencesCheckParagraph>
    </template>

    <template v-slot:footer>
      <PreferencesFooter v-if="currentStep === 'restore'">
        <Button
          @click="handleRestore"
          :loading="loadingRestore"
          :disabled="loadingRestore"
        >
          Restore my account
        </Button>
      </PreferencesFooter>

      <PreferencesFooter v-if="currentStep === 'delete-verification'">
        <Button
          @click="validatePassword"
          :disabled="!password || loadingPassword"
          >Continue</Button
        >
      </PreferencesFooter>

      <PreferencesFooter v-if="currentStep === 'delete-confirmation'">
        <Button
          type="danger"
          :disabled="!userUnderstands || loadingDelete"
          @click="handleDelete"
        >
          Delete account now
        </Button>
      </PreferencesFooter>
    </template>
  </PreferencesPanel>
</template>

<script>
import moment from "moment";
import { get } from "lodash-es";
import { mapState, mapMutations } from "vuex";
import {
  PreferencesHeader,
  PreferencesPanel,
  PreferencesFooter,
  PreferencesParagraph,
  PreferencesCheckParagraph,
  PreferencesTitle,
  PreferencesInput,
} from "@/routes/modals/preferences";
import ValueDisplay from "@/components/ui/value-display";
import { Button } from "@/components";
import api from "@/api/api";
import { logout } from "@/scripts/actions/auth";
import { password_confirm } from "@/scripts/actions/encryption";
import AuthService from "@/api/actions/auth-service";

export default {
  name: "ManageAccount",
  components: {
    PreferencesPanel,
    PreferencesHeader,
    PreferencesFooter,
    PreferencesInput,
    PreferencesParagraph,
    PreferencesCheckParagraph,
    PreferencesTitle,
    Button,
    ValueDisplay,
  },
  data() {
    return {
      step: 0,
      options: {
        RESTORE: "RESTORE",
        DELETE: "DELETE",
      },
      loadingRestore: false,
      loadingDelete: false,
      selectedOption: null,
      userUnderstands: false,
      password: "",
      invalidPassword: false,
      loadingPassword: false,
    };
  },

  watch: {
    preferencesStep: {
      immediate: true,
      handler() {
        if (this.preferencesStep === "restore") {
          this.selectedOption = this.options.RESTORE;
          this.step = this.steps.findIndex((item) => item === "restore");
        }
        if (this.preferencesStep === "manage") {
          this.selectedOption = null;
          this.step = 0;
        }
      },
    },
  },

  computed: {
    ...mapState(["user"]),
    ...mapState({
      preferencesStep: (state) => state.ui.preference.step,
    }),

    deleteDateLabel() {
      const deleteDate = get(this.user, "deletion_date");
      let then = moment().add(30, "days");

      if (deleteDate) {
        then = moment(deleteDate);
      }

      return then.format("LL");
    },

    steps() {
      const steps = {
        [this.options.RESTORE]: ["manage", "restore"],
        [this.options.DELETE]: [
          "manage",
          "delete-verification",
          "delete-confirmation",
        ],
      };

      if (!this.selectedOption || !steps[this.selectedOption]) {
        return ["manage"];
      }

      return steps[this.selectedOption];
    },

    currentStep() {
      return this.steps[this.step];
    },
  },

  methods: {
    ...mapMutations(["insertUser"]),

    toggle_back() {
      this.$emit("toggleBack", {});
    },

    nextStep() {
      let next = this.step + 1;
      const maxStep = this.steps.length - 1;

      if (next >= maxStep) {
        next = maxStep;
      }

      this.step = next;
    },

    handleDelete() {
      const userId = this.user.id;
      const url = `/api/v1/user/${userId}/state/`;

      this.loadingDelete = true;

      api()
        .post(url, {
          state: "pending_deletion",
          immediate_delete: true,
        })
        .then(() => {
          logout();
        })
        .catch(() => {
          this.$toast.error("Error deleting account");
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },

    handleRestore() {
      const userId = this.user.id;
      const url = `/api/v1/user/${userId}/state/`;

      this.loadingRestore = true;

      api()
        .post(url, {
          state: "active",
          immediate_delete: false,
        })
        .then(({ data }) => {
          this.insertUser(data);
          this.$toast.success("Account restored");
          this.toggle_back();
        })
        .catch(() => {
          this.$toast.error("Error restoring account");
        })
        .finally(() => {
          this.loadingRestore = false;
        });
    },

    setOption(option) {
      this.selectedOption = option;
      this.nextStep();
    },

    handleGoBack() {
      if (this.step > 0) {
        this.step = this.step - 1;
      } else {
        this.toggle_back();
      }
    },

    async validatePassword() {
      const userId = this.user.id;
      let password = this.password;
      if (this.$store.state.authentication.user.encryption_status === 3) {
        password = await password_confirm(password);
      }
      this.loadingPassword = true;
      AuthService.confirmPassword(userId, password)
        .then(() => {
          this.invalidPassword = false;
          this.nextStep();
        })
        .catch(() => {
          this.invalidPassword = true;
          this.$toast.error("Invalid password, please try again");
        })
        .finally(() => {
          this.loadingPassword = false;
        });
    },
  },
};
</script>

<style lang="scss">
.manage-account {
  display: flex;
  flex-direction: column;

  &__body {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  &__options {
    margin-top: 24px;
  }

  .preferences-input {
    margin-top: 37px;
  }
}
</style>
