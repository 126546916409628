var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.cardEnabled && _setup.kycValidated
    ? _c(_setup.WalletActivity)
    : _setup.cardEnabled && !_setup.kycValidated
    ? _c(_setup.KycFlow)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }