<template>
  <PreferencesPanel class="panel-offset">
    <template v-slot:header>
      <PreferencesHeader @go-back="toggle_back" />
    </template>

    <PreferencesTitle>Maintain your account access</PreferencesTitle>

    <PreferencesParagraph>
      These can be used to make sure it's really you signing in, reach you if
      there's suspicious activity in your account, or recover a lost password.
    </PreferencesParagraph>

    <div class="section forward-panel">
      <AccountRecoveryPhone
        @toggleBack="toggle_back"
        @refresh="refreshPhones"
        @delete="(phoneId) => deletePhone(phoneId)"
        :phones="phones"
      />
      <AccountRecoveryEmail
        @toggleBack="toggle_back"
        @refresh="refreshEmails"
        @delete="(emailId) => deleteEmail(emailId)"
        :emails="emails"
      />
      <ValueDisplay
        v-if="showRecoveryPassphraseSection"
        label="Download recovery passphrase"
        @click="goToRecoveryPassphrase"
      />
    </div>
  </PreferencesPanel>
</template>

<script>
import api from "@/api/api";
import { RECOVERY_KEY_ENABLE } from "@/scripts/featureFlags";
import { mapState } from "vuex";
import {
  AccountRecoveryPhone,
  AccountRecoveryEmail,
  PreferencesHeader,
  PreferencesTitle,
  PreferencesParagraph,
  PreferencesPanel,
} from "@/routes/modals/preferences";
import ValueDisplay from "@/components/ui/value-display";

export default {
  name: "AccountRecovery",
  components: {
    AccountRecoveryPhone,
    AccountRecoveryEmail,
    PreferencesHeader,
    PreferencesTitle,
    PreferencesParagraph,
    PreferencesPanel,
    ValueDisplay,
  },
  beforeMount() {
    this.refreshEmails();
    this.refreshPhones();
  },
  data() {
    return {
      emails: [],
      phones: [],
    };
  },
  computed: {
    ...mapState(["user"]),
    showRecoveryPassphraseSection() {
      return (
        (this.$store.state.authentication.user.encryption_status === 1 ||
          this.$store.state.authentication.user.encryption_status === 3) &&
        RECOVERY_KEY_ENABLE
      );
    },
  },
  methods: {
    refreshEmails() {
      return api()
        .get("/api/v1/email/")
        .then((response) => {
          this.emails = response.data.results;
        });
    },
    refreshPhones() {
      return api()
        .get("/api/v1/phone/")
        .then((response) => {
          this.phones = response.data.results;
        });
    },
    deleteEmail(emailId) {
      this.emails = this.emails.filter((item) => item.id !== emailId);
    },
    deletePhone(phoneId) {
      this.phones = this.phones.filter((item) => item.id !== phoneId);
    },
    goToRecoveryPassphrase() {
      this.$router.push({ name: "settings.recovery" });
    },
    toggle_back() {
      this.$emit("toggleBack", {});
    },
  },
};
</script>
<style lang="scss">
.forward-panel {
  margin-top: 24px;
}
</style>
