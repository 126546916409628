var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "custom-fields" },
    [
      _c("header", [
        _c(
          "h3",
          { staticClass: "custom-fields__header" },
          [
            _c("span", [_vm._v("Other information")]),
            _c(
              _setup.UiTooltip,
              {
                attrs: {
                  title:
                    "Store new custom information to identities, making them even more powerful.",
                  position: "top",
                  "max-width": "192",
                  "align-x": "center",
                },
              },
              [_c(_setup.MoreInfoQIcon)],
              1
            ),
          ],
          1
        ),
      ]),
      _setup.orderedItems && _setup.orderedItems.length
        ? _c(
            "ul",
            { staticClass: "custom-fields__list" },
            _vm._l(_setup.orderedItems, function (item) {
              return _c(
                "li",
                { key: item.id, staticClass: "custom-fields__list-item" },
                [
                  _vm.value && _vm.value.id === item.id && _vm.value.value
                    ? _c(_setup.CustomFieldForm, {
                        attrs: {
                          value: _vm.value,
                          "form-type": "edit",
                          "field-type": _vm.value.type,
                          isLoading: _vm.isLoading,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$emit("input", $event)
                          },
                          cancel: function ($event) {
                            return _vm.$emit(
                              "set-show-unsaved-changes-modal",
                              true
                            )
                          },
                          save: function ($event) {
                            return _vm.$emit("edit")
                          },
                        },
                      })
                    : _c(_setup.CustomField, {
                        attrs: {
                          type: item.type,
                          label: item.label,
                          value: item.value,
                          "is-secret": item.isSecret,
                        },
                        on: {
                          edit: function ($event) {
                            _vm.$emit("input", JSON.parse(JSON.stringify(item)))
                          },
                          delete: function ($event) {
                            _vm.$emit("input", { id: item.id })
                            _vm.$emit("set-show-confirm-delete-modal", true)
                          },
                        },
                      }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.readOnly && (_vm.value === null || _vm.value.id)
        ? _c(_setup.CustomFieldAdd, {
            staticClass: "custom-fields__add",
            on: { add: (type) => _vm.$emit("input", { type }) },
          })
        : _c(_setup.CustomFieldForm, {
            attrs: {
              value: _vm.value,
              "form-type": "new",
              "field-type": _vm.value.type,
              existingAddresses: _vm.existingAddresses,
              isLoading: _vm.isLoading,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
              cancel: function ($event) {
                return _vm.$emit("set-show-unsaved-changes-modal", true)
              },
              save: function ($event) {
                return _vm.$emit("add")
              },
            },
          }),
      _c(_setup.CustomFieldModalUnsavedChanges, {
        attrs: { value: _vm.isUnsavedChangesModalShown },
        on: {
          input: function ($event) {
            return _vm.$emit("set-show-unsaved-changes-modal", $event)
          },
          discard: function ($event) {
            _vm.$emit("input", null)
            _vm.$emit("set-show-unsaved-changes-modal", false)
          },
        },
      }),
      _c(_setup.CustomFieldModalConfirmDelete, {
        attrs: {
          value: _vm.isConfirmDeleteModalShown,
          isLoading: _vm.isLoading,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("set-show-confirm-delete-modal", $event)
          },
          delete: function ($event) {
            return _vm.$emit("delete")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }