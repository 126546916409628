var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    _vm._g(
      {
        on: {
          close: function ($event) {
            return _vm.$store.dispatch("onboarding/close")
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "AppModalContent",
        { staticClass: "onboarding-forwarding" },
        [
          _c(
            "div",
            { staticClass: "onboarding-forwarding__controls" },
            [
              _c("AppModalBack", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isBackVisible,
                    expression: "isBackVisible",
                  },
                ],
                on: { click: _vm.onBack },
              }),
              _c("AppModalClose", {
                staticClass: "onboarding-forwarding__controls-next",
                on: {
                  click: function ($event) {
                    return _vm.$store.dispatch("onboarding/close")
                  },
                },
              }),
            ],
            1
          ),
          _vm.step < 3
            ? _c("OnboardingForwardingEmail", {
                class: { "forwarding-email--verified": _vm.isEmailVerified },
                attrs: {
                  step: _vm.step,
                  email: _vm.email,
                  "email-code": _vm.emailCode,
                  emailForwarding: _vm.emailForwarding,
                },
                on: {
                  "input-email": _vm.setEmail,
                  "input-email-code": function ($event) {
                    _vm.emailCode = $event
                  },
                  "input-email-forwarding": function ($event) {
                    _vm.emailForwarding = $event
                  },
                  "input-email-forwarding-consent": function ($event) {
                    _vm.emailForwardingConsent = $event
                  },
                  resend: function ($event) {
                    return _vm.resend("email", _vm.emailData.id)
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.onContinue.apply(null, arguments)
                  },
                },
              })
            : _c("OnboardingForwardingPhone", {
                class: { "forwarding-phone--verified": _vm.isPhoneVerified },
                attrs: {
                  step: _vm.step,
                  phone: _vm.phone,
                  "phone-code": _vm.phoneCode,
                  "phone-forwarding": _vm.phoneForwarding,
                  "phone-forwarding-consent": _vm.phoneForwardingConsent,
                },
                on: {
                  "input-phone": _vm.setPhone,
                  "input-phone-code": function ($event) {
                    _vm.phoneCode = $event
                  },
                  "input-phone-forwarding": function ($event) {
                    _vm.phoneForwarding = $event
                  },
                  "input-phone-forwarding-consent": function ($event) {
                    _vm.phoneForwardingConsent = $event
                  },
                  resend: function ($event) {
                    return _vm.resend("phone", _vm.phoneData.id)
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.onContinue.apply(null, arguments)
                  },
                },
              }),
          _vm.error
            ? _c("div", { staticClass: "input_error" }, [
                _vm._v(_vm._s(_vm.error)),
              ])
            : _vm._e(),
          _c(
            "AppModalFooter",
            { attrs: { "with-border": "" } },
            [
              _c("Stepper", {
                staticClass: "onboarding-forwarding__stepper",
                attrs: {
                  value: _vm.step < 3 ? 0 : 1,
                  steps: ["Emails", "Calls & texts"],
                },
              }),
              _c(
                "Button",
                {
                  ref: "next",
                  attrs: { disabled: _vm.isNextDisabled },
                  on: { click: _vm.onContinue },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.onContinue.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.continueText) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }