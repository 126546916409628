var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ModalTemplate", {
    attrs: { show: true, "show-close-in-header": "", width: "512px" },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h1", [_vm._v("Verify your " + _vm._s(_vm.displayType))])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("p", { staticClass: "verification-cta" }, [
              _vm._v(" Enter the 6-digit code sent to "),
              _c("strong", [_vm._v(_vm._s(_vm.contact))]),
            ]),
            _c(
              "div",
              [
                _c(
                  "OnboardingInputCode",
                  _vm._g(
                    {
                      ref: "code_input",
                      attrs: { disabled: _vm.loading, value: _vm.code },
                      on: {
                        input: function ($event) {
                          _vm.code = $event
                        },
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.verify.apply(null, arguments)
                        },
                      },
                    },
                    _vm.$listeners
                  )
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "Button",
              {
                attrs: {
                  loading: _vm.loading,
                  disabled: _vm.loading,
                  type: "secondary",
                },
                on: { click: _vm.resend },
              },
              [_vm._v(" Resend code ")]
            ),
            _c(
              "Button",
              {
                attrs: { disabled: _vm.code && _vm.code.length !== 6 },
                on: { click: _vm.verify },
              },
              [_vm._v(" Verify ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }