var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(_setup.IdentitySharingButton, {
        attrs: {
          value: _vm.$attrs.isTooltipOpen,
          "is-active": _vm.$attrs.value,
          "is-shared": _vm.$attrs.isShared,
          hasAnnouncementTooltip: _vm.$attrs.hasAnnouncementTooltip,
        },
        on: {
          click: function ($event) {
            _vm.$attrs.hasAnnouncementTooltip || _vm.$emit("input", true)
          },
          input: function ($event) {
            return _vm.$emit("set-is-tooltip-open", $event)
          },
        },
      }),
      _vm.$attrs.value
        ? _c(
            "Transition",
            { attrs: { mode: "out-in", appear: "" } },
            [
              _c(
                _vm.activeModal,
                _vm._g(
                  _vm._b(
                    {
                      tag: "Component",
                      attrs: {
                        "is-shared": _vm.$attrs.isShared,
                        identity: _vm.identity,
                        "without-overlay": "",
                      },
                      on: {
                        "open-create": function ($event) {
                          return _vm.$emit(
                            "set-active-modal",
                            _setup.IdentitySharingModalCreate
                          )
                        },
                        "open-published": function ($event) {
                          return _vm.$emit(
                            "set-active-modal",
                            _setup.IdentitySharingModalPublished
                          )
                        },
                        "open-delete": function ($event) {
                          return _vm.$emit(
                            "set-active-modal",
                            _setup.IdentitySharingModalDelete
                          )
                        },
                      },
                    },
                    "Component",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "portal",
        { attrs: { to: "root" } },
        [
          _vm.$attrs.value
            ? _c(
                "Transition",
                { attrs: { name: "identity-sharing-background", appear: "" } },
                [_c("div", { staticClass: "identity-sharing-background" })]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }