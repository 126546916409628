var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-actions" }, [
    _c("span", [_vm._v(" Request approved ")]),
    _c("a", { staticClass: "link-style", on: { click: _vm.manage } }, [
      _vm._v("Name Contact"),
    ]),
    _vm._v(" | "),
    _c("a", { staticClass: "link-style", on: { click: _vm.undo } }, [
      _vm._v("Undo"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }