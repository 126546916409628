var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { attrs: { id: `fwd-toggle-${_vm.field}` } }, [
    _c(
      "div",
      { staticClass: "fwd-toggle-wrapper" },
      [
        _c(
          "UiMenu",
          {
            attrs: { width: "250px", placement: "bottom-start" },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _vm._l(_vm.options, function (option) {
                      return _c("UiMenuButton", {
                        key: option,
                        attrs: {
                          title: option,
                          active: option === _vm.selected,
                        },
                        on: { click: () => _vm.handleSelect(option) },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "icon",
                              fn: function () {
                                return [
                                  option === _vm.selected
                                    ? _c("CheckIcon")
                                    : _c("span"),
                                ]
                              },
                              proxy: true,
                            },
                            _vm.field === "email"
                              ? {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c("UiEmailOptionLabel", {
                                        attrs: { email: option },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    _c("UiMenuSeparator"),
                    _c("UiMenuButton", {
                      attrs: { title: _vm.addButtonText },
                      on: { click: _vm.addNewField },
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [_c("Plus")]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c("div", { staticClass: "fwd-toggle-wrapper__label" }, [
              _c("div", { staticClass: "fwd-toggle-wrapper__label-title" }, [
                _vm._v(" " + _vm._s(_vm.headerText) + " "),
              ]),
              _c(
                "div",
                {
                  staticClass: "fwd-toggle-wrapper__label-selection",
                  class: {
                    "fwd-toggle-wrapper__label-selection--selected":
                      _vm.selected,
                  },
                },
                [
                  _c("span", { staticClass: "selected-ellipsis" }, [
                    _vm._v(_vm._s(_vm.selectedText)),
                  ]),
                  _c("ChevronDown"),
                ],
                1
              ),
            ]),
          ]
        ),
        _c(
          "div",
          { on: { click: _vm.toggleForward } },
          [
            _c(
              "UiTooltip",
              {
                attrs: {
                  "aria-id": `ToggleSwitch.${_vm.field || ""}`,
                  title: _vm.switchTooltipTitle,
                  "can-show": _vm.switchIsDisabled,
                  "align-x": "right",
                },
              },
              [
                _c("UiSwitchInput", {
                  attrs: {
                    value: !!_vm.selected,
                    disabled: _vm.switchIsDisabled,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }