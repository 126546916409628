<template>
  <ValueDisplay
    class="preferences-radio"
    :label="label"
    :value="subLabel"
    :disabled="disabled"
    dark-label
    light-value
    @click="handleClick"
  >
    <template v-slot:pre-field>
      <slot name="pre-field" />
    </template>

    <template v-slot:actions>
      <button
        v-if="deletable"
        class="value-display__action-button value-display__action-button--delete"
        @click.stop="handleDelete"
      >
        <TrashOutline />
      </button>

      <RadioButton
        :name="groupName"
        :checked="value === inputValue"
        :value="inputValue"
        :disabled="disabled"
      />
    </template>
  </ValueDisplay>
</template>

<script>
import ValueDisplay from "@/components/ui/value-display.vue";
import { RadioButton } from "@/components";
import { TrashOutline } from "@/assets/icons";

export default {
  name: "PreferencesRadio",
  props: {
    inputValue: [String, Number, Boolean],
    value: [String, Number, Boolean],
    label: String,
    subLabel: String,
    groupName: String,
    disabled: Boolean,
    deletable: Boolean,
  },

  components: {
    ValueDisplay,
    RadioButton,
    TrashOutline,
  },

  methods: {
    handleClick() {
      if (this.disabled) {
        return;
      }

      this.$emit("update", this.inputValue);
    },

    handleDelete() {
      if (this.disabled) {
        return;
      }

      this.$emit("delete", this.inputValue);
    },
  },
};
</script>

<style lang="scss">
.preferences-radio {
  .value-display__action-button--delete {
    display: none;
  }

  &:not(.value-display--disabled):hover {
    .value-display__action-button--delete {
      display: flex;
    }
  }
}
</style>
