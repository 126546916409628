<template>
  <div :class="{ disabled }">
    <input
      type="file"
      ref="fileupload"
      @change="handleUpload"
      style="opacity: 0; display: none; position: absolute"
      multiple
    />
    <button @click="openFile">
      <slot name="icon">
        <Paperclip />
      </slot>
    </button>
  </div>
</template>

<script>
import { FILE_SIZE_LIMIT, maxPayload } from "@/scripts/validation";
import Paperclip from "@/assets/icons/paperclip.svg";
import api from "@/api/api";
import axios from "axios";
import { isTypeBanned } from "@/scripts/regex";

export default {
  name: "FileAttachments",
  props: {
    filter: [Boolean, Function],
    onSend: Function,
    endpoint: String,
    disabled: Boolean,
  },
  components: {
    Paperclip,
  },
  data() {
    return {
      files: [],
    };
  },
  watch: {
    files: {
      handler(value) {
        this.$emit("change", value);
      },
      deep: true,
    },
  },
  computed: {
    maxPayload() {
      return maxPayload(this.files);
    },
  },
  mounted() {
    this.files = [];
  },
  methods: {
    handleUpload(event) {
      const files = Array.from(event.target.files).map((f, i) => {
        f.index = i;
        return f;
      });
      let errors = files.filter((file) => {
        return (
          file.size >= FILE_SIZE_LIMIT || isTypeBanned(file.type, file.name)
        );
      });
      if (this.filter) {
        errors = [
          ...errors,
          ...files.filter((file) => {
            return this.filter(file);
          }),
        ];
      }
      const unique = [...new Set(errors.map((item) => item.index))].map((i) => {
        return files[i];
      });
      errors = unique.map((file) => {
        return {
          filename: file.name,
          error: true,
          size: file.size,
          content_type: file.type.includes("jpg") ? "image/jpeg" : file.type,
        };
      });
      this.$refs.fileupload.value = "";
      if (errors.length > 0) {
        this.files = [...this.files, ...errors];
      } else {
        const filenames = files
          .filter((file) => file.size < FILE_SIZE_LIMIT)
          .map((f) => f.name);
        api()
          .post(this.endpoint, { filenames })
          .then(({ data }) => {
            // after the activity encryption transition, the behaviour is changed
            if (Array.isArray(data)) {
              data = data[0];
            }
            const { urls, errors } = data;
            if (errors) {
              throw { response: { data: { errors } } };
            }
            files.map((file, index) => {
              this.saveFile(file, urls[index]);
            });
          })
          .catch((e) => {
            try {
              const { response } = e;
              const { data } = response;
              this.$toast.error(
                data.errors || "There was an error uploading your files."
              );
            } catch (f) {
              this.$toast.error("There was an error uploading your files.");
            }
          });
      }
    },
    saveFile(file, s3) {
      const req = axios.create();
      this.files = [
        ...this.files,
        {
          ...s3,
          size: file.size,
          content_type: file.type,
          uploading: true,
          uploaded: false,
        },
      ];
      req
        .put(s3.url, file, {
          headers: {
            "Content-Type": file.type,
          },
        })
        .then(() => {
          const findIndex = this.files.findIndex((f) => f.key === s3.key);
          this.$set(this.files, findIndex, {
            filename: s3.filename,
            key: s3.key,
            content_type: file.type,
            size: file.size,
          });
        });
    },
    removeFile(index) {
      this.files = [...this.files].filter((f, i) => i !== index);
    },
    openFile() {
      this.$refs.fileupload.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  button {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}
.error_message {
  font-size: 14px;
  line-height: 20px;
  button {
    border: none;
    cursor: pointer;
    font-size: 12px;
    display: inline-block;
    text-decoration: underline;
    background-color: transparent;
  }
}
.filelist {
  padding: 5px;
  border-radius: 8px;
  .error_m {
    background-color: $color-alert;
    border: 1px solid $color-alert-tint;
  }
  .file {
    margin: 5px 0;
    padding: 4px 8px 4px 4px;
    background: $color-background;
    border-radius: 24px;
    font-size: 12px;
    display: inline-flex;
    gap: 5px;

    &.error {
      background-color: $color-alert;
      border: 1px solid $color-alert-tint;
    }

    &:hover {
      background: $color-background;
      .delete {
        display: block;
      }
      .icon {
        display: none;
      }
    }
    button {
      border: none;
      background-color: transparent;
      height: 15px;
      width: 18px;
      svg {
        height: 13px;
        width: auto;
      }
    }
    .delete {
      display: none;
    }
  }
}
button {
  color: $color-primary-100;
  border: none;
  background-color: transparent;
  cursor: pointer !important;
}

.icon {
  color: $color-primary-100;
}
</style>
