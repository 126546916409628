var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "label",
    {
      staticClass: "border-input-multiline",
      class: { "border-input-multiline--error": _vm.errors.length },
    },
    [
      _vm.label
        ? _c("span", { staticClass: "border-input-multiline__label" }, [
            _vm._v(" " + _vm._s(_vm.label) + " "),
          ])
        : _vm._e(),
      _c("span", { staticClass: "border-input-multiline__input" }, [
        _c("span", {
          ref: "input",
          staticClass: "border-input-multiline__input-field",
          attrs: { contenteditable: true },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.innerText)
            },
            blur: function ($event) {
              return _vm.$emit("change", $event.target.innerText)
            },
            paste: function ($event) {
              $event.preventDefault()
              return _setup.onPaste.apply(null, arguments)
            },
          },
        }),
        !_vm.value.trim()
          ? _c(
              "span",
              { staticClass: "border-input-multiline__input-placeholder" },
              [_vm._v(" " + _vm._s(_vm.placeholder) + " ")]
            )
          : _vm._e(),
      ]),
      _vm._l(_vm.errors, function (error) {
        return _vm._t(
          "error",
          function () {
            return [
              _c(_setup.InputValidationError, { key: error }, [
                _vm._v(" " + _vm._s(error) + " "),
              ]),
            ]
          },
          { error: error }
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }