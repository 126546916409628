import { extensionMessaging, AutoChangeMessages } from "@/scripts/messaging";
import {
  AutoChangeEnabledStatus,
  AutoChangeStatus,
  AutoChangeUpdateStatus,
  AutoChangeVerificationStatus,
  SupportedAutoChangeFieldTypes,
} from "@/components/auto-change/utils";
import { IdentityService } from "@/api";
import { authDecrypt } from "@/scripts/actions/encryption";
import router from "@/routes/router";
import { getIdentityStatus } from "@/components/auto-change/getStatus";

const baseWebsite = (url) => {
  try {
    const hostName = new URL(
      ["http://", "https://"].some((protocol) => url.startsWith(protocol))
        ? url
        : `https://${url}`
    ).hostname;

    const [topLevelDomain, rootDomain] = hostName.split(".").reverse();
    return [rootDomain, topLevelDomain].filter((domain) => !!domain).join(".");
  } catch (e) {
    return null;
  }
};

export default {
  namespaced: true,
  state: {
    batchSize: 3,
    identities: [],
    identitiesMeta: [],
    autoChangeSupport: [],
    autoChangeState: null,
    autoChangeRequests: null,
    isFetching: false,
    isExtensionLoggedIn: false,
    isAutoChangeModalStartOpen: false,
    isAutoChangeModalCompleteOpen: false,
  },
  mutations: {
    SET_BATCH_SIZE: (state, payload) => (state.batchSize = payload),
    SET_IDENTITIES: (state, payload) => (state.identities = payload),
    SET_IDENTITIES_META: (state, payload) => (state.identitiesMeta = payload),
    SET_AUTO_CHANGE_SUPPORT: (state, payload) =>
      (state.autoChangeSupport = payload),
    SET_AUTO_CHANGE_STATE: (state, payload) =>
      (state.autoChangeState = payload),
    SET_AUTO_CHANGE_REQUESTS: (state, payload) =>
      (state.autoChangeRequests = payload),
    SET_IS_FETCHING: (state, payload) => (state.isFetching = payload),
    SET_IS_EXTENSION_LOGGED_IN: (state, payload) =>
      (state.isExtensionLoggedIn = payload),
    SET_IS_AUTO_CHANGE_MODAL_START_OPEN: (state, payload) =>
      (state.isAutoChangeModalStartOpen = payload),
    SET_IS_AUTO_CHANGE_MODAL_COMPLETE_OPEN: (state, payload) =>
      (state.isAutoChangeModalCompleteOpen = payload),
  },
  actions: {
    init({ commit }) {
      extensionMessaging.addListener(({ type, data }) => {
        if (type === AutoChangeMessages.AUTO_CHANGE_SUPPORT_RESPONSE) {
          commit("SET_AUTO_CHANGE_SUPPORT", data);
        }

        if (type === AutoChangeMessages.AUTO_CHANGE_STATE_UPDATED) {
          commit("SET_AUTO_CHANGE_STATE", data);
        }

        if (type === AutoChangeMessages.AUTO_CHANGE_LOGGED_IN_UPDATED) {
          commit("SET_IS_EXTENSION_LOGGED_IN", data);
        }

        if (type === AutoChangeMessages.AUTO_CHANGE_FINISHED) {
          commit("SET_IS_AUTO_CHANGE_MODAL_COMPLETE_OPEN", true);
          window.$vue.$toast.success("AutoCloak complete.");
        }
      });

      extensionMessaging.sendMessage({
        type: AutoChangeMessages.AUTO_CHANGE_SUPPORT_REQUEST,
      });

      extensionMessaging.sendMessage({
        type: AutoChangeMessages.AUTO_CHANGE_STATE_REQUEST,
      });

      extensionMessaging.sendMessage({
        type: AutoChangeMessages.AUTO_CHANGE_LOGGED_IN_REQUEST,
      });

      extensionMessaging.readyToListen();
    },
    async fetchIdentities({ getters, commit }) {
      commit("SET_IS_FETCHING", true);

      try {
        const response = await IdentityService.fetchIdentities();
        const availableIdentities = response.data.results.filter(
          (identity) => !identity.protected
        );

        const identitiesMeta = availableIdentities.map((identity) => ({
          id: identity.id,
          identityWebsite: identity.website_url
            ? baseWebsite(identity.website_url)
            : null,
          email:
            !!identity.cloaked_email?.email ||
            !!identity.stored_autofill?.email,
          password:
            !!identity.stored_password?.password ||
            !!identity.stored_autofill?.password,
          username: !!identity.stored_autofill?.username,
          phone:
            !!identity.cloaked_phone?.phone_number ||
            !!identity.stored_autofill?.phone_number,
        }));

        commit("SET_IDENTITIES_META", identitiesMeta);

        const identities = await Promise.all(
          availableIdentities
            .filter((identity) =>
              getters.autoChangeAbleIdentities
                .map((autoChangeAbleIdentity) => autoChangeAbleIdentity.id)
                .includes(identity.id)
            )
            .map((identity) => ({
              id: identity.id,
              cloaked_email: identity.cloaked_email?.email ?? null,
              cloaked_email_updated_at:
                identity.cloaked_email?.updated_at ?? null,
              cloaked_phone: identity.cloaked_phone?.phone_number ?? null,
              cloaked_phone_updated_at:
                identity.cloaked_phone?.updated_at ?? null,
              stored_password: identity.stored_password?.password ?? null,
              stored_password_updated_at:
                identity.stored_password?.updated_at ?? null,
              email: identity.stored_autofill?.email ?? null,
              email_updated_at:
                identity.stored_autofill?.email_updated_at ?? null,
              phone: identity.stored_autofill?.phone_number ?? null,
              phone_updated_at:
                identity.stored_autofill?.phone_number_updated_at ?? null,
              password: identity.stored_autofill?.password ?? null,
              password_updated_at:
                identity.stored_autofill?.password_updated_at ?? null,
              username: identity.stored_autofill?.username ?? null,
              username_updated_at:
                identity.stored_autofill?.username_updated_at ?? null,
            }))
            .map(
              (identity) =>
                // eslint-disable-next-line no-async-promise-executor
                new Promise(async (resolve) => {
                  const [email, password, username, phone] = await Promise.all(
                    ["email", "password", "username", "phone"].map(
                      (fieldType) =>
                        new Promise((resolve) => {
                          const lastUpdatedStorageTypes = [
                            "cloaked",
                            "stored",
                            "autofill",
                          ].sort((a, b) => {
                            const aUpdatedAt = new Date(
                              a === "autofill"
                                ? identity[`${fieldType}_updated_at`] ?? 0
                                : identity[`${a}_${fieldType}_updated_at`] ?? 0
                            ).getTime();

                            const bUpdatedAt = new Date(
                              b === "autofill"
                                ? identity[`${fieldType}_updated_at`] ?? 0
                                : identity[`${b}_${fieldType}_updated_at`] ?? 0
                            ).getTime();

                            return bUpdatedAt - aUpdatedAt;
                          });

                          for (const storageType of lastUpdatedStorageTypes) {
                            if (
                              storageType === "cloaked" &&
                              identity[`cloaked_${fieldType}`]
                            ) {
                              return resolve({
                                value: identity[`cloaked_${fieldType}`],
                                source: "generated",
                              });
                            }

                            if (
                              storageType === "stored" &&
                              identity[`stored_${fieldType}`]
                            ) {
                              return resolve(
                                authDecrypt(
                                  identity[`stored_${fieldType}`]
                                ).then((value) => ({
                                  value,
                                  source: "generated",
                                }))
                              );
                            }

                            if (
                              storageType === "autofill" &&
                              identity[fieldType]
                            ) {
                              return resolve(
                                authDecrypt(identity[fieldType]).then(
                                  (value) => ({
                                    value,
                                    source: "autofill",
                                  })
                                )
                              );
                            }
                          }

                          return resolve({ value: null, source: null });
                        })
                    )
                  );

                  resolve({
                    id: identity.id,
                    email,
                    password,
                    username,
                    phone,
                  });
                })
            )
        );

        commit("SET_IDENTITIES", identities);
      } catch (e) {
        commit("SET_IDENTITIES_META", []);
        commit("SET_IDENTITIES", []);
        window.$vue.$toast.error("Failed to fetch identities.");
      } finally {
        commit("SET_IS_FETCHING", false);
      }
    },
    autoChangeIds({ getters, commit }, identityIds) {
      if (getters.isInProgress) {
        router.currentRoute.name !== "AutoCloak" &&
          router.push({ name: "AutoCloak" });
        return;
      }

      commit(
        "SET_AUTO_CHANGE_REQUESTS",
        getters.autoChangeAbleIdentitiesWithDetails
          .filter((identity) => identityIds.some((id) => id === identity.id))
          .map((identity) => ({
            identityId: identity.id,
            identityWebsite: identity.website_url
              ? baseWebsite(identity.website_url)
              : null,
            identityNickname: identity.nickname ?? null,
            identityLogo: identity.logo_url ?? null,
            identityColor: identity.cloak_brand_color ?? null,
            fields: SupportedAutoChangeFieldTypes.map((fieldType) => ({
              fieldType,
              ...identity[fieldType],
            })).filter((field) => !!field.value),
            change: getters.getPresentSupportedFieldTypes(identity.id),
          }))
      );

      commit("SET_IS_AUTO_CHANGE_MODAL_START_OPEN", true);
    },
    autoChange({ getters, commit }, requests) {
      if (getters.isInProgress) {
        this.$route.name !== "AutoCloak" &&
          this.$router.push({ name: "AutoCloak" });
        return;
      }

      commit(
        "SET_AUTO_CHANGE_REQUESTS",
        requests.map((request) => ({
          ...request,
          fields: SupportedAutoChangeFieldTypes.map((fieldType) => ({
            fieldType,
            ...getters.autoChangeAbleIdentitiesWithDetails.find(
              (identity) => identity.id === request.identityId
            )[fieldType],
          })).filter((field) => !!field.value),
          change: request.fields.map((field) => field.fieldType),
          identityWebsite: request.identityWebsite
            ? baseWebsite(request.identityWebsite)
            : null,
          identityNickname: request.identityNickname ?? null,
          identityLogo: request.identityLogo ?? null,
          identityColor: request.identityColor ?? null,
        }))
      );

      commit("SET_IS_AUTO_CHANGE_MODAL_START_OPEN", true);
    },
    startAutoChange({ state }) {
      extensionMessaging.sendMessage({
        type: AutoChangeMessages.AUTO_CHANGE_START,
        data: {
          batchSize: state.batchSize,
          requests: state.autoChangeRequests,
        },
      });
    },
    reviewItem(context, data) {
      extensionMessaging.sendMessage({
        type: AutoChangeMessages.AUTO_CHANGE_REVIEW_ITEM,
        data,
      });
    },
    autoChangeStop() {
      extensionMessaging.sendMessage({
        type: AutoChangeMessages.AUTO_CHANGE_STOP,
      });
    },
    async startOver({ dispatch, commit }) {
      await dispatch("fetchIdentities");
      commit("SET_AUTO_CHANGE_STATE", null);
      commit("SET_AUTO_CHANGE_REQUESTS", null);
    },
  },
  getters: {
    getIsFetching: (state) => state.isFetching,
    getAutoChangeSupport: (state) => state.autoChangeSupport,
    getAutoChangeEnabled: (state, getters, rootState) => {
      if (
        !rootState.browser.pluginDetected &&
        // develop and staging cannot detect local extension builds because there is no one extension id
        process.env.NODE_ENV === "production"
      ) {
        return AutoChangeEnabledStatus.EXTENSION_NOT_INSTALLED;
      }

      if (!state.isExtensionLoggedIn) {
        return AutoChangeEnabledStatus.EXTENSION_LOGGED_OUT;
      }

      if (
        // internal users and the recipe writer team doesn't have expiration date on their subscriptions
        rootState.settings.subscription.expires_date &&
        new Date().getTime() >=
          new Date(rootState.settings.subscription.expires_date).getTime() -
            15 * 60 * 1000
      ) {
        return AutoChangeEnabledStatus.ACCOUNT_RESTRICTED;
      }

      return AutoChangeEnabledStatus.ENABLED;
    },
    allIdentities: (state, getters, rootState) =>
      rootState.localdb.db_cloaks.filter((identity) => !identity.protected),
    autoChangeAbleIdentities: (state, getters) =>
      getters.allIdentities.filter((identity) =>
        getters.isAutoChangeAvailable(identity.id)
      ),
    getIdentityMeta: (state) => (identityId) =>
      state.identitiesMeta.find((identity) => identity.id === identityId),
    autoChangeAbleIdentitiesWithDetails: (state, getters) =>
      state.identities.length
        ? getters.autoChangeAbleIdentities.map((identity) => {
            const { email, password, username, phone } =
              state.identities?.find(
                (fetchedIdentity) => fetchedIdentity.id === identity.id
              ) ?? {};

            return {
              ...identity,
              website_url: state.identitiesMeta?.find(
                (fetchedIdentity) => fetchedIdentity.id === identity.id
              ).identityWebsite,
              email,
              password,
              username,
              phone,
            };
          })
        : [],
    getAutoChangeProgress: (state) => state.autoChangeState?.progress ?? [],
    getAutoChangeVerificationProgress: (state) =>
      state.autoChangeState?.verification ?? [],
    getAutoChangeUpdatesProgress: (state) =>
      state.autoChangeState?.updates ?? [],
    isInProgress: (state, getters) =>
      getters.getAutoChangeProgress?.some((item) =>
        item.fields?.some((field) =>
          [
            AutoChangeStatus.WAITING,
            AutoChangeStatus.IN_PROGRESS,
            AutoChangeStatus.NEEDS_REVIEW,
          ].includes(field?.status)
        )
      ) ||
      getters.getAutoChangeVerificationProgress?.some((item) =>
        item.fields?.some((field) =>
          [
            AutoChangeVerificationStatus.WAITING,
            AutoChangeVerificationStatus.IN_PROGRESS,
            AutoChangeVerificationStatus.NEEDS_REVIEW,
          ].includes(field?.status)
        )
      ) ||
      getters.getAutoChangeUpdatesProgress?.some((item) =>
        item.fields?.some((field) =>
          [
            AutoChangeUpdateStatus.WAITING,
            AutoChangeUpdateStatus.IN_PROGRESS,
          ].includes(field?.status)
        )
      ),
    getAutoChangeRequests: (state) => state.autoChangeRequests,
    isAutoChangeModalStartOpen: (state) => state.isAutoChangeModalStartOpen,
    isAutoChangeModalCompleteOpen: (state) =>
      state.isAutoChangeModalCompleteOpen,
    getIdentityStatus: (state) => (identityId) => {
      const change = state?.autoChangeState?.progress?.find(
        (item) => item.identityId === identityId
      );

      const verification = state?.autoChangeState?.verification?.find(
        (item) => item.identityId === identityId
      );

      const update = state?.autoChangeState?.updates?.find(
        (item) => item.identityId === identityId
      );

      return getIdentityStatus({ change, verification, update });
    },
    isAutoChangeAvailable: (state, getters) => (identityId) =>
      getters.getPresentSupportedFieldTypes(identityId).length > 0,
    getAllSupportedFieldTypes: (state, getters) => (identityId) => {
      const identityMeta = getters.getIdentityMeta(identityId);

      return (
        state.autoChangeSupport?.find((item) =>
          identityMeta?.identityWebsite?.endsWith(item.websiteUrl)
        )?.fieldTypes ?? []
      ).filter((fieldType) =>
        SupportedAutoChangeFieldTypes.includes(fieldType)
      );
    },
    getPresentSupportedFieldTypes: (state, getters) => (identityId) => {
      const identityMeta = getters.getIdentityMeta(identityId);

      return getters
        .getAllSupportedFieldTypes(identityId)
        .filter((fieldType) => !!identityMeta?.[fieldType]);
    },
  },
};
