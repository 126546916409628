<template>
  <span class="auto-change-identity" v-on="$listeners">
    <span class="auto-change-identity__left">
      <IdentityIcon
        :identity="{ logo_url: logo, cloak_brand_color: color }"
        :override="{
          width: '32px',
          height: '32px',
        }"
        class="auto-change-identity__icon"
      />
      <slot name="left" />
    </span>
    <span>
      <span class="auto-change-identity__nickname" v-if="nickname">
        {{ nickname }}
      </span>
      <span class="auto-change-identity__website" v-if="website">
        {{ website }}
      </span>
    </span>
  </span>
</template>

<script>
import IdentityIcon from "@/components/ui/IdentityIcon.vue";

export default {
  components: { IdentityIcon },
  props: {
    logo: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: undefined,
    },
    nickname: {
      type: String,
      default: undefined,
    },
    website: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/recursive/_mixins.scss";

.auto-change-identity {
  display: flex;
  align-items: center;
  gap: 12px;

  &__left {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    position: relative;
  }

  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &__nickname {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: $color-primary-100;
    @include line-clamp(1);
  }

  &__website {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: $color-primary-60;
    margin-top: 4px;
    @include line-clamp(1);
  }
}
</style>
