<script setup>
import axios from "axios";
import CloaksList from "@/routes/your-cloaks/CloaksList";
import { analyticsExport } from "@/mixins/Analytics";
import { ChevronDown } from "@/assets/icons";
import ArrowNE from "@/assets/icons/arrow-ne.svg";
import { Button } from "@/components";
import ImportBanner from "@/components/feature/import/ImportBanner";

import Loading from "@/components/ui/loading.vue";
import ReuseService from "@/api/actions/reuse-service";
import IdentityService from "@/api/actions/identity-service";

import { useRoute } from "vue-router/composables";
import store from "@/store";
import {
  reactive,
  onMounted,
  onBeforeUnmount,
  watch,
  nextTick,
  computed,
  ref,
} from "vue";

const route = useRoute();

const source = axios.CancelToken.source();

const PAGE_SIZE = 30;

const cloakListRef = ref(null);

const state = reactive({
  filter: getFilterFromRoute(),
  sort: route.query.ordering || "-created_at",
  loading: false,
  isTotpFilterTooltipDisplayed: false,
  page: 1,
});

onBeforeUnmount(() => {
  source.cancel();
});

onMounted(() => {
  if (ready.value) {
    getOrCreateCloakedSupport();
    ReuseService.getInitialCount();
  }
});

const hasExpiringNumbers = computed(() => {
  return store.state.reuse.initialized && store.state.reuse.numbers.length > 0;
});

const ready = computed(() => {
  return (
    store.state.authentication.auth &&
    store.state.authentication.auth.access_token
  );
});

const dbLoaded = computed(() => {
  return store.getters.dbLoaded;
});

const identityList = computed(() => {
  const numToShow = state.page * PAGE_SIZE;
  let slicedCloakList = cloaksToShow.value.slice(0, numToShow);
  return slicedCloakList;
});

const allCloaks = computed(() => {
  if (store.state.localdb.db_cloaks) {
    return store.state.localdb.db_cloaks.filter((d) => !d.protected);
  }
  return [];
});

const cloaksToShow = computed(() => {
  let cloaks = [...allCloaks.value];
  if (state.filter) {
    switch (state.filter) {
      case "has_phone":
        cloaks = cloaks.filter((c) => !!c.phone);
        break;
      case "has_totp":
        cloaks = cloaks.filter(
          (c) =>
            !!c.customFields?.some((field) =>
              ["totp_url", "totp_secret"].includes(field.type)
            )
        );
        break;
      case "has_email":
        cloaks = cloaks.filter((c) => !!c.email);
        break;
      case "has_login":
        cloaks = cloaks.filter(
          (c) =>
            (!!c.has_password || !!c.password) &&
            (!!c.email || !!c.username || !!c.phone)
        );
        break;
    }
  }
  if (state.sort) {
    switch (state.sort) {
      case "nickname":
        return cloaks.sort((a, b) => {
          if (a.nickname && !b.nickname) {
            return 1;
          }
          if (!a.nickname && b.nickname) {
            return -1;
          }
          if (!a.nickname && !b.nickname) {
            return 0;
          }
          return a.nickname.localeCompare(b.nickname);
        });
      case "-created_at":
        return cloaks.sort((a, b) => {
          return new Date(a.created_at) < new Date(b.created_at) ? 1 : -1;
        });
      case "-last_used_at":
        return cloaks.sort((a, b) => {
          return new Date(a.last_used_at) < new Date(b.last_used_at) ? 1 : -1;
        });
    }
  }
  return cloaks;
});

watch(
  () => state.filter,
  () => {
    state.page = 1;
    cloakListRef.value.$refs.infinite.uncomplete();
  }
);

function loadNextPage($state) {
  const newPage = state.page + 1;
  state.page = newPage;
  nextTick(() => {
    if ($state) {
      if (newPage * PAGE_SIZE >= cloaksToShow.value.length) {
        $state.complete();
      }
    }
  });
}

function getOrCreateCloakedSupport() {
  if (!store.state.support_cloak) {
    const supportCloak = store.state.localdb.db_cloaks.find(
      (c) => c.protected && c.nickname.match(/team|support/i)
    );
    if (supportCloak) {
      store.commit("registerSupport", supportCloak);
    } else {
      return IdentityService.generateDefaultCloak();
    }
  }
}

function getFilterFromRoute() {
  let filter = "";
  if (route.query.has_email) {
    filter = "has_email";
  } else if (route.query.has_totp) {
    filter = "has_totp";
  } else if (route.query.has_phone) {
    filter = "has_phone";
  } else if (route.query.has_login) {
    filter = "has_login";
  }
  return filter;
}

function setFilter(filter) {
  if (filter !== state.filter) {
    state.filter = filter;
    let eventName = "Select Filter";
    let payload = {
      filter_name: state.filter,
    };
    analyticsExport.methods.sendEvent(eventName, payload);
    cloakListRef.value.unselectAll();
  } else {
    state.filter = "";
  }
}
</script>

<template>
  <div v-if="ready">
    <Transition name="slide-bottom" appear>
      <router-view />
    </Transition>

    <Loading v-if="state.loading || !dbLoaded" />
    <section v-else class="main-content">
      <ImportBanner />

      <div class="all-title">
        <div v-if="hasExpiringNumbers">
          <router-link to="/number-reuse" class="number-reuse">
            <span>Number clean up</span>
            <span>Review numbers <ArrowNE /></span>
          </router-link>
        </div>
      </div>

      <div class="filters">
        <div class="facets">
          <Button
            :type="state.filter === 'has_login' ? 'tag-selected' : 'tag'"
            @click="setFilter('has_login')"
          >
            Logins
          </Button>

          <Button
            :type="state.filter === 'has_totp' ? 'tag-selected' : 'tag'"
            @click="setFilter('has_totp')"
          >
            One-time passcodes
          </Button>

          <Button
            :type="state.filter === 'has_phone' ? 'tag-selected' : 'tag'"
            @click="setFilter('has_phone')"
          >
            Phone numbers
          </Button>

          <Button
            :type="state.filter === 'has_email' ? 'tag-selected' : 'tag'"
            @click="setFilter('has_email')"
          >
            Email
          </Button>
        </div>

        <div class="sort">
          <span class="filter-label">Sort by:</span>

          <div class="sort__select-wrapper">
            <select v-model="state.sort">
              <option value="nickname">Alphabetical</option>
              <option value="-created_at">Date Created</option>
            </select>

            <div class="sort__select-icon">
              <ChevronDown />
            </div>
          </div>
        </div>
      </div>

      <CloaksList
        v-if="dbLoaded"
        ref="cloakListRef"
        hoverText="New Identity"
        :add="true"
        :showModalOnHover="allCloaks.length === 0"
        :identityList="identityList"
        :identifierPriority="state.filter"
        @loadNextPage="loadNextPage"
      />
    </section>
  </div>
</template>

<style lang="scss">
.modal-container.active .content:has(.category-video) {
  width: 50vw;
  min-width: 300px !important;
  max-width: 800px !important;
}
.category-video {
  width: 100%;
}
.category-video-content {
  gap: 15px;
  h1 {
    font-size: 1.75rem !important;
    margin-bottom: 25px;
    font-weight: 400;
    width: 90%;
  }
  p {
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 0.9rem !important;
    line-height: normal !important;
    a {
      color: blue !important;
      text-decoration: underline;
    }
  }
}
</style>
<style scoped lang="scss">
@import "@/assets/scss/recursive/_mixins.scss";

.title {
  padding: 0 36px;
  margin-bottom: 7px;
  margin-top: 24px;

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: $color-primary-100;
  }
}

.all-title {
  display: flex;
  justify-content: space-between;
  margin: 0 24px;
  align-items: center;

  .number-reuse {
    font-size: 12px;
    border-radius: 12px;
    padding: 8px 16px;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    color: $color-primary-100;
    border: 1px solid $color-primary-20;
    background-color: $color-primary-5;
  }
}

.filters {
  position: sticky;
  top: 65px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
  margin-bottom: -65px;
  z-index: 9;
  float: right;
  background: $color-surface;
  display: none;

  @media (min-width: 1200px) {
    display: flex;
  }

  .facets {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .filter-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $color-primary-70;
  }

  .sort {
    margin-left: 34px;
    display: flex;
    align-items: center;
    gap: 8px;

    &__select-wrapper {
      background: $color-surface;
      display: flex;
      gap: 8px;
      align-items: center;
      position: relative;

      select {
        font-family: $poppins;
        appearance: none;
        outline: none;
        background-color: transparent;
        border: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-decoration-line: underline;
        color: $color-primary-100;
        background: $color-surface;
        width: auto;

        padding-right: 24px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &__select-icon {
      width: 24px;
      height: 18px;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-primary-100;
    }
  }
}

.app--visible-banner {
  .filters {
    // initial top plus height of banner
    top: calc(65px + 40px);
  }
}

.main-content {
  background: $color-surface;
  margin-top: 40px;
}
</style>
