var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesPanel",
    { staticClass: "preference-dual-panel" },
    [
      !_vm.right_panel
        ? _c(
            "div",
            { staticClass: "your-pref-top" },
            [
              _c("PreferencesTitle", [_vm._v("Basic information")]),
              _c("ValueDisplay", {
                attrs: {
                  label: "Name",
                  value: _vm.full_name,
                  name: "info_name",
                },
                on: {
                  click: function ($event) {
                    return _vm.go_right("info_name")
                  },
                },
              }),
              _c("ValueDisplay", {
                attrs: {
                  label: "Birthday",
                  value: _vm.birthday,
                  name: "info_birthday",
                },
                on: {
                  click: function ($event) {
                    return _vm.go_right("info_birthday")
                  },
                },
              }),
              _c("ValueDisplay", {
                attrs: {
                  label: "Gender",
                  value: _vm.capitalize(_vm.info_gender),
                  name: "info_gender",
                },
                on: {
                  click: function ($event) {
                    return _vm.go_right("info_gender")
                  },
                },
              }),
              _c("ValueDisplay", {
                attrs: {
                  label: "Address",
                  value: _vm.address_display,
                  name: "address",
                },
                on: {
                  click: function ($event) {
                    return _vm.go_right("address")
                  },
                },
              }),
              _vm.showCCSection
                ? _c("PreferencesTitle", [_vm._v("Payment methods ")])
                : _vm._e(),
              _vm.showCCSection
                ? _c("SettingsParagraph", [
                    _vm._v(
                      " Cloaked can securely store your stored payment information to automatically fill out forms via the extension or autofill on your mobile device. "
                    ),
                  ])
                : _vm._e(),
              _vm.showCCSection
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _vm._l(_vm.creditCards, function (card, index) {
                        return _c("CreditCard", {
                          key: index,
                          attrs: { card: card },
                          on: {
                            refresh: _vm.refreshCards,
                            click: function ($event) {
                              return _vm.selectCard(card)
                            },
                          },
                        })
                      }),
                      _c("ValueDisplay", {
                        attrs: {
                          label: "Add a credit or debit card",
                          value: "",
                          name: "info_name",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.selectCard(null)
                          },
                        },
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.right_panel === "info_name"
        ? _c("Name", {
            attrs: {
              first_name: _vm.info_name,
              last_name: _vm.info_last_name,
              middle_name: _vm.info_middle_name,
              id: _vm.info_id,
            },
            on: { toggleBack: _vm.toggleBack, update: _vm.on_update_value },
          })
        : _vm._e(),
      _vm.right_panel === "info_birthday"
        ? _c("Birthday", {
            attrs: { birthday: _vm.info_birthday, id: _vm.info_id },
            on: { toggleBack: _vm.toggleBack },
          })
        : _vm._e(),
      _vm.right_panel === "info_gender"
        ? _c("Gender", {
            attrs: { gender: _vm.info_gender, id: _vm.info_id },
            on: { toggleBack: _vm.toggleBack },
          })
        : _vm._e(),
      _vm.right_panel === "address"
        ? _c("Address", {
            attrs: { current: _vm.address, id: _vm.info_id },
            on: { toggleBack: _vm.toggleBack, update: _vm.on_update_value },
          })
        : _vm._e(),
      _vm.showCCSection
        ? _c(
            "div",
            { staticClass: "payment_section" },
            [
              _vm.right_panel === "payment"
                ? _c("Payment", {
                    attrs: {
                      current: _vm.address,
                      id: _vm.info_id,
                      selectedCard: _vm.selectedCard,
                      creditCards: _vm.creditCards,
                    },
                    on: {
                      toggleBack: _vm.toggleBack,
                      update: _vm.on_update_value,
                      refresh: _vm.refreshCards,
                      back: _vm.back,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }