var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "stepper" },
    _vm._l(_vm.steps, function (step, index) {
      return _c(
        "li",
        {
          key: index,
          staticClass: "stepper__item",
          class: {
            "stepper__item--done": index <= _vm.value,
            "stepper__item--active": index === _vm.value,
          },
        },
        [
          _c("span", { staticClass: "stepper__item-text" }, [
            _vm._v(" " + _vm._s(step) + " "),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }