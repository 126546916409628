<template>
  <div class="activity-sender">
    <ProfileIcon
      class="profile-icon clickable"
      :class="{ inbound }"
      @click="$emit('openCloak')"
    />
    <div @click="$emit('openCloak')" class="clickable">{{ senderDisplay }}</div>
    <div class="activity-time">{{ timeDisplay }}</div>
  </div>
</template>
<script>
import moment from "moment";
import ProfileIcon from "@/assets/icons/activity-profile.svg";
export default {
  name: "ActivityConvoNameTimeDisplay",
  props: ["inbound", "senderDisplay", "sameAsPrevSender", "createdAt"],
  components: {
    ProfileIcon,
  },
  computed: {
    timeDisplay() {
      const when = moment(this.createdAt);
      const today = moment().startOf("day");
      const yesterday = moment().subtract(24, "hours").startOf("day");
      const lastYear = moment().subtract(1, "year").startOf("day");
      if (when.isAfter(today)) {
        return when.format("hh:mm a");
      } else if (when.isAfter(yesterday) && when.isBefore(today)) {
        return when.format("hh:mm a");
      } else if (when.isAfter(lastYear)) {
        return when.format("MMM Do");
      } else {
        return when.format("MMM YYYY");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.activity-sender {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: $color-primary-100;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 30px;
  padding: 0 32px;

  .profile-icon {
    height: 24px;
    width: auto;
    margin-right: 10px;
    color: $color-primary-100;
    &.inbound {
      color: $color-primary-100;
    }
    path {
      filter: invert(100%);
    }
  }
  .activity-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-left: 6px;
  }
  .clickable {
    cursor: pointer;
  }
}
</style>
