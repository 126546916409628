var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.AppModal,
    _vm._g(_vm._b({}, "AppModal", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        _setup.AppModalContent,
        {
          staticClass: "auto-change-complete",
          class: `auto-change-complete--${_setup.status}`,
        },
        [
          _c(_setup.AutoChangeIcon, {
            staticClass: "auto-change-complete__icon",
          }),
          _c(_setup.AppModalTitle, [
            _vm._v(" " + _vm._s(_setup.modalTitle) + " "),
          ]),
          _vm.success + _vm.error + _vm.skipped > 0
            ? [
                _setup.status === "success"
                  ? _c(_setup.AppModalParagraph, [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.success} ${
                              _vm.success !== 1
                                ? "identities have"
                                : "identity has"
                            }`
                          ) +
                          " been protected with Cloaked account information. "
                      ),
                    ])
                  : _setup.status === "error"
                  ? _c(_setup.AppModalParagraph, [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.error} ${
                              _vm.error !== 1 ? "identities" : "identity"
                            }`
                          ) +
                          " could not be AutoCloaked. "
                      ),
                    ])
                  : _setup.status === "skipped"
                  ? _c(_setup.AppModalParagraph, [
                      _vm._v(" No identities have been AutoCloaked. "),
                    ])
                  : _setup.status === "partial-success" ||
                    _setup.status === "partial-error"
                  ? _c("ul", { staticClass: "auto-change-complete__list" }, [
                      _vm.success > 0
                        ? _c(
                            "li",
                            { staticClass: "auto-change-complete__list-item" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.success} ${
                                      _vm.success !== 1
                                        ? "identities have"
                                        : "identity has"
                                    }`
                                  ) +
                                  " been protected with Cloaked account information "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.error > 0
                        ? _c(
                            "li",
                            { staticClass: "auto-change-complete__list-item" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.error} ${
                                      _vm.error !== 1
                                        ? "identities have"
                                        : "identity has"
                                    }`
                                  ) +
                                  " an error "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.skipped > 0
                        ? _c(
                            "li",
                            { staticClass: "auto-change-complete__list-item" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.skipped} ${
                                      _vm.skipped !== 1
                                        ? "identities were"
                                        : "identity was"
                                    }`
                                  ) +
                                  " skipped "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            : _c(_setup.AppModalParagraph, [
                _vm._v(" No identities have been updated. "),
              ]),
          _c(
            _setup.AppModalFooter,
            [
              _c(
                _setup.Button,
                {
                  attrs: { type: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.$emit("input", false)
                      _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _c(
                _setup.Button,
                {
                  on: {
                    click: function ($event) {
                      _vm.$emit("input", false)
                      _vm.$emit("close")
                      _vm.$emit("review")
                    },
                  },
                },
                [_vm._v("Review all changes")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }