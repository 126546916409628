var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.PreferencesPanel,
    {
      staticClass: "panel-offset",
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(_setup.PreferencesHeader, {
                on: { "go-back": _setup.toggleBack },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _setup.state.currentStep === _setup.ENTER_PASSWORD
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "mfa-header" },
                [
                  _c(_setup.PreferencesTitle, [_vm._v("Enter your password")]),
                  _c(_setup.PreferencesParagraph, [
                    _vm._v(
                      "For security purposes, please re-enter your password."
                    ),
                  ]),
                ],
                1
              ),
              _c(_setup.PreferencesInput, {
                attrs: {
                  label: "Password",
                  type: "password",
                  placeholder: "",
                  error: !!_setup.state.error,
                },
                on: { save: _setup.verifyUserPassword },
                model: {
                  value: _setup.state.currentPassword,
                  callback: function ($$v) {
                    _vm.$set(_setup.state, "currentPassword", $$v)
                  },
                  expression: "state.currentPassword",
                },
              }),
              _setup.state.error
                ? _c("div", { staticClass: "message" }, [
                    _c("p", [_vm._v(_vm._s(_setup.state.errorMessage))]),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mfa-button" },
                [
                  _c(
                    _setup.Button,
                    {
                      attrs: {
                        loading: _setup.state.fetching,
                        disabled:
                          _setup.state.fetching ||
                          !_setup.state.currentPassword,
                      },
                      on: { click: _setup.verifyUserPassword },
                    },
                    [_vm._v("Continue")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _setup.state.currentStep === _setup.CHOOSE_METHOD
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "mfa-header" },
                [
                  _c(_setup.PreferencesTitle, [
                    _vm._v("Set up two-factor authentication"),
                  ]),
                  _c(_setup.PreferencesParagraph, [
                    _vm._v("Choose a method for two-factor authentication."),
                  ]),
                ],
                1
              ),
              _vm._l(_setup.state.availableMethods, function (availableMethod) {
                return _c(_setup.ValueDisplay, {
                  key: availableMethod,
                  attrs: {
                    label: `${_setup.getMethodDisplayText(availableMethod)}`,
                  },
                  on: {
                    click: function ($event) {
                      return _setup.setSelectedMethod(availableMethod)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "pre-field",
                        fn: function () {
                          return [
                            availableMethod === "email"
                              ? _c(_setup.EmailOutlineIcon)
                              : availableMethod === "call"
                              ? _c(_setup.PhoneOutlineIcon)
                              : availableMethod === "sms"
                              ? _c(_setup.TextOutlineIcon)
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
            ],
            2
          )
        : _vm._e(),
      _setup.state.selectedMethod === "call" ||
      _setup.state.selectedMethod === "sms"
        ? _c("div", [
            _setup.state.currentStep === _setup.SELECT_METHOD_DEVICE
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "mfa-header" },
                      [
                        _c(_setup.PreferencesTitle, [
                          _vm._v("Choose a phone number"),
                        ]),
                      ],
                      1
                    ),
                    _vm._l(
                      _setup.state.verifiedPhoneNumbers,
                      function (verifiedPhoneNumber) {
                        return _c(_setup.ValueDisplay, {
                          key: verifiedPhoneNumber.id,
                          attrs: {
                            label: "Verified phone number",
                            value: verifiedPhoneNumber?.formattedPhoneNumber,
                          },
                          on: {
                            click: function ($event) {
                              return _setup.addNewMfaDeviceForVerifiedMethod(
                                verifiedPhoneNumber.id,
                                verifiedPhoneNumber.verified,
                                _setup.state.selectedMethod === "call" ||
                                  _setup.state.selectedMethod === "sms"
                                  ? "phone"
                                  : "email"
                              )
                            },
                          },
                        })
                      }
                    ),
                    _c(_setup.ValueDisplay, {
                      attrs: {
                        label:
                          _setup.state.verifiedPhoneNumbers?.length > 0
                            ? "Use a different phone number"
                            : "Add a phone number",
                      },
                      on: {
                        click: function ($event) {
                          return _setup.setCurrentStep(_setup.ADD_NEW_METHOD)
                        },
                      },
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _setup.state.currentStep === _setup.ADD_NEW_METHOD
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "mfa-header" },
                      [
                        _c(_setup.PreferencesTitle, [
                          _vm._v(_vm._s(_setup.state.currentMethodDisplayText)),
                        ]),
                        _c(_setup.PreferencesParagraph, [
                          _vm._v(
                            "Add a phone number to use for two-factor authentication."
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(_setup.PreferencesInput, {
                      attrs: {
                        label: "Phone number",
                        type: "tel",
                        placeholder: "",
                        error: _setup.state.error,
                      },
                      on: {
                        save: () =>
                          _setup.addNewPersonalPhone(
                            _setup.state.newPersonalPhone
                          ),
                      },
                      model: {
                        value: _setup.state.newPersonalPhone,
                        callback: function ($$v) {
                          _vm.$set(_setup.state, "newPersonalPhone", $$v)
                        },
                        expression: "state.newPersonalPhone",
                      },
                    }),
                    _setup.state.error
                      ? _c("div", { staticClass: "message" }, [
                          _c("p", [_vm._v(_vm._s(_setup.state.errorMessage))]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mfa-button" },
                      [
                        _c(
                          _setup.Button,
                          {
                            attrs: {
                              loading: _setup.state.fetching,
                              disabled:
                                _setup.state.fetching ||
                                !_setup.state.newPersonalPhone,
                            },
                            on: {
                              click: () =>
                                _setup.addNewPersonalPhone(
                                  _setup.state.newPersonalPhone
                                ),
                            },
                          },
                          [_vm._v("Continue")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _setup.state.currentStep === _setup.VERIFY_NEW_METHOD
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "mfa-header" },
                      [
                        _c(_setup.PreferencesTitle, [
                          _vm._v("Enter verification code"),
                        ]),
                        _c(_setup.PreferencesParagraph, [
                          _vm._v(
                            "Please enter the code we sent to " +
                              _vm._s(
                                _setup.state.currentInputPhoneEmail.display
                              )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "resend-btn-wrapper" }, [
                      _c(
                        "a",
                        {
                          staticClass: "resend",
                          on: { click: _setup.resendPhoneCode },
                        },
                        [_vm._v("Resend code")]
                      ),
                    ]),
                    _c(
                      _setup.OnboardingInputCode,
                      _vm._g(
                        {
                          attrs: {
                            value: _setup.state.totpToken,
                            largeFont: true,
                          },
                          on: {
                            input: function ($event) {
                              _setup.state.totpToken = $event
                            },
                          },
                        },
                        _vm.$listeners
                      )
                    ),
                    _setup.state.error
                      ? _c("div", { staticClass: "message" }, [
                          _c("p", [_vm._v(_vm._s(_setup.state.errorMessage))]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mfa-button" },
                      [
                        _c(
                          _setup.Button,
                          {
                            attrs: {
                              loading: _setup.state.fetching,
                              disabled:
                                _setup.state.fetching ||
                                !_setup.state.totpToken,
                            },
                            on: { click: () => _setup.verifyPhoneTotpCode() },
                          },
                          [_vm._v("Verify code")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _setup.state.selectedMethod === "email"
        ? _c("div", [
            _setup.state.currentStep === _setup.SELECT_METHOD_DEVICE
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "mfa-header" },
                      [
                        _c(_setup.PreferencesTitle, [
                          _vm._v("Choose an email address"),
                        ]),
                        _c(_setup.PreferencesParagraph, [
                          _vm._v(
                            "Add an email address to use with two-factor authentication."
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._l(
                      _setup.state.verifiedEmails,
                      function (verifiedEmail) {
                        return _c(_setup.ValueDisplay, {
                          key: verifiedEmail.id,
                          attrs: {
                            label: "Verified email address",
                            value: verifiedEmail?.email,
                          },
                          on: {
                            click: function ($event) {
                              return _setup.addNewMfaDeviceForVerifiedMethod(
                                verifiedEmail.id,
                                verifiedEmail.verified,
                                _setup.state.selectedMethod
                              )
                            },
                          },
                        })
                      }
                    ),
                    _c(_setup.ValueDisplay, {
                      attrs: {
                        label:
                          _setup.state.verifiedEmails?.length > 0
                            ? "Use a different email address"
                            : "Add an email address",
                      },
                      on: {
                        click: function ($event) {
                          return _setup.setCurrentStep(_setup.ADD_NEW_METHOD)
                        },
                      },
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _setup.state.currentStep === _setup.ADD_NEW_METHOD
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "mfa-header" },
                      [
                        _c(_setup.PreferencesTitle, [
                          _vm._v(_vm._s(_setup.state.currentMethodDisplayText)),
                        ]),
                        _c(_setup.PreferencesParagraph, [
                          _vm._v(
                            "Add an email address to use with two-factor authentication."
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(_setup.PreferencesInput, {
                      attrs: {
                        label: "Email address",
                        type: "text",
                        placeholder: "",
                        error: _setup.state.error,
                      },
                      on: {
                        save: () =>
                          _setup.addNewPersonalEmail(
                            _setup.state.newPersonalEmail
                          ),
                      },
                      model: {
                        value: _setup.state.newPersonalEmail,
                        callback: function ($$v) {
                          _vm.$set(_setup.state, "newPersonalEmail", $$v)
                        },
                        expression: "state.newPersonalEmail",
                      },
                    }),
                    _setup.state.error
                      ? _c("div", { staticClass: "message" }, [
                          _c("p", [_vm._v(_vm._s(_setup.state.errorMessage))]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mfa-button" },
                      [
                        _c(
                          _setup.Button,
                          {
                            attrs: {
                              loading: _setup.state.fetching,
                              disabled:
                                _setup.state.fetching ||
                                !_setup.state.newPersonalEmail,
                            },
                            on: {
                              click: () =>
                                _setup.addNewPersonalEmail(
                                  _setup.state.newPersonalEmail
                                ),
                            },
                          },
                          [_vm._v("Continue")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _setup.state.currentStep === _setup.VERIFY_NEW_METHOD
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "mfa-header" },
                      [
                        _c(_setup.PreferencesTitle, [
                          _vm._v("Enter verification code"),
                        ]),
                        _c(_setup.PreferencesParagraph, [
                          _vm._v(
                            "Please enter the code we sent to " +
                              _vm._s(
                                _setup.state.currentInputPhoneEmail.display
                              )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "resend-btn-wrapper" }, [
                      _c(
                        "a",
                        {
                          staticClass: "resend",
                          on: { click: _setup.resendEmailCode },
                        },
                        [_vm._v("Resend code")]
                      ),
                    ]),
                    _c(
                      _setup.OnboardingInputCode,
                      _vm._g(
                        {
                          attrs: {
                            value: _setup.state.totpToken,
                            largeFont: true,
                          },
                          on: {
                            input: function ($event) {
                              _setup.state.totpToken = $event
                            },
                          },
                        },
                        _vm.$listeners
                      )
                    ),
                    _setup.state.error
                      ? _c("div", { staticClass: "message" }, [
                          _c("p", [_vm._v(_vm._s(_setup.state.errorMessage))]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mfa-button" },
                      [
                        _c(
                          _setup.Button,
                          {
                            attrs: {
                              loading: _setup.state.fetching,
                              disabled:
                                _setup.state.fetching ||
                                !_setup.state.totpToken,
                            },
                            on: { click: () => _setup.verifyEmailTotpCode() },
                          },
                          [_vm._v("Verify code")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }