<script setup>
import { onMounted } from "vue";
import { ActivityPreviewList } from "@/components";
onMounted(() => {
  console.log("a");
});
</script>
<template>
  <div class="trash-activity-container">
    <div class="left">
      <ActivityPreviewList
        ref="list"
        type="all"
        :isRequests="false"
        :multiSelected="{
          threadIds: [],
          conversationIds: [],
        }"
        :singleSelected="null"
        :multiSelectEnabled="[]"
        @select="() => {}"
        @enableMultiSelect="() => {}"
        @setMultiSelect="() => {}"
      />
    </div>
    <div class="right">right hand</div>
  </div>
</template>
<style scoped lang="scss">
.trash-activity-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-top: 1px solid $color-primary-5;
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
