var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "app",
      class: {
        "app--visible-banner": _setup.hasVisibleBanner,
      },
      attrs: { id: "app" },
    },
    [
      _setup.dbLoaded
        ? _c(
            "div",
            { staticClass: "app-div" },
            [
              _setup.shouldGetKeys
                ? _c(_setup.SecretKey, {
                    attrs: {
                      hasPlugin: _setup.hasPlugin || _setup.shownPlugin,
                    },
                    on: { message: _setup.handleKey },
                  })
                : _c(_setup.MountEvent, { on: { mounted: _setup.appReady } }, [
                    _setup.state.bootReady
                      ? _c(
                          "div",
                          { staticClass: "app-wrapper" },
                          [
                            _c(_setup.AppBanners, {
                              ref: "banner",
                              on: { visible: _setup.setVisibleBanner },
                            }),
                            _setup.shouldShowEncryptionMigrationModal
                              ? _c(_setup.MigrationModalFlow, {
                                  attrs: {
                                    userEncryptionVersion:
                                      _setup.userEncryptionVersion,
                                  },
                                })
                              : _vm._e(),
                            _c(_setup.SearchModal),
                            _c(_setup.RightPanel),
                            _setup.cardsEnabled
                              ? _c(_setup.VirtualCardCreation)
                              : _vm._e(),
                            _c(_setup.NavigationLeftPanel),
                            _c(_setup.NavigationTopBar),
                            _c(_setup.Modal),
                            _c(_setup.Toast),
                            _c(_setup.AutoChangeContainer),
                            _c(
                              "div",
                              {
                                class: [
                                  "content-container",
                                  { "hide-overflow": _setup.shouldClipBody },
                                ],
                              },
                              [
                                _c(_setup.MainNav, {
                                  attrs: {
                                    mobileDevice: _setup.state.mobileDevice,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
              _c("portal-target", { attrs: { name: "root", multiple: "" } }),
              _c("portal-target", { attrs: { name: "modals" } }),
            ],
            1
          )
        : _c(_setup.Loading),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }