var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "search", class: { open: _setup.show } }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "input" }, [
          _c(
            "div",
            { staticClass: "icon" },
            [_c(_setup.SearchIcon, { staticClass: "show" })],
            1
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _setup.search,
                expression: "search",
              },
            ],
            ref: "cInput",
            attrs: {
              "aria-id": "SearchInput",
              maxlength: "50",
              autocomplete: "off",
              "data-lpignore": "true",
              "data-form-type": "other",
              type: "text",
              placeholder: "search cloaked",
            },
            domProps: { value: _setup.search },
            on: {
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _setup.handleEnter.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  )
                    return null
                  return _setup.nav(-1)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  )
                    return null
                  return _setup.nav(1)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _setup.close.apply(null, arguments)
                },
              ],
              input: function ($event) {
                if ($event.target.composing) return
                _setup.search = $event.target.value
              },
            },
          }),
          _setup.search !== ""
            ? _c(
                "div",
                {
                  staticClass: "clear",
                  on: {
                    click: function ($event) {
                      ;[_setup.clearSearch(), _setup.focusSearch()]
                    },
                  },
                },
                [_c(_setup.CloseIcon)],
                1
              )
            : _vm._e(),
        ]),
        _setup.search !== "" && !_setup.state.loading
          ? _c("div", { ref: "scroll_results", staticClass: "results" }, [
              _setup.groups.length > 0
                ? _c(
                    "div",
                    { staticClass: "items" },
                    _vm._l(_setup.groups, function (group, groupIdx) {
                      return _c(
                        "div",
                        { key: group.title },
                        [
                          _c("span", { staticClass: "group_title" }, [
                            _vm._v(_vm._s(group.title)),
                          ]),
                          _vm._l(group.data, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                on: {
                                  click: function ($event) {
                                    return _setup.clickSearchItem(item)
                                  },
                                  mousemove: function ($event) {
                                    return _setup.nav(index, groupIdx, true)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "item",
                                    class: {
                                      active:
                                        _setup.getIdx(index, groupIdx) ===
                                        _setup.state.active,
                                    },
                                    attrs: {
                                      id: `i-${_setup.getIdx(index, groupIdx)}`,
                                    },
                                  },
                                  [
                                    _c(_setup.IdentityIcon, {
                                      attrs: { identity: item },
                                    }),
                                    _c("div", [
                                      _c(
                                        "h1",
                                        { class: { placeholder: !item.n } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _setup.formatNickname(item)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      item.display
                                        ? _c("h3", {
                                            staticClass: "display",
                                            domProps: {
                                              innerHTML: _vm._s(item.display),
                                            },
                                          })
                                        : _vm._e(),
                                      _setup.subheader(item)
                                        ? _c("h4", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_setup.subheader(item)) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _setup.groups.length === 0 && _setup.search !== ""
                ? _c("div", { staticClass: "empty" }, [
                    _c("p", [_vm._v("No results for")]),
                    _c("p", [_vm._v('"' + _vm._s(_setup.search) + '"')]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _c(_setup.SearchFooter, {
          attrs: {
            isVisible:
              _setup.count > 0 && !_setup.state.loading && _setup.search !== "",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "background", on: { click: _setup.close } }, [
      _c("div", { staticClass: "overlay" }),
      _c("div", { staticClass: "blur" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }