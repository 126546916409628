var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.props.field === "email"
    ? _c(_setup.EmailOutlineIcon)
    : _setup.props.field === "phone"
    ? _c(_setup.PhoneOutlineIcon)
    : _setup.props.field === "password"
    ? _c(_setup.KeyIcon)
    : _setup.props.field === "username"
    ? _c(_setup.UserOutlineIcon, { attrs: { height: "16px", width: "16px" } })
    : _setup.props.field === "notes"
    ? _c(_setup.LockShieldIcon)
    : _setup.props.field === "website" || _setup.props.field === "url"
    ? _c(_setup.LinkChainIcon)
    : _setup.props.field === "name"
    ? _c(_setup.PeopleIcon)
    : _setup.props.field === "one-time passcode" ||
      _setup.props.field === "totp_url" ||
      _setup.props.field === "totp_secret"
    ? _c(_setup.TotpIcon)
    : _c(_setup.CustomFieldIcon, { attrs: { type: _setup.props.field } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }