var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "onboarding-input-radio",
      class: { "onboarding-input-radio--checked": _vm.$attrs.checked },
    },
    [
      _c(
        "input",
        _vm._g(
          _vm._b(
            {
              ref: "input",
              staticClass: "onboarding-input-radio__input",
              attrs: { type: "radio", name: "forwarding", id: _vm.id },
              domProps: { value: _vm.id },
            },
            "input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _c("div", { staticClass: "onboarding-input-radio__content" }, [
        _vm.preTitle
          ? _c("span", { staticClass: "onboarding-input-radio__pre-title" }, [
              _vm._v(_vm._s(_vm.preTitle)),
            ])
          : _vm._e(),
        _vm.title
          ? _c(
              "label",
              {
                staticClass: "onboarding-input-radio__title",
                attrs: { for: _vm.id },
              },
              [_vm._v(_vm._s(_vm.title))]
            )
          : _vm._e(),
        _vm.text
          ? _c("span", { staticClass: "onboarding-input-radio__text" }, [
              _vm._v(_vm._s(_vm.text)),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "onboarding-input-radio__image" },
        [_vm._t("image")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }