var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "activity-empty-wrapper" },
    [
      _c("OctagonWarningIcon"),
      _c("h1", [_vm._v("Couldn't find")]),
      _c("h1", [_vm._v('"' + _vm._s(_vm.searchText) + '"')]),
      _c("div", [
        _vm._v("We are sorry, but your search didn’t have any results."),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }