var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "iframe_container-wrapper" }, [
    _c("div", { staticClass: "desktop-view" }, [
      _c(
        "button",
        { staticClass: "logout-button", on: { click: _setup.logout } },
        [_vm._v("logout")]
      ),
      _c(
        "div",
        {
          staticClass: "container-row",
          class: {
            download: _setup.state.download,
            completed: _setup.state.completed,
          },
        },
        [
          _c("span", { staticClass: "dots" }, [
            _c("div", {
              class: {
                selected: !_setup.state.download && !_setup.state.completed,
              },
            }),
            _c("div", {
              class: {
                selected: _setup.state.download && !_setup.state.completed,
              },
            }),
            _c("div", {
              class: {
                selected: _setup.state.download && _setup.state.completed,
              },
            }),
          ]),
          _c("div", { staticClass: "content-wrapper-div iframe-window" }, [
            _c("div", [
              _c("div", { staticClass: "iframe-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "frame",
                    class: { loaded: _setup.state.iframeLoaded },
                  },
                  [
                    _vm._m(0),
                    _c("iframe", {
                      ref: "keywindow",
                      attrs: {
                        src: _setup.src,
                        scrolling: "no",
                        frameborder: "no",
                      },
                      on: { load: _setup.iframeLoader },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "content-wrapper-div download-screen" }, [
            _c("div", { staticClass: "wrapper-padding" }, [
              _c("div", { staticClass: "content" }, [
                _c("div", [
                  _vm._m(1),
                  _c("div", { staticClass: "secret_display" }, [
                    _c("div", [
                      _setup.state.download
                        ? _c("span", [
                            _vm._v(_vm._s(_setup.state.message.data.secretKey)),
                          ])
                        : _vm._e(),
                    ]),
                    _c("span", [_vm._v("Hover to reveal")]),
                  ]),
                  _vm._m(2),
                  _vm._m(3),
                  _c("button", { on: { click: _setup.downloadRecoveryPDF } }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "13",
                          height: "15",
                          viewBox: "0 0 13 15",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M6.1957 10.5967C6.35341 10.5967 6.47793 10.5469 6.62734 10.3975L8.76894 8.34717C8.88515 8.23926 8.95156 8.11475 8.95156 7.95703C8.95156 7.6499 8.71914 7.43408 8.40371 7.43408C8.26259 7.43408 8.11318 7.49219 8.00527 7.6167L7.09218 8.60449L6.74355 8.97803L6.79336 7.99023V1.34961C6.79336 1.03418 6.51943 0.768555 6.1957 0.768555C5.88027 0.768555 5.60634 1.03418 5.60634 1.34961V7.99023L5.65615 8.97803L5.30752 8.60449L4.39443 7.6167C4.28652 7.49219 4.1288 7.43408 3.98769 7.43408C3.67226 7.43408 3.44814 7.6499 3.44814 7.95703C3.44814 8.11475 3.51455 8.23926 3.62246 8.34717L5.76406 10.3975C5.92177 10.5469 6.04629 10.5967 6.1957 10.5967ZM2.47695 14.9297H9.92275C11.3422 14.9297 12.0394 14.2324 12.0394 12.8379V6.45459C12.0394 5.06006 11.3422 4.36279 9.92275 4.36279H8.13808V5.56641H9.90615C10.4955 5.56641 10.8441 5.89014 10.8441 6.5127V12.7798C10.8441 13.4106 10.4955 13.7261 9.90615 13.7261H2.49355C1.89589 13.7261 1.55556 13.4106 1.55556 12.7798V6.5127C1.55556 5.89014 1.89589 5.56641 2.49355 5.56641H4.26162V4.36279H2.47695C1.06582 4.36279 0.36025 5.06006 0.36025 6.45459V12.8379C0.36025 14.2324 1.06582 14.9297 2.47695 14.9297Z",
                            fill: "#FDFDFD",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" Download code phrase "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "footer confirmed" }, [
                _c("button", { on: { click: _setup.complete } }, [
                  _vm._v("Next"),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "content-wrapper-div download-screen completed" },
            [
              _c("div", { staticClass: "wrapper-padding" }, [
                _vm._m(4),
                _c("div", { staticClass: "footer confirmed" }, [
                  _c("button", { on: { click: _setup.notNow } }, [
                    _vm._v("Not Now"),
                  ]),
                  _c("button", { on: { click: _setup.openStore } }, [
                    _vm._v(" Add Cloaked to Browser "),
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "12",
                          height: "12",
                          viewBox: "0 0 12 12",
                          fill: "#171717",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M11.3994 8.96436L11.3901 1.72803C11.3901 1.25488 11.084 0.920898 10.583 0.920898H3.34668C2.88281 0.920898 2.56738 1.28271 2.56738 1.68164C2.56738 2.08057 2.91992 2.42383 3.31885 2.42383H5.46191L9.13574 2.29395L7.73486 3.49072L0.823242 10.4209C0.674805 10.5693 0.591309 10.7642 0.591309 10.9404C0.591309 11.3394 0.953125 11.729 1.37061 11.729C1.56543 11.729 1.75098 11.6548 1.89941 11.5063L8.82031 4.57617L10.0356 3.17529L9.87793 6.82129V9.00146C9.87793 9.39111 10.2305 9.76221 10.6387 9.76221C11.0376 9.76221 11.3994 9.41895 11.3994 8.96436Z",
                            fill: "#FDFDFD",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "loader" }, [
      _c("span", { staticClass: "spin-loader" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h1", [
      _vm._v(" Seriously... "),
      _c("br"),
      _vm._v(" Don’t lose this code phrase: "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _c("strong", [_vm._v(" We don’t store your code phrase.")]),
      _vm._v(
        " If you lose it, you won’t be able to sign in to your Cloaked account on new devices or restore your account if you forget your password. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _c("strong", [_vm._v(" Never disclose your secret code.")]),
      _vm._v(
        " Anyone with this information will be able to gain access to your Cloaked account. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "wrapper-padding" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", {
          staticClass: "img",
          staticStyle: { "background-image": "url(/images/signup-step3.png)" },
        }),
        _c("h1", [_vm._v("Get the most from cloaked")]),
        _c("p", [
          _vm._v(
            " Install Cloaked’s browser extension to create and save cloaked emails, phone numbers, and passwords on your favorite sites. "
          ),
        ]),
        _c("strong", [
          _vm._v(
            " For the best experience, we highly recommend you download the cloaked browser extension. "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }