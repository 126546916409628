var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "forwarding-phone" },
    [
      _vm.step < 6 && _vm.step >= 3
        ? _c("Phone")
        : _vm.step === 6
        ? _c("PhoneDone", { staticClass: "forwarding-phone__done" })
        : _vm._e(),
      _vm.step === 3
        ? _c(
            "AppModalTitle",
            { staticClass: "forwarding-phone__choice-title" },
            [
              _vm._v(
                " Where should calls sent to your Cloaked phone numbers go? "
              ),
            ]
          )
        : _vm.step === 4
        ? _c("AppModalTitle", [_vm._v(" Add a phone number ")])
        : _vm.step === 5
        ? _c("AppModalTitle", [_vm._v(" Verify your phone number ")])
        : _vm.step === 6
        ? _c("AppModalTitle", { staticClass: "forwarding-phone__verified" }, [
            _vm._v(" Your phone number has been verified "),
          ])
        : _vm._e(),
      _vm.step === 4
        ? _c("AppModalParagraph", [
            _vm._v(
              " Calls made to your Cloaked phone numbers will be routed to this phone number "
            ),
          ])
        : _vm.step === 5
        ? _c("AppModalParagraph", [
            _vm._v(" Enter the 6-digit code sent to "),
            _c("strong", [_vm._v(_vm._s(_vm.phone))]),
            _c("br"),
            _c(
              "button",
              {
                staticClass: "forwarding-phone__resend",
                on: {
                  click: function ($event) {
                    return _vm.$emit("resend")
                  },
                },
              },
              [_vm._v(" Resend code ")]
            ),
          ])
        : _vm.step === 6
        ? _c(
            "AppModalParagraph",
            { staticClass: "forwarding-phone__verified-subtitle" },
            [
              _vm._v(
                " Calls made to your Cloaked phone numbers will be routed to "
              ),
              _c("strong", [_vm._v(_vm._s(_vm.phone))]),
              _vm._v(
                ". You can change this setting for individual Cloaked identities. "
              ),
            ]
          )
        : _vm._e(),
      _vm.step === 4
        ? _c("OnboardingInputPhone", {
            attrs: { value: _vm.phone, placeholder: "Phone number" },
            on: {
              input: function ($event) {
                return _vm.$emit("input-phone", $event)
              },
            },
          })
        : _vm.step === 5
        ? _c(
            "OnboardingInputCode",
            _vm._g(
              {
                attrs: { value: _vm.phoneCode },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input-phone-code", $event)
                  },
                },
              },
              _vm.$listeners
            )
          )
        : _vm._e(),
      _vm.step === 3
        ? _c("div", [
            _c(
              "fieldset",
              { staticClass: "forwarding-phone__forwarding-choice" },
              [
                _c("OnboardingInputRichRadio", {
                  ref: "radioOne",
                  attrs: {
                    checked: _vm.phoneForwarding === "cloaked",
                    "pre-title": "RECOMMENDED",
                    title: "Keep texts in Cloaked",
                    text: "Texts only go to your Cloaked inbox",
                    id: "cloaked",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$emit(
                        "input-phone-forwarding",
                        $event.target.value
                      )
                    },
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      )
                        return null
                      return _vm.$emit("input-phone-forwarding", "phone")
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "image",
                        fn: function () {
                          return [_c("PhoneForwardingCloaked")]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2585667139
                  ),
                }),
                _c("OnboardingInputRichRadio", {
                  attrs: {
                    title: "Forward texts to your personal phone number",
                    text: "Texts go to your Cloaked inbox and are also forwarded to your personal\n        phone number",
                    id: "phone",
                    checked: _vm.phoneForwarding === "phone",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$emit(
                        "input-phone-forwarding",
                        $event.target.value
                      )
                    },
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      )
                        return null
                      return _vm.$emit("input-phone-forwarding", "cloaked")
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "image",
                        fn: function () {
                          return [_c("PhoneForwardingPersonal")]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1924902832
                  ),
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.step === 4
        ? _c(
            "PreferencesCheckParagraph",
            {
              staticClass: "forwarding-phone__consent",
              attrs: { value: _vm.phoneForwardingConsent, tabindex: "0" },
              on: {
                input: function ($event) {
                  return _vm.$emit("input-phone-forwarding-consent", $event)
                },
              },
              nativeOn: {
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.$emit(
                      "input-phone-forwarding-consent",
                      !_vm.phoneForwardingConsent
                    )
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    )
                      return null
                    return _vm.$emit(
                      "input-phone-forwarding-consent",
                      !_vm.phoneForwardingConsent
                    )
                  },
                ],
              },
            },
            [
              _vm._v(
                " I understand that Cloaked will forward calls from third parties to the phone number I provide. "
              ),
              _c(
                "a",
                {
                  staticClass: "forwarding-phone__consent-link",
                  attrs: {
                    href: "https://cloaked.app/privacy-policy",
                    target: "_blank",
                  },
                },
                [_vm._v("Learn more")]
              ),
              _vm._v(". "),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }