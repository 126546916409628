var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "auto-change-state" },
    [
      _c(_setup.AutoChangeModalStart, {
        attrs: {
          identities:
            _vm.$store.getters["autoChange/getAutoChangeRequests"]?.length ?? 0,
        },
        on: {
          "auto-change": function ($event) {
            return _vm.$store.dispatch("autoChange/startAutoChange")
          },
        },
        model: {
          value: _setup.isAutoChangeModalStartOpen,
          callback: function ($$v) {
            _setup.isAutoChangeModalStartOpen = $$v
          },
          expression: "isAutoChangeModalStartOpen",
        },
      }),
      _c(_setup.AutoChangeModalComplete, {
        attrs: {
          success:
            _setup.identityStatuses.filter(
              (item) => item.status === _setup.AutoChangeStatus.SUCCESS
            ).length ?? 0,
          error:
            _setup.identityStatuses.filter(
              (item) => item.status === _setup.AutoChangeStatus.ERROR
            ).length ?? 0,
          skipped:
            _setup.identityStatuses.filter(
              (item) => item.status === _setup.AutoChangeStatus.SKIPPED
            ).length ?? 0,
        },
        on: {
          review: function ($event) {
            _vm.$route.name !== "AutoCloak" &&
              _vm.$router.push({ name: "AutoCloak" })
          },
        },
        model: {
          value: _setup.isAutoChangeModalCompleteOpen,
          callback: function ($$v) {
            _setup.isAutoChangeModalCompleteOpen = $$v
          },
          expression: "isAutoChangeModalCompleteOpen",
        },
      }),
      _vm.$store.getters["autoChange/isInProgress"]
        ? _c(_setup.AutoChangeSpinner, {
            staticClass: "auto-change-state__spinner",
            attrs: { status: _setup.spinnerStatus },
            on: {
              click: function ($event) {
                _vm.$route.name !== "AutoCloak" &&
                  _vm.$router.push({ name: "AutoCloak" })
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }