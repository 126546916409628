export const coordinateOverlaps = (area, target) => {
  const container = [
    [target.left, target.top],
    [target.left, target.bottom],
    [target.right, target.top],
    [target.right, target.bottom],
  ];

  const contained = [
    [area.left, area.top],
    [area.left, area.bottom],
    [area.right, area.top],
    [area.right, area.bottom],
  ];

  let intersect = false;
  container.map((i) => {
    const [x, y] = i;
    if (x > area.left && y > area.top && x < area.right && y < area.bottom) {
      intersect = true;
    }
  });
  contained.map((i) => {
    const [x, y] = i;
    if (
      x > target.left &&
      y > target.top &&
      x < target.right &&
      y < target.bottom
    ) {
      intersect = true;
    }
  });
  /// is area between target left and right
  if (
    target.left < area.right &&
    target.left < area.left &&
    target.right > area.left &&
    target.top < area.bottom &&
    target.top > area.top
  ) {
    intersect = true;
  }
  if (
    target.top < area.bottom &&
    target.top < area.top &&
    target.bottom > area.top &&
    target.left < area.right &&
    target.left > area.left
  ) {
    intersect = true;
  }
  /// is area between target top and bottom
  return intersect;
};
