var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "cloak-contact centered-flex-row",
      class: { "cloak-contact--menu-open": _setup.isMenuOpen },
    },
    [
      _c(
        "div",
        { staticClass: "centered-flex-row" },
        [
          _c(_setup.ContactIcon, {
            attrs: {
              showBlockIcon:
                _vm.contact.status === _setup.constants.CONTACT_STATUS.BLOCKED,
              initials: _setup.getContactInitialsDisplay(_vm.contact),
              override: { width: "24px", height: "24px" },
            },
          }),
          _c("h5", [_vm._v(_vm._s(_setup.getContactDisplayName(_vm.contact)))]),
        ],
        1
      ),
      _vm.contact.status !== _setup.constants.CONTACT_STATUS.BLOCKED
        ? _c(
            _setup.UiMenu,
            {
              attrs: { width: "188px", placement: "bottom-end" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c(_setup.UiMenuButton, {
                          attrs: { title: "Edit" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("edit", {
                                contactId: _vm.contact.id,
                                contactType: _vm.contact?.original_phone
                                  ? _setup.constants.CONTACT_TYPE.PHONE
                                  : _setup.constants.CONTACT_TYPE.EMAIL,
                              })
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [_c(_setup.EditPencilIcon)]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2452148809
                          ),
                        }),
                        _c(_setup.UiMenuButton, {
                          attrs: { title: "Copy" },
                          on: {
                            click: function ($event) {
                              return _setup.copy(
                                _vm.contact?.original_phone &&
                                  _vm.contact?.cloak_contact_phone
                                  ? _vm.contact.cloak_contact_phone
                                  : _vm.contact.cloaked_contact_email
                              )
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [_c(_setup.CopyIcon)]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            301276141
                          ),
                        }),
                        _c(_setup.UiMenuSeparator),
                        _c(_setup.UiMenuButton, {
                          attrs: { title: "Block" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("change-status", {
                                contactId: _vm.contact.id,
                                contactType: _vm.contact?.original_phone
                                  ? _setup.constants.CONTACT_TYPE.PHONE
                                  : _setup.constants.CONTACT_TYPE.EMAIL,
                                newStatus:
                                  _setup.constants.CONTACT_ACTION.BLOCK,
                              })
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [_c(_setup.BlockContact)]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1820936074
                          ),
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                720146772
              ),
              model: {
                value: _setup.isMenuOpen,
                callback: function ($$v) {
                  _setup.isMenuOpen = $$v
                },
                expression: "isMenuOpen",
              },
            },
            [
              _c(
                _setup.CloakInfoRowButton,
                {
                  staticClass: "mini-btn",
                  attrs: { icon: "", active: _setup.isMenuOpen },
                },
                [_c(_setup.KabobIcon)],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.contact.status === _setup.constants.CONTACT_STATUS.BLOCKED
        ? _c(
            _setup.Button,
            {
              staticClass: "unblock-button",
              on: {
                click: function ($event) {
                  return _vm.$emit("change-status", {
                    contactId: _vm.contact.id,
                    contactType: _vm.contact?.original_phone
                      ? _setup.constants.CONTACT_TYPE.PHONE
                      : _setup.constants.CONTACT_TYPE.EMAIL,
                    newStatus: _setup.constants.CONTACT_ACTION.APPROVE,
                  })
                },
              },
            },
            [_vm._v(" Unblock ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }