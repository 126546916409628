var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "expansion-panel" }, [
    _c(
      "div",
      [
        _c(
          "span",
          { staticClass: "button-label", on: { click: _vm.handleOnClick } },
          [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
        ),
        _c("ArrowButton", {
          staticClass: "dark-arrow",
          attrs: { onClick: _vm.handleOnClick, isOpen: _vm.isOpen },
        }),
      ],
      1
    ),
    _c(
      "div",
      { style: _vm.contentStyles },
      [
        _vm._t("default", function () {
          return [
            _vm._v(
              " if you see me, you need to add content to the expansion panel component "
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }