var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "activity-sender" },
    [
      _c("ProfileIcon", {
        staticClass: "profile-icon clickable",
        class: { inbound: _vm.inbound },
        on: {
          click: function ($event) {
            return _vm.$emit("openCloak")
          },
        },
      }),
      _c(
        "div",
        {
          staticClass: "clickable",
          on: {
            click: function ($event) {
              return _vm.$emit("openCloak")
            },
          },
        },
        [_vm._v(_vm._s(_vm.senderDisplay))]
      ),
      _c("div", { staticClass: "activity-time" }, [
        _vm._v(_vm._s(_vm.timeDisplay)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }