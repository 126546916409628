var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesPanel",
    {
      staticClass: "panel-offset",
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("PreferencesHeader", { on: { "go-back": _vm.toggle_back } }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("PreferencesTitle", [_vm._v("Maintain your account access")]),
      _c("PreferencesParagraph", [
        _vm._v(
          " These can be used to make sure it's really you signing in, reach you if there's suspicious activity in your account, or recover a lost password. "
        ),
      ]),
      _c(
        "div",
        { staticClass: "section forward-panel" },
        [
          _c("AccountRecoveryPhone", {
            attrs: { phones: _vm.phones },
            on: {
              toggleBack: _vm.toggle_back,
              refresh: _vm.refreshPhones,
              delete: (phoneId) => _vm.deletePhone(phoneId),
            },
          }),
          _c("AccountRecoveryEmail", {
            attrs: { emails: _vm.emails },
            on: {
              toggleBack: _vm.toggle_back,
              refresh: _vm.refreshEmails,
              delete: (emailId) => _vm.deleteEmail(emailId),
            },
          }),
          _vm.showRecoveryPassphraseSection
            ? _c("ValueDisplay", {
                attrs: { label: "Download recovery passphrase" },
                on: { click: _vm.goToRecoveryPassphrase },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }