var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "onboarding-input-code",
      class: { "onboarding-input-code--focused": _vm.isFocused },
    },
    [
      _c(
        "input",
        _vm._b(
          {
            ref: "input",
            staticClass: "onboarding-input-code__input",
            attrs: { type: "number" },
            on: {
              focus: function ($event) {
                _vm.isFocused = true
              },
              blur: function ($event) {
                _vm.isFocused = false
              },
              input: _vm.onInput,
              keydown: function ($event) {
                return _vm.$emit("keydown", $event)
              },
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _c(
        "span",
        { staticClass: "onboarding-input-code__background" },
        [
          _c("span", {
            staticClass: "onboarding-input-code__focus",
            style: _vm.focusStyle,
          }),
          _vm._l(6, function (i) {
            return _c(
              "span",
              {
                key: i,
                staticClass: "onboarding-input-code__placeholder",
                class: {
                  "onboarding-input-code__placeholder--focused":
                    i - 1 === Math.min(_vm.$attrs.value.length, 5) &&
                    _vm.isFocused,
                  "onboarding-input-code__placeholder--large-font":
                    _vm.largeFont,
                },
              },
              [_vm._v(" " + _vm._s(_vm.$attrs.value[i - 1]) + " ")]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }