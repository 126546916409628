import "vue-tel-input/dist/vue-tel-input.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

import VueTelInput from "vue-tel-input";
import Multiselect from "vue-multiselect";
import VueObserveVisibility from "vue-observe-visibility";
import PortalVue from "portal-vue";
import { useToast } from "@/hooks";

const ENVS = [
  "VUE_APP_API",
  "VUE_APP_PENDO",
  "VUE_APP_SECRET",
  "VUE_APP_CLIENT_ID",
  "VUE_APP_STRIPE",
  "VUE_APP_API_DIRECT",
  "VUE_APP_EXTENSION_ID",
  "VUE_APP_REDIRECT_URI",
  "VUE_APP_RADAR_PUBLIC",
  "VUE_APP_ENCRYPTION_ENABLE",
  "VUE_APP_STRIPE_PAYLINK",
];

const map_env = (env) => {
  ENVS.map((env_var) => {
    if (
      !global.ENV[env_var] &&
      (process.env[`${env_var}_${env}`] || process.env[`${env_var}`])
    ) {
      global.ENV[env_var] =
        process.env[`${env_var}_${env}`] || process.env[`${env_var}`];
    }
  });
};

global.ENV = {};
if (process.env.NODE_ENV) {
  if (process.env.NODE_ENV === "main") {
    map_env("PRODUCTION");
  } else if (process.env.NODE_ENV === "staging") {
    map_env("STAGING");
  } else {
    map_env("DEVELOP");
  }
} else {
  map_env("DEVELOP");
}

import Vue from "vue";
import App from "./App.vue";
import router from "@/routes/router";
import store from "./store";
import axios from "axios";

Vue.config.productionTip = false;

Vue.use({
  install(Vue) {
    const toast = useToast();
    Vue.$toast = toast;
    Vue.prototype.$toast = toast;
  },
});
Vue.use(VueObserveVisibility);
Vue.use(VueTelInput);
Vue.use(PortalVue);
Vue.component("multiselect", Multiselect);

Vue.directive("net-active", {
  bind: function (el, binding) {
    el.addEventListener(binding.value || "mousedown", () => {
      el.classList.add("net-active");
      store.commit("postGetListen", () => {
        setTimeout(() => {
          el.classList.remove("net-active");
        }, 500);
      });
    });
  },
});

window.$vue = new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    this.$store.dispatch("colorScheme/detectColorScheme");
  },
}).$mount("#app");

axios.interceptors.request.use(
  (config) => {
    config.headers[
      "Authorization"
    ] = `Bearer ${store.state.account.access_token}`;
    return Promise.resolve(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);
