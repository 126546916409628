<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router/composables";

const route = useRoute();

const hidePadding = computed(() => {
  return route.name.includes("setting");
});
</script>

<template>
  <main :class="{ hidePadding }">
    <router-view ref="router"></router-view>
  </main>
</template>

<style lang="scss" scoped>
main {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-left: 240px;

  body.active-right-panel & {
    @media (min-width: 1024px) {
      width: calc(100% - 400px);
    }
  }

  body.wallet & {
    width: 100% !important;
  }
}

.app--visible-banner {
  main {
    // initial padding plus banner height
    padding-top: calc(65px + 34px);
  }
}

.hidePadding {
  padding: 0 !important;
}
</style>
