<template>
  <PreferencesPanel class="your-pref-top">
    <template v-slot:header>
      <PreferencesHeader @go-back="toggle_back" />
    </template>
    <PreferencesTitle :big="true">{{ title }}</PreferencesTitle>
    <PreferencesInput
      label="Cardholder name"
      :error="hasError('cardholder_name')"
      tabindex="1"
      v-model="card.name"
    />

    <div class="cc_input">
      <div>
        <PreferencesInput
          :pattern="/[0-9]/g"
          label="Card number"
          :error="hasError('card_number')"
          :format="formatCardNumber"
          :max="maxLength"
          tabindex="2"
          v-model="card.card_number"
          @blur="validateCardNumber"
          @focus="clearErrorType('card_number')"
          errorMessage="Not a valid card number"
        />
        <CardLogo :cardType="cardType" />
      </div>
      <div>
        <PreferencesInput
          label="Exp date"
          :error="hasError('expiry_date')"
          :pattern="/[0-9]/g"
          tabindex="3"
          :max="5"
          :format="formatCardExp"
          v-model="card.expiry_date"
          @blur="validateCardExp"
          @focus="clearErrorType('expiry_date')"
          errorMessage="Not a valid expiration date"
        />
      </div>
      <div>
        <PreferencesInput
          label="Security code"
          :pattern="/[0-9]/g"
          :error="hasError('cvv')"
          :format="formatCardCVV"
          :max="4"
          tabindex="4"
          v-model="card.cvv"
          @blur="validateCardCvv"
          @focus="clearErrorType('cvv')"
          errorMessage="Not a valid security code"
        />
      </div>
    </div>
    <PreferencesTitle :big="true">Billing address (optional)</PreferencesTitle>
    <PreferencesInput label="Name" tabindex="5" v-model="address.name" />
    <EditAddress
      :key="id"
      :id="id"
      :errors="errors"
      ref="editAddressRef"
      label="address"
      :current="addressPayload"
      name="address"
      @update="updateAddress"
    />

    <PreferencesFooter>
      <Button
        :class="{ disabled: disabled }"
        :disabled="loading"
        :loading="loading"
        @click="save"
        >{{ !!selectedCard ? "Saving changes" : "Save" }}</Button
      >
      <Button
        v-if="selectedCard"
        :class="{ disabled: disabled }"
        :disabled="processingDelete"
        :loading="processingDelete"
        type="danger"
        @click="openDeleteModal"
        >Delete</Button
      >
    </PreferencesFooter>
  </PreferencesPanel>
</template>

<script>
import creditCardType, { types as CardType } from "credit-card-type";
import moment from "moment";

import {
  PreferencesHeader,
  PreferencesFooter,
  PreferencesPanel,
  PreferencesTitle,
  PreferencesInput,
} from "@/routes/modals/preferences";

import EditAddress from "./EditAddress";

import { Button, CardLogo } from "@/components";

import PersonalInfoService from "@/api/settings/personal-services";

export default {
  name: "Payment",
  props: {
    id: { default: "" },
    selectedCard: { type: Object, default: null },
    creditCards: { type: Array, default: () => [] },
  },
  data() {
    return {
      cards: [],
      errors: [],
      card: {
        name: null,
        card_number: null,
        cvv: null,
        expiry_date: null,
      },
      address: {
        autofill_street_address: null,
        autofill_unit: null,
        autofill_country: null,
        autofill_address_level2: null,
        autofill_address_level1: null,
        autofill_postal_code: null,
      },
      loading: false,
      processingDelete: false,
    };
  },
  components: {
    PreferencesHeader,
    PreferencesFooter,
    PreferencesTitle,
    PreferencesInput,
    EditAddress,
    PreferencesPanel,
    Button,
    CardLogo,
  },
  mounted() {
    if (this.selectedCard) {
      this.card = {
        ...this.card,
        ...this.selectedCard,
        name: this.selectedCard?.cardholder_name,
      };
      this.address = {
        name: this.selectedCard?.billing_name,
        autofill_street_address: this.selectedCard?.billing_address_1,
        autofill_unit: this.selectedCard?.billing_address_2,
        autofill_address_level2: this.selectedCard?.billing_city,
        autofill_address_level1: this.selectedCard?.billing_state,
        autofill_postal_code: this.selectedCard?.billing_zip,
        autofill_country: this.selectedCard?.billing_country,
      };
    }
  },
  computed: {
    title() {
      return this.selectedCard
        ? "Edit card details"
        : "Add a credit or debit card";
    },
    maxLength() {
      const card = this.getCard;
      if (card) {
        return Math.max(...card.lengths) + card.gaps.length;
      }
      return 19;
    },
    addressPayload() {
      return {
        autofill_street_address: this.address.autofill_street_address,
        autofill_unit: this.address.autofill_unit,
        autofill_country: this.address.autofill_country,
        autofill_address_level2: this.address.autofill_address_level2,
        autofill_address_level1: this.address.autofill_address_level1,
        autofill_postal_code: this.address.autofill_postal_code,
      };
    },
    savePayload() {
      return {
        ...this.card,
        cardholder_name: this.card.name,
        card_type: this.cardType,
        last_4_digits:
          this.card.card_number &&
          this.card.card_number.slice(
            this.card.card_number.length - 4,
            this.card.card_number.length
          ),
        billing_name: this.address.name,
        billing_address_1: this.address.autofill_street_address,
        billing_address_2: this.address.autofill_unit || "",
        billing_city: this.address.autofill_address_level2,
        billing_state: this.address.autofill_address_level1,
        billing_zip: this.address.autofill_postal_code,
        billing_country: this.address.autofill_country,
      };
    },
    getCard() {
      const types = creditCardType(this.card.card_number).filter((card) => {
        return (
          card.type === CardType.MASTERCARD ||
          card.type === CardType.VISA ||
          card.type === CardType.AMERICAN_EXPRESS ||
          card.type === CardType.DISCOVER ||
          card.type === CardType.DINERS_CLUB ||
          card.type === CardType.HIPERCARD
        );
      });
      if (types.length === 1) {
        return types[0];
      }
      return null;
    },
    cardType() {
      const card = this.getCard;
      return card && card.type;
    },
    cardIsValid() {
      const card = this.getCard;
      if (card) {
        if (this.savePayload.card_number) {
          const number = this.savePayload.card_number.replaceAll(" ", "");
          if (number !== "") {
            if (card.lengths.includes(number.length)) {
              return true;
            }
          }
        }
      }
      return false;
    },
    disabled() {
      if (this.cardIsValid && this.cardType) {
        return false;
      }
      return true;
    },
  },
  methods: {
    openDeleteModal() {
      this.processingDelete = true;
      return this.$store.dispatch("openModal", {
        header: `Delete card ending in ${this.card.last_4_digits}?`,
        subheader:
          "Deleting this card will remove it from your account and autofill information.",
        button: {
          text: "Yes, delete",
          danger: true,
          onClick: this.deleteCard,
        },
        cancelAction: () => {
          this.processingDelete = false;
        },
      });
    },
    deleteCard() {
      PersonalInfoService.deleteAutofillCard(this.card.url)
        .then(() => {
          this.$emit("refresh");
          this.$emit("back", "Credit card deleted.");
          this.processingDelete = false;
        })
        .catch(() => {
          this.$toast.error("Error deleting credit card");
          this.processingDelete = false;
        });
    },
    validateCardNumber() {
      const cardTypeData = this.getCard;
      const cardNumFormatted = this.card?.card_number?.split(" ").join("");
      const cardIsValidLength = cardTypeData?.lengths.includes(
        cardNumFormatted.length
      );
      const errorIncludesCardNum = this.errors.includes("card_number");
      if (!cardIsValidLength && !errorIncludesCardNum) {
        this.errors = [...this.errors, "card_number"];
      }
    },
    validateCardCvv() {
      const cardTypeData = this.getCard;
      let cardIsValidLength = false;

      if (this.card?.cvv?.length) {
        cardIsValidLength = cardTypeData?.code?.size === this.card?.cvv?.length;
      }

      const errorIncludesCardCvv = this.errors.includes("cvv");
      if (!cardIsValidLength && !errorIncludesCardCvv) {
        this.errors = [...this.errors, "cvv"];
      }
    },
    validateCardExp() {
      const errorIncludesCardExp = this.errors.includes("expiry_date");
      let cardExpIsValid = false;
      if (this.card?.expiry_date) {
        cardExpIsValid =
          moment().format("x") <
          moment(this.card.expiry_date, "MM/YY").format("x");
      }
      if (!cardExpIsValid && !errorIncludesCardExp) {
        this.errors = [...this.errors, "expiry_date"];
      }
    },
    async fetchCardDetails() {
      const cardId = this.selectedCard?.id;
      if (cardId) {
        const cardNumResp = await PersonalInfoService.getAutofillCardNumber(
          cardId
        );
        const cvvResp = await PersonalInfoService.getAutofillCardCvv(cardId);
        const expResp = await PersonalInfoService.getAutofillCardExp(cardId);
        this.card = {
          ...this.card,
          card_number: cardNumResp?.card_number,
          cvv: cvvResp?.cvv,
          expiry_date: expResp?.expiry_date,
        };
      }
    },
    clearErrorType(errorType) {
      if (this.errors.includes(errorType)) {
        this.errors = this.errors.filter((err) => err !== errorType);
      }
    },
    formatCardNumber(value) {
      const card = this.getCard;
      if (value && card) {
        let data = value.replaceAll(/[^0-9]/g, "").split("");
        card.gaps.reverse().map((d) => {
          if (data.length > d) {
            data.splice(d, 0, " ");
          }
        });
        return data.join("");
      }

      return value;
    },
    formatCardCVV(value) {
      if (value) {
        return value.replaceAll(/[^0-9]/g, "").slice(0, 4);
      }
      return value;
    },
    formatCardExp(value) {
      if (value) {
        const match = value
          .replaceAll(/[^0-9]/g, "")
          .slice(0, 4)
          .match(/.{1,2}/g);
        return match.join("/");
      }
      return value;
    },
    updateAddress({ name, value }) {
      this.address = {
        ...this.address,
        [name]: value,
      };
    },
    hasError(field) {
      return this.errors.includes(field);
    },
    cardIsDuplicate() {
      let isDupe = false;
      let cards = this.creditCards;
      if (this.selectedCard) {
        cards = cards.filter((c) => c.id !== this.selectedCard.id);
      }

      cards.forEach((card) => {
        if (!isDupe) {
          const expMatch = card.expiry_date === this.savePayload.expiry_date;
          const cvvMatch = card.cvv === this.savePayload.cvv;
          const cardNumberMatch =
            card.card_number === this.savePayload.card_number;
          if (expMatch && cvvMatch && cardNumberMatch) {
            isDupe = true;
          }
        }
      });

      return isDupe;
    },
    checkHasErrors() {
      this.error = [];
      let errors = [];
      const required = ["card_number", "expiry_date"];
      required.map((k) => {
        if (!this.savePayload[k]) {
          errors.push(k);
        }
      });
      if (this.disabled) {
        errors.push("card_number");
      }
      if (this.cardIsDuplicate()) {
        return true;
      }
      if (errors.length > 0) {
        this.errors = errors;
        return true;
      }
      return false;
    },
    successfullySaved() {
      this.$emit("refresh");
      this.$emit("back", "Credit card saved.");
    },
    errorSaving() {
      this.$emit("refresh");
      this.$emit("back", "Credit card failed.");
    },
    async save() {
      if (!this.checkHasErrors()) {
        if (!this.disabled) {
          this.loading = true;
          if (this.selectedCard) {
            return PersonalInfoService.updateAutofillCard(
              this.selectedCard?.id,
              this.savePayload
            )
              .then(this.successfullySaved)
              .catch(this.errorSaving);
          }
          return PersonalInfoService.postAutofillCard(this.savePayload)
            .then(this.successfullySaved)
            .catch(this.errorSaving);
        } else {
          this.$toast.error(
            "Error saving card. Valid credit card number required."
          );
        }
      } else {
        const errorMessage = this.cardIsDuplicate()
          ? "Card already exists"
          : "Error saving card. Enter required fields.";
        this.$toast.error(errorMessage);
      }
    },

    toggle_back() {
      this.$emit("toggleBack");
    },
  },
};
</script>
<style lang="scss" scoped>
.preferences-title {
  font-family: $poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
}
.disabled {
  opacity: 0.5;
}
.preferences-input {
  margin: 15px auto;
}
.cc_input {
  display: grid;
  gap: 10px;
  grid-template-columns: 55% 20% 20%;
  align-items: flex-start;
  margin: 15px 0;
  > div:first-child {
    position: relative;
    > div:nth-child(2) {
      position: absolute;
      background-color: $white;
    }
  }
}
</style>
