<script setup>
import Eye from "@/assets/icons/eye.svg";
import EyeSlash from "@/assets/icons/eye-slash.svg";
import { defineProps } from "vue";

defineProps({
  value: {
    type: Boolean,
    required: true,
  },
});
</script>

<template>
  <button @click="$emit('input', !value)" class="secret-toggle">
    <Eye class="secret-toggle__icon" v-if="value" />
    <EyeSlash class="secret-toggle__icon" v-else />
  </button>
</template>

<style lang="scss">
.secret-toggle {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;

  &__icon {
    cursor: pointer;
    box-sizing: content-box;
    flex-shrink: 0;
    color: $color-primary-100;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
