<script setup>
import store from "@/store";

function upgradeModal() {
  store.dispatch("subscription/openSubscriptionModal");
}
</script>

<template>
  <section>
    <span
      >Your free trial has expired.
      <a @click="upgradeModal">Upgrade now</a></span
    >
  </section>
</template>

<style lang="scss" scoped>
section {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  background: linear-gradient(
      to right,
      $color-info 0%,
      darken($color-info, 5%) 70%,
      lighten($color-info, 5%) 100%
    )
    top right no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $white;

    a {
      color: $white;
      text-decoration: underline;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
</style>
