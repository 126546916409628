<script setup>
import _ from "lodash";
import { generatePkceRequirements } from "@/scripts/actions/auth";
import MountEvent from "@/components/MountEvent";
import UserService from "@/api/actions/user-service";
import { headers } from "@/api/api";
import store from "@/store";
import { loadSupport, toggleSupport } from "@/scripts/cloakHelpers";

import { defineProps, ref, onUnmounted, nextTick, computed } from "vue";

import router from "@/routes/router";
import { useRoute } from "vue-router/composables";

const route = useRoute();

const props = defineProps({
  source: {
    type: String,
    required: true,
  },
  id: {
    type: String,
  },
});

const zendeskLoading = ref(false);
const intercept = ref(false);
const challenge = ref(null);
const verifier = ref(null);
const path = ref(props.source);
const keywindow = ref(null);

function setHistory(pathValue) {
  const params = Object.keys(route.query).map((k) => `${k}=${route.query[k]}`);
  const query = params.join("&");
  window.history.pushState(
    {},
    null,
    query ? `${pathValue}?${query}` : pathValue
  );
}

function popstate(event) {
  event.preventDefault();
  if (intercept.value) {
    if (
      confirm(
        "Are you sure you want to exit? You will lose your current progress"
      )
    ) {
      intercept.value = false;
      // why is this source and not path?
      setHistory(props.source);
      nextTick(() => {
        window.location.reload();
      });
    }
  } else {
    const [, pathValue] = window.location.href.match(/(auth\/[^/?]+)/);
    setHistory(pathValue);
  }
}

onUnmounted(() => {
  window.removeEventListener("message", iframeListener);
  window.removeEventListener("popstate", popstate);
});

function onMount() {
  window.addEventListener("beforeunload", (event) => {
    if (intercept.value) {
      event.preventDefault();
      intercept.value = false;
      event.returnValue = "";
    }
  });
  window.addEventListener("popstate", popstate);
  nextTick(() => {
    generatePkceRequirements().then(([verifierValue, challengeValue]) => {
      challenge.value = challengeValue;
      verifier.value = verifierValue;
    });
  });
  window.addEventListener("message", iframeListener);
  loadSupport();
}

function openZendek() {
  zendeskLoading.value = true;
  toggleSupport().then(() => {
    zendeskLoading.value = false;
  });
}
async function iframeListener(message) {
  const childWindow = keywindow?.value?.contentWindow;

  if (message.source === childWindow) {
    if (
      ["login-success", "migration-completed", "remind-later"].includes(
        message.data.event
      )
    ) {
      await store.dispatch("authentication/setAuthPayload", {
        payload: message.data.data,
        codeVerifier: verifier.value,
      });

      await Promise.all([
        store.dispatch("authentication/getUser"),
        UserService.getFlags(),
      ]);

      router.push({ path: "/" });
    }
    if (message.data.event === "url-change") {
      if (!window.location.href.match(new RegExp(message.data.data))) {
        setHistory(message.data.data);
      }
    }
    if (message.data.event === "user_is_editing") {
      intercept.value = true;
    }
    return;
  }
}

const src = computed(() => {
  const queries = Object.keys(route.query).map((k) => {
    return `${k}=${route.query[k]}`;
  });
  const getHeaders = headers();
  delete getHeaders["Authorization"];
  delete getHeaders["content-type"];
  const params = [
    `cloaked_client_id=${global.ENV.VUE_APP_CLIENT_ID}`,
    `cloaked_code_challenge=${challenge.value}`,
    `secret=${global.ENV.VUE_APP_SECRET}`,
    `cloaked_redirect_uri=${encodeURIComponent(
      global.ENV.VUE_APP_REDIRECT_URI
    )}`,
    ...queries,
  ];
  Object.keys(getHeaders).map((k) => {
    params.push(`${_.snakeCase(k)}=${getHeaders[k]}`);
  });

  let newPath = path.value;
  if (newPath.includes("auth/register")) {
    newPath = "auth/signup";
  }
  newPath = newPath.replace("/auth", "auth");
  return `${window.ENV.VUE_APP_API}${newPath}/?${params.join("&")}`;
});
</script>

<template>
  <MountEvent @mounted="onMount">
    <iframe
      :id="props.id || path"
      :key="path"
      v-if="challenge"
      ref="keywindow"
      :src="src"
      allow="clipboard-read; clipboard-write"
      frameborder="0"
    ></iframe>
    <button
      @click="openZendek"
      class="zendesk-button"
      v-if="!store.state.support"
    >
      <span v-if="!zendeskLoading"
        >&nbsp;Need Help <span style="font-size: 16px">?</span>&nbsp;</span
      >
      <span v-else>Loading</span>
    </button>
  </MountEvent>
</template>

<style lang="scss" scoped>
iframe {
  background-color: $color-background;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  border: none;
  width: 100vw;
  height: 100vh;
}

.zendesk-button {
  @media (max-width: 900px) {
    display: none;
  }
  cursor: pointer;
  color: $color-primary-0;
  border: none;
  font-family: $poppins;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.1px;
  background-color: $color-info;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 999px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 11px 13px 11px 16px;
  gap: 10px;
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 2000;
}
</style>
