<script setup>
import MenuBlock from "./MenuBlock.vue";
import MenuItem from "./MenuItem.vue";

import store from "@/store";
import { computed } from "vue";

const cardEnabled = computed(() => {
  return store.state.authentication.user?.cloaked_card_enabled;
});

const kycValidated = computed(() => {
  return store.state.authentication.user?.cloaked_card_kyc_configured;
});
</script>

<template>
  <MenuBlock title="Pay" v-if="cardEnabled">
    <MenuItem
      link="/wallet"
      :name="kycValidated ? 'Wallet' : 'Activate Now'"
      icon="credit-card"
    />
  </MenuBlock>
</template>
