var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      [
        _c("FileIcon"),
        _c("div", { staticClass: "filetype", attrs: { width: "50" } }, [
          _vm._v(_vm._s(_vm.type)),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }