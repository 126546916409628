var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "icon-btn",
      class: {
        disabled: _vm.disabled,
      },
      attrs: { disabled: _vm.disabled },
      on: { click: _vm.onClick },
    },
    [
      _c("ExpansionArrow", {
        class: {
          flip: _vm.isOpen,
          horizontal: _vm.horizontal,
          disabled: _vm.disabled,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }