var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "referral-container" }, [
    _c(
      "header",
      [
        _c(
          "router-link",
          { staticClass: "logo", attrs: { to: "/" } },
          [
            _c(_setup.CloakedLogoLight, { staticClass: "hidden-dark" }),
            _c(_setup.CloakedLogoDark, { staticClass: "hidden-light" }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", [
      _c(
        "div",
        [
          _vm._m(0),
          _c("div", { staticClass: "actions" }, [
            _c("ul", [
              _c("li", [
                _c("span", [_c(_setup.Referral30)], 1),
                _c("h3", [_vm._v("30 days free")]),
                _c("p", [
                  _vm._v(
                    " Your referral code includes a 30 day free trial to Cloaked Private Beta with no account limits "
                  ),
                ]),
              ]),
              _c("li", [
                _c("span", [_c(_setup.ReferralPrivacy)], 1),
                _c("h3", [_vm._v("Privacy by design")]),
                _c("p", [
                  _vm._v(
                    " Access to a fully encrypted, unique database where only you have access to use and view your data. "
                  ),
                ]),
              ]),
              _c("li", [
                _c("span", [_c(_setup.ReferralCard)], 1),
                _c("h3", [_vm._v("Cancel anytime")]),
                _c("p", [
                  _vm._v(
                    " A credit card is required to sign up for a Cloaked subscription, you can choose to cancel anytime. "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(_setup.ReferralCardsGraphic, { staticClass: "referral-graphic" }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "referral-hero" }, [
      _c("h1", { staticClass: "big" }, [
        _vm._v(" At this time, invites are only "),
        _c("br"),
        _vm._v("available in the US "),
      ]),
      _c("h1", { staticClass: "small" }, [
        _vm._v(" At this time, invites are only available in the US "),
      ]),
      _c("p", [
        _vm._v("Your redeem code is still valid and will not be removed."),
      ]),
      _c("div", { staticClass: "links" }, [
        _c("a", { attrs: { href: "/auth/referral" } }, [_vm._v("Try again")]),
        _c(
          "a",
          { attrs: { href: "https://keepitcloaked.typeform.com/waitlist" } },
          [_vm._v("Join Waitlist")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }