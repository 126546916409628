var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "cloak-detail-wrapper" },
    [
      _c(
        _setup.SidebarHeader,
        [
          _c(_setup.SidebarCloseButton, {
            nativeOn: {
              click: function ($event) {
                return _setup.handleClosePanel.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "nickname-row" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _setup.state.nickname,
              expression: "state.nickname",
            },
          ],
          ref: "cloakNicknameRef",
          staticClass: "nickname-row__input",
          attrs: {
            "aria-id": "SearchOrAddInput",
            type: "text",
            placeholder: "Add name or URL",
            autocomplete: "off",
            maxlength: "50",
            "data-lpignore": "true",
            autofocus: true,
          },
          domProps: { value: _setup.state.nickname },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _setup.handleEnter.apply(null, arguments)
            },
            keyup: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                )
                  return null
                return _setup.nav(-1)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                return _setup.nav(1)
              },
            ],
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_setup.state, "nickname", $event.target.value)
            },
          },
        }),
      ]),
      _setup.state.searching
        ? _c(
            "div",
            { staticClass: "cloak-detail-wrapper__loader" },
            [_c(_setup.InputSpinner)],
            1
          )
        : _c(_setup.SectionList, {
            attrs: {
              title: _setup.sectionTitle,
              items: _setup.listItems,
              active: _setup.state.active,
            },
            on: {
              select: _setup.selectWebsite,
              setActive: (idx) => _setup.nav(idx, true),
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }