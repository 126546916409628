<script setup>
import { defineProps } from "vue";
const props = defineProps({
  width: {
    type: Number,
    default: 512,
  },
  height: {
    type: Number,
    default: 372,
  },
  type: {
    type: String,
    default: "video/mp4",
  },
  src: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <video
    :width="props.width"
    :height="props.height"
    :key="props.src"
    class="app-modal-video"
    v-bind="$attrs"
  >
    <source :src="props.src" :type="props.type" />
    Your browser does not support the video tag.
  </video>
</template>

<style lang="scss">
.app-modal-video {
  object-fit: cover;
}
</style>
