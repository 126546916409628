<script setup>
import { onMounted, computed, defineEmits } from "vue";
import store from "@/store";
import MenuBlock from "./MenuBlock.vue";
import MenuItem from "./MenuItem.vue";
import CategoryService from "@/api/actions/category-service";

const categories = computed(() => store.getters["getCategories"]);

const emit = defineEmits(["addCategory"]);

onMounted(() => {
  CategoryService.getCategories();
});
</script>

<template>
  <MenuBlock title="Categories" :toggleState="true">
    <MenuItem
      v-for="category in categories"
      :key="category.id"
      :link="`/category/${category.id}`"
      :name="category.name"
      icon="stacked-blocks"
    />
    <MenuItem
      add
      name="Add new..."
      @click.native="emit('addCategory')"
      v-if="categories.length < 32"
    />
  </MenuBlock>
</template>
