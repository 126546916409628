var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "attachment-container" }, [
    _c(
      "a",
      {
        staticClass: "download-link",
        attrs: { href: _vm.attachment.url, target: "_new", download: "" },
      },
      [
        _c(
          "svg",
          {
            attrs: {
              width: "10",
              height: "12",
              viewBox: "0 0 10 12",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M5 0.262695C4.65576 0.262695 4.40674 0.504395 4.40674 0.848633V8.15088L4.45801 9.37402L2.63428 7.35254L1.58691 6.33447C1.48438 6.23193 1.33057 6.17334 1.17676 6.17334C0.847168 6.17334 0.605469 6.42969 0.605469 6.75195C0.605469 6.90576 0.664062 7.04492 0.788574 7.18408L4.56055 10.9634C4.68506 11.0952 4.83887 11.1685 5 11.1685C5.16113 11.1685 5.31494 11.0952 5.43945 10.9634L9.21143 7.18408C9.33594 7.04492 9.39453 6.90576 9.39453 6.75195C9.39453 6.42969 9.15283 6.17334 8.82324 6.17334C8.66943 6.17334 8.51562 6.23193 8.41309 6.33447L7.36572 7.35254L5.54199 9.37402L5.59326 8.15088V0.848633C5.59326 0.504395 5.34424 0.262695 5 0.262695Z",
                fill: "#171717",
              },
            }),
          ]
        ),
      ]
    ),
    _c("section", { staticClass: "attachment" }, [
      _c("div", [
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _vm.is_image
              ? _c("MediaImage", { attrs: { attachment: _vm.attachment } })
              : _c("GenericMedia", { attrs: { attachment: _vm.attachment } }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "media-name" }, [
        _vm._v(_vm._s(_vm.attachment.name)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }