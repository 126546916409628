var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("span", [
      _vm._v("Your free trial has expired. "),
      _c("a", { on: { click: _setup.upgradeModal } }, [_vm._v("Upgrade now")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }