var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "preferences-account" },
    [
      !_setup.rightPanel
        ? _c(
            _setup.PreferencesPanel,
            [
              _c("div", { staticClass: "preferences-account__username-row" }, [
                _c(
                  "div",
                  { staticClass: "preferences-account__username-avatar" },
                  [
                    _c(
                      "span",
                      [
                        _c(_setup.AsyncText, {
                          attrs: {
                            getValue: _setup.getUsername,
                            format: _setup.getName,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "username",
                    attrs: { "aria-id": "CloakedUsername" },
                  },
                  [
                    _c("div", { staticClass: "username-label" }, [
                      _vm._v("Username"),
                    ]),
                    _c(_setup.AsyncText, {
                      attrs: { getValue: _setup.getUsername },
                    }),
                  ],
                  1
                ),
              ]),
              _c(_setup.ValueDisplay, {
                attrs: { label: "Change password", "dark-label": "" },
                on: {
                  click: function ($event) {
                    return _setup.goRight("password")
                  },
                },
              }),
              _setup.showAccountRecoveryMenu
                ? _c(_setup.ValueDisplay, {
                    attrs: { label: "Account recovery", "dark-label": "" },
                    on: {
                      click: function ($event) {
                        return _setup.goRight("recovery")
                      },
                    },
                  })
                : _vm._e(),
              _c(_setup.ValueDisplay, {
                attrs: { label: "Export my data", "dark-label": "" },
                on: {
                  click: function ($event) {
                    return _setup.goRight("export")
                  },
                },
              }),
              !_setup.userDeleteIsScheduled
                ? _c(_setup.ValueDisplay, {
                    attrs: { label: "Delete account", danger: "" },
                    on: {
                      click: function ($event) {
                        return _setup.goRight("delete")
                      },
                    },
                  })
                : _vm._e(),
              _setup.userDeleteIsScheduled
                ? _c(_setup.ValueDisplay, {
                    attrs: { label: "Manage Account", "dark-label": "" },
                    on: {
                      click: function ($event) {
                        return _setup.goRight("manage-account")
                      },
                    },
                  })
                : _vm._e(),
              !_setup.showAccountRecoveryMenu
                ? _c(_setup.PreferencesTitle, [
                    _vm._v("Maintain your account access"),
                  ])
                : _vm._e(),
              !_setup.showAccountRecoveryMenu
                ? _c(_setup.PreferencesParagraph, [
                    _vm._v(
                      "These can be used to make sure it's really you signing in, reach you if there's suspicious activity in your account, or recover a lost password."
                    ),
                  ])
                : _vm._e(),
              !_setup.showAccountRecoveryMenu
                ? _c(_setup.ValueDisplay, {
                    attrs: {
                      label: "Recovery email",
                      value: _vm.primaryEmail ? _vm.primaryEmail.email : "",
                    },
                    on: {
                      click: function ($event) {
                        return _setup.goRight("recovery-email")
                      },
                    },
                  })
                : _vm._e(),
              !_setup.showAccountRecoveryMenu
                ? _c(_setup.ValueDisplay, {
                    attrs: {
                      label: "Recovery phone",
                      value: _vm.primaryPhone
                        ? _vm.primaryPhone.phone_number
                        : "",
                    },
                    on: {
                      click: function ($event) {
                        return _setup.goRight("recovery-phone")
                      },
                    },
                  })
                : _vm._e(),
              _setup.showMfaSection
                ? _c(_setup.PreferencesTitle, [
                    _vm._v("Two-factor authentication (2FA)"),
                  ])
                : _vm._e(),
              _setup.showMfaSection
                ? _c(_setup.PreferencesParagraph, [
                    _vm._v(
                      "For enhanced security, Cloaked recommends enabling two-factor authentication to ensure it's really you when signing in."
                    ),
                  ])
                : _vm._e(),
              _vm._l(_setup.state.mfaMethods, function (mfaMethod) {
                return _c(_setup.ValueDisplay, {
                  key: mfaMethod?.id
                    ? `${mfaMethod.id}-${mfaMethod.method}`
                    : "id",
                  attrs: {
                    label: mfaMethod?.settingsDisplayLabel
                      ? mfaMethod.settingsDisplayLabel
                      : "mfa device",
                    value: mfaMethod?.displayValue
                      ? mfaMethod.displayValue
                      : "an mfa device",
                    xIcon: "",
                  },
                  on: { delete: () => _setup.handleOpenRemoveModal(mfaMethod) },
                })
              }),
              _setup.showMfaSection
                ? _c(_setup.ValueDisplay, {
                    attrs: {
                      label:
                        _setup.state.mfaMethods.length > 0
                          ? "Add another option"
                          : "Set up two-factor authentication",
                    },
                    on: {
                      click: _setup.launchMfaSetup,
                      "mfa-devices-updated": _setup.getMfaMethodsAndDevices,
                    },
                  })
                : _vm._e(),
              _setup.showMfaSection && _setup.isMfaActive
                ? _c(_setup.ValueDisplay, {
                    class: { no_devices: _setup.state.mfaDevices.length < 1 },
                    attrs: {
                      label: "Authorized devices",
                      value:
                        _setup.state.mfaDevices.length < 1
                          ? "No authorized devices"
                          : _setup.state.mfaDevices.length > 1
                          ? `${_setup.state.mfaDevices[0].name} and ${
                              _setup.state.mfaDevices.length - 1
                            } other${
                              _setup.state.mfaDevices.length > 2 ? "s" : ""
                            }`
                          : `${_setup.state.mfaDevices[0].name}`,
                    },
                    on: {
                      click: function ($event) {
                        _setup.state.mfaDevices.length > 0
                          ? _setup.goRight("authorized-devices")
                          : () => {}
                      },
                    },
                  })
                : _vm._e(),
              _setup.showMfaSection && _setup.isMfaActive
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        display: "flex",
                        padding: "24px 0px",
                      },
                      on: {
                        "mfa-devices-updated": _setup.getMfaMethodsAndDevices,
                      },
                    },
                    [
                      _c(
                        _setup.Button,
                        {
                          attrs: { type: "danger" },
                          on: { click: _setup.handleOpenMfaToggleModal },
                        },
                        [_vm._v(" Disable two-factor authentication ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _setup.rightPanel === "mfa-setup"
        ? _c(_setup.MfaSetup, {
            on: {
              toggleBack: _setup.toggleBack,
              "mfa-devices-updated": _setup.getMfaMethodsAndDevices,
            },
          })
        : _vm._e(),
      _setup.rightPanel === "password"
        ? _c(_setup.ChangePassword, { on: { toggleBack: _setup.toggleBack } })
        : _vm._e(),
      _setup.rightPanel === "recovery"
        ? _c(_setup.AccountRecovery, { on: { toggleBack: _setup.toggleBack } })
        : _vm._e(),
      _setup.rightPanel === "authorized-devices"
        ? _c(_setup.AuthorizedDevices, {
            attrs: { devices: _setup.state.mfaDevices },
            on: {
              toggleBack: _setup.toggleBack,
              "mfa-devices-updated": _setup.getMfaMethodsAndDevices,
            },
          })
        : _vm._e(),
      _setup.rightPanel === "recovery-phone" && !_setup.showAccountRecoveryMenu
        ? _c(_setup.AccountRecoveryPhone, {
            attrs: { phones: _setup.state.phones },
            on: {
              toggleBack: _setup.toggleBack,
              refresh: _setup.refreshPhones,
              delete: (phoneId) => _vm.deletePhone(phoneId),
            },
          })
        : _vm._e(),
      _setup.rightPanel === "recovery-email" && !_setup.showAccountRecoveryMenu
        ? _c(_setup.AccountRecoveryEmail, {
            attrs: { emails: _setup.state.emails },
            on: {
              toggleBack: _setup.toggleBack,
              refresh: _setup.refreshEmails,
              delete: (emailId) => _vm.deleteEmail(emailId),
            },
          })
        : _vm._e(),
      _setup.rightPanel === "export"
        ? _c(_setup.ExportData, { on: { toggleBack: _setup.toggleBack } })
        : _vm._e(),
      _setup.rightPanel === "delete"
        ? _c(_setup.DeleteAccount, { on: { toggleBack: _setup.toggleBack } })
        : _vm._e(),
      _setup.rightPanel === "manage-account"
        ? _c(_setup.ManageAccount, { on: { toggleBack: _setup.toggleBack } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }