<template>
  <iframe
    :srcdoc="iframeContent"
    allowtransparency="true"
    frameborder="0"
    scrolling="no"
    ref="iframe"
    @load="resize"
    sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
    :style="`height: ${height}px;`"
  ></iframe>
</template>

<script>
export default {
  name: "SafeUGC",
  props: ["content", "color"],
  data() {
    return {
      height: 0,
      width: 0,
    };
  },
  computed: {
    iframeContent() {
      return `
      <base href="/" target="_blank" />
      <style>
      html, body {
        padding: 0;
        margin: 0;
        word-wrap: anywhere;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        font-family: "Poppins";
        color: ${this.color};
      }
      a {
        color: ${this.color};
        text-decoration: underline;
      }
      @font-face {
        font-family: "Poppins";
        src: local('Poppins'), url(/static-fonts/Poppins-Regular.ttf)  format('truetype');
        font-weight: 400;
        font-style: normal;
      }
    </style>
     ${this.content}`.trim();
    },
  },
  methods: {
    recalculate() {
      const body = this.$refs.iframe.contentWindow.document.body;
      const html = this.$refs.iframe.contentWindow.document.documentElement;
      const height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      this.height = height;
      const width = Math.max(
        body.scrollWidth,
        body.offsetWidth,
        html.clientWidth,
        html.scrollWidth,
        html.offsetWidth
      );
      this.width = width;
    },
    resize() {
      const document = this.$refs.iframe.contentWindow.document;
      this.$nextTick(() => {
        setTimeout(this.recalculate, 50);
        setTimeout(this.recalculate, 100);
        setTimeout(this.recalculate, 200);
        setTimeout(this.recalculate, 500);
      });
      const q = document.body.getElementsByTagName("a");
      for (let i = 0; i < q.length; i++) {
        q[i].target = "_blank";
        if (q[i].href && !q[i].href.match(/http/i)) {
          q[i].href = `https://${q[i].href}`;
        }
      }
    },
  },
};
</script>
