import IconText from "@/assets/icons/custom-fields/text.svg";
import IconAddress from "@/assets/icons/custom-fields/address.svg";
import IconUrl from "@/assets/icons/custom-fields/url.svg";
import IconDate from "@/assets/icons/custom-fields/date.svg";
import IconBank from "@/assets/icons/custom-fields/bank.svg";
import IconAuth from "@/assets/icons/custom-fields/auth.svg";
import IconIdentification from "@/assets/icons/custom-fields/identification.svg";

export {
  IconText,
  IconAddress,
  IconUrl,
  IconDate,
  IconBank,
  IconAuth,
  IconIdentification,
};
