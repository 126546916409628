<template>
  <button
    class="icon-btn"
    :class="{
      disabled: disabled,
    }"
    @click="onClick"
    :disabled="disabled"
  >
    <ExpansionArrow
      :class="{
        flip: isOpen,
        horizontal: horizontal,
        disabled: disabled,
      }"
    />
  </button>
</template>
<script>
import { ExpansionArrow } from "@/assets/icons";

export default {
  name: "ArrowButton",
  props: {
    onClick: Function,
    isOpen: Boolean,
    disabled: { type: Boolean, default: false },
    horizontal: { type: Boolean, default: false },
  },
  components: {
    ExpansionArrow,
  },
};
</script>

<style scoped lang="scss">
.icon-btn {
  background-color: transparent;
  border-radius: 50%;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: $color-primary-5;
    &.disabled {
      background-color: none;
    }
  }
  transition: transform 0.5s;
  transform: rotate(360deg);
  color: $color-primary-60;

  .flip {
    transition: transform 0.5s;
    transform: rotate(180deg);

    &.horizontal {
      transform: rotate(270deg);
    }
  }
  .horizontal {
    transform: rotate(90deg);
  }

  &.disabled {
    background-color: none;
    pointer-events: none;
    color: $color-primary-30;
  }
}
</style>
