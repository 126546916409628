<template>
  <div
    class="value-display"
    :class="{
      'value-display--has-value': value,
      'value-display--dark-label': darkLabel,
      'value-display--light-value': lightValue,
      'value-display--disabled': disabled,
      'value-display--readonly': readonly,
      'value-display--danger': danger,
      'value-display--no-separator': noSeparator,
      'value-display--delete': $listeners.delete,
      'value-display--edit': $listeners.edit,
    }"
    @click="$emit('click')"
  >
    <div v-if="$slots['pre-field']" class="value-display__pre-field">
      <slot name="pre-field" />
    </div>

    <div class="value-display__field">
      <div class="value-display__label">
        <span style="display: inline-flex; align-items: center"
          >{{ label }}
          <span v-if="$slots['icon-label']" class="icon-label">
            <slot name="icon-label" />
          </span>
        </span>
      </div>

      <div
        class="value-display__value"
        :class="{
          'value-display__capitalize': shouldCapitalize,
        }"
        v-if="value"
      >
        <span>{{ value }}</span>
      </div>
    </div>

    <div class="value-display__actions">
      <slot name="actions">
        <button
          v-if="$listeners.edit"
          class="value-display__action-button value-display__action-button--edit"
          @click="handleEdit"
        >
          <EditPencilLargerIcon />
        </button>
        <button
          v-if="$listeners.add"
          class="value-display__action-button value-display__action-button--add"
          @click="handleAdd"
        >
          <Plus />
        </button>
        <button
          v-if="$listeners.delete"
          class="value-display__action-button value-display__action-button--delete"
          @click="handleDelete"
        >
          <Close v-if="xIcon" style="width: auto" />
          <TrashOutline v-else />
        </button>
        <button
          v-else-if="($listeners.click && !$listeners.add) || arrow"
          class="value-display__action-button"
        >
          <ChevronRight />
        </button>
      </slot>
    </div>
  </div>
</template>

<script>
import {
  ChevronRight,
  TrashOutline,
  EditPencilLargerIcon,
  Plus,
} from "@/assets/icons";
import Close from "@/assets/icons/close-modal-full.svg";

export default {
  props: {
    value: [String, Number],
    label: String,
    disabled: Boolean,
    readonly: Boolean,
    arrow: Boolean,
    xIcon: Boolean,
    danger: Boolean,
    edit: Boolean,
    add: Boolean,
    darkLabel: Boolean,
    lightValue: Boolean,
    noSeparator: Boolean,
  },

  components: {
    ChevronRight,
    Close,
    EditPencilLargerIcon,
    TrashOutline,
    Plus,
  },

  methods: {
    handleDelete($event) {
      $event.stopPropagation();
      this.$emit("delete");
    },
    handleEdit($event) {
      $event.stopPropagation();
      this.$emit("edit");
    },
    handleAdd($event) {
      $event.stopPropagation();
      this.$emit("add");
    },
  },
  computed: {
    shouldCapitalize() {
      return (
        this.label &&
        !(
          this.label.toLowerCase().includes("email") ||
          this.label.toLowerCase().includes("forward")
        )
      );
    },
  },
};
</script>

<style lang="scss">
.value-display {
  display: flex;
  position: relative;
  min-height: 60px;
  width: 100%;
  border-bottom: 1px solid $color-primary-10;
  --label-color: $color-primary-70;
  --value-color: $color-primary-100;
  color: $color-primary-100;

  padding: 9px 0;

  &__pre-field {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 8px;
  }

  &__field {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__capitalize {
    text-transform: capitalize;
  }

  &__label,
  &__value {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    min-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.2px;
    overflow: hidden;

    span {
      text-overflow: ellipsis;
      // white-space: nowrap;
    }
  }

  &__label {
    color: $color-primary-70;
    flex: 1 0 0;
    .icon-label {
      > svg {
        margin-left: 8px;
        margin-right: 4px;
        margin-bottom: -1px;
      }
    }
  }

  &__value {
    color: $color-primary-100;
  }

  &__actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 14px;
  }

  &__action-button {
    width: 32px;
    height: 32px;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: $color-primary-100;

    &--delete {
      cursor: pointer;
      svg {
        width: 15px;
        height: auto;
      }

      &:hover {
        color: $color-alert;
      }
    }

    svg {
      max-width: 100%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &--has-value {
  }

  &--danger {
    --label-color: $color-alert;
  }

  &--dark-label {
    --label-color: $color-primary-100;
  }

  &--light-value {
    --value-color: $color-primary-70;
  }

  &--no-separator {
    border-bottom: none;
  }

  &--disabled {
    opacity: 0.3;
  }

  &--delete,
  &--edit,
  &--add {
    cursor: default;
  }

  &:not(&--disabled):not(&--readonly):not(&--delete):not(&--edit):not(&--add) {
    .value-display__action-button {
      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
