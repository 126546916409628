var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "block" }, [
    _c("div", { staticClass: "header" }, [
      _c("h1", [_vm._v("Your Cloaked card")]),
      _setup.cards && !_setup.loading
        ? _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _setup.openCard(_setup.cards.id)
                },
              },
            },
            [_vm._v(" View details ")]
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "card" },
      [
        _setup.cards && !_setup.loading
          ? _c(
              "svg",
              {
                staticClass: "cloaked-icon",
                attrs: {
                  width: "46",
                  height: "36",
                  viewBox: "0 0 46 36",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    opacity: "0.2",
                    d: "M11.3845 35.3999C11.3845 35.3999 -2.64235 30.0235 0.443564 24.3484C2.40733 20.7641 6.89592 24.6471 13.9094 28.5301L11.3845 35.3999Z",
                    fill: "white",
                  },
                }),
                _c("path", {
                  attrs: {
                    d: "M28.5017 33.6073C39.1621 34.5033 43.8833 29.4613 45.0055 27.9679C45.0055 27.9679 38.6411 19.4908 34.8905 13.8043C32.3365 9.93202 29.0638 4.42638 27.1912 1.23057C26.5249 0.0934141 24.9346 0.140662 24.2549 1.26884C17.2944 12.823 4.47698 21.4843 1.60352 23.1531C3.87587 22.1674 8.0225 25.2439 13.6332 28.5295C13.6332 28.5295 17.8021 30.7969 20.6239 31.845C24.2429 33.1891 28.5017 33.6073 28.5017 33.6073Z",
                    fill: "#191E23",
                  },
                }),
              ]
            )
          : _vm._e(),
        _setup.cards && !_setup.loading
          ? _c(_setup.MastercardIcon, { staticClass: "mastercard-icon" })
          : _vm._e(),
        _setup.loading ? _c("div", { staticClass: "loading" }) : _vm._e(),
        _setup.cards && !_setup.loading
          ? _c("div", { staticClass: "information" }, [
              _c("div", { staticClass: "card-number" }, [
                _c("h1", [
                  _vm._v(
                    "•••• •••• •••• " +
                      _vm._s(_setup.lastCharacters(_setup.cards.pan, 4))
                  ),
                ]),
              ]),
              _vm._m(0),
            ])
          : _vm._e(),
        !_setup.cards && !_setup.loading
          ? _c("div", { staticClass: "no-cards" }, [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _setup.generateIdentityCard()
                    },
                  },
                },
                [_vm._v("Create new card")]
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "card-details" }, [
      _c("span", [_vm._v("••/••")]),
      _c("span", [_vm._v("•••")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }