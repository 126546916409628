<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";
import CustomFieldFormAddressNew from "@/components/cloakDetails/CustomFields/CustomFieldForm/CustomFieldFormAddressNew.vue";
import CustomFieldFormAddressExisting from "@/components/cloakDetails/CustomFields/CustomFieldForm/CustomFieldFormAddressExisting.vue";
import {
  getDefaultLabel,
  getFreshNewAddress,
} from "@/components/cloakDetails/CustomFields/CustomFieldForm/utils";

const props = defineProps({
  formType: {
    type: String,
    validator: (value) => ["new", "edit"].includes(value),
  },
  existingAddresses: {
    type: Array,
    default: () => [],
  },
});

const addingNew = ref(
  props.formType === "edit" || !props.existingAddresses?.length
);

const emit = defineEmits(["input", "reset-validation"]);

const onAddNew = () => {
  emit("input", {
    type: "address",
    label: getDefaultLabel("address"),
    value: getFreshNewAddress(),
    isSecret: false,
  });

  emit("reset-validation");
  addingNew.value = true;
};

watch(
  () => props.formType,
  (value) => {
    value === "edit" && onAddNew();
  }
);

watch(
  () => props.existingAddresses,
  (value) => {
    !value?.length || onAddNew();
  }
);
</script>

<template>
  <CustomFieldFormAddressNew
    v-if="addingNew"
    v-bind="$attrs"
    v-on="$listeners"
  /><CustomFieldFormAddressExisting
    v-else
    @add-new="onAddNew"
    :existingAddresses="existingAddresses"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<style lang="scss">
.custom-field-form-address {
  &__row {
    display: flex;
    gap: 12px;

    & > * {
      flex: 1;
    }
  }
}
</style>
