<script setup>
import { sum } from "lodash-es";
import SearchIcon from "@/assets/icons/search.svg";
import CloseIcon from "@/assets/icons/close.svg";
import SearchFooter from "@/components/global/search-footer";
import { IdentityIcon } from "@/components";

import { searchLocalCloaksGrouped } from "@/scripts/search";
import { getFormattedNickname } from "@/scripts/formattedText";
import store from "@/store";

import { reactive, ref, watch, computed, nextTick } from "vue";

let searchTimeout = null;
const groupedDefault = {
  name: [],
  website: [],
  email: [],
  phone_number: [],
  other: [],
};

const state = reactive({
  loading: false,
  active: null,
  groupedResults: groupedDefault,
});

const search = ref("");
const cInput = ref(null);

const show = computed(() => {
  return store.state.searchToggle;
});

const cloaks = computed(() => {
  return store.state.localdb.db_cloaks;
});

const items = computed(() => {
  return groups.value.map((group) => group.data).flat();
});

const count = computed(() => {
  return items.value.length;
});

const groups = computed(() => {
  return Object.keys(state.groupedResults)
    .map((group) => ({
      title: group,
      data: state.groupedResults[group],
    }))
    .filter((f) => f.data.length > 0);
});

watch(
  () => show.value,
  (newShowValue) => {
    if (newShowValue === true) {
      setTimeout(() => {
        cInput.value.focus();
      }, 200);
    }
  }
);

watch(search, (newSearchValue) => {
  if (newSearchValue !== "") {
    state.active = null;
    searchCloaks();
  }
});

function cancel() {
  search.value = null;
}
function subheader(item) {
  if (item.email) {
    return item.email;
  }
  if (item.username) {
    return item.username;
  }
  if (item.phone) {
    return item.phone;
  }
  if (item.website_url) {
    return item.website_url;
  }
  return null;
}

function getIdx(index, groupIdx) {
  const groupCount = sum(
    groups.value.filter((f, i) => i < groupIdx).map((g) => g.data.length)
  );

  return index + groupCount;
}

function nav(direction, groupIdx, override) {
  if (count.value > 0) {
    if (override) {
      state.active = getIdx(direction, groupIdx);
    } else {
      let active =
        state.active === null
          ? 0
          : parseInt(state.active) + parseInt(direction);
      if (active === -1) {
        active = items.value.length - 1;
      }
      if (active > items.value.length - 1) {
        active = 0;
      }
      const el = document.getElementById(`i-${active}`);
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      state.active = active;
    }
  } else {
    state.active = null;
  }
}

function clearSearch() {
  search.value = "";
}

function focusSearch() {
  cInput.value.focus();
}

function searchCloaks() {
  state.loading = true;
  clearTimeout(searchTimeout);
  searchTimeout = setTimeout(doSearch, 300);
}

function resetResults() {
  state.active = null;

  state.groupedResults = groupedDefault;
}

function doSearch() {
  resetResults();
  nextTick(() => {
    state.groupedResults = searchLocalCloaksGrouped(
      cloaks.value.filter((f) => !f.protected),
      search.value
    );
    state.loading = false;
  });
}

function clickSearchItem(item) {
  store.dispatch("fetchPopulatedData", item).then((data) => {
    store.dispatch("openCloakDetails", { cloak: data });
  });
  store.dispatch("setSearch", false);
  clearSearch();
}

function handleEnter() {
  if (state.active === count.value) {
    doSearch();
  } else {
    const item = items.value[state.active];
    if (item) {
      clickSearchItem(item);
    }
  }
}

function formatNickname(cloak) {
  return getFormattedNickname(cloak);
}

function close() {
  store.dispatch("setSearch", false);
  clearSearch();
  cancel();
}
</script>

<template>
  <div class="search" :class="{ open: show }">
    <div class="content">
      <div class="input">
        <div class="icon">
          <SearchIcon class="show" />
        </div>

        <input
          aria-id="SearchInput"
          maxlength="50"
          autocomplete="off"
          data-lpignore="true"
          data-form-type="other"
          type="text"
          placeholder="search cloaked"
          ref="cInput"
          v-model="search"
          @keyup.enter="handleEnter"
          @keyup.up="nav(-1)"
          @keyup.down="nav(1)"
          @keyup.esc="close"
        />

        <div
          class="clear"
          @click="[clearSearch(), focusSearch()]"
          v-if="search !== ''"
        >
          <CloseIcon />
        </div>
      </div>

      <div
        class="results"
        v-if="search !== '' && !state.loading"
        ref="scroll_results"
      >
        <div class="items" v-if="groups.length > 0">
          <div v-for="(group, groupIdx) in groups" :key="group.title">
            <span class="group_title">{{ group.title }}</span>
            <div
              @click="clickSearchItem(item)"
              v-for="(item, index) in group.data"
              :key="item.id"
              @mousemove="nav(index, groupIdx, true)"
            >
              <div
                class="item"
                :id="`i-${getIdx(index, groupIdx)}`"
                :class="{ active: getIdx(index, groupIdx) === state.active }"
              >
                <IdentityIcon :identity="item" />
                <div>
                  <h1 :class="{ placeholder: !item.n }">
                    {{ formatNickname(item) }}
                  </h1>
                  <h3
                    class="display"
                    v-if="item.display"
                    v-html="item.display"
                  ></h3>
                  <h4 v-if="subheader(item)">
                    {{ subheader(item) }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="empty" v-if="groups.length === 0 && search !== ''">
          <p>No results for</p>
          <p>"{{ search }}"</p>
        </div>
      </div>
      <SearchFooter :isVisible="count > 0 && !state.loading && search !== ''" />
    </div>

    <div class="background" @click="close">
      <div class="overlay"></div>
      <div class="blur"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.load-more {
  font-size: 13px;
  padding: 15px;
}

.search {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 211;
  opacity: 0;
  visibility: hidden;

  .content {
    position: absolute;
    z-index: 3;
    top: 60px;
    left: 50%;
    @include transform(translate(-50%, 25px));
    max-height: calc(100% - 120px);
    width: 100%;
    max-width: 514px;
    border-radius: 12px;
    opacity: 0;
    visibility: hidden;
    @include transition(all 0.4s ease);
    overflow: hidden;
    background-color: $color-surface;
    border: 1px solid $color-primary-5;

    .notification {
      background: $color-alert;
      border-radius: 4px;
      padding: 1px 4px;
      color: $color-primary-0;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: -0.1px;
      display: inline-block;
      position: absolute;
      right: -10px;
      top: -6px;
      min-width: 24px;
      text-align: center;
    }

    .input {
      width: 100%;
      position: relative;

      .icon {
        position: absolute;
        left: 12px;
        top: 12px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-primary-100;

        svg {
          max-width: 100%;
          height: auto;
          width: 16px;
          opacity: 0;
          visibility: hidden;
          display: block;
          white-space: nowrap;
          position: absolute;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
          fill: $color-primary-100;

          &.show {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      input {
        width: 100%;
        display: block;
        background-color: $color-primary-5;
        border: 0;
        padding: 15px 50px;
        font-weight: 300;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.38px;
        font-family: $poppins;
        color: $color-primary-100;

        &:focus {
          outline: none;
        }

        @include placeholder() {
          color: $color-primary-30;
        }
      }

      .clear {
        position: absolute;
        right: 12px;
        top: 12px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          max-width: 100%;
          height: auto;
          width: 12px;
          display: block;
          white-space: nowrap;
          position: absolute;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
          fill: $color-primary-100;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .results {
      margin: 5px;
      overflow: auto;
      max-height: calc(100vh - 233px);
      @include custom-scroll-bar();
      padding: 20px 15px;

      .items {
        .item {
          padding: 10px;
          gap: 10px;
          display: flex;
          align-items: center;
          color: $color-primary-100;
          &.active {
            background: $color-primary-5;
            border-radius: 8px;
            cursor: pointer;
          }

          .icon {
            background: $color-background;
            border-radius: 8px;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-right: 15px;

            .results {
              margin: 5px;
              overflow: auto;
              max-height: calc(100vh - 233px);
              @include custom-scroll-bar();
              padding: 20px 15px;

              .items {
                .item {
                  padding: 10px;
                  display: flex;
                  align-items: center;

                  &:hover {
                    cursor: pointer;
                  }

                  .icon {
                    background: $color-primary-0;
                    border-radius: 8px;
                    width: 30px;
                    height: 30px;
                    box-shadow: -3.63315px -3.63315px 10.8995px $color-primary-0,
                      5.44973px 5.44973px 10.8995px rgba(1, 2, 24, 0.2);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    margin-right: 15px;

                    .emoji {
                      display: inline-block;
                      font-size: 26px;
                      @include transform(scale(0.7));
                    }

                    .notification {
                      position: absolute;
                      top: -6px;
                      right: -8px;
                      background: rgba(247, 93, 54, 0.9);
                      border-radius: 4px;
                      min-width: 22px;
                      font-size: 9px;
                      line-height: 14px;
                      letter-spacing: -0.1px;
                      color: $color-primary-0;
                      text-align: center;
                      padding: 0 4px;
                      font-family: $poppins;
                    }
                  }

                  h1 {
                    font-family: $poppins;
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 110%;
                    color: $color-primary-100;

                    &.placeholder {
                      font-style: italic;
                      font-weight: 200;
                    }
                  }
                }
              }

              .notification {
                position: absolute;
                top: -6px;
                right: -8px;
                background: rgba(247, 93, 54, 0.9);
                border-radius: 4px;
                min-width: 22px;
                font-size: 9px;
                line-height: 14px;
                letter-spacing: -0.1px;
                color: $color-primary-0;
                text-align: center;
                padding: 0 4px;
                font-family: $poppins;
              }
            }

            h1 {
              font-family: $poppins;
              font-weight: 300;
              font-size: 15px;
              line-height: 110%;
              color: $color-primary-100;

              &.placeholder {
                font-style: italic;
                font-weight: 200;
              }
            }
          }
        }
      }
    }
  }

  .empty {
    margin: 15px 0;
    color: $color-primary-100;

    p {
      font-family: $poppins;
      font-weight: 300;
      font-size: 15px;
      line-height: 20px;
      color: $color-primary-100;
      text-align: center;

      &:last-of-type {
        font-weight: 500;
      }
    }
  }
  h3.display {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: $color-primary-100;
    font-weight: 300 !important;
    margin-top: 2.5px;
    span.bolder {
      font-weight: 500 !important;
      color: $color-primary-100;
    }
  }
  h1 {
    font-family: $poppins;
    font-weight: 300;
    font-size: 15px;
    line-height: 110%;
    color: $color-primary-100;
    span.bolder {
      font-weight: 500 !important;
      color: $color-primary-100 !important;
    }
    &.placeholder {
      font-style: italic;
      font-weight: 200;
    }
  }

  h4 {
    font-size: 12px;
    color: $color-primary-100;
  }
  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .overlay,
    .blur {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      @include transition(opacity 0.3s ease);
    }

    .overlay {
      background: rgba($black, 0.5);
      z-index: 2;
    }

    .blur {
      z-index: 1;
    }
  }

  &.open {
    opacity: 1;
    visibility: visible;

    .content {
      opacity: 1;
      visibility: visible;
      @include transform(translate(-50%, 0px));
      @include transition-delay(0.15s);
    }

    .background {
      .overlay,
      .blur {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.group_title {
  font-size: 11px;
  line-height: 18px;
  text-transform: uppercase;
  color: $color-primary-100;
  margin-bottom: 15px;
}
</style>
