<template>
  <input
    type="email"
    class="onboarding-input-email"
    v-on="$listeners"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  mounted() {
    this.$el.focus();
  },
};
</script>

<style lang="scss">
.onboarding-input-email {
  padding: 8px 12px;
  height: 60px;
  background-color: $color-primary-5;
  border-radius: 10px;
  border: none;
  margin: 32px auto 0;
  width: calc(100% - 128px);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: $color-primary-100;

  &:focus {
    outline: 1px solid $color-primary-100;
  }
}
</style>
