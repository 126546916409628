import api from "@/api/api";

export default class RestoreIdentifiersService {
  static async getIdentifiers() {
    let endpoint = `/api/v1/restore/`;
    return await api()
      .get(endpoint)
      .then(({ data }) => {
        return data;
      });
  }

  static async restoreIdentifiers(number, email) {
    let endpoint = `/api/v1/restore/`;
    let data = {};
    if (number) {
      data.number_id = number.id;
    }
    if (email) {
      data.email_id = email.id;
    }
    return await api()
      .post(endpoint, data)
      .then(({ data }) => {
        return data.new_identity_id;
      });
  }
}
