var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [!_setup.kycValidated ? _c(_setup.Activate) : _c(_setup.List)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }