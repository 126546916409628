var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pill-input", on: { click: _vm.selectInput } },
    [
      _c(
        "div",
        { staticClass: "pill-display" },
        [
          _vm._l(_vm.pills, function (pill, k) {
            return _c(
              "div",
              {
                key: k,
                staticClass: "pill",
                class: { pill_error: !pill.status },
                attrs: { "aria-id": `Pill-${pill?.value || ""}` },
              },
              [
                _vm._v(" " + _vm._s(pill.display || pill.value) + " "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.remove(k)
                      },
                    },
                  },
                  [_c("RemoveIcon")],
                  1
                ),
              ]
            )
          }),
          _c("div", { staticClass: "actual" }, [
            _c("div", [_vm._v(_vm._s(_vm.value))]),
            _c("div", { staticClass: "border" }),
          ]),
        ],
        2
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        ref: "input",
        attrs: { type: "text", tabindex: "0" },
        domProps: { value: _vm.value },
        on: {
          keydown: _vm.onkey,
          blur: _vm.onblur,
          input: function ($event) {
            if ($event.target.composing) return
            _vm.value = $event.target.value
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }