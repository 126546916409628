var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ModalTemplate, {
    staticClass: "identity-verification",
    attrs: { show: _setup.props.show, "no-close": "", width: "375px" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "text" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c(_setup.InlineSvg, { attrs: { name: "lock-shield" } })],
                1
              ),
              _c("div", { staticClass: "title" }, [
                _c("h1", [_vm._v("SSN or ITIN")]),
              ]),
              _c("div", { staticClass: "block" }, [
                _c("span", [
                  _vm._v(
                    "To help the government fight terrorism and money laundering activities, Federal law requires financial institutions to obtain, verify and record information that identifies each person who opens an account."
                  ),
                ]),
              ]),
              _c("div", { staticClass: "block" }, [
                _c("h2", [_vm._v("What this means for you.")]),
                _c("span", [
                  _vm._v(
                    "When you open an account, we will ask for your name, address, and other information that will help us to identify you. We may also ask to see your driver's license or other identifying documents."
                  ),
                ]),
              ]),
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _setup.emit("close")
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }