export default {
  state: {
    kycModal: false,
    virtualCardModal: false,
    transactionsModal: false,
    fundingValidateModal: false,
    generateCardModal: false,
    kycValidated: false,
    fundingSource: false,
    fundingSources: [],
    currentCard: {},
    identityCards: "",
    cards: [],
    primaryFundingSource: "",
  },

  mutations: {
    openKycModal: (state) => {
      state.kycModal = true;
    },
    closeKycModal: (state) => {
      state.kycModal = false;
    },
    openFundingValidateModal: (state) => {
      state.fundingValidateModal = true;
    },
    closeFundingValidateModal: (state) => {
      state.fundingValidateModal = false;
    },
    openVirtualCardModal: (state) => {
      state.virtualCardModal = true;
    },
    closeVirtualCardModal: (state) => {
      state.virtualCardModal = false;
      state.currentCard = "";
    },
    openGenerateCardModal: (state) => {
      state.generateCardModal = true;
    },
    closeGenerateCardModal: (state) => {
      state.generateCardModal = false;
    },
    openTransactionsModal: (state) => {
      state.transactionsModal = true;
    },
    closeTransactionsModal: (state) => {
      state.transactionsModal = false;
    },
    kycValidation: (state) => {
      state.kycValidated = true;
    },
    fundingSourceAdded: (state) => {
      state.fundingSource = true;
    },
    fundingSourcesList: (state, data) => {
      state.fundingSources = Object.assign({}, data);
    },
    currentCard: (state, data) => {
      state.currentCard = Object.assign({}, data);
    },
    identityCards: (state, data) => {
      state.identityCards = Object.assign({}, data);
    },
    addCardList: (state, data) => {
      state.cards = Object.assign({}, data);
    },
    addPrimaryCard: (state, data) => {
      state.primaryFundingSource = Object.assign({}, data);
    },
    updatePartialCard(state, data) {
      state.cards.results.forEach((card) => {
        if (card.id === data.id) {
          card = Object.assign(card, data);
        }
      });
    },
  },

  actions: {
    openKycModal({ commit }) {
      commit("openKycModal");
      document.body.classList.add("overflow-hidden");
    },
    closeKycModal({ commit }) {
      commit("closeKycModal");
      document.body.classList.remove("overflow-hidden");
    },
    openTransactionsModal({ commit }) {
      commit("openTransactionsModal");
      document.body.classList.add("overflow-hidden");
    },
    closeTransactionsModal({ commit }) {
      commit("closeTransactionsModal");
      document.body.classList.remove("overflow-hidden");
    },
    openVirtualCardModal({ commit }) {
      commit("openVirtualCardModal");
      document.body.classList.add("overflow-hidden");
    },
    openGenerateCardModal({ commit }) {
      commit("openGenerateCardModal");
    },
    closeGenerateCardModal({ commit }) {
      commit("closeGenerateCardModal");
    },
    closeVirtualCardModal({ commit }) {
      commit("closeVirtualCardModal");
      document.body.classList.remove("overflow-hidden");
    },
    openFundingValidateModal({ commit }) {
      commit("openFundingValidateModal");
      document.body.classList.add("overflow-hidden");
    },
    closeFundingValidateModal({ commit }) {
      commit("closeFundingValidateModal");
      document.body.classList.remove("overflow-hidden");
    },
    kycValidation({ commit }) {
      commit("kycValidation");
    },
    fundingSourceAdded({ commit }) {
      commit("fundingSourceAdded");
    },
    addCardList({ commit }, data) {
      commit("addCardList", data);
    },
    addPrimaryCard({ commit }, data) {
      commit("addPrimaryCard", data);
    },
    updateCard({ commit }, data) {
      commit("updatePartialCard", data);
    },
  },

  getters: {
    verifiedFunding(state) {
      return state.fundingSources.filter(
        (fundingSource) => fundingSource.verifed === true
      );
    },
    virtualCardModal(state) {
      return state.virtualCardModal;
    },
  },
};
