var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "border-input-switch",
      class: { "border-input-switch--error": _vm.errors.length },
    },
    [
      _c(
        "label",
        {
          staticClass: "border-input-switch__label",
          attrs: { tabindex: "0" },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.$emit("input", !_vm.$attrs.value)
            },
          },
        },
        [
          _c("span", [
            _vm.title
              ? _c("span", { staticClass: "border-input-switch__title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ])
              : _vm._e(),
            _vm.text
              ? _c("span", { staticClass: "border-input-switch__subtitle" }, [
                  _vm._v(" " + _vm._s(_vm.text) + " "),
                ])
              : _vm._e(),
          ]),
          _c(
            _setup.UiSwitchInput,
            _vm._g(
              _vm._b(
                { staticClass: "border-input-switch__input" },
                "UiSwitchInput",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          ),
        ],
        1
      ),
      _vm._l(_vm.errors, function (error) {
        return _vm._t(
          "error",
          function () {
            return [
              _c(_setup.InputValidationError, { key: error }, [
                _vm._v(" " + _vm._s(error) + " "),
              ]),
            ]
          },
          { error: error }
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }