var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ModalTemplate, {
    attrs: { show: _setup.state.show, width: 1000 },
    on: { close: _setup.close },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("div", { staticClass: "subscribe-body" }, [
              _c("div", { staticClass: "subscribe-intro" }, [
                _c("div", { staticClass: "video-container" }, [
                  _c(
                    "video",
                    {
                      attrs: { autoplay: "", loop: "", muted: "" },
                      domProps: { muted: true },
                    },
                    [
                      _c("source", {
                        attrs: {
                          src: "/videos/checkout-bg.mp4",
                          type: "video/mp4",
                        },
                      }),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "subscribe-overlay" }, [
                  _c(
                    "div",
                    { staticClass: "subscribe-overlay-content" },
                    [
                      _c(_setup.CloakedIcon),
                      _c("strong", [
                        _vm._v(" Turn a privacy miss into a Cloaked Moment. "),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "subscribe-form" }, [
                _setup.state.sending
                  ? _c("div", { staticClass: "subscribe-sending" }, [
                      _c("span", [_c(_setup.Logo)], 1),
                    ])
                  : _c("div", [
                      _setup.state.sent
                        ? _c("div", { staticClass: "subscribe-sent" }, [
                            _c("div", [
                              _c(
                                "span",
                                { staticClass: "clap" },
                                [_c(_setup.ClapIcon)],
                                1
                              ),
                              _c("h1", [
                                _vm._v("Your subscription is almost ready"),
                              ]),
                              _c("p", { staticClass: "subscribe-thanks" }, [
                                _vm._v(
                                  " Before we finish your subscription, we need to make sure you have your recovery key. It’s a unique string of numbers and letters that allows you to regain access to your account should you ever lose your password. "
                                ),
                              ]),
                              _c("p", { staticClass: "subscribe-cta" }, [
                                _vm._v(
                                  " Before continuing, make sure you have your key saved and stored in a secure location. "
                                ),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "subscribe-invite",
                                  on: { click: _setup.openSettings },
                                },
                                [_vm._v(" Download recovery key ")]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "subscribe-close",
                                  on: { click: _setup.confirmClose },
                                },
                                [_vm._v(" I already have my recovery key ")]
                              ),
                            ]),
                          ])
                        : _c("div", { staticClass: "subscribe-payment-form" }, [
                            _setup.state.loaded
                              ? _c("h1", [_vm._v("Choose your plan")])
                              : _vm._e(),
                            _setup.state.loaded
                              ? _c(
                                  "div",
                                  { staticClass: "subscribe-actions" },
                                  [
                                    _setup.monthlyPlan
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "plans",
                                            class: {
                                              active:
                                                _setup.state.currentPlan
                                                  .product_id ===
                                                _setup.monthlyPlan.product_id,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _setup.selectPlan(
                                                  _setup.monthlyPlan
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "price-title" },
                                              [
                                                _c("strong", [
                                                  _vm._v("Monthly"),
                                                ]),
                                              ]
                                            ),
                                            _c("div", [
                                              _c(
                                                "div",
                                                { staticClass: "description" },
                                                [_vm._v("Billed every 30 days")]
                                              ),
                                              _setup.isDiscount(
                                                _setup.monthlyPlan
                                              )
                                                ? _c("div", [
                                                    _c(
                                                      "div",
                                                      { staticClass: "strike" },
                                                      [_vm._v("$10.00")]
                                                    ),
                                                    _vm._v(
                                                      " $" +
                                                        _vm._s(
                                                          (
                                                            _setup.promo.price /
                                                            100
                                                          ).toFixed(2)
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _c("div", [
                                                    _vm._v(
                                                      " $" +
                                                        _vm._s(
                                                          (
                                                            _setup.monthlyPlan
                                                              .price / 100
                                                          ).toFixed(2)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _setup.yearlyPlan
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "plans",
                                            class: {
                                              active:
                                                _setup.state.currentPlan
                                                  .product_id ===
                                                _setup.yearlyPlan.product_id,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _setup.selectPlan(
                                                  _setup.yearlyPlan
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "price-title" },
                                              [
                                                _c("strong", [
                                                  _vm._v("Annual"),
                                                ]),
                                                !(
                                                  _setup.promo.valid &&
                                                  _setup.promo.price
                                                )
                                                  ? _c("sup", [
                                                      _vm._v("Save 20%"),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c("div", [
                                              _c(
                                                "div",
                                                { staticClass: "description" },
                                                [
                                                  _vm._v(
                                                    "Billed every 365 days"
                                                  ),
                                                ]
                                              ),
                                              _setup.isDiscount(
                                                _setup.yearlyPlan
                                              )
                                                ? _c("div", [
                                                    _c(
                                                      "div",
                                                      { staticClass: "strike" },
                                                      [_vm._v("$96.00")]
                                                    ),
                                                    _vm._v(
                                                      " $" +
                                                        _vm._s(
                                                          (
                                                            _setup.promo.price /
                                                            100
                                                          ).toFixed(2)
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _c("div", [
                                                    _vm._v(
                                                      "$" +
                                                        _vm._s(
                                                          (
                                                            _setup.yearlyPlan
                                                              .price / 100
                                                          ).toFixed(2)
                                                        )
                                                    ),
                                                  ]),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _setup.state.loaded
                              ? _c("div", { staticClass: "promo-section" }, [
                                  _c("h1", [_vm._v("Promo Code")]),
                                  _c("div", { staticClass: "promo-content" }, [
                                    _c(
                                      "div",
                                      { staticClass: "input-section" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _setup.promo.code,
                                              expression: "promo.code",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            placeholder: "Promo code",
                                          },
                                          domProps: {
                                            value: _setup.promo.code,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _setup.promo,
                                                "code",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _setup.promo.checked &&
                                        _setup.promo.valid
                                          ? _c(
                                              "div",
                                              { staticClass: "checkmark" },
                                              [_c(_setup.CheckMark)],
                                              1
                                            )
                                          : _vm._e(),
                                        _setup.promo.checked &&
                                        _setup.promo.valid === false
                                          ? _c(
                                              "div",
                                              { staticClass: "checkmark" },
                                              [_c(_setup.RedMark)],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c("span", [
                                      !_setup.promo.checked
                                        ? _c("p", [
                                            _vm._v(
                                              " If you have a promo code for a discounted Cloaked subscription, enter it here. "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _setup.promo.checked && _setup.promo.valid
                                        ? _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_setup.promo.message) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _setup.promo.checked &&
                                      !_setup.promo.valid
                                        ? _c("p", [
                                            _vm._v(
                                              " Code is invalid or no longer active. "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _setup.state.loaded
                              ? _c("div", { staticClass: "promo-section" }, [
                                  _c("h1", [_vm._v("Payment method")]),
                                ])
                              : _vm._e(),
                            _c("div", {
                              ref: "stripeRef",
                              staticClass: "element-ref",
                            }),
                            _c("p", [
                              _setup.state.loaded
                                ? _c("span", [
                                    _vm._v(
                                      " No commitment. Cancel anytime from Settings > Manage subscription at least a day before each renewal date. Plan automatically renews until canceled. "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                    ]),
                !_setup.state.sending && !_setup.state.sent
                  ? _c("div", { staticClass: "action-footer" }, [
                      _setup.state.error
                        ? _c("div", { staticClass: "error" }, [
                            _vm._v(_vm._s(_setup.state.error)),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "action-footer-buttons" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "close-button",
                              on: { click: _setup.close },
                            },
                            [_vm._v("Close")]
                          ),
                          _c(
                            _setup.UiTooltip,
                            {
                              attrs: {
                                title: `Subscribing to ${_setup.planType} plan ${_setup.planTitleForTooltip}`,
                                position: "top",
                                "align-x": "center",
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "activate",
                                  class: {
                                    pending: _setup.state.pending,
                                    ready: _setup.state.ready,
                                  },
                                  on: { click: _setup.subscribeUser },
                                },
                                [
                                  _vm._v(" Activate subscription "),
                                  _setup.currentPrice
                                    ? _c("span", [
                                        _vm._v(_vm._s(_setup.currentPrice)),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }