<script setup>
import store from "@/store";
import UiTooltip from "@/components/ui/ui-tooltip";
import {
  UiMenu,
  UiMenuButton,
  UiMenuTitle,
  UiMenuSeparator,
  UiMenuScrollBody,
} from "@/components";

import {
  CheckIcon,
  EmptyRound,
  MinusRound,
  MoveIcon,
  MutedContextIcon,
  DeleteActionBarIcon,
  UnmuteIcon,
} from "@/assets/icons";

import { defineProps, computed, defineEmits } from "vue";

const props = defineProps({
  selected: Array,
  identityList: Array,
  ignoreDisplay: String,
});

const emit = defineEmits([
  "select",
  "ignore",
  "unignore",
  "delete",
  "transferList",
]);

const categories = computed(() => {
  return store.state.categories.custom;
});

const identity = computed(() => {
  if (props.selected.length === 1) {
    return props.identityList.find((f) => f.id === props.selected[0]);
  }
  return null;
});

const selectTooltipTitle = computed(() => {
  if (props.selected.length > 0) {
    return "Deselect all";
  }

  return "Select all";
});

function cancelBubble(event) {
  event.stopPropagation();
}

function isInCategory(cat) {
  if (identity?.categories) {
    return identity.categories.includes(cat.id);
  }
  return false;
}

function moveToCategory(cat) {
  emit("transferList", cat);
}

function toggleAction(action) {
  emit(action);
}
</script>

<template>
  <div class="multiselect-filters">
    <div class="buttons">
      <UiTooltip :title="selectTooltipTitle" align-x="center">
        <button
          aria-id="SelectAllButton"
          class="buttons__button toggle-select-all"
          @click="toggleAction('select')"
          @mousedown="cancelBubble"
          @mouseup="cancelBubble"
          @mouseleave="cancelBubble"
          @mousemove="cancelBubble"
        >
          <MinusRound v-if="selected.length > 0" />
          <EmptyRound v-else />
        </button>
      </UiTooltip>

      <UiMenu
        v-if="selected.length > 0 && categories.length > 0"
        width="164px"
        max-height="300px"
        placement="bottom-start"
      >
        <UiTooltip title="Move category" align-x="center">
          <button
            aria-id="SelectAllChangeCategory"
            @mousedown="cancelBubble"
            @mouseup="cancelBubble"
            @mouseleave="cancelBubble"
            @mousemove="cancelBubble"
            class="buttons__button"
          >
            <MoveIcon />
          </button>
        </UiTooltip>

        <template #content>
          <UiMenuTitle title="Move to:" />

          <UiMenuSeparator />

          <UiMenuScrollBody>
            <UiMenuButton
              v-for="(category, index) in categories"
              :key="index"
              :title="category.name"
              :active="isInCategory(category)"
              @click="() => moveToCategory(category)"
              class="multiselect-filters__category-button"
            >
              <template v-slot:icon>
                <CheckIcon v-if="isInCategory(category)" />
                <span v-else />
              </template>
            </UiMenuButton>
          </UiMenuScrollBody>
        </template>
      </UiMenu>

      <UiTooltip
        v-if="selected.length > 0"
        :title="ignoreDisplay"
        align-x="center"
      >
        <button
          aria-id="SelectAllMute"
          @click="
            ignoreDisplay.toLowerCase() === 'ignore'
              ? toggleAction('ignore')
              : toggleAction('unignore')
          "
          class="buttons__button"
          @mousedown="cancelBubble"
          @mouseup="cancelBubble"
          @mouseleave="cancelBubble"
          @mousemove="cancelBubble"
        >
          <MutedContextIcon v-if="ignoreDisplay.toLowerCase() === 'ignore'" />
          <UnmuteIcon v-else />
        </button>
      </UiTooltip>

      <UiTooltip v-if="selected.length > 0" title="Delete" align-x="center">
        <button
          aria-id="SelectAllDelete"
          class="buttons__button"
          @click="toggleAction('delete')"
          @mousedown="cancelBubble"
          @mouseup="cancelBubble"
          @mouseleave="cancelBubble"
          @mousemove="cancelBubble"
        >
          <DeleteActionBarIcon />
        </button>
      </UiTooltip>
    </div>

    <div
      class="text"
      v-if="selected.length === 0"
      @click="toggleAction('select')"
    >
      <span>Select all</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.multiselect-filters {
  position: sticky;
  top: 60px;
  z-index: 5;
  background: $color-surface;
  padding: 0 24px 0 10px;
  display: flex;
  align-items: center;
  height: 49px;
  fill: $color-primary-50;

  .app--visible-banner & {
    top: 100px;
  }

  .text {
    margin-left: 10px;
    display: flex;
    align-items: center;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      display: inline-block;
      text-decoration-line: underline;
      color: $color-primary-100;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 0px;

    &__button {
      border: none;
      border-radius: 8px;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 50%;
      background: $color-surface;

      svg {
        max-width: 24px;
        height: auto;
        color: $color-primary-100;
      }

      &:hover {
        cursor: pointer;
        background-color: $color-primary-30;
      }

      .toggle-select-all {
        color: $color-primary-100;
      }

      .show-move {
        position: absolute;
        background-color: $color-primary-100;
        padding: 10px;
        z-index: 50;
        min-width: 180px;
        max-width: 180px;
        top: 40px;
        left: 0px;
        box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
          13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
        border-radius: 8px;

        h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-align: left;
          padding: 0 5px;
        }
      }
    }
  }
}
</style>
