var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "status" }, [
    _c("div", { staticClass: "title" }, [
      _c("h1", [_vm._v("Status")]),
      _setup.kycValidated
        ? _c("p", { staticClass: "approved" }, [
            _vm._v("Approved for Cloaked Pay"),
          ])
        : _c("p", { staticClass: "rejected" }, [
            _vm._v("Not approved for Cloaked Pay"),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }