var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "value-display",
      class: {
        "value-display--has-value": _vm.value,
        "value-display--dark-label": _vm.darkLabel,
        "value-display--light-value": _vm.lightValue,
        "value-display--disabled": _vm.disabled,
        "value-display--readonly": _vm.readonly,
        "value-display--danger": _vm.danger,
        "value-display--no-separator": _vm.noSeparator,
        "value-display--delete": _vm.$listeners.delete,
        "value-display--edit": _vm.$listeners.edit,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.$slots["pre-field"]
        ? _c(
            "div",
            { staticClass: "value-display__pre-field" },
            [_vm._t("pre-field")],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "value-display__field" }, [
        _c("div", { staticClass: "value-display__label" }, [
          _c(
            "span",
            {
              staticStyle: { display: "inline-flex", "align-items": "center" },
            },
            [
              _vm._v(_vm._s(_vm.label) + " "),
              _vm.$slots["icon-label"]
                ? _c(
                    "span",
                    { staticClass: "icon-label" },
                    [_vm._t("icon-label")],
                    2
                  )
                : _vm._e(),
            ]
          ),
        ]),
        _vm.value
          ? _c(
              "div",
              {
                staticClass: "value-display__value",
                class: {
                  "value-display__capitalize": _vm.shouldCapitalize,
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.value))])]
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "value-display__actions" },
        [
          _vm._t("actions", function () {
            return [
              _vm.$listeners.edit
                ? _c(
                    "button",
                    {
                      staticClass:
                        "value-display__action-button value-display__action-button--edit",
                      on: { click: _vm.handleEdit },
                    },
                    [_c("EditPencilLargerIcon")],
                    1
                  )
                : _vm._e(),
              _vm.$listeners.add
                ? _c(
                    "button",
                    {
                      staticClass:
                        "value-display__action-button value-display__action-button--add",
                      on: { click: _vm.handleAdd },
                    },
                    [_c("Plus")],
                    1
                  )
                : _vm._e(),
              _vm.$listeners.delete
                ? _c(
                    "button",
                    {
                      staticClass:
                        "value-display__action-button value-display__action-button--delete",
                      on: { click: _vm.handleDelete },
                    },
                    [
                      _vm.xIcon
                        ? _c("Close", { staticStyle: { width: "auto" } })
                        : _c("TrashOutline"),
                    ],
                    1
                  )
                : (_vm.$listeners.click && !_vm.$listeners.add) || _vm.arrow
                ? _c(
                    "button",
                    { staticClass: "value-display__action-button" },
                    [_c("ChevronRight")],
                    1
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }