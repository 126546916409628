var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.showEmailSection || _setup.showPhoneSection
    ? _c("section", { staticClass: "cloak-communication-section" }, [
        _c("header", { staticClass: "cloak-communication-section__header" }, [
          _c(
            "h3",
            { staticClass: "cloak-communication-section__header-title" },
            [
              _c("span", [_vm._v("Communication Settings")]),
              _c(
                _setup.UiTooltip,
                {
                  attrs: {
                    title: _setup.helpTooltip,
                    position: "top",
                    "max-width": "220",
                    "align-x": "center",
                  },
                },
                [_c(_setup.MoreInfoQIcon)],
                1
              ),
            ],
            1
          ),
          _c("button", { on: { click: _setup.toggleShow } }, [
            _vm._v(" " + _vm._s(_setup.state.showing ? "Close" : "Open") + " "),
          ]),
        ]),
        _setup.state.showing
          ? _c("div", [
              _setup.state.loaded
                ? _c(
                    "div",
                    {
                      staticClass:
                        "cloak-communication-section__group cloak-communication-section__group--no-separator",
                    },
                    [
                      _setup.showEmailSection
                        ? _c(_setup.CloakForwardingSection, {
                            attrs: {
                              field: "email",
                              title: "Incoming emails",
                              selected: _setup.emailValue,
                              options: _setup.emails,
                              allowed: _setup.emailForwardingAllowed,
                              rules: _setup.state.commSettings.email,
                              defaultValue: _setup.state.emailDefault,
                              "unverified-entries":
                                _setup.state.hasOnlyUnverifiedEmails,
                            },
                            on: {
                              select: (selected) =>
                                _setup.select(selected, "email"),
                              deselect: () => _setup.deselect("email"),
                              updateFilterRule: (filter_rule) =>
                                _setup.updateFilterRule(filter_rule, "email"),
                              sentToSettings: _setup.setSettings,
                            },
                          })
                        : _vm._e(),
                      _setup.showPhoneSection
                        ? _c(_setup.CloakForwardingSection, {
                            attrs: {
                              field: "call",
                              title: "Incoming call",
                              locked: _setup.state.locked,
                              selected: _setup.callPhoneValue,
                              options: _setup.phones,
                              allowed: _setup.phoneForwardingAllowed,
                              rules: _setup.state.commSettings.call,
                              defaultValue: _setup.state.phoneDefault,
                              "unverified-entries":
                                _setup.state.hasOnlyUnverifiedPhones,
                            },
                            on: {
                              select: (selected) =>
                                _setup.select(selected, "call"),
                              deselect: () => _setup.deselect("call"),
                              updateFilterRule: (filter_rule) =>
                                _setup.updateFilterRule(filter_rule, "call"),
                              sentToSettings: _setup.setSettings,
                            },
                          })
                        : _vm._e(),
                      _setup.showPhoneSection
                        ? _c(_setup.CloakForwardingSection, {
                            attrs: {
                              field: "message",
                              title: "Incoming text",
                              locked: _setup.state.locked,
                              rules: _setup.state.commSettings.message,
                              selected: _setup.messagePhoneValue,
                              options: _setup.phones,
                              allowed: _setup.phoneForwardingAllowed,
                              defaultValue: _setup.state.phoneDefault,
                              "unverified-entries":
                                _setup.state.hasOnlyUnverifiedPhones,
                            },
                            on: {
                              select: (selected) =>
                                _setup.select(selected, "message"),
                              deselect: () => _setup.deselect("message"),
                              updateFilterRule: (filter_rule) =>
                                _setup.updateFilterRule(filter_rule, "message"),
                              sentToSettings: _setup.setSettings,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "spinner-wrapper" },
                    [_c(_setup.InputSpinner)],
                    1
                  ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }