<template>
  <div
    :class="[
      'ui-progress',
      {
        'ui-progress--done': isDone,
      },
    ]"
  >
    <div class="ui-progress__header">
      <div class="ui-progress__label">
        {{ label }}
      </div>
      <div v-if="counter || counterTotal" class="ui-progress__counter">
        {{ counter || 0 }}{{ counterTotal ? ` of ${counterTotal}` : "" }}
      </div>
    </div>

    <div class="ui-progress__bar" :style="barStyle" />
  </div>
</template>

<script>
export default {
  name: "UiProgress",
  props: {
    label: String,
    counter: Number,
    counterTotal: Number,
    percentage: Number,
  },

  computed: {
    isDone() {
      return this.progress === 100;
    },

    barStyle() {
      return {
        "--progress": this.progress,
      };
    },

    progress() {
      if (this.percentage) {
        return this.percentage;
      }

      if (!this.counter || !this.counterTotal) {
        return 0;
      }

      // if external percentage is missing and there is a counter, determine here
      return (this.counter * 100) / this.counterTotal;
    },
  },
};
</script>

<style lang="scss">
.ui-progress {
  --label-color: $color-primary-90;
  --counter-color: $color-primary-90;
  --bar-color: $color-primary-100;
  --bar-background: $color-surface;
  --progress: 0;
  padding-top: 8px;

  &__header {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--label-color);
    flex: 1 1 0;
  }

  &__counter {
    color: var(--counter-color);
    flex-shrink: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  &__bar {
    width: 100%;
    height: 1px;
    margin-top: 8px;
    position: relative;
    background: var(--bar-background);

    &::after {
      content: "";
      display: block;
      height: 100%;
      width: calc(1% * var(--progress));
      max-width: 100%;
      background: var(--bar-color);
      transition: all 0.5s ease-in-out;
    }
  }

  &--done {
    --label-color: $color-info;
    --bar-color: $color-info;
    --counter-color: $color-info;
  }
}
</style>
