var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "cloak-web-section" },
    [
      _c(_setup.CloakInfoRow, {
        attrs: {
          field: "website",
          placeholder: "Add a website",
          "initial-value": _setup.state.websiteUrl,
          loading: _setup.state.loading || _setup.state.expectsRefresh,
          "disable-enter": true,
          forceDelete: true,
          forceReset: _setup.state.forceReset,
          readOnly: _vm.readOnly,
        },
        on: {
          input: _setup.handleInput,
          delete: _setup.deleteWebsite,
          focus: _setup.handleFocus,
          blur: _setup.handleBlur,
        },
        nativeOn: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _setup.handleEnterPress.apply(null, arguments)
          },
          keyup: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              )
                return null
              return _setup.nav(-1)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown",
                ])
              )
                return null
              return _setup.nav(1)
            },
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "input-before",
            fn: function () {
              return [
                _c(_setup.UiMenu, {
                  staticClass: "cloak-web-section__menu",
                  attrs: {
                    value: _setup.showResultsMenu,
                    width: "264px",
                    height: "475px",
                    placement: "left-start",
                  },
                  on: { close: _setup.resetResults },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c(_setup.SectionList, {
                            attrs: {
                              items: _setup.state.results,
                              active: _setup.state.active,
                            },
                            on: {
                              setActive: _setup.handleSetActive,
                              select: _setup.handleSelect,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }