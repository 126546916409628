var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.UiBanner,
    { staticClass: "account-delete-banner", attrs: { danger: "" } },
    [
      _vm._v(" Your account will be permanently deleted on "),
      _c("strong", [_vm._v(_vm._s(_setup.deleteDateLabel) + ".")]),
      _c(
        "span",
        { staticClass: "faux-link", on: { click: _setup.openRestoreAccount } },
        [_vm._v("Restore your account")]
      ),
      _vm._v(" | "),
      _c(
        "span",
        { staticClass: "faux-link", on: { click: _setup.openManageAccount } },
        [_vm._v("Manage account")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }