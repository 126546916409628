var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.BorderInputSwitch,
    _vm._g(
      _vm._b(
        {
          attrs: {
            title: "Sensitive Information",
            text: "Hide this content by default",
          },
        },
        "BorderInputSwitch",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }