<script setup>
import { defineProps } from "vue";

defineProps({
  noBackground: { type: Boolean, default: false },
  active: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  wide: { type: Boolean, default: false },
});
</script>

<template>
  <button
    class="sidebar-header-button"
    aria-id="SidebarCloseButton"
    :class="{
      'sidebar-header-button--no-background': noBackground,
      'sidebar-header-button--active': active,
      'sidebar-header-button--wide': wide,
    }"
    :disabled="disabled"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
.sidebar-header-button {
  width: 36px;
  height: 36px;
  background: $color-primary-10;
  border-radius: 36px;
  color: $color-primary-100;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;

  svg {
    max-width: 24px;
    max-height: 24px;
  }

  &:hover {
    cursor: pointer;
    background: $color-primary-100;
    color: $color-primary-0;
  }

  &:disabled {
    &:hover {
      cursor: not-allowed;
      background: $color-surface;
      color: $color-primary-100;
    }
  }

  &--no-background {
    background: none;
  }

  &--active {
    background: $color-primary-100;
    color: $color-primary-0;
  }

  &--wide {
    width: unset;
    min-width: 36px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    gap: 2px;

    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  &:disabled {
    opacity: 0.5;
  }
}
</style>
