<template>
  <AppModal v-on="$listeners" v-bind="$attrs">
    <AppModalContent>
      <AppModalTitle> Dismiss checklist? </AppModalTitle>
      <AppModalParagraph>
        If you dismiss this checklist without completing it, you won’t be able
        to get your free month of Cloaked Premium.
      </AppModalParagraph>
      <AppModalFooter>
        <Button type="secondary" @click="dismissOnboarding"> Dismiss </Button>
        <Button @click="$emit('input', false)"> Don't dismiss </Button>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>

<script>
import AppModal from "@/components/ui/AppModal";
import AppModalTitle from "@/components/ui/AppModalTitle";
import AppModalParagraph from "@/components/ui/AppModalParagraph";
import AppModalContent from "@/components/ui/AppModalContent";
import Button from "@/components/Button.vue";
import AppModalFooter from "@/components/ui/AppModalFooter";

export default {
  components: {
    AppModalFooter,
    AppModalContent,
    AppModalParagraph,
    AppModalTitle,
    AppModal,
    Button,
  },
  methods: {
    dismissOnboarding() {
      this.$emit("input", false);
      this.$toast.success("You’re all done! Redirecting to your identities...");
      setTimeout(() => {
        this.$store.dispatch("onboarding/dismissOnboarding");
      }, 5000);
    },
  },
};
</script>
