var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "label",
    { staticClass: "identity-sharing-permission" },
    [
      _c(
        _setup.InputCheckbox,
        _vm._g(
          _vm._b({ attrs: { id: _vm.id } }, "InputCheckbox", _vm.$attrs, false),
          _vm.$listeners
        )
      ),
      _c("span", { staticClass: "identity-sharing-permission__label" }, [
        _vm._v(" " + _vm._s(_vm.label) + " "),
      ]),
      _c(
        "span",
        { staticClass: "identity-sharing-permission__icon" },
        [
          [
            "name",
            "email",
            "password",
            "phone",
            "username",
            "website",
            "notes",
            "cloaked",
            "totp",
          ].includes(_setup.iconType)
            ? _c(_setup.IdentityFieldIcon, { attrs: { type: _setup.iconType } })
            : _c(_setup.CustomFieldIcon, { attrs: { type: _setup.iconType } }),
        ],
        1
      ),
      !_setup.isTotp
        ? _c(
            _setup.SecretValue,
            {
              staticClass: "identity-sharing-permission__value",
              attrs: { "is-hidden": _vm.isSecret },
            },
            [_vm._v(_vm._s(_setup.stringValue) + " ")]
          )
        : _c(_setup.TOTPToken, {
            attrs: {
              url: _setup.stringValue.startsWith("otpauth://")
                ? _setup.stringValue
                : undefined,
              secret: _setup.stringValue.startsWith("otpauth://")
                ? undefined
                : _setup.stringValue,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }