var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.SidebarHeaderButton,
    { staticClass: "sidebar-close-button" },
    [_c(_setup.SmallX)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }