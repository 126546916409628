<template>
  <section class="activity-convo-wrapper">
    <ActivityConvoHeader
      :senderDisplay="senderDisplay(thread, true)"
      :cloakName="thread.identity.nickname"
      :cloakIdentifier="
        thread?.cloaked_number
          ? formatPhoneNumber(thread.recipient)
          : thread.recipient
      "
      :conversationId="thread.conversation_id"
      :read="thread.read"
      @onDelete="$emit('removed', thread)"
      @openCloak="goToCloak"
      @onReadToggle="updateReadStatus"
    />
    <ActivityAnonymousContactHeader
      :name="thread.identity?.nickname"
      @click="goToCloak"
      :contact="thread.sender_contact"
      v-if="!contactIsApproved"
    />
    <section
      :class="{
        [getListClass]: true,
        'activity-convo-list--protected-identity': thread?.identity?.protected,
      }"
      ref="activity-convo-list"
      v-if="loaded"
    >
      <div
        v-for="(instance, idx) in conversationList"
        :key="`convo-list-${instance.id}-${idx}`"
      >
        <ActivityMessageInstance
          v-if="instance.message"
          :message="instance.message"
          :senderDisplay="senderDisplay(instance.message)"
          :sameAsPrevSender="sameAsPrevSender(instance.message, idx + 1)"
          @openCloak="goToCloak"
        />
        <ActivityCallInstance
          v-else-if="instance.call"
          :call="instance.call"
          :senderDisplay="senderDisplay(instance.call)"
          :sameAsPrevSender="sameAsPrevSender(instance.call, idx + 1)"
          @openCloak="goToCloak"
        />
      </div>
      <InfiniteTrigger
        v-if="hasLoaded"
        key="activity-convo"
        @infinite="getConversationActivity"
        style="align-self: center; width: 100%"
        ref="convoInfinite"
      ></InfiniteTrigger>
    </section>
    <div v-else class="loader">
      <div>
        <img src="@/assets/icons/small-spinner.png" width="30" /><br />
        Loading messages....
      </div>
    </div>
    <ActivityConvoReply
      v-if="!thread?.identity?.protected"
      :thread="thread"
      @refresh="refresh"
      @setContactStatus="setContactStatus"
    />
  </section>
</template>
<script>
import api from "@/api/api";
import {
  ActivityMessageInstance,
  ActivityCallInstance,
  ActivityConvoHeader,
  ActivityConvoReply,
  ActivityAnonymousContactHeader,
  InfiniteTrigger,
} from "@/components";
import { getContactName, phone_format } from "@/scripts/format";

export default {
  name: "ActivityConversationDisplay",
  props: ["thread"],
  components: {
    InfiniteTrigger,
    ActivityMessageInstance,
    ActivityCallInstance,
    ActivityConvoHeader,
    ActivityConvoReply,
    ActivityAnonymousContactHeader,
  },
  data() {
    return {
      nextUrl: `/api/v1/cloaked/activity/?conversation_id=${this.thread.conversation_id}&ordering=-created_at`,
      fetching: false,
      conversationList: [],
      loaded: false,
      hasLoaded: false,
    };
  },
  mounted() {
    if (!this.thread.read) {
      this.mark_read_thread();
    }
    this.getConversationActivity(this.$refs.convoInfinite);
  },
  computed: {
    contactIsApproved() {
      return (
        !this.thread.sender_contact ||
        this.thread.sender_contact.status.includes("approve")
      );
    },
    getListClass() {
      if (this.contactIsApproved) {
        return "activity-convo-list";
      }
      return "extended";
    },
  },
  methods: {
    setContactStatus(status) {
      this.$emit("updated", {
        ...this.thread,
        sender_contact: {
          ...this.thread.sender_contact,
          status: status,
        },
      });
      this.$emit("refresh");
    },
    formatPhoneNumber(phone) {
      return phone_format(phone);
    },
    mark_read_thread() {
      let url = "/api/v1/cloaked/activity/read-conversation/";
      if (this.thread.read) {
        url = "/api/v1/cloaked/activity/unread-conversation/";
      }
      api()
        .post(url, {
          conversation_id: this.thread.conversation_id,
        })
        .then(() => {
          this.$emit("updated", {
            ...this.thread,
            read: !this.thread.read,
          });
          window.dispatchEvent(new CustomEvent("activity_read"));
        });
    },
    goToCloak() {
      if (this.thread.identity.protected) {
        return;
      }

      if (
        !this.$store.state.rightPanel.cloak ||
        this.$store.state.rightPanel.cloak.id !== this.thread.identity.id
      ) {
        this.$store.dispatch("openCloakDetails", {
          cloak: this.thread.identity,
        });
        api()
          .get(
            `/api/v1/cloaked/identity/${this.thread.identity.id}/?populated=true`
          )
          .then(({ data }) => {
            this.$store.dispatch("openCloakDetails", {
              cloak: data,
            });
          });
      }
    },
    updateReadStatus(status) {
      this.$emit("updated", {
        ...this.thread,
        read: status,
      });
    },
    refresh() {
      this.nextUrl = `/api/v1/cloaked/activity/?conversation_id=${this.thread.conversation_id}&ordering=-created_at`;
      this.getConversationActivity();
    },
    sameAsPrevSender(instance, prevIdx) {
      if (prevIdx >= 0) {
        const currSender = this.senderDisplay(instance);
        const prevInstance = this.conversationList[prevIdx];
        if (prevInstance) {
          const prevSender = this.senderDisplay(
            prevInstance.message || prevInstance.call
          );
          return currSender === prevSender;
        }
      }
      return false;
    },
    senderDisplay(instance, isHeader) {
      let senderName = "";
      if (instance?.sender_contact) {
        const { firstName, lastName } = getContactName(instance.sender_contact);

        if (firstName?.length || lastName?.length) {
          senderName = `${firstName} ${lastName}`.trim();
        } else if (instance?.sender_contact?.cloak_contact_phone?.length) {
          senderName = phone_format(
            instance.sender_contact.cloak_contact_phone
          );
        }
      }

      if (!isHeader && !instance.inbound) {
        const firstName = this.$store.state.autofill?.first_name;
        const lastName = this.$store.state.autofill?.last_name;
        if (firstName?.length || lastName?.length) {
          senderName = `${firstName || ""} ${lastName || ""}`.trim();
        } else {
          senderName = this.$store.getters["authentication/username"];
        }
      }
      return senderName;
    },
    getConversationActivity($state) {
      if (!this.fetching && this.nextUrl) {
        this.fetching = true;
        api()
          .get(this.nextUrl)
          .then(({ data }) => {
            const isFirstPage =
              !this.nextUrl.includes("page=") || /page=1\b/.test(this.nextUrl);
            if (isFirstPage) {
              this.conversationList = data.results;
            } else {
              this.conversationList = [
                ...this.conversationList,
                ...data.results,
              ];
            }
            this.nextUrl = data.next;
            this.fetching = false;
            this.loaded = true;
            this.$nextTick(() => {
              this.hasLoaded = true;
            });
            if (!data.next && $state) {
              $state.complete();
            } else if (data.next && $state) {
              $state.loaded();
            }
          })
          .catch(() => {
            this.$nextTick(() => {
              this.hasLoaded = true;
            });
            this.fetching = false;
          });
      } else {
        this.$nextTick(() => {
          this.hasLoaded = true;
        });
        if ($state) {
          $state.complete();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.extended {
  padding-bottom: 200px;
}

.activity-convo-wrapper {
  display: flex;
  flex-direction: column;
  // height - app header
  height: calc(100vh - 69px);
  padding: 0 0 24px !important;

  .activity-convo-list {
    // height - app header - convo header - reply box
    height: calc(100vh - 69px - 85px - 114px);
    overflow-y: auto;
    padding: 0 0 24px;
    display: flex;
    flex-direction: column-reverse;

    &--protected-identity {
      height: 100vh !important;
    }
  }
}
.loader {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: $color-primary-50;
  img {
    margin-bottom: 25px;
    animation: rotation 2s infinite linear;
  }
  text-align: center;
}

.app--visible-banner {
  .activity-convo-wrapper {
    height: calc(100vh - 69px - 40px);

    .activity-convo-list {
      // height - app header - convo header - reply box - banner height
      height: calc(100vh - 69px - 85px - 114px - 40px);
    }
  }

  .loader {
    height: calc(100vh - 200px - 40px);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
