var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "ui-switch-input",
      class: {
        "ui-switch-input--checked": _vm.value,
        "ui-switch-input--disabled": _vm.disabled,
      },
    },
    [
      _c("input", {
        attrs: {
          "aria-id": "SwitchToggleButton",
          type: "checkbox",
          name: "checkbox",
          autocomplete: "off",
          "data-lpignore": "true",
          "data-form-type": "other",
          disabled: _vm.disabled,
        },
        domProps: { checked: _vm.value },
        on: { change: _vm.handleChange },
      }),
      _c("div", { staticClass: "ui-switch-input__pill" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }