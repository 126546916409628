<script setup>
import IdentitySharingButton from "@/components/cloakDetails/IdentitySharing/IdentitySharingButton.vue";
import IdentitySharingModalCreate from "@/components/cloakDetails/IdentitySharing/IdentitySharingModal/IdentitySharingModalCreate.vue";
import IdentitySharingModalPublished from "@/components/cloakDetails/IdentitySharing/IdentitySharingModal/IdentitySharingModalPublished.vue";
import IdentitySharingModalDelete from "@/components/cloakDetails/IdentitySharing/IdentitySharingModal/IdentitySharingModalDelete.vue";
import { defineProps } from "vue";

defineProps({
  identity: {
    type: Object,
    default: () => ({}),
  },
  activeModal: {
    type: Object,
    default: () => ({}),
  },
});
</script>

<template>
  <div>
    <IdentitySharingButton
      @click="$attrs.hasAnnouncementTooltip || $emit('input', true)"
      :value="$attrs.isTooltipOpen"
      @input="$emit('set-is-tooltip-open', $event)"
      :is-active="$attrs.value"
      :is-shared="$attrs.isShared"
      :hasAnnouncementTooltip="$attrs.hasAnnouncementTooltip"
    />
    <Transition mode="out-in" appear v-if="$attrs.value">
      <Component
        :is="activeModal"
        :is-shared="$attrs.isShared"
        :identity="identity"
        without-overlay
        @open-create="$emit('set-active-modal', IdentitySharingModalCreate)"
        @open-published="
          $emit('set-active-modal', IdentitySharingModalPublished)
        "
        @open-delete="$emit('set-active-modal', IdentitySharingModalDelete)"
        v-on="$listeners"
        v-bind="$attrs"
      />
    </Transition>
    <portal to="root">
      <Transition name="identity-sharing-background" v-if="$attrs.value" appear>
        <div class="identity-sharing-background" />
      </Transition>
    </portal>
  </div>
</template>

<style lang="scss">
.identity-sharing-background {
  position: fixed;
  inset: 0;
  background: rgba($black, 0.5);
  opacity: 1;
  z-index: 1000;
}

.identity-sharing-background-enter-active,
.identity-sharing-background-leave-active {
  transition: opacity 0.45s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.identity-sharing-background-enter,
.identity-sharing-background-leave-to {
  opacity: 0;
}
</style>
