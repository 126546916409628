<script setup>
import { ref, defineProps, computed, watch } from "vue";
import { UiTooltip } from "@/components";
import { MoreInfoQIcon } from "@/assets/icons";
import UiSwitchInput from "@/components/UiSwitchInput.vue";
import { IdentityService } from "@/api";

const cardLockEnum = {
  locked_by_user: true,
  unlocked: false,
};

const props = defineProps(["cloak"]);
const cardInfo = computed(() => props.cloak?.cloaked_card);
const lockCardSwitch = ref(false);
const apiLoading = ref(false);

watch([cardInfo], (newValue) => {
  const info = newValue[0];
  lockCardSwitch.value = cardLockEnum[info.state];
});

const saveLockState = async (value) => {
  try {
    apiLoading.value = true;
    value
      ? await IdentityService.lockCloakedCard(
          cardInfo.value.identity_id,
          cardInfo.value.id
        )
      : await IdentityService.unlockCloakedCard(
          cardInfo.value.identity_id,
          cardInfo.value.id
        );
    lockCardSwitch.value = value;
  } finally {
    apiLoading.value = false;
  }
};
</script>

<template>
  <div class="block">
    <div class="header">
      <h1>Card Settings</h1>
      <UiTooltip :title="' '" position="top" max-width="192" align-x="center">
        <MoreInfoQIcon />
      </UiTooltip>
    </div>
    <div class="card">
      <div class="card-item">
        <div class="title">
          <h1>Lock card</h1>
          <p class="description">Prevent unwanted transactions</p>
        </div>
        <UiSwitchInput
          :value="lockCardSwitch"
          :disabled="apiLoading"
          @change="saveLockState"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.block {
  padding: 20px;
  h1 {
    color: $color-primary-100;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }
  .header {
    display: flex;
    gap: 4px;
    color: $color-primary-100;
  }
  .card {
    border: 1px solid $color-primary-100-light-10;
    border-radius: 15px;
    padding: 20px;
    margin-top: 20px;

    .card-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        .description {
          color: $color-primary-60-light;
          font-size: 12px;
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
