<template>
  <button
    class="review-toggle-all"
    @click="onToggleAll"
    :class="{ 'review-toggle-all--disabled': disabled }"
  >
    <CheckboxChecked v-if="areAllSelected" class="review-toggle-all__icon" />
    <CheckboxSome v-else-if="areSomeSelected" class="review-toggle-all__icon" />
    <CheckboxUnchecked v-else class="review-toggle-all__icon" />
  </button>
</template>

<script>
import CheckboxChecked from "@/assets/icons/checkbox-checked.svg";
import CheckboxUnchecked from "@/assets/icons/checkbox-unchecked.svg";
import CheckboxSome from "@/assets/icons/checkbox-some.svg";

export default {
  components: {
    CheckboxChecked,
    CheckboxUnchecked,
    CheckboxSome,
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    areAllSelected() {
      return (
        this.values.length && this.values.every((id) => this.value.includes(id))
      );
    },
    areSomeSelected() {
      return (
        !this.areAllSelected &&
        this.values.some((id) => this.value.includes(id))
      );
    },
  },
  methods: {
    onToggleAll() {
      if (this.disabled) {
        return;
      }

      if (this.areAllSelected) {
        this.$emit(
          "input",
          this.value.filter((selectedValue) =>
            this.values.every((value) => value !== selectedValue)
          )
        );
        return;
      }

      this.$emit("input", Array.from(new Set([...this.value, ...this.values])));
    },
  },
};
</script>

<style lang="scss">
.review-toggle-all {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: $color-primary-100;

  &:hover {
    .review-toggle-all__icon {
      opacity: 0.9;
    }
  }

  &--disabled {
    opacity: 1;
    color: $color-primary-60;
    cursor: not-allowed;

    .review-toggle-all__icon {
      opacity: 1 !important;
    }
  }
}
</style>
