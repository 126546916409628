var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "UiMenu",
    {
      attrs: {
        width: "188px",
        "has-event-offset": true,
        "has-click-open": false,
        "has-right-click-open": true,
        "z-index": 9,
      },
      on: {
        open: function ($event) {
          return _vm.$emit("select")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "content",
          fn: function () {
            return [
              _c("UiMenuButton", {
                attrs: { title: `Select messages` },
                on: {
                  click: function ($event) {
                    return _vm.$emit("setMultiSelect", true)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [_c("SelectMessagesIcon")]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("UiMenuButton", {
                attrs: { title: `Select all` },
                on: {
                  click: function ($event) {
                    return _vm.$emit("toggleSelectAll")
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [_c("SelectAllIcon")]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("UiMenuButton", {
                attrs: { title: `Mark as ${_vm.readOrUnread}` },
                on: {
                  click: function ($event) {
                    return _vm.$emit("markAsRead", _vm.readOrUnread)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _vm.readOrUnread === "read"
                          ? _c("ActivityMarkAsReadIcon")
                          : _c("ActivityMarkAsUnReadIcon"),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("UiMenuSeparator"),
              _c(
                "div",
                { staticClass: "alert-text" },
                [
                  _c("UiMenuButton", {
                    attrs: { title: "Delete", danger: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("showDeleteModal")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [_c("DeleteTrashIcon")]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        {
          staticClass: "preview-item-outer",
          class: {
            multiSelectEnabled: _vm.multiSelectEnabled,
            selected: _vm.selected,
          },
          attrs: { id: `preview-item-outer-${_vm.thread.id}` },
          on: {
            click: function ($event) {
              return _vm.$emit("select")
            },
          },
        },
        [
          _vm.multiSelectEnabled
            ? _c(
                "div",
                [
                  _vm.selected
                    ? _c("CheckDarkCircleIcon")
                    : _c("div", { staticClass: "preview-unselected-circle" }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "preview-item-wrapper",
              class: {
                unread: !_vm.thread.read,
                selected: _vm.selected,
                multiSelectEnabled: _vm.multiSelectEnabled,
              },
              attrs: {
                "aria-id": `ActivityRelatedIdentityNickname.${
                  _vm.identity?.nickname || ""
                }`,
              },
            },
            [
              _vm.isCloakSpecificView
                ? _c(
                    "div",
                    {
                      staticClass: "preview-icon",
                      class: { unread: !_vm.thread.read },
                    },
                    [
                      _vm.contactIsKnown
                        ? _c("ContactIcon", {
                            attrs: {
                              initials: _vm.getInitials,
                              override: { height: "40px", width: "40px" },
                            },
                            on: {
                              mouseenter: function ($event) {
                                return _vm.toggleContactDetails(true)
                              },
                              mouseleave: function ($event) {
                                return _vm.toggleContactDetails(false)
                              },
                            },
                          })
                        : _vm.threadType === "email"
                        ? _c("EmailIcon")
                        : _vm.threadType === "text"
                        ? _c("TextIcon")
                        : _vm.threadType === "call"
                        ? _c("CallIcon")
                        : _vm._e(),
                      _vm.contactIsKnown && _vm.showContactDetails
                        ? _c(
                            "div",
                            {
                              staticClass: "contact-details",
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.toggleContactDetails(true)
                                },
                                mouseleave: function ($event) {
                                  return _vm.toggleContactDetails(false)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "contact-details-container" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "contact-detail-header" },
                                    [
                                      _vm.contactIsKnown
                                        ? _c("ContactIcon", {
                                            attrs: {
                                              initials: _vm.getInitials,
                                            },
                                          })
                                        : _vm._e(),
                                      _c("div", [
                                        _c("h1", [
                                          _vm._v(_vm._s(_vm.senderName)),
                                        ]),
                                        _c("h2", [
                                          _vm._v(_vm._s(_vm.senderContact)),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "contact-detail-actions" },
                                    [
                                      _c(
                                        "button",
                                        { on: { click: _vm.openCompose } },
                                        [
                                          _c("TextOutlineIcon"),
                                          _vm._v(" Send Message "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "button",
                                        { on: { click: _vm.openDetails } },
                                        [
                                          _c("EditIcon"),
                                          _vm._v(" Edit Details"),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "preview-icon-identity-wrapper" },
                    [
                      _c("IdentityIcon", {
                        attrs: {
                          identity: _vm.identity,
                          override: { width: "32px", height: "32px" },
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "preview-icon-mini",
                          class: { unread: !_vm.thread.read },
                        },
                        [
                          _vm.threadType === "email"
                            ? _c("EmailIcon")
                            : _vm.threadType === "text"
                            ? _c("TextIcon")
                            : _vm.threadType === "call"
                            ? _c("CallIcon")
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c("div", { staticClass: "preview-thread-row" }, [
                _c(
                  "div",
                  {
                    staticClass: "preview-sender-row",
                    class: { unread: !_vm.thread.read },
                    attrs: { "aria-id": `Sender.${_vm.senderContact || ""}` },
                  },
                  [
                    _c("div", [
                      _c("span", { ref: "prevIdentifierEl" }, [
                        _vm._v(" " + _vm._s(_vm.senderIdentifier) + " "),
                      ]),
                      _vm.senderLocation
                        ? _c("span", [_vm._v(" • ")])
                        : _vm._e(),
                      _vm.senderLocation
                        ? _c("span", { staticClass: "location" }, [
                            _vm._v(" " + _vm._s(_vm.senderLocation) + " "),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(" " + _vm._s(_vm.thread.timeDisplay) + " "),
                    ]),
                  ]
                ),
                _vm.subject
                  ? _c(
                      "div",
                      {
                        ref: "prevSubjectEl",
                        staticClass: "preview-subject",
                        class: { unread: !_vm.thread.read },
                        attrs: { "aria-id": `Subject.${_vm.subject || ""}` },
                      },
                      [_vm._v(" " + _vm._s(_vm.subject) + " ")]
                    )
                  : _vm._e(),
                _vm.threadType === "text" || _vm.threadType === "call"
                  ? _c(
                      "div",
                      {
                        ref: "prevBodyEl",
                        staticClass: "preview-body",
                        attrs: { "aria-id": `Body.${_vm.body || ""}` },
                      },
                      [_vm._v(" " + _vm._s(_vm.body) + " ")]
                    )
                  : _vm._e(),
                _vm.thread.missed
                  ? _c("div", { staticClass: "preview-missed" }, [
                      _vm._v("Missed Call"),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "preview-pill-row" },
                  [
                    _vm.unreadCount
                      ? _c(
                          "div",
                          {
                            staticClass: "preview-pill",
                            class: { unread: !_vm.thread.read },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(`+${_vm.unreadCount} more`) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._l(_vm.thread.attachments, function (attachment) {
                      return _c(
                        "div",
                        {
                          key: `activity-attachments-${attachment.id}`,
                          staticClass: "preview-pill",
                          class: { unread: !_vm.thread.read },
                        },
                        [_vm._v(" " + _vm._s(attachment.file_name) + " ")]
                      )
                    }),
                    _vm._l(_vm.thread.recordings, function (recording, idx) {
                      return _c(
                        "div",
                        {
                          key: `activity-recordings-${recording.id}`,
                          staticClass: "preview-pill",
                          class: { unread: !_vm.thread.read },
                          attrs: { id: `activity-recordings-${recording.id}` },
                        },
                        [
                          _c("VoicePlayIcon", {
                            attrs: { recording: recording, idx: idx },
                          }),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }