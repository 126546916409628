<script setup>
import SettingsChildPage from "./SettingsChildPage.vue";
import { SettingsTitle, SettingsParagraph } from "@/components";
import CloakedCards from "@/routes/modals/preferences/CloakedCards.vue";
</script>

<template>
  <SettingsChildPage>
    <template #aside>
      <SettingsTitle>Cloaked Pay</SettingsTitle>
      <SettingsParagraph>
        <p>
          Keep your personal cards protected with Cloaked Pay - powered by
          Mastercard.
        </p>

        <p>
          For more information, please take a look at the
          <a href="https://www.cloaked.app/terms-of-service" target="_blank"
            >Terms of Service</a
          >
          and
          <a href="https://www.cloaked.app/privacy-policy" target="_blank"
            >Privacy Policy</a
          >.
        </p>
      </SettingsParagraph>
    </template>

    <CloakedCards />
  </SettingsChildPage>
</template>
