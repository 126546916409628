<template>
  <PreferencesPanel v-if="showNewMenu">
    <ValueDisplay
      v-if="!primaryEmail"
      label="Add a recovery email"
      @add="handleAddEmail"
      @click="handleAddEmail"
    />
    <ValueDisplay
      v-else
      label="Recovery email"
      :value="primaryEmail.email"
      @edit="handleAddEmail"
      @delete="handleDelete(primaryEmail.id)"
    />
  </PreferencesPanel>
  <PreferencesPanel v-else>
    <template v-slot:header>
      <PreferencesHeader @go-back="toggle_back" />
    </template>

    <PreferencesTitle>Recovery email address</PreferencesTitle>

    <PreferencesParagraph v-if="!primaryEmail">
      Add a recovery email address to ensure you can access your Cloaked
      account.
    </PreferencesParagraph>

    <ValueDisplay
      label="Recovery Email"
      v-else-if="primaryEmail.email"
      :value="primaryEmail.email"
      @delete="handleDelete(primaryEmail.id)"
    />

    <Button @click="handleAddEmail">
      {{
        primaryEmail ? "Use a different email address" : "Add an email address"
      }}
    </Button>
  </PreferencesPanel>
</template>

<script>
import { ACCOUNT_RECOVERY_NEW_MENU } from "@/scripts/featureFlags";
import api from "@/api/api";
import { mapActions } from "vuex";
import ValueDisplay from "@/components/ui/value-display";
import {
  PreferencesHeader,
  PreferencesTitle,
  PreferencesParagraph,
  PreferencesPanel,
} from "@/routes/modals/preferences";
import { AddVerifyEmail } from "@/components/modals";
import { Button } from "@/components";

export default {
  name: "AccountRecoveryEmail",
  props: ["emails"],
  components: {
    Button,
    PreferencesHeader,
    PreferencesTitle,
    PreferencesParagraph,
    PreferencesPanel,
    ValueDisplay,
  },
  computed: {
    primaryEmail() {
      return this.emails.find((email) => email.verified && email.primary);
    },
    showNewMenu() {
      return ACCOUNT_RECOVERY_NEW_MENU;
    },
  },

  methods: {
    ...mapActions(["openModal", "openGlobalDeleteModal"]),

    toggle_back() {
      this.$emit("toggleBack", {});
    },

    handleAddEmail() {
      this.openModal({
        customTemplate: {
          template: AddVerifyEmail,
          props: {
            setPrimary: true,
            title: this.primaryEmail
              ? "Enter a new recovery email"
              : "Add an email address",
          },
          events: {
            "email-verified": this.refresh,
            "email-created": this.refresh,
          },
        },
      });
    },

    handleDelete(emailId) {
      const email = this.emails.find((item) => item.id === emailId);

      this.openGlobalDeleteModal({
        type: "email",
        onClick: () => {
          api()
            .delete(email.url)
            .then(() => {
              this.$emit("delete", emailId);
              this.$toast.success("Email deleted");
            })
            .catch(() => {
              this.$toast.error(
                "Email is in use for 2FA. To delete, please remove from 2FA first."
              );
            });
        },
      });
    },

    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>
