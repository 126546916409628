import moment from "moment";

import store from "@/store";
import { cleanup, initiateEncryption } from "./encryption";
import AuthService from "@/api/actions/auth-service";
import UserService from "@/api/actions/user-service";
import api from "@/api/api";
import querystring from "querystring";
import { SubscriptionService } from "@/api";
let last_check;

export const auth_channel = new BroadcastChannel("auth_channel");
export const refresh_channel = new BroadcastChannel("refresh_channel");

function generateRandomString(length) {
  return Array.from(
    window.crypto.getRandomValues(new Uint8Array(Math.ceil(length / 2))),
    (b) => ("0" + (b & 0xff).toString(16)).slice(-2)
  ).join("");
}

async function generateCodeChallenge(algorithm = "SHA-256", codeVerifier) {
  const digest = await crypto.subtle.digest(
    algorithm,
    new TextEncoder().encode(codeVerifier)
  );

  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}

async function generateCodeChallengeAndVerifier(
  algorithm = "SHA-256",
  length = 43
) {
  const codeVerifier = generateRandomString(length);
  const codeChallenge = await generateCodeChallenge(algorithm, codeVerifier);

  return [codeVerifier, codeChallenge];
}

export async function generatePkceRequirements() {
  return await generateCodeChallengeAndVerifier();
}

window.addEventListener("storage", (storage) => {
  if (storage.key === "logout" && storage.newValue === "true") {
    logout();
  }
});
export const logout = (dontSendEvent = false) => {
  AuthService.logout().then(() => {
    cleanup();
    if (!dontSendEvent) {
      auth_channel.postMessage("logout");
    }
    store.dispatch("logout");
    window.Appcues?.off("step_completed");
    window.Appcues?.off("flow_attempted");
  });
};

export const handleLoginWithCode = (response_code) => {
  const codeVerifier = window.localStorage.getItem("codeVerifier");
  const code = response_code;
  const data = {
    grant_type: "authorization_code",
    client_id: window.ENV.VUE_APP_CLIENT_ID,
    redirect_uri: window.ENV.VUE_APP_REDIRECT_URI,
    code: code,
    code_verifier: codeVerifier,
  };

  return AuthService.login(data)
    .then((response) => {
      store.commit("insertAccessToken", response.data.access_token);
      store.commit("insertRefreshToken", response.data.refresh_token);
      store.commit("insertExpiresAt", response.data.expires_in);
      store.commit("insertScope", response.data.scope);
      store.commit("insertLoggedIn", true);
      window.localStorage.removeItem("codeVerifier");
      const search = localStorage.getItem("on_login");
      if (search) {
        localStorage.removeItem("on_login");
        window.location.search = search;
      }
      return true;
    })
    .catch(() => {
      logout();
    });
};

export const checkRefresh = async (count = 0) => {
  window.localStorage.removeItem("logout");
  return new Promise((resolve) => {
    if (!last_check || moment(last_check).isAfter(moment())) {
      if (count < 3) {
        if (isTokenExpired()) {
          return refreshToken()
            .then(() => {
              resolve();
            })
            .catch(() => {
              return checkRefresh(count + 1);
            });
        } else {
          resolve();
        }
      } else {
        logout();
      }
    }
    return resolve();
  });
};

export let isTokenExpired = () => {
  let check = true;
  if (store.state.account.expires_at) {
    const current_time = moment();
    const expiry_time = moment(store.state.account.expires_at);
    check = current_time.isAfter(expiry_time);
  }
  return check;
};

export const refreshToken = () => {
  const payload = {
    grant_type: "refresh_token",
    client_id: global.ENV.VUE_APP_CLIENT_ID,
    refresh_token: store.getters.refresh_token,
  };
  const headers = {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
    },
  };
  const anonymous = true;
  return api(anonymous)
    .post("/o/token/", querystring.stringify(payload), headers)
    .then((response) => {
      if (!store.state.authentication.user) {
        store.dispatch("getUser");
      }
      return store.dispatch("authentication/setAccessToken", {
        oauth: response.data,
      });
    })
    .catch(() => {
      return store.dispatch("logout");
    });
};

let appBootPromise = null;

export let appBoot = async () => {
  if (appBootPromise) {
    return appBootPromise;
  }
  await refreshToken();
  await initiateEncryption();
  appBootPromise = new Promise((resolve) => {
    setTimeout(async () => {
      await store.dispatch("authentication/getUser");
      store.dispatch("authentication/setRefreshTimeout");
      await UserService.getFlags();
      await SubscriptionService.getSubscription();
      await SubscriptionService.getPlanLimits();
      resolve();
    }, 500);
  });

  return appBootPromise;
};
