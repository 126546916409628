import router from "@/routes/router";
import UserService from "@/api/actions/user-service";
import store from "@/store";

export const STEP_DEMO = "STEP_DEMO";
export const STEP_FORWARDING = "STEP_FORWARDING";
export const STEP_APP = "STEP_APP";
export const STEP_IMPORT = "STEP_IMPORT";
export const STORAGE_KEY_ONBOARDING_DISMISSED = "ONBOARDING_DISMISSED";
export const STORAGE_KEY_ONBOARDING_COMPLETED = "ONBOARDING_COMPLETED";

export default {
  namespaced: true,
  state: {
    isAvailable: false,
    availableSteps: [],
    completedSteps: [],
  },
  mutations: {
    setIsAvailable: (state, payload) => (state.isAvailable = payload),
    setAvailableSteps: (state, payload) => (state.availableSteps = payload),
    setCompletedSteps: (state, payload) => (state.completedSteps = payload),
  },
  actions: {
    close() {
      router.push({ name: "Onboarding" });
    },
    dismissOnboarding({ commit }) {
      commit("setIsAvailable", false);
      UserService.setFlags({ [STORAGE_KEY_ONBOARDING_DISMISSED]: true });
      router.push("/");
    },
    completeOnboarding({ commit }) {
      commit("setIsAvailable", false);
      UserService.setFlags({ [STORAGE_KEY_ONBOARDING_COMPLETED]: true });
      router.push("/");
    },
    completeStep({ commit, state }, step) {
      if (!state.completedSteps.includes(step)) {
        commit("setCompletedSteps", [...state.completedSteps, step]);
        store.dispatch("sendOnboardingStepCompleteAnalytics", { step });
        UserService.setFlags({ [`${step}_COMPLETED`]: true });
      }
    },
  },
  getters: {
    isAvailable: (state) => state.isAvailable,
    availableSteps: (state) => state.availableSteps,
    completedSteps: (state) => state.completedSteps,
    isStepCompleted: (state) => (step) => state.completedSteps.includes(step),
  },
};
