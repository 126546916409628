var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { disabled: _vm.disabled } }, [
    _c("input", {
      ref: "fileupload",
      staticStyle: { opacity: "0", display: "none", position: "absolute" },
      attrs: { type: "file", multiple: "" },
      on: { change: _vm.handleUpload },
    }),
    _c(
      "button",
      { on: { click: _vm.openFile } },
      [
        _vm._t("icon", function () {
          return [_c("Paperclip")]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }