<template>
  <section class="activity-call-section">
    <ActivityConvoNameTimeDisplay
      v-if="!sameAsPrevSender"
      :senderDisplay="senderDisplay"
      :inbound="call.inbound"
      :createdAt="call.created_at"
      @openCloak="$emit('openCloak')"
    />

    <div v-if="call.recordings && call.recordings.length">
      <div class="voice-message">
        <VoicemailPlayer
          v-for="(recording, idx) in call.recordings"
          :key="`voicemail-${idx}`"
          :recording="recording"
          :idx="idx"
        />
      </div>
    </div>

    <div
      v-else
      class="activity-call-display-pill"
      :class="{ outbound: !call.inbound }"
    >
      <div>
        <ActivityPhoneIcon
          :class="
            callType.toLowerCase().includes('out') ? 'lightIcon' : 'darkIcon'
          "
        />
        <span class="activity-call-type">
          {{ callType }}
        </span>
      </div>
      <span class="activity-call-time">
        {{ timeDisplay }}
      </span>
    </div>
  </section>
</template>
<script>
import moment from "moment";
import { ActivityConvoNameTimeDisplay } from "@/components";
import { ActivityPhoneIcon } from "@/assets/icons";
import VoicemailPlayer from "@/components/activity/VoicemailPlayer";
export default {
  name: "ActivityCallItem",
  props: ["call", "senderDisplay", "sameAsPrevSender"],
  components: {
    VoicemailPlayer,
    ActivityConvoNameTimeDisplay,
    ActivityPhoneIcon,
  },
  data() {
    return {
      audioState: null,
      activeRecordingId: null,
      activeVoicemail: null,
    };
  },
  methods: {
    durationDisplay(voicemail) {
      const played = this.$store.state.media.progress;
      const duration = moment.duration(voicemail.duration);
      duration.subtract(played, "milliseconds");
      const min = duration.minutes().toString().padStart(2, "0");
      const sec = duration.seconds().toString().padStart(2, "0");
      return `${min}:${sec}`;
    },
    pauseVoicemail(recording) {
      this.$store.dispatch("media/playAudio", recording);
    },
    playVoicemail(recording) {
      this.$store.dispatch("media/playAudio", recording);
    },
    resetVoicemailData() {
      this.audioState = null;
      this.activeRecordingId = null;
      this.activeVoicemail = null;
    },
    isTruncated(idx) {
      const el = document.getElementById(`vm-body-${idx}`);
      if (!el) return false;
      return el.scrollHeight > el.clientHeight;
    },
    expandTranscript(idx) {
      const el = document.getElementById(`vm-body-${idx}`);
      el.classList.remove("truncate");
    },
    animationLengthSec(recording) {
      const duration = moment.duration(recording.duration);
      return duration.asSeconds();
    },
  },
  computed: {
    callType() {
      if (this.call.missed) {
        return "Missed Call";
      }
      if (this.call.inbound) {
        return "Inbound Call";
      }
      return "Outbound Call";
    },
    timeDisplay() {
      const when = moment(this.created_at);
      const today = moment().startOf("day");
      const yesterday = moment().subtract(24, "hours").startOf("day");
      const lastYear = moment().subtract(1, "year").startOf("day");
      if (when.isAfter(today)) {
        return when.format("hh:mm a");
      } else if (when.isAfter(yesterday) && when.isBefore(today)) {
        return when.format("hh:mm a");
      } else if (when.isAfter(lastYear)) {
        return when.format("MMM Do");
      } else {
        return when.format("MMM YYYY");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/recursive/_mixins.scss";

.voice-message {
  background-color: $color-primary-5;
  border-radius: 20px;
  max-width: 400px;
  margin-left: 66px;
  margin-top: 4px;
  padding: 20px 16px;
}
.activity-call-section {
  .activity-call-display-pill {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 99px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $color-primary-100;
    padding: 12px 15px;
    width: 240px;
    margin-left: 66px;
    margin-top: 4px;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .activity-call-type {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        letter-spacing: -0.2px;
        margin-left: 10px;
      }
    }

    .activity-call-time {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      display: flex;
      align-items: flex-end;
      text-align: right;
    }

    &.outbound {
      background-color: $color-primary-90;
      border-color: $color-primary-90;
    }
    .darkIcon {
      color: $color-primary-5;
    }
    .lightIcon {
      color: $color-primary-0;
    }
  }

  .activity-call-media {
    max-width: 366px;
    height: auto;
    border-radius: 16px;
    margin: 4px 66px 0 66px;
  }
}

@keyframes animate {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}
</style>
