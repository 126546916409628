var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.CloakDetailsInputRow, {
    ref: "inputRow",
    attrs: {
      label: _setup.label,
      loading: _setup.props.loading,
      placeholder: _setup.props.placeholder,
      disabled: _setup.isDisabled,
      error: _setup.props.error,
      type: _setup.inputType,
      "loading-message": _setup.state.loadingMesssage,
      textarea: _setup.props.field === "notes",
      "tooltip-message": _setup.tooltipMessage,
      warning: !!_setup.warningMessage,
      warningTooltipMessage: _setup.warningMessage,
      "aria-id": `CloakedDetail${_setup.label || ""}Row`,
      maxlength: _setup.props.field === "password" ? 128 : undefined,
      "is-menu-open": _setup.state.isMenuOpen,
      isOnSharedPage: _setup.props.isOnSharedPage,
      "is-editable": _setup.state.isEditable,
      numberLockingToolTipText: _setup.state.numberLockingToolTipText,
      showLockIcon: _setup.showLockIcon,
    },
    on: {
      "click-input-wrapper": _setup.handleClickInputWrapper,
      input: _setup.handleInput,
      focus: _setup.handleFocus,
      blur: _setup.handleBlur,
      save: _setup.save,
    },
    nativeOn: {
      mouseleave: function ($event) {
        return _setup.handleMouseLeave.apply(null, arguments)
      },
      keydown: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        )
          return null
        return _setup.handleEnterPress.apply(null, arguments)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "input-before",
          fn: function () {
            return [_vm._t("input-before")]
          },
          proxy: true,
        },
        {
          key: "input",
          fn: function (props) {
            return [_vm._t("input", null, null, props)]
          },
        },
        {
          key: "tooltip",
          fn: function () {
            return [_vm._t("tooltip")]
          },
          proxy: true,
        },
        {
          key: "icon",
          fn: function () {
            return [
              _setup.props.readOnly
                ? _c(_setup.LockIcon)
                : _setup.state.value && _setup.state.value.length
                ? _c(_setup.CloakIdentifierIcon, {
                    attrs: {
                      field: _setup.props.field,
                      fieldType: _setup.props.fieldType,
                    },
                  })
                : _c(_setup.Plus),
            ]
          },
          proxy: true,
        },
        {
          key: "numberLocking",
          fn: function () {
            return [
              _c(
                _setup.CloakInfoRowButton,
                {
                  attrs: {
                    disabled:
                      _setup.state.lockStatus === "locked" ||
                      _setup.state.lockStatus === "expired",
                    colorOverride: "",
                    icon: "",
                  },
                  on: { click: _setup.handleNumberLockToggle },
                },
                [
                  _setup.state.lockStatus === "open" ||
                  _setup.state.lockStatus === "pending_expiration"
                    ? _c(_setup.OpenLockIcon)
                    : _setup.state.lockStatus === "pending_lock"
                    ? _c(_setup.LockIcon)
                    : _setup.state.lockStatus === "locked"
                    ? _c(_setup.PermalockIcon)
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        !_setup.props.readOnly
          ? {
              key: "actions",
              fn: function () {
                return [
                  _setup.showPasswordRevealButton
                    ? _c(
                        _setup.CloakInfoRowButton,
                        {
                          attrs: { "aria-id": "ShowPasswordButton", icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _setup.handlePasswordReveal.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _setup.state.passwordIsVisible
                            ? _c(_setup.Eye)
                            : _c(_setup.EyeSlash),
                        ],
                        1
                      )
                    : _vm._e(),
                  _setup.showLinkOutButton
                    ? _c(
                        _setup.CloakInfoRowButton,
                        {
                          attrs: { icon: "", href: _setup.props.initialValue },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return (() => {}).apply(null, arguments)
                            },
                          },
                        },
                        [_c(_setup.LinkOutIcon)],
                        1
                      )
                    : _vm._e(),
                  _setup.showGenerateButton
                    ? _c(
                        _setup.CloakInfoRowButton,
                        {
                          attrs: { "aria-id": "GenerateButton" },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _setup.regenerate()
                            },
                          },
                        },
                        [_vm._v(" Generate ")]
                      )
                    : !!_setup.state.value && !_setup.props.isOnSharedPage
                    ? _c(
                        _setup.UiMenu,
                        {
                          attrs: { width: "188px", placement: "bottom-end" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _setup.showEditOption
                                      ? _c(_setup.UiMenuButton, {
                                          attrs: {
                                            "aria-id": `Edit.${
                                              _setup.label || ""
                                            }Button`,
                                            title: "Edit",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _setup.handleEditButton()
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "icon",
                                                fn: function () {
                                                  return [
                                                    _c(_setup.EditPencilIcon),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            2452148809
                                          ),
                                        })
                                      : _vm._e(),
                                    _setup.showGenerateOption
                                      ? _c(_setup.UiMenuButton, {
                                          attrs: {
                                            "aria-id": `Regenerate.${
                                              _setup.label || ""
                                            }Button`,
                                            title: "Generate new",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _setup.regenerate()
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "icon",
                                                fn: function () {
                                                  return [
                                                    _c(_setup.GenerateIcon),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            3351838307
                                          ),
                                        })
                                      : _vm._e(),
                                    _c(_setup.UiMenuButton, {
                                      attrs: {
                                        title: "Copy",
                                        "aria-id": `Copy.${
                                          _setup.props.field || ""
                                        }Button`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _setup.copy()
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "icon",
                                            fn: function () {
                                              return [_c(_setup.CopyIcon)]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        301276141
                                      ),
                                    }),
                                    _c(_setup.UiMenuSeparator),
                                    _c(_setup.UiMenuButton, {
                                      attrs: {
                                        title: "Delete",
                                        "aria-id": `Delete.${
                                          _setup.props.field || ""
                                        }Button`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _setup.showDeleteModal()
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "icon",
                                            fn: function () {
                                              return [
                                                _c(_setup.DeleteTrashIcon, {
                                                  attrs: {
                                                    height: "15px",
                                                    width: "15px",
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3277277838
                                      ),
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            297254965
                          ),
                          model: {
                            value: _setup.state.isMenuOpen,
                            callback: function ($$v) {
                              _vm.$set(_setup.state, "isMenuOpen", $$v)
                            },
                            expression: "state.isMenuOpen",
                          },
                        },
                        [
                          _c(
                            _setup.CloakInfoRowButton,
                            {
                              attrs: {
                                "aria-id": `Menu.${
                                  _setup.props.field || ""
                                }Button`,
                                icon: "",
                                active: _setup.state.isMenuOpen,
                              },
                            },
                            [_c(_setup.KabobIcon)],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
    model: {
      value: _setup.state.value,
      callback: function ($$v) {
        _vm.$set(_setup.state, "value", $$v)
      },
      expression: "state.value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }