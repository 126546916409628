var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("section", { staticClass: "page" }, [
      _c("div", { staticClass: "title" }, [_c("h1", [_vm._v("Transactions")])]),
      _c("img", {
        attrs: {
          src: "https://media3.giphy.com/media/9HQRIttS5C4Za/giphy.gif?cid=ecf05e472dgw7fiwbxh2o3gdb4w9eoxnp5092td95kyys3q4&ep=v1_gifs_search&rid=giphy.gif&ct=g",
          alt: "Loading",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }