<script setup>
import { defineProps } from "vue";
const props = defineProps({
  status: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <div class="toggle" :class="{ active: props.status }">
    <span></span>
  </div>
</template>

<style lang="scss" scoped>
div.toggle {
  display: block;
  background-color: $color-primary-100;
  border: 1px solid $color-primary-100;
  border-radius: 15px;
  width: 60px;
  &.active {
    background-color: $color-success;
    border: 1px solid $color-success;
    span {
      transform: translateX(25px);
    }
  }
  > span {
    background-color: $color-primary-0;
    width: 25px;
    height: 25px;
    border-radius: 45px;
  }
}
</style>
