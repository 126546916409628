<template>
  <section ref="scrollRef" class="email-container">
    <div v-if="loaded">
      <div>
        <header>
          <div class="header-wrapper">
            <div class="header-title">
              <h1>{{ thread.subject }}</h1>
            </div>
            <div class="header-actions" ref="title_kabab">
              <UiMenu width="247px" max-height="300px" placement="bottom-end">
                <UiTooltip title="Options" align-x="center">
                  <span class="kabab"> <Kabob /></span>
                </UiTooltip>
                <template #content>
                  <UiMenuButton
                    :title="thread.read ? 'Mark as unread' : 'Mark as read'"
                    @click="mark_read_thread"
                  >
                    <template v-slot:icon>
                      <Read />
                    </template>
                  </UiMenuButton>
                  <UiMenuSeparator />
                  <div class="danger">
                    <UiMenuButton
                      title="Delete"
                      danger
                      @click="trip_delete_thread"
                    >
                      <template v-slot:icon>
                        <Delete />
                      </template>
                    </UiMenuButton>
                  </div>
                </template>
              </UiMenu>
            </div>
          </div>
        </header>
        <div class="messages">
          <div
            v-for="(message, index) in messages"
            :key="message.id"
            :id="`activity-id-${message.id}`"
            :class="{
              message: true,
              single: messages.length === 1,
              inbound: message.inbound,
            }"
          >
            <div class="email-message" v-if="message.email">
              <ActivityInfoHeader
                :message="message"
                :index="index"
                :thread="thread"
                @removed="() => $emit('removed', message.email)"
                :confirmDelete="confirm_delete"
                :replyTo="reply_to"
              />
              <div
                class="preview"
                v-if="current !== index"
                @click="open = index"
              >
                {{ removeHtmlTags(safe(message.email.body_preview)) }}
              </div>
              <div class="email-content" v-else>
                <SafeUGC :content="iframe_source(message, index)" />
                <div v-if="has_attachments(message)" class="attachment-list">
                  <label v-if="message.email.attachments.length > 1"
                    >{{ message.email.attachments.length }} attachments</label
                  >
                  <label v-else>1 attachment</label>
                  <div>
                    <Attachment
                      :attachment="attachment"
                      v-for="(attachment, index) in message.email.attachments"
                      :key="index"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <InfiniteTrigger @infinite="get_messages"></InfiniteTrigger>
        </div>
        <button
          class="reply_footer"
          v-if="!replyIsOpen"
          @click="openReplyToLatest"
        >
          <span>Write a reply</span>
          <span><SendIcon /></span>
        </button>
      </div>
    </div>

    <div v-else class="loader">
      <div>
        <img src="@/assets/icons/small-spinner.png" width="30" /><br />
        Loading messages....
      </div>
    </div>

    <portal-target name="email-display" multiple />
  </section>
</template>

<script>
import api from "@/api/api";
import InfiniteTrigger from "@/components/global/InfiniteTrigger";
import { getAliasFromEmail } from "@/scripts/format";
import Attachment from "@/routes/inbox/attachments/Attachment";
import { analyticsExport } from "@/mixins/Analytics";
import Kabob from "@/assets/icons/kabob-actionbar.svg";
import Read from "@/assets/icons/read.svg";
import Delete from "@/assets/icons/delete-trash.svg";
import {
  ActivityInfoHeader,
  UiTooltip,
  UiMenuSeparator,
  UiMenuButton,
  UiMenu,
  SafeUGC,
} from "@/components";
import { mapActions } from "vuex";
import SendIcon from "@/assets/icons/compose-send.svg";
import PersonalInfoServices from "@/api/settings/personal-services";
import { safe_html } from "@/scripts/format";

export default {
  name: "ActivityEmailDisplay",
  props: ["thread"],
  mixins: [analyticsExport],
  data() {
    return {
      next: `/api/v1/cloaked/activity/?populated=true&has_email=true&thread_id=${this.thread.thread_id}&ordering=created_at`,
      loaded: false,
      locked: false,
      email: null,
      sent: false,
      title_menu: false,
      item_menu: false,
      sending: false,
      subject: null,
      errors: [],
      html_body: null,
      replyOpened: false,
      disabled: false,
      open: null,
      hasReply: {},
      messages: [],
      showForwardingEmails: false,
    };
  },
  components: {
    SafeUGC,
    UiMenu,
    UiMenuButton,
    UiTooltip,
    UiMenuSeparator,
    Read,
    Kabob,
    Delete,
    SendIcon,
    ActivityInfoHeader,
    Attachment,
    InfiniteTrigger,
  },
  mounted() {
    this.get_messages();
    if (!this.thread.read) {
      this.mark_read_thread();
    }
    if (!this.$store.getters["settings/getVerifiedEmails"].length) {
      PersonalInfoServices.getPersonalEmails();
    }
  },
  computed: {
    verifiedEmails() {
      return this.$store.getters["settings/getVerifiedEmails"];
    },
    forwardingOptionTitle() {
      if (this.verifiedEmails.length) {
        return "Continue with...";
      }
      return "Continue with my personal email";
    },
    replyIsOpen() {
      return !!this.$store.state.compose_message.identity;
    },
    current() {
      return this.open !== null ? this.open : this.messages.length - 1;
    },
  },
  watch: {
    replyOpened(value) {
      if (value) {
        setTimeout(() => {
          this.$refs.replyInput.focus();
        }, 100);
      }
    },
  },
  methods: {
    ...mapActions(["openModal"]),
    safe(html) {
      return safe_html(html);
    },
    removeHtmlTags(html) {
      return html
        .replace(/<.*?script.*?>.*?<\/.*?script.*?>/gim, "")
        .replace(/<[^>]*>?/gm, "");
    },
    openReplyToLatest() {
      this.reply_to(this.messages[this.current]);
    },
    reply_to(message) {
      analyticsExport.methods.sendEvent("Create Draft Message", {
        cloak_name: this.thread.identity.nickname,
        cloak_id: this.thread.identity.id,
        thread_id: this.thread.thread_id,
        message_type: "email",
        message_receive_time: this.thread.created_at,
      });

      this.$store.commit("replyTo", {
        identity: this.thread.identity,
        activity_id: this.thread.activity_id,
        payload: {
          ...message.email,
          onSend: () => {
            this.next = `/api/v1/cloaked/activity/?populated=true&has_email=true&thread_id=${this.thread.thread_id}&ordering=created_at`;
            setTimeout(() => this.get_messages(null, true), 1000);

            analyticsExport.methods.sendEvent("Send Message", {
              cloak_name: this.thread.identity.nickname,
              cloak_id: this.thread.identity.id,
              thread_id: this.thread.thread_id,
              message_type: "email",
              message_receive_time: this.thread.created_at,
            });
          },
        },
      });
    },
    iframe_source(message) {
      if (message?.email?.html) {
        return message.email.html;
      }
      /* Some plain text emails are coming back with html that doesn't include \n breaks *and* html, so we were losing the breaks. Checking for DOCTYPE ensures we'll show the html if it's a marketing email etc or show the text if plain text  */
      return message.email && this.safe(this.format(message.email.text));
    },
    has_attachments(message) {
      if (message && message.email.attachments) {
        return message.email.attachments.length > 0;
      }
      return false;
    },
    mark_read_thread() {
      let url = "/api/v1/cloaked/activity/read-thread/";
      if (this.thread.read) {
        url = "/api/v1/cloaked/activity/unread-thread/";
      }
      api()
        .post(url, {
          thread_id: this.thread.thread_id,
        })
        .then(() => {
          this.$emit("updated", {
            ...this.thread,
            read: !this.thread.read,
          });
          window.dispatchEvent(new CustomEvent("activity_read"));
        });
    },
    format(text) {
      if (text) {
        return text.replaceAll(/[\n]/g, "<br />");
      }
      return text;
    },
    trip_delete_thread() {
      this.openModal({
        header: `Delete message?`,
        subheader:
          "Any copies of this message that have been forwarded to your personal email will be unaffected. This cannot be undone.",
        button: {
          text: "Yes, Delete",
          onClick: () => this.confirm_delete_thread(),
          danger: true,
        },
      });
    },
    confirm_delete_thread() {
      this.$emit("removed", this.thread);
      api()
        .post("/api/v1/cloaked/activity/delete-thread/", {
          thread_id: this.thread.thread_id,
        })
        .then(() => {
          this.$emit("removed", this.thread);
        });
    },
    confirm_delete(email) {
      api()
        .delete(email.url)
        .then(() => {
          this.messages = this.messages.filter((m) => m.id !== email.id);
          /* TODO(madeline): really should only do this if there are no emails left at all, but we don't have the count for all, that's in ActivityPreviewList */
          if (this.messages.length === 0) {
            this.$router.push({ path: "/inbox" });
            this.$emit("removed", this.thread);
          }
          this.$emit("refresh", this.messages[0]);
          let eventName = "Delete Message";
          let payload = {
            message_type: "email",
            message_receive_time: email.created_at,
            cloak_name: this.thread.identity.nickname
              ? this.thread.identity.nickname
              : this.thread.identity.app_ref,
            cloak_id: this.thread.identity.id,
            message_in_thread: false,
            thread_id: null,
          };
          analyticsExport.methods.sendEvent(eventName, payload);
        });
    },
    async get_messages($state, reset) {
      if (this.next && !this.locked) {
        this.locked = true;
        return api()
          .get(this.next)
          .then((response) => {
            this.next = response.data.next;
            if (reset) {
              this.messages = response.data.results;
            } else {
              this.messages = [...this.messages, ...response.data.results];
            }
            this.loaded = true;
            this.locked = false;
            if ($state) {
              $state.loaded();
            }
          });
      } else {
        if ($state) {
          $state.complete();
        }
      }
    },
    getAliasFromEmail(value) {
      return getAliasFromEmail(value);
    },
  },
};
</script>
<style scoped lang="scss">
.loader {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: $color-primary-100;
  img {
    margin-bottom: 25px;
    animation: rotation 2s infinite linear;
  }
  text-align: center;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.reply_footer {
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 334px;
  height: 56px;
  background: $color-primary-10;
  border: 0 solid $color-primary-10;
  box-shadow: -3.63315px -3.63315px 10.8995px rgba(0, 0, 0, 0.04),
    5.44973px 5.44973px 20.9px rgba(1, 2, 24, 0.12);
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  padding: 0 16px;
  letter-spacing: -0.2px;
  color: $color-primary-50;
  span:last-child {
    background-color: $color-surface;
    width: 24px;
    height: 24px;
    border-radius: 999px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}

.preview {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 25px;
  max-width: 800px;
  margin: 10px auto 0 auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $color-primary-70;
  padding: 0 60px;
}
.attachment-list > div {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.attachment-list label {
  display: block;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: $color-primary-100-30;
  margin: 16px 0;
}
.messages {
  padding-bottom: 100px;

  .message {
    padding: 16px 20px;
    border-top: 1px solid $color-primary-10;

    &:first-child {
      border-top-width: 0;
    }
  }
}
.email-content {
  margin: 30px auto 0 auto;
  padding: 10px;
  width: 100%;
  max-width: 736px;
  background: $white;
  border-radius: 12px;
  border: 1px solid $color-primary-10;
  color: $color-primary-100;
}
iframe {
  background: transparent;
  width: 100%;
}
.kabab {
  background-color: transparent;
  border: none;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $color-primary-100;
  }
}
.app--visible-banner {
  header {
    top: 100px;
  }
}
header {
  top: 65px;
  width: 100%;
  display: flex;
  padding: 10px 20px;
  position: sticky;
  flex-direction: row;
  background-color: $color-surface;
  justify-content: space-between;
  border-bottom: 1px solid $color-primary-10;
  z-index: 10;
  margin-top: 0;

  .header-wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
  }
  .header-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: -0.5px;
    color: $color-primary-100;
    padding: 0 15px;
    flex-grow: 1;
  }
  .header-actions {
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    flex-grow: 0;
    color: $color-primary-100;
  }
}
.danger * {
  color: $color-alert;
}
</style>
