<script setup>
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import AppModal from "@/components/ui/AppModal.vue";
import Button from "@/components/Button.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
</script>

<template>
  <AppModal v-on="$listeners" v-bind="$attrs">
    <AppModalContent>
      <AppModalTitle> Unsaved changes </AppModalTitle>
      <AppModalParagraph> You have unsaved changes. </AppModalParagraph>
      <AppModalFooter>
        <Button type="secondary" @click="$emit('discard')"> Discard </Button>
        <Button @click="$emit('input', false)"> Keep editing </Button>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>
