<template>
  <svg
    :width="width || 3"
    :height="height || 12"
    viewBox="0 0 3 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.36719 9.35938C0.65625 9.35938 0.078125 9.9375 0.078125 10.6719C0.078125 11.3828 0.65625 11.9609 1.36719 11.9609C2.08594 11.9609 2.64844 11.3828 2.64844 10.6719C2.64844 9.9375 2.08594 9.35938 1.36719 9.35938ZM1.36719 4.71875C0.65625 4.71875 0.078125 5.28906 0.078125 6.00781C0.078125 6.71875 0.65625 7.28906 1.36719 7.28906C2.08594 7.28906 2.64844 6.71875 2.64844 6.00781C2.64844 5.28906 2.08594 4.71875 1.36719 4.71875ZM1.36719 0.046875C0.65625 0.046875 0.078125 0.625 0.078125 1.33594C0.078125 2.07031 0.65625 2.64844 1.36719 2.64844C2.08594 2.64844 2.64844 2.07031 2.64844 1.33594C2.64844 0.625 2.08594 0.046875 1.36719 0.046875Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "KabobIcon",
  props: ["width", "height"],
};
</script>
