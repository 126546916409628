var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-content" }, [
    _c(
      "table",
      _vm._l(_vm.tableContent, function (tableRow) {
        return _c("tr", { key: tableRow.header }, [
          _c("th", [_vm._v(_vm._s(tableRow.header))]),
          _c(
            "td",
            _vm._l(tableRow.content, function (tableData) {
              return _c("div", { key: tableData.title }, [
                _c("p", [
                  _vm._v(" " + _vm._s(tableData.title) + " "),
                  tableData.subtitle
                    ? _c("span", { staticClass: "td-subtitle" }, [
                        _vm._v("(" + _vm._s(tableData.subtitle) + ")"),
                      ])
                    : _vm._e(),
                ]),
              ])
            }),
            0
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }