<script setup>
import { computed, onMounted } from "vue";
import store from "@/store";
import WalletActivity from "@/components/Wallet/WalletActivity.vue";
import KycFlow from "@/components/Wallet/KycFlow.vue";
import router from "@/routes/router";

const cardEnabled = computed(() => {
  return store.state.authentication.user?.cloaked_card_enabled;
});

const kycValidated = computed(() => {
  return store.state.authentication.user?.cloaked_card_kyc_configured;
});

onMounted(() => {
  if (!cardEnabled.value && !kycValidated.value) {
    router.push("/");
  }
});
</script>

<template>
  <WalletActivity v-if="cardEnabled && kycValidated" />
  <KycFlow v-else-if="cardEnabled && !kycValidated" />
</template>
