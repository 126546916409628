<script setup>
import { nextTick } from "vue";

import store from "@/store";

function close() {
  return store.dispatch("closeModal");
}

function navigate() {
  close().then(() => {
    nextTick(() => {
      router.push({ name: "settings.recovery" });
    });
  });
}

import ModalTemplate from "@/components/ModalTemplate.vue";
import Warning from "@/assets/icons/warning-hex.svg";
import router from "@/routes/router";
</script>
<template>
  <ModalTemplate
    class="recovery-modal"
    :show="true"
    @close="close"
    :width="580"
  >
    <template #header>
      <span class="clap">
        <Warning />
      </span>
      <h1 class="title">Are you sure you have your recovery key?</h1>
    </template>
    <template #body>
      <p class="subscribe-thanks">
        Before continuing, be sure you’ve saved your recovery key in a secure
        location.
      </p>
      <p class="subscribe-cta">
        Cloaked has no record or way to recover your key if you lose it. We will
        be unable to help you regain access to your account if you lose your
        password and don’t have this key.
      </p>
    </template>
    <template #footer>
      <div class="actions">
        <button @click="navigate" class="subscribe-invite">
          Download recovery key
        </button>
        <button class="subscribe-close" @click="close">
          Yes, I have my key
        </button>
      </div>
    </template>
  </ModalTemplate>
</template>
<style scoped lang="scss">
.recovery-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 30px;
}
.clap {
  border-radius: 500px;
  height: 72px;
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 20px;
}
.title {
  font-size: 32px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 40px !important; /* 125% */
  letter-spacing: -0.5px;
  margin-bottom: 10px;
}
.subscribe-thanks {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.2px;
  margin-bottom: 10px;
}
.subscribe-cta {
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: normal;
  letter-spacing: -0.2px;
}
.actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  .subscribe-invite {
    border: none;
    width: 100%;
    border-radius: 50px;
    padding: 11px;
    background-color: $color-primary-100;
    color: $color-primary-0;
    text-align: center;
  }
  button {
    border: none;
    width: 100%;
    border-radius: 50px;
    padding: 11px;
    cursor: pointer;
    &.subscribe-close {
      background-color: $color-primary-0;
      border: 1px solid $color-primary-100;
      color: $color-primary-100;
    }
  }
}
</style>
