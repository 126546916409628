<template>
  <PreferencesPanel>
    <template v-slot:header>
      <PreferencesHeader @go-back="toggle_back" />
    </template>

    <PreferencesTitle>Gender</PreferencesTitle>

    <PreferencesRadio
      label="Female"
      :value="value"
      input-value="female"
      group-name="gender"
      :disabled="false"
      @update="handleUpdate"
    />

    <PreferencesRadio
      label="Male"
      :value="value"
      input-value="male"
      group-name="gender"
      :disabled="false"
      @update="handleUpdate"
    />

    <PreferencesRadio
      label="Non-binary"
      :value="value"
      input-value="non-binary"
      group-name="gender"
      :disabled="false"
      @update="handleUpdate"
    />

    <PreferencesRadio
      label="Prefer not to answer"
      :value="value"
      input-value="not specified"
      group-name="gender"
      :disabled="false"
      @update="handleUpdate"
    />

    <PreferencesFooter>
      <Button :disabled="loading" :loading="loading" @click="handleSave"
        >Save changes</Button
      >
    </PreferencesFooter>
  </PreferencesPanel>
</template>

<script>
import {
  PreferencesHeader,
  PreferencesPanel,
  PreferencesFooter,
  PreferencesTitle,
  PreferencesRadio,
} from "@/routes/modals/preferences";

import { Button } from "@/components";

import PersonalInfoService from "@/api/settings/personal-services";

export default {
  name: "Gender",
  components: {
    PreferencesHeader,
    PreferencesPanel,
    PreferencesRadio,
    PreferencesFooter,
    PreferencesTitle,
    Button,
  },
  mounted() {
    window.document.title = "Gender Preference • Cloaked";
  },
  data() {
    return {
      value: this.gender,
      options: ["male", "female", "non-binary", "not specified"],
      loading: false,
    };
  },
  props: ["id", "gender"],

  methods: {
    toggle_back(data) {
      this.$emit("toggleBack", data);
    },

    async save_changes() {
      let payload = {
        autofill_sex: this.value,
      };

      this.loading = true;

      if (this.id) {
        PersonalInfoService.updateInfo(this.id, payload)
          .then(() => {
            this.toggle_back({
              info_gender: this.value,
            });
            this.$toast.success("Gender saved.");
            this.loading = false;
          })
          .catch(() => {
            this.$toast.error("Error saving your gender.");
            this.loading = false;
          });
      } else {
        PersonalInfoService.createInfo(payload)
          .then(() => {
            this.toggle_back({
              info_gender: this.value,
            });
            this.$toast.success("Gender saved.");
            this.loading = false;
          })
          .catch(() => {
            this.$toast.error("Error saving your gender.");
            this.loading = false;
          });
      }
      this.loading = false;

      this.toggle_back({
        info_gender: this.value,
      });
    },

    handleUpdate(value) {
      this.value = value;
    },

    handleSave() {
      this.save_changes();
    },
  },
};
</script>
