var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "multiselect-filters" }, [
    _c(
      "div",
      { staticClass: "buttons" },
      [
        _c(
          _setup.UiTooltip,
          { attrs: { title: _setup.selectTooltipTitle, "align-x": "center" } },
          [
            _c(
              "button",
              {
                staticClass: "buttons__button toggle-select-all",
                attrs: { "aria-id": "SelectAllButton" },
                on: {
                  click: function ($event) {
                    return _setup.toggleAction("select")
                  },
                  mousedown: _setup.cancelBubble,
                  mouseup: _setup.cancelBubble,
                  mouseleave: _setup.cancelBubble,
                  mousemove: _setup.cancelBubble,
                },
              },
              [
                _vm.selected.length > 0
                  ? _c(_setup.MinusRound)
                  : _c(_setup.EmptyRound),
              ],
              1
            ),
          ]
        ),
        _vm.selected.length > 0 && _setup.categories.length > 0
          ? _c(
              _setup.UiMenu,
              {
                attrs: {
                  width: "164px",
                  "max-height": "300px",
                  placement: "bottom-start",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c(_setup.UiMenuTitle, {
                            attrs: { title: "Move to:" },
                          }),
                          _c(_setup.UiMenuSeparator),
                          _c(
                            _setup.UiMenuScrollBody,
                            _vm._l(
                              _setup.categories,
                              function (category, index) {
                                return _c(_setup.UiMenuButton, {
                                  key: index,
                                  staticClass:
                                    "multiselect-filters__category-button",
                                  attrs: {
                                    title: category.name,
                                    active: _setup.isInCategory(category),
                                  },
                                  on: {
                                    click: () =>
                                      _setup.moveToCategory(category),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [
                                            _setup.isInCategory(category)
                                              ? _c(_setup.CheckIcon)
                                              : _c("span"),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }
                            ),
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2866293562
                ),
              },
              [
                _c(
                  _setup.UiTooltip,
                  { attrs: { title: "Move category", "align-x": "center" } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "buttons__button",
                        attrs: { "aria-id": "SelectAllChangeCategory" },
                        on: {
                          mousedown: _setup.cancelBubble,
                          mouseup: _setup.cancelBubble,
                          mouseleave: _setup.cancelBubble,
                          mousemove: _setup.cancelBubble,
                        },
                      },
                      [_c(_setup.MoveIcon)],
                      1
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.selected.length > 0
          ? _c(
              _setup.UiTooltip,
              { attrs: { title: _vm.ignoreDisplay, "align-x": "center" } },
              [
                _c(
                  "button",
                  {
                    staticClass: "buttons__button",
                    attrs: { "aria-id": "SelectAllMute" },
                    on: {
                      click: function ($event) {
                        _vm.ignoreDisplay.toLowerCase() === "ignore"
                          ? _setup.toggleAction("ignore")
                          : _setup.toggleAction("unignore")
                      },
                      mousedown: _setup.cancelBubble,
                      mouseup: _setup.cancelBubble,
                      mouseleave: _setup.cancelBubble,
                      mousemove: _setup.cancelBubble,
                    },
                  },
                  [
                    _vm.ignoreDisplay.toLowerCase() === "ignore"
                      ? _c(_setup.MutedContextIcon)
                      : _c(_setup.UnmuteIcon),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.selected.length > 0
          ? _c(
              _setup.UiTooltip,
              { attrs: { title: "Delete", "align-x": "center" } },
              [
                _c(
                  "button",
                  {
                    staticClass: "buttons__button",
                    attrs: { "aria-id": "SelectAllDelete" },
                    on: {
                      click: function ($event) {
                        return _setup.toggleAction("delete")
                      },
                      mousedown: _setup.cancelBubble,
                      mouseup: _setup.cancelBubble,
                      mouseleave: _setup.cancelBubble,
                      mousemove: _setup.cancelBubble,
                    },
                  },
                  [_c(_setup.DeleteActionBarIcon)],
                  1
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm.selected.length === 0
      ? _c(
          "div",
          {
            staticClass: "text",
            on: {
              click: function ($event) {
                return _setup.toggleAction("select")
              },
            },
          },
          [_c("span", [_vm._v("Select all")])]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }