var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.MenuBlock,
    { attrs: { title: "Identities" } },
    [
      _c(_setup.MenuItem, {
        attrs: { link: "/", name: "All identities", icon: "blocks" },
      }),
      _c(_setup.MenuItem, {
        attrs: {
          link: "/favorites",
          name: "Favorites",
          icon: "favorite-filled",
        },
      }),
      _c(_setup.MenuItem, {
        attrs: { link: "/ignored", name: "Ignored", icon: "user-block" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }