var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    _vm._g(
      {
        on: {
          close: function ($event) {
            return _vm.$store.dispatch("onboarding/close")
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "AppModalContent",
        [
          _c("AppModalClose", {
            attrs: { "is-absolute": "" },
            on: {
              click: function ($event) {
                return _vm.$store.dispatch("onboarding/close")
              },
            },
          }),
          _c("AppModalImage", {
            attrs: {
              src: require("@/assets/images/onboarding/onboarding-import-large.jpg"),
              alt: _vm.title,
            },
          }),
          _c("AppModalTitle", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
          _c("AppModalParagraph", [
            _vm._v(
              " Cloaked can automatically replace passwords, email addresses, and phone numbers for your most important accounts "
            ),
          ]),
          _c(
            "AppModalFooter",
            { attrs: { "with-border": "" } },
            [
              _c(
                "Button",
                {
                  attrs: { type: "secondary" },
                  on: { click: _vm.completeStep },
                },
                [_vm._v(" No, thanks ")]
              ),
              _c(
                "Button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "Import" })
                    },
                  },
                },
                [_vm._v(" Get started ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }