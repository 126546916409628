<template>
  <span>{{ value }}</span>
</template>

<script>
export default {
  name: "AsyncText",
  props: ["getValue", "format"],
  data() {
    return {
      value: null,
    };
  },
  mounted() {
    if (typeof this.getValue === "string") {
      this.value = this.getValue;
    } else if (typeof this.getValue === "function") {
      this.getValue().then((value) => {
        this.value = this.format ? this.format(value) : value;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
span {
  color: $color-primary-100;
}
</style>
