var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PreferencesPanel",
    { staticClass: "forwarding-pref" },
    [
      _c("PreferencesTitle", [_vm._v("Analytics settings")]),
      _c("ValueDisplay", {
        attrs: {
          label: "Share usage analytics",
          value:
            "Your data is anonymized, and we will never share it with anyone.",
          "dark-label": "",
          "light-value": "",
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function () {
              return [
                _c("UiSwitchInput", {
                  attrs: { value: _vm.toggle },
                  on: {
                    change: function ($event) {
                      return _vm.toggleAnalytics(!_vm.toggle)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }