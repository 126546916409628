var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("UiMenu", {
    staticClass: "cloak-generate-email-flyout",
    attrs: { value: _vm.visible, width: "250px", placement: "left-start" },
    on: {
      close: function ($event) {
        return _vm.$emit("close")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("UiMenuButton", {
              attrs: {
                title: "Professional",
                subtitle: "(ie. yourusername@cloak.id)",
                rtl: false,
                active: _vm.email_type === "professional",
              },
              on: {
                click: function ($event) {
                  return _vm.onChangeInput("professional")
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("RadioButton", {
                        attrs: {
                          name: "email_type",
                          value: "professional",
                          checked: _vm.email_type === "professional",
                        },
                        on: { update: _vm.onChangeInput },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("UiMenuButton", {
              attrs: {
                title: "Random",
                subtitle: "(ie. five.score.extra@cloaked.id)",
                rtl: false,
                active: _vm.email_type === "random",
              },
              on: {
                click: function ($event) {
                  return _vm.onChangeInput("random")
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("RadioButton", {
                        attrs: {
                          name: "email_type",
                          value: "random",
                          checked: _vm.email_type === "random",
                        },
                        on: { update: _vm.onChangeInput },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("UiMenuSeparator"),
            _c("UiMenuButton", {
              attrs: { title: "Generate New Email Address", active: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("generate", _vm.email_type)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [_c("GenerateIcon")]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }