<script setup>
import axios from "axios";
import store from "@/store";

import CategoryService from "@/api/actions/category-service";

import CloaksList from "@/routes/your-cloaks/CloaksList";
import AddCloakToCategory from "@/routes/modals/categories/AddCloakToCategory";
import CategoryActionsMenu from "@/routes/modals/categories/CategoryActionsMenu";
import AddEditNewCategory from "@/routes/modals/categories/AddEditNewCategory";
import KabobIcon from "@/assets/icons/KabobIcon.vue";
import UiTooltip from "@/components/ui/ui-tooltip";

import router from "@/routes/router";
import { useRoute } from "vue-router/composables";
import {
  reactive,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from "vue";

const route = useRoute();

const source = axios.CancelToken.source();

const storeCategoryName = computed(() => {
  const id = parseInt(route.params.id, 10);
  const category = store.state.categories.custom.find((cat) => cat.id === id);
  return category?.name || "";
});

const state = reactive({
  category: store.state.categories.custom.find(
    (cat) => cat.id === route.params.id
  ),
  showAddCloaksModal: false,
  categoryName: storeCategoryName.value,
  nextPage: `/api/v1/cloaked/identity/?category=${route.params.id}&populated=true`,
  categoryActionsMenuVisible: false,
  isEditCategoryModalVisible: false,
  fetchingIdentities: false,
  fetchingCategory: false,
  savedCloaksInCategory: [],
});

onMounted(() => {
  getCategoryData();
  window.addEventListener("cloak:created", addCloakToCategory);
  window.addEventListener("category:updated", setCategoryName);
  window.addEventListener("category:identities", handleIdentityUpdate);
});

onBeforeUnmount(() => {
  window.removeEventListener("cloak:created", addCloakToCategory);
  window.removeEventListener("category:updated", setCategoryName);
  window.removeEventListener("category:identities", handleIdentityUpdate);
  source.cancel();
});

function addCloakToCategory(event) {
  const newCloak = event.data;
  addCloaksToCategory([newCloak.id]);
}

function addCloaksToCategory(newCloaks) {
  cloaksInCategory.value = [...newCloaks, ...cloaksInCategory.value];
  const identityIds = newCloaks.map((c) => c.id);
  CategoryService.addCloaksToCategory(categoryId.value, identityIds);
}

function loadNextPage($state) {
  if ($state && !state.nextPage && !state.fetchingIdentities) {
    // dismiss inifite loading spinner if there is nothing left to fetch
    $state.complete();
  }

  if (state.nextPage && !state.fetchingIdentities) {
    state.fetchingIdentities = true;
    return CategoryService.getNextCategoryPage(state.nextPage)
      .then(({ data }) => {
        const isFirstPage =
          !state.nextPage.includes("page=") || /page=1\b/.test(state.nextPage);

        if (state.nextPage.includes(`category=${route.params.id}`)) {
          // NOTE: do not update list if route param has changed since fetching

          if (isFirstPage) {
            cloaksInCategory.value = data.results;
          } else {
            cloaksInCategory.value = [
              ...cloaksInCategory.value,
              ...data.results,
            ];
          }

          state.nextPage = data.next;

          nextTick(() => {
            if ($state) {
              if (data.next) {
                $state.loaded();
              } else {
                $state.complete();
              }
            }
          });
        }

        state.fetchingIdentities = false;
      })
      .catch(() => {
        state.fetchingIdentities = false;
        if ($state) {
          $state.complete();
        }
      });
  }
}

function openAddCloaksModal() {
  state.showAddCloaksModal = true;
}

function closeAddCloaksModal() {
  state.showAddCloaksModal = false;
}

function refreshCloaks() {
  state.nextPage = `/api/v1/cloaked/identity/?category=${route.params.id}&populated=true`;
  cloaksInCategory.value = [];
}

function refreshCategory() {
  state.category = store.state.categories.custom.find(
    (cat) => cat.id === categoryId.value
  );
  setCategoryName();
  state.nextPage = `/api/v1/cloaked/identity/?category=${categoryId.value}&populated=true`;
  if (!state.category) {
    getCategoryData();
  }
}

function showCategoryActionsMenu() {
  state.categoryActionsMenuVisible = true;
}

function closeCategoryActionsMenu() {
  state.categoryActionsMenuVisible = false;
}

function handleEditCatName() {
  state.isEditCategoryModalVisible = true;
  state.categoryActionsMenuVisible = false;
}

function closeEditCategoryModal() {
  state.isEditCategoryModalVisible = false;
}

function handleDeleteCat() {
  state.categoryActionsMenuVisible = false;

  store.dispatch("openGlobalDeleteModal", {
    type: "category",
    paragraphs: [
      "Deleting the category won't delete the cloaks in the category.",
    ],
    onClick: deleteCat,
  });
}

function deleteCat(e) {
  e.stopPropagation();
  CategoryService.deleteCategory(state.category.id)
    .then(handleCloseDeleteCat)
    .catch(handleCloseDeleteCat);
}

function handleCloseDeleteCat() {
  if (route.params.id == state.category.id) {
    return router.push({ path: "/" });
  }
}

function getBoundingClientRect() {
  return document
    .getElementById("category-ellipsis-icon")
    .getBoundingClientRect();
}

function getCategoryData() {
  if (!state.fetchingCategory) {
    state.fetchingCategory = true;
    CategoryService.refreshCategory(source, categoryId.value)
      .then(({ data }) => {
        state.category = data;
        state.categoryName = data.name;
        state.fetchingCategory = false;
      })
      .catch(() => {
        state.fetchingCategory = false;
      });
  }
}

function handleIdentityUpdate() {
  // if user updates category in right panel, refresh category cloaks

  const idsInCat = identityList.value.map((cloak) => cloak.id);
  if (idsInCat.includes(store.state.rightPanel.cloak?.id)) {
    refreshCloaks();
  }
}

function setCategoryName() {
  state.categoryName = storeCategoryName.value;
}

const cloaksInCategory = computed({
  get() {
    const ids = state.savedCloaksInCategory.map((d) => d.id);
    return store.state.localdb.db_cloaks.filter((d) => ids.includes(d.id));
  },
  set(value) {
    state.savedCloaksInCategory = value;
  },
});

const categoryId = computed(() => {
  return route.params.id;
});

const identityList = computed(() => {
  let cloaksToShow = cloaksInCategory.value;
  if (store.state.rightPanel.cloakCreate) {
    const tempCloak = store.state.localdb.db_cloaks.find((c) => c.id === -1);
    if (tempCloak) {
      cloaksToShow = [tempCloak, ...cloaksToShow];
    }
  }
  return cloaksToShow;
});

watch(
  () => categoryId.value,
  () => {
    refreshCloaks();
    refreshCategory();
  }
);
</script>

<template>
  <div class="container" :key="`cat-${categoryId}`">
    <div class="title">
      <h1>{{ state.categoryName }}</h1>

      <UiTooltip title="Edit name and delete" align-x="right">
        <span
          aria-id="CategoryKabob"
          id="category-ellipsis-icon"
          @click="showCategoryActionsMenu"
        >
          <KabobIcon />
        </span>
      </UiTooltip>
    </div>

    <CloaksList
      ref="cloaklist"
      hoverText="Add cloak to category"
      :openAddCloaksModal="openAddCloaksModal"
      :showModalOnHover="true"
      :identityList="identityList"
      @loadNextPage="loadNextPage"
      @refresh="refreshCloaks"
    />
    <AddCloakToCategory
      :category="state.category"
      :isModalVisible="state.showAddCloaksModal"
      :identitiesInCategory="identityList"
      @closeModal="closeAddCloaksModal"
      @addCloaksToCategory="addCloaksToCategory"
    />
    <AddEditNewCategory
      id="add-edit-category"
      :isModalVisible="state.isEditCategoryModalVisible"
      :category="state.category"
      @closeModal="closeEditCategoryModal"
    />
    <CategoryActionsMenu
      id="category-actions-menu-id"
      :isVisible="state.categoryActionsMenuVisible"
      :category="state.category"
      :getParentBoundingRect="getBoundingClientRect"
      @handleEditCatName="handleEditCatName"
      @handleDeleteCat="handleDeleteCat"
      @close="closeCategoryActionsMenu"
    />
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin: 30px auto;
}
.title {
  padding: 0 36px;
  margin-bottom: 7px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: $color-primary-100;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  span {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &:hover {
      background-color: $color-primary-10;
    }

    svg {
      color: $color-primary-70;
    }
  }
}
</style>
