var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "activity-convo-wrapper" },
    [
      _c("ActivityConvoHeader", {
        attrs: {
          senderDisplay: _vm.senderDisplay(_vm.thread, true),
          cloakName: _vm.thread.identity.nickname,
          cloakIdentifier: _vm.thread?.cloaked_number
            ? _vm.formatPhoneNumber(_vm.thread.recipient)
            : _vm.thread.recipient,
          conversationId: _vm.thread.conversation_id,
          read: _vm.thread.read,
        },
        on: {
          onDelete: function ($event) {
            return _vm.$emit("removed", _vm.thread)
          },
          openCloak: _vm.goToCloak,
          onReadToggle: _vm.updateReadStatus,
        },
      }),
      !_vm.contactIsApproved
        ? _c("ActivityAnonymousContactHeader", {
            attrs: {
              name: _vm.thread.identity?.nickname,
              contact: _vm.thread.sender_contact,
            },
            on: { click: _vm.goToCloak },
          })
        : _vm._e(),
      _vm.loaded
        ? _c(
            "section",
            {
              ref: "activity-convo-list",
              class: {
                [_vm.getListClass]: true,
                "activity-convo-list--protected-identity":
                  _vm.thread?.identity?.protected,
              },
            },
            [
              _vm._l(_vm.conversationList, function (instance, idx) {
                return _c(
                  "div",
                  { key: `convo-list-${instance.id}-${idx}` },
                  [
                    instance.message
                      ? _c("ActivityMessageInstance", {
                          attrs: {
                            message: instance.message,
                            senderDisplay: _vm.senderDisplay(instance.message),
                            sameAsPrevSender: _vm.sameAsPrevSender(
                              instance.message,
                              idx + 1
                            ),
                          },
                          on: { openCloak: _vm.goToCloak },
                        })
                      : instance.call
                      ? _c("ActivityCallInstance", {
                          attrs: {
                            call: instance.call,
                            senderDisplay: _vm.senderDisplay(instance.call),
                            sameAsPrevSender: _vm.sameAsPrevSender(
                              instance.call,
                              idx + 1
                            ),
                          },
                          on: { openCloak: _vm.goToCloak },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm.hasLoaded
                ? _c("InfiniteTrigger", {
                    key: "activity-convo",
                    ref: "convoInfinite",
                    staticStyle: { "align-self": "center", width: "100%" },
                    on: { infinite: _vm.getConversationActivity },
                  })
                : _vm._e(),
            ],
            2
          )
        : _c("div", { staticClass: "loader" }, [_vm._m(0)]),
      !_vm.thread?.identity?.protected
        ? _c("ActivityConvoReply", {
            attrs: { thread: _vm.thread },
            on: {
              refresh: _vm.refresh,
              setContactStatus: _vm.setContactStatus,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("@/assets/icons/small-spinner.png"),
          width: "30",
        },
      }),
      _c("br"),
      _vm._v(" Loading messages.... "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }