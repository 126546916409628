var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "settings-child-page" }, [
    _c(
      "aside",
      { staticClass: "settings-child-page__aside" },
      [_vm._t("aside")],
      2
    ),
    _c(
      "div",
      { staticClass: "settings-child-page__content" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }