<template>
  <div class="tip" :class="`tip--${type}`">
    <Component :is="`tip-${type}`" class="tip__icon" />
    <slot />
  </div>
</template>

<script>
import TipInfo from "@/assets/icons/tip-info.svg";
import TipWarning from "@/assets/icons/tip-warning.svg";

export default {
  components: {
    TipInfo,
    TipWarning,
  },
  props: {
    type: {
      type: String,
      default: "info",
      validator: (value) => ["info", "warning"].includes(value),
    },
  },
};
</script>

<style lang="scss">
.tip {
  padding: 16px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: $color-primary-100;
  display: flex;
  align-items: center;

  &__icon {
    flex-shrink: 0;
    margin-right: 14px;
  }

  &--info {
    border: 1px solid $color-primary-30;
    color: $color-primary-100;
  }

  &--warning {
    background: $color-warning-light;
    border: 1px solid $color-warning;

    @at-root .theme-dark & {
      color: $color-primary-100-light;
    }
  }
}
</style>
