var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preferences-panel" }, [
    _vm.hasHeader
      ? _c(
          "header",
          { staticClass: "preferences-panel__header" },
          [_vm._t("header")],
          2
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "preferences-panel__body" },
      [_vm._t("default")],
      2
    ),
    _vm.hasFooter
      ? _c(
          "footer",
          { staticClass: "preferences-panel__footer" },
          [_vm._t("footer")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }