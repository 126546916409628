var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_setup.rightPanel
    ? _c(
        _setup.PreferencesPanel,
        [_c(_setup.CloakedCardsStatus), _c(_setup.FundingSource)],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }