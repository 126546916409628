<script setup>
import AppModal from "@/components/ui/AppModal.vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
import Button from "@/components/Button.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import {
  defineProps,
  defineEmits,
  ref,
  watch,
  onBeforeMount,
  onBeforeUnmount,
  computed,
} from "vue";
import IdentitySharingTerms from "@/components/cloakDetails/IdentitySharing/IdentitySharingModal/IdentitySharingTerms.vue";
import BorderInputText from "@/components/BorderInputText.vue";
import BorderInputSelect from "@/components/BorderInputSelect.vue";
import AppModalCustomWrapper from "@/components/ui/AppModalCustomWrapper.vue";
import IdentityIcon from "@/components/ui/IdentityIcon.vue";
import Timer from "@/components/Timer.vue";
import IconCopy from "@/assets/icons/copy.svg";
import IconReload from "@/assets/icons/reload.svg";
import IconSpinner from "@/assets/icons/spinner.svg";
import CheckMark from "@/assets/icons/check-mark.svg";
import { tools } from "@/scripts";
import Spinner from "@/assets/icons/spinner.svg";

const props = defineProps({
  identity: {
    type: Object,
    default: () => ({}),
  },
  sharing: {
    type: Object,
    default: () => ({}),
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update-sharing", "expired"]);
const expiresIn = ref(null);

const previousExpiresIn = computed(() => {
  if (props.identity.sharing.onetimeview) {
    return "One-time view";
  }

  const sharedDate = new Date(props.identity.sharing.shared_at);
  const expiresDate = new Date(props.identity.sharing.expires_at);

  const dateDifferenceInHours =
    (expiresDate.getTime() - sharedDate.getTime()) / (1000 * 60 * 60);

  if (dateDifferenceInHours > 7 * 24) {
    return "30 days";
  }

  if (dateDifferenceInHours > 24) {
    return "7 days";
  }

  if (dateDifferenceInHours > 1) {
    return "24 hours";
  }

  return "1 hour";
});

let intervalCheckExpired = null;

onBeforeMount(() => {
  intervalCheckExpired = setInterval(() => {
    const dateExpired = new Date(props.identity.sharing.expires_at);
    const dateNow = new Date();

    if (dateNow.getTime() >= dateExpired.getTime()) {
      emit("expired");
      clearInterval(intervalCheckExpired);
    }
  }, 5000);
});

onBeforeUnmount(() => {
  clearInterval(intervalCheckExpired);
});

watch(
  () => props.isLoading,
  (newValue, oldValue) => {
    if (!newValue && oldValue) {
      expiresIn.value = null;
    }
  }
);

const onExpiresInChanged = (value) => {
  expiresIn.value = value;

  const sharedDate = new Date();
  const expirationDate = new Date();

  if (value === "One-time view") {
    expirationDate.setDate(expirationDate.getDate() + 30);

    emit("update-sharing", {
      ...props.sharing,
      onetimeview: true,
      shared_at: sharedDate.toISOString(),
      expires_at: expirationDate.toISOString(),
    });
  } else {
    switch (value) {
      case "1 hour":
        expirationDate.setHours(expirationDate.getHours() + 1);
        break;
      case "24 hours":
        expirationDate.setHours(expirationDate.getHours() + 24);
        break;
      case "7 days":
        expirationDate.setDate(expirationDate.getDate() + 7);
        break;
      case "30 days":
        expirationDate.setDate(expirationDate.getDate() + 30);
        break;
    }

    emit("update-sharing", {
      ...props.sharing,
      shared_at: sharedDate.toISOString(),
      expires_at: expirationDate.toISOString(),
      onetimeview: false,
    });
  }

  window.$vue?.$toast?.success(
    "Link expiration changed. Click publish changes."
  );
};
</script>

<template>
  <AppModal v-on="$listeners" v-bind="$attrs">
    <AppModalContent
      class="sharing-modal-published"
      :class="{ 'sharing-modal-published--loading': isLoading }"
    >
      <AppModalCustomWrapper class="sharing-modal-published__head">
        <IdentityIcon
          :identity="identity"
          :override="{ width: '72px', height: '72px' }"
          class="sharing-modal-published__identity-icon"
        />
        <AppModalTitle class="sharing-modal-published__title-top">
          Publishing your changes...
        </AppModalTitle>
        <Timer
          :start-date="identity.sharing?.shared_at"
          :end-date="identity.sharing?.expires_at"
          :is-one-time-view="identity.sharing?.onetimeview"
          class="sharing-modal-published__timer"
        />
        <div class="sharing-modal-published__spinner">
          <Spinner />
        </div>
      </AppModalCustomWrapper>
      <div class="sharing-modal-published__content">
        <AppModalTitle class="sharing-modal-published__title">
          Your share link is published
        </AppModalTitle>
        <IdentitySharingTerms />
        <AppModalCustomWrapper>
          <BorderInputText
            label="Link"
            :value="identity.sharing?.shared_url"
            disabled
            class="sharing-modal-published__input"
            @click="
              tools.copyToClipboard(identity.sharing?.shared_url);
              $toast?.success('Link copied.');
            "
          >
            <template #after>
              <button class="sharing-modal-published__icon-action">
                <IconCopy />
              </button>
              <button
                class="sharing-modal-published__icon-action"
                @click.stop="
                  $attrs.isGeneratingLink || $emit('generate-new-link')
                "
                :disabled="expiresIn !== null"
              >
                <IconSpinner v-if="$attrs.isGeneratingLink" />
                <IconReload v-else />
              </button>
            </template>
          </BorderInputText>
          <div class="sharing-modal-published__input-row">
            <BorderInputText
              label="Link password"
              :value="identity.sharing?.decryptedPassword"
              disabled
              class="sharing-modal-published__input"
              @click="
                tools.copyToClipboard(identity.sharing?.decryptedPassword);
                $toast?.success('Link password copied.');
              "
            >
              <template #after>
                <button class="sharing-modal-published__icon-action">
                  <IconCopy />
                </button>
                <button
                  class="sharing-modal-published__icon-action"
                  @click.stop="
                    $attrs.isGeneratingPassword ||
                      $emit('generate-new-password')
                  "
                  :disabled="expiresIn !== null"
                >
                  <IconSpinner v-if="$attrs.isGeneratingPassword" />
                  <IconReload v-else />
                </button>
              </template>
            </BorderInputText>
            <BorderInputSelect
              class="sharing-modal-published__expiration"
              label="Link expires in..."
              :value="expiresIn ?? previousExpiresIn"
              @input="onExpiresInChanged"
              :options="[
                'One-time view',
                '1 hour',
                '24 hours',
                '7 days',
                '30 days',
              ]"
              disabled
            >
              <template #option="{ option }">
                <span
                  class="sharing-modal-published__expiration-option"
                  :class="{
                    'sharing-modal-published__expiration-option--selected':
                      option === expiresIn ||
                      (option === previousExpiresIn && !expiresIn),
                  }"
                >
                  {{ option }}
                  <CheckMark
                    v-if="
                      option === expiresIn ||
                      (option === previousExpiresIn && !expiresIn)
                    "
                  />
                </span>
              </template>
            </BorderInputSelect>
          </div>
        </AppModalCustomWrapper>
      </div>
      <AppModalFooter with-border class="sharing-modal-published__footer">
        <button
          class="sharing-modal-published__change-permission"
          @click="$emit('open-create')"
        >
          Change permissions
        </button>
        <template v-if="expiresIn !== null">
          <Button
            type="danger-secondary"
            @click="
              $emit('discard-changes');
              expiresIn = null;
            "
            >Discard changes</Button
          >
          <Button @click="$emit('update')">Publish changes</Button>
        </template>
        <template v-else>
          <Button type="secondary" @click="$emit('open-delete')"
            >Delete link</Button
          >
          <Button @click="$emit('input', false)">Close</Button>
        </template>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>

<style lang="scss">
.sharing-modal-published {
  max-height: 535px;
  transition: max-height 0.25s ease-in-out;

  &--loading {
    max-height: 108px;
  }

  &__identity-icon {
    transform: scale(1);
    transition: transform 0.25s ease-in-out;
    transform-origin: 0 0;

    @at-root .sharing-modal-published--loading & {
      transform: scale(0.61);
    }
  }

  &__spinner {
    position: absolute;
    right: 32px;
    top: calc(50% - 24px);
    transition: all 0.25s ease-in-out;
    opacity: 0;
    transform: translateY(50px);

    @at-root .sharing-modal-published--loading & {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__timer {
    position: absolute;
    right: 32px;
    top: 0;
    transition: all 0.25s ease-in-out;
    transform: translateY(0);

    @at-root .sharing-modal-published--loading & {
      opacity: 0;
      transform: translateY(50px);
    }
  }

  &__title-top {
    margin: 0;
    opacity: 0;
    transform: translate3d(-16px, 77px, 0);
    transition: all 0.25s ease-in-out;
    padding: 0;

    @at-root .sharing-modal-published--loading & {
      opacity: 1;
      transform: translate3d(-16px, 7px, 0);
    }
  }

  &__head {
    margin-top: 32px;
    position: relative;
    display: flex;
    align-items: flex-start;
  }

  &__content {
    transition: all 0.3s ease-out;
    opacity: 1;

    @at-root .sharing-modal-published--loading & {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  &__footer {
    transition: all 0.25s 0.1s ease-out;
    opacity: 1;

    @at-root .sharing-modal-published--loading & {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  & &__title {
    margin-top: 16px;
  }

  .border-input-text,
  .border-input-select {
    margin-top: 16px;
  }

  &__input-row {
    display: grid;
    gap: 24px;
    grid-template-columns: 3fr 2fr;
  }

  &__input {
    .border-input-text__input {
      padding-right: 84px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
    }
  }

  &__change-permission {
    color: $color-primary-100;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    margin-right: auto;
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__icon-action {
    width: 34px;
    height: 44px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 0;
    cursor: pointer;
    transition: transform 0.05s ease-out;
    color: $color-primary-100;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      transform: scale(0.92);
    }

    &:disabled {
      transform: scale(1);
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  .border-input-select__button {
    background-color: $color-primary-5;
  }

  &__expiration {
    .popper__content {
      padding: 0;
    }

    .border-input-select__options-item {
      height: 36px;
    }

    &-option {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      path {
        fill: $color-primary-100;
      }

      &--selected {
        font-weight: 600;
      }
    }
  }
}
</style>
