<template>
  <section @click="$event.stopPropagation()">
    <!--    <div class="option-box-container" v-if="shouldUpdate">-->
    <!--      <div class="option-box">-->
    <!--        <multiselect-->
    <!--          ref="searchInput"-->
    <!--          v-model="address"-->
    <!--          placeholder="search address"-->
    <!--          :options="options"-->
    <!--          label="label"-->
    <!--          :searchable="true"-->
    <!--          :allow-empty="false"-->
    <!--          :show-labels="false"-->
    <!--          :internal-search="false"-->
    <!--          :clear-on-select="false"-->
    <!--          :close-on-select="true"-->
    <!--          :options-limit="300"-->
    <!--          :limit="3"-->
    <!--          :max-height="600"-->
    <!--          :show-no-results="false"-->
    <!--          :show-no-option="false"-->
    <!--          no-options="Search for address"-->
    <!--          @search-change="search_address"-->
    <!--          @select="select_option"-->
    <!--          @close="editing = false"-->
    <!--          maxlength="50"-->
    <!--          ><template slot="noOptions">Search for address</template></multiselect-->
    <!--        >-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div v-else class="search_button">-->
    <!--      <button @click="editing = true">change</button>-->
    <!--    </div>-->

    <div class="edit-address" v-on:body="isOpen = false" ref="openAddressRef">
      <div class="split_row">
        <PreferencesInput
          @save="$emit('save')"
          label="Street"
          :error="hasError('billing_address_1')"
          :value="value('autofill_street_address')"
          @input="
            (value) =>
              on_update_value({ name: 'autofill_street_address', value })
          "
        />

        <PreferencesInput
          @save="$emit('save')"
          label="Apartment #"
          :value="value('autofill_unit')"
          @input="(value) => on_update_value({ name: 'autofill_unit', value })"
        />
      </div>

      <div class="split_row">
        <PreferencesInput
          @save="$emit('save')"
          label="City"
          :error="hasError('billing_city')"
          :value="value('autofill_address_level2')"
          @input="
            (value) =>
              on_update_value({ name: 'autofill_address_level2', value })
          "
        />

        <PreferencesInput
          @save="$emit('save')"
          label="State"
          :options="states"
          :error="hasError('billing_state')"
          :value="value('autofill_address_level1')"
          :readonly="true"
          @option="
            (value) =>
              on_update_value({ name: 'autofill_address_level1', value })
          "
        />
      </div>

      <div class="split_row">
        <PreferencesInput
          @save="$emit('save')"
          label="Zip"
          :pattern="/[0-9]/g"
          :error="hasError('billing_zip')"
          :max="9"
          :value="value('autofill_postal_code')"
          @input="
            (value) => on_update_value({ name: 'autofill_postal_code', value })
          "
        />

        <PreferencesInput
          @save="$emit('save')"
          label="Country"
          :options="countries"
          :error="hasError('billing_country')"
          :value="value('autofill_country')"
          :readonly="true"
          @option="
            (value) => on_update_value({ name: 'autofill_country', value })
          "
        />
      </div>
    </div>
  </section>
</template>

<script>
import Radar from "radar-sdk-js";
import { debounce } from "lodash-es";

import { countries } from "@/scripts/countries";
import { states } from "@/scripts/states";
import PreferencesInput from "../PreferencesInput";

let timeout;
export default {
  name: "EditAddress",
  props: ["current", "errors"],
  components: {
    PreferencesInput,
  },
  data() {
    return {
      address: null,
      options: [],
      editing: true,
      isLoading: false,
    };
  },
  mounted() {
    Radar.initialize(global.ENV.VUE_APP_RADAR_PUBLIC);
  },
  watch: {
    isOpen(value) {
      if (value) {
        window.addEventListener("modal:click", this.onClickBody);
      } else {
        window.removeEventListener("modal:click", this.onClickBody);
      }
    },
    editing(value) {
      if (value) {
        setTimeout(() => {
          this.$refs.searchInput.activate();
        }, 100);
      }
    },
  },
  updated() {
    if (!this.location && this.not_empty(this.current)) {
      this.location = this.current;
    }
  },
  computed: {
    location() {
      return this.not_empty(this.current) && this.current;
    },
    shouldUpdate() {
      return this.editing || !this.location;
    },
    countries() {
      return countries().map((o) => o.name);
    },
    states() {
      if (this.country === "United States" || !this.country) {
        return states().map((o) => o.name);
      }
      return [];
    },
  },
  methods: {
    hasError(field) {
      if (this.errors) {
        return this.errors.includes(field);
      }
      return false;
    },
    not_empty(address) {
      return (
        address.autofill_street_address ||
        address.autofill_unit ||
        address.autofill_country ||
        address.autofill_address_level1 ||
        address.autofill_address_level2 ||
        address.autofill_postal_code
      );
    },
    search_address(value) {
      if (!this.isLoading) {
        let a = this;
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          this.isLoading = true;
          setTimeout(() => {
            a.isLoading = false;
          }, 2000);
          if (value) {
            Radar.autocomplete(
              { query: value, limit: 7 },
              function (err, result) {
                a.isLoading = false;
                if (!err) {
                  a.options = result.addresses.map((v) => ({
                    label: `${v.addressLabel || ""} - ${v.city || ""}, ${
                      v.stateCode || ""
                    }`.trim(),
                    ...v,
                  }));
                }
              }
            );
          }
        }, 1050);
      }
    },
    value(field) {
      if (this.location) {
        return this.location[field];
      }
      return null;
    },
    on_update_value: debounce(
      function debouncedSave({ name, value }) {
        this.$emit("update", { name, value });
      },
      300,
      { leading: false, trailing: true }
    ),
  },
};
</script>
<style lang="scss" scoped>
.edit-address {
  display: flex;
  flex-direction: column;
  gap: 13px;

  .preferences-input {
    margin-top: 0;
  }
}

.split_row {
  display: grid;
  gap: 13px;

  &:nth-child(1) {
    grid-template-columns: 8fr 4fr;
  }

  &:nth-child(2) {
    grid-template-columns: 8fr 4fr;
  }

  &:nth-child(3) {
    grid-template-columns: 4fr 8fr;
  }
}
</style>
